<template>
  <div class="flexCenter">
    <el-upload
      action="#"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      :http-request="httprequest"
      multiple
      :accept="
        props.type == 'file'
          ? chatType
          : props.type == 'base'
          ? baseType
          : props.type == 'img'
          ? imgType
          : props.type == 'excel'
          ? excelType
          : ''
      "
    >
      <slot></slot>
    </el-upload>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["type"]);
const emit = defineEmits(["beforeAvatarUpload"]);
const fileList = ref([]);
const errList = ref([]);
const timer = ref(null);
const fileTypeList = ref(["txt", "pptx", "pdf", "docx", "xlsx", "html"]);
const chatType = ".txt,.pptx,.pdf,.docx,.xlsx,.html";
const baseType = ".txt,.pdf,.docx,.xlsx";
const imgType = ".jpg,.png,.gif";
const baseList = ref(["txt", "pdf", "docx", "xlsx"]);
const imgTypeList = ref(["jpg", "png", "gif"]);
const excelList = ref(["xlsx"]);
const excelType = ".xlsx";
const httprequest = () => {};
const beforeAvatarUpload = (file) => {
  file.fileType = file.name
    .substring(file.name.lastIndexOf(".") + 1)
    .toLowerCase()
    .replace(" ", "");
  // 不符合类型直接提示
  if (props.type == "file") {
    if (!fileTypeList.value.includes(file.fileType)) {
      message.warning(file.name + t("base.base283"));
      return;
    }
  }
  if (props.type == "base") {
    if (!baseList.value.includes(file.fileType)) {
      errList.value.push({
        name: file.name,
        type: t("base.base283"),
        fileType: file.fileType,
        uid: file.uid,
      });
    }
  }
  if (props.type == "excel") {
    if (!excelList.value.includes(file.fileType)) {
      errList.value.push({
        name: file.name,
        type: t("base.base283"),
        fileType: file.fileType,
        uid: file.uid,
      });
    }
  }
  if (props.type == "img") {
    if (!imgTypeList.value.includes(file.fileType)) {
      message.warning(file.name + t("base.base284"));
      return;
    }
  }
  // 大小限制
  if (file.size > 20971520) {
    if (props.type != "base") {
      message.warning(file.name + t("base.base281"));
      return;
    } else {
      errList.value.push({
        name: file.name,
        type: t("base.base281"),
        fileType: file.fileType,
        uid: file.uid,
      });
    }
  }
  // 名称超长
  let fileNameLen = String(file.name);
  if (fileNameLen.length > 100) {
    if (props.type != "base") {
      message.warning(file.name + t("base.base282"));
      return;
    } else {
      errList.value.push({
        name: file.name,
        type: t("base.base282"),
        fileType: file.fileType,
        uid: file.uid,
      });
    }
  }
  fileList.value = [...fileList.value, file];
  // 清除之前的定时器
  clearTimeout(timer.value);
  // 设置新的定时器
  timer.value = setTimeout(() => {
    let uidsInArray2 = errList.value.map((item) => item.uid);
    fileList.value = fileList.value.filter(
      (item) => !uidsInArray2.includes(item.uid)
    );
    emit("beforeAvatarUpload", fileList.value, errList.value);
    fileList.value = [];
    errList.value = [];
  }, 100); // 设置防抖的时间间隔，例如500毫秒
};
</script>

<style lang="scss" scoped>
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
