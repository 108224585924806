<template>
  <div class="qwDialog" @click.stop="">
    <div class="header">
      <div class="qwTitle">{{ t("publish.index1") }}</div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeWx" />
    </div>
    <div class="diaTitle">
      {{ props.type == "base" ? t("publish.index52") : t("publish.index2") }}
    </div>
    <div class="inputTitle">
      {{ t("publish.index8") }}<span class="red">*</span>
    </div>
    <div class="divInput">
      <div class="urlText">
        {{
          Object.keys(props.configInfo).length
            ? `${nowUrl}/openai/publish/new/1/${
                props.configInfo.appid ? props.configInfo.appid + "/" : ""
              }100/200/callback`
            : nowUrl
        }}
      </div>
      <img class="copySvg" :src="icon.copySvg" alt="" @click="copyFn" />
    </div>
    <div class="inputTitle">AppID<span class="red">*</span></div>
    <el-input
      :placeholder="t('publish.index3') + 'AppID'"
      v-model="props.configInfo.appid"
    ></el-input>
    <div class="inputTitle">Secret<span class="red">*</span></div>
    <el-input
      :placeholder="t('publish.index3') + 'Secret'"
      v-model="props.configInfo.secret"
    ></el-input>
    <div class="inputTitle">Token<span class="red">*</span></div>
    <el-input
      :placeholder="t('publish.index3') + 'Token'"
      v-model="props.configInfo.token"
    ></el-input>
    <div class="inputTitle">EncodingAesKey<span class="red">*</span></div>
    <el-input
      :placeholder="t('publish.index3') + 'EncodingAesKey'"
      v-model="props.configInfo.encodingAesKey"
    ></el-input>
    <div class="flexRight">
      <el-button class="cancel" @click="closeWx">{{
        t("publish.index4")
      }}</el-button>
      <el-button class="saveBtn" @click="save">{{
        t("publish.index5")
      }}</el-button>
    </div>
  </div>
  <div class="mask" @click.stop=""></div>
</template>

<script setup>
import { message } from "ant-design-vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import clipboard3 from "vue-clipboard3";
import { icon } from "@/utils/icon";
const { toClipboard } = clipboard3();
const nowUrl = ref(window.location.origin);
const { t } = useI18n();
const props = defineProps(["configInfo", "type"]);
const emit = defineEmits(["closeWx", "save"]);
const closeWx = () => {
  emit("closeWx");
};
// 复制
const copyFn = async () => {
  if (!props.configInfo.appid) {
    message.warning(t("publish.index3") + "AppID");
    return;
  }
  let url =
    nowUrl.value +
    "/openai/publish/new/1/" +
    props.configInfo.appid +
    "/100/200/callback";
  await toClipboard(url); // str 为需要复制的文字
  message.success(t("base.base216"));
};
const save = () => {
  for (const key in props.configInfo) {
    if (!props.configInfo[key]) {
      return message.warning(t("publish.index3") + key);
    }
  }
  emit("save", props.configInfo, 1);
};
</script>

<style lang="scss" scoped>
.addClose {
  width: 14px;
  cursor: pointer;
}
.qwDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  border-radius: 30px;
  padding: 30px;
  background-color: #fefefe;
  transform: translate(-50%, -50%);
  z-index: 110;
  width: 540px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 109;
  opacity: 0.3;
}
.qwTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-weight: 500;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.diaTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 500;
  margin: 30px 0px;
}
.inputTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  padding-left: 15px;
  padding-bottom: 17px;
}
.red {
  color: #f02d63;
  margin-left: 5px;
}
:deep(.el-input__wrapper) {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 400;
}
.cancel {
  border: 2px solid #edeef6;
  border-radius: 16px;
  padding: 10px 20px;
  height: 48px;
  font-size: 20px;
  color: #f02d63;
  font-weight: 400;
}
.saveBtn {
  border: 2px solid #3376ff;
  border-radius: 16px;
  padding: 10px 20px;
  height: 48px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  background-color: #3376ff;
}
.flexRight {
  display: flex;
  justify-content: flex-end;
}
.copySvg {
  width: 36px;
  cursor: pointer;
}
.divInput {
  border-radius: 16px;
  height: 48px;
  background-color: #edeef6;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}
.copySvg {
  width: 36px;
  cursor: pointer;
}
.urlText {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  width: calc(100% - 36px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-input__wrapper) {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 400;
}
</style>
