<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { message } from "ant-design-vue";
import RoleDetail from "../components/roleDetail.vue";
import RoleAdd from "../components/roleAdd.vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { debounce } from "@/utils/utils";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const loading = ref(false);
onMounted(() => {
  getList();
});
//搜索
const status = ref("userName");
const search = ref([
  {
    value: "userName",
    label: t("manage.roleForm.role1"),
  },
  {
    value: "roleName",
    label: t("manage.roleForm.role2"),
  },
]);
// 请求参数
const currentPage = ref(1);
const pageSize = ref(20);
const totalItems = ref(0);
const tableDepartment = ref([]);
const valueInput = ref("");
const listForm = ref({
  userId: userId.value,
  pageId: currentPage.value,
  perPage: pageSize.value,
  userName: "",
  roleName: "",
});
watch(
  () => valueInput.value,
  (newVal) => {
    if (status.value == "userName") {
      listForm.value.roleName = "";
      listForm.value.userName = newVal;
    } else if (status.value == "roleName") {
      listForm.value.userName = "";
      listForm.value.roleName = newVal;
    }
    getList();
  }
);
watch(
  () => status.value,
  (newVal) => {
    if (newVal == "userName") {
      getList();
    } else if (newVal == "roleName") {
      getList();
    }
  }
);
const selectable = (row) => {
  return row.id != 4;
};
// 角色table查询
const getList = debounce(() => {
  loading.value = true;
  api.manage.getRoleListApi(listForm.value).then((res) => {
    if (res.returnCode == 200) {
      totalItems.value = res.totalDataNum;
      tableDepartment.value = res.data;
      loading.value = false;
    }
  });
}, 1000);

const updatePageSize = (newSize) => {
  pageSize.value = newSize;
};
const updateCurrentPage = (newPage) => {
  currentPage.value = newPage;
};
watch(
  () => currentPage.value,
  (newVal) => {
    listForm.value.pageId = newVal;
    getList();
  }
);
watch(
  () => pageSize.value,
  (newVal) => {
    listForm.value.perPage = newVal;
    getList();
  }
);
// 多选
const ids = ref([]);
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  ids.value = val.map((item) => item.id);
  multipleSelection.value = val;
};
// 批量禁用
const removeGroup = () => {
  if (multipleSelection.value.length == 0) {
    message.error(t("manage.roleForm.role3"));
    return;
  }

  ElMessageBox.confirm(t("manage.roleForm.role4"), t("manage.roleForm.role5"), {
    confirmButtonText: t("manage.roleForm.role6"),
    cancelButtonText: t("manage.roleForm.role7"),
    type: "warning",
  }).then(() => {
    api.manage
      .roleDisableApi({
        roleIds: ids.value,
        isActivate: 0,
        userId: userId.value,
      })
      .then((res) => {
        console.log(res);

        if (res.returnCode == 200) {
          message.success(t("manage.roleForm.role8"));
          getList();
        }
      });
  });
};
// 导出角色详情
const exportRole = async () => {
  const imageUrl = "/openai/user-management/roles/export2";
  const a = document.createElement("a");
  a.href = imageUrl;
  a.download = "download.xlsx";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
// 打开详情
const openShow = ref(false);
const roleInfo = ref({});
const handleDetail = (row) => {
  roleInfo.value = {};
  roleInfo.value = JSON.parse(JSON.stringify(row));
  openShow.value = true;
};
// 关闭详情
const handleCancel = () => {
  openShow.value = false;
};
const formType = ref("add");
// 打开角色编辑
const showEdit = ref(false);
const handleAdd = () => {
  openShow.value = false;
  showEdit.value = true;
  formType.value = "edit";
};
// 关闭角色编辑
const handleClose = () => {
  showEdit.value = false;
};
// 提交角色编辑
const handleSubmit = () => {
  showEdit.value = false;
  getList();
};
// 打开角色新增
const addUser1 = () => {
  formType.value = "add";
  roleInfo.value = {};
  showEdit.value = true;
};
// 角色新增
const addSubmit = () => {
  showEdit.value = false;
  showAddRole.value = false;
  getList();
};
// 操作工具栏
const changeMore = (key, data) => {
  if (key === 1) {
    formType.value = "edit";
    roleInfo.value = JSON.parse(JSON.stringify(data));
    showEdit.value = true;
  } else if (key === 2) {
    // 禁用角色
    api.manage
      .roleDisableApi({
        roleIds: [data.id],
        isActivate: 0,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode === 200) {
          message.success(t("manage.roleForm.role8"));
          getList();
        }
      });
  } else if (key === 3) {
    if (data.id == 1 || data.id == 4) {
      message.error(t("manage.roleForm.role9"));
      return;
    }
    // 删除角色
    api.manage
      .roleDeleteApi({
        userId: userId.value,
        roleIds: [data.id],
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode === 200) {
          message.success(t("manage.roleForm.role10"));
          getList();
        }
      });
  } else if (key === 4) {
    api.manage
      .roleDisableApi({
        roleIds: [data.id],
        isActivate: 1,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode === 200) {
          message.success(t("manage.roleForm.role11"));
          getList();
        }
      });
  }
};
const ids2 = ref([]);
const roleId = ref(null);
const showAddRole = ref(false);
const userList = ref([]);
const addDepartment = (row) => {
  roleInfo.value = JSON.parse(JSON.stringify(row));
  roleId.value = row.id;
  showAddRole.value = true;
};
// 确定增加角色用户
const saveUser = (arr) => {
  ids2.value = arr.map((item) => item.userId);
  showAddRole.value = false;
  api.manage
    .roleAddUserApi({
      userId: userId.value,
      userIdList: ids2.value,
      roleId: roleId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.roleForm.role12"));
        getList();
      }
    });
};
</script>

<template>
  <div class="main" v-loading="loading">
    <div class="menuBox">
      <div style="display: flex">
        <!-- 用户 -->
        <div class="User">
          <div class="searchDiv">
            <el-select v-model="status">
              <el-option
                v-for="(item, index) in search"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
                <span class="dropText">{{ item.label }}</span>
              </el-option>
            </el-select>
            <el-input
              v-model="valueInput"
              :placeholder="t('manage.roleForm.role13')"
            />
          </div>
        </div>
      </div>
      <!-- 功能区 -->
      <div style="display: flex">
        <el-button class="toolBox" @click="exportRole">
          {{ $t("manage.roleForm.role14") }}
        </el-button>
        <el-button
          :disabled="$hasPermission('10022') == false"
          class="toolBox"
          style="color: #f02d63"
          @click="removeGroup"
        >
          {{ $t("manage.roleForm.role15") }}
        </el-button>
        <el-button
          :disabled="$hasPermission('10019') == false"
          class="toolBox addUser"
          @click="addUser1"
        >
          {{ $t("manage.roleForm.role16") }}
        </el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableDepartment"
        class="tableHeight"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :selectable="selectable" />
        <el-table-column prop="roleName" :label="t('manage.roleForm.role17')">
          <template #default="scope">
            <el-tooltip popper-class="toolTip" :content="scope.row.roleName">
              <div class="wid100">{{ scope.row.roleName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="userInfoList"
          :label="t('manage.roleForm.role18')"
        >
          <template #default="scope">
            <AvatarGroupTwo
              :avatars="scope.row.userInfoList"
              :number="5"
            ></AvatarGroupTwo>
          </template>
        </el-table-column>
        <el-table-column
          prop="isActivate"
          :label="$t('manage.roleForm.role21')"
          width="120"
        >
          <template #default="scope">
            <div style="color: #409eff" v-if="scope.row.isActivate">
              {{ $t("manage.roleForm.role22") }}
            </div>
            <div style="color: #f02d63" v-else type="info">
              {{ $t("manage.roleForm.role23") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleScopes" :label="t('manage.roleForm.role19')">
          <template #default="scope">
            <baseName :avatars="scope.row.roleScopes" :number="2"></baseName>
          </template>
        </el-table-column>
        <el-table-column prop="rolesNames" :label="t('manage.roleForm.role20')">
          <template #default="scope">
            <AvatarName
              :avatars="scope.row.rolesNames"
              :number="2"
            ></AvatarName>
          </template>
        </el-table-column>

        <el-table-column :label="$t('manage.userForm.operation')" fixed="right">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="handleDetail(scope.row)"
              :disabled="scope.row.id == 4 || $hasPermission('10018') == false"
            >
              {{ $t("manage.roleForm.role24") }}
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              :disabled="$hasPermission('10019') == false"
              @click="addDepartment(scope.row)"
            >
              {{ $t("manage.roleForm.role25") }}
            </el-button>
            <el-dropdown
              @command="changeMore($event, scope.row)"
              trigger="click"
              class="changeMore"
              :disabled="scope.row.id == 4"
            >
              <div class="moreIcon">
                <img :src="icon.baseMore" alt="" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :command="1"
                    :disabled="$hasPermission('10020') == false"
                    ><span class="dropText">{{
                      $t("manage.roleForm.role26")
                    }}</span></el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="2"
                    style="color: #f02d63"
                    :disabled="$hasPermission('10022') == false"
                    v-if="scope.row.id !== 4 && scope.row.isActivate === 1"
                    ><span class="dropText">{{
                      $t("manage.roleForm.role27")
                    }}</span></el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="4"
                    style="color: #14ae5c"
                    :disabled="$hasPermission('10022') == false"
                    v-if="scope.row.id !== 4 && scope.row.isActivate === 0"
                    ><span class="dropText">{{
                      $t("manage.roleForm.role28")
                    }}</span></el-dropdown-item
                  >

                  <el-dropdown-item
                    :command="3"
                    style="color: #f02d63"
                    :disabled="$hasPermission('10021') == false"
                    v-if="scope.row.id !== 4"
                    ><span class="dropText">{{
                      $t("manage.roleForm.role29")
                    }}</span></el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :currentPage="currentPage"
        :pageSize="pageSize"
        :total="totalItems"
        @size-change="updatePageSize"
        @current-change="updateCurrentPage"
        class="Pagination"
      />
    </div>
  </div>
  <!-- 角色详情 -->
  <RoleDetail
    :show="openShow"
    :roleInfo="roleInfo"
    @handleCancel="handleCancel"
    @handleAdd="handleAdd"
  ></RoleDetail>
  <!-- 角色编辑 -->
  <RoleAdd
    v-if="showEdit"
    :showEdit="showEdit"
    :formType="formType"
    :roleInfo="roleInfo"
    @addSubmit="addSubmit"
    @handleClose="handleClose"
    @handleSubmit="handleSubmit"
  ></RoleAdd>
  <!-- 给角色增加用户 -->
  <addUser
    v-if="showAddRole"
    :show="showAddRole"
    :roleInfo="roleInfo"
    @closeUser="showAddRole = false"
    @saveUser="saveUser"
  >
  </addUser>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.menuBox {
  :deep(.el-input__wrapper) {
    border-radius: 0 16px 16px 0;
  }

  :deep(.el-input) {
    --el-input-height: 42px !important;
  }
}
.searchDiv {
  :deep(.el-select) {
    width: 80px !important;
  }

  :deep(.el-select__wrapper) {
    width: 80px;
    padding: 0 6px;
    height: 42px !important;
    border-radius: 16px 0 0 16px !important;
    background-color: #edeef6;
    color: #3D3D3D;
    font-family:'Regular';
  }
}

:deep(.el-date-editor.el-input__wrapper) {
  height: 50px !important;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

.main {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

.Pagination {
  margin-top: 16px;
}

.menuBox {
  position: relative;
  display: flex;
  justify-content: space-between;

  .User {
    height: 42px;
    line-height: 42px;
    border-radius: 16px;
    color: #3d3d3d;
    font-size: 17px;

    .searchDiv {
      display: flex;
      color: #3d3d3d;

      .searchSpan {
        color: #3d3d3d;
        font-size: 16px;
        display: flex;

        .text {
          min-width: 34px;
          white-space: nowrap;
        }
      }

      .searchIcon {
        margin-left: 3px;
      }
    }
  }

  .date {
    height: 42px;
    line-height: 42px;
    border: 2px solid #edeef6;
    border-radius: 16px;
    font-size: 18px;
    color: #8a8a8a;
    display: flex;
    font-size: 17px;
    text-align: center;
    padding: 0 0 0 10px;
    margin-right: 16px;

    div:first-child {
      margin-right: 20px;
      color: #8a8a8a;
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .searchDiv {
      // margin-left: 10px;
      color: #3d3d3d;

      .searchSpan {
        color: #3d3d3d;
        font-size: 16px;
        width: 100%;
        display: flex;
        margin-top: 18px;
      }

      .searchIcon {
        margin-left: 10px;
      }
    }
  }
}

.table {
  margin-top: 20px;
  height: calc(100% - 62px);
}

.toolBox {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  height: 42px;
  line-height: 40px;
  color: #8a8a8a;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Regular';
}

.tableHeight {
  height: calc(100% - 45px);
}

.addUser {
  background-color: #0256ff;
  color: #fff;
  margin-right: 0;
}

.changeMore {
  margin-left: 5px;

  .moreIcon {
    width: 24px;
    height: 24px;

    img {
      display: block;
      width: 16px;
      height: 16px;
      margin: 4px auto;
    }
  }
}

.exportBtn {
  width: 148px;
  height: 42px;
  line-height: 42px;
  border-radius: 16px;
  font-size: 17px;
  color: #3376ff;
  border: 2px solid #edeef6;
  text-align: center;
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  padding-left:10px
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  padding-left:10px
}
</style>
