<template>
  <div class="editFlex">
    <img
      :src="icon.closeHistory"
      alt=""
      class="historyCloseIcon"
      @click="closeEdit(props.item)"
    />
    <div class="editDiv">
      <el-input
        :placeholder="t('base.base229')"
        class="editInput"
        v-model="editMessage"
      ></el-input>
      <img
        :src="icon.chatSend"
        v-if="editMessage.length == 0"
        alt=""
        class="sendImg"
      />
      <img :src="icon.sendYes" v-else class="sendImg" />
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { onMounted, ref } from "vue";
const editMessage = ref("");
const props = defineProps(["item"]);
const emit = defineEmits(["closeEdit"]);
onMounted(() => {
  editMessage.value = props.item.quesstion;
});
</script>

<style lang="scss" scoped>
.editFlex {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.historyCloseIcon {
  width: 14px;
  cursor: pointer;
}
.editDiv {
  height: 33px;
  border-radius: 18px;
  border: 2px solid #d9d9d9;
  padding: 15px 15px 15px 30px;
  display: flex;
  align-items: center;
  margin-left: 23px;
  width: calc(100% - 85px);
}
.editInput {
  :deep(.el-input__wrapper) {
    font-size: 22px;
    font-weight: 500;
    box-shadow: 0px 0px 0px 0px;
    border: 0px;
  }
}
.sendImg {
  width: 30px;
}
</style>
