<template>
  <div class="plugAdd">
    <!-- 标题 -->
    <div class="titleFlex">
      <div class="addTitle">{{ t("bot.base131") }}</div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeAt" />
    </div>
    <!-- 鉴权类型 -->
    <div class="names">{{ t("bot.base138") }}</div>
    <div class="flexBetween">
      <div
        :class="authorizationHas == item.value ? 'typeItemAc' : 'typeItem'"
        class="type"
        v-for="item in typeList"
        :key="item.value"
        @click="changeType(item.value)"
      >
        {{ item.label }}
      </div>
    </div>
    <template v-if="authorizationHas == 1">
      <!-- 鉴权头部前缀 -->
      <div class="names">{{ t("bot.base139") }}</div>
      <div class="flexBetween">
        <div
          :class="authorizationType == item.value ? 'typeItemAc' : 'typeItem'"
          class="header"
          v-for="item in headerList"
          :key="item.value"
          @click="changeHeader(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
      <!-- 键 -->
      <div class="names">{{ t("bot.base140") }}</div>
      <el-input
        :placeholder="t('bot.base141')"
        v-model="authorizationName"
        class="atInput"
      ></el-input>
      <!-- 值 -->
      <div class="names">{{ t("bot.base135") }}</div>
      <el-input
        :placeholder="t('bot.base142')"
        v-model="authorizationValue"
        class="atInput"
      ></el-input>
    </template>
    <!-- 按钮 -->
    <div class="fooderDiv">
      <el-button class="cannel" @click="closeAt">{{
        t("bot.bot48")
      }}</el-button>
      <el-button class="saveBtn" @click="saveAt">{{
        t("base.base47")
      }}</el-button>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { message } from "ant-design-vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const authorizationName = ref("Authorization");
const authorizationValue = ref(null);
const typeList = ref([
  {
    label: t("bot.base132"),
    value: 0,
  },
  {
    label: "API Key",
    value: 1,
  },
]);
const headerList = ref([
  {
    label: "Basic",
    value: 1,
  },
  {
    label: "Bearer",
    value: 2,
  },
  {
    label: "Custom",
    value: 3,
  },
]);
const emit = defineEmits(["closeAt", "saveAt"]);
const props = defineProps(["form"]);
onMounted(() => {
  authorizationHas.value = props.form.authorizationHas;
  authorizationName.value = props.form.authorizationName;
  authorizationValue.value = props.form.authorizationValue;
  authorizationType.value = props.form.authorizationType;
});
// 切换鉴权类型
const authorizationHas = ref(0);
const changeType = (index) => {
  authorizationHas.value = index;
  authorizationName.value = "Authorization";
  authorizationValue.value = null;
  authorizationType.value = null;
};
// 切换头
const authorizationType = ref(null);
const changeHeader = (index) => {
  authorizationType.value = index;
};
// 关闭
const closeAt = () => {
  emit("closeAt");
};
// 保存鉴权
const saveAt = () => {
  if (authorizationHas.value == 1) {
    if (!authorizationType.value) {
      return message.warning(t("bot.base171"));
    }
    if (!authorizationName.value) {
      return message.warning(t("bot.base141"));
    }
    if (!authorizationValue.value) {
      return message.warning(t("bot.base142"));
    }
  }
  let obj = {
    authorizationHas: authorizationHas.value,
    authorizationName: authorizationName.value,
    authorizationValue: authorizationValue.value,
    authorizationType: authorizationType.value,
  };
  emit("saveAt", obj);
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.plugAdd {
  width: 520px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 141;
  padding: 30px;
  border-radius: 30px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 140;
  opacity: 0.3;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.names {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.names::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  margin-left: 5px;
}
.optionDiv {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin-top: 17px;
  margin-bottom: 24px;
}
.optionText {
  width: calc(100% - 24px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.botOption {
  width: 24px;
  cursor: pointer;
}
.typeItem {
  border: 1px solid #dbdfff;
  border-radius: 12px;
  box-shadow: 1px 2px 4px 0px #edeef6;
  font-size: 16px;
  font-weight: 500;
  color: #3d3d3d;
  padding: 12px 0px;
  text-align: center;
  margin-top: 17px;
  cursor: pointer;
  margin-bottom: 24px;
}
.typeItemAc {
  background-color: #eceeff;
  border: 1px solid #3376ff;
  box-shadow: 1px 2px 4px 0px #dbdfff;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #3d3d3d;
  padding: 12px 0px;
  text-align: center;
  margin-top: 17px;
  cursor: pointer;
  margin-bottom: 24px;
}
.type {
  width: 49%;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  width: 32%;
}
.atInput {
  font-size: 18px;
  font-weight: 400;
  :deep(.el-input__wrapper) {
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 0px 0px;
    height: 44px;
    border-radius: 12px;
    margin-top: 17px;
    margin-bottom: 24px;
  }
}
.fooderDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}
.cannel {
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  padding: 11px 20px;
  color: #f02d63;
  font-size: 18px;
  font-weight: 400;
  height: 48px;
}
.saveBtn {
  border: 1px solid #3376ff;
  border-radius: 16px;
  padding: 11px 20px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  height: 48px;
  background-color: #3376ff;
}
</style>
