
import axios from "../server";
export default {
    // 获取时间
    getChatTime(data: any) {
        return axios({
            url: "/test/response/chat/test",
            method: "GET",
            data
        });
    },
    // embedding获取时间
    getEmbeddingTime(data: any) {
        return axios({
            url: "/test/response/embedding/test",
            method: "GET",
            data
        });
    },
    // 个人中心
    getUserInfo(data: any) {
        return axios({
            url: "/personal-page/view",
            method: "POST",
            data,
        });
    },
    // 更新用户中心的数据
    updateUserInfos(data: any) {
        return axios({
            url: "/personal-page/update",
            method: "POST",
            data,
        });
    },
    // 获取未读通知数量
    getCount(data: any) {
        return axios({
            url: `configuration-set-future/notice/unread/count/${data.userId}`,
            method: "GET",
            data
        });
    },
    // 删除通知
    deleteNoticeList(data: any) {
        return axios({
            url: `configuration-set-future/notice/delete`,
            method: "POST",
            data
        });
    },
    // 点击为已读
    markreadNotice(data: any) {
        return axios({
            url: `configuration-set-future/notice/markread`,
            method: "POST",
            data
        });
    },
    // 最近话题
    chatAllQuery(data: any) {
        return axios({
            url: `chat/session/session/all/query`,
            method: "POST",
            data
        });
    },
    // 获取消息列表
    getNotice(data: any) {
        return axios({
            url: `configuration-set-future/notice/list2`,
            method: "POST",
            data
        });
    },
}