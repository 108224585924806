<template>
  <div class="flexBetWeen">
    <div class="flexCenter">
      <img src="@/assets/publish/spIcon.svg" alt="" class="spIcon" />
      <span class="spTitle">{{ t("publish.index30") }}</span>
    </div>
    <div>
      <el-icon class="close" @click="closeSp">
        <Close />
      </el-icon>
    </div>
  </div>
  <div class="spContent">
    <div class="spName">{{ t("publish.index31") }}</div>
    <div class="spInput">{{ t("publish.index32") }}</div>
    <!-- 国内国际 -->
    <el-select v-model="region" class="selectSp">
      <el-option
        v-for="item in spList"
        :key="item.value"
        :label="t(item.label)"
        :value="item.value"
      >
        <span class="dropText">{{ t(item.label) }}</span>
      </el-option>
    </el-select>
    <div class="spInput">TenantId</div>
    <el-input
      :placeholder="t('publish.index34')"
      v-model="tenant_id"
      class="spInputDiv"
    ></el-input>
    <div class="spInput">ClientId</div>
    <el-input
      :placeholder="t('publish.index35')"
      v-model="client_id"
      class="spInputDiv"
    ></el-input>
    <div class="spInput">ClientSecret</div>
    <el-input
      :placeholder="t('publish.index50')"
      v-model="client_secret"
      class="spInputDiv"
    ></el-input>
    <div class="spName">{{ t("publish.index33") }}</div>
    <div :class="is_active ? 'spInput' : 'spNoInput'">
      {{ t("publish.index36") }}
    </div>
    <el-select
      v-model="frequency"
      @change="changeDay"
      class="selectSp"
      :disabled="!is_active"
    >
      <el-option
        v-for="item in spTimeList"
        :key="item.value"
        :label="t(item.label)"
        :value="item.value"
      >
        <span class="dropText">{{ t(item.label) }}</span>
      </el-option>
    </el-select>
    <div :class="is_active ? 'spInput' : 'spNoInput'">
      {{ t("publish.index37") }}
    </div>
    <div class="betWeen" v-if="frequency == 'weekly'">
      <el-select v-model="day" class="selectSp wid49" :disabled="!is_active">
        <el-option
          v-for="item in weekList"
          :key="item.value"
          :label="t(item.label)"
          :value="item.value"
        >
          <span class="dropText">{{ t(item.label) }}</span>
        </el-option>
      </el-select>
      <el-time-picker
        :disabled="!is_active"
        style="width: 49%"
        v-model="synchronization_time"
        value-format="HH:mm:ss"
      />
    </div>
    <div class="dataTime" v-if="frequency == 'daily'">
      <el-time-picker
        :disabled="!is_active"
        style="width: 100%"
        v-model="synchronization_time"
        value-format="HH:mm:ss"
      />
    </div>
    <div class="betWeen" v-if="frequency == 'monthly'">
      <el-select
        :disabled="!is_active"
        v-model="day"
        :placeholder="t('publish.index55')"
        class="selectSp wid49"
      >
        <el-option
          v-for="item in 31"
          :key="item"
          :label="item + t('publish.index56')"
          :value="item"
        >
          <span class="dropText">{{ item }}{{ t("publish.index56") }}</span>
        </el-option>
      </el-select>
      <el-time-picker
        :disabled="!is_active"
        style="width: 49%"
        v-model="synchronization_time"
        value-format="HH:mm:ss"
      />
    </div>
    <div class="bottom24">
      <el-checkbox v-model="is_active"> </el-checkbox>
      <span class="timeTip">{{ t("publish.index38") }}</span>
    </div>
    <div class="flexRight">
      <el-button class="cancel" @click="closeSp">{{
        t("publish.index4")
      }}</el-button>
      <el-button class="saveBtn" @click="saveSp">{{
        t("manage.baseForm.base59")
      }}</el-button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["spInfo"]);
const emit = defineEmits(["closeSp", "saveSp"]);
const region = ref(0);
const tenant_id = ref("");
const client_id = ref("");
const client_secret = ref("");
const is_active = ref(false);
const spList = ref([
  {
    label: "publish.index40",
    value: 0,
  },
  {
    label: "publish.index39",
    value: 1,
  },
]);
// 每天 每周
const frequency = ref("daily");
const spTimeList = ref([
  {
    label: "publish.index41",
    value: "daily",
  },
  {
    label: "publish.index42",
    value: "weekly",
  },
  {
    label: "publish.index54",
    value: "monthly",
  },
]);
const synchronization_time = ref(null);
const day = ref(7);
const weekList = ref([
  {
    label: "publish.index43",
    value: 1,
  },
  {
    label: "publish.index44",
    value: 2,
  },
  {
    label: "publish.index45",
    value: 3,
  },
  {
    label: "publish.index46",
    value: 4,
  },
  {
    label: "publish.index47",
    value: 5,
  },
  {
    label: "publish.index48",
    value: 6,
  },
  {
    label: "publish.index49",
    value: 7,
  },
]);
onMounted(() => {
  let spInfo = JSON.parse(JSON.stringify(props.spInfo));
  tenant_id.value = spInfo.tenant_id;
  client_id.value = spInfo.client_id;
  client_secret.value = spInfo.client_secret;
  region.value = spInfo.region;
  frequency.value = spInfo.frequency;
  day.value = spInfo.day;
  synchronization_time.value = spInfo.synchronization_time;
  is_active.value = spInfo.is_active;
});
const closeSp = () => {
  emit("closeSp");
};
const changeDay = () => {
  if (frequency.value == "weekly") {
    day.value = 7;
    synchronization_time.value = null
  } else {
    synchronization_time.value = null
    day.value = null;
  }
};
// 保存sp
const saveSp = () => {
  let obj = {
    tenant_id: tenant_id.value,
    client_id: client_id.value,
    client_secret: client_secret.value,
    region: region.value, //0:国际 1:中国
    frequency: frequency.value, //"weekly",
    synchronization_time: synchronization_time.value,
    is_active: is_active.value,
    day:
      frequency.value == "weekly" || frequency.value == "monthly"
        ? day.value
        : null, // 假设这个任务只在周一执行
  };
  emit("saveSp", obj);
};
</script>

<style lang="scss" scoped>
.spIcon {
  width: 25px;
  margin-right: 13px;
}
.spTitle {
  color: #3d3d3d;
  font-size: 20px;
  font-weight: 500;
  margin-right: 12px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.betWeen {
  display: flex;
  justify-content: space-between;
}
.spContent {
  margin-top: 24px;
  height: calc(100% - 52px);
  overflow: auto;
}
.spName {
  padding-left: 15px;
  color: #3d3d3d;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 17px;
}
.spInput {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
  padding-left: 15px;
  margin-bottom: 12px;
  position: relative;
}
.spNoInput {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
  padding-left: 15px;
  margin-bottom: 12px;
  position: relative;
}
.spInput::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  margin-left: 5px;
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
.selectSp {
  margin-bottom: 24px;
  :deep(.el-select__wrapper) {
    border-radius: 16px;
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px;
    height: 48px;
    font-size: 18px;
    font-weight: 400;
  }
}
.spInputDiv {
  :deep(.el-input__wrapper) {
    border: 2px solid #edeef6;
    border-radius: 16px;
    height: 42px;
    box-shadow: 0px 0px 0px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}
.wid49 {
  width: 49%;
}
:deep(.el-input__wrapper) {
  border: 2px solid #edeef6;
  border-radius: 16px;
  height: 42px;
  box-shadow: 0px 0px 0px;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px;
}
.dataTime {
  height: 48px;
  margin-bottom: 24px;
}
.timeTip {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  margin-left: 10px;
}
.flexRight {
  display: flex;
  justify-content: flex-end;
}
.cancel {
  border: 2px solid #edeef6;
  border-radius: 16px;
  padding: 10px 20px;
  height: 48px;
  font-size: 20px;
  color: #f02d63;
  font-weight: 400;
}
.saveBtn {
  border: 2px solid #3376ff;
  border-radius: 16px;
  padding: 10px 20px;
  height: 48px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  background-color: #3376ff;
}
.bottom24 {
  margin-bottom: 24px;
}
.close {
  color: #8a8a8a;
  font-size: 22px;
  cursor: pointer;
}

.close:hover {
  color: #0256ff;
}
</style>
