<template>
  <div class="itemDiv">
    <!-- 头类型 -->
    <div class="flexBetWeen">
      <div class="flexCenter">
        <div class="titleDiv">{{ t("header.header44") }}</div>
        <div :class="!props.item.isRead ? 'wd' : ''" class="timeText">
          {{ getTime(props.item.createdAt) }}
        </div>
      </div>
      <div class="flexCenter">
        <el-tooltip popper-class="toolTip" :content="t('header.header54')">
          <img
            :src="icon.clearMessage"
            alt=""
            class="clearMessage"
            @click="markItem"
          />
        </el-tooltip>
      </div>
    </div>
    <!-- 内容 -->
    <div class="flexCenter top12">
      <img :src="icon.headerNext" class="headerNext" alt="" />
      <div class="messageName">{{ t("header.header57") }}</div>
    </div>
    <div class="bodyDiv">
      <messageView :message="props.item.content"></messageView>
      <div class="flexRight">
        <div class="flexCenter yzBtn" @click="openLook">
          <div>{{ t("header.header56") }}</div>
          <img :src="icon.bImg" class="bImg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import messageView from "@/components/message/index.vue";
import { getTime } from "@/utils/utils";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["item"]);
const emit = defineEmits(["openLook",'markItem']);
const openLook = () => {
  emit("openLook", props.item);
};
const markItem = () => {
  emit("markItem", props.item);
};
</script>

<style lang="scss" scoped>
.flexRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
}
.headerNext {
  width: 27px;
  margin-right: 10px;
}
.clearMessage {
  width: 20px;
  cursor: pointer;
}
.itemDiv {
  padding: 12px 0px;
  border-bottom: 1px solid #edeef6;
}
.bodyDiv {
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 14px 18px;
}
.bImg {
  width: 9px;
  margin-left: 6px;
}
.yzBtn {
  border-radius: 8px;
  font-size: 16px;
  color: #0256ff;
  padding: 3px 9px;
  font-weight: 400;
  cursor: pointer;
}
.yzBtn:hover {
  background-color: #eceeff;
}
.top12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.messageName {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
}
.left12 {
  margin-left: 12px;
}
.wd::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #11d7b2;
  right: -15px;
  top: 3px;
}
.timeText {
  font-size: 16px;
  color: #b4b4b4;
  font-weight: 400;
  position: relative;
}
.titleDiv {
  background-color: #d6eef9;
  font-size: 14px;
  border-radius: 8px;
  color: #38aee5;
  font-weight: 400;
  padding: 5px 12px;
  margin-right: 12px;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  align-items: center;
}
</style>
