<template>
  <div class="loginDiv">
    <div :class="!channelType ? 'loginContent' : 'loginContentChannel'">
      <!-- 左侧 -->
      <div class="flexLogin" v-if="!channelType">
        <img :src="icon.login" alt="" class="loginImg" />
        <div class="leftBigDiv" v-if="companyName">
          <img :src="imgUrl" alt="" class="qcImg" />
          <div>
            <div class="zhDiv">{{ t("login.login1") }}</div>
            <div class="compyName">{{ companyName }}</div>
          </div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="rightLogin">
        <div class="flexCenter titleDiv">
          <img :src="icon.slogo" alt="" class="slogo" />
          <div class="loginTitle">
            {{
              loginStep == "login"
                ? !channelType
                  ? t("login.login2")
                  : t("login.login9")
                : loginStep == "sso"
                ? t("login.login3")
                : loginStep == "password"
                ? t("login.login4")
                : loginStep == "active"
                ? t("login.login5")
                : ""
            }}
          </div>
        </div>
        <!-- 返回 -->
        <el-button class="goBack" @click="goBack" v-if="loginStep != 'login'">{{
          t("login.login6")
        }}</el-button>
        <!-- 正常登录 -->
        <div v-if="loginStep == 'login'">
          <el-input
            autocomplete="new-password"
            :read-only="userNameType"
            @focus="userNameType = false"
            v-model="userName"
            :placeholder="t('login.login7')"
          />
          <el-input
            autocomplete="new-password"
            :read-only="passwordType"
            @focus="passwordType = false"
            v-model="userPassword"
            @keyup.enter.stop="goLogin"
            type="password"
            :placeholder="t('login.login8')"
            show-password
          />
          <el-button @click="goLogin" class="loginBtn" :loading="loginLoading">
            {{ t("login.login9") }}
          </el-button>
        </div>
        <!-- sso登录 -->
        <div v-if="loginStep == 'sso'" class="flexLinma">
          <iframe
            width="300"
            height="425"
            src="https://sso.linmara.com/#/sso/iframe?serviceId=ai"
            frameborder="0"
          ></iframe>
        </div>
        <!-- 找回密码第一步 -->
        <div v-if="loginStep == 'password' && !passwordStep">
          <el-input
            autocomplete="new-password"
            :read-only="userNameType"
            @focus="userNameType = false"
            v-model="userName"
            :placeholder="t('login.login7')"
          />
          <el-button @click="sendCode" class="loginBtn" :loading="codeLoading">
            {{ t("login.login11") }}
          </el-button>
        </div>
        <!-- 找回密码第二部 -->
        <div v-if="loginStep == 'password' && passwordStep">
          <div class="sendDiv">
            <el-input
              class="sendInput"
              autocomplete="new-password"
              :read-only="userNameType"
              @focus="userNameType = false"
              v-model="activationCode"
              :placeholder="t('login.login12')"
            >
            </el-input>
            <el-button
              class="sendBtn"
              link
              @click="sendCode"
              :loading="codeLoading"
              :disabled="sendType"
            >
              {{
                sendType
                  ? t("login.login14") + time + t("login.login15")
                  : t("login.login13")
              }}
            </el-button>
          </div>
          <el-input
            autocomplete="new-password"
            :read-only="passwordType"
            @focus="passwordType = false"
            v-model="userPassword"
            type="password"
            :placeholder="t('login.login8')"
            show-password
          />
          <el-input
            autocomplete="new-password"
            :read-only="passwordType"
            @focus="passwordType = false"
            v-model="surePassword"
            type="password"
            :placeholder="t('login.login16')"
            show-password
          />
          <el-button
            @click="savePassword"
            class="loginBtn"
            :loading="loginLoading"
          >
            {{ t("login.login17") }}
          </el-button>
        </div>
        <!-- 激活 -->
        <div v-if="loginStep == 'active'">
          <el-input
            autocomplete="new-password"
            :read-only="userNameType"
            @focus="userNameType = false"
            v-model="userName"
            :placeholder="t('login.login7')"
          />
          <el-input
            autocomplete="new-password"
            :read-only="passwordType"
            @focus="passwordType = false"
            v-model="userPassword"
            @keyup.enter.stop="goLogin"
            type="password"
            :placeholder="t('login.login8')"
            show-password
          />
          <el-input
            autocomplete="new-password"
            :read-only="userNameType"
            @focus="userNameType = false"
            v-model="activationCode"
            :placeholder="t('login.login18')"
          />
          <el-button
            @click="saveActive"
            class="loginBtn"
            :loading="loginLoading"
            >{{ t("login.login5") }}</el-button
          >
        </div>
        <!-- 免责 -->
        <div :class="!channelType ? 'mzDiv' : 'mzDivChannel'">
          <div
            class="flexCenter"
            v-if="loginStep != 'password' && !channelType"
          >
            <el-checkbox v-model="checked" label="" />
            <span class="tipSpan">{{ t("login.login19") }} </span>
            <span class="tipSpan1" @click="openDisclaimer">{{
              t("login.login20")
            }}</span>
          </div>
          <div
            class="zhmm"
            v-if="loginStep == 'login' && companyStatus && langKey != 'US'"
            @click="forGet"
          >
            {{ t("login.login4") }}
          </div>
          <div
            class="zhmm"
            v-if="!companyStatus && loginStep != 'active' && langKey != 'US'"
            @click="goActive"
          >
            {{ t("login.login21") }}
          </div>
        </div>
        <div
          class="zhmm passwordRight"
          v-if="loginStep == 'login' && companyStatus && langKey == 'US'"
          @click="forGet"
        >
          {{ t("login.login4") }}
        </div>
        <div
          class="zhmm passwordRight"
          v-if="!companyStatus && loginStep != 'active' && langKey == 'US'"
          @click="goActive"
        >
          {{ t("login.login21") }}
        </div>
        <el-divider
          v-if="loginStep == 'login' && ssoType == true"
          content-position="center"
          class="h1"
          >{{ t("login.login22") }}
        </el-divider>
        <el-button
          v-if="loginStep == 'login' && ssoType == true"
          class="ssoDiv"
          @click="openSso"
        >
          <img :src="icon.sso" alt="" class="sso" />
          {{ appName != "LINMA" ? t("login.login3") : t("login.login89") }}
        </el-button>
      </div>
    </div>
    <!-- 免责 -->
    <el-dialog
      :z-index="100"
      v-model="disclaimerType"
      :title="$t('login.login20')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="htmlTitle">{{ $t("login.login29") }}</div>
      <div class="disclaimerDiv" v-html="disclaimer"></div>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="sureBtn" @click="disclaimerType = false">
            {{ $t("login.login30") }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 切换语言选项 -->
    <el-dropdown placement="bottom-start">
      <div class="headerItem">
        <img :src="icon.langTx" class="langTx" alt="" />
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="item in langList"
            :key="item.key"
            @click="changeDrop(item.key)"
          >
            <div class="messageCenter">
              <img class="langImg" :src="item.icon" alt="" />
              <div class="messageYzText">{{ t(item.name) }}</div>
              <img
                :src="icon.langActive"
                class="langActive"
                alt=""
                v-if="item.key == langKey"
              />
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <img :src="icon.bgLogin" alt="" class="bgLogin" />
    <!-- 冲突提醒 -->
    <conflictView
      :conflictViewObj="conflictViewObj"
      :companyName="companyName"
      v-if="conflictViewType"
      @closeView="closeView"
      @goLogin="goLogin"
    ></conflictView>
  </div>
</template>

<script setup>
import conflictView from "./conflict.vue";
import { onMounted, ref, watch } from "vue";
import SHA256 from "crypto-js/sha256";
import api from "@/api";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { decryptApi } from "@/utils/crypto";
import { icon } from "@/utils/icon";
const { locale, t } = useI18n();
const store = useStore();
const router = useRouter();
const companyName = ref(""); //公司名称
const companyStatus = ref(false); //公司状态
const disclaimer = ref(""); //免责公告
const disclaimerType = ref(false); //免责弹窗
const userNameType = ref(false);
const passwordType = ref(false);
const userName = ref(""); //账号
const userPassword = ref(""); //密码
const checked = ref(true); //免责
const loginLoading = ref(false); //登录loading
const loginStep = ref("login");
const passwordStep = ref(false); //找回密码验证码页面
const time = ref(60);
const timer = ref(null);
const sendType = ref(false);
const surePassword = ref(""); //确认密码
const activationCode = ref(""); //验证码
const codeLoading = ref(false); //验证码loading
const langKey = ref(store.state.lang);
const langList = ref([
  {
    name: "header.header1",
    key: "CN",
    icon: icon.chinaImg,
  },
  {
    name: "header.header2",
    key: "HK",
    icon: icon.chinaHkImg,
  },
  {
    name: "header.header3",
    key: "US",
    icon: icon.usaImg,
  },
]);
const imgUrl = ref(icon.qcImg);
const ssoType = ref(false);
const ssoUrl = ref("");
const conflictViewType = ref(false);
const conflictViewObj = ref(null);
const channelType = ref(false);
const appName = ref("QC"); //默认qc
onMounted(() => {
  appName.value = process.env.VUE_APP_NAME;
  console.log(appName.value);
  if (process.env.VUE_APP_CHANNEL === "NO") {
    channelType.value = false;
  } else {
    channelType.value = true;
  }
  store.commit("CLEAR_TOKEN");
  // 沁城
  if (appName.value == "QC") {
    let queryString = window.location.search;
    let dataParam = new URLSearchParams(queryString).get("data");
    if (dataParam) {
      let decodedData = JSON.parse(decryptApi(decodeURIComponent(dataParam)));
      if (decodedData.returnCode == 200) {
        store.commit("SET_USERINFO", decodedData.data);
        message.success(t("login.login28"));
        router.push("/index");
      } else {
        message.warning(decodedData.returnMessage);
      }
    }
    getSso();
  }
  // suiyuan or linma
  if (appName.value != "QC") {
    // 判断url是否有code
    const url = window.location.href;
    // 创建一个URL对象
    const urlObj = new URL(url);
    // 使用URLSearchParams获取code的值
    const searchParams = new URLSearchParams(urlObj.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    if (code) {
      store.commit("SET_SSO", {
        state: state,
        code: code,
      });
      api.login
        .postCode({
          code: code,
          state: state,
        })
        .then((res) => {
          if (res.returnCode == 200) {
            loginLoading.value = false;
            store.commit("SET_USERINFO", res.data);
            message.success(t("login.login28"));
            router.push("/index");
          } else if (res.returnCode == 201) {
            loginLoading.value = false;
            conflictViewObj.value = res.data;
            conflictViewType.value = true;
          } else {
            loginLoading.value = false;
          }
        });
    }
    // suiyuan 需要sso linma扫码
    if (appName.value == "SUIYUAN") {
      getSso();
    } else {
      ssoType.value = true;
    }
  }
  getLogo();
  getName();
  getDisclaimer();
  getModelList();
});
watch(
  () => time.value,
  (newValue, oldValue) => {
    if (newValue <= 0) {
      sendType.value = false;
      time.value = 60;
      clearInterval(timer.value);
    }
  }
);
// 关闭
const closeView = () => {
  conflictViewType.value = false;
};
// 判断是否有sso
const getSso = () => {
  api.manage.ssoGet().then((res) => {
    if (res.returnCode == 200) {
      ssoType.value = res.data;
      if (ssoType.value == true) {
        getConfig();
      }
    }
  });
};
// 获取参数
const getConfig = () => {
  api.manage.ssoGetConfig().then((res) => {
    if (res.returnCode == 200) {
      ssoUrl.value = res.data.idp_config.adloginAddr;
    }
  });
};
// 获取公司logo
const getLogo = () => {
  api.manage.getLogoApi().then((res) => {
    if (res.returnCode) {
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          // 创建 Blob 对象
          const blob = new Blob([data], { type: "image/png" });
          // 生成 URL
          imgUrl.value = URL.createObjectURL(blob);
        })
        .catch((error) => (imgUrl.value = icon.qcImg));
    }
  });
};
// 点击切换语言
const changeDrop = (e) => {
  langKey.value = e;
  locale.value = e;
  store.commit("SET_LANG", e);
};
// 发送验证码
const sendCode = () => {
  if (!userName.value) {
    message.warning(t("login.login7"));
    return;
  }
  codeLoading.value = true;
  let obj = {
    userName: userName.value,
    step: 1,
  };
  sendType.value = false;
  time.value = 60;
  api.login
    .postforgotPasswd(obj)
    .then((res) => {
      codeLoading.value = false;
      if (res.returnCode == 200) {
        passwordStep.value = true;
        sendType.value = true;
        timer.value = setInterval(() => {
          setTimeout(() => {
            time.value--;
            if (time.value <= 0) {
              sendType.value = false;
              time.value = 60;
              clearInterval(timer.value);
            }
          }, 0);
        }, 3000);
        message.success(t("login.login23"));
      }
    })
    .catch((err) => {
      codeLoading.value = false;
    });
};
// 重置密码
const savePassword = () => {
  if (userPassword.value.length < 6) {
    message.warning(t("login.login24"));
    return;
  }
  if (userPassword.value != surePassword.value) {
    message.warning(t("login.login25"));
    return;
  }
  loginLoading.value = true;
  let data = {
    userName: userName.value,
    step: 2,
    activationCode: activationCode.value,
    password: SHA256(userPassword.value).toString(),
  };
  api.login.postforgotPasswd(data).then((res) => {
    loginLoading.value = false;
    if (res.returnCode == 200) {
      message.success(t("login.login26"));
      passwordStep.value = false;
      loginStep.value = "login";
    }
  });
};
// 前往激活
const goActive = () => {
  userName.value = "";
  activationCode.value = "";
  userPassword.value = "";
  loginStep.value = "active";
};
// 激活
const saveActive = () => {
  if (!userName.value) {
    message.warning(t("login.login7"));
    return;
  }
  if (!userName.value) {
    message.warning(t("login.login8"));
    return;
  }
  if (!userName.value) {
    message.warning(t("login.login18"));
    return;
  }
  loginLoading.value = true;
  let data = {
    userName: userName.value,
    userPassword: SHA256(userPassword.value).toString(),
    activationCode: activationCode.value,
  };
  api.login.activation(data).then((res) => {
    if (res.returnCode == 200) {
      loginLoading.value = false;
      store.commit("SET_USERINFO", res.data);
      message.success(t("login.login27"));
      router.push("/index");
    }
  });
};
// 模型列表
const getModelList = () => {
  api.chat.modelDict().then((res) => {
    if (res.returnCode == 200) {
      const arr = Object.entries(res.data).map(([label, value]) => ({
        label,
        value,
      }));
      store.commit("SET_MODEL", arr);
    }
  });
};
// 获取公司名称
const getName = () => {
  api.login.getCompanyName().then((res) => {
    if (res.returnCode == 200) {
      companyStatus.value = res.companyStatus;
      companyName.value = res.companyName;
    }
  });
};
// 获取免责公告
const getDisclaimer = () => {
  api.login.getDisclaimer().then((res) => {
    if (res.returnCode == 200) {
      disclaimer.value = res.data;
    }
  });
};
// 打开找回密码
const forGet = () => {
  passwordStep.value = false;
  time.value = 60;
  timer.value = null;
  sendType.value = false;
  surePassword.value = "";
  activationCode.value = "";
  codeLoading.value = false;
  userPassword.value = "";
  loginStep.value = "password";
};
// 返回
const goBack = () => {
  if (passwordStep.value) {
    passwordStep.value = false;
  } else {
    loginStep.value = "login";
  }
};
// 切换至sso
const openSso = () => {
  if (appName.value != "LINMA") {
    if (ssoUrl.value == "") {
      message.warning(t("login.login62"));
      return;
    }
    window.open(ssoUrl.value);
  } else {
    userPassword.value = "";
    loginStep.value = "sso";
  }
};
// 登录
const goLogin = () => {
  if (!userName.value) {
    message.warning(t("login.login7"));
    return;
  }
  if (!userPassword.value) {
    message.warning(t("login.login8"));
    return;
  }
  if (!checked.value) {
    message.warning(t("login.login31"));
    return;
  }
  conflictViewType.value = false;
  let obj = {
    userName: userName.value,
    userPassword: SHA256(userPassword.value).toString(),
  };
  loginLoading.value = true;
  api.login
    .goLogin(obj)
    .then((res) => {
      if (res.returnCode == 200) {
        loginLoading.value = false;
        store.commit("SET_USERINFO", res.data);
        message.success(t("login.login28"));
        router.push("/index");
      } else if (res.returnCode == 201) {
        loginLoading.value = false;
        conflictViewObj.value = res.data;
        conflictViewType.value = true;
      } else {
        loginLoading.value = false;
      }
    })
    .catch((err) => {
      loginLoading.value = false;
    });
};
// 打开免责
const openDisclaimer = () => {
  disclaimerType.value = true;
};
</script>

<style lang="scss" scoped>
.loginDiv {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f7f8ff;
  overflow: hidden;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.loginContent {
  width: 1355px;
  height: 640px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: space-between;
}
.loginContentChannel {
  width: 1355px;
  height: 640px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: flex-end;
}
.loginImg {
  width: 256px;
  height: 100px;
  margin-top: 97px;
  margin-left: 68px;
  margin-bottom: 64px;
}
.leftBigDiv {
  background-color: #edeef6;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  align-items: center;
}
.qcImg {
  width: 85px;
  height: 85px;
  margin-right: 12px;
}
.zhDiv {
  background-color: #dbdfff;
  border-radius: 16px;
  padding: 3px 9px;
  font-size: 18px;
  color: #0256ff;
  font-weight: 400;
  text-align: center;
  display: inline-block;
}
.compyName {
  font-size: 24px;
  color: #000000;
  font-weight: 500;
  margin-top: 6px;
}
.flexLogin {
  display: flex;
  flex-direction: column;
}
.rightLogin {
  width: 412px;
  height: calc(100% - 60px);
  padding: 30px;
  background-color: #fefefe;
  border-radius: 30px;
  position: relative;
}
.slogo {
  width: 31px;
  margin-right: 12px;
}
.loginTitle {
  font-size: 26px;
  font-weight: 600;
  color: #3d3d3d;
}
.titleDiv {
  padding: 30px 0px 0px 20px;
  margin-bottom: 60px;
}
:deep(.el-input__wrapper) {
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  padding: 12px 20px;
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 16px;
  margin-bottom: 24px;
}
.loginBtn {
  width: 100%;
  height: 48px;
  border: 2px solid #3376ff;
  border-radius: 16px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}
.tipSpan {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
}
.tipSpan1 {
  font-size: 18px;
  color: #3376ff;
  font-weight: 400;
  cursor: pointer;
  border-bottom: 1px solid #3376ff;
  margin-left: 5px;
}
.mzDiv {
  height: 25px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mzDivChannel {
  height: 25px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.zhmm {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  cursor: pointer;
}
.sso {
  width: 21px;
  margin-right: 8px;
}
.ssoDiv {
  width: 100%;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  height: 48px;
  background-color: #fefefe;
  border-radius: 16px;
  color: #0256ff;
  font-weight: 400;
  font-size: 18px;
}
:deep(.el-divider--horizontal) {
  margin: 110px 0px 38px 0px;
  border-top: 1px solid #edeef6;
}
:deep(.el-divider__text) {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
}
:deep(.el-divider__text.is-center) {
  width: 175px;
  text-align: center;
}
.disclaimerDiv {
  color: #000000;
  font-weight: 400;
  max-height: 500px;
  overflow: auto;
}
.htmlTitle {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  color: #000000;
}
:deep(.el-dialog) {
  width: 800px;
}
.bgLogin {
  height: 100%;
}
.goBack {
  position: absolute;
  right: 30px;
  top: 53px;
  height: 48px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 18px;
  color: #3376ff;
  font-weight: 400;
}
.sendDiv {
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 2px solid #edeef6;
  padding: 12px 20px;
  height: 20px;
  margin-bottom: 24px;
}
.sendInput {
  :deep(.el-input__wrapper) {
    box-shadow: 0px 0px 0px;
    border: 0px;
    font-size: 18px;
    font-weight: 400;
    padding: 0px;
    margin: 0;
    height: 100%;
  }
}
.sendBtn {
  color: #3376ff;
  font-size: 18px;
  font-weight: 400;
  padding: 0px;
  width: 100px;
}
.headerItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #edeef6;
  border-radius: 16px;
  cursor: pointer;
  position: fixed;
  right: 26px;
  top: 26px;
}
.langTx {
  width: 21px;
}
.messageCenter {
  display: flex;
  align-items: center;
}
.langImg {
  width: 21px;
  margin-right: 7px;
}
.messageYzText {
  color: #3d3d3d;
  font-weight: 600;
  font-size: 18px;
}
.langActive {
  width: 16px;
  margin-left: 11px;
}
.passwordRight {
  text-align: right;
  margin-top: 10px;
}
.sureBtn {
  background-color: #3376ff;
  border: 2px solid #3376ff;
  border-radius: 8px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}
:deep(.el-dialog__title) {
  font-size: 20px;
  font-weight: 500;
  color: #3d3d3d;
}
.flexLinma {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
