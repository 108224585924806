<script setup>
import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import api from "@/api/index";
import { getTime } from "@/utils/utils";
import { icon } from "@/utils/icon";
const store = useStore();
const userId = ref(store.state.userId);
const deleteType = ref(false);
const { t } = useI18n();
const loading = ref(false);
const valueInput = ref("");
onMounted(() => {
  getList();
});
const status = ref("userNamePattern");
const search = ref([
  {
    value: "userNamePattern",
    label: t("manage.botForm.bot1"),
  },
  {
    value: "templateNamePattern",
    label: t("manage.botForm.bot2"),
  },
]);

// 打开回收站
const openDelete = () => {
  deleteType.value = true;
  getList2();
};
// 关闭回收站
const closeDelete = () => {
  getList();
  deleteType.value = false;
};
// 用户表格
const tableMember = ref([]);
const totalItems = ref(0);
// 分页
const pageSize = ref(20);
const currentPage = ref(1);
// 每页显示条数
const updatePageSize = (newSize) => {
  pageSize.value = newSize;
};
// 更新当前页码
const updateCurrentPage = (newPage) => {
  currentPage.value = newPage;
};
watch(
  () => pageSize.value,
  (newVal) => {
    formList.value.perPage = newVal;
    getList();
  }
);
watch(
  () => currentPage.value,
  (newVal) => {
    formList.value.pageId = newVal;
    getList();
  }
);
const formList = ref({
  userId: userId.value,
  pageId: currentPage.value,
  perPage: pageSize.value,
  userNamePattern: "",
  templateNamePattern: "",
  operationType: 0,
});
watch(
  () => valueInput.value,
  (newVal) => {
    if (status.value == "userNamePattern") {
      formList.value.userNamePattern = newVal;
      formList.value.templateNamePattern = "";
    } else if (status.value == "templateNamePattern") {
      formList.value.userNamePattern = "";
      formList.value.templateNamePattern = newVal;
    }
    getList();
  }
);

const getList = () => {
  loading.value = true;
  api.manage.getAgentListApi(formList.value).then((res) => {
    if (res.returnCode == 200) {
      tableMember.value = res.data;
      totalItems.value = res.totalNum;
      loading.value = false;
    }
  });
};
// 多选
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
};
// 多选
const multipleSelection2 = ref([]);
const handleSelectionChange2 = (val) => {
  multipleSelection2.value = val;
};
const returnForm = ref({
  userId: userId.value,
  operationType: 0,
  templateIds: [],
});
// 归档
const returnBtn = (row) => {
  returnForm.value.templateIds = [row.templateId];
  api.manage.getAgentArchiveApi(returnForm.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.botForm.bot3"));
      getList();
    }
  });
};
const returnForm1 = ref({
  userId: userId.value,
  operationType: 1,
  templateIds: [],
});
// 删除
const deleteBtn = (row) => {
  returnForm1.value.templateIds = [row.templateId];
  api.manage.getAgentArchiveApi(returnForm1.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.botForm.bot4"));
      getList();
      getList2();
    }
  });
};
// 批量归档
const allReturn = () => {
  if (multipleSelection.value.length == 0) {
    message.error(t("manage.botForm.bot5"));
    return;
  }
  returnForm.value.templateIds = [];
  multipleSelection.value.forEach((item) => {
    returnForm.value.templateIds.push(item.templateId);
  });
  api.manage.getAgentArchiveApi(returnForm.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.botForm.bot6"));
      getList();
    }
  });
};
// 批量删除
const allDelete = (num) => {
  returnForm1.value.templateIds = [];
  if (num == 1) {
    if (multipleSelection.value.length == 0) {
      message.error(t("manage.botForm.bot7"));
      return;
    }
    multipleSelection.value.forEach((item) => {
      returnForm1.value.templateIds.push(item.templateId);
    });
  } else {
    if (multipleSelection2.value.length == 0) {
      message.error(t("manage.botForm.bot7"));
      return;
    }
    multipleSelection2.value.forEach((item) => {
      returnForm1.value.templateIds.push(item.templateId);
    });
  }
  api.manage.getAgentArchiveApi(returnForm1.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.botForm.bot4"));
      getList();
      getList2();
    }
  });
};
// ===== 回收站=====
const status2 = ref("userNamePattern");
const valueInput2 = ref("");
const tableMember2 = ref([]);
const totalItems2 = ref(0);
// 分页
const pageSize2 = ref(20);
const currentPage2 = ref(1);
const updatePageSize2 = (newSize) => {
  pageSize2.value = newSize;
};
const updateCurrentPage2 = (newPage) => {
  currentPage2.value = newPage;
};
watch(
  () => pageSize2.value,
  (newVal) => {
    formList2.value.perPage = newVal;
    getList2();
  }
);
watch(
  () => currentPage2.value,
  (newVal) => {
    formList2.value.pageId = newVal;
    getList2();
  }
);
const formList2 = ref({
  userId: userId.value,
  pageId: currentPage2.value,
  perPage: pageSize2.value,
  userNamePattern: "",
  templateNamePattern: "",
  operationType: 1,
});
watch(
  () => valueInput2.value,
  (newVal) => {
    if (status.value == "userNamePattern") {
      formList2.value.userNamePattern = newVal;
      formList2.value.templateNamePattern = "";
    } else if (status.value == "templateNamePattern") {
      formList2.value.userNamePattern = "";
      formList2.value.templateNamePattern = newVal;
    }
    getList2();
  }
);

const getList2 = () => {
  api.manage.getAgentListApi(formList2.value).then((res) => {
    if (res.returnCode == 200) {
      tableMember2.value = res.data;
      totalItems2.value = res.totalNum;
    }
  });
};
// 恢复
const returnForm3 = ref({
  userId: userId.value,
  operationType: 2,
  templateIds: [],
});
const restoreBtn = (row) => {
  returnForm3.value.templateIds = [row.templateId];
  api.manage.getAgentArchiveApi(returnForm3.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.botForm.bot8"));
      getList2();
    }
  });
};
// 批量恢复
const allRestore = () => {
  if (multipleSelection2.value.length == 0) {
    message.error(t("manage.botForm.bot9"));
    return;
  }
  returnForm3.value.templateIds = [];
  multipleSelection2.value.forEach((item) => {
    returnForm3.value.templateIds.push(item.templateId);
  });
  api.manage.getAgentArchiveApi(returnForm3.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.botForm.bot8"));
      getList2();
    }
  });
};
</script>

<template>
  <div class="main" v-loading="loading">
    <!-- 列表 -->
    <div v-if="!deleteType" class="hei100">
      <div class="menuBox">
        <div style="display: flex">
          <!-- 用户 -->
          <div class="User">
            <div class="searchDiv">
              <el-select v-model="status">
                <el-option
                  v-for="(item, index) in search"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                  <span class="dropText">{{ item.label }}</span>
                </el-option>
              </el-select>
              <el-input
                v-model="valueInput"
                :placeholder="$t('manage.topicForm.topic8')"
              ></el-input>
            </div>
          </div>
        </div>
        <!-- 功能区 -->
        <div style="display: flex">
          <el-button
            class="toolBox"
            @click="allReturn"
            :disabled="$hasPermission('10045') == false"
          >
            {{ t("manage.topicForm.topic13") }}
          </el-button>
          <el-button
            class="toolBox"
            @click="allDelete(1)"
            :disabled="$hasPermission('10046') == false"
          >
            {{ t("manage.topicForm.topic14") }}
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableMember"
        @selection-change="handleSelectionChange"
        class="tableHeight"
      >
        <el-table-column type="selection" />
        <el-table-column
          prop="templateOwner"
          :label="t('manage.botForm.bot10')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="scope.row.templateOwner"
            >
              <div class="wid100">{{ scope.row.templateOwner }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="templateName"
          :label="t('manage.botForm.bot2')"
        />
        <el-table-column
          prop="userNum"
          :label="t('manage.botForm.bot11')"
          width="80"
        />
        <el-table-column :label="t('manage.botForm.bot12')" width="120">
          <template #default>
            <span>{{ t("manage.botForm.bot13") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sessionNum"
          :label="t('manage.botForm.bot14')"
          width="80"
        />
        <el-table-column prop="createTime" :label="t('manage.botForm.bot15')">
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="getTime(scope.row.createTime)"
            >
              <div class="wid100">{{ getTime(scope.row.createTime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="t('manage.botForm.bot16')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="getTime(scope.row.lastUpdateTime)"
            >
              <div class="wid100">{{ getTime(scope.row.lastUpdateTime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="t('manage.botForm.bot17')" class="operation">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="returnBtn(scope.row)"
              :disabled="$hasPermission('10045') == false"
            >
              {{ t("manage.botForm.bot18") }}
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              @click="deleteBtn(scope.row)"
              :disabled="$hasPermission('10046') == false"
            >
              {{ t("manage.botForm.bot19") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="deleteDiv" @click="openDelete">
          <img :src="icon.hszImg" alt="" class="deleteImg" />
          {{ t("manage.botForm.bot20") }}
        </div>
        <div>
          <Pagination
            :background="true"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :total="totalItems"
            @size-change="updatePageSize"
            @current-change="updateCurrentPage"
          />
        </div>
      </div>
    </div>
    <!-- 归档列表 -->
    <div v-if="deleteType" class="hei100">
      <div class="menuBox">
        <div style="display: flex">
          <!-- 用户 -->
          <div class="User">
            <div class="searchDiv">
              <el-select v-model="status2">
                <el-option
                  v-for="(item, index) in search"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                  <span class="dropText">{{ item.label }}</span>
                </el-option>
              </el-select>
              <el-input
                v-model="valueInput2"
                :placeholder="$t('manage.topicForm.topic8')"
              ></el-input>
            </div>
          </div>
        </div>
        <!-- 功能区 -->
        <div style="display: flex">
          <el-button
            class="toolBox"
            @click="allRestore"
            :disabled="$hasPermission('10045') == false"
          >
            {{ t("manage.topicForm.topic25") }}
          </el-button>
          <el-button
            class="toolBox"
            @click="allDelete(2)"
            :disabled="$hasPermission('10046') == false"
          >
            {{ t("manage.topicForm.topic14") }}
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableMember2"
        @selection-change="handleSelectionChange2"
        class="tableHeight"
      >
        <el-table-column type="selection" />
        <el-table-column
          prop="templateOwner"
          :label="t('manage.botForm.bot10')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="scope.row.templateOwner"
            >
              <div class="wid100">{{ scope.row.templateOwner }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="templateName"
          :label="t('manage.botForm.bot2')"
        />
        <el-table-column
          prop="userNum"
          :label="t('manage.botForm.bot11')"
          width="80"
        />
        <el-table-column :label="t('manage.botForm.bot12')" width="120">
          <template #default>
            <span>{{ t("manage.botForm.bot13") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sessionNum"
          :label="t('manage.botForm.bot14')"
          width="80"
        />
        <el-table-column prop="createTime" :label="t('manage.botForm.bot15')">
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="getTime(scope.row.createTime)"
            >
              <div class="wid100">{{ getTime(scope.row.createTime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="t('manage.botForm.bot16')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="getTime(scope.row.lastUpdateTime)"
            >
              <div class="wid100">{{ getTime(scope.row.lastUpdateTime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="t('manage.botForm.bot17')" class="operation">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="restoreBtn(scope.row)"
              :disabled="$hasPermission('10045') == false"
            >
              {{ t("manage.botForm.bot21") }}
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              @click="deleteBtn(scope.row)"
              :disabled="$hasPermission('10046') == false"
            >
              {{ t("manage.botForm.bot19") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="gobackDiv" @click="closeDelete">
          <img :src="icon.goBackImgLook" alt="" class="deleteImg" />
          {{ t("manage.botForm.bot22") }}
        </div>
        <div>
          <Pagination
            :background="true"
            :currentPage="currentPage2"
            :pageSize="pageSize2"
            :total="totalItems2"
            @size-change="updatePageSize2"
            @current-change="updateCurrentPage2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.hei100 {
  height: 100%;
}
.menuBox {
  :deep(.el-input__wrapper) {
    border-radius: 0 16px 16px 0;
    border-right: none !important;
  }

  :deep(.el-input) {
    --el-input-height: 42px !important;
  }
}

.searchDiv {
  :deep(.el-select) {
    width: 80px;
  }

  :deep(.el-select__wrapper) {
    width: 80px;
    padding: 0 6px;
    height: 42px !important;
    border-radius: 16px 0 0 16px !important;
    background-color: #f7f8ff;
    color: #3d3d3d;
    font-weight: 400;
  }
}

:deep(.el-input) {
  --el-input-height: 42px !important;
}

:deep(.el-date-editor.el-input__wrapper) {
  height: 42px !important;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

.main {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

.time {
  padding: 0 10px;
  background-color: #f7f8ff;
  border-radius: 16px 0 0 16px;
  height: 42px;
  color: #a8abb2;
  font-size: 14px;
  line-height: 42px;
  border: 1px solid #dcdfe6;
  border-right: none;
}

.footer {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;

  .deleteDiv {
    height: 34px;
    border-radius: 12px;
    border: 1px solid #f7f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f02d63;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 10px;

    .deleteImg {
      width: 25px;
      margin-right: 3px;
    }
  }

  .gobackDiv {
    height: 34px;
    border-radius: 12px;
    border: 1px solid #f7f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8a8a8a;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 10px;

    .deleteImg {
      width: 25px;
      margin-right: 3px;
    }
  }
}

.menuBox {
  position: relative;
  display: flex;
  justify-content: space-between;

  .User {
    height: 42px;
    line-height: 42px;
    border-radius: 16px;
    color: #3d3d3d;
    font-size: 17px;
    margin-right: 10px;

    .searchDiv {
      display: flex;
      color: #3d3d3d;

      .searchSpan {
        color: #3d3d3d;
        font-size: 16px;
        display: flex;

        .text {
          min-width: 34px;
          white-space: nowrap;
        }
      }

      .searchIcon {
        margin-left: 3px;
      }
    }
  }

  .date {
    height: 42px;
    line-height: 42px;
    border: 2px solid #f7f8ff;
    border-radius: 16px;
    font-size: 18px;
    color: #8a8a8a;
    display: flex;
    font-size: 17px;
    text-align: center;
    padding: 0 0 0 10px;
    margin-right: 16px;

    div:first-child {
      margin-right: 20px;
      color: #8a8a8a;
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .searchDiv {
      margin-left: 10px;
      color: #3d3d3d;

      .searchSpan {
        color: #3d3d3d;
        font-size: 16px;
        width: 100%;
        display: flex;
        margin-top: 10px;
      }

      .searchIcon {
        margin-left: 10px;
      }
    }
  }
}

.toolBox {
  border: #f7f8ff solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  height: 42px;
  line-height: 40px;
  color: #f02d63;
  margin-right: 10px;
  font-weight: 400;
}

.tableHeight {
  height: calc(100% - 106px);
  margin-top: 20px;
}

.footer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;

  .deleteDiv {
    height: 34px;
    border-radius: 12px;
    border: 1px solid #f7f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f02d63;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 10px;

    .deleteImg {
      width: 25px;
      margin-right: 3px;
    }
  }

  .gobackDiv {
    height: 34px;
    border-radius: 12px;
    border: 1px solid #f7f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8a8a8a;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 10px;

    .deleteImg {
      width: 25px;
      margin-right: 3px;
    }
  }
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
</style>
