<template>
  <div class="talkViewDiv">
    <div class="hisBetween topTalk">
      <div class="hisCenter">
        <div class="stepBtn">{{ t("base.base320") }}</div>
        <el-tooltip
          popper-class="toolTip"
          :content="props.sessionInfo.sessionName"
        >
          <div class="talkTitle">
            {{
              props.sessionInfo.sessionName.length > 16
                ? props.sessionInfo.sessionName.slice(0, 16) + "..."
                : props.sessionInfo.sessionName
            }}
          </div>
        </el-tooltip>
        <img :src="icon.topTime" alt="" class="itemTime" />
        <div class="itemFont20s">
          {{ getYear(props.sessionInfo.sessionCreateTime) }}
        </div>
        <img
          :src="icon.topYes"
          alt=""
          class="itemTime"
          v-if="props.sessionInfo.isValid == 1"
        />
        <div class="itemFont20s" v-if="props.sessionInfo.isValid == 1">
          {{
            props.sessionInfo.expireDate == null
              ? t("base.base176")
              : getYear(props.sessionInfo.expireDate)
          }}
        </div>
        <img
          :src="icon.hotPngHeader"
          alt=""
          class="wid16"
          v-if="
            props.sessionInfo.result != 0 && props.sessionInfo.result != null
          "
        />
        <div
          class="topicItem"
          v-if="
            props.sessionInfo.result != 0 && props.sessionInfo.result != null
          "
        >
          {{ props.sessionInfo.result }}
        </div>
      </div>
      <img
        :src="icon.talkCloseSvg"
        class="talkCloseSvg"
        alt=""
        @click="closeTalk"
      />
    </div>
    <div class="talkQaDiv">
      <qaIndex
        v-for="(item, index) in props.talkList"
        :key="index"
        type="chat"
        :copyType="false"
        :item="item"
        :ifThink="false"
        @openFileView="talkOpenFile"
        @openTalk="openTalk"
        @openResultDialog="openResultDialog"
      >
      </qaIndex>
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { getYear } from "@/utils/utils";
import qaIndex from "@/components/qa/index.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["sessionInfo", "talkList"]);
const emit = defineEmits([
  "closeTalk",
  "openTalk",
  "talkOpenFile",
  "openResultDialog",
]);
// 打开查询结果
const openResultDialog = (message) => {
  emit("openResultDialog", message);
};
const closeTalk = () => {
  emit("closeTalk");
};
const openTalk = (item) => {
  emit("openTalk", item);
};
const talkOpenFile = (item, list) => {
  emit("talkOpenFile", item, list);
};
</script>

<style lang="scss" scoped>
.talkViewDiv {
  width: calc(100% - 607px);
  height: calc(100% - 60px);
  border-radius: 30px;
  overflow: auto;
  background-color: #fefefe;
  padding: 30px;
}
.hisBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topTalk {
  height: 40px;
  margin-bottom: 20px;
}

.hisCenter {
  display: flex;
  align-items: center;
}
.stepBtn {
  background-color: #767bfa;
  height: 31px;
  text-align: center;
  line-height: 31px;
  border-radius: 30px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 15px;
}
.talkTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 600;
  margin: 0px 12px;
}
.itemTime {
  width: 18px;
  margin: 0px 10px 0px 0px;
}
.itemFont20s {
  font-size: 18px;
  font-weight: 400;
  color: #8a8a8a;
  margin-right: 15px;
  max-width: 185px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid16 {
  width: 16px;
}
.topicItem {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  margin: 0px 12px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.talkCloseSvg {
  width: 36px;
  cursor: pointer;
}

.talkQaDiv {
  height: calc(100% - 60px);
  overflow: auto;
}
</style>
