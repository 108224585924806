<template>
  <div :class="props.type == 'chat' ? 'plugBigDivChat' : 'plugBigDiv'">
    <!-- header -->
    <div class="flexBetween">
      <div class="flexCenter">
        <img :src="icon.plugBs" alt="" class="plugBs" />
        <div class="plugTitle">
          {{ t("gpt.index56") }}{{ props.pluginInfo.length
          }}{{ t("gpt.index57") }}
        </div>
      </div>
      <div class="flexCenter">
        <div
          class="plugTip"
          v-if="
            props.pluginInfo[0].thinkSumTime && props.pluginInfo[0].toolSumTime
          "
        >
          {{
            getTime(
              getTime(props.pluginInfo[0].thinkSumTime) +
                getTime(props.pluginInfo[0].toolSumTime)
            )
          }}s： LLM{{ getTime(props.pluginInfo[0].thinkSumTime) }}s |
          {{ t("gpt.index58") }} {{ getTime(props.pluginInfo[0].toolSumTime) }}s
        </div>
        <img
          class="reSonUpFileImg"
          :src="icon.upReson"
          alt=""
          v-if="resonOneType"
          @click="resonOneType = !resonOneType"
        />
        <img
          class="reSonUpFileImg"
          :src="icon.downReson"
          alt=""
          v-else
          @click="resonOneType = !resonOneType"
        />
      </div>
    </div>
    <!-- 内容 -->
    <el-collapse-transition>
      <div v-show="resonOneType">
        <div
          :class="props.type == 'chat' ? 'itemDivChat' : 'itemDiv'"
          v-for="(item, index) in props.pluginInfo"
          :key="index"
        >
          <!-- 内容头 -->
          <div class="flexBetween curpor" @click="changePlug(index)">
            <div class="flexCenter wid20">
              <img :src="icon.plugOption" class="plugOption" alt="" />
              <el-tooltip popper-class="toolTip" :content="item.functionName">
                <div class="innerTitle wid32">{{ item.functionName }}</div>
              </el-tooltip>
            </div>
            <div class="plugTip">
              {{ getTime(getTime(item.thinkTime) + getTime(item.toolTime)) }}s：
              LLM{{ getTime(item.thinkTime) }}s | {{ t("gpt.index59") }}
              {{ getTime(item.toolTime) }}s
            </div>
          </div>
          <el-collapse-transition>
            <div v-show="plugIndex == index">
              <!-- 请求体 -->
              <div class="flexCenter">
                <div class="resHeader">{{ t("gpt.index60") }}</div>
              </div>
              <JsonViewer
                :value="getJson(item.functionParameter)"
                :expand-depth="1"
                sort
                theme="my-json-theme"
                class="jsonDiv"
              ></JsonViewer>
              <!-- 响应体 -->
              <div class="flexCenter">
                <div class="resBody">{{ t("gpt.index61") }}</div>
              </div>
              <JsonViewer
                :value="getJson(item.functionResponse)"
                :expand-depth="1"
                sort
                theme="my-json-theme"
                class="jsonDiv"
              ></JsonViewer>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { computed, ref } from "vue";
import { JsonViewer } from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["type", "pluginInfo"]);
const resonOneType = ref(true);
const plugIndex = ref(null);
const changePlug = (index) => {
  if (plugIndex.value == index) {
    plugIndex.value = null;
    return;
  }
  plugIndex.value = index;
};
const getJson = (item) => {
  if (item) {
    return JSON.parse(item);
  }
  return {}
};
const getTime = (time) => {
  return Math.trunc(time * 100) / 100;
};
</script>

<style lang="scss" scoped>
.wid20 {
  width: calc(100% - 250px);
}
.wid32 {
  width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.jsonDiv {
  background-color: #f6f6f6;
  border-radius: 8px;
  border: 1px solid #edeef6;
  margin-bottom: 10px;
  font-size: 15px !important;
  color: #696969;
  font-weight: 500;
  padding: 12px 10px;
  :deep(.jv-code) {
    padding: 0px;
  }
}
.resHeader {
  background-color: rgba(255, 195, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  color: #ffc300;
  font-weight: 500;
  padding: 2px 8px;
  margin-top: 10px;
  margin-bottom: 6px;
}
.resBody {
  background-color: rgba(40, 196, 69, 0.2);
  border-radius: 4px;
  font-size: 14px;
  color: #28c445;
  font-weight: 500;
  padding: 2px 8px;
  margin-top: 10px;
  margin-bottom: 6px;
}
.itemDiv {
  border: 1px solid #edeef6;
  border-radius: 12px;
  margin-top: 12px;
  box-shadow: 1px 2px 4px 0px rgba(204, 206, 229, 0.25);
  padding: 12px;
}
.itemDivChat {
  background-color: #feffff;
  border: 1px solid #edeef6;
  border-radius: 12px;
  margin-top: 12px;
  box-shadow: 1px 2px 4px 0px rgba(204, 206, 229, 0.25);
  padding: 12px;
}
.innerTitle {
  font-size: 16px;
  font-weight: 500;
  color: #3d3d3d;
}
.plugOption {
  width: 20px;
  margin-right: 12px;
}
.plugBigDiv {
  background-color: #fefefe;
  border-radius: 28px;
  padding: 17px 15px;
  margin-bottom: 24px;
}
.plugBigDivChat {
  background-color: #f7f8ff;
  border-radius: 28px;
  padding: 17px 15px;
  margin-bottom: 24px;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.plugBs {
  width: 20px;
  margin-right: 12px;
}
.reSonUpFileImg {
  width: 24px;
  margin-left: 12px;
  cursor: pointer;
}
.plugTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
}
.plugTip {
  font-size: 14px;
  color: #696969;
  font-weight: 500;
}
.curpor {
  cursor: pointer;
}
</style>
