<script setup>
import { computed, onMounted, ref, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { icon } from "@/utils/icon";
import uploadIndex from "@/components/upload/index.vue";
import testView from "./testView.vue";
import atView from "./atView.vue";
import userView from "@/views/base/user.vue";
const { t } = useI18n();
import { useStore } from "vuex";
import api from "@/api";
const store = useStore();
const props = defineProps({
  toolObj: Object,
});
const emit = defineEmits(["closeTool", "saveTool"]);
const navList = ref([
  {
    label: "bot.base167",
    value: 0,
  },
  {
    label: "bot.base155",
    value: 1,
  },
]);
const nullTemplate = `{
      "openapi": "3.1.0",
      "info": {
        "title": "Untitled",
        "description": "Your OpenAPI specification",
        "version": "v1.0.0"
      },
      "servers": [
        {
          "url": ""
        }
      ],
      "paths": {},
      "components": {
        "schemas": {}
      }
    }`;
const navActive = ref(0);
onMounted(() => {
  form.value = JSON.parse(JSON.stringify(props.toolObj));
  form.value.pictureId = form.value.imagePath;
  changeSchema();
  document.addEventListener("click", handleOutsideClick);
});
// scema
const userId = ref(store.state.userId);
const tableData = ref([]);
const getImg = computed(() => {
  if (props.toolObj.imagePath) {
    return "/openai/chat/v4/image/get/" + props.toolObj.imagePath;
  } else {
    return icon.plugUpload;
  }
});
const getImgEdit = computed(() => {
  if (form.value.pictureId) {
    return "/openai/chat/v4/image/get/" + form.value.pictureId;
  } else {
    return icon.plugUpload;
  }
});
const changeSchema = () => {
  tableData.value = [];
  api.bot
    .toolSwaggerParse({
      userId: userId.value,
      swaggerDescription:
        editType.value == "edit"
          ? form.value.swaggerDescription
          : props.toolObj.swaggerDescription,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        tableData.value = res.data;
      }
    });
};
const closeTool = () => {
  emit("closeTool");
};
// 修改确定
const form = ref({
  userId: store.state.userId,
  shareUserIds: [],
  agentName: "",
  pictureId: "",
  swaggerDescription: "",
  authorizationHas: 0,
  authorizationName: "Authorization",
  authorizationValue: null,
  authorizationType: null,
});
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          form.value.pictureId = res.data;
        })
        .catch((error) => console.log(error));
    }
  });
};
const editType = ref("detail");
// 打开编辑
const openToolEdit = () => {
  editType.value = "edit";
};
// 关闭编辑
const closeEdit = () => {
  form.value = JSON.parse(JSON.stringify(props.toolObj));
  form.value.pictureId = form.value.imagePath;
  changeSchema();
  editType.value = "detail";
};
// 下一步判断
const nextStepDisabled = () => {
  if (
    form.value.agentName &&
    form.value.swaggerDescription &&
    tableData.value.length != 0
  ) {
    return false;
  } else {
    return true;
  }
};
// 保存编辑
const saveTool = () => {
  let newForm = JSON.parse(JSON.stringify(form.value));
  newForm.shareUserIds = newForm.shareUserIds.map((item) => {
    return {
      id: item.id || item.ownerId,
      name: item.name,
      type: item.type,
      ownerType: item.ownerType,
      color: item.color,
    };
  });
  emit("saveTool", form.value);
};
// 监听点击事件，点击页面其他地方时关闭弹出框
const handleOutsideClick = (event) => {
  if (urlType.value && !event.target.closest(".urlDiv")) {
    urlType.value = false;
  }
};
onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick);
});
// 打开url导入
const urlType = ref(false);
const url = ref("");
const urlLoading = ref(false);
const openUrl = () => {
  urlType.value = !urlType.value;
};
const importUrl = () => {
  tableData.value = [];
  urlLoading.value = true;
  api.bot
    .urlDown({
      userId: userId.value,
      url: url.value,
    })
    .then((res) => {
      urlLoading.value = false;
      if (res.returnCode == 200) {
        form.value.swaggerDescription = res.data;
        urlType.value = false;
      }
    });
};
// 插入空白模板
const setTemplate = () => {
  tableData.value = [];
  form.value.swaggerDescription = nullTemplate;
};
// 打开测试
const testType = ref(false);
const testObj = ref({
  functionName: "",
  swaggerDescription: "",
  parameters: [],
  userId: userId.value,
});
const openTest = (row) => {
  testObj.value.functionName = row.functionName;
  testObj.value.swaggerDescription = form.value.swaggerDescription;
  testObj.value.parameters = row.functionParameter;
  testType.value = true;
};
// 关闭测试
const closeTest = () => {
  testType.value = false;
};
// 打开鉴权
const atType = ref(false);
const openAt = () => {
  atType.value = true;
};
// 保存健全
const saveAt = (obj) => {
  form.value = { ...form.value, ...obj };
  atType.value = false;
};
// 关闭鉴权
const closeAt = () => {
  atType.value = false;
};
// 移除成员
const deleteUser = (item, index) => {
  form.value.shareUserIds.splice(index, 1);
};
// 打开添加成员
const addDialog = ref(false);
const userList = ref([]);
const userObj = ref({});
const openUser = () => {
  // 判断部门 群组  人
  // 使用reduce方法将ownerType为0，1，2的对象分别筛选出来
  const result = form.value.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    if (item.ownerType == 0) {
      item.userId = Number(item.id);
    }
    if (item.ownerType == 1) {
      item.departmentId = Number(item.id);
    }
    if (item.ownerType == 2) {
      item.groupId = item.id;
    }
    acc[ownerType].push(item);
    return acc;
  }, {});

  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  userObj.value = userList.value.userList.filter((item) => item.type == 0);
  addDialog.value = true;
};
// 确定保存
const saveUser = (arr) => {
  const index = arr.findIndex((user) => user.id === userId.value);
  if (index == -1) {
    arr.unshift({
      userId: userId.value,
      id: userId.value,
      type: 0, //0 拥有者 1 创作者 2 普通用户
      userName: store.state.userName,
      name: store.state.userName,
      ownerType: 0, //0个人 1部门 2群组
      color: store.state.color,
    });
  }
  // 去重
  const seenIds = new Set();
  form.value.shareUserIds = arr.filter((item) => {
    const id = item.id;
    if (!seenIds.has(id)) {
      seenIds.add(id);
      return true;
    }
    return false;
  });
  addDialog.value = false;
};
</script>

<template>
  <div class="addContent">
    <div class="my-header">
      <div>
        {{ editType == "detail" ? t("bot.base168") : t("bot.base143") }}
      </div>
      <el-icon class="close-icon" @click="closeTool" style="cursor: pointer">
        <Close />
      </el-icon>
    </div>
    <div class="botBody">
      <!-- 信息 -->
      <div class="topDiv">
        <img class="botImg" :src="getImg" alt="" />
        <div>
          <div class="botName">{{ props.toolObj.agentName }}</div>
          <div class="nameTip">
            {{ props.toolObj.sessionNum }}
            {{ t("bot.base169") }}
            {{ props.toolObj.userNum }}
            {{ t("base.base354") }}
          </div>
        </div>
      </div>
      <!-- 导航 -->
      <div class="custom-style">
        <el-segmented v-model="navActive" :options="navList" id="value">
          <template #default="scope">
            <div>
              <div>{{ t(scope.item.label) }}</div>
            </div>
          </template>
        </el-segmented>
      </div>
      <div v-if="navActive == 0" class="maxHeight">
        <!-- 名称 -->
        <div class="flex" v-if="editType == 'edit'">
          <div class="inputDiv">
            <div class="name">{{ t("bot.bot28") }}</div>
            <el-input
              class="plugInput"
              :placeholder="t('bot.base144')"
              v-model="form.agentName"
              show-word-limit
              maxlength="100"
            ></el-input>
          </div>
          <uploadIndex @beforeAvatarUpload="beforeAvatarUpload" type="img">
            <img :src="getImgEdit" alt="" class="plugUpload" />
          </uploadIndex>
        </div>
        <!-- Schema -->
        <div class="flexBetween">
          <div class="name">Schema</div>
          <div class="flexCenter" v-if="editType == 'edit'">
            <div class="pos">
              <div class="urlBtn" @click.stop="openUrl">
                <img class="plugAddImg" :src="icon.plugAdd" alt="" />
                <div class="urlAdd">{{ t("bot.base145") }}</div>
              </div>
              <div class="urlDiv" v-show="urlType" @click.stop="">
                <el-input
                  placeholder="https://"
                  class="urlInputModel"
                  v-model="url"
                ></el-input>
                <el-button
                  :loading="urlLoading"
                  class="importBtn"
                  @click.stop="importUrl"
                  >{{ t("bot.base172") }}</el-button
                >
              </div>
            </div>
            <div class="urlAdd urlBtn" @click="setTemplate">
              {{ t("bot.base146") }}
            </div>
          </div>
        </div>
        <textarea
          v-model="form.swaggerDescription"
          @change="changeSchema"
          class="urlInput"
          :disabled="editType == 'detail' ? true : false"
        ></textarea>
        <!-- 可用工具 -->
        <template v-if="tableData.length != 0">
          <div class="names">{{ t("bot.base147") }}</div>
          <el-table :data="tableData" class="toolTable">
            <el-table-column prop="functionName" :label="t('bot.bot28')" />
            <el-table-column prop="description" :label="t('bot.base148')" />
            <el-table-column prop="methods" :label="t('bot.base149')" />
            <el-table-column prop="path" :label="t('bot.base150')" />
            <el-table-column :label="t('bot.bot39')" v-if="editType == 'edit'">
              <template #default="scope">
                <div class="testBtn" @click="openTest(scope.row)">
                  {{ t("bot.base136") }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 鉴权方法 -->
        <div class="names">{{ t("bot.base131") }}</div>
        <div class="optionDiv">
          <div class="optionText">
            {{ form.authorizationHas == 0 ? t("bot.base132") : "API Key" }}
          </div>
          <img
            :src="icon.botOption"
            class="botOption"
            alt=""
            v-if="editType == 'edit'"
            @click="openAt"
          />
        </div>
      </div>
      <template v-else>
        <div class="userFlex">
          <div class="names">{{ t("bot.base151") }}</div>
          <div v-if="editType == 'edit'" class="addUser" @click="openUser">
            {{ t("bot.bot41") }}
          </div>
        </div>
        <el-row class="rightTable">
          <el-col
            :span="editType != 'edit' ? 12 : 10"
            class="flexCenter padding10 tableNmae"
            >{{ t("base.base34") }}</el-col
          >
          <el-col
            :span="editType != 'edit' ? 12 : 8"
            class="flexCenter padding10 tableNmae"
          >
            {{ t("base.base35") }}
          </el-col>
          <el-col :span="6" v-if="editType == 'edit'">{{
            t("base.base36")
          }}</el-col>
        </el-row>
        <div class="maxHeight">
          <el-row
            class="tableItem"
            :class="index == form.shareUserIds.length - 1 ? 'tableBottom' : ''"
            v-for="(item, index) in form.shareUserIds"
            :key="item.id"
          >
            <el-col
              :span="editType != 'edit' ? 12 : 10"
              class="flexCenter padding10"
              v-if="item.name"
            >
              <div class="userImg" :style="'background-color:' + item.color">
                {{ item.name?.slice(0, 1) }}
              </div>
              <span class="tableTitleNmae">{{ item.name }}</span>
            </el-col>
            <el-col
              :span="editType != 'edit' ? 12 : 10"
              class="flexCenter padding10"
              v-else
            >
              <span class="tableTitleNmae">-</span>
            </el-col>
            <el-col
              :span="editType != 'edit' ? 12 : 8"
              class="flexCenter padding10"
            >
              <div class="tableRoleType" v-if="item.type == 0">
                <div class="tableLogo">{{ t("base.base37").slice(0, 1) }}</div>
                <div class="tableRole">{{ t("base.base38") }}</div>
              </div>
              <div class="tableRole" v-if="item.type == 1">
                {{ t("base.base41") }}
              </div>
            </el-col>
            <el-col :span="6" class="flexCenter" v-if="editType == 'edit'">
              <el-button
                class="deleUserBtn"
                @click="deleteUser(item, index)"
                v-if="item.type != 0"
              >
                {{ t("base.base42") }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </template>
    </div>
    <div class="dialog-footer">
      <!-- 详情按钮 -->
      <el-button @click="closeTool" v-if="editType == 'detail'" class="cancel">
        {{ t("bot.bot48") }}
      </el-button>
      <el-button
        @click="openToolEdit"
        class="confirm"
        v-if="props.toolObj.isOwner == 1 && editType == 'detail'"
      >
        {{ t("base.base5") }}
      </el-button>
      <!-- 编辑 -->
      <el-button class="cancel" @click="closeEdit" v-if="editType == 'edit'">
        {{ t("base.base113") }}
      </el-button>
      <el-button
        @click="saveTool"
        class="confirm"
        v-if="props.toolObj.isOwner == 1 && editType == 'edit'"
        :disabled="nextStepDisabled()"
      >
        {{ t("base.base47") }}
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
  <!-- 测试弹窗 -->
  <testView
    v-if="testType"
    @closeTest="closeTest"
    :form="form"
    :testObj="testObj"
  ></testView>
  <!-- 鉴权 -->
  <atView
    v-if="atType"
    @closeAt="closeAt"
    @saveAt="saveAt"
    :form="form"
  ></atView>
  <userView
    v-if="addDialog"
    type="bot"
    :userList="userList"
    :userObj="userObj"
    @closeUser="addDialog = false"
    @saveUser="saveUser"
  ></userView>
</template>

<style lang="scss" scoped>
.optionDiv {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin-top: 17px;
  margin-bottom: 15px;
}
.optionText {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.names {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.toolTable {
  width: 100%;
  border-radius: 12px;
  margin-top: 17px;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  :deep(.el-table thead) {
    background-color: #f6f6f6;
  }
}
.maxHeight {
  max-height: 400px;
  overflow: auto;
  margin-bottom: 17px;
}
.name::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  margin-left: 5px;
}
.name {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.urlInput {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-top: 17px;
  padding: 11px 20px;
  font-size: 16px;
  font-weight: 400;
  height: 150px;
  resize: none;
  outline: none;
  overflow: auto;
  margin-bottom: 24px;
}
.jsText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-top: 24px;
  position: relative;
}
.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #fefefe;
  --el-border-radius-base: 10px;
  padding: 3px;
  :deep(.el-segmented__item:not(.is-disabled):not(.is-selected):hover) {
    background-color: #e4e4e4;
    border-radius: 8px;
  }
  :deep(.el-segmented__item) {
    padding: 5px 22px;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 500;
  }
  :deep(.is-selected) {
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 500;
  }
}
.topDiv {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 24px;
}
.botName {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.nameTip {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
}
.botImg {
  width: 52px;
  margin-right: 16px;
  border-radius: 16px;
}
.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    font-weight: 400;
    height: 48px;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    font-weight: 400;
    height: 48px;
  }
}
.close-icon {
  font-size: 20px;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 99;
  opacity: 0.3;
}
.addContent {
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 100;
  padding: 30px;
  border-radius: 30px;
}
:deep(.el-table th.el-table__cell) {
  background-color: #f6f6f6;
  font-size: 18px;
  color: #8a8a8a;
  height: 60px;
}
.flex {
  display: flex;
  margin-top: 24px;
}
.inputDiv {
  width: calc(100% - 118px);
}
.plugInput {
  :deep(.el-input__wrapper) {
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 0px;
    height: 44px;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 17px;
  }
}
.plugUpload {
  width: 90px;
  margin-left: 18px;
  border-radius: 16px;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.pos {
  position: relative;
}
.urlBtn {
  display: flex;
  border: 1px solid #edeef6;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 4px 0px #edeef6;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 9px;
  position: relative;
}
.plugAddImg {
  width: 12px;
  margin-right: 6px;
}
.urlAdd {
  font-size: 16px;
  color: #696969;
  font-weight: 500;
}
.urlDiv {
  position: absolute;
  background-color: #fefefe;
  border: 1px solid #edeef6;
  border-radius: 16px;
  width: 360px;
  height: 59px;
  bottom: -65px;
  right: 0;
  box-shadow: 0px 2px 4px 0px #edeef6;
  padding: 12px;
  display: flex;
  align-items: center;
}
.urlInputModel {
  :deep(.el-input__wrapper) {
    background-color: #f6f6f6;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
    border-radius: 8px;
    height: 17px;
    font-size: 14px;
    font-weight: 400;
    padding: 9px 12px;
  }
}
.importBtn {
  background-color: #3376ff;
  border: 0px;
  box-shadow: 0px 0px 0px 0px;
  border-radius: 8px;
  color: #fefefe;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 12px;
  margin-left: 6px;
}
.testBtn {
  border: 1px solid #d9d9d9;
  box-shadow: 1px 1px 4px 0px rgba(212, 212, 212, 0.25);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 10px;
  display: inline-block;
  cursor: pointer;
}
.botOption {
  width: 24px;
  cursor: pointer;
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border: 1px solid #d9d9d9;
  border-top: 0px;
  font-size: 18px;
  color: #8a8a8a;
}
.tableBottom {
  border-radius: 0px 0px 12px 12px;
}
.padding10 {
  padding-left: 10px;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  margin-right: 6px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
}
.userFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 13px;
}
.addUser {
  border-radius: 8px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 16px;
  cursor: pointer;
}
.rightTable {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #8a8a8a;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 12px 12px 0px 0px;
}
.tableNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  padding-left: 10px;
}
</style>
