<template>
  <div class="tipSetDiv">
    <div class="tipTitleFlex">
      <div class="tipCenter">
        <img :src="icon.qxdz" alt="" class="qxdz" />
        <div class="tipTitle">{{ t("base.base230") }}</div>
      </div>
      <img
        :src="icon.closeHistory"
        alt=""
        class="historyCloseIcon"
        @click="closeDownUp"
      />
    </div>
    <div style="display: flex">
      <div
        @click="tipActive = index"
        :class="tipActive == index ? 'tipActive' : 'tipNoActive'"
        v-for="(item, index) in tipList"
        :key="item"
      >
        {{ t(item) }}
      </div>
    </div>
    <div class="flexBetween" v-if="tipActive == 1 || tipActive == 3">
      <el-input class="tipInput" :placeholder="t('base.base231')"></el-input>
      <el-button type="primary" class="tipSaveBtn">{{
        t("base.base212")
      }}</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const tipActive = ref(null);
const tipList = ref([
  "base.base243",
  "base.base244",
  "base.base245",
  "base.base246",
]);
const props = defineProps(["item"]);
const emit = defineEmits(["closeDownUp"]);
const closeDownUp = () => {
  emit("closeDownUp", props.item);
};
</script>

<style lang="scss" scoped>
.qxdz {
  width: 28px;
}
.tipSetDiv {
  max-height: 159px;
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 17px;
  margin-bottom: 24px;
}
.tipTitleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.tipCenter {
  display: flex;
  align-items: center;
}
.tipTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
  margin-left: 13px;
}
.tipNoActive {
  height: 44px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  color: #8a8a8a;
  font-weight: 400;
  text-align: center;
  line-height: 44px;
  background-color: #fefefe;
  padding: 0px 20px;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.tipActive {
  height: 44px;
  border: 2px solid #dbdfff;
  border-radius: 16px;
  font-size: 20px;
  color: #0256ff;
  font-weight: 400;
  text-align: center;
  line-height: 44px;
  background-color: #eceeff;
  padding: 0px 20px;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.tipInput {
  width: 80%;
  :deep(.el-input__wrapper) {
    height: 50px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px 0px;
    font-size: 20px;
    font-weight: 400;
  }
}
.tipSaveBtn {
  height: 48px;
  border-radius: 16px;
  padding: 0px 20px;
  font-size: 20px;
  color: #fefefe;
  font-weight: 400;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.historyCloseIcon {
  width: 14px;
  cursor: pointer;
}
</style>
