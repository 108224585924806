<template>
  <div
    :class="
      (props.historyDialog && props.rightType) || lookDialog
        ? 'bottomIcon'
        : 'flexCenter'
    "
  >
    <div class="center">
      <div class="fillCenter" v-if="props.item.answers.length > 1">
        <el-button link @click="changeItemPage(false)" :disabled="getLoading()">
          <img
            :src="props.item.chatActive != 1 ? icon.leftFill : icon.leftNoFill"
            alt=""
            class="fillImg"
          />
        </el-button>
        <span
          :class="props.item.chatActive == 1 ? 'fillColor' : ''"
          class="fillText"
          >{{ props.item.chatActive }}</span
        >
        <span
          class="fillText"
          :class="
            props.item.chatActive == props.item.answers.length
              ? 'fillColor'
              : ''
          "
          >/{{ props.item.answers.length }}</span
        >
        <el-button link @click="changeItemPage(true)" :disabled="getLoading()">
          <img
            :src="
              props.item.chatActive == props.item.answers.length
                ? icon.rightNoFill
                : icon.rightFill
            "
            alt=""
            class="fillImg"
          />
        </el-button>
      </div>
      <el-tooltip
        popper-class="toolTip"
        :content="t('login.login73')"
        v-if="
          props.routeType == 'chat' &&
          chatType == 'base' &&
          props.item.answers[props.item.chatActive - 1].strategy != 0 &&
          topicItem.knowledge_type != 3
        "
        placement="top"
      >
        <el-button
          :disabled="getLoading()"
          class="detailsBtn color4 btnImg"
          @click="releteTalk(0)"
        >
          <img :src="icon.setSearch" alt="" class="btnImg wid17" />
          <div>{{ t("base.base202") }}</div>
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        :content="t('login.login74')"
        placement="top"
        v-if="
          props.routeType == 'chat' &&
          chatType == 'base' &&
          props.item.answers[props.item.chatActive - 1].strategy != 1 &&
          topicItem.knowledge_type != 3
        "
      >
        <el-button
          :disabled="getLoading()"
          class="detailsBtn color1 btnImg"
          @click="releteTalk(1)"
        >
          <img :src="icon.setSearch" alt="" class="btnImg wid17" />
          <div>{{ t("base.base191") }}</div>
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        :content="t('login.login75')"
        placement="top"
        v-if="
          props.routeType == 'chat' &&
          chatType == 'base' &&
          props.item.answers[props.item.chatActive - 1].strategy != 2 &&
          topicItem.knowledge_type != 3
        "
      >
        <el-button
          :disabled="getLoading()"
          class="detailsBtn color2 btnImg"
          @click="releteTalk(2)"
        >
          <img :src="icon.setSearch" alt="" class="btnImg wid17" />
          <div>{{ t("base.base192") }}</div>
        </el-button>
      </el-tooltip>

      <el-button
        :disabled="getLoading()"
        class="detailsBtn color3 marginRight"
        @click="openRequestverify"
        v-if="chatType == 'base'"
      >
        <img :src="icon.setCap" alt="" class="btnImg wid21" />
        <div>{{ t("base.base193") }}</div>
      </el-button>
    </div>
    <div
      :class="
        props.historyDialog && props.rightType
          ? 'marginBottom center'
          : 'center'
      "
    >
      <el-tooltip
        popper-class="toolTip"
        :content="t('base.base194')"
        placement="top"
      >
        <el-button class="iconMargin" @click="copyFn">
          <img :src="icon.chatCopySvg" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        :content="t('base.base195')"
        v-if="props.routeType == 'chat'"
        placement="top"
      >
        <el-button
          class="iconMargin"
          @click="releteTalk(0)"
          :disabled="getLoading()"
        >
          <img :src="icon.relete" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-dropdown placement="top" @command="changeLang">
        <el-button
          :class="
            !props.item.answers[props.item.chatActive - 1].langType
              ? 'iconMargin'
              : 'tocBtn'
          "
          @click="btnLang"
          :disabled="getLoading()"
        >
          <img :src="icon.cToa" alt="" class="wid36" />
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="item in langList"
              :key="item.value"
              :command="item.value"
              :disabled="getLoading()"
            >
              <div class="dropItem">
                <img :src="item.icon" alt="" class="itemIcon" />
                {{ t(item.label) }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-tooltip
        popper-class="toolTip"
        :content="
          props.item.isCollected == 0 ? t('base.base197') : t('base.base198')
        "
        placement="top"
      >
        <el-button
          class="iconMargin"
          @click="itemLike"
          v-if="props.item.isCollected == 0"
        >
          <img :src="icon.setSq" alt="" class="wid36" />
        </el-button>
        <el-button class="iconMargin" @click="itemLike" v-else>
          <img :src="icon.qxsc" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        placement="top"
        :content="t('base.base199')"
      >
        <el-button class="iconMargin" @click="openHistory">
          <img :src="icon.history" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip popper-class="toolTip" placement="top">
        <template #content v-if="chatType == 'base'">
          {{ t("base.base200") }}<br />
          {{ t("login.login33") }}:{{ descObj.model }}<br />
          {{ t("login.login34") }}:
          {{
            props.item.answers[props.item.chatActive - 1].strategy == 0
              ? t("base.base202")
              : props.item.answers[props.item.chatActive - 1].strategy == 1
              ? t("base.base191")
              : t("base.base192")
          }}<br />
          {{ t("login.login35") }}: {{ descObj.answerTokens }}<br />
          {{ t("login.login37") }}:{{ descObj.questionTokens }}<br />
          {{ t("login.login36") }}: {{ descObj.realQuestionTokens }}<br />
          {{ t("login.login38") }}: {{ descObj.internetCount }}<br />
          {{ t("login.login39") }}: {{ getTime(descObj.createdTime) }}<br />
        </template>
        <template #content v-else>
          {{ t("base.base200") }}<br />
          {{ t("login.login33") }}:{{ descObj.model }}<br />
          {{ t("login.login35") }}: {{ descObj.answerTokens }}<br />
          {{ t("login.login37") }}:{{ descObj.questionTokens }}<br />
          {{ t("login.login36") }}: {{ descObj.realQuestionTokens }}<br />
          {{ t("login.login38") }}: {{ descObj.internetCount }}<br />
          {{ t("login.login39") }}: {{ getTime(descObj.createdTime) }}<br />
        </template>
        <el-button class="iconMargin" @mouseover="getDesc">
          <img :src="icon.gantan" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        placement="top"
        :content="t('base.base201')"
        v-if="props.routeType == 'chat'"
      >
        <el-button class="iconMargin" @click="deleteItem(props.item)">
          <img :src="icon.cd" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <div class="lineIcon">
        <img :src="icon.lineSvg" alt="" class="widLine" />
      </div>
      <el-tooltip
        popper-class="toolTip"
        placement="top"
        :content="
          props.item.isVoteUp == 0 ? t('base.base203') : t('base.base204')
        "
      >
        <el-button
          class="iconMargin"
          @click="itemUp"
          v-if="props.item.isVoteUp == 0"
        >
          <img :src="icon.dz" alt="" class="wid36" />
        </el-button>
        <el-button class="iconMargin" @click="itemUp" v-else>
          <img :src="icon.ydz" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        placement="top"
        :content="
          props.item.isVoteDown == 0 ? t('base.base205') : t('base.base206')
        "
      >
        <el-button
          class="iconMargin"
          @click="itemDown"
          v-if="props.item.isVoteDown == 0"
        >
          <img :src="icon.qxdzSet" alt="" class="wid36" />
        </el-button>
        <el-button class="iconMargin" @click="itemDown" v-else>
          <img :src="icon.ydc" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <div class="yesCenter" v-if="props.item.isValid == 1">
        <img class="chatYesSvg" :src="icon.chatYesSvg" alt="" />
        <div class="yesText">{{ t("base.base319") }}</div>
      </div>
    </div>
    <!-- 验证 -->
    <verifyView
      v-if="requestverify"
      type="set"
      :seleteUserInfo="seleteUserInfo"
      :requestverifyLoading="requestverifyLoading"
      @closeCap="setCloseCap"
      @saveCap="saveCap"
    ></verifyView>
  </div>
</template>

<script setup>
import verifyView from "./verify.vue";
import { getTime, throttle } from "@/utils/utils";
import { ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { encryptApi, decryptApi } from "@/utils/crypto";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import clipboard3 from "vue-clipboard3";
const { toClipboard } = clipboard3();
import api from "@/api";
import { icon } from "@/utils/icon";
const router = useRouter();
const store = useStore();
const chatType = ref(store.state.chatType);
const userId = ref(store.state.userId);
const topicItem = ref(store.state.topicItem);
const requestverify = ref(false); //请求重新验证
const seleteUserInfo = ref([]);
const requestverifyLoading = ref(false);
const langList = ref([
  {
    label: "gpt.index79",
    value: "EN",
    icon: icon.EN,
  },
  {
    label: "gpt.index80",
    value: "CN",
    icon: icon.CN,
  },
  {
    label: "gpt.index81",
    value: "TC",
    icon: icon.TC,
  },
  {
    label: "gpt.index82",
    value: "JA",
    icon: icon.JA,
  },
  {
    label: "gpt.index83",
    value: "KO",
    icon: icon.KO,
  },
  {
    label: "gpt.index84",
    value: "RU",
    icon: icon.RU,
  },
  {
    label: "gpt.index85",
    value: "ES",
    icon: icon.ES,
  },
  {
    label: "gpt.index86",
    value: "FR",
    icon: icon.FR,
  },
  {
    label: "gpt.index87",
    value: "IT",
    icon: icon.IT,
  },
  {
    label: "gpt.index88",
    value: "PT",
    icon: icon.PT,
  },
  {
    label: "gpt.index89",
    value: "AR",
    icon: icon.AR,
  },
  {
    label: "gpt.index90",
    value: "DE",
    icon: icon.DE,
  },
]);
const emit = defineEmits([
  "openHistory",
  "scrollBottom",
  "getTalk",
  "changeRequestverify",
]);
const props = defineProps([
  "rightType",
  "historyDialog",
  "lookDialog",
  "item",
  "model",
  "routeType",
  "chatList",
  "llmConfigurationDict",
]);
const descObj = ref({
  answerTokens: null,
  createdTime: "",
  internetCount: null,
  model: "",
  questionTokens: null,
  realQuestionTokens: null,
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
  }
);
const getLoading = () => {
  return props.chatList.some((item) => item.loading === true);
};
// 删除会话
const deleteItem = (item) => {
  api.chat[
    chatType.value == "base"
      ? "baseConversationDelete"
      : "chatConversationDelete"
  ]({
    conversationId: item.conversationId,
    userId: userId.value,
    operation_type: 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("base.base213"));
      emit("getTalk");
    }
  });
};
// 保存
const saveCap = (treeData, peopleSel, leftNotes) => {
  requestverifyLoading.value = true;
  let verifyNums = peopleSel
    .map((ownerId) => {
      const item = seleteUserInfo.value.find(
        (item) => item.ownerId === ownerId
      );
      return item ? { ownerId: item.ownerId, ownerType: item.ownerType } : null;
    })
    .filter((item) => item !== null);
  api.share
    .postRequestverify({
      userId: userId.value,
      conversationIds: [props.item.conversationId],
      verifyNums: verifyNums,
      sessionName: topicItem.value.sessionName,
      sessionId: topicItem.value.sessionId,
      leftNotes: leftNotes,
      onlyQA: true,
    })
    .then((res) => {
      requestverifyLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base214"));
        requestverify.value = false;
      }
    });
};
// 关闭严重
const setCloseCap = () => {
  requestverify.value = false;
};
defineExpose({
  setCloseCap,
});
// 打开验证
const openRequestverify = () => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: topicItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        seleteUserInfo.value = res.data.userInfo.filter(
          (item) => item.type !== 2 && item.ownerName
        );
        requestverify.value = true;
        emit("changeRequestverify");
      }
    });
};
// 获取详情
const getDesc = throttle(() => {
  api.chat[chatType.value == "chat" ? "chatDesc" : "baseDesc"]({
    answerId: props.item.answers[props.item.chatActive - 1].answerId,
    conversationId: props.item.conversationId,
    userId: userId.value,
  }).then((res) => {
    if (res.returnCode == 200) {
      descObj.value = res.data;
    }
  });
}, 1000);
// 重新生成
const releteTalk = (strategy) => {
  props.item.resonType = false;
  props.item.answers.push({
    answerContent: "",
    answerId: "",
    strategy: strategy,
    thinkContent: "", //思考文本
    thinkTime: null, //思考时间
    model: props.model,
    pluginInfo: [],
  });
  props.item.chatActive = props.item.answers.length;
  let time = Math.round(new Date() / 1000);
  let obj = {};
  if (chatType.value == "chat") {
    obj = {
      userId: userId.value,
      model: props.model,
      topicId: topicItem.value.sessionId,
      conversationId: props.item.conversationId,
      strategy: strategy,
    };
  } else {
    obj = {
      userId: userId.value,
      model: props.model,
      libraryId: topicItem.value.knowledge_library_id,
      conversationId: props.item.conversationId,
      sessionId: topicItem.value.sessionId,
      strategy: strategy,
    };
  }
  obj.isKnowledgeOn = topicItem.value.isKnowledgeOn == 1 ? true : false;
  obj.llmConfigurationDict = props.llmConfigurationDict;
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(
      JSON.stringify({
        ...obj,
        time: time,
      })
    ),
  };
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (obj.model == "GPT-o1-preview") {
    props.item.loading = true;
    api.chat[chatType.value == "chat" ? "chatRegenerate" : "baseRegenerate"](
      obj
    ).then((res) => {
      props.item.loading = false;
      if (res.returnCode == 200) {
        props.item.answers[props.item.answers.length - 1].answerContent =
          res.data.message;
        nextTick(() => {
          emit("scrollBottom");
        });
      } else if (res.returnCode == 423 || res.returnCode == 412) {
        router.push("/");
      } else {
        props.item.chatActive = props.item.answers.length - 1;
        props.item.answers.splice(props.item.chatActive, 1);
        nextTick(() => {
          emit("scrollBottom");
        });
      }
    });
  } else {
    if (chatType.value == "chat") {
      regenerateFn(postObj, lang, "/openai/chat/v4/normal/regenerate");
    } else {
      regenerateFn(postObj, lang, "/openai/chat/v4/knowledge/regenerate");
    }
  }
};
// 重新生成chat
const regenerateFn = async (postObj, lang, url) => {
  try {
    props.item.loading = true;
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if ([500, 504].includes(response.status)) {
      const errorMap = {
        500: "base.base182",
        504: "base.base183",
      };
      message.warning(t(errorMap[response.status]));
      props.item.answers[props.item.answers.length - 1].answerContent = t(
        errorMap[response.status]
      );
      props.item.loading = false;
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    let think = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      // 解析插件
      const plugRegex = /<swagger>\s*<start>(.*?)<end>\s*<\/swagger>/g;
      let plugMatch;
      while ((plugMatch = plugRegex.exec(rawData)) !== null) {
        props.item.answers[props.item.answers.length - 1].pluginInfo.push(
          JSON.parse(decryptApi(plugMatch[1]))
        );
      }
      // 解析 <think> 标签内的内容
      const thinkRegex = /<think>\s*<start>(.*?)<end>\s*<\/think>/g;
      let thinkMatch;
      let thinkArr = [];
      while ((thinkMatch = thinkRegex.exec(rawData)) !== null) {
        thinkArr.push(thinkMatch[1]);
      }
      // 解析 <text> 标签内的内容
      const textRegex = /<text>\s*<start>(.*?)<end>\s*<\/text>/g;
      let textMatch;
      let textArr = [];
      while ((textMatch = textRegex.exec(rawData)) !== null) {
        textArr.push(textMatch[1]);
      }
      const thinkData = thinkArr.map((data) => decryptApi(data));
      const textData = textArr.map((data) => decryptApi(data));
      think += thinkData.map((item) => item).join("");
      data += textData.map((item) => item).join("");
      props.item.answers[props.item.answers.length - 1].thinkContent = think;
      props.item.answers[props.item.answers.length - 1].answerContent = data;
      nextTick(() => {
        emit("scrollBottom");
      });
      if (done) {
        props.item.loading = false;
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          nextTick(() => {
            store.commit("SET_TALK", {});
          });
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          props.item.answers[props.item.answers.length - 1].answerContent =
            t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/");
          } else {
            props.item.chatActive = props.item.answers.length - 1;
            props.item.answers.splice(props.item.chatActive, 1);
          }
        }
        break;
      }
    }
  } catch (err) {
    props.item.loading = false;
  }
};
// 加减页数
const changeItemPage = (type) => {
  props.item.resonType = false;
  if (type && props.item.chatActive < props.item.answers.length) {
    props.item.chatActive++;
  }
  if (!type && props.item.chatActive > 1) {
    props.item.chatActive--;
  }
};
// 打开历史记录
const openHistory = () => {
  if (chatType.value == "chat") {
    api.base
      .chatHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        topicId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length == 0) {
            message.warning(t("base.base215"));
            return;
          }
          res.data.chatActive = 1;
          emit("openHistory", res.data);
        }
      });
  } else {
    api.base
      .baseHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        libraryId: topicItem.value.knowledge_library_id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length == 0) {
            message.warning(t("base.base215"));
            return;
          }
          res.data.chatActive = 1;
          emit("openHistory", res.data);
        }
      });
  }
};
// 复制
const copyFn = async () => {
  await toClipboard(
    props.item.answers[props.item.chatActive - 1].answerContent
  ); // str 为需要复制的文字
  message.success(t("base.base216"));
};
// 直接点击翻译
const btnLang = () => {
  if (props.item.answers[props.item.chatActive - 1].langType) {
    props.item.answers[props.item.chatActive - 1].langType = false;
    props.item.answers[props.item.chatActive - 1].langText = "";
    return;
  }
  qaLang.value = "EN";
  props.item.answers[props.item.chatActive - 1].langType = true;
  itemQa();
};
// 切换语言
const qaLang = ref(null);
const changeLang = (e) => {
  qaLang.value = e;
  props.item.answers[props.item.chatActive - 1].langType = true;
  itemQa();
};
const itemQa = () => {
  props.item.loading = true;
  props.item.answers[props.item.chatActive - 1].langText = "";
  api.chat[chatType.value == "chat" ? "chatTranslate" : "baseTranslate"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    answerId: props.item.answers[props.item.chatActive - 1].answerId,
    language: qaLang.value,
  })
    .then((res) => {
      props.item.loading = false;
      if (res.returnCode == 200) {
        message.success(t("base.base218"));
        props.item.answers[props.item.chatActive - 1].langType = true;
        props.item.answers[props.item.chatActive - 1].langText = res.data;
      } else {
        props.item.answers[props.item.chatActive - 1].langType = false;
        props.item.answers[props.item.chatActive - 1].langText = "";
      }
    })
    .catch((err) => {
      props.item.loading = false;
    });
};
// 收藏
const itemLike = () => {
  api.chat[chatType.value == "chat" ? "chatLike" : "baseLike"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isCollected == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(
        props.item.isCollected == 1 ? t("base.base219") : t("base.base220")
      );
      props.item.isCollected = props.item.isCollected == 1 ? 0 : 1;
    }
  });
};
// 点赞
const itemUp = () => {
  api.chat[chatType.value == "chat" ? "chatUp" : "baseUp"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isVoteUp == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(
        props.item.isVoteUp == 1 ? t("base.base221") : t("base.base222")
      );
      props.item.isVoteUp = props.item.isVoteUp == 1 ? 0 : 1;
    }
  });
};
// 点踩
const itemDown = () => {
  api.chat[chatType.value == "chat" ? "chatDown" : "baseDown"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isVoteDown == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      // if (props.item.isVoteDown == 0) {
      //   props.item.tipDialog = true;
      // }
      message.success(
        props.item.isVoteDown == 1 ? t("base.base223") : t("base.base224")
      );
      props.item.isVoteDown = props.item.isVoteDown == 1 ? 0 : 1;
    }
  });
};
</script>

<style lang="scss" scoped>
.detailsBtn {
  display: flex;
  border-radius: 30px;
  font-size: 16px;
  color: #fefefe;
  font-weight: 400;
  padding: 5px 12px;
  height: 36px;
  cursor: pointer;
  margin-left: 0px !important;
}
.color4 {
  background-color: #37ddbe;
}
.color1 {
  background-color: #38aee5;
}
.color2 {
  background-color: #767bfa;
}
.color3 {
  background-color: #3376ff;
}
.btnImg {
  margin-right: 8px;
}
.flexCenter {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.iconMargin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 0px;
  margin-left: 0px !important;
}
.tocBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 0px;
  margin-left: 0px !important;
  background-color: #edeef6;
  border-radius: 12px;
}
.lineIcon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconMargin:hover {
  background-color: #edeef6;
  border-radius: 12px;
}
.marginRight {
  margin-right: 20px;
}
.center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bottomIcon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 30px;
}
.marginBottom {
  margin-bottom: 20px;
}
.wid17 {
  width: 17px;
}
.wid21 {
  width: 21px;
}
.wid36 {
  width: 36px;
}
.widLine {
  width: 5px;
  height: 25px;
}
.fillImg {
  width: 24px;
}
.fillText {
  font-size: 18px;
  font-weight: 600;
  color: #8a8a8a;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillColor {
  color: #d9d9d9;
}
:deep(.el-button.is-disabled, .el-button.is-disabled:hover) {
  background-color: none;
  color: none;
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
.chatYesSvg {
  width: 20px;
}
.yesCenter {
  display: flex;
  align-items: center;
}
.yesText {
  font-size: 18px;
  color: #14ae5c;
  font-weight: 500;
  margin-left: 10px;
}
.itemIcon {
  width: 18px;
  margin-right: 7px;
}
.dropItem {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
}
</style>
