<script setup>
import { ref, watch, nextTick, onMounted, computed } from "vue";
import { icon } from "@/utils/icon";
import userView from "@/views/base/user.vue";
import toolDialogView from "./toolDialog.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import uploadIndex from "@/components/upload/index.vue";
import stepView from "@/components/step/index.vue";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
  botInfo: Object,
});
onMounted(() => {
  getBase();
});
const userObj = ref({});
const imgList = ref([]);
const imgActive = ref(null);
const resonOne = ref(null);
const formRef = ref(null);
const emit = defineEmits(["closeBtn", "submitBtn"]);
const highType = ref(false);
const stepList = ref([
  {
    name: t("bot.base152"),
    value: 1,
    tip: t("bot.base156"),
  },
  {
    name: t("bot.base154"),
    value: 2,
    tip: t("bot.base155"),
  },
]);
// 禁用下一步
const getNextDisabled = computed(() => {
  if (props.botInfo.templateName && props.botInfo.templateValue) {
    return false;
  } else {
    return true;
  }
});
// 打开插件
const toolDialog = ref(false);
const openTool = () => {
  toolDialog.value = true;
};
const closeTool = () => {
  toolDialog.value = false;
};
const step = ref(1);
const basePageId = ref(1);
const basePerPage = ref(10000);
const baseList = ref([]);
const toolList = ref([]);
// 删除插件
const deleteTool = (item) => {
  toolList.value = toolList.value.filter(
    (items) => items.agentId !== item.agentId
  );
};
// 获取插件头像
const getToolImg = (item) => {
  if (item.image_path) {
    return "/openai/chat/v4/image/get/" + item.image_path;
  } else {
    return icon.plugUpload;
  }
};
// 切换步骤
const changeStep = (index) => {
  step.value = index;
};
const goBase = () => {
  basePageId.value++;
  getBase();
};
// 获取知识库列表
const getBase = () => {
  api.base
    .customLibraryList({
      userId: userId.value,
      namePattern: "",
      pageId: basePageId.value,
      perPage: basePerPage.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (basePageId.value == 1) {
          baseList.value = res.data;
        } else {
          baseList.value = baseList.value.concat(res.data);
        }
        let result = baseList.value.findIndex(
          (item) => item.id == props.botInfo.relativeKnowledgeId
        );
        if (result == -1) {
          relativeKnowledgeId.value = null;
        } else {
          console.log(123123123);
          relativeKnowledgeId.value = props.botInfo.relativeKnowledgeId;
        }
      }
    });
};
const perLoading = ref(false);
// 一键完善
const oneKeyPerfect = (e) => {
  perLoading.value = true;
  api.bot
    .templatePerfect({
      message: e,
      templateName: props.botInfo.templateName,
      templateValue: props.botInfo.templateValue,
      userId: userId.value,
    })
    .then((res) => {
      perLoading.value = false;
      if (res.returnCode === 200) {
        props.botInfo.templateCall = res.data.templateCall;
        props.botInfo.templateDesc = res.data.templateDesc;
        props.botInfo.templateValue = res.data.templateValue;
        highType.value = true;
        message.success(t("bot.bot18"));
      }
    })
    .catch((err) => {
      perLoading.value = false;
    });
};

// 校验规则
const rules = ref({
  templateName: [{ required: true, message: t("bot.bot19") }],
  templateValue: [{ required: true, message: t("bot.bot20") }],
});
// 提问灵感
const inputValue = ref("");
const dynamicTags = ref(props.botInfo.suggestQuestion || []);
watch(
  () => props.botInfo.suggestQuestion,
  (newVal) => {
    dynamicTags.value = newVal;
  }
);
const inputVisible = ref(false);
const InputRef = ref(null);
const handleClose = (tag) => {
  dynamicTags.value.splice(dynamicTags.value.indexOf(tag), 1);
};
const showInput = () => {
  inputVisible.value = true;
  nextTick(() => {
    if (InputRef.value) {
      InputRef.value.focus();
    }
  });
};
const handleInputConfirm = () => {
  if (inputValue.value) {
    dynamicTags.value.push(inputValue.value);
  }
  inputVisible.value = false;
  inputValue.value = "";
};
// 增加成员弹窗
const showTool = ref(false);
const userList = ref({
  departmentList: [],
  userList: [],
  gropuList: [],
}); //回显
const addMember = () => {
  const result = props.botInfo.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    if (item.ownerType == 0) {
      item.id = Number(item.id);
      item.userId = Number(item.id);
    }
    if (item.ownerType == 1) {
      item.id = Number(item.id);
      item.departmentId = Number(item.id);
    }
    if (item.ownerType == 2) {
      item.groupId = item.id;
    }
    acc[ownerType].push(item);
    return acc;
  }, {});
  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  userObj.value = userList.value.userList.filter((item) => item.type == 0);
  showTool.value = true;
};
// 确定保存
const saveUser = (arr) => {
  const index = arr.findIndex((user) => user.id === userId.value);
  if (index == -1) {
    arr.unshift({
      userId: userId.value,
      id: userId.value,
      type: 0, //0 拥有者 1 创作者 2 普通用户
      userName: store.state.userName,
      name: store.state.userName,
      ownerType: 0, //0个人 1部门 2群组
      color: store.state.color,
    });
  }
  // 去重
  const seenIds = new Set();
  props.botInfo.shareUserIds = arr.filter((item) => {
    const id = item.id;
    if (!seenIds.has(id)) {
      seenIds.add(id);
      return true;
    }
    return false;
  });
  showTool.value = false;
};
const closeDialog = () => {
  formRef.value.resetFields();
  emit("closeBtn");
  imgList.value = [];
};
const closeDialog1 = () => {
  emit("closeBtn");
  imgList.value = [];
};
const isAgentOn = ref(false);
const isInternetOn = ref(false);
const isKnowledgeOn = ref(false);
const relativeKnowledgeId = ref(null);
const submitBtn = (formRef) => {
  props.botInfo.userId = userId.value;
  props.botInfo.suggest = dynamicTags.value;
  props.botInfo.isAgentOn = isAgentOn.value ? 1 : 0;
  props.botInfo.isInternetOn = isInternetOn.value ? 1 : 0;
  props.botInfo.isKnowledgeOn = isKnowledgeOn.value ? 1 : 0;
  props.botInfo.relativeKnowledgeId = relativeKnowledgeId.value;
  props.botInfo.AgentIds = toolList.value.map((item) => item.agentId);
  formRef.validate((valid) => {
    if (valid) {
      emit("submitBtn", props.botInfo);
      imgList.value = [];
    }
  });
};
// 用户移除
const removeMember = (item, index) => {
  props.botInfo.shareUserIds.splice(index, 1);
};
// 图标事件

const imgLoading = ref(false);
const getImgLoading = ref(false);
// 微模型上传头像
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      getImgLoading.value = true;
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          // 创建 Blob 对象
          const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
          // 生成 URL
          const imageUrl = URL.createObjectURL(blob);
          getImgLoading.value = false;
          props.botInfo.image_path = res.data;
          console.log(res.data);
          imgList.value.push({
            id: res.data,
            url: imageUrl,
          });
          imgActive.value = imgList.value.length - 1;
        })
        .catch((error) => console.log(error));
    }
  });
};
// 向左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 选择微模型头像
const changeImg = (item, index) => {
  imgActive.value = index;
  props.botInfo.image_path = item.id;
};
// ai生成图片
const addImg = () => {
  imgLoading.value = true;
  api.base
    .imageGenerate({
      userId: userId.value,
      message: props.botInfo.templateName,
    })
    .then((res) => {
      imgLoading.value = false;
      if (res.returnCode == 200) {
        getImgLoading.value = true;
        fetch(`/openai/chat/v4/image/get/${res.data}`)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            // 创建 Blob 对象
            const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
            // 生成 URL
            const imageUrl = URL.createObjectURL(blob);
            getImgLoading.value = false;
            imgList.value.push({
              id: res.data,
              url: imageUrl,
            });
            imgActive.value = imgList.value.length - 1;
            props.botInfo.image_path = res.data;
          })
          .catch((error) => (getImgLoading.value = false));
      }
    })
    .catch((err) => {
      imgLoading.value = false;
    });
};
</script>

<template>
  <div class="addContent">
    <div class="my-header">
      <div>
        {{ t("bot.bot25") }}
      </div>
      <el-icon
        class="el-icon--left"
        :class="'close-icon'"
        @click="closeDialog1"
        style="cursor: pointer"
      >
        <Close />
      </el-icon>
    </div>
    <div :class="step == 1 ? 'step1Height' : ''" class="flexDiv">
      <!-- 步骤 -->
      <stepView :list="stepList" :step="step"></stepView>
      <el-form
        ref="formRef"
        :model="props.botInfo"
        :rules="rules"
        label-position="top"
        v-show="step == 1"
      >
        <!-- 名称 -->
        <el-form-item prop="templateName" :label="$t('bot.bot28')">
          <el-input
            v-model="props.botInfo.templateName"
            :placeholder="$t('bot.bot29')"
            maxlength="20"
            show-word-limit
          />
        </el-form-item>
        <el-form-item prop="templateValue">
          <div class="settingBox">
            <div class="title"><span>*</span>{{ $t("bot.bot30") }}</div>
            <el-button
              :loading="perLoading"
              class="iconBox"
              @click="oneKeyPerfect(props.botInfo.templateValue)"
            >
              <img :src="icon.yjwsIcon" class="iconSet" alt="" />
              {{ $t("bot.bot31") }}
            </el-button>
          </div>
          <el-input
            v-model="props.botInfo.templateValue"
            maxlength="2000"
            :placeholder="$t('bot.bot32')"
            show-word-limit
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 10 }"
            style="color: #a8abb2"
          />
        </el-form-item>
        <el-form-item :label="$t('bot.bot34')">
          <div class="flexCenter wid100">
            <el-tooltip popper-class="toolTip" :content="t('bot.bot101')">
              <uploadIndex type="img" @beforeAvatarUpload="beforeAvatarUpload">
                <div
                  :class="props.botInfo.image_path ? 'txCenter' : 'step2Img'"
                >
                  <img
                    v-if="props.botInfo.image_path == null"
                    :src="icon.botImg"
                    alt=""
                    class="stepImgIcon"
                  />
                  <img
                    v-if="props.botInfo.image_path != null"
                    :src="
                      '/openai/chat/v4/image/get/' + props.botInfo.image_path
                    "
                    alt=""
                    class="stepImgIcons"
                  />
                </div>
              </uploadIndex>
            </el-tooltip>
            <div class="step2RightImg">
              <div class="pa">
                <img
                  :src="icon.leftReson"
                  v-if="imgList.length > 2"
                  alt=""
                  class="leftResonIcon"
                  @click="changeLeftReson(resonOne)"
                />
                <div class="imgFlex" ref="resonOne">
                  <div class="getImgLoading" v-if="getImgLoading">
                    <span class="loader"></span>
                  </div>
                  <div
                    v-for="(item, index) in imgList"
                    :key="item"
                    class="itemImgDiv"
                  >
                    <img
                      @click="changeImg(item, index)"
                      class="itemImg"
                      :src="'/openai/chat/v4/image/get/' + item.id"
                      alt=""
                    />
                    <img
                      :src="icon.imgYes"
                      alt=""
                      v-if="imgActive == index"
                      class="imgYes"
                    />
                  </div>
                </div>
                <img
                  :src="icon.rightReson"
                  v-if="imgList.length > 2"
                  alt=""
                  class="rightResonIcon"
                  @click="changeRightReson(resonOne)"
                />
              </div>
              <el-button
                class="stepImgAi"
                @click="addImg"
                :loading="imgLoading"
              >
                <div class="aiImgIcon">
                  <img :src="icon.imgAi" alt="" class="wenshengtuImg" />
                </div>
                <div class="aiImgTitle">{{ t("base.base29") }}</div>
              </el-button>
            </div>
          </div>
        </el-form-item>
        <div class="hightDiv" @click="highType = !highType">
          <div class="flexCenterHigh">
            <img :src="icon.highSvg" alt="" class="high" />
            <span class="hightTitle">{{ t("bot.bot104") }}</span>
          </div>
          <img :src="icon.botRight" alt="" class="botRight" v-if="!highType" />
          <img :src="icon.botBottom" class="botRight" alt="" v-else />
        </div>
        <el-collapse-transition>
          <div v-if="highType">
            <!-- 联网搜索 -->
            <div class="formName">
              <el-switch v-model="isInternetOn" />
              <div class="textMargin">{{ t("base.base226") }}</div>
            </div>
            <!-- 知识库 -->
            <div class="formName">
              <el-switch v-model="isKnowledgeOn" />
              <div class="textMargin">{{ t("bot.base159") }}</div>
              <el-tooltip popper-class="toolTip" :content="t('bot.base158')">
                <el-icon><Warning /></el-icon>
              </el-tooltip>
            </div>
            <el-select
              :placeholder="t('base.base268')"
              class="inputSelete"
              v-model="relativeKnowledgeId"
              v-selectLoadMore="goBase"
              :teleported="false"
            >
              <div style="overflow: hidden">
                <el-option
                  v-for="item in baseList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span class="dropText">{{ item.name }}</span>
                </el-option>
              </div>
            </el-select>
            <!-- 插件 -->
            <div class="flexBetween">
              <div class="formName">
                <el-switch v-model="isAgentOn" />
                <div class="textMargin">{{ t("bot.base160") }}</div>
                <el-tooltip popper-class="toolTip" :content="t('bot.base157')">
                  <el-icon><Warning /></el-icon>
                </el-tooltip>
              </div>
              <el-button class="addBtnDiv" @click="openTool">
                <img :src="icon.plugAdd" class="plugAddImg" alt="" />
                {{ $t("bot.bot47") }}
              </el-button>
            </div>
            <div class="plugDiv" v-for="item in toolList" :key="item.agentId">
              <div class="plugLeft">
                <img :src="getToolImg(item)" class="plugSelImg" alt="" />
                <div class="plugText">{{ item.agentName }}</div>
              </div>
              <div class="plugDeleteDiv" @click="deleteTool(item)">
                <img class="plugDelete" :src="icon.plugDelete" alt="" />
              </div>
            </div>
            <!-- 介绍 -->
            <el-form-item prop="templateDesc" :label="$t('bot.bot42')">
              <el-input
                v-model="props.botInfo.templateDesc"
                maxlength="2000"
                :placeholder="$t('bot.bot43')"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                show-word-limit
              />
            </el-form-item>
            <!-- 开场白 -->
            <el-form-item :label="$t('bot.bot44')" prop="templateCall">
              <el-input
                v-model="props.botInfo.templateCall"
                maxlength="2000"
                :placeholder="$t('bot.bot45')"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                show-word-limit
              />
            </el-form-item>
            <!-- 提问灵感 -->
            <el-form-item prop="suggest" class="suggest">
              <div class="inspirationBox">
                <div class="title">{{ $t("bot.bot46") }}</div>
                <div class="inspiration">
                  <el-input
                    v-if="inputVisible"
                    maxlength="30"
                    ref="InputRef"
                    v-model="inputValue"
                    @keyup.enter="handleInputConfirm"
                    @blur="handleInputConfirm"
                  />
                  <el-button
                    @click="showInput"
                    :disabled="dynamicTags?.length >= 3"
                    v-else
                    class="addBtnDiv"
                  >
                    <img :src="icon.plugAdd" class="plugAddImg" alt="" />
                    {{ $t("bot.bot47") }}
                  </el-button>
                </div>
              </div>
              <div>
                <el-tag
                  v-for="tag in dynamicTags"
                  :key="tag"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)"
                  class="blockTag"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-form-item>
          </div>
        </el-collapse-transition>
      </el-form>
      <!-- 第二步 -->
      <!-- 成员 -->
      <div v-if="step == 2">
        <div class="userFlex">
          <div class="names">{{ t("bot.base151") }}</div>
          <div class="addUser" @click="addMember">{{ t("bot.bot41") }}</div>
        </div>
        <el-row class="rightTable">
          <el-col :span="10" class="tableNmae">{{ t("base.base34") }}</el-col>
          <el-col :span="8" class="tableNmae">
            {{ t("base.base35") }}
            <!-- <el-tooltip popper-class="toolTip" raw-content content="暂无内容">
              <el-icon class="tipIcon"><Warning /></el-icon>
            </el-tooltip> -->
          </el-col>
          <el-col :span="6">{{ t("base.base36") }}</el-col>
        </el-row>
        <div class="maxHeight">
          <el-row
            class="tableItem"
            :class="index == props.botInfo.length - 1 ? 'tableBottom' : ''"
            v-for="(item, index) in props.botInfo.shareUserIds"
            :key="item.id"
          >
            <el-col :span="10" class="stepCenter padding10" v-if="item.name">
              <div class="userImg" :style="'background-color:' + item.color">
                {{ item.name?.slice(0, 1) }}
              </div>
              <span class="tableTitleNmae">{{ item.name }}</span>
            </el-col>
            <el-col :span="10" class="stepCenter padding10" v-else>
              <span class="tableTitleNmae">-</span>
            </el-col>
            <el-col :span="8" class="padding10">
              <div class="tableRoleType" v-if="item.type == 0">
                <div class="tableLogo">{{ t("base.base37").slice(0, 1) }}</div>
                <div class="tableRole">{{ t("base.base38") }}</div>
              </div>
              <div class="tableRole" v-if="item.type == 1">
                {{ t("base.base41") }}
              </div>
            </el-col>
            <el-col :span="6" class="stepCenter">
              <el-button
                class="deleUserBtn"
                @click="removeMember(item, index)"
                v-if="item.type != 0"
              >
                {{ t("base.base42") }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <!-- 取消 -->
      <el-button @click="closeDialog(formRef)" class="cancel">
        {{ $t("bot.bot48") }}
      </el-button>
      <!-- 上一步 -->
      <el-button @click="changeStep(1)" class="confirm" v-if="step == 2">
        {{ $t("base.base337") }}
      </el-button>
      <!-- 下一步 -->
      <el-button
        @click="changeStep(2)"
        class="confirm"
        v-if="step == 1"
        :disabled="getNextDisabled"
      >
        {{ $t("login.login11") }}
      </el-button>
      <!-- 新增 -->
      <el-button @click="submitBtn(formRef)" class="confirm" v-if="step == 2">
        {{ $t("base.base46") }}
      </el-button>
    </div>
  </div>
  <!-- 添加成员 -->
  <div class="mask"></div>
  <userView
    v-if="showTool"
    type="bot"
    :userList="userList"
    :userObj="userObj"
    @closeUser="showTool = false"
    @saveUser="saveUser"
  >
  </userView>
  <!-- 添加插件 -->
  <toolDialogView
    v-if="toolDialog"
    @closeTool="closeTool"
    @deleteTool="deleteTool"
    :toolList="toolList"
  ></toolDialogView>
</template>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:deep(.el-tag) {
  width: 300px;
}

:deep(.el-input__wrapper) {
  height: 44px;
  border-radius: 18px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 0;
}

:deep(.el-select) {
  width: calc(100% - 10px) !important;
}

:deep(.el-select__wrapper) {
  min-height: 40px !important;
  width: calc(100% - 4px) !important;
}

:deep(.el-collapse-item__header) {
  background-color: #f7f8ff;
}

:deep(.el-button.is-round) {
  border-radius: 16px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

:deep(.el-dialog) {
  --el-dialog-width: 610px;
  border-radius: 30px;
  padding: 30px;
}

:deep(.el-overlay) {
  z-index: 60 !important;
}

.suggest {
  margin-bottom: 40px;

  :deep(.el-tag.is-closable) {
    height: 40px;
    border-radius: 16px;
    background-color: #fff !important;
  }

  :deep(.el-tag__content) {
    margin: 0 6px;
    font-size: 15px;
  }

  :deep(.el-tag.el-tag--primary) {
    --el-tag-text-color: #606266 !important;
  }
}

.inspiration {
  :deep(.el-input__wrapper) {
    margin-top: 4px;
    border-radius: 8px;
    height: 30px;
  }
}

.blockTag {
  display: block;
  position: relative;
  height: 40px;
  line-height: center;
  border-radius: 16px;
  width: 510px;
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #fff;
  margin-bottom: 6px;
  border: solid 1px #dcdfe6;

  :deep(.el-tag__content) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    font-size: 16px;
    margin-top: 6px;
  }

  :deep(.el-tag__close) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.add-tag {
  height: 36px;
  border-radius: 12px;
  border: 0px;
  background-color: #eceeff;
  padding: 6px;
  color: #0256ff;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}

.pa {
  position: relative;
  height: 100%;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}

.add-tag2 {
  height: 30px;
  background-color: #f7f8ff;
  border-radius: 4px;
  padding: 0px 6px;
  color: #a8abb2;
  font-size: 15px;
  margin-top: 4px;
  cursor: pointer;
}

.flexDiv {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
}
.step1Height {
  height: 590px;
}
.close-icon {
  font-size: 20px;
}

.tagRight {
  margin-right: 6px;
}

.column2 {
  width: 160px;
}

.tableSet {
  height: 230px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
  margin-bottom: 24px;
}

.formIcon {
  margin-right: 4px;
  font-size: 18px;
  margin-top: 8px;
}
.textMargin {
  margin: 0px 6px;
}
// 图标
.flexCenter {
  display: flex;
}
.stepCenter {
  display: flex;
  align-items: center;
}
.wid100 {
  width: 100%;
}
.step2RightImg {
  background-color: #f7f8ff;
  height: 80px;
  border-radius: 18px;
  margin-left: 10px;
  width: 424px;
  padding: 12px 18px;
  display: flex;
  align-items: center;
}

.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e6ff;
  overflow: hidden;
}
.txCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.stepImgIcon {
  width: 60px;
}
.stepImgIcons {
  width: 100%;
  height: 100%;
}
.itemImgDiv {
  position: relative;
}

.itemImg {
  height: 100%;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}

.imgFlex {
  display: flex;
  overflow: hidden;
  max-width: 210px;
  margin-right: 10px;
  height: 100%;
}

.getImgLoading {
  width: 59px;
  margin-right: 10px;
  height: 59px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 28px;
    height: 28px;
    border: 4px solid #f6f6f6;
    border-bottom-color: #0256ff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.imgYes {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 16px;
}

.stepImgAi {
  border-radius: 12px;
  height: 49px;
  border: 0px;
  background-color: #eceeff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  cursor: pointer;
}

.aiImgIcon {
  width: 36px;
  height: 36px;
  background-color: #dbdfff;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.aiImgTitle {
  font-size: 18px;
  color: #0256ff;
  font-weight: 500;
}

.infoBtn {
  height: 42px;
  width: 100%;
  color: #fff;
  background-color: #3376ff;
  border-radius: 16px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 400;
}

.divTop {
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.btnSpan {
  margin-left: 10px;
}

// 添加成员
.button-new-tag {
  display: flex;
  justify-content: center;
  color: #0256ff;
  font-size: 15px;
  margin-left: 12px;
  width: 82px;
  background-color: #f7f8ff;
  text-align: center;
  padding: 0px 8px;
}

.inspirationBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.collapseBox {
  background-color: #f7f8ff;
}

.title-Icon {
  font-size: 16px;
  color: #8a8a8a;
  display: flex;
  height: 46px;
  line-height: 46px;

  .header-icon {
    color: #3d3d3d;
    width: 21px;
    height: 21px;
    margin: 12px;
  }
}

// 一键完善
.settingBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 14px;

  span {
    color: red;
    margin-right: 4px;
  }

  .iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border: 1px solid #dbdfff;
    background-color: #f7f8ff;
    box-shadow: 1px 1px 4px 0px #edeef6;
    border-radius: 8px;
    padding: 6px;
    color: #0256ff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    cursor: pointer;
    .iconSet {
      width: 26px;
      height: 26px;
      border-radius: 6px;
      margin-right: 8px;
    }
  }
}
.flexCenter1 {
  display: flex;
  align-items: center;
}

.itemText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    font-weight: 400;
    font-size: 18px;
    height: 48px;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    font-weight: 400;
    font-size: 18px;
    height: 48px;
  }
}
.high {
  width: 20px;
  margin-right: 12px;
}
.botRight {
  width: 24px;
}
.hightTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 500;
}
:deep(.el-form-item__label) {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 17px;
}
.formName {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}
.tableName {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
}
.title {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 17px;
}
:deep(.el-input__inner) {
  font-weight: 400;
  font-size: 18px;
}
.deleteText {
  color: #f02d63;
  font-weight: 400;
  font-size: 18px;
}
.hightDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f6;
  height: 48px;
  border-radius: 18px;
  padding: 0px 15px;
  margin-top: 24px;
  cursor: pointer;
}
.flexCenterHigh {
  display: flex;
  align-items: center;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 99;
  opacity: 0.3;
}
.addContent {
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 100;
  padding: 30px;
  border-radius: 30px;
}
:deep(.el-input__inner::placeholder) {
  font-weight: 400;
  font-size: 18px;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-weight: 400;
  font-size: 18px;
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  margin-right: 6px;
}
.nameHidden {
  max-width: calc(100% - 36px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  border-radius: 7px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 7px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #fefefe;
  left: 2px;
  border-radius: 4px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
  border-radius: 4px;
}
.inputSelete {
  width: 100%;
  :deep(.el-select__wrapper) {
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 0px 0px #ffffff;
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 500;
    height: 48px;
    background-color: transparent;
  }
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
.plugAddImg {
  width: 12px;
  margin-right: 6px;
}
.addBtnDiv {
  border: 1px solid #edeef6;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #edeef6;
  padding: 5px 9px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 13px;
}
.names {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.addUser {
  border-radius: 8px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 16px;
  cursor: pointer;
}
.rightTable {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #8a8a8a;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 12px 12px 0px 0px;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  padding-left: 10px;
}
.tipIcon {
  margin-left: 10px;
  font-size: 16px;
}
.maxHeight {
  max-height: 500px;
  overflow: auto;
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border: 1px solid #d9d9d9;
  border-top: 0px;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableBottom {
  border-radius: 0px 0px 12px 12px;
}
.padding10 {
  padding-left: 10px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableRole2 {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  margin-right: 6px;
}
.plugDiv {
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #edeef6;
  padding: 9px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.plugDelete {
  width: 18px;
  cursor: pointer;
}
.plugDeleteDiv {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plugDeleteDiv:hover {
  background-color: #f7f8ff;
  border-radius: 8px;
}
.plugSelImg {
  width: 23px;
  margin-right: 6px;
  border-radius: 4px;
}
.plugLeft {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  width: calc(100% - 28px);
}
.plugText {
  width: calc(100% - 29px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wenshengtuImg {
  width: 21px;
}
</style>
