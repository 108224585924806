<template>
  <div class="chunkIndex" v-if="!spType">
    <!-- pdf -->
    <pdf :pdfUrl="pdfUrl" v-if="pdfType"></pdf>
    <!-- txt -->
    <txt class="txt" :textData="textData" v-if="txtType"></txt>
    <!-- word -->
    <word :wordUrl="wordUrl" :flag="flag" v-if="wordType"></word>
    <!-- excel -->
    <excel v-if="excelType" :excelUrl="excelUrl"></excel>
    <!-- html -->
    <htmlView v-if="htmlType" :htmlData="htmlData"></htmlView>
    <!-- ppt -->
    <ppt :pptUrl="pptData" v-if="pptType" :flag="flag"></ppt>
  </div>
  <div v-else class="chunkIndex divCenter">
    <img :src="icon.noFileImg" alt="" class="noFileImg" />
    <div class="spBtn" @click="openWindow(spUrl)">
      <div>{{ t('base.base346') }}</div>
      <img :src="icon.spShare" class="spShare" />
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import pdf from "./pdf.vue";
import txt from "./txt.vue";
import excel from "./excel.vue";
import word from "./word.vue";
import htmlView from "./html.vue";
import ppt from "./ppt.vue";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const props = defineProps(["fileItem"]);
const userId = ref(store.state.userId);
const chatType = ref(store.state.chatType);
const pdfUrl = ref("");
const textData = ref("");
const excelUrl = ref("");
const wordUrl = ref("");
const htmlData = ref("");
const pptData = ref("");
const pdfType = ref(false);
const wordType = ref(false);
const excelType = ref(false);
const txtType = ref(false);
const htmlType = ref(false);
const pptType = ref(false);
const flag = ref(null);
onMounted(() => {
  if (chatType.value == "chat" && props.fileItem.type != 1) {
    getChatFile();
  } else {
    getFile();
  }
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => props.fileItem,
  (newValue, oldValue) => {
    if (chatType.value == "chat" && props.fileItem.type != 1) {
      getChatFile();
    } else {
      getFile();
    }
  }
);
// chat聊天文件查看
const getChatFile = () => {
  let fileType = props.fileItem.fileName
    .substring(props.fileItem.fileName.lastIndexOf(".") + 1)
    .toLowerCase()
    .replace(" ", "");
  pdfUrl.value = "";
  textData.value = "";
  wordUrl.value = "";
  flag.value = null;
  excelUrl.value = "";
  htmlData.value = "";
  pptData.value = "";
  pdfType.value = false;
  wordType.value = false;
  excelType.value = false;
  txtType.value = false;
  htmlType.value = false;
  pptType.value = false;
  if (fileType == "pdf") {
    prewViewPdf();
  } else if (fileType == "xlsx") {
    prewExcel();
  } else if (fileType == "docx") {
    prewWord();
  } else if (fileType == "txt") {
    prewTxt();
  } else if (fileType == "html") {
    prewHtml();
  } else if (fileType == "pptx") {
    prewPpt();
  }
};
const prewViewPdf = () => {
  api.chat
    .pdfFind({
      fileId: props.fileItem.fileId || props.fileItem.id,
      userId: userId.value,
      pageId: 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        pdfUrl.value = res.data.Url;
        pdfType.value = true;
      }
    });
};
const prewExcel = () => {
  api.chat
    .wordFind({
      fileId: props.fileItem.fileId || props.fileItem.id,
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        excelUrl.value = res.data;
        excelType.value = true;
      }
    });
};
const prewWord = () => {
  api.chat
    .wordFind({
      fileId: props.fileItem.fileId || props.fileItem.id,
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        wordUrl.value = res.data;
        flag.value = res.flag;
        wordType.value = true;
      }
    });
};
const prewTxt = () => {
  api.chat
    .txtFind({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        textData.value = res.data;
        txtType.value = true;
      }
    });
};
const prewHtml = () => {
  api.chat
    .txtFind({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        htmlData.value = res.data;
        htmlType.value = true;
      }
    });
};
const prewPpt = () => {
  api.chat
    .wordFind({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        pptData.value = res.data;
        flag.value = res.flag;
        pptType.value = true;
      }
    });
};
const spType = ref(false);
const spUrl = ref("");
// 微模型文件查询
const getFile = () => {
  console.log(props.fileItem.fileName);
  let fileType = props.fileItem.fileName
    .substring(props.fileItem.fileName.lastIndexOf(".") + 1)
    .toLowerCase()
    .replace(" ", "");
  pdfUrl.value = "";
  textData.value = "";
  wordUrl.value = "";
  flag.value = null;
  excelUrl.value = "";
  htmlData.value = "";
  pptData.value = "";
  pdfType.value = false;
  wordType.value = false;
  excelType.value = false;
  txtType.value = false;
  htmlType.value = false;
  pptType.value = false;
  spUrl.value = "";
  spType.value = false;
  console.log(fileType == "xlsx", fileType);
  if (fileType == "pdf") {
    goPdf();
  } else if (fileType == "txt") {
    goTxt();
  } else if (fileType == "doc" || fileType == "docx") {
    goWord();
  } else if (fileType == "xls" || fileType == "xlsx") {
    goExcel();
  }
};
// 跳出预览
const openWindow = (url) => {
  window.open(url);
};
// 预览pdf
const goPdf = () => {
  api.base
    .pdfView(
      {
        fileId: props.fileItem.fileId || props.fileItem.id,
        userId: userId.value,
        pageId: 1,
      },
      undefined
    )
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.resourcetype == 1) {
          spType.value = true;
          spUrl.value = res.data.url;
          return;
        }
        pdfUrl.value = res.data.url;
        pdfType.value = true;
      }
    });
};
// 预览txt
const goTxt = () => {
  api.base
    .getText(
      undefined,
      userId.value,
      props.fileItem.fileId || props.fileItem.id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.resourcetype == 1) {
          spType.value = true;
          spUrl.value = res.data.url;
          return;
        }
        textData.value = res.data.url;
        txtType.value = true;
      }
    });
};
// 预览word
const goWord = () => {
  api.base
    .wordView(
      {
        fileId: props.fileItem.fileId || props.fileItem.id,
        userId: userId.value,
      },
      undefined
    )
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.resourcetype == 1) {
          spType.value = true;
          spUrl.value = res.data.url;
          return;
        }
        wordUrl.value = res.data.url;
        flag.value = res.flag;
        wordType.value = true;
      }
    });
};
// 预览excel
const goExcel = () => {
  api.base
    .wordView(
      {
        fileId: props.fileItem.fileId || props.fileItem.id,
        userId: userId.value,
      },
      undefined
    )
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.resourcetype == 1) {
          spType.value = true;
          spUrl.value = res.data.url;
          return;
        }
        excelUrl.value = res.data.url;
        excelType.value = true;
      }
    });
};
</script>

<style lang="scss" scoped>
.chunkIndex {
  width: 100%;
  height: 100%;
}
.txt {
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
}
.spShare {
  width: 11px;
  margin-left: 11px;
}
.spBtn {
  background-color: #3376ff;
  border-radius: 16px;
  font-size: 20px;
  color: #fefefe;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 12px;
  cursor: pointer;
}
.noFileImg {
  width: 360px;
}
.divCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
