<script setup>
import { ref, computed, onMounted, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { icon } from "@/utils/icon";
import { message } from "ant-design-vue";
import toolDialogView from "./toolDialog.vue";
import userView from "@/views/base/user.vue";
const { t } = useI18n();
import { useStore } from "vuex";
import uploadIndex from "@/components/upload/index.vue";
import api from "@/api";
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
  botInfo: Object,
  botIsOwner: Number,
});
const emit = defineEmits(["closeDetail", "editBtn"]);
const navList = ref([
  {
    label: "bot.base167",
    value: 0,
  },
  {
    label: "bot.base155",
    value: 1,
  },
]);
const navActive = ref(0);
onMounted(() => {
  form.value = JSON.parse(JSON.stringify(props.botInfo));
  highType.value = true;
  isAgentOn.value = form.value.isAgentOn == 1 ? true : false;
  isInternetOn.value = form.value.isInternetOn == 1 ? true : false;
  isKnowledgeOn.value = form.value.isKnowledgeOn == 1 ? true : false;
  toolList.value = form.value.AgentIds;
  getBase();
});
const closeDialog = () => {
  emit("closeDetail");
};
// 修改确定
const editType = ref("detail");
const openEdit = () => {
  dynamicTags.value = props.botInfo.suggestQuestion;
  editType.value = "edit";
};
// 关闭编辑
const form = ref({});
const closeEdit = () => {
  editType.value = "detail";
  form.value = JSON.parse(JSON.stringify(props.botInfo));
  highType.value = true;
  isAgentOn.value = form.value.isAgentOn == 1 ? true : false;
  isInternetOn.value = form.value.isInternetOn == 1 ? true : false;
  isKnowledgeOn.value = form.value.isKnowledgeOn == 1 ? true : false;
  toolList.value = form.value.AgentIds;
};
// 保存编辑
const editBtn = () => {
  if (!form.value.templateName) {
    message.warning(t("bot.bot19"));
    return;
  }
  if (!form.value.templateValue) {
    message.warning(t("bot.bot20"));
    return;
  }
  form.value.userId = userId.value;
  form.value.suggest = dynamicTags.value;
  form.value.isAgentOn = isAgentOn.value ? 1 : 0;
  form.value.isInternetOn = isInternetOn.value ? 1 : 0;
  form.value.isKnowledgeOn = isKnowledgeOn.value ? 1 : 0;
  form.value.relativeKnowledgeId = relativeKnowledgeId.value;
  form.value.AgentIds = toolList.value.map((item) => item.agentId);
  emit("editBtn", form.value);
};
const getImg = computed(() => {
  if (props.botInfo.image_path) {
    return "/openai/chat/v4/image/get/" + props.botInfo.image_path;
  } else {
    return icon.botImg;
  }
});
const perLoading = ref(false);
const highType = ref(false);
// 一键完善
const oneKeyPerfect = (e) => {
  perLoading.value = true;
  api.bot
    .templatePerfect({
      message: e,
      templateName: form.value.templateName,
      templateValue: form.value.templateValue,
      userId: userId.value,
    })
    .then((res) => {
      perLoading.value = false;
      if (res.returnCode === 200) {
        form.value.templateCall = res.data.templateCall;
        form.value.templateDesc = res.data.templateDesc;
        form.value.templateValue = res.data.templateValue;
        highType.value = true;
        message.success(t("bot.bot18"));
      }
    })
    .catch((err) => {
      perLoading.value = false;
    });
};
// 图标事件

const imgLoading = ref(false);
const getImgLoading = ref(false);
const imgList = ref([]);
const imgActive = ref(0);
// 微模型上传头像
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      getImgLoading.value = true;
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          // 创建 Blob 对象
          const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
          // 生成 URL
          const imageUrl = URL.createObjectURL(blob);
          getImgLoading.value = false;
          form.value.image_path = res.data;
          imgList.value.push({
            id: res.data,
            url: imageUrl,
          });
          imgActive.value = imgList.value.length - 1;
        })
        .catch((error) => console.log(error));
    }
  });
};
// 向左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 选择微模型头像
const changeImg = (item, index) => {
  imgActive.value = index;
  form.value.image_path = item.id;
};
const isAgentOn = ref(false);
const isInternetOn = ref(false);
const isKnowledgeOn = ref(false);
const relativeKnowledgeId = ref(null);
// ai生成图片
const addImg = () => {
  imgLoading.value = true;
  api.base
    .imageGenerate({
      userId: userId.value,
      message: props.botInfo.templateName,
    })
    .then((res) => {
      imgLoading.value = false;
      if (res.returnCode == 200) {
        getImgLoading.value = true;
        fetch(`/openai/chat/v4/image/get/${res.data}`)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            // 创建 Blob 对象
            const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
            // 生成 URL
            const imageUrl = URL.createObjectURL(blob);
            getImgLoading.value = false;
            imgList.value.push({
              id: res.data,
              url: imageUrl,
            });
            imgActive.value = imgList.value.length - 1;
            form.value.image_path = res.data;
          })
          .catch((error) => (getImgLoading.value = false));
      }
    })
    .catch((err) => {
      imgLoading.value = false;
    });
};
const baseList = ref([]);
const basePageId = ref(1);
const basePerPage = ref(10000);
const goBase = () => {
  basePageId.value++;
  getBase();
};
// 获取知识库列表
const getBase = () => {
  api.base
    .customLibraryList({
      userId: userId.value,
      namePattern: "",
      pageId: basePageId.value,
      perPage: basePerPage.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (basePageId.value == 1) {
          baseList.value = res.data;
        } else {
          baseList.value = baseList.value.concat(res.data);
        }
        let result = baseList.value.findIndex(
          (item) => item.id == props.botInfo.relativeKnowledgeId
        );
        if (result == -1) {
          relativeKnowledgeId.value = null;
        } else {
          relativeKnowledgeId.value = props.botInfo.relativeKnowledgeId;
        }
      }
    });
};
// 打开插件
const toolDialog = ref(false);
const openTool = () => {
  toolDialog.value = true;
};
const closeTool = () => {
  toolDialog.value = false;
};
// 删除插件
const toolList = ref([]);
const deleteTool = (item) => {
  toolList.value = toolList.value.filter(
    (items) => items.agentId !== item.agentId
  );
};
// 获取插件头像
const getToolImg = (item) => {
  if (item.image_path) {
    return "/openai/chat/v4/image/get/" + item.image_path;
  } else {
    return icon.plugUpload;
  }
};
const inputValue = ref(null);
const dynamicTags = ref([]);
const inputVisible = ref(false);
const InputRef = ref(null);
const handleInputConfirm = () => {
  if (inputValue.value) {
    dynamicTags.value.push(inputValue.value);
  }
  inputVisible.value = false;
  inputValue.value = "";
};
const showInput = () => {
  inputVisible.value = true;
  nextTick(() => {
    if (InputRef.value) {
      InputRef.value.focus();
    }
  });
};
const handleClose = (tag) => {
  dynamicTags.value.splice(dynamicTags.value.indexOf(tag), 1);
};
// 移除成员
const deleteUser = (item, index) => {
  form.value.shareUserIds.splice(index, 1);
};
// 打开添加成员
const addDialog = ref(false);
const userList = ref([]);
const userObj = ref({});
const openUser = () => {
  // 判断部门 群组  人
  // 使用reduce方法将ownerType为0，1，2的对象分别筛选出来
  const result = form.value.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    if (item.ownerType == 0) {
      item.userId = Number(item.id);
    }
    if (item.ownerType == 1) {
      item.departmentId = Number(item.id);
    }
    if (item.ownerType == 2) {
      item.groupId = item.id;
    }
    acc[ownerType].push(item);
    return acc;
  }, {});

  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  userObj.value = userList.value.userList.filter((item) => item.type == 0);
  addDialog.value = true;
};
// 确定保存
const saveUser = (arr) => {
  const index = arr.findIndex((user) => user.id === userId.value);
  if (index == -1) {
    arr.unshift({
      userId: userId.value,
      id: userId.value,
      type: 0, //0 拥有者 1 创作者 2 普通用户
      userName: store.state.userName,
      name: store.state.userName,
      ownerType: 0, //0个人 1部门 2群组
      color: store.state.color,
    });
  }
  // 去重
  const seenIds = new Set();
  form.value.shareUserIds = arr.filter((item) => {
    const id = item.id;
    if (!seenIds.has(id)) {
      seenIds.add(id);
      return true;
    }
    return false;
  });
  addDialog.value = false;
};
</script>

<template>
  <div class="addContent">
    <div class="my-header">
      <div>
        {{ editType == "edit" ? t("bot.base173") : t("bot.bot106") }}
      </div>
      <el-icon class="close-icon" @click="closeDialog" style="cursor: pointer">
        <Close />
      </el-icon>
    </div>
    <div>
      <!-- 信息 -->
      <div class="topDiv">
        <img class="botImg" :src="getImg" alt="" />
        <div>
          <div class="botName">{{ props.botInfo.templateName }}</div>
          <div class="nameTip">
            {{ props.botInfo.sessionNum }}
            {{ t("base.base353") }}
            {{ props.botInfo.userNum }}
            {{ t("base.base354") }}
          </div>
        </div>
      </div>
      <!-- 导航 -->
      <div class="custom-style">
        <el-segmented v-model="navActive" :options="navList" id="value">
          <template #default="scope">
            <div class="flex flex-col items-center gap-2 p-2">
              <div>{{ t(scope.item.label) }}</div>
            </div>
          </template>
        </el-segmented>
      </div>
      <!-- 介绍 -->
      <div v-if="navActive == 0" class="maxHeight">
        <template v-if="editType == 'edit'">
          <el-form :model="form" label-position="top">
            <!-- 名称 -->
            <el-form-item prop="templateName" :label="$t('bot.bot28')">
              <el-input
                v-model="form.templateName"
                :placeholder="$t('bot.bot29')"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
            <el-form-item prop="templateValue">
              <div class="settingBox">
                <div class="title"><span>*</span>{{ $t("bot.bot30") }}</div>
                <el-button
                  :loading="perLoading"
                  class="iconBox"
                  @click="oneKeyPerfect(form.templateValue)"
                >
                  <img :src="icon.yjwsIcon" class="iconSet" alt="" />
                  {{ $t("bot.bot31") }}
                </el-button>
              </div>
              <el-input
                v-model="form.templateValue"
                maxlength="2000"
                :placeholder="$t('bot.bot32')"
                show-word-limit
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 10 }"
                style="color: #a8abb2"
              />
            </el-form-item>
            <el-form-item :label="$t('bot.bot34')">
              <div class="flexCenter wid100">
                <el-tooltip popper-class="toolTip" :content="t('bot.bot101')">
                  <uploadIndex
                    type="img"
                    @beforeAvatarUpload="beforeAvatarUpload"
                  >
                    <div :class="form.image_path ? 'txCenter' : 'step2Img'">
                      <img
                        v-if="form.image_path == null"
                        :src="icon.botImg"
                        alt=""
                        class="stepImgIcon"
                      />
                      <img
                        v-if="form.image_path != null"
                        :src="'/openai/chat/v4/image/get/' + form.image_path"
                        alt=""
                        class="stepImgIcons"
                      />
                    </div>
                  </uploadIndex>
                </el-tooltip>
                <div class="step2RightImg">
                  <div class="pa">
                    <img
                      :src="icon.leftReson"
                      v-if="imgList.length > 2"
                      alt=""
                      class="leftResonIcon"
                      @click="changeLeftReson(resonOne)"
                    />
                    <div class="imgFlex" ref="resonOne">
                      <div class="getImgLoading" v-if="getImgLoading">
                        <span class="loader"></span>
                      </div>
                      <div
                        v-for="(item, index) in imgList"
                        :key="item"
                        class="itemImgDiv"
                      >
                        <img
                          @click="changeImg(item, index)"
                          class="itemImg"
                          :src="'/openai/chat/v4/image/get/' + item.id"
                          alt=""
                        />
                        <img
                          :src="icon.imgYes"
                          alt=""
                          v-if="imgActive == index"
                          class="imgYes"
                        />
                      </div>
                    </div>
                    <img
                      :src="icon.rightReson"
                      v-if="imgList.length > 2"
                      alt=""
                      class="rightResonIcon"
                      @click="changeRightReson(resonOne)"
                    />
                  </div>
                  <el-button
                    class="stepImgAi"
                    @click="addImg"
                    :loading="imgLoading"
                  >
                    <div class="aiImgIcon">
                      <img :src="icon.imgAi" alt="" class="wenshengtuImg" />
                    </div>
                    <div class="aiImgTitle">{{ t("base.base29") }}</div>
                  </el-button>
                </div>
              </div>
            </el-form-item>
            <div class="hightDiv" @click="highType = !highType">
              <div class="flexCenterHigh">
                <img :src="icon.highSvg" alt="" class="high" />
                <span class="hightTitle">{{ t("bot.bot104") }}</span>
              </div>
              <img
                :src="icon.botRight"
                alt=""
                class="botRight"
                v-if="!highType"
              />
              <img :src="icon.botBottom" class="botRight" alt="" v-else />
            </div>
            <el-collapse-transition>
              <div v-if="highType">
                <!-- 联网搜索 -->
                <div class="formName">
                  <el-switch v-model="isInternetOn" />
                  <div class="textMargin">{{ t("base.base226") }}</div>
                </div>
                <!-- 知识库 -->
                <div class="formName">
                  <el-switch v-model="isKnowledgeOn" />
                  <div class="textMargin">{{ t("bot.base159") }}</div>
                  <el-tooltip
                    popper-class="toolTip"
                    :content="t('bot.base158')"
                  >
                    <el-icon><Warning /></el-icon>
                  </el-tooltip>
                </div>
                <el-select
                  :placeholder="t('base.base268')"
                  class="inputSelete"
                  v-model="relativeKnowledgeId"
                  v-selectLoadMore="goBase"
                  :teleported="false"
                >
                  <div style="overflow: hidden">
                    <el-option
                      v-for="item in baseList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                      <span class="dropText">{{ item.name }}</span>
                    </el-option>
                  </div>
                </el-select>
                <!-- 插件 -->
                <div class="flexBetween">
                  <div class="formName">
                    <el-switch v-model="isAgentOn" />
                    <div class="textMargin">{{ t("bot.base160") }}</div>
                    <el-tooltip
                      popper-class="toolTip"
                      :content="t('bot.base157')"
                    >
                      <el-icon><Warning /></el-icon>
                    </el-tooltip>
                  </div>
                  <el-button class="addBtnDiv" @click="openTool">
                    <img :src="icon.plugAdd" class="plugAddImg" alt="" />
                    {{ $t("bot.bot47") }}
                  </el-button>
                </div>
                <div
                  class="plugDiv"
                  v-for="item in toolList"
                  :key="item.agentId"
                >
                  <div class="plugLeft">
                    <img :src="getToolImg(item)" class="plugSelImg" alt="" />
                    <div class="plugText">{{ item.agentName }}</div>
                  </div>
                  <div class="plugDeleteDiv" @click="deleteTool(item)">
                    <img class="plugDelete" :src="icon.plugDelete" alt="" />
                  </div>
                </div>
                <!-- 介绍 -->
                <el-form-item prop="templateDesc" :label="$t('bot.bot42')">
                  <el-input
                    v-model="form.templateDesc"
                    maxlength="2000"
                    :placeholder="$t('bot.bot43')"
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 5 }"
                    show-word-limit
                  />
                </el-form-item>
                <!-- 开场白 -->
                <el-form-item :label="$t('bot.bot44')" prop="templateCall">
                  <el-input
                    v-model="form.templateCall"
                    maxlength="2000"
                    :placeholder="$t('bot.bot45')"
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 5 }"
                    show-word-limit
                  />
                </el-form-item>
                <!-- 提问灵感 -->
                <el-form-item prop="suggest" class="suggest">
                  <div class="inspirationBox">
                    <div class="title">{{ $t("bot.bot46") }}</div>
                    <div class="inspiration">
                      <el-input
                        v-if="inputVisible"
                        maxlength="30"
                        ref="InputRef"
                        v-model="inputValue"
                        @keyup.enter="handleInputConfirm"
                        @blur="handleInputConfirm"
                      />
                      <el-button
                        @click="showInput"
                        :disabled="dynamicTags?.length >= 3"
                        v-else
                        class="addBtnDiv"
                      >
                        <img :src="icon.plugAdd" class="plugAddImg" alt="" />
                        {{ $t("bot.bot47") }}
                      </el-button>
                    </div>
                  </div>
                  <div>
                    <el-tag
                      v-for="tag in dynamicTags"
                      :key="tag"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag)"
                      class="blockTag"
                    >
                      {{ tag }}
                    </el-tag>
                  </div>
                </el-form-item>
              </div>
            </el-collapse-transition>
          </el-form>
        </template>
        <template v-if="editType == 'detail'">
          <div class="jsText">{{ t("bot.bot42") }}</div>
          <textarea
            :placeholder="t('bot.bot43')"
            class="textarea"
            :value="form.templateDesc"
            disabled
          ></textarea>
        </template>
      </div>
      <template v-else>
        <div class="userFlex">
          <div class="names">{{ t("bot.base151") }}</div>
          <div v-if="editType == 'edit'" class="addUser" @click="openUser">
            {{ t("bot.bot41") }}
          </div>
        </div>
        <el-row class="rightTable">
          <el-col
            :span="editType != 'edit' ? 12 : 10"
            class="flexCenterHigh padding10 tableNmae"
            >{{ t("base.base34") }}</el-col
          >
          <el-col
            :span="editType != 'edit' ? 12 : 8"
            class="flexCenterHigh padding10 tableNmae"
          >
            {{ t("base.base35") }}
          </el-col>
          <el-col :span="6" v-if="editType == 'edit'">{{
            t("base.base36")
          }}</el-col>
        </el-row>
        <div class="maxHeightTable">
          <el-row
            class="tableItem"
            :class="index == form.shareUserIds.length - 1 ? 'tableBottom' : ''"
            v-for="(item, index) in form.shareUserIds"
            :key="item.id"
          >
            <el-col
              :span="editType != 'edit' ? 12 : 10"
              class="flexCenterHigh padding10"
              v-if="item.name"
            >
              <div class="userImg" :style="'background-color:' + item.color">
                {{ item.name?.slice(0, 1) }}
              </div>
              <span class="tableTitleNmae">{{ item.name }}</span>
            </el-col>
            <el-col
              :span="editType != 'edit' ? 12 : 10"
              class="flexCenterHigh padding10"
              v-else
            >
              <span class="tableTitleNmae">-</span>
            </el-col>
            <el-col
              :span="editType != 'edit' ? 12 : 8"
              class="flexCenterHigh padding10"
            >
              <div class="tableRoleType" v-if="item.type == 0">
                <div class="tableLogo">{{ t("base.base37").slice(0, 1) }}</div>
                <div class="tableRole">{{ t("base.base38") }}</div>
              </div>
              <div class="tableRole" v-if="item.type == 1">
                {{ t("base.base41") }}
              </div>
            </el-col>
            <el-col :span="6" class="flexCenterHigh" v-if="editType == 'edit'">
              <el-button
                class="deleUserBtn"
                @click="deleteUser(item, index)"
                v-if="item.type != 0"
              >
                {{ t("base.base42") }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </template>
    </div>
    <div class="dialog-footer">
      <!-- 详情 -->
      <div @click="closeDialog" class="cancel" v-if="editType == 'detail'">
        {{ t("bot.bot48") }}
      </div>
      <div
        @click="openEdit"
        class="confirm"
        v-if="props.botIsOwner == 1 && editType == 'detail'"
      >
        {{ t("base.base5") }}
      </div>
      <!-- 编辑 -->
      <div class="cancel" @click="closeEdit" v-if="editType == 'edit'">
        {{ t("base.base113") }}
      </div>
      <div
        @click="editBtn"
        class="confirm"
        v-if="props.botIsOwner == 1 && editType == 'edit'"
      >
        {{ t("base.base47") }}
      </div>
    </div>
  </div>
  <div class="mask"></div>
  <!-- 添加插件 -->
  <toolDialogView
    v-if="toolDialog"
    @closeTool="closeTool"
    @deleteTool="deleteTool"
    :toolList="toolList"
  ></toolDialogView>
  <userView
    v-if="addDialog"
    type="bot"
    :userList="userList"
    :userObj="userObj"
    @closeUser="addDialog = false"
    @saveUser="saveUser"
  ></userView>
</template>

<style lang="scss" scoped>
.textarea {
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 0px;
  padding: 11px 20px;
  font-size: 18px;
  color: #696969;
  font-weight: 400;
  margin-bottom: 24px;
  resize: none;
  outline: none;
  height: 150px;
}
.jsText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 17px;
}
.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #fefefe;
  --el-border-radius-base: 10px;
  padding: 3px;
  :deep(.el-segmented__item:not(.is-disabled):not(.is-selected):hover) {
    background-color: #e4e4e4;
    border-radius: 8px;
  }
  :deep(.el-segmented__item) {
    padding: 5px 22px;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 500;
  }
  :deep(.is-selected) {
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 500;
  }
}
.topDiv {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 24px;
}
.botName {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.nameTip {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
}
.botImg {
  width: 52px;
  margin-right: 16px;
}
.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    font-weight: 400;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}
.close-icon {
  font-size: 20px;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 99;
  opacity: 0.3;
}
.addContent {
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 100;
  padding: 30px;
  border-radius: 30px;
}
:deep(.el-table th.el-table__cell) {
  background-color: #f6f6f6;
  font-size: 18px;
  color: #8a8a8a;
  height: 60px;
}
// 一键完善
.settingBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 14px;

  span {
    color: red;
    margin-right: 4px;
  }

  .iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border: 1px solid #dbdfff;
    background-color: #f7f8ff;
    box-shadow: 1px 1px 4px 0px #edeef6;
    border-radius: 8px;
    padding: 6px;
    color: #0256ff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    cursor: pointer;
    .iconSet {
      width: 26px;
      height: 26px;
      border-radius: 6px;
      margin-right: 8px;
    }
  }
}
.title {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 17px;
}
.flexCenter {
  display: flex;
}
.wid100 {
  width: 100%;
}
.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e6ff;
  overflow: hidden;
}
.txCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.stepImgIcon {
  width: 60px;
}
.step2RightImg {
  background-color: #f7f8ff;
  height: 80px;
  border-radius: 18px;
  margin-left: 10px;
  width: 424px;
  padding: 12px 18px;
  display: flex;
  align-items: center;
}
.pa {
  position: relative;
  height: 100%;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.imgFlex {
  display: flex;
  overflow: hidden;
  max-width: 210px;
  margin-right: 10px;
  height: 100%;
}
.getImgLoading {
  width: 59px;
  margin-right: 10px;
  height: 59px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 28px;
    height: 28px;
    border: 4px solid #f6f6f6;
    border-bottom-color: #0256ff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}
.itemImgDiv {
  position: relative;
}
.itemImg {
  height: 100%;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}
.imgYes {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 16px;
}
.stepImgAi {
  border-radius: 12px;
  height: 49px;
  border: 0px;
  background-color: #eceeff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  cursor: pointer;
}
.aiImgIcon {
  width: 36px;
  height: 36px;
  background-color: #dbdfff;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.wenshengtuImg {
  width: 21px;
}
.aiImgTitle {
  font-size: 18px;
  color: #0256ff;
  font-weight: 500;
}
.hightDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f6;
  height: 48px;
  border-radius: 18px;
  padding: 0px 15px;
  margin-top: 24px;
  cursor: pointer;
}
.flexCenterHigh {
  display: flex;
  align-items: center;
}
.high {
  width: 20px;
  margin-right: 12px;
}
.hightTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 500;
}
.botRight {
  width: 24px;
}
.formName {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}
.textMargin {
  margin: 0px 6px;
}
.inputSelete {
  width: 100%;
  :deep(.el-select__wrapper) {
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 0px 0px #ffffff;
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 500;
    height: 48px;
    background-color: transparent;
  }
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addBtnDiv {
  border: 1px solid #edeef6;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #edeef6;
  padding: 5px 9px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.plugDiv {
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #edeef6;
  padding: 9px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.plugLeft {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  width: calc(100% - 28px);
}
.plugSelImg {
  width: 23px;
  margin-right: 6px;
  border-radius: 4px;
}
.plugText {
  width: calc(100% - 29px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.plugDeleteDiv {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plugDeleteDiv:hover {
  background-color: #f7f8ff;
  border-radius: 8px;
}
.plugDelete {
  width: 18px;
  cursor: pointer;
}
.suggest {
  margin-bottom: 40px;

  :deep(.el-tag.is-closable) {
    height: 40px;
    border-radius: 16px;
    background-color: #fff !important;
  }

  :deep(.el-tag__content) {
    margin: 0 6px;
    font-size: 15px;
  }

  :deep(.el-tag.el-tag--primary) {
    --el-tag-text-color: #606266 !important;
  }
}
.inspirationBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.inspiration {
  :deep(.el-input__wrapper) {
    margin-top: 4px;
    border-radius: 8px;
    height: 30px;
  }
}
.addBtnDiv {
  border: 1px solid #edeef6;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #edeef6;
  padding: 5px 9px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.blockTag {
  display: block;
  position: relative;
  height: 40px;
  line-height: center;
  border-radius: 16px;
  width: 510px;
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #fff;
  margin-bottom: 6px;
  border: solid 1px #dcdfe6;

  :deep(.el-tag__content) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    font-size: 16px;
    margin-top: 6px;
  }

  :deep(.el-tag__close) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
.stepImgIcons {
  width: 100%;
  height: 100%;
}
.plugAddImg {
  width: 12px;
  margin-right: 6px;
}
.maxHeight {
  max-height: 400px;
  overflow: auto;
  padding: 20px 0px;
}
.maxHeightTable {
  max-height: 400px;
  overflow: auto;
  margin-bottom: 17px;
}
:deep(.el-input__inner::placeholder) {
  font-weight: 400;
  font-size: 18px;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-weight: 400;
  font-size: 18px;
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}
:deep(.el-form-item__label) {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 17px;
}
:deep(.el-input__inner) {
  font-weight: 400;
  font-size: 18px;
}
:deep(.el-input__wrapper) {
  height: 44px;
  border-radius: 18px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 0;
}
.userFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 13px;
}
.addUser {
  border-radius: 8px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 16px;
  cursor: pointer;
}
.rightTable {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #8a8a8a;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 12px 12px 0px 0px;
}
.tableNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  padding-left: 10px;
}
.names {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.padding10 {
  padding-left: 10px;
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border: 1px solid #d9d9d9;
  border-top: 0px;
  font-size: 18px;
  color: #8a8a8a;
}
.tableBottom {
  border-radius: 0px 0px 12px 12px;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  margin-right: 6px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
}
:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  border-radius: 7px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 7px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #fefefe;
  left: 2px;
  border-radius: 4px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
  border-radius: 4px;
}
</style>
