<template>
  <div class="addContent">
    <div class="titleFlex">
      <div class="addTitle">
        {{ t("base.base19") }}
      </div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeAdd" />
    </div>
    <stepView :list="stepList" :step="step"></stepView>
    <div class="addBody">
      <!-- 第一步 -->
      <template v-if="step == 1">
        <!-- 选择模式 -->
        <div class="flexBetWeen marginBtomm20px">
          <el-button
            v-for="item in navList"
            :key="item"
            :class="form.type == item.value ? 'itemDivNavActive' : 'itemDivNav'"
            @click="changeNav(item.value)"
          >
            <div>
              <img :src="item.url" alt="" class="navImg" />
              <div class="navText">
                {{ t(item.name) }}
              </div>
            </div>
          </el-button>
        </div>
        <!-- 知识库名称 -->
        <div>
          <span class="addLabel nameLabel">{{ t("base.base21") }}</span>
        </div>
        <el-input
          class="itemInput marginBtomm20px"
          maxlength="20"
          show-word-limit
          :placeholder="t('base.base22')"
          v-model="form.name"
        ></el-input>
        <!-- 描述 -->
        <div class="addLabel">{{ t("base.base23") }}</div>
        <el-input
          v-model="form.desc"
          class="textInput marginBtomm20px"
          maxlength="100"
          show-word-limit
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 5 }"
          :placeholder="t('base.base24')"
        ></el-input>
        <!-- 头像 -->
        <div class="marginBtomm20px addLabel">{{ t("base.base28") }}</div>
        <div class="flexCenter marginBtomm20px">
          <el-tooltip popper-class="toolTip" :content="t('base.base302')">
            <uploadIndex type="img" @beforeAvatarUpload="beforeAvatarUpload">
              <div :class="form.image_path ? 'txCenter' : 'step2Img'">
                <img
                  v-if="form.imageName == null || form.imageName == ''"
                  :src="icon.baseBImg"
                  alt=""
                  class="stepImgIcon"
                />
                <img
                  v-else
                  :src="'/openai/chat/v4/image/get/' + form.imageName"
                  alt=""
                  class="stepImgIcons"
                />
              </div>
            </uploadIndex>
          </el-tooltip>
          <div class="step2RightImg">
            <div class="pa">
              <img
                :src="icon.leftReson"
                v-if="imgList.length > 2"
                alt=""
                class="leftResonIcon"
                @click="changeLeftReson(resonOne)"
              />
              <div class="imgFlex" ref="resonOne">
                <div class="getImgLoading" v-if="getImgLoading">
                  <span class="loader"></span>
                </div>
                <div
                  v-for="(item, index) in imgList"
                  :key="item"
                  class="itemImgDiv"
                >
                  <img
                    @click="changeImg(item, index)"
                    class="itemImg"
                    :src="item.url"
                    alt=""
                  />
                  <img
                    :src="icon.imgYes"
                    alt=""
                    v-if="imgActive == index"
                    class="imgYes"
                  />
                </div>
              </div>
              <img
                :src="icon.rightReson"
                v-if="imgList.length > 2"
                alt=""
                class="rightResonIcon"
                @click="changeRightReson(resonOne)"
              />
            </div>
            <el-button class="stepImgAi" @click="addImg" :loading="imgLoading">
              <div class="aiImgIcon">
                <img :src="icon.imgAi" alt="" class="wenshengtuImg" />
              </div>
              <div class="aiImgTitle">{{ t("base.base29") }}</div>
            </el-button>
          </div>
        </div>
        <!-- 上传方式 -->
        <div class="addLabel marginBtomm20px">{{ t("base.base25") }}</div>
        <div class="flexBetWeen marginBtomm20px">
          <div
            v-for="(item, index) in upTypeList"
            :key="index"
            @click="changeFile(index, item)"
            :class="{
              bottomFileActive: fileActive == index,
              bottomFile: fileActive != index,
            }"
          >
            <div class="flexCenter">
              <img
                class="fileImg"
                :src="fileActive == index ? item.acIcon : item.icon"
                alt=""
              />
              <div :class="fileActive == index ? 'itemTextActive' : 'itemText'">
                {{ t(item.name) }}
              </div>
            </div>
            <el-tooltip
              effect="dark"
              :content="t(item.tip)"
              placement="top-start"
            >
              <div :class="fileActive == index ? 'fileTipActive' : 'fileTip'">
                {{ t(item.tip) }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </template>
      <!-- 第二部 -->
      <step2
        v-if="step == 2"
        :type="form.type"
        @sendForm="sendForm"
        :addForm="form"
        :disabled="false"
      ></step2>
      <!-- 第三步 -->
      <template v-if="step == 3">
        <!-- 添加用户 -->
        <div class="marginBtomm20px flexBetweenAdd">
          <span class="addLabel">{{ t("base.base32") }} </span>
          <el-button class="addUserBtn" @click="addUser">{{
            t("base.base33")
          }}</el-button>
        </div>
        <el-row class="rightTable">
          <el-col :span="10" class="tableNmae">{{ t("base.base34") }}</el-col>
          <el-col :span="8" class="tableNmae">
            {{ t("base.base35") }}
            <el-tooltip
              popper-class="toolTip"
              raw-content
              :content="t('base.base288')"
            >
              <el-icon class="tipIcon"><Warning /></el-icon>
            </el-tooltip>
          </el-col>
          <el-col :span="6">{{ t("base.base36") }}</el-col>
        </el-row>
        <el-row
          class="tableItem"
          v-for="(item, index) in form.shareUserIds"
          :key="item.id"
        >
          <el-col :span="10" class="flexCenter padding10" v-if="item.name">
            <div class="userImg" :style="'background-color:' + item.color">
              {{ item.name?.slice(0, 1) }}
            </div>
            <span class="tableTitleNmae">{{ item.name }}</span>
          </el-col>
          <el-col :span="10" class="flexCenter padding10" v-else>
            <span class="tableTitleNmae">-</span>
          </el-col>
          <el-col :span="8" class="padding10">
            <div class="tableRoleType" v-if="item.type == 0">
              <div class="tableLogo">{{ t("base.base37").slice(0, 1) }}</div>
              <div class="tableRole">{{ t("base.base38") }}</div>
            </div>
            <div class="tableRoleType1" v-if="item.type == 1">
              <div class="tableLogo1">{{ t("base.base39") }}</div>
              <div class="tableRole1">{{ t("base.base40") }}</div>
            </div>
            <div class="tableRole" v-if="item.type == 2">
              {{ t("base.base41") }}
            </div>
          </el-col>
          <el-col :span="6" class="flexCenter">
            <el-button
              class="deleUserBtn"
              @click="deleteUser(item, index)"
              v-if="item.type != 0"
            >
              {{ t("base.base42") }}
            </el-button>
            <el-dropdown
              @command="changeUser($event, item)"
              v-if="item.type != 0 && item.ownerType != 2"
            >
              <el-icon class="moreUser">
                <MoreFilled />
              </el-icon>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-if="item.type == 1" :command="2">
                    <span class="dropText">{{ t("base.base43") }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item :command="1" v-if="item.type == 2">
                    <span class="dropText">{{ t("base.base44") }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
        </el-row>
      </template>
    </div>
    <div class="addBottomBtn">
      <!-- 取消 -->
      <el-button class="closeBtn" @click="closeAdd">{{
        t("base.base45")
      }}</el-button>
      <!-- 上一步 -->
      <el-button class="nextBtn" v-if="step != 1" @click="changeStep('up')">{{
        t("base.base337")
      }}</el-button>
      <el-button
        class="nextBtn"
        v-if="step != 3"
        :disabled="getNextDisabled"
        @click="changeStep('next')"
        >{{ t("login.login11") }}</el-button
      >
      <!-- 新增 -->
      <el-button
        class="nextBtn"
        v-if="step == 3"
        @click="save"
        :loading="props.addLoading"
      >
        {{ t("base.base46") }}
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
  <userView
    v-if="addDialog"
    type="base"
    :userList="userList"
    :userObj="userObj"
    @closeUser="addDialog = false"
    @saveUser="saveUser"
  ></userView>
  <spView
    v-if="spDialog"
    @closeSp="closeSp"
    @saveSp="saveSp"
    @changeId="changeId"
    :form="form"
    :type="1"
  ></spView>
</template>

<script setup>
import { icon } from "@/utils/icon";
import uploadIndex from "@/components/upload/index.vue";
import spView from "./spView.vue";
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { message } from "ant-design-vue";
import userView from "./user.vue";
import stepView from "@/components/step/index.vue";
import step2 from "./step2.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["addLoading", "editObj"]);
const emit = defineEmits(["closeAdd", "saveAdd"]);
const store = useStore();
const fileActive = ref(0);
const userId = ref(store.state.userId);
const resonOne = ref(null);
const navList = ref([
  {
    name: "base.base11",
    url: icon.item1,
    value: 2,
  },
  {
    name: "base.base12",
    url: icon.excelImg,
    value: 3,
  },
]);
const upTypeList = ref([
  {
    name: "base.base48",
    icon: icon.addFile,
    acIcon: icon.addFileAc,
    tip: "base.base49",
    value: "file",
  },
  {
    name: "base.base332",
    icon: icon.spNo,
    acIcon: icon.spAc,
    tip: "base.base325",
    value: "sp",
  },
]);
const form = ref({
  userId: store.state.userId,
  name: "", //微模型名称
  desc: "", //微模型描述
  type: 2, //微模型类型
  shareUserIds: [], //知识库成员
  imageName: "",
  site: {
    siteurl: "",
    fileIds: [],
    folderIds: [],
    ParentfolderIds: [],
  },
  resourcetype: 0, //知识来源
  //分段设置
  segment: {
    type: 1, //0自动 1自定义
    segmentIdentifier: "", //分段标识符 自动传null 自定义给值必传 文档模式
    maximumSegmentLength: 0, //分段最大长度 自动传null 自定义给值必传 文档模式
    segmentOverlapRatio: 0, //分段重叠比例 自动传null 自定义给值必传 文档模式
    replaceType: false, //替换掉连续的空格、换行符和制表符 true开启 false关闭 自动传null 自定义给值必传 文档模式
    deleteType: false, //删除所有 URL 和电子邮件地址 true开启 false关闭 自动传null 自定义给值必传 文档模式
    maximumDescriptionLength: 0, //表格/文档最大描述长度 表格模式传  自动分段传null 自定义必传
    maximumReQueryCount: 0, //NL2SQL最大重查询次数 表格模式传  自动分段传null 自定义必传
  },
  //检索设置
  retrieval: {
    type: 3, //0向量检索 1全文检索 2混合检索
    semantic: 0.5, //语义0-1
    keywords: 0.5, //关键词 0-1
    topK: 10, //Top K
    score: 0.6, //Score 阈值0-1
    scoreType: true, //是否开启Score 阈值 true开启 false关闭
  },
  //平台数组
  resourcePlatform: [],
  promptId: -1,
});

const addDialog = ref(false);
const imgList = ref([]);
const imgActive = ref(null);
const getImgLoading = ref(false);
const userObj = ref({});
const userList = ref({
  departmentList: [],
  userList: [],
  gropuList: [],
}); //回显
const imgLoading = ref(false);
const stepList = ref([
  {
    name: t("bot.base152"),
    value: 1,
    tip: t("bot.base153"),
  },
  {
    name: t("bot.base154"),
    value: 2,
    tip: t("bot.base163"),
  },
  {
    name: t("bot.base162"),
    value: 3,
    tip: t("bot.base155"),
  },
]);
const step = ref(1);
watch(
  () => form.value.type,
  (newValue, oldValue) => {
    if (form.value.type == 2) {
      upTypeList.value = [
        {
          name: "base.base48",
          icon: icon.addFile,
          acIcon: icon.addFileAc,
          tip: "base.base49",
          value: "file",
        },
        {
          name: "base.base332",
          icon: icon.spNo,
          acIcon: icon.spAc,
          tip: "base.base325",
          value: "sp",
        },
      ];
    } else {
      upTypeList.value = [
        {
          name: "base.base48",
          icon: icon.addFile,
          acIcon: icon.addFileAc,
          tip: "base.base362",
          value: "file",
        },
      ];
    }
  }
);
onMounted(() => {
  // 新增设置本人为主创
  form.value.shareUserIds.push({
    userId: userId.value,
    id: userId.value,
    type: 0, //0 拥有者 1 创作者 2 普通用户
    userName: store.state.userName,
    name: store.state.userName,
    ownerType: 0, //0个人 1部门 2群组
    color: store.state.color,
  });
});
// 第二步返回的form
const sendForm = (obj) => {
  let newObj = JSON.parse(JSON.stringify(obj));
  form.value = { ...form.value, ...newObj };
};
// 下一步禁用
const getNextDisabled = computed(() => {
  if (step.value == 1) {
    if (!form.value.name) {
      return true;
    }
    return false;
  } else {
    // 检查 segment 部分
    if (form.value.segment.type == 2) {
      if (form.value.type == 2) {
        if (
          !form.value.segment.segmentIdentifier ||
          !form.value.segment.maximumSegmentLength ||
          !form.value.segment.segmentOverlapRatio
        ) {
          return true;
        }
      } else {
        if (
          !form.value.segment.maximumDescriptionLength ||
          !form.value.segment.maximumReQueryCount
        ) {
          return true;
        }
      }
    }

    // 检查 retrieval 部分
    const retrieval = form.value.retrieval;
    if (retrieval.type == 1 || retrieval.type == 2) {
      if (!retrieval.topK || (retrieval.scoreType && !retrieval.score)) {
        return true;
      }
    } else if (retrieval.type == 3) {
      if (
        !retrieval.topK ||
        !retrieval.semantic ||
        !retrieval.keywords ||
        (retrieval.scoreType && !retrieval.score)
      ) {
        return true;
      }
    }

    // 检查 resourcePlatform 部分
    if (form.value.resourcePlatform.length == 0) {
      return true;
    } else {
      for (let item of form.value.resourcePlatform) {
        if (!item.embedding || !item.ocr || !item.model) {
          return true;
        }
      }
    }

    return false;
  }
});
// 切换步骤
const changeStep = (type) => {
  // 上一步
  if (type == "up") {
    step.value--;
    if (step.value < 1) {
      step.value = 1;
    }
  } else {
    step.value++;
    if (step.value > 3) {
      step.value = 3;
    }
  }
};
// 子级id
const changeId = (arr) => {
  form.value.site.ParentfolderIds = arr;
};
// 关闭sp
const closeSp = () => {
  spDialog.value = false;
};
// 保存sp
const saveSp = (site) => {
  form.value.site = site;
  spDialog.value = false;
};

// 微模型上传头像
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      getImgLoading.value = true;
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          // 创建 Blob 对象
          const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
          // 生成 URL
          const imageUrl = URL.createObjectURL(blob);
          getImgLoading.value = false;
          form.value.imageName = res.data;
          console.log(res.data);
          imgList.value.push({
            id: res.data,
            url: imageUrl,
          });
          imgActive.value = imgList.value.length - 1;
        })
        .catch((error) => console.log(error));
    }
  });
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 选择微模型头像
const changeImg = (item, index) => {
  imgActive.value = index;
  form.value.imageName = item.id;
};
// ai生成图片
const addImg = () => {
  // if (!form.value.name) {
  //   message.warning(t("base.base22"));
  //   return;
  // }
  imgLoading.value = true;
  api.base
    .imageGenerate({
      userId: userId.value,
      message: form.value.name,
    })
    .then((res) => {
      imgLoading.value = false;
      if (res.returnCode == 200) {
        getImgLoading.value = true;
        fetch(`/openai/chat/v4/image/get/${res.data}`)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            // 创建 Blob 对象
            const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
            // 生成 URL
            const imageUrl = URL.createObjectURL(blob);
            getImgLoading.value = false;
            imgList.value.push({
              id: res.data,
              url: imageUrl,
            });
            imgActive.value = imgList.value.length - 1;
            form.value.imageName = res.data;
          })
          .catch((error) => (getImgLoading.value = false));
      }
    })
    .catch((err) => {
      imgLoading.value = false;
    });
};
// 更改成员权限
const changeUser = (e, item) => {
  item.type = e;
};
// 确定保存
const saveUser = (arr) => {
  const index = arr.findIndex((user) => user.id === userId.value);
  if (index == -1) {
    arr.unshift({
      userId: userId.value,
      id: userId.value,
      type: 0, //0 拥有者 1 创作者 2 普通用户
      userName: store.state.userName,
      name: store.state.userName,
      ownerType: 0, //0个人 1部门 2群组
      color: store.state.color,
    });
  }
  // 去重
  const seenIds = new Set();
  form.value.shareUserIds = arr.filter((item) => {
    const id = item.id;
    if (!seenIds.has(id)) {
      seenIds.add(id);
      return true;
    }
    return false;
  });
  addDialog.value = false;
};
//添加成员
const addUser = () => {
  // 判断部门 群组  人
  // 使用reduce方法将ownerType为0，1，2的对象分别筛选出来
  const result = form.value.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    if (item.ownerType == 0) {
      item.userId = Number(item.id);
    }
    if (item.ownerType == 1) {
      item.departmentId = Number(item.id);
    }
    if (item.ownerType == 2) {
      item.groupId = item.id;
    }
    acc[ownerType].push(item);
    return acc;
  }, {});

  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  userObj.value = userList.value.userList.filter((item) => item.type == 0);
  addDialog.value = true;
};
// 移除成员
const deleteUser = (item, index) => {
  form.value.shareUserIds.splice(index, 1);
};

// 取消
const closeAdd = () => {
  emit("closeAdd");
};
// 切换选择
const changeNav = (index) => {
  changeFile(0, upTypeList.value[0]);
  form.value.type = index;
};
const spDialog = ref(false);
const changeFile = (index, item) => {
  form.value.resourcetype = index;
  fileActive.value = index;
  if (item.value == "sp") {
    spDialog.value = true;
  } else {
    spDialog.value = false;
  }
};
// 保存
const save = () => {
  let newForm = JSON.parse(JSON.stringify(form.value));
  newForm.shareUserIds = newForm.shareUserIds.map((item) => {
    return {
      id: item.id,
      name: item.name,
      type: item.type,
      ownerType: item.ownerType,
      color: item.color,
    };
  });
  console.log(newForm);
  emit("saveAdd", newForm);
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 110;
  opacity: 0.3;
}
.addContent {
  position: fixed;
  width: 540px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 111;
  padding: 30px;
  border-radius: 30px;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.addBottomBtn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}
.closeBtn {
  height: 48px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
  padding: 12px 20px;
}
.nextBtn {
  height: 48px;
  border-radius: 16px;
  color: #fefefe;
  background-color: #3376ff;
  border: 0px;
  font-size: 18px;
  font-weight: 400;
  padding: 12px 20px;
  margin-left: 12px !important;
}
.addBody {
  max-height: 510px;
  overflow: auto;
  padding: 5px 5px;
  margin-top: 16px;
}
.navImg {
  width: 24px;
  height: 24px;
}
.itemText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
}
.itemTextActive {
  font-size: 18px;
  color: #0256ff;
  font-weight: 400;
}
.itemDivNav {
  border: 2px solid #dbdfff;
  background-color: #f1f2ff;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px #edeef6;
  height: 84px;
  width: 49%;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
}
.itemDivNavActive {
  border: 2px solid #3376ff;
  background-color: #f1f2ff;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px #edeef6;
  height: 84px;
  width: 49%;
  font-size: 18px;
  color: #0256ff;
  font-weight: 500;
}
.navText {
  margin-top: 8px;
}
:deep(.el-button.is-disabled:hover) {
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border: 2px solid #eceeff;
  background-color: #eceeff;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.marginBtomm20px {
  margin-bottom: 12px;
}
.addLabel {
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  position: relative;
}
.nameLabel::before {
  position: absolute;
  content: "*";
  color: #f02d63;
  right: -15px;
}
.itemInput {
  height: 48px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
}
.textInput {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}

:deep(.el-input__inner::placeholder) {
  font-weight: 400;
  font-size: 18px;
}
:deep(.el-input__wrapper) {
  border-radius: 16px;
  border: 1px solid #edeef6;
  box-shadow: 0px 0px 0px;
}
.fileImg {
  width: 23px;
  margin-right: 6px;
}
.fileTip {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTipActive {
  font-size: 16px;
  color: #a3acec;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.bottomFileActive {
  width: calc(49.5% - 48px);
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  padding: 14px 20px;
  border-radius: 18px;
  cursor: pointer;
  border: 2px solid #3376ff;
}
.bottomFile {
  width: calc(49.5% - 44px);
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #feffff;
  padding: 14px 20px;
  border-radius: 18px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  cursor: pointer;
}
.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c4ddfc;
  overflow: hidden;
}
.txCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.stepImgIcon {
  width: 60px;
}
.stepImgIcons {
  width: 100%;
  height: 100%;
}
.step2RightImg {
  background-color: #f7f8ff;
  height: 60px;
  border-radius: 18px;
  margin-left: 10px;
  width: calc(100% - 130px);
  padding: 12px 18px;
  display: flex;
  align-items: center;
}
.stepImgAi {
  border-radius: 12px;
  height: 49px;
  border: 0px;
  background-color: #eceeff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  cursor: pointer;
}
.aiImgIcon {
  width: 36px;
  height: 36px;
  background-color: #dbdfff;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.aiImgTitle {
  font-size: 18px;
  color: #0256ff;
  font-weight: 500;
}
.tipIcon {
  margin-left: 10px;
  font-size: 16px;
}
.rightTable {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #8a8a8a;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 12px 12px 0px 0px;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border: 1px solid #d9d9d9;
  border-top: 0px;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableIcon {
  margin-right: 15px;
  width: 24px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableRole2 {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableLogo1 {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #ffc300;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.tableRole1 {
  color: #ffc300;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.tableRoleType1 {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #feecb2;
  border-radius: 8px;
  justify-content: center;
}
.wenshengtuImg {
  width: 21px;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.addUserBtn {
  height: 36px;
  border-radius: 12px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  border: 0px;
  padding: 6px 20px;
}
.flexBetweenAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ptTip {
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 500;
  max-width: 300px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ptImg {
  height: 25px;
}
.ptDivItem {
  width: calc(50% - 6px);
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.ptDivItemActive {
  width: calc(50% - 6px);
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dbdfff;
  margin-bottom: 12px;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
}
.moreUser {
  margin-left: 10px;
  font-size: 20px;
}
.itemImg {
  height: 59px;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}
.imgFlex {
  display: flex;
  overflow: hidden;
  max-width: 230px;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.itemImgDiv {
  position: relative;
}
.getImgLoading {
  width: 59px;
  margin-right: 10px;
  height: 59px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 28px;
  height: 28px;
  border: 4px solid #f6f6f6;
  border-bottom-color: #0256ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.imgYes {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 16px;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  margin-right: 6px;
}
.tableNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  padding-left: 10px;
}
.padding10 {
  padding-left: 10px;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 1px solid #edeef6;
  border-radius: 16px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-weight: 400;
  font-size: 18px;
}
.flexWarp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
:deep(.el-button + .el-button) {
  margin-left: 0px;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
</style>
