<template>
  <div>
    <div class="headerFlex">
      <!-- 导航 -->
      <div class="custom-style">
        <el-segmented
          v-model="navActive"
          :options="navList"
          id="value"
          @change="changeNav"
        >
          <template #default="scope">
            <div
              :class="navActive == scope.item.value ? 'navActive' : 'navNoAc'"
              class="navCenter"
            >
              <div>{{ t(scope.item.label) }}</div>
              <div v-if="scope.item.value == 'unread' && unreadCount != 0">
                （{{ props.unreadCount }}）
              </div>
            </div>
          </template>
        </el-segmented>
      </div>
      <el-tooltip popper-class="toolTip" :content="t('header.header58')">
        <img
          :src="icon.clearMessage"
          alt=""
          class="clearMessage"
          @click="markAllItem"
        />
      </el-tooltip>
    </div>
    <div v-loading="loading">
      <div class="max700">
        <!-- 知识共享 -->
        <div v-if="navActive == 'Request'">
          <template v-if="list.length != 0">
            <TopicView
              v-for="item in list"
              :key="item.notificationId"
              :item="item"
              @goYz="goYz"
              @markItem="markItem"
              @deleteNotice="deleteNotice"
            ></TopicView>
            <div class="messageTipDiv" v-if="listLength != 0">
              <div class="messageLength">
                {{ t("header.header10") }} {{ listLength }}
                {{ t("header.header11") }}
              </div>
              <div class="messageGoShare" @click="goShare">
                <div>{{ t("header.header61") }}</div>
                <img :src="icon.bImg" alt="" class="bImg" />
              </div>
            </div>
          </template>
          <div v-if="!loading && list.length == 0" class="nodataCenter">
            <img :src="icon.noDataSvg" class="noDataSvg" alt="" />
          </div>
        </div>
        <!-- 更新公告 -->
        <div
          v-if="navActive == 'system'"
          v-infinite-scroll="loadList"
          :infinite-scroll-distance="5"
          :infinite-scroll-immediate="false"
        >
          <template v-if="list.length != 0">
            <versionView
              v-for="item in list"
              :key="item.notificationId"
              :item="item"
              @openLook="openLook"
              @markItem="markItem"
            ></versionView>
          </template>
          <div v-if="!loading && list.length == 0" class="nodataCenter">
            <img :src="icon.noDataSvg" class="noDataSvg" alt="" />
          </div>
        </div>
        <!-- 未读 -->
        <div
          v-if="navActive == 'unread'"
          v-infinite-scroll="loadList"
          :infinite-scroll-distance="5"
          :infinite-scroll-immediate="false"
        >
          <template v-if="list.length != 0">
            <div v-for="item in list" :key="item.notificationId">
              <versionView
                v-if="item.notificationType == 0"
                :item="item"
                @openLook="openLook"
                @markItem="markItem"
              ></versionView>
              <TopicView
                v-else
                :item="item"
                @goYz="goYz"
                @markItem="markItem"
                @deleteNotice="deleteNotice"
              ></TopicView>
            </div>
          </template>
          <div v-if="!loading && list.length == 0" class="nodataCenter">
            <img :src="icon.noDataSvg" class="noDataSvg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <tipView
    v-if="statusDialog"
    :title="t('base.base297')"
    :tip="t('base.base298')"
    :ok="t('base.base299')"
    :maskType="true"
    :markDown="false"
    @cancel="statusDialog = false"
    @ok="closeStatusDialog"
  ></tipView>
  <tipView
    v-if="lookIType"
    :title="t('base.base297')"
    :tip="lookObj.content"
    :ok="t('base.base299')"
    :maskType="true"
    :markDown="true"
    @cancel="lookIType = false"
    @ok="closeLook"
  ></tipView>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { onMounted, ref, watch } from "vue";
import TopicView from "./topic.vue";
import versionView from "./version.vue";
import tipView from "@/components/tipDialog/index.vue";
import api from "@/api";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
const router = useRouter();
const { t } = useI18n();
const store = useStore();
const route = useRoute();
const userId = ref(store.state.userId);
const navList = ref([
  {
    label: "header.header61",
    value: "Request",
  },
  {
    label: "header.header59",
    value: "system",
  },
  {
    label: "header.header60",
    value: "unread",
  },
]);
const statusDialog = ref(false);
const navActive = ref("Request");
const pageId = ref(1);
const perPage = ref(5);
const list = ref([]);
const listLength = ref(0);
const props = defineProps(["unreadCount"]);
const emit = defineEmits(["getHeaderList"]);
onMounted(() => {
  firstPage();
});
const firstPage = () => {
  pageId.value = 1;
  getList();
};
const loadList = () => {
  if (navActive.value == "Request") return;
  pageId.value++;
  getList();
};
const loading = ref(false);
const getList = () => {
  loading.value = true;
  api.header
    .getNotice({
      userId: userId.value,
      type: navActive.value,
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        if (pageId.value == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
        listLength.value = res.totalNum - list.value.length;
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
watch(
  () => route.path,
  (newValue, oldValue) => {
    firstPage();
  }
);
const closeStatusDialog = () => {
  statusDialog.value = false;
};
// 打开版本查看
const lookIType = ref(false);
const lookObj = ref({});
const openLook = (item) => {
  lookIType.value = true;
  lookObj.value = item;
};
const closeLook = () => {
  lookIType.value = false;
  lookObj.value = {};
};
// 去验证
const goYz = (item) => {
  if (item.status == 2) {
    statusDialog.value = true;
  } else {
    store.commit("SET_YZ", item);
    store.commit("SET_CHATTYPE", "base");
    router.push("/share/details");
  }
};
// 切换nav
const changeNav = () => {
  pageId.value = 1;
  list.value = [];
  getList();
};
// 去知识共享
const goShare = () => {
  store.commit("SET_MENUACTIVE", {
    name: "share",
    key: "/share",
    icon: icon.shareMenu,
    type: 1,
    active: icon.shareMenuAc,
    tip: "base.base286",
  });
  router.push("/share");
};
const markAllItem = () => {
  api.header
    .markreadNotice({
      userId: userId.value,
      readall: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        firstPage();
        emit("getHeaderList");
      }
    });
};
// 标记已读
const markItem = (item) => {
  api.header
    .markreadNotice({
      userId: userId.value,
      notificationIds: [item.notificationId],
    })
    .then((res) => {
      if (res.returnCode == 200) {
        firstPage();
        emit("getHeaderList");
      }
    });
};
const deleteNotice = (item) => {
  api.header
    .deleteNoticeList({
      userId: userId.value,
      notificationIds: [item.notificationId],
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base16"));
        firstPage();
        emit("getHeaderList");
      }
    });
};
defineExpose({
  getList,
  firstPage
});
</script>

<style lang="scss" scoped>
.max700 {
  max-height: 700px;
  overflow: auto;
  min-height: 300px;
}
.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #fefefe;
  --el-border-radius-base: 10px;
  padding: 3px;
  :deep(.el-segmented__item:not(.is-disabled):not(.is-selected):hover) {
    background-color: #e4e4e4;
    border-radius: 8px;
  }
  :deep(.el-segmented__item) {
    padding: 5px 22px;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 500;
  }
  :deep(.is-selected) {
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 500;
  }
}
.navCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navActive {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 500;
}
.navNoAc {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
}
.clearMessage {
  width: 20px;
  cursor: pointer;
}
.headerFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.messageTipDiv {
  border-radius: 16px;
  padding: 11px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messageLength {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
}

.messageGoShare {
  font-size: 16px;
  color: #3376ff;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.noDataSvg {
  width: 300px;
  height: 300px;
}

.nodataCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bImg {
  width: 9px;
  margin-left: 6px;
}
</style>
