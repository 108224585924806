<template>
  <div class="flexCenter">
    <el-dropdown
      placement="top"
      class="marginRgiht12"
      @command="changeqxd"
      :max-height="200"
      v-if="qxdList.length != 0"
    >
      <div class="imgDiv">
        <div class="imgCenter">
          <img :src="icon.fbl" alt="" class="fbl" />
          <span class="imgDrow">
            {{ qxdActive == null ? t("base.base254") : qxdActive }}
          </span>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="(item, index) in qxdList"
            :key="index"
            :command="item"
          >
            <img :src="icon.imgIcon" alt="" class="imgIcon" />
            <span class="itemDrap">{{ item }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown
      placement="top"
      class="marginRgiht12"
      @command="changebl"
      :max-height="200"
      v-if="blList.length != 0"
    >
      <div class="imgDiv">
        <div class="imgCenter">
          <img :src="icon.cc" alt="" class="cc" />
          <span class="imgDrow">
            {{ blActive == null ? t("base.base255") : blActive }}
          </span>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="(item, index) in blList"
            :key="index"
            :command="item"
          >
            <img :src="icon.imgIcon" alt="" class="imgIcon" />
            <span class="itemDrap">{{ item }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown
      placement="top"
      class="marginRgiht12"
      @command="changeStyle"
      :max-height="200"
      v-if="styleList.length != 0"
    >
      <div class="imgDiv">
        <div class="imgCenter">
          <img :src="icon.fg" alt="" class="fg" />
          <span class="imgDrow">
            {{ styleActive == null ? t("base.base256") : styleActive }}
          </span>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="(item, index) in styleList"
            :key="index"
            :command="item"
          >
            <img :src="icon.imgIcon" alt="" class="imgIcon" />
            <span class="itemDrap">{{ item }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api";
const emit = defineEmits([
  "changebl",
  "changeqxd",
  "changeStyle",
  "changeInput",
]);
const store = useStore();
const props = defineProps(["model"]);
const { t } = useI18n();
const qxdActive = ref(null);
const blActive = ref(null);
const styleActive = ref(null);
const userId = ref(store.state.userId);
// 清晰度
const qxdList = ref([]);
// 切换清晰度
const changeqxd = (e) => {
  qxdActive.value = e;
  emit("changeqxd", e);
};
// 比例
const blList = ref([]);
// 切换比例
const changebl = (e) => {
  blActive.value = e;
  emit("changebl", e);
};
// 风格
const styleList = ref([]);
// 切换风格
const changeStyle = (e) => {
  styleActive.value = e;
  emit("changeStyle", e);
};
defineExpose({
  changeqxd,
  changebl,
  changeStyle,
});
watch(
  () => props.model,
  (newValue, oldValue) => {
    if (props.model) {
      qxdList.value = [];
      blList.value = [];
      styleList.value = [];
      changeStyle(null);
      changebl(null);
      changeqxd(null);

      if (props.model == "dalle3") {
        getDall();
      }
      if (props.model == "通义万象") {
        getTywx();
      }
      if (props.model == "文心一格") {
        getWxyg();
      }
    }
  }
);
onMounted(() => {
  if (props.model) {
    qxdList.value = [];
    blList.value = [];
    styleList.value = [];
    changeStyle(null);
    changebl(null);
    changeqxd(null);
    if (props.model == "dalle3") {
      getDall();
    }
    if (props.model == "通义万象") {
      getTywx();
    }
    if (props.model == "文心一格") {
      getWxyg();
    }
  }
});
const getDall = () => {
  // 比例下拉
  api.chat
    .imgListGet({
      userId: userId.value,
      resourceType: "dalle3",
      option: "size",
    })
    .then((res) => {
      if (res.returnCode == 200) {
        blList.value = res.data;
        emit("changeInput");
      }
    });
  // 清晰度下拉
  api.chat
    .imgListGet({
      userId: userId.value,
      resourceType: "dalle3",
      option: "quality",
    })
    .then((res) => {
      if (res.returnCode == 200) {
        qxdList.value = res.data;
        emit("changeInput");
      }
    });
  // 风格下拉
  api.chat
    .imgListGet({
      userId: userId.value,
      resourceType: "dalle3",
      option: "style",
    })
    .then((res) => {
      if (res.returnCode == 200) {
        styleList.value = res.data;
        emit("changeInput");
      }
    });
};
const getTywx = () => {
  // 比例下拉
  api.chat
    .imgListGet({
      userId: userId.value,
      resourceType: "通义万象",
      option: "size",
    })
    .then((res) => {
      if (res.returnCode == 200) {
        blList.value = res.data;
        emit("changeInput");
      }
    });
};
const getWxyg = () => {
  // 比例下拉
  api.chat
    .imgListGet({
      userId: userId.value,
      resourceType: "文心一格",
      option: "size",
    })
    .then((res) => {
      if (res.returnCode == 200) {
        blList.value = res.data;
        emit("changeInput");
      }
    });
  // 风格下拉
  api.chat
    .imgListGet({
      userId: userId.value,
      resourceType: "文心一格",
      option: "style",
    })
    .then((res) => {
      if (res.returnCode == 200) {
        styleList.value = res.data;
        emit("changeInput");
      }
    });
};
</script>

<style lang="scss" scoped>
.marginRgiht12 {
  margin-right: 12px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.imgDiv {
  height: 32px;
  border: 1px solid #dbdfff;
  border-radius: 10px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.imgCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px 4.5px 3.5px 4.5px;
  height: calc(100% - 6px);
  background-color: #eceeff;
  border-radius: 8px;
}
.fbl {
  width: 16px;
  margin-right: 5px;
}
.imgDrow {
  font-size: 18px;
  color: #0256ff;
  font-weight: 500;
  white-space: nowrap;
}
.itemDrap {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  margin-left: 2px;
}
.cc {
  width: 19px;
  margin-right: 5px;
}
.fg {
  width: 25px;
  margin-right: 5px;
}
.imgIcon {
  width: 25px;
}
</style>
