<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(["closeReset", "submitReset"]);
const props = defineProps({
  showReset: Boolean,
  resetInfo: Object,
});
const showDia = ref(props.showReset);
watch(
  () => props.showReset,
  (newVal) => {
    showDia.value = newVal;
  }
);

const form = ref({
  isEmailInfo: true,
  auto: true,
  userId: userId.value,
  resetUserIds: [],
  newPassword: "",
});
watch(
  () => form.value,
  (newVal) => {
    form.value = newVal;
  }
);
// 取消
const handleCancel = () => {
  form.value = {
    newPassword: "",
    isEmailInfo: true,
    auto: true,
    userId: userId.value,
    resetUserIds: [props.resetInfo.userId],
  };
  showDia.value = !showDia.value;
  emit("closeReset");
};
//同意
const handleConfirm = () => {
  form.value.resetUserIds = [props.resetInfo.userId];
  if (form.value.auto == false) {
    showDia.value = !showDia.value;
    form.value.isEmailInfo = false;
    emit("submitReset", form.value);
    form.value.newPassword = "";
    form.value.auto = true;
  } else {
    showDia.value = !showDia.value;
    emit("submitReset", form.value);
  }
};
</script>

<template>
  <div>
    <!-- 重置密码 -->
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="showDia"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ t("manage.userForm.user65") }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div class="content">
        <el-divider />
        <el-form :model="form" class="formDetail">
          <el-form-item>
            <div class="form-name">
              <span class="tit">{{ t("manage.userForm.userName") }}:</span>
              <span class="comtent">{{ resetInfo.userName }}</span>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="form-name">
              <span class="tit">{{ t("manage.userForm.email") }}:</span>
              <span class="comtent">{{ resetInfo.email }}</span>
            </div>
          </el-form-item>
          <el-form-item prop="newPassword">
            <div>
              <el-checkbox
                v-model="form.auto"
                :label="$t('manage.userForm.user58')"
                size="large"
              />
              <div v-if="!form.auto">
                <div class="tit">{{ t("manage.userForm.user66") }}:</div>
                <el-input
                  v-model="form.newPassword"
                  :placeholder="$t('manage.userForm.user59')"
                  show-password
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-checkbox
              v-if="form.auto"
              v-model="form.isEmailInfo"
              :label="$t('manage.userForm.user56')"
              size="large"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div @click="handleCancel" class="cancel">
            {{ t("manage.userForm.user37") }}
          </div>
          <div @click="handleConfirm" class="confirm">
            {{ t("manage.userForm.user38") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  // height: 800px;
  padding: 30px;
}

:deep(.el-divider) {
  margin: 1px 0 10px !important;
  width: calc(100%) !important;
}

:deep(.el-input__wrapper) {
  height: 40px !important;
  width: 300px !important;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.close-icon {
  font-size: 20px;
}

.passWord {
  color: #f02d63;
  font-size: 12px;
}

.form {
  margin-top: 10px;
}

.tit {
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: 600;
  margin-right: 10px;
  color: #3d3d3d;
}

.comtent {
  font-size: 16px;
  margin-bottom: 8px;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
</style>
