<template>
  <div class="plugAdd">
    <!-- 标题 -->
    <div class="titleFlex">
      <div class="addTitle">
        {{ t("bot.base130") }}
        {{ props.testObj.functionName }}
      </div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeTest" />
    </div>
    <!-- 鉴权 -->
    <div class="names">{{ t("bot.base131") }}</div>
    <div class="optionDiv">
      <div class="optionText">
        {{ postForm.authorizationHas == 0 ? t("bot.base132") : "API Key" }}
      </div>
      <img :src="icon.botOption" class="botOption" alt="" @click="openAt" />
    </div>
    <!-- 可用工具 -->
    <div class="names">{{ t("bot.base133") }}</div>
    <el-table :data="tableData" class="toolTable">
      <el-table-column prop="parameterName" :label="t('bot.base134')" />
      <el-table-column :label="t('bot.base135')">
        <template #default="scope">
          <el-input v-model="scope.row.parameterValue"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <!-- 测试 -->
    <div class="testBtn" @click="test">{{ t("bot.base136") }}</div>
    <!-- 输出 -->
    <div class="names">{{ t("bot.base137") }}</div>
    <textarea class="urlInput" disabled v-model="textMessage"></textarea>
  </div>
  <!-- 鉴权 -->
  <atView
    v-if="atType"
    @closeAt="closeAt"
    @saveAt="saveAt"
    :form="postForm"
  ></atView>
  <div class="mask"></div>
</template>

<script setup>
import api from "@/api";
import { icon } from "@/utils/icon";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import atView from "./atView.vue";
import { message } from "ant-design-vue";
const emit = defineEmits(["closeTest"]);
const props = defineProps(["testObj", "form"]);
const tableData = ref([]);
const postForm = ref({});
onMounted(() => {
  tableData.value = JSON.parse(JSON.stringify(props.testObj.parameters));
  postForm.value = JSON.parse(JSON.stringify(props.form));
});
// 测试
const textMessage = ref("");
const test = () => {
  textMessage.value = ''
  const result = tableData.value.map((item) => {
    if (item.parameterValue === "") {
      delete item.parameterValue;
    }
    return item;
  });
  api.bot
    .toolSwaggerTest({
      userId: props.testObj.userId,
      functionName: props.testObj.functionName,
      swaggerDescription: props.testObj.swaggerDescription,
      parameters: result,
      authorizationHas: postForm.value.authorizationHas,
      authorizationName: postForm.value.authorizationName,
      authorizationValue: postForm.value.authorizationValue,
      authorizationType: postForm.value.authorizationType,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base213"));
        textMessage.value = JSON.parse(res.data);
      }
    });
};
// 打开鉴权
const atType = ref(false);
const openAt = () => {
  atType.value = true;
};
// 保存健全
const saveAt = (obj) => {
  postForm.value = { ...postForm.value, ...obj };
  atType.value = false;
};
// 关闭鉴权
const closeAt = () => {
  atType.value = false;
};
const closeTest = () => {
  emit("closeTest");
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.plugAdd {
  width: 720px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 131;
  padding: 30px;
  border-radius: 30px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 130;
  opacity: 0.3;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
  max-width: calc(100% - 14px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.names {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.optionDiv {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin-top: 17px;
  margin-bottom: 24px;
}
.optionText {
  width: calc(100% - 24px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.botOption {
  width: 24px;
  cursor: pointer;
}
.toolTable {
  width: 100%;
  border-radius: 12px;
  margin-top: 17px;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  :deep(.el-table thead) {
    background-color: #f6f6f6;
  }
}
.testBtn {
  background-color: #3376ff;
  border-radius: 16px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 24px;
}
.urlInput {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-top: 17px;
  padding: 11px 20px;
  font-size: 16px;
  font-weight: 400;
  height: 150px;
  resize: none;
  outline: none;
  overflow: auto;
  margin-bottom: 24px;
  background-color: #f6f6f6;
}
</style>
