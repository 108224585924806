<template>
  <div>
    <div
      v-for="(idea, index) in props.ideas"
      :key="index"
      @click="saveMessage(idea)"
    >
      <div class="name">{{ idea }}</div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  ideas: Array,
});
const emit = defineEmits(["saveMessage"]);
const saveMessage = (item) => {
  emit("saveMessage", item);
};
</script>
<style scoped lang="less">
.name {
  padding: 8px 12px;
  border: #edeef6 2px solid;
  background-color: #fefefe;
  border-radius: 12px;
  color: #3d3d3d;
  font-size: 18px;
  min-width: 30px;
  margin-bottom: 12px;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
}
</style>
