import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import util from './modules/util';
import api from '@/api';
import { icon } from '@/utils/icon';
export default createStore({
  state: {
    menuList: [
      {
        name: 'chat',
        key: '/chat',
        icon: icon.chatMenu,
      },
      {
        name: 'index',
        key: '/index',
        icon: icon.indexMenu,
      },
      {
        name: 'base',
        key: '/base',
        icon: icon.baseMenu,
        type: 1,
        active: icon.baseMenu,
        tip: 'base.base285',
      },
      {
        name: 'share',
        key: '/share',
        icon: icon.shareMenu,
        type: 1,
        active: icon.shareMenuAc,
        tip: 'base.base286',
      },
      {
        name: 'bot',
        key: '/bot',
        icon: icon.botMenu,
        type: 1,
        active: icon.botMenuAc,
        tip: 'base.base287',
      },
      {
        name: 'statistics',
        key: '/statistics',
        icon: icon.statisticsMenu,
        type: 1,
        active: icon.tokenAc,
        tip: '',
      },
      {
        name: 'manage',
        key: '/manage',
        icon: icon.manageMenu,
        type: 1,
        active: icon.mangeMenuAc,
        menuName: 'manage.department',//进行传参即可
      },
    ],
    menuActive: '/index',
    lang: 'CN',
    speedType: false,
    token: '',
    userId: null,
    avatar: '',
    departmentId: null,
    departmentName: null,
    email: '',
    roleId: null,
    sex: null,
    userName: "",
    menuType: true,//菜单类型
    topicItem: null,//对话详情
    baseItem: null,//知识库详情
    menuItem: {},//菜单列表信息
    chatType: '',//判断是chat还是知识库
    yzItem: null,//验证
    modelList: [],//模型列表
    baseDrapList: {},//拖动列表
    postObj: {},//首页 ctrl+k
    fileList: [],//首页文件列表
    color: '',//当前用户头像颜色
    botItem: {},//智能体详情
    chatParams: {},//传参存储
    baseDetailsRoute: {
      navActive: 0,
      topicActive: 0
    },//知识库详情点击返回的效果
    authState: '',
    authCode: ''
  },
  getters: {
  },
  mutations: {
    SET_SSO(state: any, data: any) {
      state.authState = data.state
      state.authCode = data.code
    },
    SET_MENUACTIVE(state: any, data: any) {
      state.menuItem = data
      state.menuActive = data.key
    },
    SET_LANG(state: any, data: any) {
      state.lang = data
    },
    SET_SPEEDTYPE(state: any, data: any) {
      state.speedType = data
    },
    SET_USERINFO(state: any, data: any) {
      state.userId = data.userInfo.userId
      state.token = data.token
      state.departmentId = data.userInfo.departmentId
      state.email = data.userInfo.email
      state.roleId = data.userInfo.roleId
      state.sex = data.userInfo.sex
      state.userName = data.userInfo.userName
      state.departmentName = data.userInfo.departmentName
      state.color = data.userInfo.color
      state.roles = data.rolesList
      state.menuActive = '/index'
    },
    CLEAR_TOKEN(state: any, data: any) {
      state.menuList = [
        {
          name: 'chat',
          key: '/chat',
          icon: icon.chatMenu,
        },
        {
          name: 'index',
          key: '/index',
          icon: icon.indexMenu,
        },
        {
          name: 'base',
          key: '/base',
          icon: icon.baseMenu,
          type: 1,
          active: icon.baseMenu,
          tip: 'base.base285',
        },
        {
          name: 'share',
          key: '/share',
          icon: icon.shareMenu,
          type: 1,
          active: icon.shareMenuAc,
          tip: 'base.base286',
        },
        {
          name: 'bot',
          key: '/bot',
          icon: icon.botMenu,
          type: 1,
          active: icon.botMenuAc,
          tip: 'base.base287',
        },
        {
          name: 'statistics',
          key: '/statistics',
          icon: icon.statisticsMenu,
          type: 1,
          active: icon.tokenAc,
          tip: '',
        },
        {
          name: 'manage',
          key: '/manage',
          icon: icon.manageMenu,
          type: 1,
          active: icon.mangeMenuAc,
          menuName: 'manage.department',//进行传参即可
        },
      ]
      state.menuActive = '/index'
      state.speedType = false
      state.token = ''
      state.userId = null
      state.departmentId = null
      state.departmentName = null
      state.email = ''
      state.roleId = null
      state.sex = null
      state.userName = ""
      state.menuType = true//菜单类型
      state.topicItem = null//对话详情
      state.baseItem = null//知识库详情
      state.menuItem = {}//菜单列表信息
      state.chatType = ''//判断是chat还是知识库
      state.yzItem = null//验证
      state.modelList = []//模型列表
      state.baseDrapList = {}//拖动列表
      state.postObj = {}//首页 ctrl+k
      state.fileList = []//首页文件列表
      state.color = ''//当前用户头像颜色
      state.botItem = {}//智能体详情
      state.chatParams = {}
      state.baseDetailsRoute = {
        navActive: 0,
        topicActive: 0
      }//知识库详情点击返回的效果
      state.authState = ''
      state.authCode = ''
    },
    SET_MENUTYPE(state: any, data: any) {
      state.menuType = data
    },
    SET_AVATAR(state: any, data: any) {
      state.avatar = data
    },
    SET_TOPIC(state: any, data: any) {
      state.topicItem = data
    },
    SET_BOT(state: any, data: any) {
      state.botItem = data
    },
    SET_BASEITEM(state: any, data: any) {
      state.baseItem = data
    },
    SET_MANGER(state: any, data: any) {
      state.menuItem.menuName = data
    },
    SET_CHATTYPE(state: any, data: any) {
      if (data != 'base') {
        state.baseDrapList = {}
      }
      state.chatType = data
    },
    SET_YZ(state: any, data: any) {
      state.yzItem = data
    },
    SET_INDEXGOSHARE(state: any) {
      state.menuItem = {
        name: 'share',
        key: '/share',
        icon: icon.shareMenu,
        type: 1,
        active: icon.shareMenuAc,
        tip: 'base.base286',
      }
      state.menuActive = '/share'
    },
    SET_INDEXGOBOT(state: any) {
      state.menuItem = {
        name: 'bot',
        key: '/bot',
        icon: icon.botMenu,
        type: 1,
        active: icon.botMenuAc,
        tip: 'base.base287',
      }
      state.menuActive = '/bot'
    },
    SET_MODEL(state: any, data: any) {
      state.modelList = data
    },
    SET_BASEDRAP(state: any, data: any) {
      state.baseDrapList = data
    },
    // 普通对话
    SET_TALK(state: any, data: any) {
      state.postObj = data
    },
    SET_FILELIST(state: any, data: any) {
      state.fileList = data
    },
    SET_CHATPARMAS(state: any, data: any) {
      state.chatParams = data
    },
    SET_BASEDETAILSROUTE(state: any, data: any) {
      state.baseDetailsRoute = data
    },
  },
  actions: {
  },
  modules: {
    util
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})
