<template>
  <div class="gptContent">
    <div class="flexBetween">
      <div class="flexCenter">
        <div class="titileLabel">
          <img class="gptDialogTab" :src="icon.gptDialogTab" alt="" />
        </div>
        <div class="gptTitle">{{ t("gpt.index63") }}</div>
        <div class="hdzcText" @click="reshDesc">
          <img class="step2Img" :src="icon.hdzcImg" alt="" />
          <div>{{ t("gpt.index64") }}</div>
        </div>
      </div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeGpt" />
    </div>
    <!-- 配置名称 -->
    <div class="name nameTop">{{ t("gpt.index65") }}</div>
    <div class="pzName">{{ props.gptItem.name }}</div>
    <div class="names">{{ t("gpt.index66") }}</div>
    <gptView
      @sendForm="sendForm"
      ref="gptRef"
      type="manage"
      :gptDesc="gptDesc"
      @changeBiodiversity="changeBiodiversity"
    ></gptView>
    <!-- 按钮 -->
    <div class="fooderDiv">
      <el-button class="cannel" @click="closeGpt">{{
        t("bot.bot48")
      }}</el-button>
      <el-button class="saveBtn" @click="saveGpt">{{
        t("base.base212")
      }}</el-button>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import api from "@/api";
import gptView from "@/components/chat/gpt.vue";
import { icon } from "@/utils/icon";
import { message } from "ant-design-vue";
import { nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useStore } from "vuex";
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(["closeGpt"]);
const props = defineProps(["gptItem"]);
onMounted(() => {
  getModelDesc();
});
// 回到最初
const changeBiodiversity = (nav) => {
  let parameterType = ["accurate", "balance", "creative"];
  api.gpt
    .llmGetDefault({
      userId: userId.value,
      parameterType: parameterType[nav],
    })
    .then((res) => {
      if (res.returnCode == 200) {
        gptDesc.value[parameterType[nav]] = res.data;
        nextTick(() => {
          if (gptRef.value) {
            gptRef.value.copyDesc(false);
          }
        });
      }
    });
};
const gptRef = ref(null);
const reshDesc = () => {
  gptRef.value.changeBiodiversity();
};
// 获取当前模型详情
const gptDesc = ref({
  customize: {
    frequencyPenalty: 0.5,
    maxRound: 5,
    maxTokens: 4096,
    outputFormat: 0,
    presencePenalty: 0.5,
    temperature: 0.2,
    topP: 0.75,
    isFrequencyPenaltyOn: 0,
    isMaxRoundOn: 0,
    isMaxTokensOn: 0,
    isOutputFormatOn: 0,
    isPresencePenaltyOn: 0,
    isTemperatureOn: 0,
    isTopPOn: 0,
  },
});
const getModelDesc = () => {
  gptDesc.value = {
    customize: {
      frequencyPenalty: 0.5,
      maxRound: 5,
      maxTokens: 4096,
      outputFormat: 0,
      presencePenalty: 0.5,
      temperature: 0.2,
      topP: 0.75,
      isFrequencyPenaltyOn: 0,
      isMaxRoundOn: 0,
      isMaxTokensOn: 0,
      isOutputFormatOn: 0,
      isPresencePenaltyOn: 0,
      isTemperatureOn: 0,
      isTopPOn: 0,
    },
  };
  api.gpt
    .llmDesc({
      userId: userId.value,
      resourceType: props.gptItem.resourceType,
      configurationId: props.gptItem.configurationId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        gptDesc.value = { ...res.data, ...gptDesc.value };
        nextTick(() => {
          if (gptRef.value) {
            gptRef.value.copyDesc(true);
          }
        });
      }
    });
};
const llmConfigurationDict = ref();
const sendForm = (obj) => {
  llmConfigurationDict.value = obj;
};
const saveGpt = () => {
  api.gpt
    .llmUpdata({
      userId: userId.value,
      resourceType: props.gptItem.resourceType,
      configurationId: props.gptItem.configurationId,
      llmConfigurationDict: llmConfigurationDict.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base103"));
        closeGpt();
      }
    });
};
const closeGpt = () => {
  emit("closeGpt");
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.pzName {
  border: 1px solid #d9d9d9;
  background-color: #f6f6f6;
  border-radius: 12px;
  padding: 11px 20px;
  font-size: 18px;
  color: #696969;
  font-weight: 400;
  margin-bottom: 24px;
}
.nameTop {
  margin-top: 24px;
  margin-bottom: 17px;
}
.name {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.names {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
  margin-bottom: 24px;
}
.name::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  margin-left: 5px;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.gptTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
  margin: 0px 12px;
}
.gptContent {
  width: 480px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 111;
  padding: 30px;
  border-radius: 18px;
  overflow: auto;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 110;
  opacity: 0.3;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.step2Img {
  width: 13px;
  margin-right: 6px;
}
.gptDialogTab {
  width: 20px;
}
.titileLabel {
  background-color: #767bfa;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.hdzcText {
  display: flex;
  border: 1px solid #edeef6;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 0px #edeef6;
  padding: 5px 9px;
  font-size: 16px;
  color: #696969;
  font-weight: 500;
  cursor: pointer;
}
.cannel {
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  padding: 11px 20px;
  color: #f02d63;
  font-size: 18px;
  font-weight: 400;
  height: 48px;
}
.saveBtn {
  border: 1px solid #3376ff;
  border-radius: 16px;
  padding: 11px 20px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  height: 48px;
  background-color: #3376ff;
}
.fooderDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}
</style>
