import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.inc(0.2)
NProgress.configure({ showSpinner: false });
import indexView from '@/views/index/index.vue'
import loginView from '@/views/login/index.vue'
import baseView from '@/views/base/index.vue'
import botView from '@/views/bot/index.vue'
import botDetailView from '@/views/bot/detail.vue'
import wordView from '@/views/bot/components/word.vue'
import imageView from '@/views/bot/components/image.vue'
import excelView from '@/views/bot/components/excel.vue'
import audioView from '@/views/bot/components/audio.vue'
import billView from '@/views/bot/components/bill.vue'
import chatView from '@/views/chat/index.vue'
import manageView from '@/views/manage/index.vue'
import statisticsView from '@/views/statistics/index.vue'
import shareIndex from '@/views/share/index.vue'
import baseDetails from '@/views/base/details.vue'
import shareDetails from '@/views/share/details.vue'
import shareView from '@/views/share/shareView.vue'
import lookAll from '@/views/lookAll/index.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: loginView
  },
  {
    path: '/index',
    name: 'index',
    component: indexView,
    meta: {
      type: 'menu',
      name: 'index'
    }
  },
  {
    path: '/base',
    name: 'base',
    component: baseView,
    meta: {
      type: 'menu',
      name: 'base'
    }
  },
  {
    path: '/base/details',
    name: 'baseDetails',
    component: baseDetails,
    meta: {
      type: 'base',
      name: 'base'
    }
  },
  {
    path: '/bot',
    name: 'bot',
    component: botView,
    meta: {
      type: 'menu',
      name: 'bot'
    }
  },
  {
    path: '/bot/detail',
    name: 'botDetail',
    component: botDetailView,
    meta: {
      type: 'bot',
      name: 'botDetail'
    }
  },
  {
    path: '/bot/components/word',
    name: 'wordTool',
    component: wordView,
    meta: {
      type: 'menu',
      name: 'bot'
    }
  },
  {
    path: '/bot/components/image',
    name: 'imageTool',
    component: imageView,
    meta: {
      type: 'menu',
      name: 'bot'
    }
  },
  {
    path: '/bot/components/excel',
    name: 'excelTool',
    component: excelView,
    meta: {
      type: 'menu',
      name: 'bot'
    }
  },
  {
    path: '/bot/components/audio',
    name: 'audioTool',
    component: audioView,
    meta: {
      type: 'menu',
      name: 'bot'
    }
  },
  {
    path: '/bot/components/bill',
    name: 'billTool',
    component: billView,
    meta: {
      type: 'menu',
      name: 'bot'
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: chatView,
    meta: {
      type: 'chat',
      name: 'chat'
    }
  },
  {
    path: '/manage',
    name: 'manage',
    component: manageView,
    meta: {
      type: 'menu',
      name: 'manage'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: statisticsView,
    meta: {
      type: 'menu',
      name: 'statistics'
    }
  },
  {
    path: '/share',
    name: 'share',
    component: shareIndex,
    meta: {
      type: 'menu',
      name: 'share'
    }
  },
  {
    path: '/share/details',
    name: 'shareDetails',
    component: shareDetails,
    meta: {
      type: 'yanzheng',
      name: 'share'
    }
  },
  {
    path: '/share/shareView',
    name: 'shareView',
    component: shareView,
    meta: {
      type: 'yanzheng',
      name: 'share'
    }
  },
  {
    path: '/lookAll',
    name: 'lookAll',
    component: lookAll,
    meta: {
      type: 'menu',
      name: 'lookAll'
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 当路由请求之前
router.beforeEach((to, from, next) => {
  NProgress.start();
  // 跳转页面不是登录 都验证token
  if (to.path != '/') {
    const token = store.state.token
    if (token == '') {
      router.push('/')
    } else {
      next();
    }
  } else {
    next();
  }
});
// 当路由请求之后：关闭进度条
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

export default router
