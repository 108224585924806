export default {
    CN: {
        name1: '免责声明',
        name2: '1.以下所有数据源自资源平台的账单数据，仅供参考，并不决定本平台的真实用量；以下账单均可能存在延迟和定期更新，各资源API的意外泄漏也可能导致成本的增加，请您知悉。',
        name3: '拒绝',
        name4: '同意并进入仪表盘',
        name5: '以下所有数据源自资源平台的账单数据，仅供参考，并不决定本平台的真实用量；以下账单均可能存在延迟和定期更新，各资源API的意外泄漏也可能导致成本的增加，请您知悉。',
        name6: '资源平台',
        name7: '资源组',
        name8: '统计周期',
        name9: '请选择时间',
        name10: '统计粒度',
        name11: '输入提问者用户名',
        name12: '至',
        name13: '开始时间',
        name14: '结束时间',
        name15: '当前资源组成本消耗金额',
        name16: '用户均耗',
        name17: '资源平台成本额粒度图表（CNY）',
        name18: '资源平台成本服务分布（CNY）',
        name19: '问题令牌消耗',
        name20: '回答令牌消耗',
        name21: '提示词令牌消耗',
        name22: '文本嵌入令牌消耗',
        name23: '成本概览',
        name24: '用户用量',
        name25: 'Azure',
        name26: '百度智能云',
        name27: '阿里云',
        name28: '过去30天',
        name29: '自定义',
        name30: '日',
        name31: '月',
        name32: '输入',
        name33: '输出',
        name34: '统计粒度：设置统计图表中显示的最小颗粒度（当前用于：成本额粒度图表）',
        name35: '访问仪表盘',
        name36: '环比增率',
        name37: '过去7天',
        name38: 'Ollama',
    },
    HK: {
        name1: "免責聲明",
        name2: "1.以下所有數據源自資源平台的帳單數據，僅供參考，並不決定本平台的真實用量；以下帳單均可能存在延遲和定期更新，各資源API的意外洩漏也可能導致成本的增加，請您知悉。",
        name3: "拒絕",
        name4: "同意並進入儀錶盤",
        name5: "以下所有數據源自資源平台的帳單數據，僅供參考，並不決定本平台的真實用量；以下帳單均可能存在延遲和定期更新，各資源API的意外洩漏也可能導致成本的增加，請您知悉。",
        name6: "資源平臺",
        name7: "資源組",
        name8: "統計週期",
        name9: "請選擇時間",
        name10: "統計粒度",
        name11: "輸入提問者使用者名",
        name12: "至",
        name13: "開始時間",
        name14: "結束時間",
        name15: "當前資源組成本消耗金額",
        name16: "使用者均耗",
        name17: "資源平臺成本額粒度圖表（CNY）",
        name18: "資源平臺成本服務分佈（CNY）",
        name19: "問題令牌消耗",
        name20: "回答令牌消耗",
        name21: "提示詞令牌消耗",
        name22: "文本嵌入令牌消耗",
        name23: "成本概覽",
        name24: "使用者用量",
        name25: "Azure",
        name26: "百度智能雲",
        name27: "阿裡雲",
        name28: "過去30天",
        name29: "自定義",
        name30: "日",
        name31: "月",
        name32: '輸入',
        name33: '輸出',
        name34: '統計粒度：設定統計圖表中顯示的最小顆粒度（目前用於：成本額粒度圖表）',
        name35: '訪問儀表板',
        name36: '環比增率',
        name37: '過去7天',
        name38: 'Ollama',
    },
    US: {
        name1: "Disclaimer",
        name2: "1.The data provided below is sourced from the billing data of the resource platform, and is for reference only. It does not determine the actual usage on this platform. The billing information below may be subject to delays and periodic updates, and unexpected leaks from resource APIs could also result in increased costs. Please be aware of this.",
        name3: "Reject",
        name4: "Agree and enter the dashboard",
        name5: "The data provided below is sourced from the billing data of the resource platform, and is for reference only. It does not determine the actual usage on this platform. The billing information below may be subject to delays and periodic updates, and unexpected leaks from resource APIs could also result in increased costs. Please be aware of this.",
        name6: "Resource Platform",
        name7: "Resource Group",
        name8: "Statistical Period",
        name9: "Please select time",
        name10: "Statistical Granularity",
        name11: "Enter the questioner's username",
        name12: "To",
        name13: "Start Time",
        name14: "End Time",
        name15: "Current resource composition cost consumption amount",
        name16: "Average User Consumption",
        name17: "Resource platform cost granularity chart (CNY)",
        name18: "Resource platform cost service distribution (CNY)",
        name19: "Question Token Consumption",
        name20: "Answer Token Consumption",
        name21: "Prompt Word Token Consumption",
        name22: "Text Embedding Token Consumption",
        name23: "Cost Overview",
        name24: "User Usage",
        name25: "Azure",
        name26: "Baidu Intelligent Cloud",
        name27: "Alibaba Cloud",
        name28: "The past 30 days",
        name29: "Custom",
        name30: "Day",
        name31: "Month",
        name32: 'In',
        name33: 'Out',
        name34: 'Granularity: Set the minimum granularity displayed in the statistical charts (currently used for cost amount granularity charts).',
        name35: 'Visit the dashboard.',
        name36: 'MOM',
        name37: 'The past 7 days',
        name38: 'Ollama',
    }
};
