<script setup>
import { returnModel } from "@/utils/gpt";
import { ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emit = defineEmits(["handleCancel", "handleAdd"]);
const props = defineProps({
  openShow: Boolean,
  resoureInfo: Object,
});
watch(
  () => props.resoureInfo,
  (newVal) => {
    props.resoureInfo = newVal;
  }
);
onMounted(() => {});
const title = ref(t("manage.resoureForm.resoure84"));
const editNum = ref(true);
const limitMessages = ref({
  limitAliImageTimes: "manage.resoureForm.resoure65",
  limitBaiduImageTimes: "manage.resoureForm.resoure65",
  limitImageTimes: "manage.resoureForm.resoure65",
  limitDocumentChatNum: "manage.resoureForm.resoure66",
  limitDocumentNum: "manage.resoureForm.resoure66",
  limitAzureChunkTimes: "menu.index28",
  limitBaiduChunkTimes: "menu.index28",
  limitAliChunkTimes: "menu.index28",
  limitAzureOcrTimes: "menu.index31",
  limitBaiduOcrTimes: "menu.index31",
  limitAliOcrTimes: "menu.index31",
  limit35Token: "manage.resoureForm.resoure67",
  limit4Token: "manage.resoureForm.resoure67",
  limit4oToken: "manage.resoureForm.resoure67",
  limit4ominiToken: "manage.resoureForm.resoure67",
  limitQianFanModel1Token: "manage.resoureForm.resoure67",
  limitQianFanModel2Token: "manage.resoureForm.resoure67",
  limitQianFanModel3Token: "manage.resoureForm.resoure67",
  limitqwenMaxTokens: "manage.resoureForm.resoure67",
  limitqwenTurboTokens: "manage.resoureForm.resoure67",
  limitqwenPlusTokens: "manage.resoureForm.resoure67",
  limitDeepseekTokens: "manage.resoureForm.resoure67",
  limitLocalChunkTimes: "menu.index28",
  limitLocalImageTimes: "manage.resoureForm.resoure65",
  limitLocalOcrTimes: "menu.index31",
});
const limit = ref({});
// input失焦
const newObj = ref({});
const changeInput = (e) => {
  limit.value[e.limit.limitType] = e.limit.value;
  Object.assign(newObj.value, limit.value);
};
// 拒绝
const handleCancel = () => {
  emit("handleCancel");
};
//同意
const handleConfirm = () => {
  emit("handleAdd");
};
// 详情里面的移除按钮
const handleDelete = () => {};
</script>

<template>
  <!-- 白名单详情 -->
  <div>
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="props.openShow"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ title }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <el-divider />
      <div class="content">
        <div class="name">{{ $t("manage.resoureForm.resoure24") }}</div>
      </div>
      <div class="introduce">
        {{ props.resoureInfo.description }}
      </div>
      <div class="addUser">
        <div class="left">{{ $t("manage.resoureForm.resoure85") }}</div>
      </div>
      <el-table :data="props.resoureInfo.groups" class="table">
        <el-table-column
          prop="groupName"
          :label="$t('manage.resoureForm.resoure86')"
        />
      </el-table>
      <div class="addUser">
        <div class="left">{{ $t("manage.resoureForm.resoure89") }}</div>
        <!-- <div class="twoBtn">
                    <div class="oneBtn">添加资源</div>
                    <div @click="editNumBtn" v-if="editNum" class="oneBtn">编辑配额</div>
                    <div @click="submitNumBtn" v-if="!editNum" class="submitBtn">完成编辑</div>
                </div> -->
      </div>
      <el-table :data="props.resoureInfo.resourceInfo" class="table">
        <el-table-column :label="$t('manage.resoureForm.resoure81')">
          <template #default="scope">
            <div class="flexCenter">
              <img :src="returnModel(scope.row.icon)" class="gptImg" alt="" />
              <div>{{ scope.row.modelType }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="functionType"
          :label="$t('manage.resoureForm.resoure82')"
        />
        <el-table-column
          prop="limit"
          :label="$t('manage.resoureForm.resoure83')"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.limit.value"
              :disabled="editNum"
              type="number"
              :max="2147483647"
              @blur="changeInput(scope.row)"
            >
              <template #append>
                <div v-if="limitMessages[scope.row.limit.limitType]">
                  {{ $t(limitMessages[scope.row.limit.limitType]) }}
                </div>
              </template>
            </el-input>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <div class="dialog-footer">
          <div @click="handleCancel" class="cancel">
            {{ $t("manage.resoureForm.resoure90") }}
          </div>
          <el-button
            @click="handleConfirm"
            class="confirm"
            :disabled="$hasPermission('10030') == false"
            >{{ $t("manage.resoureForm.resoure92") }}</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-divider) {
  margin: 1px 0 10px !important;
  width: calc(100%) !important;
}

:deep(.el-dialog) {
  --el-dialog-width: 756px;
  border-radius: 30px;
  padding: 30px;
  max-height: 872px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.twoBtn {
  display: flex;

  .oneBtn {
    width: 80px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 8px;
    background-color: #3376ff;
    cursor: pointer;
    margin-left: 10px;
    color: #fff;
  }
}

.submitBtn {
  width: 80px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 8px;
  background-color: #767bfa;
  cursor: pointer;
  margin-left: 10px;
  color: #fff;
}
.close-icon {
  font-size: 20px;
}

.content {
  display: flex;
  color: #3d3d3d;

  .name {
    font-size: 24px;
    font-weight: 600;
    margin-right: 6px;
  }

  .tag {
    display: flex;
    justify-content: space-between;
    background-color: #dbdfff;
    color: #0256ff;
    padding: 0px 6px;
    height: 28px;
    line-height: 28px;
    border-radius: 6px;
    font-size: 16px;
    margin: auto 0;
  }
}

.introduce {
  margin-top: 12px;
  font-size: 18px;
  height: 25px;
  line-height: 25px;
}

.addUser {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  .left {
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 600;
    height: 36px;
    line-height: 36px;
  }

  .add {
    width: 112px;
    height: 36px;
    font-size: 18px;
    color: #fff;
    background-color: #3376ff;
    line-height: 36px;
    text-align: center;
    border-radius: 12px;
  }
}

.table {
  margin-top: 12px;
  width: 100%;
  height: 220px;
}

.form-name {
  margin-top: 12px;
  font-size: 18px;
  color: #3d3d3d;

  .el-tag {
    display: flex;
    padding: 2px 33px 2px 2px;
    width: 144px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    font-size: 18px;
    margin-top: 12px;

    .tag-img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .tit {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .comtent {
    margin-bottom: 12px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
.gptImg {
  width: 24px;
  margin-right: 6px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
</style>
