<script setup>
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(["handleClose", "handleSave", "editSave", "addSave"]);
const props = defineProps({
  uploadDepartment: Boolean,
  departmentInfo: Object,
  formType: String,
});
const show = ref(props.uploadDepartment);
watch(
  () => props.uploadDepartment,
  (newVal) => {
    show.value = newVal;
  }
);
onMounted(() => {
  getRole();
  getDepart();
  getUserList();
});
const defaultProps = ref({
  children: "children",
  label: "departmentName",
  id: "departmentId",
});
// 部门树形列表
const departmentList = ref([]);
const getDepart = () => {
  api.manage
    .getDepartmentList({ userId: userId.value, pageId: 0 })
    .then((res) => {
      if (res.returnCode == 200) {
        departmentList.value = res.data;
      }
    });
};
// 角色树形列表
const roleList = ref([]);
const getRole = () => {
  api.manage.queryUserRole({ userId: userId.value, pageId: 0 }).then((res) => {
    if (res.returnCode == 200) {
      roleList.value = res.data;
    }
  });
};

// 取消
const handleClose = () => {
  formRef.value.resetFields();
  show.value = !show.value;
  emit("handleClose");
  form.value = {};
};
const handleClose2 = () => {
  show.value = !show.value;
  emit("handleClose");
  form.value = {};
};
const handleClose1 = () => {
  formRef2.value.resetFields();
  show.value = !show.value;
  emit("handleClose");
};
const rules = ref({
  departmentName: [
    {
      required: true,
      message: t("manage.userForm.user26"),
    },
    { min: 1, max: 20, message: t("login.login67"), trigger: "blur" },
  ],
  parentId: [{ required: true, message: t("manage.userForm.user27") }],
});
const formRef = ref(null);
//保存新增
const handleSave = async (formRef) => {
  (props.departmentInfo.userId = userId.value),
    await formRef.validate((valid) => {
      if (valid) {
        show.value = !show.value;
        emit("handleSave", props.departmentInfo);
      }
    });
};
// 关闭编辑/保存
const editSave = async (formRef) => {
  await formRef.validate((valid) => {
    if (valid) {
      show.value = !show.value;
      emit("editSave", props.departmentInfo);
    }
  });
};
const getTree = () => {
  loading.value = true;
  getUserList();
};
// 部门负责人下拉
const userForm = ref({
  userId: userId.value,
  departmentId: props.departmentInfo.departmentId,
  activate: 1,
  ifDirectly: false,
});
const loading = ref(false);
const userList = ref([]);
const getUserList = () => {
  api.manage.getUserList(userForm.value).then((res) => {
    if (res.returnCode == 200) {
      userList.value = res.data;
      loading.value = false;
    }
  });
};
const form = ref({
  departmentName: "",
  userId: userId.value,
});
const formRef2 = ref(null);
const rules2 = ref({
  departmentName: [
    {
      required: true,
      message: t("manage.userForm.user28"),
    },
    { min: 1, max: 20, message: t("login.login67"), trigger: "blur" },
  ],
});
const addSave = async (formRef2) => {
  await formRef2.validate((valid) => {
    if (valid) {
      show.value = !show.value;
      emit("addSave", form.value);
      form.value = {};
      getUserList();
    }
  });
};
</script>

<template>
  <div>
    <el-dialog
      :z-index="100"
      destroy-on-close
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>
            {{
              props.formType == "add"
                ? t("manage.userForm.user29")
                : props.formType == "edit"
                ? t("manage.userForm.user30")
                : t("manage.userForm.user18")
            }}
          </div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleClose2"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <el-divider class="divider" />
      <div class="content">
        <el-form
          :model="form"
          ref="formRef2"
          :rules="rules2"
          label-position="top"
          v-if="props.formType == 'addSmall'"
        >
          <el-form-item
            prop="departmentName"
            :label="$t('manage.userForm.user31')"
          >
            <el-input
              class="comtent"
              v-model="form.departmentName"
              :placeholder="$t('manage.userForm.user32')"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form
          :model="props.departmentInfo"
          class="form"
          :rules="rules"
          ref="formRef"
          label-position="top"
          v-else
        >
          <el-form-item
            prop="departmentName"
            :label="$t('manage.userForm.user33')"
          >
            <el-input
              class="comtent"
              v-model="props.departmentInfo.departmentName"
              :placeholder="$t('manage.userForm.user34')"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="parentId"
            :label="$t('manage.userForm.user23')"
            v-if="props.formType === 'add' || props.formType === 'edit'"
          >
            <el-tree-select
              :data="departmentList"
              v-model="props.departmentInfo.parentId"
              check-strictly
              :render-after-expand="false"
              :props="defaultProps"
              node-key="departmentId"
            >
              <template #default="{ data }">
                <div>
                  {{ data.departmentName }}
                </div>
              </template>
            </el-tree-select>
          </el-form-item>
          <!-- <el-form-item prop="manager" v-if="props.formType == 'edit'" label="部门负责人">
                        <el-select v-loading="loading" @focus="getTree" v-model="props.departmentInfo.manager" clearable>
                            <el-option v-for="(items, index) in userList" :key="index" :label="items.userName"
                                :value="items.userId" />
                        </el-select>
                    </el-form-item> -->
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer" v-if="props.formType == 'add'">
          <div @click="handleClose(formRef)" class="cancel">
            {{ t("manage.userForm.user35") }}
          </div>
          <div @click="handleSave(formRef)" class="confirm">
            {{ t("manage.userForm.user36") }}
          </div>
        </div>
        <div class="dialog-footer" v-if="props.formType == 'edit'">
          <div @click="handleClose(formRef)" class="cancel">
            {{ t("manage.userForm.user37") }}
          </div>
          <div @click="editSave(formRef)" class="confirm">
            {{ t("manage.userForm.user38") }}
          </div>
        </div>
        <div class="dialog-footer" v-if="props.formType == 'addSmall'">
          <div @click="handleClose1(formRef2)" class="cancel">
            {{ t("manage.userForm.user37") }}
          </div>
          <div @click="addSave(formRef2)" class="confirm">
            {{ t("manage.userForm.user39") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  padding: 30px;
}

:deep(.el-input__wrapper) {
  height: 38px !important;
  width: 520px !important;
  margin-bottom: 10px !important;
}

:deep(.el-select__wrapper) {
  height: 42px !important;
  border-radius: 16px !important;
  width: 540px !important;
  margin-bottom: 10px !important;
}

:deep(.el-form-item__label) {
  margin-top: 10px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.close-icon {
  font-size: 20px;
}
.divider {
  margin-bottom: 18px;
}
.content {
  font-size: 18px;
  color: #8a8a8a;

  .name {
    color: #3d3d3d;
    font-size: 24px;
    margin: auto 6px auto 18px;
    font-weight: 500;
  }

  .avatar {
    height: 50px;
    width: 50px;
  }

  .tag {
    display: flex;
    justify-content: space-between;
    background-color: #b8e6cd;
    color: #14ae5c;
    padding: 0px 6px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 18px;
      height: 18px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .tag_cuo {
    display: flex;
    justify-content: space-between;
    background-color: #fbd4df;
    color: #f02d63;
    padding: 0px 6px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 18px;
      height: 18px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .reset {
    color: #0256ff;
    margin: auto 10px;
  }
}

.form {
  margin-top: 20px;
}

.form-name {
  font-size: 18px;
  color: #3d3d3d;

  .el-tag {
    display: flex;
    padding: 2px 33px 2px 2px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    font-size: 18px;
    margin-top: 12px;

    .tag-img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .tit {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .comtent {
    margin-bottom: 12px;
  }
}

.isActive {
  margin-right: 6px;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
</style>
