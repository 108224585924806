<template>
  <div class="capDialogClassSet">
    <div class="capTitle">
      <div>{{ t("share.index1") }}</div>
      <img
        class="crossSvug"
        :src="icon.crossSvug"
        alt=""
        @click="closeRecordReject"
      />
    </div>
    <el-input
      :placeholder="t('share.index2')"
      show-word-limit
      class="catText"
      type="textarea"
      v-model="leftNotes"
    />
    <div class="capBtnDiv">
      <el-button class="capCancel" @click="closeRecordReject">{{
        t("base.base45")
      }}</el-button>
      <el-button
        class="capSave"
        :loading="props.noDialogLoading"
        @click="saveRecordReject"
        >{{ t("base.base212") }}</el-button
      >
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const leftNotes = ref("");
const emit = defineEmits(["saveRecordReject", "closeRecordReject"]);
const props = defineProps(["noDialogLoading"]);
const saveRecordReject = () => {
  emit("saveRecordReject", leftNotes.value);
};
const closeRecordReject = () => {
  emit("closeRecordReject");
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  z-index: 39;
}
.capDialogClassSet {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 566px;
  border-radius: 16px;
  background-color: #f7f8ff;
  z-index: 40;
  padding: 17px;
  cursor: default;
}
.capTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 30px;
}
.crossSvug {
  width: 24px;
  cursor: pointer;
}
.catText {
  margin-bottom: 24px;

  :deep(.el-textarea__inner) {
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    padding: 12px 20px;
    resize: none;
    outline: none;
    font-weight: 400;
    font-size: 18px;
  }
}
.capBtnDiv {
  text-align: right;
}
.capCancel {
  height: 48px;
  color: #f02d63;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 20px;
}
.capSave {
  background-color: #3376ff;
  border: 0px;
  border-radius: 16px;
  height: 48px;
  font-size: 20px;
  font-weight: 400;
  color: #fefefe;
  padding: 10px 20px;
}
</style>
