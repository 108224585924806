<template>
  <!-- 上传进度 -->
  <div class="processDialog">
    <div class="processBetWeen">
      <div class="processTop">
        <img
          :src="icon.ok"
          alt=""
          class="img36"
          v-if="props.processList.length == 0"
        />
        <div class="processTopTitle">
          {{
            props.processList.length == 0
              ? t("base.base92")
              : t("base.base93") + props.processList.length
          }}
        </div>
      </div>
      <div>
        <img
          :src="icon.down"
          alt=""
          class="img36"
          v-if="!processType"
          @click="processType = !processType"
        />
        <img
          :src="icon.up"
          alt=""
          class="img36"
          v-else
          @click="processType = !processType"
        />
        <img
          v-if="props.processList.length == 0"
          :src="icon.closeSvg"
          alt=""
          class="img36"
          @click="closeProcess"
        />
      </div>
    </div>
    <el-collapse-transition>
      <div v-if="!processType">
        <!-- 进度中 -->
        <div class="processItemDiv" v-if="props.processList.length != 0">
          <div :style="'width:' + getContSum + '%'" class="processItem"></div>
          <div class="zindex">{{ t("base.base94") }}{{ getContSum }}%</div>
          <div class="zindex">
            <el-button
              link
              class="cancelBtn"
              :disabled="props.cancelType"
              @click="postFile({}, 'allDelete')"
              >{{ t("base.base95") }}</el-button
            >
          </div>
        </div>
        <el-collapse-transition>
          <div class="fileListItemUp">
            <div v-for="(item, index) in props.processList" :key="index">
              <div class="upFileItemDiv">
                <div class="upFileLeftDiv">
                  <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                  <div class="upListItemTitle">{{ item.fileName }}</div>
                </div>
                <div class="upFileItemTip">
                  <el-progress :percentage="Math.floor(item.process * 100)" />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { getBaseFileIcon } from "@/utils/file";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const processType = ref(false); //上传进度展开收起
const props = defineProps(["processList", "cancelType"]);
const emit = defineEmits(["postFile", "closeProcess"]);
const getContSum = computed(() => {
  // 获取所有值 / 个数 *100
  let totalProcess =
    props.processList.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.process;
    }, 0) / props.processList.length;
  totalProcess = totalProcess >= 1 ? 100 : totalProcess * 100;
  return Math.floor(totalProcess);
});
const postFile = (item, type) => {
  emit("postFile", item, type);
};
const closeProcess = () => {
  emit("closeProcess");
};
</script>

<style lang="scss" scoped>
.processDialog {
  position: absolute;
  right: 20px;
  bottom: 10px;
  background-color: #fefefe;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  width: 660px;
  z-index: 100;
  max-height: 424px;
}
.processBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.processTop {
  display: flex;
  align-items: center;
  width: calc(100% - 72px);
}
.processTopTitle {
  margin-left: 12px;
  color: #3d3d3d;
  font-size: 26px;
  font-weight: 500;
  max-width: calc(100% - 48px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.img36 {
  width: 36px;
  cursor: pointer;
}
.processItemDiv {
  height: 60px;
  background-color: #f7f8ff;
  line-height: 60px;
  padding: 0px 30px;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.processItem {
  position: absolute;
  left: 0px;
  height: 100%;
  background-color: #dbdfff;
}
.zindex {
  z-index: 20;
}
.cancelBtn {
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
  margin-right: 12px;
  cursor: pointer;
}
.fileListItemUp {
  max-height: 264px;
  overflow: auto;
}
.upFileTip {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 18px;
}
.upListItemTitle {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 43px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileItemTip {
  color: #3376ff;
  font-size: 18px;
  font-weight: 400;
  text-align: right;
  width: 40%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileItemDiv {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
.upFileLeftDiv {
  width: 60%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
</style>
