<template>
  <el-config-provider :locale="elementLocale">
    <layoutIndex v-if="route.path != '/'"></layoutIndex>
    <router-view v-else />
  </el-config-provider>
</template>

<script setup>
import layoutIndex from "@/layout/index.vue";
import CN from "element-plus/es/locale/lang/zh-cn";
import US from "element-plus/es/locale/lang/en";
import HK from "element-plus/es/locale/lang/zh-tw";
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
const { locale, t } = useI18n();
const store = useStore();
const route = useRoute();
//组件语言
const elementLocale = ref({});
//设置语言
onMounted(() => {
  setLanguage(store.state.lang);
});
watch(
  () => store.state.lang,
  (newValue, oldValue) => {
    setLanguage(newValue);
  }
);
watch(
  () => route.path,
  (newValue, oldValue) => {
    setHtmlTitle();
  }
);
const setHtmlTitle = () => {
  if (route.name) {
    if (process.env.VUE_APP_CHANNEL === "NO") {
      document.title = "NextAI" + " | " + t(`menu.${route.name}`);
    } else {
      document.title = t(`menu.${route.name}`);
    }
  } else {
    if (process.env.VUE_APP_CHANNEL === "NO") {
      document.title = "NextAI";
    } else {
      document.title = t("login.login9");
    }
  }
};
// 切换 组件也要切换并且i18n也要切换
const setLanguage = (lang) => {
  locale.value = lang;
  switch (lang) {
    case "CN":
      elementLocale.value = CN;
      break;
    case "HK":
      elementLocale.value = HK;
      break;
    case "US":
    default:
      elementLocale.value = US;
      break;
  }
};
</script>

<style lang="less">
html,
body,
#app {
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
  letter-spacing: 0px !important;
}
#nprogress .bar {
  background-color: #0256ff !important;
}
:root {
  --el-color-primary-light-9: #eceeff;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: #0000004d;
}
::selection {
  background-color: #0256ff !important;
}
::-webkit-scrollbar-thumb {
  background-color: #0003;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px transparent;
  border-radius: 0;
  background: transparent;
}
:deep(.el-select__caret) {
  font-size: 20px;
  color: #8a8a8a;
}
:focus {
  outline: none !important;
}
.popperItem {
  width: 445px !important;
  padding: 24px !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 16px !important;
}
.fileImgPopover {
  width: 326px !important;
  max-height: 326px !important;
}
.txPopover {
  width: 228px !important;
  padding: 6px;
}
.formDIv {
  padding: 24px;
  width: 404px !important;
}
.toolTip {
  max-width: 300px !important;
  max-height: 300px !important;
  overflow: auto;
}
.baseSelBotDetail {
  width: 700px !important;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
