<template>
  <div class="shareContent">
    <!-- 左侧列表 -->
    <div class="shareLeft">
      <!-- 搜索 -->
      <div class="navDiv navBottomn">
        <el-input
          :placeholder="t('base.base130')"
          prefix-icon="Search"
          class="shareInput"
          v-model="listFomr.sessionNamePattern"
          @input="searchInput"
        ></el-input>
        <div class="navDiv">
          <div
            :class="navActive == index ? 'navItemAc' : 'navItem'"
            @click="changeNav(index)"
            v-for="(item, index) in navList"
            :key="index"
          >
            <img :src="item.url" alt="" v-if="item.url" class="navImg" />
            <div>{{ t(item.name) }}</div>
          </div>
          <div class="line"></div>
          <div
            :class="navActive == 3 ? 'navItemAc' : 'navItem'"
            @click="changeNav(3)"
          >
            <div class="yzText">{{ t("base.base131") }}</div>
          </div>
          <div
            :class="navActive == 4 ? 'navItemAc' : 'navItem'"
            @click="changeNav(4)"
          >
            <div class="yzText">{{ t("base.base132") }}</div>
          </div>
        </div>
      </div>
      <!-- 预览 -->
      <div v-loading="loading" class="lookDiv">
        <div class="lookDIc" @scroll="scrollLoad" v-if="list.length != 0">
          <lookView
            :type="navActive == 3 ? 'yz' : navActive == 4 ? 'sq' : 'share'"
            :like="navActive == 2 ? true : false"
            :list="list"
            :draggable="false"
            @goShare="toList"
            @goLookView="goBaseDetails"
            @reshList="searchInput"
          ></lookView>
        </div>
        <img
          :src="icon.noData"
          class="noData"
          v-if="!loading && list.length == 0"
          alt=""
        />
      </div>
    </div>
    <!-- 右侧 -->
    <div class="shareRight">
      <div class="cardItem bottom20">
        <div class="centerBetween">
          <div class="rightTitle">{{ t("base.base133") }}</div>
          <div class="moreDiv" @click="changeNav(3)">
            <div class="moreText">{{ $t("share.more") }}</div>
            <el-icon class="moreIcon">
              <TopRight />
            </el-icon>
          </div>
        </div>
        <div
          v-for="(item, index) in canverify.slice(0, 2)"
          :key="index"
          class="rightItem"
        >
          <div class="navDiv marginBottom10">
            <img :src="icon.shareYz" alt="" class="shareHt" />
            <div class="rightQuestion">{{ item.title }}</div>
          </div>
          <div class="navDiv">
            <div class="tx" :style="'background-color:' + item.color">
              {{ item.applicantUserName?.slice(0, 1) }}
            </div>
            <div class="userName">{{ item.applicantUserName }}</div>
            <el-button class="yzBtn" @click="toList(item)">
              <div>{{ t("base.base317") }}</div>
              <el-icon class="yzIcon">
                <TopRight />
              </el-icon>
            </el-button>
          </div>
        </div>
      </div>
      <div class="cardItem">
        <div class="centerBetween">
          <div class="rightTitle">{{ t("base.base134") }}</div>
          <div class="moreDiv" @click="changeNav(4)">
            <div class="moreText">{{ $t("share.more") }}</div>
            <el-icon class="moreIcon">
              <TopRight />
            </el-icon>
          </div>
        </div>
        <div
          v-for="(item, index) in initiateVerList.slice(0, 2)"
          :key="index"
          class="rightItem"
        >
          <div class="navDiv marginBottom10">
            <img :src="icon.shareYz" alt="" class="shareHt" />
            <div class="rightQuestion">{{ item.title }}</div>
          </div>
          <div class="navDiv">
            <div class="tx" :style="'background-color:' + item.color">
              {{ item.applicantUserName?.slice(0, 1) }}
            </div>
            <div class="userName">{{ item.applicantUserName }}</div>
            <!-- <el-button class="yzBtn">
              <div>验证</div>
              <el-icon class="yzIcon">
                <TopRight />
              </el-icon>
            </el-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import lookView from "@/components/look/index.vue";
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import { useRouter } from "vue-router";
import { throttle } from "@/utils/utils";
import { icon } from "@/utils/icon";
const router = useRouter();
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const navActive = ref(0);
const navList = ref([
  {
    name: "base.base98",
    url: icon.verified,
  },
  {
    name: "base.base99",
    url: icon.hotShare,
  },
  {
    name: "base.base135",
    url: icon.collect,
  },
]);
const loading = ref(false);
const list = ref([]);
// 列表参数
const listFomr = ref({
  userId: store.state.userId,
  sessionNamePattern: "",
  pageId: 1,
  perPage: 10,
});
const initiateVerList = ref([]);
const canverify = ref([]);
onMounted(() => {
  navActive.value = 0;
  getInitiateVerify();
  topicList();
  changeNav(navActive.value);
});
// 下拉加载
const scrollLoad = (e) => {
  const scrollHeight = e.target.scrollHeight;
  const scrollTop = e.target.scrollTop;
  const clientHeight = e.target.clientHeight;
  if (scrollTop + clientHeight >= scrollHeight - 1) {
    load();
  }
};
// 下拉加载
const load = () => {
  listFomr.value.pageId++;
  getListFn(navActive.value);
};
// 我申请的验证
const getInitiateVerify = () => {
  api.share.getInitiateVerifyApi({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      initiateVerList.value = res.data;
    }
  });
};
// 我可验证的话题列表
const topicList = () => {
  api.share.getVerifyApi(listFomr.value).then((res) => {
    if (res.returnCode == 200) {
      canverify.value = res.data;
    }
  });
};
const searchInput = () => {
  listFomr.value.pageId = 1;
  getListFn(navActive.value);
};
// 点击菜单
const changeNav = throttle((index) => {
  list.value = [];
  navActive.value = index;
  listFomr.value = {
    userId: store.state.userId,
    sessionNamePattern: "",
    pageId: 1,
    perPage: 10,
  };
  getListFn(index);
}, 1000);
const getListFn = (index) => {
  if (index == 0) {
    // 权威话题
    getList();
  } else if (index == 1) {
    // 热门
    getHot();
  } else if (index == 2) {
    // 收藏
    getLike();
  } else if (index == 3) {
    // 我可验证
    getRight();
  } else if (index == 4) {
    // 请求验证
    getBottom();
  }
};
// 热门话题
const getHot = () => {
  loading.value = true;
  api.base
    .hotQuery(listFomr.value)
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.sessionType = 1;
        });
        if (listFomr.value.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      loading.value = true;
    });
};
// 收藏话题
const getLike = () => {
  loading.value = true;
  api.base
    .likeQuery(listFomr.value)
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.sessionType = 1;
        });
        if (listFomr.value.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      loading.value = true;
    });
};
// 获取列表
const getList = () => {
  loading.value = true;
  api.share
    .getVerifyQuertApi(listFomr.value)
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.sessionType = 1;
        });
        if (listFomr.value.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
// nav点击我可验证
const getRight = () => {
  loading.value = true;
  api.share
    .getVerifyApi(listFomr.value)
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.sessionType = 1;
        });
        if (listFomr.value.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
// nav点击我申请的验证
const getBottom = () => {
  loading.value = true;
  api.share
    .getInitiateVerifyApi(listFomr.value)
    .then((res) => {
      loading.value = false;
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.sessionType = 1;
        });
        if (listFomr.value.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};
const toList = (item) => {
  store.commit("SET_CHATTYPE", "base");
  store.commit("SET_YZ", item);
  router.push("/share/details");
};
// 去预览
const goBaseDetails = (item) => {
  api.chat
    .chatDescQuery({
      userId: userId.value,
      sessionId: item.sessionId,
      sessionType: item.sessionType,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        // 判断权威
        if (res.data.isValid == 1) {
          if (item.conversations) {
            res.data.conversations = item.conversations;
          }
          store.commit("SET_CHATTYPE", "base");
          store.commit("SET_TOPIC", res.data);
          router.push("/chat");
          store.commit("SET_CHATPARMAS", {
            type: res.data.isOwner == 1 ? "looks" : "look",
            isValid: res.data.isValid,
          });
        } else {
          if (item.conversations) {
            res.data.conversations = item.conversations;
          }
          store.commit("SET_CHATTYPE", "base");
          store.commit("SET_TOPIC", res.data);
          router.push("/chat");
          store.commit("SET_CHATPARMAS", {
            type:
              res.data.isOwner == 1 && navActive.value < 3 ? "chat" : "look",
            isValid: res.data.isValid,
          });
        }
      }
    });
};
</script>

<style lang="scss" scoped>
.shareContent {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  display: flex;
}
.shareLeft {
  width: calc(100% - 450px);
  margin-right: 30px;
  height: 100%;
}
.shareRight {
  width: 420px;
}
.shareInput {
  margin-right: 12px;
  width: 240px;
  :deep(.el-input__wrapper) {
    height: 46px;
    border-radius: 16px;
    box-shadow: 0px 0px 0px 0px;
    border: 2px solid #edeef6;
    font-size: 18px;
  }
}
.navItem {
  display: flex;
  align-items: center;
  height: 48px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  padding: 0px 16px;
  margin-right: 12px;
  cursor: pointer;
}
.navItemAc {
  display: flex;
  align-items: center;
  height: 48px;
  border: 2px solid #dbdfff;
  background-color: #eceeff;
  border-radius: 16px;
  font-size: 18px;
  color: #0256ff;
  font-weight: 400;
  padding: 0px 16px;
  margin-right: 12px;
  cursor: pointer;
}
.navImg {
  width: 19px;
  margin-right: 6px;
}
.navDiv {
  display: flex;
  align-items: center;
  position: relative;
}
.line {
  width: 3px;
  background-color: #edeef6;
  height: 36px;
  margin-right: 12px;
}
.yzText {
  color: #0256ff;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sharePxDiv {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  background-color: #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sharePx {
  width: 25px;
}
.navBottomn {
  margin-bottom: 17px;
}
.noData {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lookDIc {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.lookDiv {
  height: calc(100% - 69px);
  position: relative;
}
.cardItem {
  width: calc(100% - 60px);
  background-color: #fafaff;
  border-radius: 18px;
  padding: 25px 30px;
  height: 280px;
}
.bottom20 {
  margin-bottom: 20px;
}
.moreText {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 18px;
}
.moreIcon {
  color: #8a8a8a;
}
.yzIcon {
  color: #0256ff;
}
.moreDiv {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.rightTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 600;
}
.centerBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightItem {
  border-radius: 16px;
  background-color: #f7f8ff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  padding: 14px 18px;
  margin-top: 17px;
  .yzBtn {
    display: none;
  }
}
.rightItem:hover {
  background-color: #dbdfff;
  cursor: pointer;
  .yzBtn {
    display: block;
    border-radius: 8px;
    background-color: #eceeff;
    border: 0px;
    font-size: 18px;
    font-weight: 400;
    color: #0256ff;
    position: absolute;
    right: 0;
  }
}
.rightQuestion {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
  width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.shareHt {
  width: 22px;
  margin-right: 10px;
}
.userName {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
}
.tx {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin-right: 6px;
  text-align: center;
  line-height: 31px;
  font-weight: 600;
  color: #fefefe;
  font-size: 18px;
}
.marginBottom10 {
  margin-bottom: 10px;
}
</style>
