import axios from "../server";
export default {
    // 资源组模型配置参数获取
    llmDesc(data: any) {
        return axios({
            url: "configuration-set-future/llm-parameter/desc",
            method: "POST",
            data,
        });
    },
    // 资源组参数更新
    llmUpdata(data: any) {
        return axios({
            url: "configuration-set-future/llm-parameter/update",
            method: "POST",
            data,
        });
    },
    // 获得当前模型参数
    llmGet(data: any) {
        return axios({
            url: "/chat/v4/model-llm-parameter/get",
            method: "POST",
            data,
        });
    },
    // 获取默认参数
    llmGetDefault(data: any) {
        return axios({
            url: "configuration-set-future/llm-parameter/default/get",
            method: "POST",
            data,
        });
    },
    //知识库获取em ocr gpt
    modelSelGet(data: any) {
        return axios({
            url: "chat/v4/model_select/get",
            method: "POST",
            data,
        });
    },
    //获取excel详情
    excelDesc(data: any) {
        return axios({
            url: "custom-library/excel/content/desc",
            method: "POST",
            data,
        });
    },
    //获取excel表
    excelPreview(data: any) {
        return axios({
            url: "custom-library/excel/content/preview",
            method: "POST",
            data,
        });
    },

}