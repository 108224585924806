<template>
  <div class="widhei100" v-loading="loading">
    <iframe
      v-if="props.flag == 1"
      style="width: 100%; height: 100%"
      :src="offUrl + encodeURIComponent(props.wordUrl)"
      frameborder="0"
    ></iframe>
    <vue-office-docx v-else :src="props.wordUrl" @rendered="renderedHandler" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
//引入VueOfficeDocx组件
import VueOfficeDocx from "@vue-office/docx";
//引入相关样式
import "@vue-office/docx/lib/index.css";
const props = defineProps(["wordUrl", "flag"]);
const offUrl = ref("https://view.officeapps.live.com/op/embed.aspx?src=");
const loading = ref(true);
onMounted(() => {
  if (props.flag == 1) {
    loading.value = false;
  } else {
    loading.value = true;
  }
});
watch(
  () => props.flag,
  (newValue, oldValue) => {
    if (props.flag == 1) {
      loading.value = false;
    } else {
      loading.value = true;
    }
  }
);
const renderedHandler = () => {
  loading.value = false;
};
</script>

<style lang="scss" scoped>
:deep(.docx) {
  width: 90% !important;
  padding: 96px 120px !important;
}
.widhei100 {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
