<template>
  <div class="fileItem" @click="goUrl">
    <div class="leftDiv">
      <el-tooltip
        popper-class="toolTip"
        :content="props.htmlItem.internetName || getUrl()"
      >
        <div class="fontTitle">
          {{ props.htmlItem.internetName || getUrl() }}
        </div>
      </el-tooltip>
      <img
        :src="props.htmlItem.internetImage"
        v-if="props.htmlItem.internetImage"
        alt=""
        class="img56"
      />
    </div>
    <div class="flexBetween">
      <div class="fontName">{{ getUrl() }}</div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(["htmlItem"]);
const getUrl = () => {
  var url = new URL(props.htmlItem.internetUrl);
  return url.origin;
};
const goUrl = () => {
  window.open(props.htmlItem.internetUrl, "_blank");
};
</script>

<style lang="scss" scoped>
.fileItem {
  background-color: #fefefe;
  border-radius: 20px;
  padding: 12px 23px 12px 23px;
  height: 90px;
  width: 310px;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px #dbdfff7d;
}
.zwf {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d9d9d9;
  font-size: 13px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  line-height: 18px;
}
.fontName {
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 400;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-bottom: 12px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; /* 强制换行 */
}
.img56 {
  width: 56px;
  margin-left: 9px;
}
.leftDiv {
  display: flex;
  width: 100%;
}
</style>
