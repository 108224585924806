<script setup>
import { ref, nextTick, onMounted, watch } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const multipleTableRef = ref(null);
const tableMember = ref([]);
const props = defineProps(["show", "roleInfo"]);
const showDia = ref(props.show);
const disabledList = ref([]);
watch(
  () => props.show,
  (newVal) => {
    showDia.value = newVal;
  }
);
const newArrList = ref([]);
onMounted(() => {
  getTableList();
  newArrList.value = props.roleInfo?.userInfoList
    ? props.roleInfo?.userInfoList
    : [];
  disabledList.value = JSON.parse(JSON.stringify(newArrList.value));
});
const emit = defineEmits(["closeUser", "saveUser"]);

// 左侧部门菜单
const defaultProps = ref({
  children: "children",
  label: "label",
});
const listForm = ref({
  userId: userId,
  departmentName: "",
});
const tableId = ref(null); //部门ID
watch(
  () => tableId.value,
  (newVal) => {
    userTable.value.departmentId = newVal;
    getUserTable();
  }
);
const data = ref([]);
const getTableList = () => {
  api.manage.getGroupList(listForm.value).then((res) => {
    if (res.returnCode == 200) {
      data.value = res.data;
      getUserTable();
      tableId.value = res.data[0]?.departmentId; //默认选中第一个部门ID
    }
  });
};
// 部门组收缩
const isAsideVisible = ref(true);
const toggleAside = () => {
  isAsideVisible.value = !isAsideVisible.value;
};
// 部门树形列表点击
const handleNodeClick = (data) => {
  tableId.value = data.departmentId;
};
const user = ref(null);
watch(
  () => user.value,
  (newVal) => {
    userTable.value.userNamePattern = newVal;
    getUserTable();
  }
);
// 请求表格数据
const userTable = ref({
  userId: userId.value,
  userNamePattern: "",
  departmentId: tableId.value,
  activate: 1,
  ifDirectly: true,
});
const getUserTable = () => {
  if (userTable.value.departmentId) {
    api.manage.getUserList(userTable.value).then((res) => {
      if (res.returnCode == 200) {
        tableMember.value = res.data;
        console.log(tableMember.value);
        toggleSelection();
      }
    });
  }
};
// 右侧回显选择
const toggleSelection = () => {
  if (!newArrList.value) return;
  nextTick(() => {
    newArrList.value.forEach((row) => {
      tableMember.value.forEach((item) => {
        if (row.userId == item.userId) {
          multipleTableRef.value.toggleRowSelection(item, true);
        }
      });
    });
  });
};
// 多选
const multipleSelection = ref([]);
watch(
  () => multipleSelection.value,
  (newVal) => {
    multipleSelection.value = newVal;
  }
);
const selectable = (row) => {
  return !disabledList.value.some((item) => item.userId === row.userId);
};
// 右侧选择人 value数组
const changeSel = (value, row) => {
  const index = newArrList.value.findIndex(
    (user) => user.userId === row.userId
  );
  if (index !== -1) {
    newArrList.value.splice(index, 1);
    nextTick(() => {
      multipleTableRef.value.toggleRowSelection(row, false);
    });
  } else {
    newArrList.value.push({
      ...row,
      id: row.userId,
      name: row.userName,
      type: 2,
      ownerType: 0,
    });
  }
};
const handleSelectionChange = (list) => {
  //   有数据则添加
  if (list.length != 0) {
    list.forEach((item) => {
      newArrList.value.push({
        ...item,
        id: item.userId,
        name: item.userName,
        type: 2,
        ownerType: 0,
      });
    });
  } else {
    // 拿table列表数据
    tableMember.value.forEach((item) => {
      const index = newArrList.value.findIndex(
        (user) => user.userId === item.userId
      );
      if (index !== -1) {
        newArrList.value.splice(index, 1);
      }
    });
  }
};
const handleClose = () => {
  showDia.value = !showDia.value;
  emit("closeUser");
  user.value = "";
};
const handleSave = () => {
  showDia.value = !showDia.value;
  if (userTable.value.departmentId) {
    emit("saveUser", newArrList.value);
    user.value = "";
  }
};
</script>
<template>
  <div>
    <el-dialog
      :z-index="100"
      destroy-on-close
      v-model="showDia"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ t("manage.userForm.user89") }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleClose"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <el-divider class="divider" />
      <div>
        <el-container>
          <div class="container">
            <!-- 用户 -->
            <el-input
              v-model="user"
              size="large"
              class="searchInput"
              :placeholder="$t('manage.roleForm.role40')"
              v-if="isAsideVisible"
            >
              <template #prefix>
                <el-icon class="el-input__icon">
                  <search />
                </el-icon>
              </template>
            </el-input>
            <el-aside class="side" v-if="isAsideVisible">
              <el-scrollbar>
                <div class="scrollbar-flex-content">
                  <el-tree
                    ref="treeRef"
                    :data="data"
                    node-key="departmentId"
                    :props="defaultProps"
                    :default-expand-all="true"
                    :expand-on-click-node="false"
                    @node-click="handleNodeClick"
                    highlight-current
                    :current-node-key="tableId"
                  >
                    <template #default="{ data }">
                      <el-tooltip
                        popper-class="toolTip"
                        :content="data.departmentName"
                      >
                        <div class="wid100">{{ data.departmentName }}</div>
                      </el-tooltip>
                    </template>
                  </el-tree>
                </div>
              </el-scrollbar>
            </el-aside>
            <div @click="toggleAside" class="toggleAside">
              <el-icon class="tagIcon">
                <component
                  :is="isAsideVisible ? 'DArrowLeft' : 'DArrowRight'"
                />
              </el-icon>
            </div>
          </div>
          <el-main class="userMain">
            <el-table
              :data="tableMember"
              @select-all="handleSelectionChange"
              @select="changeSel"
              ref="multipleTableRef"
              class="tableHeight"
            >
              <el-table-column type="selection" :selectable="selectable" />
              <el-table-column
                prop="userName"
                :label="$t('manage.userForm.userName')"
              />
              <el-table-column
                prop="departmentName"
                :label="$t('manage.userForm.user2')"
              >
                <template #default="scope">
                  <el-tooltip
                    popper-class="toolTip"
                    :content="scope.row.departmentName"
                  >
                    <div class="wid100">{{ scope.row.departmentName }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-main>
        </el-container>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div @click="handleClose" class="cancel">
            {{ t("manage.userForm.user35") }}
          </div>
          <div @click="handleSave" class="confirm">
            {{ t("manage.userForm.user36") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 700px;
  border-radius: 30px;
  padding: 30px;
}

:deep(.el-tree-node) {
  width: 225px;
  display: table;
}

:deep(.el-tree-node__content) {
  height: 50px;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
}

:deep(.el-tree-node__content:hover) {
  background-color: #eceeff;
  color: #0256ff;
}

:deep(.el-tree-node:focus > .el-tree-node__content) {
  background-color: #eceeff;
  color: #0256ff;
}

:deep(.el-input__wrapper) {
  border-radius: 16px;
  width: 130px !important;
  margin-bottom: 10px;
  margin-right: 10px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
.divider {
  margin-bottom: 18px;
}
.container {
  position: relative;

  .side {
    max-width: 200px;
    height: 460px;
    overflow: auto;
    padding: 0px 2px;
    position: relative;
    margin-right: 20px;
  }

  .toggleAside {
    width: 20px;
    height: 40px;
    background-color: #f7f8ff;
    margin-top: 20px;
    z-index: 3;
    position: absolute;
    right: -10px;
    top: 40%;
    cursor: pointer;
    color: #8a8a8a;
    border-radius: 0 12px 12px 0;

    .tagIcon {
      font-size: 20px;
      margin-top: 8px;
    }
  }
}

.tableHeight {
  height: 510px;
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
</style>
