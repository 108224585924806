<template>
  <!-- 生成多样性 -->
  <div :class="lang == 'US' ? 'flexBottom' : 'flexBetween'">
    <div class="chatTitle">{{ t("gpt.index1") }}</div>
    <div :class="lang == 'US' ? 'top12' : ''" class="custom-style">
      <el-segmented
        v-model="Biodiversity"
        :options="navList"
        @change="getNavItem"
        id="value"
      >
        <template #default="scope">
          <div class="flex flex-col items-center gap-2 p-2">
            <div>{{ t(scope.item.label) }}</div>
          </div>
        </template>
      </el-segmented>
    </div>
  </div>
  <!-- 内容 -->
  <div v-for="(item, index) in form" :key="index">
    <div class="flexCenter top12" v-if="item.model != 'outputFormat'">
      <el-switch
        v-model="item.type"
        class="switchDiv"
        @change="sendForm"
        :disabled="inputDisabled"
      />
      <el-tooltip popper-class="toolTip" :content="t(item.name)">
        <div class="itemName">{{ t(item.name) }}</div>
      </el-tooltip>
      <el-slider
        :min="item.min"
        :max="item.max"
        :step="item.step"
        v-model="item[item.model]"
        @change="sendForm"
        :disabled="inputDisabled"
        class="hkNumber"
      />
      <el-input-number
        v-model="item[item.model]"
        :min="item.min"
        :max="item.max"
        :step="item.step"
        :value-on-clear="0"
        size="small"
        controls-position="right"
        :disabled="inputDisabled"
        @change="sendForm"
      />
    </div>
    <div v-else class="flexBetween top12">
      <div class="flexCenter">
        <el-switch
          v-model="item.type"
          class="switchDiv"
          @change="sendForm"
          :disabled="inputDisabled"
        />
        <el-tooltip popper-class="toolTip" :content="t(item.name)">
          <div class="itemName">{{ t(item.name) }}</div>
        </el-tooltip>
      </div>
      <div class="custom-style">
        <el-segmented
          v-model="item[item.model]"
          @change="sendForm"
          :options="textList"
          id="value"
          :disabled="inputDisabled"
        >
          <template #default="scope">
            <div class="flex flex-col items-center gap-2 p-2">
              <div>{{ t(scope.item.label) }}</div>
            </div>
          </template>
        </el-segmented>
      </div>
    </div>
    <div class="chatTitle top24" v-if="item.model == 'frequencyPenalty'">
      {{ t("gpt.index2") }}
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useStore } from "vuex";
const store = useStore();
const Biodiversity = ref(0);
const lang = ref(store.state.lang);
const emit = defineEmits(["sendForm", "changeBiodiversity"]);
const props = defineProps([
  "type",
  "gptDesc",
  "llmConfigurationDict",
  "llmNav",
]);
const llmConfigurationDict = ref({});
onMounted(() => {
  if (props.type == "chat") {
    llmConfigurationDict.value = JSON.parse(JSON.stringify(props.gptDesc));
  }
  if (props.llmNav && props.llmConfigurationDict) {
    Biodiversity.value = props.llmNav;
    if (!props.llmConfigurationDict) {
      reshForm();
    } else {
      for (const key in props.llmConfigurationDict) {
        const value = props.llmConfigurationDict[key];
        const item = form.value.find((item) => item.model === key); // 找到对应的数组对象
        if (item) {
          const isTypeOn = props.llmConfigurationDict[item.typeName] === 1;
          item.type = isTypeOn;
          item[key] = value; // 回填值
        }
      }
    }
    emit("sendForm", props.llmConfigurationDict, Biodiversity.value);
  } else {
    getNavItem();
  }
  getNav();
});
watch(
  () => store.state.lang,
  (newValue, oldValue) => {
    lang.value = store.state.lang;
  }
);
watch(
  () => props.type,
  (newValue, oldValue) => {
    getNav();
  }
);
const inputDisabled = computed(() => {
  if (props.type != "chat") {
    return false;
  } else {
    if (Biodiversity.value == 3) {
      return false;
    } else {
      return true;
    }
  }
});
const form = ref([
  {
    name: "gpt.index9",
    model: "temperature",
    type: false,
    temperature: 0,
    max: 1,
    min: 0,
    step: 0.1,
    typeName: "isTemperatureOn",
  },
  {
    name: "gpt.index10",
    model: "topP",
    type: false,
    topP: 0,
    max: 1,
    min: 0,
    step: 0.1,
    typeName: "isTopPOn",
  },
  {
    name: "gpt.index11",
    model: "presencePenalty",
    type: false,
    presencePenalty: 0,
    max: 1,
    min: 0,
    step: 0.1,
    typeName: "isPresencePenaltyOn",
  },
  {
    name: "gpt.index12",
    model: "frequencyPenalty",
    type: false,
    frequencyPenalty: 0,
    max: 1,
    min: 0,
    step: 0.1,
    typeName: "isFrequencyPenaltyOn",
  },
  {
    name: "gpt.index13",
    model: "maxRound",
    type: false,
    maxRound: 0,
    max: 999999999,
    min: 0,
    step: 1,
    typeName: "isMaxRoundOn",
  },
  {
    name: "gpt.index14",
    model: "maxTokens",
    type: false,
    maxTokens: 0,
    max: 1000000,
    min: 1000,
    step: 1,
    typeName: "isMaxTokensOn",
  },
  {
    name: "gpt.index15",
    model: "outputFormat",
    type: false,
    outputFormat: 0,
    max: 1,
    min: 0,
    step: 1,
    typeName: "isOutputFormatOn",
  },
]);
const navList = ref([
  {
    label: "gpt.index3",
    value: 0,
  },
  {
    label: "gpt.index4",
    value: 1,
  },
  {
    label: "gpt.index5",
    value: 2,
  },
  {
    label: "gpt.index6",
    value: 3,
  },
]);
const textList = ref([
  {
    label: "gpt.index7",
    value: 0,
  },
  {
    label: "gpt.index8",
    value: 1,
  },
]);
// 深拷贝参数
const copyDesc = (type) => {
  // 是否回到精准
  if (type) {
    Biodiversity.value = 0;
  }
  llmConfigurationDict.value = JSON.parse(JSON.stringify(props.gptDesc));
  getNavItem();
};
// 回到最初用的暴露事件
const changeBiodiversity = () => {
  emit("changeBiodiversity", Biodiversity.value);
};
defineExpose({
  changeBiodiversity,
  copyDesc,
});
// 回填参数
const getNavItem = () => {
  let backendData = {};
  if (Biodiversity.value == 0) {
    backendData = llmConfigurationDict.value.accurate;
  } else if (Biodiversity.value == 1) {
    backendData = llmConfigurationDict.value.balance;
  } else if (Biodiversity.value == 2) {
    backendData = llmConfigurationDict.value.creative;
  } else {
    backendData = llmConfigurationDict.value.customize;
  }
  // 回填逻辑
  if (!backendData) {
    reshForm();
  } else {
    for (const key in backendData) {
      const value = backendData[key];
      const item = form.value.find((item) => item.model === key); // 找到对应的数组对象
      if (item) {
        const isTypeOn = backendData[item.typeName] === 1;
        item.type = isTypeOn;
        item[key] = value; // 回填值
      }
    }
  }
  if (props.type == "chat") {
    emit("sendForm", backendData, Biodiversity.value);
  } else {
    emit("sendForm", llmConfigurationDict.value, Biodiversity.value);
  }
};
const reshForm = () => {
  form.value = [
    {
      name: "gpt.index9",
      model: "temperature",
      type: false,
      temperature: 0,
      max: 1,
      min: 0,
      step: 0.1,
      typeName: "isTemperatureOn",
    },
    {
      name: "gpt.index10",
      model: "topP",
      type: false,
      topP: 0,
      max: 1,
      min: 0,
      step: 0.1,
      typeName: "isTopPOn",
    },
    {
      name: "gpt.index11",
      model: "presencePenalty",
      type: false,
      presencePenalty: 0,
      max: 1,
      min: 0,
      step: 0.1,
      typeName: "isPresencePenaltyOn",
    },
    {
      name: "gpt.index12",
      model: "frequencyPenalty",
      type: false,
      frequencyPenalty: 0,
      max: 1,
      min: 0,
      step: 0.1,
      typeName: "isFrequencyPenaltyOn",
    },
    {
      name: "gpt.index13",
      model: "maxRound",
      type: false,
      maxRound: 0,
      max: 999999999,
      min: 0,
      step: 1,
      typeName: "isMaxRoundOn",
    },
    {
      name: "gpt.index14",
      model: "maxTokens",
      type: false,
      maxTokens: 0,
      max: 1000000,
      min: 1000,
      step: 1,
      typeName: "isMaxTokensOn",
    },
    {
      name: "gpt.index15",
      model: "outputFormat",
      type: false,
      outputFormat: 0,
      max: 1,
      min: 0,
      step: 1,
      typeName: "isOutputFormatOn",
    },
  ];
};
// 切换多样性
const getNav = () => {
  if (props.type == "chat") {
    navList.value = [
      {
        label: "gpt.index3",
        value: 0,
      },
      {
        label: "gpt.index4",
        value: 1,
      },
      {
        label: "gpt.index5",
        value: 2,
      },
      {
        label: "gpt.index6",
        value: 3,
      },
    ];
  } else {
    navList.value = [
      {
        label: "gpt.index3",
        value: 0,
      },
      {
        label: "gpt.index4",
        value: 1,
      },
      {
        label: "gpt.index5",
        value: 2,
      },
    ];
  }
};
// 切换参数回调
const sendForm = () => {
  const result = form.value.reduce((acc, item) => {
    acc[item.model] = item[item.model];
    acc[item.typeName] = item.type ? 1 : 0;
    return acc;
  }, {});
  if (Biodiversity.value == 0) {
    llmConfigurationDict.value.accurate = result;
  } else if (Biodiversity.value == 1) {
    llmConfigurationDict.value.balance = result;
  } else if (Biodiversity.value == 2) {
    llmConfigurationDict.value.creative = result;
  } else {
    llmConfigurationDict.value.customize = result;
  }
  if (props.type == "chat") {
    emit("sendForm", result, Biodiversity.value);
  } else {
    emit("sendForm", llmConfigurationDict.value, Biodiversity.value);
  }
};
</script>

<style lang="scss" scoped>
.flexBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatTitle {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 600;
}
.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #fefefe;
  --el-border-radius-base: 10px;
  padding: 3px;
  :deep(.el-segmented__item:not(.is-disabled):not(.is-selected):hover) {
    background-color: #e4e4e4;
    border-radius: 8px;
  }
  :deep(.el-segmented__item) {
    padding: 5px 22px;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 500;
  }
  :deep(.is-selected) {
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 500;
  }
}
.switchDiv {
  margin-right: 6px;
}
.itemName {
  font-size: 16px;
  color: #696969;
  font-weight: 600;
  width: 90px;
  margin-right: 10px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.hkNumber {
  width: 180px;
}
.top12 {
  margin-top: 12px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.top24 {
  margin-top: 24px;
}

:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  border-radius: 7px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 7px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #fefefe;
  left: 2px;
  border-radius: 4px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
  border-radius: 4px;
}
:deep(.el-input-number.is-controls-right .el-input__wrapper) {
  background-color: #f6f6f6;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
:deep(.el-input-number--small) {
  width: 100px;
  margin-left: 24px;
}
:deep(.el-input__wrapper .el-input__inner) {
  font-size: 16px !important;
  padding: 5px 0px;
}
</style>
