<template>
  <div class="footerContent">
    <img :src="icon.footerImg" alt="" class="footerImg" />
    <div class="footerFont icon">|</div>
    <div class="footerFont">
      {{ t("header.header45") + " " + getYear + " " + t("header.header47") }}
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const getYear = computed(() => {
  return new Date().getFullYear();
});
</script>

<style lang="less" scoped>
.footerContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerFont {
  color: #8a8a8a;
  font-size: 20px;
  font-weight: 400;
}
.icon {
  margin: 0px 20px;
}
.footerImg {
  width: 141px;
  height: 40px;
}
</style>
