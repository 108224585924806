<script setup>
import { ref, onMounted } from "vue";
import { Search } from "@element-plus/icons-vue";
import LogSizer from "../components/logSizer.vue";
import logExport from "../components/logExport.vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { formatTime } from "@/utils/qincheng";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const loading = ref(true);
onMounted(() => {
  getList();
  allModuleBtn();
  allOperationBtn();
});
const pageSize = ref(20);
const currentPage = ref(1);
const namePattern = ref(""); //用户名
const operationType = ref(""); //操作类型
const operationModule = ref(""); //操作模块
const operationStartDate = ref(""); //开始时间
const operationEndDate = ref(""); //结束时间
const operationResult = ref(null); //操作结果
const ips = ref([]); //ip
const RoleId = ref(""); //角色id
const departmentId = ref(""); //部门id

// 每页显示条数
const updatePageSize = (newSize) => {
  pageSize.value = newSize;
  getList();
};
// 更新当前页码
const updateCurrentPage = (newPage) => {
  currentPage.value = newPage;
  getList();
};
const tableData = ref([]);
const total = ref(0);
// 表格数据
const getList = () => {
  api.log
    .getLogs({
      userId: userId.value,
      pageId: currentPage.value,
      perPage: pageSize.value,
      namePattern: namePattern.value,
      operationType: operationType.value,
      operationModule: operationModule.value,
      operationResult: operationResult.value,
      operationStartDate: operationStartDate.value,
      operationEndDate: operationEndDate.value,
      ips: ips.value,
      RoleId: RoleId.value,
      departmentId: departmentId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        tableData.value = res.data;
        loading.value = false;
        total.value = res.totalNum;
      } else {
        message.error(res.returnMsg);
      }
    });
};
// 起止时间
const valueTime = ref([]);
// 选择事件段
const shortcuts = [
  {
    text: "上周",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: "上月",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: "上季度",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
  },
];
const resetObj = ref(false);
// 重置
const resetBtn = () => {
  resetObj.value = true;
  valueTime.value = [];
  pageSize.value = 20;
  currentPage.value = 1;
  namePattern.value = ""; //用户名
  operationType.value = ""; //操作类型
  operationModule.value = ""; //操作模块
  operationResult.value = null; //操作结果
  operationStartDate.value = ""; //开始时间
  operationEndDate.value = ""; //结束时间
  ips.value = []; //IP地址
  RoleId.value = ""; //角色
  departmentId.value = ""; //部门
  getList();
};

// 全部操作
const operationBtn = (value) => {
  operationType.value = value;
  getList();
};
const allOperationList = ref([]);
const allOperationBtn = () => {
  api.log
    .getType({
      userId: userId.value,
    })
    .then((res) => {
      allOperationList.value = res.data;
    });
};
// 全部模块
const moduleBtn = (value) => {
  operationModule.value = value;
  getList();
};
const allModuleList = ref([]);
const allModuleBtn = () => {
  api.log
    .getLogger({
      userId: userId.value,
    })
    .then((res) => {
      allModuleList.value = res.data;
    });
};
// 起止时间
const handleTime = (value) => {
  if (value != null) {
    operationStartDate.value = formatTime(value[0]);
    operationEndDate.value = formatTime(value[1]);
  }
  getList();
};
// 弹窗
const showDialog = ref(false);
// 弹窗显示
const tabelTypeBtn = () => {
  showDialog.value = true;
};
// 表头控制-确定
const handleConfirm = () => {
  tabelType.value = tabelType.value;
  showDialog.value = false;
};
// 表头弹窗选择按钮
const handleChange = (e, key) => {
  tabelType.value[key] = e;
};
// 控制表头显示
const tabelType = ref({
  operationTime: true,
  userName: true,
  email: true,
  ip: true,
  department: true,
  operationType: true,
  operationModule: true,
  operationContent: true,
  operationResult: false,
});
// 筛选弹窗
const showTool = ref(false);
const toolBtn = () => {
  showTool.value = true;
};
// 关闭筛选弹窗
const handleClose = () => {
  showTool.value = false;
};
const data = ref([]);
// 确定筛选条件
const handleSave = (value) => {
  resetObj.value = false;
  data.value = value;
  value.forEach((item) => {
    if (item.name == "ips") {
      ips.value = [item.data];
    } else if (item.name == "RoleId") {
      RoleId.value = item.data;
    } else if (item.name == "departmentId") {
      departmentId.value = item.data;
    } else if (item.name == "operationResult") {
      operationResult.value = item.data;
    }
  });
  getList();
  showTool.value = false;
};
// 导出弹窗
const showExport = ref(false);
const exportBtn = () => {
  showExport.value = true;
};
// 关闭导出弹窗
const exportClose = () => {
  showExport.value = false;
};
// 调用导出接口
const exportSave = (data) => {
  api.log
    .expotrExcel(data)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      // 创建一个 a 标签用于下载
      const link = document.createElement("a");
      link.href = url;
      // 设置文件名
      link.setAttribute("download", "日志.xlsx");
      // 触发下载
      document.body.appendChild(link);
      link.click();
      // 清理
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      showExport.value = false;
    })
    .catch((error) => {
      showExport.value = false;
      message.error(t("manage.logForm.log1"));
    });
};
</script>

<template>
  <div class="main">
    <!-- 搜索 -->
    <div class="search">
      <div class="leftBox">
        <!-- 用户名 -->
        <div class="baseInput">
          <el-input
            class="baseInput"
            :placeholder="$t('manage.logForm.log2')"
            :prefix-icon="Search"
            v-model="namePattern"
            @input="getList"
            @change="getList"
          />
        </div>
        <!-- 起止时间 -->
        <div class="baseTime">
          <el-date-picker
            v-model="valueTime"
            type="daterange"
            unlink-panels
            :range-separator="$t('manage.logForm.log3')"
            :start-placeholder="$t('manage.logForm.log4')"
            :end-placeholder="$t('manage.logForm.log5')"
            :clearable="false"
            :shortcuts="shortcuts"
            @change="handleTime"
          />
        </div>
        <!-- 全部操作 -->
        <div>
          <el-select
            v-model="operationType"
            :placeholder="$t('manage.logForm.log6')"
            size="large"
            @change="operationBtn"
          >
            <el-option
              v-for="(item, index) in allOperationList"
              :key="index"
              :label="item"
              :value="item"
            >
              <span class="dropText">{{ item }}</span>
            </el-option>
          </el-select>
        </div>
        <!-- 全部模块 -->
        <div>
          <el-select
            v-model="operationModule"
            :placeholder="$t('manage.logForm.log7')"
            size="large"
            @change="moduleBtn"
          >
            <el-option
              v-for="(item, index) in allModuleList"
              :key="index"
              :value="item"
            >
              <span class="dropText">{{ item }}</span>
            </el-option>
          </el-select>
        </div>
        <!--筛选 -->
        <div class="searchDiv2" @click="toolBtn">
          <div class="toolImg">
            <img :src="icon.screen" alt="" />
          </div>
        </div>
        <!-- 重置 -->
        <div
          class="searchDiv3"
          @click="resetBtn"
          style="background-color: #0256ff"
        >
          <div class="toolImg">
            <img :src="icon.loop" alt="" />
          </div>
        </div>
        <div class="tip">
          <div class="tipSpan">*</div>
          <el-tooltip
            popper-class="toolTip"
            :content="$t('manage.logForm.log8')"
          >
            <div class="admin-text">{{ $t("manage.logForm.log8") }}</div>
          </el-tooltip>
          <!-- <div>{{ $t('manage.logForm.log8') }}</div> -->
        </div>
      </div>
      <div class="rightBox">
        <div class="searchDiv2" @click="tabelTypeBtn">
          <div class="toolImg">
            <img :src="icon.table" alt="" />
          </div>
        </div>
        <div class="toolBox addUser" @click="exportBtn">
          {{ $t("manage.logForm.log9") }}
        </div>
      </div>
    </div>
    <div v-loading="loading" class="heid">
      <!-- 日志表格 -->
      <el-table :data="tableData" class="table" border>
        <el-table-column
          v-if="tabelType.operationTime"
          prop="operationTime"
          :label="$t('manage.logForm.log10')"
        />
        <el-table-column
          v-if="tabelType.userName"
          prop="userName"
          :label="$t('manage.logForm.log11')"
          width="80"
        />
        <el-table-column
          v-if="tabelType.email"
          prop="email"
          :label="$t('manage.logForm.log12')"
        >
          <template #default="scope">
            <el-tooltip popper-class="toolTip" :content="scope.row.email">
              <div class="wid100">{{ scope.row.email }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="tabelType.ip"
          prop="ip"
          :label="$t('manage.logForm.log13')"
        />
        <el-table-column
          v-if="tabelType.department"
          prop="department"
          :label="$t('manage.logForm.log14')"
        >
          <template #default="scope">
            <el-tooltip popper-class="toolTip" :content="scope.row.department">
              <div class="wid100">{{ scope.row.department }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="tabelType.operationType"
          prop="operationType"
          :label="$t('manage.logForm.log15')"
        />
        <el-table-column
          v-if="tabelType.operationModule"
          prop="operationModule"
          :label="$t('manage.logForm.log16')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="scope.row.operationModule"
            >
              <div class="wid100">{{ scope.row.operationModule }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="tabelType.operationContent"
          prop="operationContent"
          :label="$t('manage.logForm.log17')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="scope.row.operationContent"
            >
              <div class="wid100">{{ scope.row.operationContent }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="tabelType.operationResult"
          prop="operationResult"
          :label="$t('manage.logForm.log18')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="scope.row.operationResult"
            >
              <div class="wid100">{{ scope.row.operationResult }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination
        :background="true"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :total="total"
        @size-change="updatePageSize"
        @current-change="updateCurrentPage"
        class="Pagination"
      />
    </div>
    <!-- 表头控制弹窗 -->
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="showDialog"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ $t("manage.logForm.log19") }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="showDialog = false"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div v-for="(item, key, index) in tabelType" :key="index">
        <el-checkbox
          :value="item"
          :checked="item"
          @change="handleChange($event, key)"
          :disabled="index === 0 || index === 1 || index === 2"
        >
          {{ $t("log.table." + key) }}
        </el-checkbox>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div @click="showDialog = false" class="cancel">
            {{ $t("manage.userForm.user37") }}
          </div>
          <div @click="handleConfirm" class="confirm">
            {{ $t("manage.userForm.user38") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
  <!-- 筛选弹框 -->
  <LogSizer
    :showTool="showTool"
    :resetObj="resetObj"
    @handleClose="handleClose"
    @handleSave="handleSave"
  ></LogSizer>
  <!-- 导出弹窗 -->
  <logExport
    :showExport="showExport"
    @exportClose="exportClose"
    @exportSave="exportSave"
  ></logExport>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  :deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    padding: 30px;
  }

  :deep(.el-table th.el-table__cell) {
    background-color: #f7f8ff;
  }

  :deep(.el-select) {
    width: 170px !important;
    margin-right: 10px;
  }

  :deep(.el-select__wrapper) {
    width: 170px !important;
    box-shadow: none !important;
    border-radius: 16px;
    border: #dcdfe6 solid 1px;
    box-shadow: 0px 0px 0px !important;
  }
}

.baseInput {
  margin-right: 10px;

  :deep(.el-input__wrapper) {
    height: 38px !important;
    width: 120px !important;
    border-radius: 16px !important;
  }
}

.baseTime {
  margin-right: 10px;

  :deep(.el-input__wrapper) {
    height: 40px !important;
    width: 260px !important;
    border-radius: 16px !important;
  }
}

.main {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

.tip {
  width: 180px;
  height: 25px;
  font-size: 14px;
  color: #8a8a8a;
  display: flex;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  .tipSpan {
    color: #f02d63;
    margin-right: 6px;
    margin-top: 4px;
  }
}

.search {
  display: flex;
  justify-content: space-between;

  .leftBox {
    display: flex;

    .searchDiv3 {
      border-radius: 16px;
      font-size: 16px;
      height: 40px;
      cursor: pointer;
      margin-right: 10px;

      .toolImg {
        margin: 6px 6px 0 6px;
        width: 26px;
        height: 26px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .searchDiv2 {
      border: #dcdfe6 solid 2px;
      border-radius: 16px;
      font-size: 16px;
      height: 40px;
      cursor: pointer;
      margin-right: 10px;

      .toolImg {
        margin: 5px 5px 0 5px;
        width: 28px;
        height: 28px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .rightBox {
    display: flex;

    .searchDiv2 {
      border: #dcdfe6 solid 2px;
      border-radius: 16px;
      font-size: 16px;
      height: 40px;
      cursor: pointer;
      margin-right: 10px;

      .toolImg {
        margin: 5px 5px 0 5px;
        width: 26px;
        height: 26px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .toolBox {
      border: #edeef6 solid 2px;
      border-radius: 16px;
      font-size: 16px;
      padding: 0 10px;
      height: 42px;
      line-height: 38px;
      cursor: pointer;
      background-color: #0256ff;
      color: #fff;
      margin-right: 0;
    }
  }
}

.table {
  margin-top: 10px;
  height: 100%;
}

.Pagination {
  margin-top: 16px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
.heid {
  height: calc(100% - 110px);
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
</style>
