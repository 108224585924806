import { icon } from "./icon"
//返回对应文件图标
export function getFileIcon(item: any) {
    let fileType
    if (item.folderName) {
        return icon.fileFolder
    }
    if (item.sessionName) {
        return icon.dImg
    }
    if (typeof item.fileType != 'number') {
        fileType = item.fileType.toLowerCase();
    } else {
        fileType = item.fileType
        if (fileType == 0) {
            fileType = item.fileName.substring(item.fileName.lastIndexOf(".") + 1);
            fileType = fileType.toLowerCase().replace(" ", "");
        }
    }
    if (fileType == 'docx' || fileType == 'doc') {
        return icon.word
    } else if (fileType == 'xls' || fileType == 'xlsx') {
        return icon.excel
    } else if (fileType == 'html') {
        return icon.html
    } else if (fileType == 'pdf') {
        return icon.pdf
    } else if (fileType == 'pptx') {
        return icon.ppt
    } else if (fileType == 'txt') {
        return icon.txt
    } else if (fileType == 'txt') {
        return icon.txt
    } else if (fileType == 1) {
        return icon.fileFolder
    } else if (fileType == 2) {
        return icon.dImg
    } else {
        return icon.notype
    }
}
//返回文件size
export function formatFileSize(bytes: any) {
    if (bytes == 0 || bytes == null || bytes == undefined) return "";
    if (typeof bytes === 'string') {
        return bytes
    }
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (bytes / Math.pow(k, i)).toFixed(2) + " " + sizes[i];
}
//数据库使用返回图标
export function getBaseFileIcon(item: any) {
    if (!item) {
        return
    }
    if (item.folderName) {
        return icon.fileFolder
    }
    if (item.sessionName || item.fileType == 2) {
        return icon.dImg
    }
    if (item.fileType == 1) {
        return icon.fileFolder
    }
    item.fileType = item.fileType || item.fileName.substring(item.fileName.lastIndexOf(".") + 1);
    const fileType = item.fileType.toLowerCase().replace(" ", "");
    if (fileType == 'docx' || fileType == 'doc') {
        return icon.word
    } else if (fileType == 'xls' || fileType == 'xlsx') {
        return icon.excel
    } else if (fileType == 'html') {
        return icon.html
    } else if (fileType == 'pdf') {
        return icon.pdf
    } else if (fileType == 'pptx') {
        return icon.ppt
    } else if (fileType == 'txt') {
        return icon.txt
    } else {
        return icon.notype
    }
}
//返回文件对应原型图片
export function getChatFileImg(item: any) {
    const fileType = item.fileType.toLowerCase();
    if (fileType == 'docx' || fileType == 'doc') {
        return icon.bword
    } else if (fileType == 'xls' || fileType == 'xlsx') {
        return icon.bexcel
    } else if (fileType == 'html') {
        return icon.bhtml
    } else if (fileType == 'pdf') {
        return icon.bpdf
    } else if (fileType == 'pptx') {
        return icon.bppt
    } else if (fileType == 'txt') {
        return icon.btext
    } else {
        return icon.wlSvg
    }
}
// 返回文件预览对应图片
export function getResonFileImg(item: any) {
    const fileType = item.fileName.substring(item.fileName.lastIndexOf(".") + 1).replace(" ", "");
    if (fileType == 'docx' || fileType == 'doc') {
        return icon.rword
    } else if (fileType == 'xls' || fileType == 'xlsx') {
        return icon.rexcel
    } else if (fileType == 'html') {
        return icon.rhtml
    } else if (fileType == 'pdf') {
        return icon.rpdf
    } else if (fileType == 'pptx') {
        return icon.rppt
    } else if (fileType == 'txt') {
        return icon.rtext
    }
}