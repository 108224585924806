<template>
  <div class="resonDialog">
    <div
      class="reSonUpFile bottom12"
      v-if="
        props.item.fileResource.length != 0 ||
        props.item.sessionResource.length != 0
      "
    >
      <div class="reSonCenter">
        <img class="reSonIcon" :src="icon.baseReson" alt="" />
        <div class="reSonTitle">
          {{ t("base.base277") }}{{ getNumber() }}{{ t("base.base280") }}
        </div>
      </div>
      <img
        class="reSonUpFileImg"
        :src="icon.upReson"
        alt=""
        v-if="props.item.resonOneType"
        @click="props.item.resonOneType = !props.item.resonOneType"
      />
      <img
        class="reSonUpFileImg"
        :src="icon.downReson"
        alt=""
        v-else
        @click="props.item.resonOneType = !props.item.resonOneType"
      />
    </div>
    <!-- 文件内容 -->
    <el-collapse-transition>
      <div class="pa" v-show="props.item.resonOneType">
        <img
          :src="icon.leftReson"
          alt=""
          class="leftResonIcon"
          @click="changeLeftReson(resonOne)"
          v-if="
            props.item.fileResource.length +
              props.item.sessionResource.length >=
            3
          "
        />
        <div class="flexItemReson" ref="resonOne">
          <fileReSon
            v-for="items in props.item.fileResource"
            :key="items.id"
            :item="items"
            :deleType="false"
            class="resonMargin"
            @openLook="openFileView"
          ></fileReSon>
          <talkReSon
            class="resonMargin"
            v-for="items in props.item.sessionResource"
            :key="items.sessionId"
            :talkItem="items"
            :deleType="false"
            @openTalk="openTalk"
          ></talkReSon>
        </div>
        <img
          v-if="
            props.item.fileResource.length +
              props.item.sessionResource.length >=
            3
          "
          :src="icon.rightReson"
          alt=""
          class="rightResonIcon"
          @click="changeRightReson(resonOne)"
        />
      </div>
    </el-collapse-transition>
    <!-- 网页 -->
    <div class="reSonUpFile" v-if="props.item.internets.length != 0">
      <div class="reSonCenter">
        <img class="reSonIcon" :src="icon.netReson" alt="" />
        <div class="reSonTitle">
          {{ t("base.base277") }}{{ props.item.internets.length
          }}{{ t("base.base279") }}
        </div>
      </div>
      <img
        class="reSonUpFileImg"
        :src="icon.upReson"
        alt=""
        v-if="props.item.resonTwoType"
        @click="props.item.resonTwoType = !props.item.resonTwoType"
      />
      <img
        class="reSonUpFileImg"
        :src="icon.downReson"
        alt=""
        v-else
        @click="props.item.resonTwoType = !props.item.resonTwoType"
      />
    </div>
    <el-collapse-transition>
      <div class="pa" v-show="props.item.resonTwoType">
        <img
          v-if="props.item.internets.length >= 3"
          :src="icon.leftReson"
          alt=""
          class="leftResonIcon"
          @click="changeLeftReson(resonTwo)"
        />
        <div class="flexItemReson" ref="resonTwo">
          <htmlReSon
            v-for="items in props.item.internets"
            :key="items"
            :htmlItem="items"
            :deleType="false"
            class="resonMargin"
          ></htmlReSon>
        </div>
        <img
          v-if="props.item.internets.length >= 3"
          :src="icon.rightReson"
          alt=""
          class="rightResonIcon"
          @click="changeRightReson(resonTwo)"
        />
      </div>
    </el-collapse-transition>
    <!-- sql -->
    <div
      class="reSonUpFile"
      v-if="props.item.sqlResults && props.item.sqlResults.length != 0"
    >
      <div class="reSonCenter">
        <img class="reSonIcon" :src="icon.baseSql" alt="" />
        <div class="reSonTitle">{{ t("gpt.index55") }}</div>
      </div>
      <img
        class="reSonUpFileImg"
        :src="icon.upReson"
        alt=""
        v-if="resonTreeType"
        @click="resonTreeType = !resonTreeType"
      />
      <img
        class="reSonUpFileImg"
        :src="icon.downReson"
        alt=""
        v-else
        @click="resonTreeType = !resonTreeType"
      />
    </div>
    <el-collapse-transition>
      <div
        v-show="
          resonTreeType &&
          props.item.sqlResults &&
          props.item.sqlResults.length != 0
        "
        class="sqlDiv"
      >
        <div
          v-for="(item, index) in props.item.sqlResults"
          :key="item"
          :class="index != props.item.sqlResults.length - 1 ? 'bottom20' : ''"
        >
          <div v-html="getSql(item.sqlSentence)" class="sqlText"></div>
          <div class="flexEnd">
            <div class="sqlCxDiv" @click="openResultDialog(item.sqlResult)">
              <img :src="icon.sqlCx" class="sqlCx" alt="" />
              {{ t("gpt.index62") }}
            </div>
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import fileReSon from "@/components/shareReson/file.vue";
import htmlReSon from "@/components/shareReson/html.vue";
import talkReSon from "@/components/shareReson/talk.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { ref } from "vue";
const props = defineProps(["item"]);
const emit = defineEmits(["openFileView", "openTalk", "openResultDialog"]);
const resonOne = ref(null);
const resonTwo = ref(null);
const resonTreeType = ref(true);
// 打开查询结果
const openResultDialog = (message) => {
  emit("openResultDialog", message);
};
// 将\n换成换行标签
const getSql = (sql) => {
  return sql.replace(/\n/g, "<br>");
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
const openFileView = (item) => {
  emit("openFileView", item);
};
const openTalk = (item) => {
  emit("openTalk", item);
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
const getNumber = () => {
  let number =
    props.item.fileResource.length + props.item.sessionResource.length;
  return number;
};
</script>

<style lang="scss" scoped>
.resonDialog {
  width: calc(100% - 30px);
  background-color: #f7f8ff;
  border-radius: 30px;
  padding: 17px 15px;
  position: relative;
  margin-bottom: 20px;
}
.reSonUpFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom12 {
  margin-bottom: 12px;
}
.reSonCenter {
  display: flex;
  align-items: center;
}
.reSonIcon {
  width: 25px;
  margin-right: 10px;
}
.reSonTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
}
.reSonUpFileImg {
  width: 24px;
  cursor: pointer;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.flexItemReson {
  display: flex;
  margin: 0px 0px 12px 32px;
  align-content: flex-start;
  justify-content: flex-start;
  overflow: auto; /* 使div可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
  -ms-overflow-style: none;
  position: relative;
  margin-top: 10px;
}
.resonMargin {
  margin-right: 12px;
}
.sqlDiv {
  background-color: #fefefe;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(219, 223, 255, 0.5);
  padding: 12px 20px;
  margin-top: 10px;
  cursor: default;
  position: relative;
  margin-left: 32px;
}
.sqlCx {
  width: 12px;
  margin-right: 12px;
}
.sqlText {
  color: #898989;
  font-size: 16px;
  font-weight: 400;
  max-width: calc(100% - 130px);
  overflow: auto;
}
.sqlCxDiv {
  background-color: #fefefe;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  box-shadow: 1px 2px 8px 0px rgba(204, 206, 229, 0.25);
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding: 8px 16px;
  cursor: pointer;
}
.flexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom20 {
  margin-bottom: 20px;
}
</style>
