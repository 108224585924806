<script setup>
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const emit = defineEmits(["handleClose", "editSave", "handleSave"]);
const props = defineProps({
  show: Boolean,
  groupInfo: Object,
  formType: String,
});
const show = ref(props.show);
watch(
  () => props.show,
  (newVal) => {
    show.value = newVal;
  }
);
onMounted(() => {});
const users = ref([]);
watch(
  () => props.groupInfo.users,
  (newVal) => {
    users.value = newVal;
  }
);
const formRef = ref(null);
const rules = ref({
  groupName: [{ required: true, message: t("manage.groupForm.group19") }],
  groupDescription: [
    { required: true, message: t("manage.groupForm.group20") },
  ],
  userIdList: [{ required: true, message: t("manage.groupForm.group21") }],
});
const showAddDialog = ref(false);
const handleAddUser = () => {
  showAddDialog.value = true;
};
const ids = ref([]);
// 确定保存
const saveUser = (arr) => {
  showAddDialog.value = false;
};
// 关闭编辑
const handleClose = () => {
  formRef.value.resetFields();
  show.value = !show.value;
  emit("handleClose");
};
const newForm = ref({});
//保存新增
const handleSave = async (formRef) => {
  newForm.value.userIdList = ids.value;
  newForm.value.userId = userId.value;
  newForm.value.groupName = props.groupInfo.groupName;
  newForm.value.groupDescription = props.groupInfo.groupDescription;
  await formRef.validate((valid) => {
    if (valid) {
      show.value = !show.value;
      emit("handleSave", newForm.value);
    }
  });
};
// 确定修改
const editSave = async (formRef) => {
  ids.value = users.value.map((item) => item.userId);
  newForm.value.userIdList = ids.value;
  newForm.value.userId = userId.value;
  newForm.value.groupName = props.groupInfo.groupName;
  newForm.value.groupDescription = props.groupInfo.groupDescription;
  await formRef.validate((valid) => {
    if (valid) {
      api.manage
        .editGroupApi(newForm.value, props.groupInfo.groupId)
        .then((res) => {
          if (res.returnCode == 200) {
            message.success(t("manage.groupForm.group22"));
            show.value = !show.value;
            emit("handleClose");
          }
        });
    }
  });
};
</script>

<template>
  <div>
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>
            {{
              props.formType == "add"
                ? t("manage.groupForm.group23")
                : t("manage.groupForm.group24")
            }}
          </div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleClose"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <!-- 添加用户弹窗 -->
      <addUser
        :show="showAddDialog"
        @closeUser="showAddDialog = false"
        @saveUser="saveUser"
        :userList="users"
      >
      </addUser>
      <div class="content">
        <el-form
          :model="props.groupInfo"
          class="form"
          :rules="rules"
          ref="formRef"
          label-position="top"
        >
          <el-form-item
            prop="groupName"
            :label="$t('manage.groupForm.group16')"
          >
            <el-input
              v-model="props.groupInfo.groupName"
              :placeholder="$t('manage.groupForm.group19')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('manage.groupForm.group17')" class="userAdd">
            <el-input
              class="comtent"
              v-model="props.groupInfo.groupDescription"
              :placeholder="$t('manage.groupForm.group20')"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="users" label="群组成员" class="userAdd" >
                         <div @click="handleAddUser">
                            <div class="btnTag" v-if="!showAddDialog" >添加成员</div>
                         </div>                        
                        <el-table :data="users" class="table">
                            <el-table-column prop="userName" label="用户名" />
                            <el-table-column prop="email" label="邮箱" />
                            <el-table-column prop="departmentName" label="部门" />
                        </el-table>
                    </el-form-item> -->
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer" v-if="props.formType === 'add'">
          <div @click="handleClose(formRef)" class="cancel">
            {{ t("manage.userForm.user35") }}
          </div>
          <div @click="handleSave(formRef)" class="confirm">
            {{ t("manage.userForm.user36") }}
          </div>
        </div>
        <div class="dialog-footer" v-if="props.formType === 'edit'">
          <div @click="handleClose(formRef)" class="cancel">
            {{ t("manage.userForm.user37") }}
          </div>
          <div @click="editSave(formRef)" class="confirm">
            {{ t("manage.userForm.user38") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  padding: 30px;
}

:deep(.el-input__wrapper) {
  height: 38px !important;
  width: 520px !important;
}

:deep(.el-select__wrapper) {
  height: 42px !important;
  border-radius: 16px !important;
  width: 540px !important;
  margin-bottom: 10px !important;
}

:deep(.el-form-item__label) {
  margin-top: 10px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.userAdd {
  position: relative;
  margin-top: 14px;

  .btnTag {
    position: absolute;
    right: 10px;
    top: -36px;
    z-index: 777;
    color: #fff;
    border-radius: 8px;
    padding: 0 8px;
    background-color: #0d6efd;
    cursor: pointer;
  }
}

.close-icon {
  font-size: 20px;
}

.content {
  font-size: 18px;
  color: #8a8a8a;

  .name {
    color: #3d3d3d;
    font-size: 24px;
    margin: auto 6px auto 18px;
    font-weight: 500;
  }
}

.form {
  margin-top: 10px;
}

.form-name {
  font-size: 18px;
  color: #3d3d3d;
  display: flex;
  justify-content: space-between;

  .tit {
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.isActive {
  margin-right: 6px;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
</style>
