import axios from "axios";
import { message } from "ant-design-vue";
import store from "@/store";
import { encryptApi, decryptApi } from '@/utils/crypto'
import router from "@/router";
var type = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.timeout = 180000
// 数组变formData
function listToFormData(list: any, id: any, time: any) {
    let formData = new FormData();
    let obj = {
        userId: store.state.userId,
        time: time,
        ...id
    };
    for (let item of list) {
        if (!item.fileId) {
            formData.append(item.name, item);
        }
    }
    formData.append("time", time);
    formData.append("idempotentEncryData", encryptApi(JSON.stringify(obj)));
    return formData;
}
//请求拦截
axios.interceptors.request.use((config) => {
    const time: number = Math.round(new Date().getTime() / 1000);
    if (config.method != "get" && config.data !== undefined) {
        if (config.headers.apiType == 'file') {
            config.data = listToFormData(config.data, JSON.parse(config.headers.apiId), time);
        } else {
            let data = JSON.parse(JSON.stringify({ ...config.data, time: time }));
            delete data.idempotentEncryData;
            let idempotentEncryData = encryptApi(data);
            if (process.env.VUE_APP_TYPE == 'Testing') {
                config.data = {
                    ...config.data,
                    time: time,
                    idempotentEncryData: idempotentEncryData,
                };
            } else {
                config.data = {
                    time: time,
                    idempotentEncryData: idempotentEncryData,
                };
            }

        }

    }
    if (config.method == "get") {
        let data = JSON.parse(JSON.stringify({ ...config.data, time: time }));
        delete data.idempotentEncryData;
        let idempotentEncryData = encryptApi(data);
        let newurl = `?time=${time}&idempotentEncryData=${idempotentEncryData}`;
        config.url = config.url + newurl;
    }
    const token = store.state.token
    let lang = store.state.lang;
    if (lang === "CN") {
        lang = "zh-CN";
    } else if (lang === "HK") {
        lang = "zh-HK";
    } else if (lang === "US") {
        lang = "EN";
    }
    config.headers['Accept-Language'] = lang;
    if (token) {
        config.headers.Authorization = "Bearer " + token;
    }
    return config;
});

//请求响应
axios.interceptors.response.use(
    (res) => {
        let data
        if (
            res.config.responseType != 'blob'
        ) {
            data = JSON.parse(decryptApi(res.data.data));
        } else {
            data = res.data;
        }
        if (data.returnCode === 423) {
            sessionStorage.clear();
            let timmer = null;
            if (type) {
                type = false;
                message.warning("登录过期，请重新登录");
                router.push('/')
                timmer = setTimeout(() => {
                    type = true;
                }, 2000);
            }
            return data;
        }
        if (data.returnCode === 412) {
            sessionStorage.clear();
            let timmer = null;
            if (type) {
                type = false;
                //是否返回信息
                message.warning("账号不存在，请重新登录。");
                //是否返回页面
                router.push('/')
                timmer = setTimeout(() => {
                    type = true;
                }, 2000);
            }
            return data;
        }
        if (data.returnCode != 200 && res.config.responseType != "blob") {
            if (data.returnMessage) {
                message.warning(data.returnMessage);
            }
            return data;
        }
        return data;
    },
    (err) => {
        if (err.config.headers.apiType == 'file') {
            return message.warning("上传失败");
        }
        return message.warning("服务器连接错误！请稍后重试");
    }
);
export default axios;