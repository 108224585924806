<template>
  <!-- q -->
  <div class="qDiv">
    <div class="itemQuestion">
      {{ props.item.question ? props.item.question : t("login.login41") }}
    </div>
  </div>
  <!-- 来源 -->
  <shareFile
    :item="props.item"
    v-if="
      props.item.fileResource.length != 0 ||
      props.item.internets.length != 0 ||
      props.item.sessionResource.length != 0
    "
    @openFileView="openFileView"
    @openTalk="openTalk"
    @openResultDialog="openResultDialog"
  ></shareFile>
  <!-- 插件 -->
  <plugView
    type="chat"
    :pluginInfo="props.item.pluginInfo"
    v-if="props.item.pluginInfo && props.item.pluginInfo.length != 0"
  ></plugView>
  <!-- deepseek思考 -->
  <thinkMessageView
    v-if="
      (props.item.model == 12 || props.item.model == 'DeepSeek-R1') &&
      props.ifThink
    "
    :message="props.item.thinkContent"
    :chatMessage="props.item.answerContent"
    :time="props.item.thinkTime"
  ></thinkMessageView>
  <!-- a -->
  <messageView :message="props.item.message" class="messageView"></messageView>
</template>

<script setup>
import plugView from "@/components/qa/plug.vue";
import messageView from "@/components/message/index.vue";
import thinkMessageView from "@/components/message/thinkMessage.vue";
import shareFile from "./shareFile.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["item", "ifThink"]);
const emit = defineEmits(["openFileView", "openTalk", "openResultDialog"]);
// 打开查询结果
const openResultDialog = (message) => {
  emit("openResultDialog", message);
};
const openFileView = (item) => {
  item.fileId = item.id;
  emit("openFileView", item, props.item.chunkResource);
};
// 打开会话
const openTalk = (item) => {
  emit("openTalk", item);
};
</script>

<style lang="scss" scoped>
.qDiv {
  display: flex;
  justify-content: flex-end;
  margin-left: 110px;
  position: relative;
}
.itemQuestion {
  display: flex;
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 12px 26px;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  margin-bottom: 52px;
}
.messageView {
  margin-bottom: 52px;
}
</style>
