import { icon } from "./icon";
export function returnModel(name: any) {
    if (name == 'AzureOAI') {
        return icon.azureGpt
    }
    if (name == 'BaiduWenxin') {
        return icon.baiduGpt
    }
    if (name == 'Aliqianwen') {
        return icon.qianwenGpt
    }
    if (name == 'LocalDeepSeek') {
        return icon.deepseekGtp
    }
    if (name == 'Ali') {
        return icon.aliNoGpt
    }
    if (name == 'Baidu') {
        return icon.baiduNoGpt
    }
    if (name == 'Local') {
        return icon.ollamaNoGpt
    }
    if (name == 'Azure') {
        return icon.baseAzure
    }
    if (name == 'AzureWenDang') {
        return icon.azureWord
    }
    if (name == 'AzureBlob') {
        return icon.azureBlob
    }
    if (name == 'AzureTranslate') {
        return icon.azureTranslator
    }
    if (name == 'AzureYuYing') {
        return icon.azureSpeech
    }
    if (name == 'AzureBingSearch') {
        return icon.azureBing
    }
    if (name == 'AliOSS') {
        return icon.aliBlob
    }

}