<script setup>
import { message } from "ant-design-vue";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps({
  showName: Boolean,
  memberInfo: Object,
});
const emit = defineEmits(["closeMember", "submitMember"]);
const show = ref(props.showName);
watch(
  () => props.showName,
  (newVal) => {
    show.value = newVal;
  }
);
const handleClose = () => {
  show.value = !show.value;
  emit("closeMember");
};
const handleSave = () => {
  if (!props.memberInfo.departmentName) {
    message.warning(t("manage.userForm.user34"));
    return;
  }
  show.value = !show.value;
  emit("submitMember", props.memberInfo);
};
</script>
<template>
  <div>
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ t("manage.userForm.user64") }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleClose"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div class="my-body">
        <el-form :model="memberInfo" class="form">
          <el-form-item prop="departmentName">
            <div class="form-name">
              
              <p class="tit">
                <span class="xh">*</span>
                {{ t("manage.userForm.user33") }}
              </p>
              <el-input
                v-model="memberInfo.departmentName"
                :placeholder="$t('manage.userForm.user34')"
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div @click="handleClose" class="cancel">
            {{ t("manage.userForm.user37") }}
          </div>
          <div @click="handleSave" class="confirm">
            {{ t("manage.userForm.user38") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
:deep(.el-dialog) {
  --el-dialog-width: 460px;
  border-radius: 30px;
  padding: 20px;
}
:deep(.el-input__wrapper) {
  height: 38px !important;
  width: 380px !important;
  border-radius: 12px;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.close-icon {
  font-size: 20px;
}
.my-body {
  font-size: 18px;
  color: #3d3d3d;
}
.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
.xh{
  color: #f02d63;
}
</style>
