<template>
  <!-- 针对微模型提问 -->
  <div class="drapBottomDiv">
    <div @dragover.prevent="dragover" @drop="bottomDrop">
      <div class="drapFlex" v-if="props.bottomFileList.length == 0">
        <img :src="icon.drapBottom" alt="" class="drapBottom" />
        <div class="drapBottomText">
          {{
            baseItem.knowledge_type == 3 ? t("base.base364") : t("base.base69")
          }}
        </div>
      </div>
      <div v-else class="bottomMax">
        <div
          v-for="(item, index) in props.bottomFileList"
          :key="item.id"
          class="bottomFileItem"
        >
          <div class="fileBetween">
            <div class="flexCenter bottomTreeDiv">
              <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
              <el-tooltip
                popper-class="toolTip"
                :content="item.fileName || item.folderName"
                v-if="item.fileName || item.folderName"
              >
                <div
                  :class="{
                    fileTitle: item.expireFlag == 1 && item.importantFlag == 1,
                    fileTitle1: item.expireFlag == 1 || item.importantFlag == 1,
                    fileTitle2: item.expireFlag != 1 && item.importantFlag != 1,
                  }"
                >
                  {{ item.fileName || item.folderName }}
                </div>
              </el-tooltip>
              <el-tooltip
                popper-class="toolTip"
                :content="item.sessionName"
                v-if="item.sessionName"
              >
                <div class="fileTitle2">
                  {{ item.sessionName }}
                </div>
              </el-tooltip>
              <el-tooltip
                popper-class="toolTip"
                :content="t('base.base291')"
                v-if="item.importantFlag == 1"
              >
                <img :src="icon.fileYear" alt="" class="fileYear" />
              </el-tooltip>
            </div>
            <img
              :src="icon.closeFileBottom"
              alt=""
              class="closeFileBottom"
              @click="deleteBottomFile(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <el-button class="qaBtn" @click="goChat">{{ t("base.base70") }}</el-button>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { getBaseFileIcon } from "@/utils/file";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useStore } from "vuex";
import { ref } from "vue";
const store = useStore();
const props = defineProps(["bottomFileList"]);
const baseItem = ref(store.state.baseItem);
const emit = defineEmits([
  "dragover",
  "bottomDrop",
  "deleteBottomFile",
  "goChat",
]);
const goChat = () => {
  emit("goChat");
};
const deleteBottomFile = (index) => {
  emit("deleteBottomFile", index);
};
const bottomDrop = (event) => {
  emit("bottomDrop", event);
};
const dragover = (event) => {
  emit("dragover", event);
};
</script>

<style lang="scss" scoped>
.drapBottomDiv {
  background-color: #f7f8ff;
  border-radius: 20px;
  padding: 12px;
}
.drapFlex {
  display: flex;
  margin-bottom: 15px;
}
.drapBottom {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}
.drapBottomText {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; /* 强制换行 */
}
.bottomMax {
  max-height: 232px;
  overflow: auto;
}
.bottomFileItem {
  height: 26px;
  padding: 13px 0px 13px 30px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #eceeff;
  margin-bottom: 8px;
}
.fileBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  height: 100%;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.bottomTreeDiv {
  width: calc(100% - 26px);
  margin-right: 10px;
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
.fileTitle {
  color: #3d3d3d;
  font-size: 22px;
  font-weight: 400;
  max-width: calc(100% - 64px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTitle1 {
  color: #3d3d3d;
  font-size: 22px;
  font-weight: 400;
  max-width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTitle2 {
  color: #3d3d3d;
  font-size: 22px;
  font-weight: 400;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileYear {
  width: 18px;
  margin-left: 10px;
}
.closeFileBottom {
  width: 16px;
}
.qaBtn {
  width: 100%;
  height: 49px;
  border-radius: 16px;
  font-size: 18px;
  color: #fefefe;
  font-weight: 400;
  background-color: #0256ff;
  border: 0px;
}
</style>
