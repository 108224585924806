<template>
  <div>
    <!-- 文件信息 -->
    <div class="topMDiv">
      <div class="topRightDiv59" v-if="chatType == 'base'">
        <div class="flexCenter">
          <el-tooltip
            popper-class="toolTip"
            :content="fileObj.knowledge_name"
            v-if="fileObj.knowledge_name"
          >
            <div class="topTitleName">
              {{ fileObj.knowledge_name }}
            </div>
          </el-tooltip>
          <img
            :src="icon.arrow"
            alt=""
            class="wid24"
            v-if="fileObj.knowledge_name"
          />
          <div class="topFileName flexCenter">
            <el-tooltip popper-class="toolTip" :content="fileItem.fileName">
              <div
                :class="{
                  nameH:
                    fileDescObj.importantFlag == 1 &&
                    fileDescObj.importantExpireTime,
                  nameC:
                    fileDescObj.importantFlag == 1 &&
                    !fileDescObj.importantExpireTime,
                  nameN: fileDescObj.importantFlag != 1,
                }"
              >
                {{ fileItem.fileName }}
              </div>
            </el-tooltip>
            <el-tooltip
              popper-class="toolTip"
              :content="t('base.base290')"
              v-if="fileDescObj.importantFlag == 1"
            >
              <div class="flexCenter">
                <img :src="icon.fileYear" alt="" class="wid25 marGin12" />
                <div class="topFontItem">
                  {{
                    fileDescObj.importantExpireTime
                      ? getYear(fileDescObj.importantExpireTime)
                      : t("base.base176")
                  }}
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="flexCenter" v-if="Object.keys(fileDescObj).length != 0">
          <div class="topFontItem">{{ t("base.base269") }}:</div>
          <div class="topTx" :style="'background-color:' + fileDescObj.color">
            {{ fileDescObj.userName.slice(0, 1) }}
          </div>
          <el-tooltip popper-class="toolTip" :content="fileDescObj.userName">
            <div class="topFontItem nameMax">{{ fileDescObj.userName }}</div>
          </el-tooltip>
          <el-tooltip popper-class="toolTip" :content="t('base.base289')">
            <div class="flexCenter">
              <img :src="icon.baseTopTime" alt="" class="wid25 marGin12" />
              <div class="topFontItem">
                {{
                  fileDescObj.createTime ? getYear(fileDescObj.createTime) : ""
                }}
              </div>
            </div>
          </el-tooltip>
          <el-tooltip
            popper-class="toolTip"
            :content="t('base.base120')"
            v-if="fileDescObj.expireFlag == 1"
          >
            <div class="flexCenter">
              <div class="topFontItem">
                {{
                  fileDescObj.expireDate ? getYear(fileDescObj.expireDate) : ""
                }}
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="topRightDivCenter" v-else>
        <div class="topFileName">
          <el-tooltip popper-class="toolTip" :content="fileItem.fileName">
            {{ fileItem.fileName }}
          </el-tooltip>
        </div>
      </div>
      <div class="topRightBtn" v-if="chatType == 'base'">
        <el-dropdown
          @command="changeMore"
          v-if="
            props.type == 'base' &&
            (baseItem.resourcetype == 0 ||
              fileDescObj.knowledge_role_id == 0) &&
            (baseItem.resourcetype != 0 || fileDescObj.knowledge_role_id != 2)
          "
        >
          <div class="moreDiv">
            <img :src="icon.hMore" alt="" class="hMore" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :command="1" v-if="baseItem.resourcetype == 0">
                <span class="dropText blueColor">{{ t("base.base62") }}</span>
              </el-dropdown-item>
              <el-dropdown-item
                :command="2"
                v-if="fileDescObj.knowledge_role_id == 0"
              >
                <span class="dropText redColor">{{ t("base.base67") }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <div class="topLook" @click="lookText">
          <img :src="icon.topResh" alt="" class="wid25 marGin6" />
          <div class="topFontItem">
            {{ textType ? t("base.base270") : t("base.base271") }}
          </div>
        </div>
        <div
          v-if="
            (routeType == 'chat' || routeType == 'edit') &&
            fileDescObj.knowledge_role_id != 2 &&
            props.qaType == false &&
            fileObj.isValid != 1 &&
            props.isValed
          "
        >
          <el-button
            class="topLook"
            v-if="fileDescObj.importantFlag == 1"
            @click="changeSel(2)"
          >
            <div class="topFonItemF">{{ t("base.base65") }}</div>
          </el-button>
          <el-button
            @click="changeSel(4)"
            class="topLook"
            v-if="
              fileDescObj.importantFlag == 0 ||
              fileDescObj.importantFlag == null
            "
          >
            <div class="topFonItemF">{{ t("base.base66") }}</div>
          </el-button>
        </div>
        <img
          :src="icon.closeFileView"
          alt=""
          class="closeFileView"
          @click="closeView"
        />
      </div>
      <div class="topRightBtn" v-else>
        <img
          :src="icon.closeFileView"
          alt=""
          class="closeFileView"
          @click="closeView"
        />
      </div>
    </div>
    <!-- 文件展示 -->
    <div
      class="rightFileChunk"
      v-show="
        props.chunkDialog == true &&
        Object.keys(props.fileItem).length != 0 &&
        !textType
      "
    >
      <chunkView :fileItem="props.fileItem"></chunkView>
    </div>
    <!-- 文字展示 -->
    <div
      class="rightFileChunk"
      v-if="
        props.chunkDialog == true &&
        Object.keys(props.fileItem).length != 0 &&
        textType
      "
    >
      <DynamicScroller
        :items="textInner"
        :min-item-size="525"
        key-field="chunkId"
        class="scroller"
      >
        <template v-slot="{ item, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :size-dependencies="[item.chunk]"
            class="textMessageItem"
          >
            <div
              :class="getId(item) ? ' textMessageItemAc' : 'textMessageItembg'"
            >
              <messageView :message="item.chunk"></messageView>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
    <!-- 时间展示 -->
    <timeView
      v-if="timeDialog"
      @closeTime="closeTime"
      @saveTime="saveTime"
      :loading="timeLoading"
    ></timeView>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import chunkView from "@/components/chunk/index.vue";
import messageView from "@/components/message/index.vue";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { getYear } from "@/utils/utils";
import { useRoute } from "vue-router";
import api from "@/api";
import { message } from "ant-design-vue";
import timeView from "@/views/base/time.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const route = useRoute();
const store = useStore();
const emit = defineEmits([
  "closeFileView",
  "downFile",
  "deleteFile",
  "getFileList",
]);
const props = defineProps([
  "fileItem",
  "chunkDialog",
  "type",
  "chunkList",
  "qaType",
  "isValed",
]);
const topicItem = ref(store.state.topicItem);
const baseItem = ref(store.state.baseItem);
const yzItem = ref(store.state.yzItem);
const botItem = ref(store.state.botItem);
const textType = ref(false);
const userId = ref(store.state.userId);
const chatType = ref(store.state.chatType);
const textInner = ref([]);
const timeDialog = ref(false); //设置过期时间
const fileDescObj = ref({}); //文件描述
const timeLoading = ref(false);
const routeType = ref("");
const routeMetaType = ref("");
const fileObj = ref({});
onMounted(() => {
  routeType.value = route.query.type ? route.query.type : "chat";
  routeMetaType.value = route.meta.type;
  changeItem();
  getText();
  getFileDesc();
});
watch(
  () => route.meta.type,
  (newValue, oldValue) => {
    routeMetaType.value = route.meta.type;
    changeItem();
  }
);
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => props.fileItem,
  (newValue, oldValue) => {
    textType.value = false;
    textInner.value = [];
    timeDialog.value = false;
    fileDescObj.value = {};
    timeLoading.value = false;
    getFileDesc();
    getText();
  }
);
watch(
  () => store.state.baseItem,
  (newValue, oldValue) => {
    baseItem.value = store.state.baseItem;
    changeItem();
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = store.state.topicItem;
    changeItem();
  }
);
watch(
  () => store.state.yzItem,
  (newValue, oldValue) => {
    yzItem.value = store.state.topicItem;
    changeItem();
  }
);
watch(
  () => store.state.botItem,
  (newValue, oldValue) => {
    botItem.value = store.state.botItem;
    changeItem();
  }
);
// 根据场景切换不同的item
const changeItem = () => {
  if (routeMetaType.value == "chat") {
    fileObj.value = topicItem.value;
  } else if (routeMetaType.value == "yanzheng") {
    fileObj.value = yzItem.value;
  } else if (routeMetaType.value == "base") {
    fileObj.value = baseItem.value;
  } else if (routeMetaType.value == "bot") {
    fileObj.value = botItem.value;
  }
};
// 确定设置过期时间
const saveTime = (time) => {
  timeLoading.value = true;
  api.base
    .importantSet({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
      expireTime: time,
      libraryId:
        fileObj.value.knowledge_library_id ||
        fileObj.value.libraryId ||
        fileObj.value.knowledge.knowledge_library_id,
      operateType: "add",
    })
    .then((res) => {
      timeLoading.value = false;
      if (res.returnCode == 200) {
        timeDialog.value = false;
        message.success(t("base.base105"));
        getFileDesc();
        emit("getFileList");
      }
    })
    .catch((err) => {
      timeLoading.value = false;
    });
};
const getFileDesc = () => {
  if (chatType.value == "base") {
    api.base
      .fileDesc({
        userId: userId.value,
        libraryId:
          fileObj.value.knowledge_library_id ||
          fileObj.value.libraryId ||
          fileObj.value.knowledge.knowledge_library_id,
        fileId: props.fileItem.fileId || props.fileItem.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          fileDescObj.value = res.data;
        }
      });
  }
};
defineExpose({
  getFileDesc,
});
const closeView = () => {
  emit("closeFileView");
};
const changeMore = (e) => {
  if (e == 1) {
    emit("downFile", props.fileItem);
  }
  if (e == 2) {
    emit("deleteFile");
  }
};
const changeSel = (e) => {
  if (e == 1) {
    timeDialog.value = true;
  }
  if (e == 2) {
    api.base
      .importantSet({
        userId: userId.value,
        fileId: props.fileItem.fileId || props.fileItem.id,
        expireTime: null,
        libraryId:
          fileObj.value.knowledge_library_id ||
          fileObj.value.libraryId ||
          fileObj.value.knowledge.knowledge_library_id,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base15"));
          getFileDesc();
          emit("getFileList");
        }
      });
  } else if (e == 3) {
    api.base
      .fileExpire({
        userId: userId.value,
        fileId: props.fileItem.fileId || props.fileItem.id,
        expireTime: null,
        libraryId:
          fileObj.value.knowledge_library_id ||
          fileObj.value.libraryId ||
          fileObj.value.knowledge.knowledge_library_id,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base15"));
          getFileDesc();
          emit("getFileList");
        }
      });
  }
  if (e == 4) {
    timeDialog.value = true;
  }
};
const closeTime = () => {
  timeDialog.value = false;
};
// 展示文字
const lookText = () => {
  textType.value = !textType.value;
};
// 判断高亮
const getId = (item) => {
  if (!props.chunkList || props.chunkList.length == 0) {
    return false;
  }
  const hasChunkId = props.chunkList.some((e) => e.chunkId === item.chunkId);
  return hasChunkId;
};
// 获取文字内容
const getText = () => {
  if (chatType.value != "chat") {
    api.base
      .textView(
        {
          userId: userId.value,
          fileId: props.fileItem.fileId || props.fileItem.id,
        },
        fileObj.value.knowledge_library_id ||
          fileObj.value.libraryId ||
          fileObj.value.knowledge.knowledge_library_id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          textInner.value = res.returnMessage;
        }
      });
  }
};
</script>

<style lang="scss" scoped>
.topMDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 92px;
  padding: 0px 30px;
  border-bottom: 1px solid #e2e6ed;
  background-color: #fefefe;
  border-radius: 30px 30px 0px 0px;
}
.topRightDiv59 {
  width: calc(100% - 420px);
  height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.topRightDivCenter {
  width: calc(100% - 30px);
  height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.topTitleName {
  color: #8a8a8a;
  font-size: 20px;
  font-weight: 400;
  max-width: 180px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid24 {
  width: 24px;
}
.topFileName {
  color: #3d3d3d;
  font-size: 20px;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topFontItem {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 18px;
}
.nameMax {
  max-width: 100px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topTx {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin: 0px 10px;
  font-size: 16px;
  color: #f7f8ff;
  text-align: center;
  font-weight: 400;
  line-height: 31px;
}
.wid25 {
  width: 18px;
}
.marGin12 {
  margin: 0px 6px 0px 12px;
}
.topRightBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 420px;
}
.topLook {
  height: 44px;
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  margin-left: 12px;
  cursor: pointer;
}
.marGin6 {
  margin-right: 6px;
}
.topFonItemF {
  color: #0256ff;
  font-weight: 400;
  font-size: 18px;
}
.rightFileChunk {
  padding: 12px;
  height: calc(100% - 117px);
  width: calc(100% - 24px);
  background-color: #ffffff;
  overflow: auto;
}
.closeFileView {
  width: 14px;
  margin-left: 12px;
  cursor: pointer;
}
.hMore {
  width: 18px;
}
.moreDiv {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}
.moreDiv:hover {
  background-color: #f7f8ff;
}
.textMessageItem {
  font-size: 18px;
  font-weight: 400;
  padding: 12px;
}
.textMessageItemAc {
  border-radius: 12px;
  background-color: #faf2d8;
}
.textMessageItembg {
  border-radius: 12px;
  background-color: #f7f8ff;
}
.nameH {
  max-width: calc(100% - 144px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.nameC {
  max-width: calc(100% - 100px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.nameN {
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
.redColor {
  color: #f02d63;
}
.blueColor {
  color: #0256ff;
}
.scroller {
  height: 100%;
  width: 100%;
}
</style>
