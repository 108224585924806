<template>
  <div>
    <!-- 顶部 -->
    <div class="topDiv">
      <div class="topLeftDiv">
        <div
          :class="tagItem == null ? 'topDivTitle' : 'max50'"
          v-if="fileList.length == 0"
        >
          <div class="leftText">
            <el-tooltip
              popper-class="toolTip"
              effect="dark"
              :content="userName"
            >
              <div class="topTitle">{{ nowTime + "，" }}{{ userName }}</div>
            </el-tooltip>
            <div class="topTip" v-if="tagItem == null">
              {{ t("menu.index1") }}
            </div>
          </div>
          <!-- <div class="rightTop">
            <tipCom
              class="tipItem"
              v-for="item in messageList"
              :key="item"
              @click="saveMessage(item)"
            >
              <el-tooltip popper-class="toolTip"  effect="dark" :content="item">
                {{ item.length > 12 ? item.slice(0, 12) + "..." : item }}
              </el-tooltip>
            </tipCom>
            <div class="reshDiv" @click="getMessage" v-if="messageList.length !=0">
              <img :src="icon.reshImg" alt="" class="reshIcon" />
            </div>
          </div> -->
        </div>
        <div class="chatDiv">
          <chatIndex
            type="chat"
            ref="chatIndexRef"
            :bottomFileList="[]"
            :inputType="true"
            @changeFile="changeFile"
            @handleSearch="handleSearch"
          ></chatIndex>
        </div>
      </div>
      <topRight></topRight>
    </div>
    <!-- 底部 -->
    <bottom></bottom>
  </div>
</template>

<script setup>
import topRight from "./components/topRight.vue";
import bottom from "./components/bottom.vue";
import chatIndex from "@/components/chat/index.vue";
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import { formatAxis } from "@/utils/qincheng";
import tipCom from "@/components/tip/index.vue";
import api from "@/api";
import { useI18n } from "vue-i18n";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const userName = ref(store.state.userName);
const messageList = ref([]);
const chatIndexRef = ref(null);
const nowTime = computed(() => {
  return formatAxis(new Date());
});
const tagItem = ref(null);
onMounted(() => {
  // getMessage();
  store.commit("SET_CHATTYPE", "chat");
});
const saveMessage = (item) => {
  if (chatIndexRef.value) {
    chatIndexRef.value.saveMessage(item);
  }
};
// 获取随机三条
const getMessage = () => {
  api.chat.randomMessage().then((res) => {
    if (res.returnCode == 200) {
      messageList.value = res.data;
    }
  });
};
// 切换@值
const handleSearch = (value) => {
  tagItem.value = value;
};
const fileList = ref([]);
const changeFile = (list) => {
  fileList.value = list;
};
</script>

<style lang="less" scoped>
.topDiv {
  height: 430px;
  margin-bottom: 20px;
  display: flex;
}
.topLeftDiv {
  min-width: 935px;
  width: 70%;
  background-color: #fefefe;
  border-radius: 30px;
  padding: 20px;
  margin-right: 20px;
  box-shadow: 0px 0px 16px 0 #dbdfff82;
}
.topTitle {
  font-size: 24px;
  color: #3d3d3d;
  font-weight: 600;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topTip {
  font-size: 24px;
  color: #3d3d3d;
  font-weight: 400;
}
.topDivTitle {
  min-height: 120px;
  display: flex;
  margin-bottom: 10px;
}
.chatDiv {
  width: 100%;
}
.rightTop {
  width: 600px;
  height: 110%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.leftText {
  width: calc(100% - 600px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.reshDiv {
  width: 36px;
  height: 36px;
  background-color: #f7f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
}
.reshIcon {
  width: 22px;
  height: 17px;
}
.tipItem {
  margin-bottom: 10px;
}
.max50 {
  height: 50px;
  overflow: hidden;
  display: flex;
  margin-bottom: 20px;
  max-height: 50px;
}
</style>
