<template>
  <div class="addContent">
    <div class="titleFlex">
      <div class="searchDiv">
        <div class="addTitle">{{ t("bot.base164") }}</div>
        <el-input
          prefix-icon="Search"
          :placeholder="t('bot.base128')"
          class="searchInput"
          v-model="agentNamePattern"
          @input="searchInput"
        ></el-input>
      </div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeTool" />
    </div>
    <div
      class="plugDiv"
      v-infinite-scroll="loadList"
      :infinite-scroll-distance="5"
      :infinite-scroll-immediate="false"
      v-loading="toolLoading"
    >
      <template v-if="allToolList.length != 0">
        <div
          class="flexCenter bottom16"
          v-for="item in allToolList"
          :key="item.agentId"
        >
          <img :src="getImg(item)" alt="" class="plugUpload" />
          <div class="toolTitle">{{ item.agentName }}</div>
          <div class="toolTip">{{ item.agentDescription }}</div>
          <el-button
            class="addBtn"
            @click="addTool(item)"
            v-if="getId(item) == false"
          >
            <el-icon class="yesIcon addIcon"><Plus /></el-icon>
            {{ t("bot.base165") }}
          </el-button>
          <el-button
            class="ytjBtn"
            v-if="getId(item) == true"
            @click="deleteTool(item)"
          >
            <el-icon class="yesIcon"><Check /></el-icon>
            {{ t("bot.base166") }}
          </el-button>
        </div>
      </template>
      <template v-if="allToolList.length == 0 && !toolLoading">
        <el-empty />
      </template>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import api from "@/api";
import { icon } from "@/utils/icon";
import { debounce } from "@/utils/utils";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useStore } from "vuex";
const store = useStore();
const userId = ref(store.state.userId);
const pageId = ref(1);
const perPage = ref(10);
const allToolList = ref([]);
const toolLoading = ref(false);
const agentNamePattern = ref("");
const props = defineProps(["toolList"]);
const emit = defineEmits(["closeTool", "deleteTool"]);
onMounted(() => {
  getTool();
});
// 下拉加载
const loadList = () => {
  pageId.value++;
  getTool();
};
// 搜索
const searchInput = () => {
  pageId.value = 1;
  getTool();
};
const getImg = (item) => {
  if (item.image_path) {
    return "/openai/chat/v4/image/get/" + item.image_path;
  } else {
    return icon.plugUpload;
  }
};
// 判断
const getId = (item) => {
  return props.toolList.some((items) => items.agentId == item.agentId);
};
// 删除插件
const deleteTool = (item) => {
  emit("deleteTool", item);
};
// 添加插件
const addTool = (item) => {
  props.toolList.push(item);
};
// 获取插件列表
const getTool = debounce(() => {
  toolLoading.value = true;
  api.bot
    .toolQuery({
      userId: userId.value,
      agentNamePattern: agentNamePattern.value,
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      toolLoading.value = false;
      if (res.returnCode == 200) {
        if (pageId.value == 1) {
          allToolList.value = res.data;
        } else {
          allToolList.value = allToolList.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      toolLoading.value = false;
    });
}, 200);
const closeTool = () => {
  emit("closeTool");
};
</script>

<style lang="scss" scoped>
.plugDiv {
  max-height: 292px;
  overflow: auto;
}
.addIcon {
  color: #3376ff;
}
.yesIcon {
  margin-right: 3px;
}
.bottom16 {
  margin-bottom: 16px;
}
.ytjBtn {
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 0px;
  font-size: 16px;
  color: #d9d9d9;
  font-weight: 500;
}
.addBtn {
  background-color: #f7f8ff;
  border: 0px;
  box-shadow: 0px 0px 0px 0px;
  font-size: 16px;
  color: #3376ff;
  font-weight: 500;
  border-radius: 8px;
}
.toolTip {
  font-size: 16px;
  color: #696969;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 321px;
}
.toolTitle {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 500;
  width: 121px;
  margin-right: 18px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.plugUpload {
  width: 32px;
  margin-right: 6px;
  border-radius: 8px;
}
.searchDiv {
  display: flex;
  align-items: center;
  width: calc(100% - 14px);
}
.searchInput {
  width: 240px;
  margin-left: 24px;
  :deep(.el-input__wrapper) {
    height: 48px;
    border-radius: 12px;
    background-color: #f6f6f6;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
    font-size: 18px;
    font-weight: 400;
  }
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 120;
  opacity: 0.3;
}

.addContent {
  position: fixed;
  width: 540px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 121;
  padding: 30px;
  border-radius: 30px;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}

.addClose {
  width: 14px;
  cursor: pointer;
}

.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
</style>
