<template>
  <div class="pos">
    <div class="gptTitle" @click.stop="openDialog">
      <template v-if="model">
        <img class="gptAzure" :src="getModelIcon" alt="" />
        <el-tooltip popper-class="toolTip" :content="model">
          <div class="modelText">{{ model }}</div>
        </el-tooltip>
      </template>
      <div v-else>{{ t("manage.baseForm.base33") }}</div>
      <img :src="icon.modelOptions" class="modelOptions" alt="" />
    </div>
    <!-- 弹出框 -->
    <div
      :class="{
        bottomClass: props.type == 'bottom',
        rightClass: props.type == 'right',
        leftClass: props.type == 'left',
      }"
      class="chatDivDialogssss"
      v-if="divDialog"
    >
      <!-- 选择模型 -->
      <div class="flexBetween">
        <div class="chatTitle">
          {{ t("manage.baseForm.base88") }}
        </div>
        <el-select v-model="model" @change="changeModel" class="dialogChatSel">
          <el-option-group
            v-for="(group, index) in props.modelList"
            :key="group.name"
          >
            <div class="flexCenter groupDiv">
              <img :src="returnModel(group.name)" alt="" class="gropuImg" />
              <span class="groupName">{{ t("gpt." + group.name) }}</span>
            </div>
            <el-option
              v-for="item in group.models"
              :key="item.model"
              :label="item.model"
              :value="item.model"
            >
              <div class="flexCenter">
                <img :src="returnModel(item.icon)" alt="" class="gptAzure" />
                <span class="selText">{{ item.model }}</span>
              </div>
            </el-option>
            <el-divider class="hr" v-if="index != props.modelList.length - 1" />
          </el-option-group>
          <template #prefix>
            <img :src="getModelIcon" alt="" class="gptAzure" />
          </template>
        </el-select>
      </div>
      <!-- hr -->
      <el-divider v-if="props.tagActive != 2" />
      <gptView
        v-if="props.tagActive != 2"
        ref="gptRef"
        @sendForm="sendForm"
        type="chat"
        :llmConfigurationDict="props.llmConfigurationDict"
        :llmNav="props.llmNav"
        :gptDesc="gptDesc"
      ></gptView>
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { watch, ref, onMounted, onUnmounted, nextTick, computed } from "vue";
import gptView from "./gpt.vue";
import { useI18n } from "vue-i18n";
import { returnModel } from "@/utils/gpt";
import { message } from "ant-design-vue";
import api from "@/api";
const { t } = useI18n();
import { useStore } from "vuex";
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps([
  "model",
  "modelList",
  "type",
  "macType",
  "tagActive",
  "llmConfigurationDict",
  "llmNav",
  "copyModelList",
]);
const emit = defineEmits(["changeModel", "sendForm"]);
const model = ref(null);
watch(
  () => props.model,
  (newValue, oldValue) => {
    if (props.model) {
      model.value = props.model;
    } else {
      model.value = null;
    }
    getModelDesc();
  }
);
const getModelIcon = computed(() => {
  if (model.value && props.copyModelList && props.copyModelList.length != 0) {
    let item = props.copyModelList.find((item) => item.model === model.value);
    if (item) {
      return returnModel(item.icon);
    } else {
      return null;
    }
  }
});
onMounted(() => {
  document.addEventListener("click", handleOutsideClick);
  model.value = props.model;
  getModelDesc();
});
onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick);
});
// 获取模型默认参数
const gptDesc = ref({
  customize: {
    frequencyPenalty: 0.5,
    maxRound: 5,
    maxTokens: 4096,
    outputFormat: 0,
    presencePenalty: 0.5,
    temperature: 0.2,
    topP: 0.75,
    isFrequencyPenaltyOn: 0,
    isMaxRoundOn: 0,
    isMaxTokensOn: 0,
    isOutputFormatOn: 0,
    isPresencePenaltyOn: 0,
    isTemperatureOn: 0,
    isTopPOn: 0,
  },
});
const gptRef = ref(null);
const getModelDesc = () => {
  if (props.tagActive == 2) return;
  if (model.value) {
    gptDesc.value = {
      customize: {
        frequencyPenalty: 0.5,
        maxRound: 5,
        maxTokens: 4096,
        outputFormat: 0,
        presencePenalty: 0.5,
        temperature: 0.2,
        topP: 0.75,
        isFrequencyPenaltyOn: 0,
        isMaxRoundOn: 0,
        isMaxTokensOn: 0,
        isOutputFormatOn: 0,
        isPresencePenaltyOn: 0,
        isTemperatureOn: 0,
        isTopPOn: 0,
      },
    };
    api.gpt
      .llmGet({
        userId: userId.value,
        modelType: model.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          gptDesc.value = { ...res.data, ...gptDesc.value };
          nextTick(() => {
            if (gptRef.value) {
              gptRef.value.copyDesc(true);
            }
          });
        }
      });
  }
};
// 打开弹出
const divDialog = ref(false);
const openDialog = () => {
  if (props.macType) {
    message.warning(t("gpt.index76"));
    return;
  }
  divDialog.value = true;
};
// 监听点击事件，点击页面其他地方时关闭弹出框
const handleOutsideClick = (event) => {
  if (divDialog.value && !event.target.closest(".chatDivDialogssss")) {
    divDialog.value = false;
  }
};
// 切换模型
const changeModel = (e) => {
  model.value = e;
  getModelDesc();
  emit("changeModel", e);
};
// 发送参数
const sendForm = (result, nav) => {
  emit("sendForm", result, nav);
};
</script>

<style lang="scss" scoped>
.pos {
  position: relative;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.modelOptions {
  width: 14px;
  margin-left: 10px;
}
.modelText {
  width: calc(100% - 56px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.gptTitle {
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  background-color: #fefefe;
  display: flex;
  align-items: center;
  padding: 12px;
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 500;
  cursor: pointer;
  max-width: 200px;
}
.gptAzure {
  width: 20px;
  margin-right: 10px;
}
.gropuImg {
  width: 16px;
  margin-right: 10px;
}
.selText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
}
.chatDivDialogssss {
  width: 452px !important;
  padding: 24px;
  background-color: #fefefe;
  border-radius: 16px;
  z-index: 100;
  position: absolute;
  border: 1px solid #edeef6;
  box-shadow: 1px 2px 8px 0px rgba(204, 206, 229, 0.25);
  margin-top: 6px;
  overflow: auto;
  max-height: 480px;
}
.bottomClass {
  right: 0;
}
.rightClass {
  left: 0;
}
.dialogChatSel {
  max-width: 300px;
  :deep(.el-select__wrapper) {
    background-color: #f6f6f6;
    border-radius: 8px;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
    height: 20px;
    padding: 10px 12px;
  }
}
.chatTitle {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 600;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftClass {
  right: 230px;
  top: -150px;
}
.groupDiv {
  padding: 0px 32px 0px 20px;
  height: 45px;
}
.groupName {
  font-size: 15px;
  color: #8a8a8a;
  font-weight: 500;
}
.hr {
  margin: 10px 20px;
  width: calc(100% - 40px);
}
</style>
