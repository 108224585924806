
import axios from "../server";
export default {
    // 文件轮询
    documentQuery(data: any) {
        return axios({
            url: "/translate/document/query",
            method: "POST",
            data,
        });
    },
    // 字典
    getLanguage(data: any) {
        return axios({
            url: "/translate/language-support/get",
            method: "GET",
            data,
        });
    },
    // 上传文件
    fileTranslate(data: any, apiId: any) {
        return axios({
            url: "/translate/translate",
            method: "POST",
            headers: {
                apiId: JSON.stringify(apiId),
                apiType: 'file'
            },
            data,
        });
    },
    // 文件轮询
    statusView(data: any) {
        return axios({
            url: "/translate/task-status/view",
            method: "POST",
            data,
        });
    },
    // 图片/历史记录
    imageHistory(data: any) {
        return axios({
            url: "/picture-describe/history/query",
            method: "POST",
            data,
        });
    },
    // 图片转文字
    imgToTxt(data: any, apiId: any) {
        return axios({
            url: "/picture-describe/picture/describe",
            method: "POST",
            headers: {
                apiType: 'file',
                apiId: JSON.stringify(apiId),
            },
            data,
        });
    },
    //打开DOC
    exlcexDoc(data: any) {
        return axios({
            url: "/knowledge-excel-qa/fileUrl/find",
            method: "POST",
            data,
        });
    },
    // 打开text
    excelGetText(data: any) {
        return axios({
            url: `knowledge-excel-qa/text/${data.userId}/${data.id}/download`,
            method: "GET",
        });
    },
    //pdf
    excelPdfView(data: any) {
        return axios({
            url: "/knowledge-excel-qa/pdfUrl/find",
            method: "POST",
            data,
        });
    },
    // 左侧excel文件预览
    excelView(data: any) {
        return axios({
            url: "/knowledge-excel-qa/excel/view",
            method: "POST",
            data,
        });
    },
    // excel demo 左侧文件列表
    excelDemoFileList(data: any) {
        return axios({
            url: "/knowledge-excel-qa/file/list",
            method: "POST",
            data,
        });
    },
    // excel列表 轮询
    excelAnswerQuery(data: any) {
        return axios({
            url: "/knowledge-excel-qa/answer/query",
            method: "POST",
            data,
        });
    },
    // 上传文件问答
    excelFileAnswer(data: any, apiId: any) {
        return axios({
            url: "/knowledge-excel-qa/file/answer",
            headers: {
                apiType: 'file',
                apiId: JSON.stringify(apiId),
            },
            method: "POST",
            data,
        });
    },
    // excelDemo 左侧文件上传
    excelFileUpload(data: any, apiId: any) {
        return axios({
            url: "/knowledge-excel-qa/file/upload",
            headers: {
                apiType: 'file',
                apiId: JSON.stringify(apiId),
            },
            method: "POST",
            data,
        });
    },
    // 上传语音
    upVoice(data: any, apiId: any) {
        return axios({
            url: "/voice-convert/voice/convert",
            method: "POST",
            headers: {
                apiType: 'file',
                apiId: JSON.stringify(apiId),
            },
            data,
        });
    },
    // 语音轮询
    voiceStatus(data: any) {
        return axios({
            url: "voice-convert/voice/status",
            method: "POST",
            data,
        });
    },
    //语言轮询，成功后调用
    voiceResult(data: any) {
        return axios({
            url: "voice-convert/voice/result",
            method: "POST",
            data,
        });
    },
    //语音历史记录
    voiceListApi(data: any) {
        return axios({
            url: "/voice-convert/history/query",
            method: "POST",
            data,
        });
    },
    // ocr历史
    ocrHistory(data: any) {
        return axios({
            url: "/file-convert/history/query",
            method: "POST",
            data,
        });
    },
    // ocr
    upFileOcr(data: any, apiId: any) {
        return axios({
            url: "/file-convert/file/convert",
            method: "POST",
            headers: {
                apiType: 'file',
                apiId: JSON.stringify(apiId),
            },
            data,
        });
    },
    // 首页智能体查询
    recommendQuery(data: any) {
        return axios({
            url: "/chat/session/template/recommend/query",
            method: "POST",
            data,
        });
    },
    // 智能体模板查询
    templateQuery(data: any) {
        return axios({
            url: "/memory-chat/template/query",
            method: "POST",
            data,
        });
    },
    // 模板顶置/取消
    templateTop(data: any) {
        return axios({
            url: "/memory-chat/template/pin",
            method: "POST",
            data,
        });
    },
    // 模板新增
    templateAdd(data: any) {
        return axios({
            url: "/memory-chat/template/add",
            method: "POST",
            data,
        });
    },
    // 模板修改
    templateEdit(data: any) {
        return axios({
            url: "/memory-chat/template/update",
            method: "POST",
            data,
        });
    },
    // 查看模板详情
    templateDetail(data: any) {
        return axios({
            url: "/memory-chat/template/desc",
            method: "POST",
            data,
        });
    },
    // 模板删除
    templateDel(data: any) {
        return axios({
            url: "/memory-chat/template/delete",
            method: "POST",
            data,
        });
    },
    // 一键完善
    templatePerfect(data: any) {
        return axios({
            url: "/chat/v4/describe/optimize",
            method: "POST",
            data,
        });
    },
    // 插件列表
    toolQuery(data: any) {
        return axios({
            url: "agent-tool/query",
            method: "POST",
            data,
        });
    },
    // 插件置顶
    toolPin(data: any) {
        return axios({
            url: "agent-tool/pin",
            method: "POST",
            data,
        });
    },
    // 插件生成测试
    toolSwaggerParse(data: any) {
        return axios({
            url: "agent-tool/swagger/parse",
            method: "POST",
            data,
        });
    },
    // 插件测试
    toolSwaggerTest(data: any) {
        return axios({
            url: "agent-tool/swagger/function/test",
            method: "POST",
            data,
        });
    },
    // 插件新增
    toolAdd(data: any) {
        return axios({
            url: "agent-tool/add",
            method: "POST",
            data,
        });
    },
    // 插件新详情
    toolDesc(data: any) {
        return axios({
            url: "agent-tool/desc",
            method: "POST",
            data,
        });
    },
    // 插件删除
    toolDelete(data: any) {
        return axios({
            url: "agent-tool/delete",
            method: "POST",
            data,
        });
    },
    // 插件编辑更新
    toolUpdate(data: any) {
        return axios({
            url: "agent-tool/update",
            method: "POST",
            data,
        });
    },
    // 插件url导入
    urlDown(data: any) {
        return axios({
            url: "/agent-tool/duwei/download",
            method: "POST",
            data,
        });
    },
    
}