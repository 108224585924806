<template>
  <div class="headerContent">
    <div class="headerDiv">
      <div class="flexCenter abs">
        <!-- 菜单 -->
        <div
          class="flexCenter"
          v-if="
            routeType == 'menu' &&
            menuItem.name != 'index' &&
            menuItem.name != 'chat'
          "
        >
          <img class="wid31" :src="menuItem.active" alt="" />
          <div class="menuTitle" v-if="menuItem.name">
            {{ $t("menu." + menuItem.name) }}
          </div>
          <img
            :src="icon.menuIconRight"
            alt=""
            class="widRight24"
            v-if="menuItem.menuName"
          />
          <div class="menuTip" v-if="menuItem.tip">{{ t(menuItem.tip) }}</div>
          <!-- 平台管理专属 -->
          <div class="menuTitle" v-if="menuItem.menuName">
            {{ t(menuItem.menuName) }}
          </div>
        </div>
        <!-- 聊天 -->
        <div
          class="flexCenter wid100"
          v-if="
            (routeType == 'chat' || menuItem.name == 'chat') &&
            routeType != 'bot'
          "
        >
          <div class="returnDiv" @click="goBack">
            <img class="goBackImg" :src="icon.goBackImg" alt="" />
            <span>{{ t("header.header46") }}</span>
          </div>
          <img :src="icon.iconPng" alt="" class="wid22" />
          <div class="topicName">
            {{ topicItem.sessionName }}
          </div>
          <img
            :src="icon.timePng"
            alt=""
            class="wid18"
            v-if="topicItem.updateTime"
          />
          <div class="topicItem" v-if="topicItem.updateTime">
            {{ getTime(topicItem.updateTime) }}
          </div>
          <div class="flexCenter" v-if="topicItem.isValid == 1">
            <img :src="icon.yesPng" alt="" class="wid18" />
            <div class="topicItem">
              {{
                topicItem.expireDate != null
                  ? getYear(topicItem.expireDate)
                  : t("menu.index4")
              }}
            </div>
          </div>
          <img
            :src="icon.hotPngHeader"
            alt=""
            class="wid16"
            v-if="topicItem.result != 0 && topicItem.result != null"
          />
          <div
            class="topicItem"
            v-if="topicItem.result != 0 && topicItem.result != null"
          >
            {{ topicItem.result }}
          </div>
          <div v-if="topicItem.knowledge_name" class="flexCenter topTipDiv">
            <img :src="icon.nextAiPng" alt="" class="wid21" />
            <div class="topTip">{{ topicItem.knowledge_name }}</div>
          </div>
        </div>
        <!-- 验证 -->
        <div class="flexCenter wid100" v-if="routeType == 'yanzheng'">
          <div class="returnDiv" @click="goBack">
            <img class="goBackImg" :src="icon.goBackImg" alt="" />
            <span>{{ t("header.header46") }}</span>
          </div>
          <div class="yZdIv">
            <img :src="icon.headerYz" alt="" class="headerYz" />
            {{ t("header.header4") }}
          </div>
          <div class="topicName">{{ yzItem.title }}</div>
          <img :src="icon.timePng" alt="" class="wid18" />
          <div class="topicItem">{{ getTime(yzItem.createdAt) }}</div>
          <div
            class="flexCenter topTipDiv"
            v-if="yzItem.libraryName || yzItem.knowledge_name"
          >
            <img :src="icon.nextAiPng" alt="" class="wid21" />
            <div class="topTip">
              {{ yzItem.libraryName || yzItem.knowledge_name }}
            </div>
          </div>
        </div>
        <!-- 微模型 -->
        <div class="flexCenter wid100" v-if="routeType == 'base'">
          <div class="returnDiv" @click="goBack">
            <img class="goBackImg" :src="icon.goBackImg" alt="" />
            <span>{{ t("header.header46") }}</span>
          </div>
          <img
            class="baseTx"
            :src="baseItem.imageName ? baseItem.imageName : icon.nextAiPng"
            alt=""
          />
          <div class="baseTitle">{{ baseItem.knowledge_name }}</div>
          <img
            :src="icon.titleSpImg"
            v-if="baseItem.resourcetype == 1"
            class="titleSpImg"
          />
          <div
            :style="'background-color:' + baseItem.ownerColor"
            class="messageTx"
          >
            {{ baseItem.ownerName.slice(0, 1) }}
          </div>
          <div class="baseUserName">{{ baseItem.ownerName }}</div>
          <img :src="icon.HeaderFile" alt="" class="baseImgItem" />
          <div class="speedTitle">{{ baseItem.documentNum }}</div>
          <img :src="icon.baseTopic" alt="" class="baseImgItem" />
          <div class="speedTitle">{{ baseItem.conversationNum }}</div>
          <img :src="icon.HeaderMember" alt="" class="baseImgItem" />
          <div class="speedTitle">{{ baseItem.userNum }}</div>
        </div>
        <!-- 智能体 -->
        <div class="flexCenter wid100" v-if="routeType == 'bot'">
          <div class="returnDiv" @click="goBack">
            <img class="goBackImg" :src="icon.goBackImg" alt="" />
            <span>{{ t("header.header46") }}</span>
          </div>
          <img
            class="boxTx"
            :src="
              botItem.image_path != null
                ? '/openai/chat/v4/image/get/' + botItem.image_path
                : icon.botImg
            "
            alt=""
          />
          <div class="baseTitle">{{ botItem.templateName }}</div>
        </div>
      </div>
      <div class="flexCenter bg">
        <!-- 仪表盘 -->
        <div class="headerItem" @click="changeSpeed" v-if="!speedType">
          <img :src="icon.ybp" alt="" class="headerImg" />
        </div>
        <div class="headerItem" @click="changeSpeed" v-if="speedType">
          <img :src="icon.sq" alt="" class="rightIcon" />
        </div>
        <!-- 测速 -->
        <div class="flexCenter" v-if="speedType">
          <img :src="icon.ybp" alt="" class="ybpIcon" />
          <div>
            <!-- s -->
            <div class="flexCenter">
              <div class="speedTitle">{{ $t("header.chatSpeed") }}</div>
              <el-tooltip
                popper-class="toolTip"
                :content="$t('header.chatSpeed')"
                placement="bottom"
              >
                <el-icon>
                  <Warning class="speedIcon" />
                </el-icon>
              </el-tooltip>
            </div>
            <!-- x -->
            <div class="flexCenter">
              <div class="speedLabel">OpenAI</div>
              <div
                v-if="chatTime != '' && !chatLoading"
                class="openAiNumber"
                @click="speedChat"
              >
                {{ chatTime }}<span class="font16">ms</span>
              </div>
              <div
                v-if="!chatTime && !chatLoading"
                class="speedTitle"
                @click="speedChat"
              >
                {{ t("header.header5") }}
              </div>
              <div v-if="chatLoading" class="speedTitle">
                {{ t("header.header6") }}
              </div>
              <div class="timeText">{{ nowTime }}</div>
            </div>
          </div>
        </div>
        <div class="flexCenter" v-if="speedType">
          <img :src="icon.ybp" alt="" class="ybpIcon" />
          <div>
            <!-- s -->
            <div class="flexCenter">
              <div class="speedTitle">{{ $t("header.adaSpeed") }}</div>
              <el-tooltip
                popper-class="toolTip"
                :content="$t('header.adaSpeed')"
                placement="bottom"
              >
                <el-icon>
                  <Warning class="speedIcon" />
                </el-icon>
              </el-tooltip>
            </div>
            <!-- x -->
            <div class="flexCenter">
              <div class="speedLabel">ADA</div>
              <div
                v-if="adaTime != '' && !adaLoading"
                class="adaNumber"
                @click="speedAda"
              >
                {{ adaTime }}<span class="font16">ms</span>
              </div>
              <div
                v-if="!adaTime && !adaLoading"
                class="speedTitle"
                @click="speedAda"
              >
                {{ t("header.header5") }}
              </div>
              <div v-if="adaLoading" class="speedTitle">
                {{ t("header.header6") }}
              </div>
              <div class="timeText">{{ nowTime }}</div>
            </div>
          </div>
        </div>
        <!-- 铃铛 -->
        <el-popover
          placement="bottom-start"
          trigger="click"
          popper-class="popperItem"
        >
          <template #reference>
            <div class="headerItem" @click="openMessageDialog">
              <img :src="icon.ld" alt="" class="ldImg" />
              <div
                :class="unreadCount <= 99 ? 'numberTip' : 'numberTip1'"
                v-if="unreadCount"
              >
                {{ unreadCount <= 99 ? unreadCount : "99+" }}
              </div>
            </div>
          </template>
          <headerMessageView
            ref="headerMessageRef"
            @getHeaderList="getHeaderList"
            :unreadCount="unreadCount"
          ></headerMessageView>
        </el-popover>

        <!-- 切换语言选项 -->
        <el-dropdown>
          <div class="headerItem">
            <img :src="icon.langTx" class="langTx" alt="" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in langList"
                :key="item.key"
                @click="changeDrop(item.key)"
              >
                <div class="messageCenter">
                  <img class="langImg" :src="item.icon" alt="" />
                  <div class="messageYzText">{{ t(item.name) }}</div>
                  <img
                    :src="icon.langActive"
                    class="langActive"
                    alt=""
                    v-if="item.key == langKey"
                  />
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- 头像 -->
        <el-popover placement="bottom" popper-class="txPopover">
          <template #reference>
            <div class="txItem">
              <img :src="avatar ? avatar : userImg" alt="" class="txImg" />
            </div>
          </template>
          <div>
            <div class="userItemDiv">
              <img :src="avatar ? avatar : userImg" alt="" class="avatar" />
              <div class="powerDiv">
                <el-tooltip
                  popper-class="toolTip"
                  effect="dark"
                  :content="userName"
                >
                  <div class="userName">
                    {{ userName }}
                  </div>
                </el-tooltip>
                <el-tooltip
                  popper-class="toolTip"
                  effect="dark"
                  :content="departmentName"
                >
                  <div class="userDepartment">
                    {{ departmentName ? departmentName : "" }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <el-divider class="userDivider" />
            <div v-for="(item, index) in userMenuList" :key="item.name">
              <div class="userMenuItem" @click="changeMenu(index)">
                <img class="userItemImg" :src="item.img" alt="" />
                <div>{{ t(item.name) }}</div>
              </div>
              <el-divider v-if="index == 1" class="userDivider" />
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <!-- 个人中心 -->
    <userInfoDialog
      v-if="userType"
      :userInfo="userInfo"
      @closeUserInfo="closeUserInfo"
      @successUserInfo="successUserInfo"
      @openPasswordDialog="openPasswordDialog"
    ></userInfoDialog>
    <!-- 修改密码弹窗 -->
    <div class="passwordDialog" v-if="passwordDialog">
      <div class="passwordDialogTopDiv">
        <div class="passwordDialogTitle">{{ t("header.header13") }}</div>
        <img
          :src="icon.closeMM"
          alt=""
          class="closeMM"
          @click="passwordDialog = false"
        />
      </div>
      <div class="emilInput">
        <div class="emilText">
          {{ email }}
        </div>
        <div class="emilTip">{{ t("header.header14") }}</div>
      </div>
      <div class="sendDiv">
        <el-input
          class="sendInput"
          autocomplete="new-password"
          :read-only="userNameType"
          @focus="userNameType = false"
          v-model="passwordForm.activationCode"
          :placeholder="t('login.login12')"
        >
        </el-input>
        <el-button
          class="sendBtn"
          link
          @click="sendCode"
          :loading="stepLoading"
          :disabled="sendType"
        >
          {{ sendType ? time : t("header.header15") }}
        </el-button>
      </div>
      <el-input
        class="passwordInput"
        autocomplete="new-password"
        :read-only="passwordType"
        @focus="passwordType = false"
        v-model="passwordForm.password"
        type="password"
        :placeholder="t('header.header16')"
        show-password
      />
      <el-input
        class="passwordInput"
        autocomplete="new-password"
        :read-only="passwordType"
        @focus="passwordType = false"
        v-model="surePassword"
        type="password"
        :placeholder="t('header.header17')"
        show-password
      />
      <div class="btnDiv">
        <el-button class="cancenBtn" @click="passwordDialog = false">{{
          t("header.header18")
        }}</el-button>
        <el-button
          class="saveBtn"
          @click="savePassword"
          :loading="upDataLoading"
          >{{ t("login.login17") }}</el-button
        >
      </div>
    </div>
    <div class="mask" v-if="passwordDialog || userType"></div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import userImg from "@/assets/header/userImg.png";
import { message } from "ant-design-vue";
import { useRouter, useRoute } from "vue-router";
import api from "@/api";
import SHA256 from "crypto-js/sha256";
import userInfoDialog from "./userInfoDialog.vue";
import { getTime } from "@/utils/utils";
import headerMessageView from "./headerMessage.vue";
import { getYear } from "@/utils/utils";
const { locale, t } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const langKey = ref(store.state.lang);
const speedType = ref(store.state.speedType);
const avatar = ref(store.state.avatar);
const userId = ref(Number(store.state.userId));
const userName = ref(store.state.userName);
const topicItem = ref(store.state.topicItem);
const botItem = ref(store.state.botItem);
const menuItem = ref(store.state.menuItem);
const yzItem = ref(store.state.yzItem);
const baseItem = ref(store.state.baseItem);
const email = ref(store.state.email);
const departmentName = ref(store.state.departmentName);
const unreadCount = ref(null); //未读条数
const surePassword = ref("");
const userMenuList = ref([
  {
    name: "header.header19",
    img: icon.grzx,
  },
  {
    name: "header.header13",
    img: icon.xgmm,
  },
  {
    name: "header.header21",
    img: icon.tcdl,
  },
]);
const langList = ref([
  {
    name: "header.header1",
    key: "CN",
    icon: icon.chinaImg,
  },
  {
    name: "header.header2",
    key: "HK",
    icon: icon.chinaHkImg,
  },
  {
    name: "header.header3",
    key: "US",
    icon: icon.usaImg,
  },
]);
// 测速
const chatTime = ref("");
const adaTime = ref("");
const chatLoading = ref(false);
const adaLoading = ref(false);
// 个人中心
const userType = ref(false);
const userInfo = ref({});
const upDataLoading = ref(false);
// 修改密码
const passwordDialog = ref(false);
const passwordType = ref(false);
const userNameType = ref(false);
const stepLoading = ref(false);
const sendType = ref(false);
const time = ref(60);
const timer = ref(null);
const routeType = ref("menu"); //判断菜单 chat base 验证
const menuList = ref(store.state.menuList);
// watch(
//   () => time.value,
//   (newValue, oldValue) => {
//     if (newValue <= 0) {
//       sendType.value = false;
//       time.value = 60;
//       clearInterval(timer.value);
//     }
//   }
// );
watch(
  () => store.state.menuItem,
  (newValue, oldValue) => {
    menuItem.value = store.state.menuItem;
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = store.state.topicItem;
  }
);
watch(
  () => store.state.botItem,
  (newValue, oldValue) => {
    botItem.value = store.state.botItem;
  }
);
watch(
  () => store.state.yzItem,
  (newValue, oldValue) => {
    yzItem.value = store.state.yzItem;
  }
);
watch(
  () => store.state.baseItem,
  (newValue, oldValue) => {
    baseItem.value = store.state.baseItem;
  }
);
// 监听router的变化 然后调用查询未读条数
watch(
  () => route.path,
  (newValue, oldValue) => {
    routeType.value = route.meta.type;
    if (route.meta.name != "lookAll") {
      let data = menuList.value.find((item) => item.name == route.meta.name);
      if (data) {
        store.commit(
          "SET_MENUACTIVE",
          menuList.value.find((item) => item.name == route.meta.name)
        );
      }
    } else {
      store.commit("SET_MENUACTIVE", {
        name: "lookAll",
        key: "/lookAll",
        type: 1,
        iconType: false,
        active: icon.lookAllMenu,
      });
    }
    getHeaderList();
  }
);
const goBack = () => {
  router.go(-1);
};
// 获取现在时间
const nowTime = computed(() => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
});
const passwordForm = ref({
  userName: store.state.userName,
  step: 2,
  activationCode: "",
  password: "",
});
onMounted(() => {
  routeType.value = route.meta.type;
  getHeaderList();
  getImg();
});

// 获取头像
const getImg = () => {
  api.login
    .getAvatar({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        avatar.value = res.data.avatar;
        store.commit("SET_AVATAR", res.data.avatar);
      }
    });
};

// 获取未读
const getHeaderList = () => {
  api.header.getCount({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      unreadCount.value = res.data.unreadCount;
    }
  });
};
// 保存更新头像
const successUserInfo = (userInfo) => {
  avatar.value = userInfo.avatar;
  store.commit("SET_AVATAR", userInfo.avatar);
  message.success(t("header.header22"));
};
// 打开消息列表弹窗
const headerMessageRef = ref(null);
const openMessageDialog = () => {
  getHeaderList();
  if (headerMessageRef.value) {
    headerMessageRef.value.firstPage();
  }
};
// 关闭用户
const closeUserInfo = () => {
  userType.value = false;
};
// chat测速
const speedChat = () => {
  chatLoading.value = true;
  api.header.getChatTime({ userId: userId.value }).then((res) => {
    chatLoading.value = false;
    if (res.returnCode == 200) {
      chatTime.value = res.result;
    }
  });
};
// ada测速
const speedAda = () => {
  adaLoading.value = true;
  api.header.getEmbeddingTime({ userId: userId.value }).then((res) => {
    adaLoading.value = false;
    if (res.returnCode == 200) {
      adaTime.value = res.result;
    }
  });
};
// 切换菜单
const changeSpeed = () => {
  speedType.value = !speedType.value;
  store.commit("SET_SPEEDTYPE", speedType.value);
};
// 点击切换语言
const changeDrop = (e) => {
  langKey.value = e;
  locale.value = e;
  store.commit("SET_LANG", e);
};
// 退出登录
const changeMenu = (key) => {
  // 个人中心
  if (key == 0) {
    api.header.getUserInfo({ userId: userId.value }).then((res) => {
      if (res.returnCode == 200) {
        userInfo.value = res.data;
        userType.value = true;
        passwordDialog.value = false;
      }
    });
  }
  // 修改密码
  if (key == 1) {
    userType.value = false;
    openPasswordDialog();
  }
  // 退出登录
  if (key == 2) {
    if (process.env.VUE_APP_NAME != "QC") {
      api.login
        .loginOut({
          code: store.state.authCode,
          state: store.state.authState,
        })
        .then((res) => {
          if (res) {
            store.commit("CLEAR_TOKEN", "");
            message.success(t("header.header23"));
            router.push("/");
          }
        });
    } else {
      store.commit("CLEAR_TOKEN", "");
      message.success(t("header.header23"));
      router.push("/");
    }
  }
};
// 打开重置密码
const openPasswordDialog = () => {
  userType.value = false;
  passwordForm.value = {
    userName: store.state.userName,
    step: 2,
    activationCode: "",
    password: "",
  };
  surePassword.value = "";
  passwordDialog.value = true;
};
// 修改密码保存
const savePassword = () => {
  if (!passwordForm.value.activationCode) {
    message.warning(t("header.header24"));
    return;
  }
  if (!passwordForm.value.password) {
    message.warning(t("header.header25"));
    return;
  }
  if (passwordForm.value.password.length < 6) {
    message.warning(t("header.header26"));
    return;
  }
  if (!surePassword.value) {
    message.warning(t("header.header17"));
    return;
  }
  if (passwordForm.value.password != surePassword.value) {
    message.warning(t("header.header27"));
    return;
  }
  upDataLoading.value = true;
  let data = {
    userName: passwordForm.value.userName,
    step: 2,
    activationCode: passwordForm.value.activationCode,
    password: SHA256(passwordForm.value.password).toString(),
  };
  api.login.postforgotPasswd(data).then((res) => {
    upDataLoading.value = false;
    if (res.returnCode == 200) {
      message.success(t("header.header22"));
      passwordDialog.value = false;
    }
  });
};
// 发送验证码
const sendCode = () => {
  let obj = {
    userName: userName.value,
    step: 1,
  };
  stepLoading.value = true;
  sendType.value = false;
  time.value = 60;
  api.login
    .postforgotPasswd(obj)
    .then((res) => {
      stepLoading.value = false;
      if (res.returnCode == 200) {
        sendType.value = true;
        timer.value = setInterval(() => {
          setTimeout(() => {
            time.value--;
            if (time.value <= 0) {
              sendType.value = false;
              time.value = 60;
              clearInterval(timer.value);
            }
          }, 500);
        }, 1000);
        message.success(t("login.login23"));
      }
    })
    .catch((err) => {
      stepLoading.value = false;
    });
};
</script>

<style lang="less" scoped>
.headerContent {
  width: 100%;
  height: 100%;
}

.headerImg {
  width: 24px;
  height: 20px;
}

.ldImg {
  width: 20px;
  height: 24px;
}

.headerDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  position: relative;
}

.abs {
  position: absolute;
  left: 30px;
  width: 70%;
}
.wid100 {
  width: 100%;
}
.headerItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #edeef6;
  border-radius: 16px;
  margin-left: 24px;
  cursor: pointer;
  position: relative;
}

.numberTip {
  position: absolute;
  right: -10px;
  top: -5px;
  width: 18px;
  padding: 1px 3px;
  height: 18px;
  background-color: #f02d63;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
}
.numberTip1 {
  position: absolute;
  right: -15px;
  top: -5px;
  padding: 1px 3px;
  height: 18px;
  background-color: #f02d63;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
}
.txItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 24px;
  overflow: hidden;
}

.txImg {
  width: 100%;
  height: 100%;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.speedTitle {
  font-size: 18px;
  font-weight: 400;
  color: #8a8a8a;
  margin-right: 10px;
  cursor: pointer;
}

.speedIcon {
  color: #8a8a8a;
}

.speedLabel {
  background-color: #3376ff;
  font-size: 14px;
  font-weight: 400;
  color: #fefefe;
  padding: 2px 8px;
  border-radius: 5px;
  margin-right: 5px;
}

.ybpIcon {
  margin-right: 12px;
  margin-left: 24px;
  width: 36px;
}

.openAiNumber {
  font-size: 20px;
  color: #ffc300;
  margin-right: 5px;
  cursor: pointer;
}

.adaNumber {
  font-size: 20px;
  color: #14ae5c;
  margin-right: 5px;
  cursor: pointer;
}

.font16 {
  font-size: 14px;
}

.timeText {
  color: #8a8a8a;
  font-size: 18px;
  font-weight: 400;
}

.rightIcon {
  width: 16px;
  height: 20px;
}

.topicName {
  font-size: 20px;
  font-weight: 600;
  color: #3d3d3d;
  margin: 0px 12px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.topicItem {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  margin: 0px 12px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topTip {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
  margin-left: 7px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topTipDiv {
  height: 26px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 5px 12px;
  background-color: #f6f6f6;
}

.wid22 {
  width: 22px;
}

.wid18 {
  width: 18px;
}

.wid21 {
  width: 21px;
}

.wid16 {
  width: 16px;
}

.wid31 {
  width: 31px;
  margin-right: 12px;
}

.menuTitle {
  font-size: 24px;
  color: #3d3d3d;
  font-weight: 400;
}

.menuTip {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  margin-left: 23px;
}

.widRight24 {
  margin: 0px 6px;
  width: 24px;
}

.yZdIv {
  background-color: #ffc300;
  height: 31px;
  border-radius: 30px;
  padding: 3px 10px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerYz {
  width: 25px;
  margin-right: 3px;
}
.mcImg {
  width: 20px;
  cursor: pointer;
}

.messageCenter {
  display: flex;
  align-items: center;
}

.messageTx {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin-right: 6px;
  text-align: center;
  line-height: 31px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}

.messageYzText {
  color: #3d3d3d;
  font-weight: 600;
  font-size: 18px;
}

.langImg {
  width: 21px;
  margin-right: 7px;
}

.langTx {
  width: 21px;
}

.langActive {
  width: 16px;
  margin-left: 11px;
}

.avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 12px;
}

.userName {
  font-size: 22px;
  color: #3d3d3d;
  font-weight: 500;
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.userDepartment {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.userItemDiv {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 12px;
}

.userMenuItem {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 12px 18px;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  cursor: pointer;
}

.userMenuItem:hover {
  background-color: #f7f8ff;
}

.userItemImg {
  width: 20px;
  margin-right: 5px;
}

.userDivider {
  margin: 12px 0px;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  z-index: 500;
}

.passwordDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  padding: 30px;
  background-color: #ffffff;
  z-index: 600;
  width: 412px;
}

.passwordDialogTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.closeMM {
  width: 14px;
  cursor: pointer;
}

.passwordDialogTopDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding-bottom: 19px;
  border-bottom: 2px solid #edeef6;
  margin-bottom: 24px;
}

.emilInput {
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 2px solid #edeef6;
  background-color: #edeef6;
  padding: 13px 30px;
  margin-bottom: 24px;
}

.emilTip {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  width: 72px;
}

.emilText {
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  width: calc(100% - 72px);
}

.sendBtn {
  color: #3376ff;
  font-size: 18px;
  font-weight: 400;
  padding: 0px;
  width: 100px;
}

.sendDiv {
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 2px solid #edeef6;
  padding: 12px 20px;
  height: 20px;
  margin-bottom: 24px;
}

.sendInput {
  :deep(.el-input__wrapper) {
    box-shadow: 0px 0px 0px;
    font-size: 18px;
    font-weight: 400;
    padding: 0px;
  }
}

.passwordInput {
  :deep(.el-input__wrapper) {
    box-shadow: 0px 0px 0px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    height: 42px;
    font-size: 18px;
    padding: 0px 20px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}

.cancenBtn {
  height: 48px;
  border: 2px solid #edeef6;
  padding: 10px 20px;
  border-radius: 16px;
  color: #f02d63;
  font-size: 18px;
  font-weight: 400;
}

.saveBtn {
  height: 48px;
  border: 2px solid #3376ff;
  padding: 10px 20px;
  border-radius: 16px;
  color: #fefefe;
  font-size: 18px;
  font-weight: 400;
  background-color: #3376ff;
}

.btnDiv {
  text-align: right;
}

.baseTx {
  width: 28px;
  margin-right: 13px;
}
.boxTx {
  width: 31px;
  margin-right: 13px;
}
.baseTitle {
  color: #3d3d3d;
  font-weight: 400;
  font-size: 20px;
  margin-right: 19px;
}

.baseUserName {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  margin: 0px 15px 0px 6px;
}

.baseImgItem {
  width: 25px;
}

.powerDiv {
  width: calc(100% - 47px);
}
.bg {
  background-color: #f7f8ff;
  z-index: 10;
}
.goBackImg {
  width: 25px;
  margin-right: 3px;
}
.returnDiv {
  border: 1px solid #dbdfff;
  border-radius: 12px;
  height: 34px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #a3acec;
  margin-right: 24px;
  cursor: pointer;
  white-space: nowrap;
}
.titleSpImg {
  width: 23px;
  margin-right: 24px;
}
</style>
