<template>
  <!-- 验证 -->
  <div :class="props.type == 'set' ? 'capDialogClassSet' : 'capDialogClass'">
    <div class="capTitle">
      <div>{{ t("base.base238") }}</div>
      <img class="crossSvug" :src="icon.crossSvug" alt="" @click="closeCap" />
    </div>
    <template v-if="props.type == 'right'">
      <div class="labelItem">
        <span class="capLabel">{{ t("base.base240") }}</span>
        <span class="labelTip">{{ t("base.base241") }}</span>
      </div>
      <div>
        <el-select v-model="treeData" multiple :placeholder="t('base.base242')">
          <el-option
            v-for="item in props.chatList"
            :key="item.conversationId"
            :label="item.question"
            :value="item.conversationId"
          >
            <el-tooltip popper-class="toolTip" :content="item.question">
              <span>{{
                item.question.length > 35
                  ? item.question.slice(0, 35) + "..."
                  : item.question
              }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
      </div>
    </template>
    <div class="labelItem">
      <span class="capLabel">{{ t("base.base208") }}</span>
    </div>
    <div>
      <el-select v-model="peopleSel" multiple :placeholder="t('base.base209')">
        <el-option
          v-for="item in props.seleteUserInfo"
          :key="item.ownerId"
          :label="item.ownerName"
          :value="item.ownerId"
        >
          <span class="dropText">{{ item.ownerName }}</span>
        </el-option>
      </el-select>
    </div>
    <div class="labelItem">{{ t("base.base210") }}</div>
    <el-input
      :placeholder="t('base.base211')"
      show-word-limit
      class="catText"
      type="textarea"
      v-model="leftNotes"
    />
    <div class="capBtnDiv">
      <el-button class="capCancel" @click="closeCap">{{
        t("base.base45")
      }}</el-button>
      <el-button
        class="capSave"
        :loading="props.requestverifyLoading"
        @click="saveCap"
        >{{ t("base.base212") }}</el-button
      >
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const peopleSel = ref([]);
const leftNotes = ref("");
const treeData = ref([]);
const props = defineProps([
  "chatList",
  "seleteUserInfo",
  "requestverifyLoading",
  "type",
]);
const emit = defineEmits(["saveCap", "closeCap"]);
const closeCap = () => {
  emit("closeCap");
};
const saveCap = () => {
  emit("saveCap", treeData.value, peopleSel.value, leftNotes.value);
};
</script>

<style lang="scss" scoped>
.capDialogClass {
  position: absolute;
  right: 100px;
  bottom: -550px;
  width: 566px;
  border-radius: 16px;
  background-color: #f7f8ff;
  z-index: 40;
  padding: 17px;
  cursor: default;
}
.capDialogClassSet {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 566px;
  height: 370px;
  border-radius: 16px;
  background-color: #f7f8ff;
  z-index: 40;
  padding: 17px;
  cursor: default;
}

.capTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 30px;
}
.crossSvug {
  width: 24px;
  cursor: pointer;
}
.labelItem {
  font-size: 18px;
  font-weight: 500;
  color: #3d3d3d;
  margin-bottom: 12px;
  margin-top: 24px;
}
.capLabel {
  position: relative;
  margin-right: 20px;
}

.capLabel::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  right: -15px;
}
.labelTip {
  font-size: 16px;
  font-weight: 500;
  color: #8a8a8a;
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
.catText {
  margin-bottom: 24px;

  :deep(.el-textarea__inner) {
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    padding: 12px 20px;
    resize: none;
    outline: none;
    font-weight: 400;
    font-size: 18px;
  }
}
.capBtnDiv {
  text-align: right;
}
.capCancel {
  height: 48px;
  color: #f02d63;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 20px;
}

.capSave {
  background-color: #3376ff;
  border: 0px;
  border-radius: 16px;
  height: 48px;
  font-size: 20px;
  font-weight: 400;
  color: #fefefe;
  padding: 10px 20px;
}
</style>
