<template>
  <div v-loading="excelLoading">
    <div ref="excelRef" class="pos" v-if="Object.keys(excelDesc).length != 0">
      <div ref="topDivExcel" class="paddingBottom20">
        <!-- 知识库头 -->
        <div class="topBaseHeader">
          <div>
            <div class="flexCenter">
              <img
                :src="icon.excelModel"
                alt=""
                class="excelModel marginRight6"
              />
              <el-tooltip
                popper-class="toolTip"
                :content="excelDesc.knowledge_name"
              >
                <div class="excelName">{{ excelDesc.knowledge_name }}</div>
              </el-tooltip>
              <div class="excelName margin6">/</div>
              <el-tooltip popper-class="toolTip" :content="excelDesc.fileName">
                <div class="excelName">{{ excelDesc.fileName }}</div>
              </el-tooltip>
              <div class="excelTag margin108">
                {{ excelDesc.excelNum }} {{ t("gpt.index67") }}
              </div>
              <div class="excelTag">
                {{ excelDesc.tableNum }} {{ t("gpt.index68") }}
              </div>
            </div>
            <div class="flexCenter">
              <div class="upFileName">{{ t("base.base269") }}：</div>
              <div
                class="userNameBg marginRight6"
                :style="'background-color:' + excelDesc.color"
              >
                {{ excelDesc.upLoader.slice(0, 1) }}
              </div>
              <el-tooltip popper-class="toolTip" :content="excelDesc.upLoader">
                <div class="userName marginRight12">
                  {{ excelDesc.upLoader }}
                </div>
              </el-tooltip>
              <img
                :src="icon.baseTopTime"
                alt=""
                class="excelModel marginRight3"
              />
              <el-tooltip
                popper-class="toolTip"
                :content="getTime(excelDesc.updataTime)"
              >
                <div class="userName">{{ getTime(excelDesc.updataTime) }}</div>
              </el-tooltip>
              <el-tooltip
                popper-class="toolTip"
                :content="t('base.base290')"
                v-if="excelDesc.importantFlag == 1"
              >
                <div class="flexCenter">
                  <img :src="icon.fileYear" alt="" class="wid25 marGin12" />
                  <div class="topFontItem">
                    {{
                      excelDesc.importantExpireTime
                        ? getYear(excelDesc.importantExpireTime)
                        : t("base.base176")
                    }}
                  </div>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="flexCenter">
            <div
              class="flexCenter flexDescDiv marginRight12"
              @click="openFileDesc"
            >
              <img :src="icon.fileDesc" alt="" class="excelModel" />
              <div>{{ t("gpt.index69") }}</div>
            </div>
            <template
              v-if="props.type == 0 && excelDesc.knowledge_role_id != 2"
            >
              <div
                class="swqwDiv"
                @click="openTime"
                v-if="
                  excelDesc.importantFlag == 0 ||
                  excelDesc.importantFlag == null
                "
              >
                {{ t("gpt.index70") }}
              </div>
              <div
                class="swqwDiv"
                @click="noTime"
                v-if="excelDesc.importantFlag == 1"
              >
                {{ t("base.base65") }}
              </div>
            </template>
            <img
              :src="icon.closeFileView"
              alt=""
              class="closeFileView"
              @click="closeExcel"
            />
          </div>
        </div>
        <!-- 查询结果 -->
        <!-- <div class="topBaseHeader" v-if="props.type == 'chat'">
        <div class="flexCenter">
          <img :src="icon.excelModel" alt="" class="excelModel marginRight6" />
          <div class="chatTitle">{{ t("gpt.index71") }}</div>
          <div class="excelTag margin108">
            {{ excelDesc.tableNum }} {{ t("gpt.index72") }}
          </div>
        </div>
        <img
          :src="icon.closeFileView"
          alt=""
          class="closeFileView"
          @click="closeExcel"
        />
      </div> -->
        <!-- Sheet头 -->
        <div class="sheetDiv">
          <img
            :src="icon.leftReson"
            alt=""
            class="leftReson"
            @click="changeLeftReson(resonOne)"
            v-if="excelDesc.sheetList.length >= 4"
          />
          <div ref="resonOne" class="flexCenter sheetHidden">
            <div
              v-for="item in excelDesc.sheetList"
              :key="item.id"
              class="flexCenter"
              @click="changeSheet(item)"
            >
              <div
                :class="sheetActive == item.id ? 'sheetItemActive' : ''"
                class="sheetItem"
              >
                <el-tooltip popper-class="toolTip" :content="item.name">
                  <div
                    :class="
                      sheetActive == item.id ? 'sheetNameActive' : 'sheetName'
                    "
                  >
                    {{ item.name }}
                  </div>
                </el-tooltip>
                <el-tooltip popper-class="toolTip" :content="t('gpt.index73')">
                  <img
                    :src="icon.fileDesc"
                    alt=""
                    class="excelModel"
                    @click.stop="openTableDesc(item)"
                  />
                </el-tooltip>
              </div>
              <div class="line"></div>
            </div>
          </div>
          <img
            :src="icon.rightReson"
            alt=""
            class="rightReson"
            @click="changeRightReson(resonOne)"
            v-if="excelDesc.sheetList.length >= 4"
          />
        </div>
      </div>
      <!-- 表格 -->
      <div
        class="tableClass"
        :style="'height:' + tableHeight + 'px'"
        v-loading="tableLoading"
      >
        <el-table
          :data="tableData"
          class="tableClass"
          ref="tableRef"
          :height="tableHeight"
          @scroll="scrollTable"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.prop"
            :prop="column.prop"
            :label="column.label"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
      </div>
      <descView
        v-if="descType"
        :type="fileType"
        :excelDesc="excelDesc"
        :fileDesc="fileDesc"
        @closeDesc="closeDesc"
      ></descView>
      <!-- 时间展示 -->
      <timeView
        v-if="timeDialog"
        @closeTime="closeTime"
        @saveTime="saveTime"
        :loading="timeLoading"
      ></timeView>
    </div>
    <div
      v-if="Object.keys(excelDesc).length == 0 && !excelLoading"
      class="noData"
    >
      <img
        :src="icon.closeFileView"
        alt=""
        class="closePos"
        @click="closeExcel"
      />
      <img :src="icon.noFileImg" alt="" class="noFileImg" />
    </div>
  </div>
</template>

<script setup>
import timeView from "@/views/base/time.vue";
import { icon } from "@/utils/icon";
import { onMounted, ref, nextTick, watch } from "vue";
import descView from "./desc.vue";
import { useI18n } from "vue-i18n";
import api from "@/api";
const { t } = useI18n();
const props = defineProps(["type", "fileItem"]);
const emit = defineEmits(["closeExcel", "getFileList"]);
import { useStore } from "vuex";
import { getTime, getYear } from "@/utils/utils";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import { throttle } from "@/utils/utils";
const route = useRoute();
const timeDialog = ref(false);
const routeMetaType = ref("");
const store = useStore();
const userId = ref(store.state.userId); //userId
const sheetActive = ref(1);
const resonOne = ref(null);
const topDivExcel = ref(null);
const excelRef = ref(null);
// 动态生成表格数据
const tableData = ref([]);
// 动态生成表头
const tableColumns = ref([]);
const tableHeight = ref(300);
onMounted(() => {
  window.addEventListener("resize", () => {
    getDivHeight();
  });
  routeMetaType.value = route.meta.type;
  getDivHeight();
  changeItem();
  getExcelDesc();
});
const topicItem = ref(store.state.topicItem);
const baseItem = ref(store.state.baseItem);
const yzItem = ref(store.state.yzItem);
const botItem = ref(store.state.botItem);
const fileObj = ref({});
watch(
  () => props.fileItem,
  (newValue, oldValue) => {
    excelDesc.value = {};
    pageId.value = 1;
    tableData.value = [];
    tableColumns.value = [];
    descType.value = false;
    changeItem();
    getExcelDesc();
  }
);
watch(
  () => store.state.baseItem,
  (newValue, oldValue) => {
    baseItem.value = store.state.baseItem;
    changeItem();
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = store.state.topicItem;
    changeItem();
  }
);
watch(
  () => store.state.yzItem,
  (newValue, oldValue) => {
    yzItem.value = store.state.topicItem;
    changeItem();
  }
);
watch(
  () => store.state.botItem,
  (newValue, oldValue) => {
    botItem.value = store.state.botItem;
    changeItem();
  }
);
watch(
  () => route.meta.type,
  (newValue, oldValue) => {
    routeMetaType.value = route.meta.type;
    changeItem();
  }
);
// 根据场景切换不同的item
const changeItem = () => {
  if (routeMetaType.value == "chat") {
    fileObj.value = topicItem.value;
  } else if (routeMetaType.value == "yanzheng") {
    fileObj.value = yzItem.value;
  } else if (routeMetaType.value == "base") {
    fileObj.value = baseItem.value;
  } else if (routeMetaType.value == "bot") {
    fileObj.value = botItem.value;
  }
};
const timeLoading = ref(false);
const openTime = () => {
  timeDialog.value = true;
};
// 取消权威
const noTime = () => {
  api.base
    .importantSet({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
      expireTime: null,
      libraryId:
        fileObj.value.knowledge_library_id ||
        fileObj.value.libraryId ||
        fileObj.value.knowledge.knowledge_library_id,
      operateType: "delete",
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base15"));
        getExcelDesc(excelDesc.value.sheetList[0]);
        emit("getFileList");
      }
    });
};
// 设为权威
const saveTime = (time) => {
  timeLoading.value = true;
  api.base
    .importantSet({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
      expireTime: time,
      libraryId:
        fileObj.value.knowledge_library_id ||
        fileObj.value.libraryId ||
        fileObj.value.knowledge.knowledge_library_id,
      operateType: "add",
    })
    .then((res) => {
      timeLoading.value = false;
      if (res.returnCode == 200) {
        timeDialog.value = false;
        message.success(t("base.base105"));
        getExcelDesc(excelDesc.value.sheetList[0]);
        emit("getFileList");
      }
    })
    .catch((err) => {
      timeLoading.value = false;
    });
};
const closeTime = () => {
  timeDialog.value = false;
};
// 获取excelDesc
const excelDesc = ref({});
const excelLoading = ref(true);
const getExcelDesc = () => {
  excelLoading.value = true;
  api.gpt
    .excelDesc({
      userId: userId.value,
      id: props.fileItem.fileId || props.fileItem.id,
    })
    .then((res) => {
      excelLoading.value = false;
      if (res.returnCode == 200) {
        excelDesc.value = res.data;
        pageId.value = 1;
        tableData.value = [];
        tableColumns.value = [];
        descType.value = false;
        changeSheet(res.data.sheetList[0]);
      }
    })
    .catch((err) => {
      excelDesc.value = {};
      excelLoading.value = false;
    });
};
// 关闭当前页面
const closeExcel = () => {
  emit("closeExcel");
};
// 打开文件描述
const fileType = ref("");
const descType = ref(false);
const openFileDesc = () => {
  fileDesc.value = excelDesc.value.fileDesc;
  fileType.value = "file";
  descType.value = true;
};
// 打开表格描述
const fileDesc = ref(null);
const openTableDesc = (item) => {
  fileDesc.value = item.desc;
  fileType.value = "table";
  descType.value = true;
};
// 关闭描述
const closeDesc = () => {
  descType.value = false;
};
// 获取高度
const getDivHeight = () => {
  nextTick(() => {
    if (topDivExcel.value && excelRef.value) {
      tableHeight.value =
        excelRef.value.offsetHeight - topDivExcel.value.offsetHeight - 10;
      console.log(tableHeight.value);
    }
  });
};
const changeSheet = (item) => {
  descType.value = false;
  sheetActive.value = item.id;
  pageId.value = 1;
  tableData.value = [];
  tableColumns.value = [];
  getDivHeight();
  getSheetTable();
};
const tableRef = ref(null);
// 表格scroll
const scrollTable = (e) => {
  if (!tableRef.value) return;
  //外面
  const scrollBody = tableRef.value.$el.querySelector(
    ".el-table__body-wrapper"
  );
  //里面真实
  const scrollDiv = tableRef.value.$el.querySelector(".el-scrollbar__view");
  if (e.scrollTop + scrollBody.clientHeight >= scrollDiv.clientHeight - 5) {
    loadList();
  }
};
// 下拉加载
const loadList = throttle(() => {
  pageId.value++;
  getSheetTable();
}, 300);
const pageId = ref(1);
const perPage = ref(20);
const tableLoading = ref(false);
const getSheetTable = () => {
  tableLoading.value = true;
  api.gpt
    .excelPreview({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
      id: sheetActive.value,
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      tableLoading.value = false;
      getDivHeight();
      if (res.returnCode == 200) {
        tableColumns.value = res.data.headers;
        if (pageId.value == 1) {
          tableData.value = res.data.data;
        } else {
          tableData.value = tableData.value.concat(res.data.data);
        }
      }
    })
    .catch((err) => {
      getDivHeight();
      tableLoading.value = false;
    });
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 5);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 5);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
</script>

<style lang="scss" scoped>
.pos {
  position: relative;
  height: 100%;
}
.paddingBottom20 {
  padding-bottom: 20px;
}
.tableClass {
  width: calc(100% - 40px);
  margin: 0px 20px;
}
:deep(.el-table th.el-table__cell) {
  background-color: rgba(246, 246, 246, 0.4);
}
.sheetHidden {
  width: 100%;
  overflow: hidden;
}
.line {
  width: 2px;
  background-color: #d9d9d9;
  height: 25px;
  margin: 0px 5px;
}
.sheetItem {
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 40px;
  padding: 0px 4px;
  width: 250px;
  cursor: pointer;
}
.sheetItemActive {
  background-color: #edeef6;
}
.sheetDiv {
  display: flex;
  background-color: #fefefe;
  height: 40px;
  border: 0.5px solid #edeef6;
  box-shadow: 1px 2px 8px 0px rgba(204, 206, 229, 0.75);
  margin: 20px;
  margin-bottom: 0px;
  border-radius: 12px;
  padding: 4px;
  align-items: center;
  overflow: hidden;
  position: relative;
  .leftReson {
    display: none;
  }
  .rightReson {
    display: none;
  }
}
.sheetDiv:hover {
  .leftReson {
    display: block;
    position: absolute;
    left: 10px;
    width: 24px;
    cursor: pointer;
  }
  .rightReson {
    display: block;
    position: absolute;
    right: 10px;
    width: 24px;
    cursor: pointer;
  }
}
.sheetName {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 400;
  width: calc(100% - 24px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sheetNameActive {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 600;
  width: calc(100% - 24px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chatTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.flexDescDiv {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  box-shadow: 1px 2px 8px 0px rgba(204, 206, 229, 0.25);
  padding: 9px 16px;
  cursor: pointer;
  color: #3d3d3d;
  font-size: 16px;
  font-weight: 400;
}
.swqwDiv {
  border: 1px solid #d9d9d9;
  box-shadow: 1px 2px 8px 0px rgba(204, 206, 229, 0.25);
  font-size: 16px;
  color: #3376ff;
  font-weight: 400;
  padding: 9px 16px;
  cursor: pointer;
  border-radius: 12px;
}
.closeFileView {
  width: 14px;
  cursor: pointer;
  margin-left: 23px;
}
.topBaseHeader {
  padding: 13px 26px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
}
.marginRight6 {
  margin-right: 6px;
}
.margin6 {
  margin: 0px 6px;
}
.margin108 {
  margin-left: 10px;
  margin-right: 8px;
}
.marginRight12 {
  margin-right: 12px;
}
.marginRight3 {
  margin-right: 3px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.excelModel {
  width: 24px;
}
.excelName {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 400;
  max-width: 200px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.excelTag {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 500;
  background-color: #edeef6;
  border-radius: 4px;
  padding: 2px 6px;
}
.upFileName {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 400;
}
.userNameBg {
  background-color: #0256ff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  color: #fefefe;
  font-weight: 500;
}
.userName {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 400;
}
:deep(.el-popper) {
  max-width: 300px !important;
  max-height: 300px !important;
  overflow: auto;
}
.wid25 {
  width: 18px;
}
.marGin12 {
  margin: 0px 6px 0px 12px;
}
.topFontItem {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 18px;
}
.noFileImg {
  width: 360px;
}
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.closePos {
  width: 14px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
