<template>
  <div class="content">
    <div class="topFlex">
      <div class="titile">{{ t("bot.bot109") }}</div>
      <!-- 发布 -->
      <publishView
        ref="publishViewRef"
        type="bot"
        :macType="macType"
        :debugtemplateId="debugtemplateId"
        @openDetail="openDetail"
        @changeModel="changeModel"
        @saveEdit="saveEdit"
        @sendForm="sendForm"
      ></publishView>
    </div>
    <div class="bottomDiv">
      <!-- 左侧 -->
      <div style="position: relative" v-if="!lookDialog">
        <div class="leftDiv">
          <!-- 设定和逻辑介绍 -->
          <div class="bigDiv">
            <div class="divFlex">
              <div class="divTitle">{{ t("bot.bot110") }}</div>
              <el-button
                class="wsBtn"
                @click="oneKeyPerfect"
                :loading="perLoading"
              >
                <div class="botWsDiv">
                  <img :src="icon.botWsSvg" class="botWsSvg" />
                </div>
                <span class="botWsText">{{ t("bot.bot111") }}</span>
              </el-button>
            </div>
            <el-input
              maxlength="2000"
              :placeholder="t('bot.bot32')"
              v-model="botItem.templateValue"
              show-word-limit
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 10 }"
            />
          </div>
          <!-- 高级 -->
          <div class="hightDiv" @click="highType = !highType">
            <div class="flexCenterHigh">
              <img :src="icon.highSvg" alt="" class="highSvg" />
              <span class="hightTitle">{{ t("bot.bot112") }}</span>
            </div>
            <img
              :src="icon.botRight"
              alt=""
              class="botRight"
              v-if="!highType"
            />
            <img :src="icon.botBottom" class="botRight" alt="" v-else />
          </div>
          <el-collapse-transition>
            <div v-if="highType">
              <!-- 联网搜索 -->
              <div class="bigDIvNoBorder">
                <div class="baseDiv">
                  <el-switch v-model="isInternetOn" />
                  <div class="divTitle left5">{{ t("base.base226") }}</div>
                </div>
              </div>
              <!-- 知识库 -->
              <div class="bigDIvNoBorder">
                <div class="baseDiv">
                  <el-switch v-model="isKnowledgeOn" />
                  <div class="divTitle left5">{{ t("bot.base159") }}</div>
                </div>
                <el-select
                  :placeholder="t('base.base268')"
                  class="inputSelete"
                  v-model="relativeKnowledgeId"
                  v-selectLoadMore="goBase"
                  popper-class="baseSelBotDetail"
                  :teleported="false"
                >
                  <div style="overflow: hidden">
                    <el-option
                      v-for="item in baseList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                      <el-tooltip popper-class="toolTip" :content="item.name">
                        <span class="dropText">{{ item.name }}</span>
                      </el-tooltip>
                    </el-option>
                  </div>
                </el-select>
              </div>
              <!-- 插件 -->
              <div class="bigDIvNoBorder">
                <div class="divFlex">
                  <div class="flexCenter">
                    <el-switch v-model="isAgentOn" />
                    <div class="divTitle left5">{{ t("bot.base160") }}</div>
                  </div>
                  <el-button class="addBtnDiv" @click="openTool">
                    <img :src="icon.plugAdd" class="plugAddImg" alt="" />
                    {{ $t("bot.bot47") }}
                  </el-button>
                </div>
                <div
                  class="plugDiv"
                  v-for="item in toolList"
                  :key="item.agentId"
                >
                  <div class="plugLeft">
                    <img :src="getToolImg(item)" class="plugSelImg" alt="" />
                    <div class="plugText">{{ item.agentName }}</div>
                  </div>
                  <div class="plugDeleteDiv" @click="deleteTool(item)">
                    <img class="plugDelete" :src="icon.plugDelete" alt="" />
                  </div>
                </div>
              </div>
              <!-- 开场白 -->
              <div class="bigDIvNoBorder">
                <div class="divFlex">
                  <div class="divTitle">{{ t("bot.bot44") }}</div>
                </div>
                <el-input
                  maxlength="2000"
                  :placeholder="t('bot.bot45')"
                  v-model="botItem.templateCall"
                  show-word-limit
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 5 }"
                />
              </div>
              <!-- 提问灵感 -->
              <div class="bigDIvNoBorder">
                <div class="divFlex">
                  <div class="divTitle">{{ t("bot.bot46") }}</div>
                  <el-button
                    class="wsBtn"
                    :disabled="botItem.suggest.length == 3"
                    @click="addLg"
                  >
                    <img :src="icon.addSvg" class="addSvg" />
                    <span class="botWsText">{{ t("bot.bot47") }}</span>
                  </el-button>
                </div>
                <div
                  v-for="(item, index) in botItem.suggest"
                  :key="item"
                  class="inputDelete"
                >
                  <el-input
                    class="lgInput"
                    maxlength="30"
                    show-word-limit
                    v-model="item.value"
                  />
                  <el-icon class="deleteIcon" @click="clearInput(index)">
                    <CircleCloseFilled />
                  </el-icon>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
        <div class="testDiv">
          <el-tooltip popper-class="toolTip" :content="t('bot.bot113')">
            <div class="testBtn" @click="templateTest(true)">
              {{ t("bot.bot114") }}
            </div>
          </el-tooltip>
        </div>
      </div>
      <!-- 右侧 -->
      <div :class="lookDialog ? 'rightDiv1' : 'rightDiv'">
        <div class="rightTopFlex">
          <div class="divTitle">{{ t("bot.bot115") }}</div>
        </div>
        <div class="bigQaP" ref="qaDivRef">
          <div
            class="qaDIv"
            :style="'height:' + qaHeight + 'px'"
            ref="containerRef"
          >
            <botQa
              @saveMessage="saveMessage"
              @releteTalk="releteTalk"
              @openFileView="openFileView"
              @openTalk="openTalk"
              @openResultDialog="openResultDialog"
              :ifThink="true"
              :botItem="rightInfo"
              :debugtemplateId="debugtemplateId"
              :chatList="chatList"
              :templateCall="templateCall"
            ></botQa>
          </div>
          <!-- input -->
          <div ref="inputDivRef" style="position: relative">
            <botInput
              @getDivHeight="getDivHeight"
              @sendMessage="sendMessage"
              @openFileView="openFileView"
              @clearTalk="clearTalk"
              @changeMacType="changeMacType"
              :isKnowledgeOn="inputIsKnowledgeOn"
              :knowledgeType="knowledgeType"
              :isInternetOn="inputIsInternetOn"
              :botItem="rightInfo"
              :debugtemplateId="debugtemplateId"
              :model="model"
              ref="botInputRef"
            ></botInput>
          </div>
        </div>
      </div>
      <!-- 文件预览 -->
      <fileView
        v-if="lookDialog && lookType == 'file'"
        type="chat"
        class="fileViewDiv"
        :fileItem="fileItem"
        :chunkDialog="true"
        :chunkList="chunkList"
        :isValed="false"
        :qaType="qaType"
        @closeFileView="closeFileView"
        @getFileList="getTalk"
      ></fileView>
      <!-- 对话预览 -->
      <topicView
        v-if="lookDialog && lookType == 'talk'"
        :sessionInfo="sessionInfo"
        :talkList="talkList"
        @closeTalk="closeTalk"
        @openTalk="openTalk"
        @talkOpenFile="talkOpenFile"
        @openResultDialog="openResultDialog"
      ></topicView>
      <!-- sql预览 -->
      <excelView
        v-if="lookDialog && lookType == 'sql'"
        class="fileViewDiv"
        :type="1"
        :fileItem="fileItem"
        @closeExcel="closeExcel"
        @getFileList="getTalk"
      ></excelView>
      <!-- sql查询结果 -->
      <tipView
        v-if="resultType"
        :title="t('gpt.index62')"
        :tip="resultMessage"
        :ok="t('bot.bot49')"
        :maskType="false"
        :markDown="false"
        @cancel="closeResultType"
        @ok="closeResultType"
      ></tipView>
    </div>
  </div>
  <!-- 详情 -->
  <detailBot
    v-if="detailType"
    :botInfo="botInfo"
    :botIsOwner="1"
    @closeDetail="closeDetail"
    @editBtn="editBtn"
  ></detailBot>
  <!-- 插件 -->
  <toolDialogView
    v-if="toolDialog"
    :toolList="toolList"
    @closeTool="closeTool"
    @deleteTool="deleteTool"
  ></toolDialogView>
</template>

<script setup>
import { icon } from "@/utils/icon";
import publishView from "@/components/publish/index.vue";
import excelView from "@/components/fileView/excelIndex.vue";
import botQa from "@/components/qa/botQa.vue";
import tipView from "@/components/tipDialog/index.vue";
import detailBot from "./components/detailBot.vue";
import botInput from "./components/input.vue";
import fileView from "@/components/fileView/index.vue";
import topicView from "@/components/chat/topicView.vue";
import api from "@/api";
import toolDialogView from "./components/toolDialog.vue";
import { message } from "ant-design-vue";
import { onMounted, ref, watch, nextTick, onUpdated } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { encryptApi, decryptApi } from "@/utils/crypto";
const { t } = useI18n();
const store = useStore();
const botItem = ref(JSON.parse(JSON.stringify(store.state.botItem)));
const highType = ref(true);
const detailType = ref(false);
const userId = ref(store.state.userId);
const model = ref(null);
const botInputRef = ref(null);
const qaDivRef = ref(null);
const qaHeight = ref(null);
const inputDivRef = ref(null);
const chatList = ref([]);
const debugtemplateId = ref(null);
const publishViewRef = ref(null);
const isAgentOn = ref(false);
const isInternetOn = ref(false);
const isKnowledgeOn = ref(false);
const relativeKnowledgeId = ref(null);
const knowledgeType = ref(null);
onMounted(() => {
  store.commit("SET_CHATTYPE", "chat");
  getBase();
  isAgentOn.value = botItem.value.isAgentOn == 1 ? true : false;
  isInternetOn.value = botItem.value.isInternetOn == 1 ? true : false;
  isKnowledgeOn.value = botItem.value.isKnowledgeOn == 1 ? true : false;
  toolList.value = botItem.value.AgentIds;
  if (botItem.value.suggest.length != 0) {
    botItem.value.suggest = botItem.value.suggest.map((item) => {
      return {
        value: item,
      };
    });
  }
  templateTest(false);
  window.addEventListener("resize", () => {
    getDivHeight();
  });
  setTimeout(() => {
    getDivHeight();
  }, 100);
});
onUpdated(() => {
  scrollBottom();
});
watch(
  () => store.state.botItem,
  (newValue, oldValue) => {
    getBase();
    botItem.value = JSON.parse(JSON.stringify(store.state.botItem));
    isAgentOn.value = botItem.value.isAgentOn == 1 ? true : false;
    isInternetOn.value = botItem.value.isInternetOn == 1 ? true : false;
    isKnowledgeOn.value = botItem.value.isKnowledgeOn == 1 ? true : false;
    toolList.value = botItem.value.AgentIds;
    if (botItem.value.suggest.length != 0) {
      botItem.value.suggest = botItem.value.suggest.map((item) => {
        return {
          value: item,
        };
      });
    }
  }
);
const resultMessage = ref("");
const resultType = ref(false);
const openResultDialog = (message) => {
  resultType.value = true;
  resultMessage.value = message;
};
// 关闭查询结果
const closeResultType = () => {
  resultType.value = false;
};
const llmConfigurationDict = ref({});
const getDefaulLlm = () => {
  if (!model.value) return;
  api.gpt
    .llmGet({
      userId: userId.value,
      modelType: model.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        llmConfigurationDict.value = res.data.accurate;
      }
    });
};
const sendForm = (form) => {
  llmConfigurationDict.value = form;
};
// 切换
const macType = ref(false);
const changeMacType = (type) => {
  macType.value = type;
};
const closeExcel = () => {
  lookDialog.value = false;
  lookType.value = "";
};
// 插件
const toolList = ref([]);
const toolDialog = ref(false);
// 删除插件
const deleteTool = (item) => {
  toolList.value = toolList.value.filter(
    (items) => items.agentId !== item.agentId
  );
};
// 获取插件头像
const getToolImg = (item) => {
  if (item.image_path) {
    return "/openai/chat/v4/image/get/" + item.image_path;
  } else {
    return icon.plugUpload;
  }
};
// 打开插件
const openTool = () => {
  toolDialog.value = true;
};
const closeTool = () => {
  toolDialog.value = false;
};
const basePageId = ref(1);
const basePerPage = ref(10000);
const baseList = ref([]);
// 获取知识库列表
const goBase = () => {
  basePageId.value++;
  getBase();
};

const getBase = () => {
  api.base
    .customLibraryList({
      userId: userId.value,
      namePattern: "",
      pageId: basePageId.value,
      perPage: basePerPage.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (basePageId.value == 1) {
          baseList.value = res.data;
        } else {
          baseList.value = baseList.value.concat(res.data);
        }
        let result = baseList.value.findIndex(
          (item) => item.id == botItem.value.relativeKnowledgeId
        );
        if (result == -1) {
          relativeKnowledgeId.value = null;
        } else {
          relativeKnowledgeId.value = botItem.value.relativeKnowledgeId;
          baseList.value.forEach((item) => {
            if (item.id == relativeKnowledgeId.value) {
              knowledgeType.value = item.knowledge_type;
            }
          });
        }
      }
    });
};
// 滚动向下
const containerRef = ref(null);
const scrollBottom = () => {
  nextTick(() => {
    if (containerRef.value) {
      containerRef.value.scrollTop = containerRef.value.scrollHeight;
    }
  });
};
// 清楚上下文
const clearTalk = () => {
  if (!debugtemplateId.value) {
    return message.warning(t("bot.bot113"));
  }
  api.chat
    .chatClear({
      userId: userId.value,
      topicId: debugtemplateId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("login.login50"));
      }
    });
};
// 提交
const saveEdit = () => {
  let obj = JSON.parse(JSON.stringify(botItem.value));
  obj.isAgentOn = isAgentOn.value ? 1 : 0;
  obj.isInternetOn = isInternetOn.value ? 1 : 0;
  obj.isKnowledgeOn = isKnowledgeOn.value ? 1 : 0;
  obj.relativeKnowledgeId = relativeKnowledgeId.value;
  obj.AgentIds = toolList.value.map((item) => item.agentId);
  api.bot.templateEdit(obj).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("bot.bot116"));
      api.bot
        .templateDetail({
          userId: userId.value,
          templateId: botItem.value.templateId,
        })
        .then((ress) => {
          if (ress.returnCode == 200) {
            ress.data.templateId = ress.data.id;
            ress.data.suggest = ress.data.suggestQuestion;
            store.commit("SET_BOT", ress.data);
          }
        });
    }
  });
};
// 打开话题
const talkList = ref([]);
const sessionInfo = ref({});
const openTalk = (item) => {
  if (item.readable == 0) {
    message.warning(t("base.base321"));
    return;
  }
  api.share
    .baseDetailQuery({
      userId: userId.value,
      sessionId: item.sessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length != 0) {
          res.data.conversationInfo.forEach((items) => {
            items.chatActive = 1;
            items.resonType = false;
            items.resonList = [];
            items.tipDialog = false;
            items.editType = false;
            items.loading = false;
          });
        }
        talkList.value = res.data.conversationInfo;
        sessionInfo.value = res.data.sessionInfo;
        lookDialog.value = true;
        lookType.value = "talk";
      }
    });
};
// 打开文件
const qaType = ref(false);
const fileItem = ref({});
const chunkList = ref([]);
const lookType = ref("file");
const lookDialog = ref(false);
// qa打开文件预览
const talkOpenFile = (item, list) => {
  qaType.value = true;
  if (item.fileType == 2) {
    openTalk({ sessionId: item.id });
    return;
  }
  fileItem.value = item;
  chunkList.value = list;
  if (setRef.value) {
    for (let i = 0; i < setRef.value.length; i++) {
      setRef.value[i].setCloseCap();
    }
  }
  if (item.type == 3 || item.knowledge_type == 3) {
    lookType.value = "sql";
  } else {
    lookType.value = "file";
  }
  lookDialog.value = true;
};
const openFileView = (item, list) => {
  qaType.value = false;
  if (item.fileType == 2) {
    openTalk({ sessionId: item.id });
    return;
  }
  fileItem.value = item;
  chunkList.value = list;
  if (item.type == 3 || item.knowledge_type == 3) {
    lookType.value = "sql";
  } else {
    lookType.value = "file";
  }
  lookDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
// 重新生成
const releteTalk = (item) => {
  if (getLoading()) {
    message.warning(t("base.base310"));
    return;
  }
  if (!debugtemplateId.value) {
    return message.warning(t("bot.bot113"));
  }
  item.resonType = false;
  item.answers.push({
    answerContent: "",
    answerId: "",
    strategy: item.strategy,
    thinkContent: "", //思考文本
    thinkTime: null, //思考时间
    model: model.value,
    pluginInfo: [],
  });
  item.chatActive = item.answers.length;
  let time = Math.round(new Date() / 1000);
  let obj = {
    userId: userId.value,
    model: model.value,
    topicId: debugtemplateId.value,
    conversationId: item.conversationId,
    strategy: item.strategy,
    indebug: true,
    llmConfigurationDict: llmConfigurationDict.value,
  };
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (obj.model == "GPT-o1-preview") {
    item.loading = true;
    api.chat.chatRegenerate(obj).then((res) => {
      item.loading = false;
      if (res.returnCode == 200) {
        item.answers[item.answers.length - 1].answerContent = res.data.message;
        scrollBottom();
      } else if (res.returnCode == 423 || res.returnCode == 412) {
        router.push("/");
      } else {
        item.answers[item.answers.length - 1].answerContent = res.returnMessage;
        scrollBottom();
      }
    });
  } else {
    regenerateFn(postObj, lang, "/openai/chat/v4/normal/regenerate", item);
  }
};
// 重新生成chat
const regenerateFn = async (postObj, lang, url, item) => {
  scrollBottom();
  try {
    item.loading = true;
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      message.warning(t("base.base182"));
      item.answers[item.answers.length - 1].answerContent = t("base.base182");
      item.loading = false;
      return;
    }
    if (response.status === 504) {
      message.warning(t("base.base183"));
      item.answers[item.answers.length - 1].answerContent = t("base.base183");
      item.loading = false;
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    let think = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      // 解析插件
      const plugRegex = /<swagger>\s*<start>(.*?)<end>\s*<\/swagger>/g;
      let plugMatch;
      while ((plugMatch = plugRegex.exec(rawData)) !== null) {
        item.answers[item.answers.length - 1].pluginInfo.push(
          JSON.parse(decryptApi(plugMatch[1]))
        );
      }
      // 解析 <think> 标签内的内容
      const thinkRegex = /<think>\s*<start>(.*?)<end>\s*<\/think>/g;
      let thinkMatch;
      let thinkArr = [];
      while ((thinkMatch = thinkRegex.exec(rawData)) !== null) {
        thinkArr.push(thinkMatch[1]);
      }
      // 解析 <text> 标签内的内容
      const textRegex = /<text>\s*<start>(.*?)<end>\s*<\/text>/g;
      let textMatch;
      let textArr = [];
      while ((textMatch = textRegex.exec(rawData)) !== null) {
        textArr.push(textMatch[1]);
      }
      const thinkData = thinkArr.map((data) => decryptApi(data));
      const textData = textArr.map((data) => decryptApi(data));
      think += thinkData.map((item) => item).join("");
      data += textData.map((item) => item).join("");
      item.answers[item.answers.length - 1].thinkContent = think;
      item.answers[item.answers.length - 1].answerContent = data;
      scrollBottom();
      if (done) {
        item.loading = false;
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          // 结束
          console.log(data);
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          item.answers[item.answers.length - 1].answerContent =
            t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/");
          } else {
            item.chatActive = item.answers.length - 1;
            item.answers.splice(item.chatActive, 1);
          }
        }
        break;
      }
    }
  } catch (err) {
    item.loading = false;
  }
};
// 获取model
const changeModel = (e) => {
  model.value = e;
  getDefaulLlm();
};
// 判断是否有正在输出的内容
const getLoading = () => {
  return chatList.value.some((item) => item.loading === true);
};
// 发送消息
const sendMessage = (obj) => {
  if (!debugtemplateId.value) {
    return message.warning(t("bot.bot113"));
  }
  if (getLoading()) {
    message.warning(t("base.base310"));
    return;
  }
  if (!obj.message) {
    message.warning(t("share.input1"));
    return;
  }
  if (!obj.model) {
    message.warning(t("base.base249"));
    return;
  }
  if (obj.imageFlag == 1) {
    obj.pictureModelType = obj.model;
  }
  obj.knowledgeId = relativeKnowledgeId.value;
  obj.isKnowledgeOn = isKnowledgeOn.value;
  obj.llmConfigurationDict = llmConfigurationDict.value
  //加密时间
  let time = Math.round(new Date() / 1000);
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(
      JSON.stringify({
        ...obj,
        time: time,
      })
    ),
  };
  let chatObj = {
    question: obj.message,
    chatActive: 1,
    loading: false,
    answers: [
      {
        answerContent: "",
        strategy: obj.strategy,
        thinkContent: "", //思考文本
        thinkTime: null, //思考时间
        model: obj.model,
        pluginInfo: [],
      },
    ],
    model: obj.model,
  };
  chatList.value.push(chatObj);
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (model.value == "GPT-o1-preview") {
    const currentChatItem = chatList.value[chatList.value.length - 1];
    currentChatItem.loading = true;
    scrollBottom();
    api.chat.o1Chat(obj).then((res) => {
      currentChatItem.loading = false;
      if (res.returnCode == 200) {
        nextTick(() => {
          getTalk();
          scrollBottom();
        });
      } else if (res.returnCode == 423 || res.returnCode == 412) {
        router.push("/");
      } else {
        currentChatItem.answers[currentChatItem.chatActive - 1].answerContent =
          res.returnMessage;
        scrollBottom();
      }
    });
  } else {
    sendFn(postObj, lang, "/openai/chat/v4/memory/chat");
  }
};
const sendFn = async (postObj, lang, url) => {
  scrollBottom();
  try {
    const currentChatItem = chatList.value[chatList.value.length - 1];
    currentChatItem.loading = true;
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if ([500, 504].includes(response.status)) {
      const errorMap = {
        500: "base.base182",
        504: "base.base183",
      };
      message.warning(t(errorMap[response.status]));
      currentChatItem.answers[currentChatItem.chatActive - 1].answerContent = t(
        errorMap[response.status]
      );
      currentChatItem.loading = false;
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    let think = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      // 解析插件
      const plugRegex = /<swagger>\s*<start>(.*?)<end>\s*<\/swagger>/g;
      let plugMatch;
      while ((plugMatch = plugRegex.exec(rawData)) !== null) {
        currentChatItem.answers[currentChatItem.chatActive - 1].pluginInfo.push(
          JSON.parse(decryptApi(plugMatch[1]))
        );
      }
      // 解析 <think> 标签内的内容
      const thinkRegex = /<think>\s*<start>(.*?)<end>\s*<\/think>/g;
      let thinkMatch;
      let thinkArr = [];
      while ((thinkMatch = thinkRegex.exec(rawData)) !== null) {
        thinkArr.push(thinkMatch[1]);
      }
      // 解析 <text> 标签内的内容
      const textRegex = /<text>\s*<start>(.*?)<end>\s*<\/text>/g;
      let textMatch;
      let textArr = [];
      while ((textMatch = textRegex.exec(rawData)) !== null) {
        textArr.push(textMatch[1]);
      }
      const thinkData = thinkArr.map((data) => decryptApi(data));
      const textData = textArr.map((data) => decryptApi(data));
      think += thinkData.map((item) => item).join("");
      data += textData.map((item) => item).join("");
      currentChatItem.answers[currentChatItem.chatActive - 1].thinkContent =
        think;
      currentChatItem.answers[currentChatItem.chatActive - 1].answerContent =
        data;
      scrollBottom();
      if (done) {
        currentChatItem.loading = false;
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          getTalk();
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          currentChatItem.answers[
            currentChatItem.chatActive - 1
          ].answerContent = t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/");
          } else {
            currentChatItem.answers[
              currentChatItem.chatActive - 1
            ].answerContent = newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    const currentChatItem = chatList.value[chatList.value.length - 1];
    currentChatItem.loading = false;
  }
};
// 获取历史记录
const templateCall = ref(null);
const getTalk = () => {
  api.share
    .chatDetailQuery({
      userId: userId.value,
      topicId: debugtemplateId.value,
      indebug: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.chatActive = 1;
          item.resonType = false;
          item.resonList = [];
          item.tipDialog = false;
          item.editType = false;
          item.loading = false;
          if (item.answers.length != 0) {
            item.answers.forEach((i) => {
              i.langType = false;
              i.langText = "";
            });
          }
        });
        templateCall.value = res.templateCall;
        chatList.value = res.data;
        scrollBottom();
      }
    });
};
// 调试
const rightInfo = ref({});
const inputIsInternetOn = ref(false);
const inputIsKnowledgeOn = ref(false);
const templateTest = (type) => {
  let obj = JSON.parse(JSON.stringify(botItem.value));
  obj.isAgentOn = isAgentOn.value ? 1 : 0;
  obj.isInternetOn = isInternetOn.value ? 1 : 0;
  obj.isKnowledgeOn = isKnowledgeOn.value ? 1 : 0;
  obj.relativeKnowledgeId = relativeKnowledgeId.value;
  obj.AgentIds = toolList.value.map((item) => item.agentId);
  obj.suggest = obj.suggest.map((item) => item.value);
  obj.indebug = true;
  api.bot.templateAdd(obj).then((res) => {
    if (res.returnCode == 200) {
      api.chat
        .addChat({
          userId: userId.value,
          topicName: botItem.value.templateName.slice(0, 250),
          templateId: res.data.debugtemplateId,
          indebug: true,
        })
        .then((res) => {
          if (res.returnCode == 200) {
            baseList.value.forEach((item) => {
              if (item.id == relativeKnowledgeId.value) {
                knowledgeType.value = item.knowledge_type;
              }
            });
            debugtemplateId.value = res.data.table_id;
            inputIsInternetOn.value = JSON.parse(
              JSON.stringify(isInternetOn.value)
            );
            inputIsKnowledgeOn.value = JSON.parse(
              JSON.stringify(isKnowledgeOn.value)
            );
            rightInfo.value = obj;
            chatList.value = [];
            nextTick(() => {
              if (publishViewRef.value) {
                publishViewRef.value.getModel(
                  isKnowledgeOn.value
                    ? relativeKnowledgeId.value ||
                        botItem.value.relativeKnowledgeId
                    : null
                );
              }
            });
            if (botInputRef.value) {
              nextTick(() => {
                botInputRef.value.getChatFile();
              });
            }
            if (type) {
              message.success(t("base.base213"));
            }
            getDivHeight();
          }
        });
    }
  });
};
// 一键完善
const perLoading = ref(false);
const oneKeyPerfect = () => {
  perLoading.value = true;
  api.bot
    .templatePerfect({
      message: botItem.value.templateValue,
      templateName: botItem.value.templateName,
      templateValue: botItem.value.templateValue,
      userId: userId.value,
    })
    .then((res) => {
      perLoading.value = false;
      if (res.returnCode === 200) {
        botItem.value.templateCall = res.data.templateCall;
        botItem.value.templateDesc = res.data.templateDesc;
        botItem.value.templateValue = res.data.templateValue;
        highType.value = true;
        message.success(t("bot.bot18"));
      }
    })
    .catch((err) => {
      perLoading.value = false;
    });
};
// 计算高度
const getDivHeight = () => {
  nextTick(() => {
    if (qaDivRef.value && inputDivRef.value) {
      qaHeight.value =
        qaDivRef.value.offsetHeight - inputDivRef.value.offsetHeight;
    }
  });
};
// 新增灵感
const addLg = () => {
  botItem.value.suggest.push({
    value: "",
  });
};
// 点击灵感
const saveMessage = (message) => {
  botInputRef.value.saveMessage(message);
};
// 删除开场白
const clearInput = (e) => {
  botItem.value.suggest.splice(e, 1);
};
// 打开详情
const botInfo = ref({});
const openDetail = () => {
  api.bot
    .templateDetail({
      userId: userId.value,
      templateId: botItem.value.templateId || botItem.value.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.templateId = res.data.id;
        res.data.suggest = res.data.suggestQuestion;
        botInfo.value = res.data;
        detailType.value = true;
      }
    });
};
// 关闭详情
const closeDetail = () => {
  detailType.value = false;
};
// 保存编辑
const editBtn = (form) => {
  api.bot.templateEdit(form).then((res) => {
    if (res.returnCode == 200) {
      detailType.value = false;
      message.success(t("bot.bot5"));
      api.bot
        .templateDetail({
          userId: userId.value,
          templateId: form.templateId,
        })
        .then((ress) => {
          if (ress.returnCode == 200) {
            ress.data.templateId = ress.data.id;
            ress.data.suggest = ress.data.suggestQuestion;
            store.commit("SET_BOT", ress.data);
          }
        });
    }
  });
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  border-radius: 30px;
  padding: 30px;
}
.titile {
  font-size: 24px;
  color: #3d3d3d;
  font-weight: 600;
}
.topFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  margin-bottom: 20px;
}
.divTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.botWsText {
  color: #0256ff;
  font-weight: 500;
  font-size: 18px;
}
.botWsSvg {
  width: 21px;
}
.botWsDiv {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: #dbdfff;
  margin-right: 8px;
  display: flex;
  align-items: center;
}
.wsBtn {
  background-color: #eceeff;
  border-radius: 12px;
  border: 0px;
  padding: 6px;
}
.divFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
}
.baseDiv {
  display: flex;
  align-items: center;
  padding: 12px 20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 0px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-weight: 400;
  font-size: 18px;
}
.bigDiv {
  background-color: #f7f8ff;
  border-radius: 20px;
  border: 2px solid #a3acec;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  margin-bottom: 20px;
}
.bigDIvNoBorder {
  background-color: #f7f8ff;
  border-radius: 20px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  margin-bottom: 20px;
}
.bottomDiv {
  height: calc(100% - 75px);
  overflow: hidden;
  display: flex;
  position: relative;
}
.leftDiv {
  width: 720px;
  margin-right: 20px;
  height: calc(100% - 60px);
  overflow: auto;
  position: relative;
}
.rightDiv {
  width: calc(100% - 740px);
  position: sticky;
  right: 0;
  top: 0;
  border: 2px solid #edeef6;
  border-radius: 20px;
  background-color: #f7f8ff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  padding: 20px;
}
.rightDiv1 {
  width: 527px;
  border: 2px solid #edeef6;
  border-radius: 20px;
  background-color: #f7f8ff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  padding: 20px;
  margin-right: 20px;
}
.highSvg {
  width: 20px;
  margin-right: 12px;
}
.botRight {
  width: 24px;
}
.hightDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8ff;
  height: 48px;
  border-radius: 18px;
  padding: 0px 15px;
  cursor: pointer;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  margin-bottom: 15px;
}
.flexCenterHigh {
  display: flex;
  align-items: center;
}
.hightTitle {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 500;
}
.addSvg {
  width: 16px;
  margin-right: 6px;
}
.lgInput {
  margin-right: 10px;
  :deep(.el-input__wrapper) {
    height: 48px;
    border-radius: 16px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 0px;
    font-size: 18px;
    font-weight: 400;
  }
}
.inputDelete {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.deleteIcon {
  font-size: 18px;
  color: #f02d63;
  cursor: pointer;
}
.rightTopFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 20px;
}
.bigQaP {
  height: calc(100% - 50px);
  overflow: hidden;
}
.qaDIv {
  overflow: auto;
}
.testBtn {
  border: 1px solid #edeef6;
  box-shadow: 0px 0px 16px 0px #a3acec;
  border-radius: 16px;
  padding: 12px 20px;
  font-size: 18px;
  color: #3376ff;
  font-weight: 400;
  background-color: #fefefe;
  cursor: pointer;
  margin-right: 20px;
}
.testDiv {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fileViewDiv {
  width: calc(100% - 551px);
  height: calc(100% - 4px);
  border-radius: 30px;
  overflow: hidden;
  border: 2px solid #edeef6;
}
:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  border-radius: 7px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 7px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #fefefe;
  left: 2px;
  border-radius: 4px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
  border-radius: 4px;
}
.addBtnDiv {
  border: 1px solid #edeef6;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #edeef6;
  padding: 5px 9px;
  font-size: 16px;
  font-weight: 500;
  color: #696969;
}
.plugAddImg {
  width: 12px;
  margin-right: 6px;
}
.left5 {
  margin-left: 5px;
}
.inputSelete {
  width: 100%;
  padding: 0px 20px;
  padding-bottom: 16px;
  :deep(.el-select__wrapper) {
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 0px 0px #ffffff;
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 500;
    height: 48px;
    background-color: transparent;
    background-color: #fefefe;
  }
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
.plugDiv {
  background-color: #fefefe;
  border-radius: 12px;
  border: 1px solid #edeef6;
  padding: 9px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.plugDelete {
  width: 18px;
  cursor: pointer;
}
.plugDeleteDiv {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plugDeleteDiv:hover {
  background-color: #f7f8ff;
  border-radius: 8px;
}
.plugSelImg {
  width: 23px;
  margin-right: 6px;
}
.plugLeft {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  width: calc(100% - 28px);
}
.plugText {
  width: calc(100% - 29px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
