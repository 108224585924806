<template>
  <div class="rightDivAbs">
    <div class="flexRightCenter">
      <!-- 归档 chat -->
      <el-button
        class="sc"
        v-if="props.chatType == 'chat'"
        :loading="props.deleteLoading"
        @click="deleteSession(1)"
      >
        <img :src="icon.rightGd" alt="" class="marginRight8 rightGd" />
        <div class="gdText">{{ t("base.base236") }}</div>
      </el-button>
      <!-- 收藏 -->
      <el-button
        class="sc"
        v-if="topicItem.isCollected == 0 || topicItem.isCollected == null"
        @click="likeFn(0)"
      >
        <img :src="icon.scSvg" alt="" class="marginRight8 wid14" />
        <div>{{ t("base.base100") }}</div>
      </el-button>
      <!-- 取消收藏-->
      <el-button
        class="color3 sc"
        v-if="topicItem.isCollected == 1"
        @click="likeFn(1)"
      >
        <img :src="icon.scNoSvg" alt="" class="marginRight8 wid14" />
        <div>{{ t("base.base198") }}</div>
      </el-button>
      <!-- 请求验证话题 base -->
      <el-button
        class="color2 sc"
        @click="openCap"
        v-if="props.chatType == 'base'"
        :disabled="getLoading()"
      >
        <img :src="icon.setCap" alt="" class="marginRight8 wid21" />
        <div>{{ t("base.base238") }}</div>
      </el-button>
      <!-- 撤回验证请求 -->
      <!-- <div class="color3 sc" @click="openCap" v-if="props.chatType == 'base'">
              <img :src="icon.setNoCap" alt="" class="marginRight8 wid21" />
              <div>{{ t('base.base239') }}</div>
            </div> -->
      <!-- 知识库 收藏 清除 -->
      <el-dropdown
        @command="deleteSession"
        v-if="props.chatType == 'base' && props.routeType != 'look'"
      >
        <div class="iconRightCenter">
          <img :src="icon.moreSvg" alt="" class="wid18" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <!-- 归档 -->
            <el-dropdown-item :command="1">
              <div class="dropdownItem">
                <img :src="icon.rightGd" alt="" class="marginRight8 rightGd" />
                <div class="gdText">{{ t("base.base236") }}</div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="iconRightCenter" @click="closeRight">
        <img :src="icon.sqRightSvg" alt="" class="wid20" />
      </div>
      <!-- 弹窗验证 -->
      <verifyView
        v-if="props.capDialog"
        type="right"
        :chatList="props.chatList"
        :seleteUserInfo="props.seleteUserInfo"
        :requestverifyLoading="props.requestverifyLoading"
        @closeCap="closeCap"
        @saveCap="saveCap"
      ></verifyView>
    </div>
    <!-- 导航 -->
    <div class="hrefDiv" ref="aRef" @scroll="loadList">
      <el-anchor
        :container="props.containerRef"
        direction="vertical"
        type="underline"
        :offset="50"
        @click="handleClick"
      >
        <el-anchor-link
          :href="'#talk' + item.conversationId"
          :title="item.question"
          v-for="(item, index) in props.chatList"
          :key="index"
        />
      </el-anchor>
    </div>
  </div>
</template>

<script setup>
import verifyView from "./verify.vue";
import { ref, onUpdated, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const props = defineProps([
  "chatType",
  "containerRef",
  "requestverifyLoading",
  "capDialog",
  "chatList",
  "deleteLoading",
  "topicItem",
  "seleteUserInfo",
  "pageId",
  "routeType",
]);
const emit = defineEmits([
  "handleClick",
  "deleteSession",
  "likeFn",
  "openCap",
  "closeRight",
  "closeCap",
  "saveCap",
  "loadList",
]);
const loadList = (e) => {
  if (scrollType.value) {
    scrollType.value = false;
    return;
  }
  const scrollTop = e.target.scrollTop;
  if (scrollTop == 0) {
    emit("loadList");
  }
};
const scrollType = ref(true);
onMounted(() => {
  scrollType.value = true;
  scrollBottom();
});
onUpdated(() => {
  scrollBottom();
});
const aRef = ref(null);
const scrollBottom = () => {
  if (props.pageId != 1) return;
  nextTick(() => {
    if (aRef.value.scrollTop) {
      aRef.value.scrollTop = aRef.value.scrollHeight;
    }
  });
};
const handleClick = (e) => {
  emit("handleClick", e);
};
const deleteSession = (e) => {
  emit("deleteSession", e);
};
const likeFn = (e) => {
  emit("likeFn", e);
};
const openCap = () => {
  emit("openCap");
};
const closeRight = () => {
  emit("closeRight");
};
const closeCap = () => {
  emit("closeCap");
};
const saveCap = (treeData, peopleSel, leftNotes) => {
  emit("saveCap", treeData, peopleSel, leftNotes);
};
const getLoading = () => {
  return props.chatList.some((item) => item.loading === true);
};
</script>

<style lang="scss" scoped>
.rightDivAbs {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 401px;
  height: calc(100% - 60px);
}
.flexRightCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
}
.marginRight8 {
  margin-right: 8px;
}
.rightGd {
  width: 20px;
}
.gdText {
  color: #f02d63;
}
.wid14 {
  width: 14px;
}
.color3 {
  color: #f02d63;
}
.sc {
  border: 2px solid #edeef6;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  margin-right: 12px;
  cursor: pointer;
  height: 48px;
  margin-left: 0px !important;
}
.color2 {
  background-color: #3376ff;
  color: #ffffff;
  border: 2px solid #3376ff;
  position: relative;
}
.wid21 {
  width: 21px;
}
.iconRightCenter {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconRightCenter:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.wid18 {
  width: 18px;
}
.dropdownItem {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wid20 {
  width: 20px;
}
.hrefDiv {
  padding: 0px 18px;
  max-height: calc(100% - 160px);
  overflow: auto;
}
:deep(.el-anchor__link) {
  font-weight: 400;
  font-size: 18px !important;
  margin-bottom: 10px;
}
:deep(.el-anchor .el-anchor__list .el-anchor__item a) {
  font-weight: 400;
  font-size: 18px !important;
  margin-bottom: 10px;
}
</style>
