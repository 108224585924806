import menu from './menu/index'
import header from './header/index'
import share from './share/index'
import bot from './bot/index'
import statistics from './statistics/index'
import manage from './manage/index'
import login from './login/index'
import log from './log/index'
import base from './base/index'
import publish from './publish/index'
import gpt from './gpt/index'
export default {
    CN: {
        menu: menu.CN,
        header: header.CN,
        share: share.CN,
        bot: bot.CN,
        statistics: statistics.CN,
        login: login.CN,
        manage: manage.CN,
        log: log.CN,
        base: base.CN,
        publish: publish.CN,
        gpt: gpt.CN
    },
    HK: {
        menu: menu.HK,
        header: header.HK,
        share: share.HK,
        bot: bot.HK,
        statistics: statistics.HK,
        login: login.HK,
        manage: manage.HK,
        log: log.HK,
        base: base.HK,
        publish: publish.HK,
        gpt: gpt.HK
    },
    US: {
        menu: menu.US,
        header: header.US,
        share: share.US,
        bot: bot.US,
        statistics: statistics.US,
        login: login.US,
        manage: manage.US,
        log: log.US,
        base: base.US,
        publish: publish.US,
        gpt: gpt.US
    },
};
