<template>
  <div class="upFileDialog">
    <div class="upFileTitleDiv dialogBetWeen">
      <div class="upFileTitle">
        {{ props.upTypeActive == 1 ? t("base.base57") : t("base.base58") }}
      </div>
      <img
        :src="icon.closeFileView"
        alt=""
        class="closeFileViewImg"
        @click="closeUpDialog"
      />
    </div>
    <div class="upFileBody">
      <div class="upFileTip" v-if="props.upFileList.length != 0">
        {{ props.upTypeActive == 1 ? t("base.base77") : t("base.base78") }}
      </div>
      <div class="upFileItemDiv" v-if="props.upFloderName">
        <div class="upFileLeftDiv">
          <img
            :src="icon.zkr"
            alt=""
            v-if="collType"
            class="zk"
            @click="collType = !collType"
          />
          <img
            :src="icon.zkb"
            alt=""
            v-if="!collType"
            class="zk"
            @click="collType = !collType"
          />
          <img :src="icon.fileFolder" alt="" class="fileIcon" />
          <div class="upListItemTitle">{{ props.upFloderName }}</div>
        </div>
        <div class="upFileItemTip">{{ t("base.base79") }}</div>
      </div>
      <el-collapse-transition>
        <div v-show="(props.upFloderName && !collType) || !props.upFloderName">
          <div
            v-for="(item, index) in props.upFileList.slice(0, 10)"
            :key="index"
          >
            <div class="upFileItemDiv">
              <div class="upFileLeftDiv">
                <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                <div class="upListItemTitle">{{ item.name }}</div>
              </div>
              <div class="upFileItemTip">{{ t("base.base79") }}</div>
            </div>
          </div>
          <div v-if="isExpanded">
            <div
              v-for="(item, index) in props.upFileList.slice(10)"
              :key="index"
            >
              <div class="upFileItemDiv">
                <div class="upFileLeftDiv">
                  <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                  <div class="upListItemTitle">{{ item.name }}</div>
                </div>
                <div class="upFileItemTip">{{ t("base.base79") }}</div>
              </div>
            </div>
          </div>
          <div
            class="zkText"
            @click="isExpanded = !isExpanded"
            v-if="props.upFileList.length > 10"
          >
            {{ isExpanded ? t("base.base80") : t("base.base81") }}
          </div>
        </div>
      </el-collapse-transition>
      <div class="upFileTip" v-if="props.errorFileList.length != 0">
        {{ t("base.base82") }}
      </div>
      <div>
        <div v-for="(item, index) in props.errorFileList" :key="index">
          <div class="upFileItemDiv">
            <div class="upFileLeftDiv">
              <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
              <div class="upListItemTitle">{{ item.name }}</div>
            </div>
            <div class="upFileItemTip">{{ item.type }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="dialogEnd">
      <el-button class="dialogCancelBtn" @click="closeUpDialog">{{
        t("base.base45")
      }}</el-button>
      <el-button
        class="dialogSaveBtn"
        v-if="props.upFileList.length != 0"
        @click="saveDialog"
        >{{ t("base.base56") }}</el-button
      >
    </div>
  </div>
  <div class="maskFile"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { getBaseFileIcon } from "@/utils/file";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps([
  "upTypeActive",
  "upFileList",
  "upFloderName",
  "errorFileList",
]);
const emit = defineEmits(["closeUpDialog", "saveDialog"]);
const collType = ref(false);
const isExpanded = ref(false); //默认不展开
const closeUpDialog = () => {
  emit("closeUpDialog");
};
const saveDialog = () => {
  emit("saveDialog");
};
</script>

<style lang="scss" scoped>
.upFileDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 30px;
  width: 600px;
  max-height: 782px;
  background-color: #fefefe;
  border-radius: 30px;
}
.upFileTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.upFileTitleDiv {
  height: 36px;
  padding-bottom: 17px;
  border-bottom: 2px solid #edeef6;
  margin-bottom: 17px;
}
.upFileTip {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 18px;
}
.upListItemTitle {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 43px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileItemTip {
  color: #3376ff;
  font-size: 18px;
  font-weight: 400;
  text-align: right;
  width: 40%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileItemDiv {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
.upFileLeftDiv {
  width: 60%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upFileBody {
  max-height: 500px;
  overflow: auto;
}
.zk {
  width: 10px;
  margin-right: 5px;
  cursor: pointer;
  transition: 0.5s ease;
}
.zkText {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0px;
  text-align: center;
  cursor: pointer;
}
.dialogBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.closeFileViewImg {
  width: 14px;
  cursor: pointer;
}
.dialogCancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #f02d63;
  font-weight: 400;
  font-size: 18px;
}
.dialogSaveBtn {
  height: 48px;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #fefefe;
  font-weight: 400;
  font-size: 18px;
}
.dialogEnd {
  display: flex;
  justify-content: flex-end;
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
.maskFile {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 200;
  opacity: 0.3;
}
</style>
