<template>
  <div class="bottomDiv">
    <!-- 左侧 -->
    <div class="bottomDivItem marginRight">
      <div class="flexCenterBetween">
        <div class="flexCenter">
          <div
            v-for="(item, index) in navList"
            :key="item"
            :class="{
              navItem: navActive != index,
              navItemActive: navActive == index,
            }"
            @click="changeNav(index)"
          >
            {{ t(item) }}
          </div>
        </div>
        <div class="flexCenter" @click="goShare">
          <span class="leftTip">{{ t("menu.index3") }}</span>
          <img :src="icon.eImg" alt="" class="eImg" />
        </div>
      </div>
      <div
        class="itemBottom"
        v-if="topicList.length != 0"
        v-loading="topicLoading"
      >
        <div
          v-for="item in topicList"
          :key="item"
          class="itemForDiv"
          @click="goShareView(item)"
        >
          <div class="flexCenter">
            <img :src="icon.dImg" alt="" class="dImg" />
            <span class="itemTitle">{{ item.sessionName }}</span>
          </div>
          <div class="itemTip">
            {{ item.message || item.firstAnswer }}
          </div>
          <div class="flexCenter padding35">
            <div class="flexCenter" v-if="item.updateTime">
              <img :src="icon.timePng" alt="" class="bottomIconRihgt" />
              <span class="bottomFont">{{ getYear(item.updateTime) }}</span>
            </div>
            <div class="flexCenter" v-if="item.isValid == 1">
              <img :src="icon.yesPng" alt="" class="bottomIconRihgt" />
              <span class="bottomFont">{{
                item.expireDate != null
                  ? getYear(item.expireDate)
                  : t("menu.index4")
              }}</span>
            </div>
            <div
              class="flexCenter"
              v-if="item.result != null && item.result != 0"
            >
              <img :src="icon.hotPng" alt="" class="bottomIconRihgt" />
              <span class="bottomFont">{{ getNumber(item.result) }}</span>
            </div>
            <div class="tipDiv">
              <img :src="icon.leftTip" alt="" class="wid20" />
              <span class="leftTipText">{{
                item.knowledgeName || item.knowledge.knowledgeName
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-button
        class="changeLeft"
        link
        :disabled="leftPageId == 1"
        @click="leftLast"
        v-if="topicList.length !=0"
      >
        <img :src="icon.kImg" alt="" class="bottomIconTip" />
      </el-button>
      <div
        class="itemBottom flex"
        v-if="topicList.length == 0 && !topicLoading"
      >
        <img :src="icon.noData" class="noData" alt="" />
      </div>
      <el-button
        class="changRight"
        link
        :disabled="leftPageId == leftTotal"
        @click="goLeft"
        v-if="topicList.length !=0"
      >
        <img :src="icon.jImg" alt="" class="bottomIconTip" />
      </el-button>
    </div>
    <!-- 右侧 -->
    <div class="bottomDivItem marginLeft">
      <div class="flexCenterBetween">
        <div class="leftTitle">{{ t("menu.bot") }}</div>
        <div class="flexCenter" @click="goBot">
          <span class="leftTip">{{ $t("menu.index5") }}</span>
          <img :src="icon.eImg" alt="" class="eImg" />
        </div>
      </div>
      <el-button
        class="changeLeft"
        link
        :disabled="pageId == 1"
        @click="lastPage"
        v-if="botList.length !=0"
      >
        <img :src="icon.kImg" alt="" class="bottomIconTip" />
      </el-button>
      <div class="itemBottom flexStart" v-loading="botLoading">
        <div
          class="rightFor"
          v-for="item in botList"
          :key="item"
          @click="goBaseDetails(item)"
        >
          <div class="flexCenter">
            <img :src="icon.botImgIndex" alt="" class="botImg" />
            <el-tooltip
              popper-class="toolTip"
              effect="dark"
              :content="item.templateName"
            >
              <div class="itemTitle">{{ item.templateName }}</div>
            </el-tooltip>
          </div>
          <div class="itemTip">
            {{ item.templateDesc }}
          </div>
          <div class="flexCenter padding35">
            <div class="flexCenter">
              <!-- <img :src="rightTipImg" alt="" class="bottomIconRihgt" /> -->
              <span class="bottomFont">{{ getYear(item.createAt) }}</span>
            </div>
            <!-- <div class="flexCenter">
              <img :src="icon.hotPng" alt="" class="bottomIconRihgt" />
              <span class="bottomFont">{{ getNumber(item.voteUpNum) }}</span>
            </div> -->
          </div>
        </div>
      </div>
      <el-button
        class="changRight"
        link
        :disabled="pageId == totalPage"
        @click="nextPage"
         v-if="botList.length !=0"
      >
        <img :src="icon.jImg" alt="" class="bottomIconTip" />
      </el-button>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { getYear } from "@/utils/utils";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { throttle } from "@/utils/utils";
import api from "@/api";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const userId = ref(store.state.userId);
const botList = ref([]);
const botLoading = ref(false);
const topicList = ref([]);
const topicLoading = ref(false);
const pageId = ref(1); //分页
const perPage = ref(6);
const leftPageId = ref(1); //分页
const leftPerPage = ref(3);
const totalPage = ref(null);
const navList = ref(["base.base98", "base.base99"]);
const navActive = ref(0);
const leftTotal = ref(null);
onMounted(() => {
  getBot();
  getQw();
});
const changeNav = throttle((e) => {
  leftPageId.value = 1;
  navActive.value = e;
  if (navActive.value == 0) {
    getQw();
  } else {
    getRm();
  }
}, 300);
const leftLast = () => {
  leftPageId.value--;
  if (navActive.value == 0) {
    getQw();
  } else {
    getRm();
  }
};
const goLeft = () => {
  leftPageId.value++;
  if (navActive.value == 0) {
    getQw();
  } else {
    getRm();
  }
};
const getNumber = (item) => {
  let number = Math.floor(item);
  return number;
};
const getQw = () => {
  topicLoading.value = true;
  api.share
    .getVerifyQuertApi({
      userId: userId.value,
      namePattern: "",
      pageId: leftPageId.value,
      perPage: leftPerPage.value,
    })
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        topicList.value = res.data;
        leftTotal.value = res.totalPage;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
const getRm = () => {
  topicLoading.value = true;
  api.base
    .hotQuery({
      userId: userId.value,
      namePattern: "",
      pageId: leftPageId.value,
      perPage: leftPerPage.value,
    })
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        topicList.value = res.data;
        leftTotal.value = res.totalPage;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
const lastPage = () => {
  pageId.value--;
  getBot();
};
const nextPage = () => {
  pageId.value++;
  getBot();
};
const getBot = () => {
  botLoading.value = true;
  api.bot
    .recommendQuery({
      userId: userId.value,
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      botLoading.value = false;
      if (res.returnCode == 200) {
        totalPage.value = res.totalPage;
        botList.value = res.data;
      }
    });
};
// 知识共享预览
const goShareView = (item) => {
  api.chat
    .chatDescQuery({
      userId: userId.value,
      sessionId: item.sessionId,
      sessionType: item.sessionType,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        // 判断权威
        if (res.data.isValid == 1) {
          store.commit("SET_CHATTYPE", "base");
          store.commit("SET_TOPIC", res.data);
          router.push("/chat");
          store.commit("SET_CHATPARMAS", {
            type: res.data.isOwner == 1 ? "looks" : "look",
            isValid: res.data.isValid,
          });
        } else {
          store.commit("SET_CHATTYPE", "base");
          store.commit("SET_TOPIC", res.data);
          router.push("/chat");
          store.commit("SET_CHATPARMAS", {
            type: res.data.isOwner == 1 ? "chat" : "look",
            isValid: res.data.isValid,
          });
        }
      }
    });
};
// 知识共享
const goShare = () => {
  store.commit("SET_INDEXGOSHARE");
  router.push({
    name: "share",
  });
};
// 智能体
const goBot = () => {
  store.commit("SET_INDEXGOBOT");
  router.push({
    name: "bot",
  });
};
const goBaseDetails = (item) => {
  api.chat
    .addChat({
      userId: userId.value,
      topicName: item.templateName,
      templateId: item.templateId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        api.chat
          .chatDescQuery({
            userId: userId.value,
            sessionId: res.data.table_id,
            sessionType: 2,
          })
          .then((ress) => {
            if (ress.returnCode == 200) {
              // 新增对话 不算base chat
              store.commit("SET_CHATTYPE", "chat");
              store.commit("SET_TOPIC", ress.data);
              router.push("/chat");
              store.commit("SET_CHATPARMAS", {
                type: "chat",
                isValid: 0,
              });
            }
          });
      }
    });
};
</script>

<style lang="less" scoped>
.bottomDiv {
  height: 430px;
  width: 100%;
  display: flex;
}
.bottomDivItem {
  min-width: 664px;
  width: calc(50% - 10px);
  background-color: #fefefe;
  border-radius: 30px;
  padding: 20px 30px;
  box-shadow: 0px 0px 16px 0 #dbdfff82;
  position: relative;
}
.bottomDivItem:hover {
  .changeLeft {
    display: block;
  }
  .changRight {
    display: block;
  }
}

.marginRight {
  margin-right: 10px;
}
.marginLeft {
  margin-left: 10px;
}
.leftTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 600;
}
.leftTip {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 600;
  cursor: pointer;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.bottomIconTip {
  width: 12px;
}
.wid20 {
  margin-right: 5px;
  width: 21px;
}
.eImg {
  margin-left: 5px;
  width: 11px;
  cursor: pointer;
}
.flexCenterBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 10px;
}
.itemBottom {
  height: calc(100% - 50px);
  overflow: auto;
}
.itemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
  max-width: calc(100% - 25px);
  margin-left: 10px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.itemTip {
  font-size: 20px;
  font-weight: 400;
  color: #8a8a8a;
  margin-left: 35px;
  width: calc(100% - 35px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.marginLeft45 {
  margin-left: 45px;
  width: calc(100% - 45px);
}
.padding35 {
  padding-left: 35px;
  position: relative;
}
.itemForDiv {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}
.itemForDiv:hover {
  background-color: #eceeff;
  border-radius: 16px;
  cursor: pointer;
  .tipDiv {
    background-color: #eceeff;
    border: 0.5px solid #dbdfff;
  }
}
.rightFor {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: calc(50% - 20px);
}
.rightFor:hover {
  background-color: #eceeff;
  border-radius: 16px;
}
.noMargin {
  margin: 0;
}
.bottomFont {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  margin-right: 10px;
}
.bottomIconRihgt {
  margin-right: 10px;
  width: 18px;
}
.leftTipText {
  font-size: 18px;
  color: #808080;
  font-weight: 400;
  max-width: 130px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tipDiv {
  height: 34px;
  border: 0.5px solid #d9d9d9;
  box-shadow: 1px 2px 4px 0px #cccee55e;
  background-color: #f6f6f6;
  border-radius: 8px;
  max-width: 178px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
}
.flexStart {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.dImg {
  width: 22px;
}
.botImg {
  width: 25px;
}
.noData {
  width: 300px;
  height: 300px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftTipTitle {
  font-size: 16px;
  color: #8a8a8a;
  margin-left: 10px;
}
.navItem {
  font-size: 20px;
  color: #8a8a8a;
  font-weight: 400;
  text-align: center;
  margin-right: 22px;
  height: 35px;
  cursor: pointer;
}
.navItemActive {
  font-size: 20px;
  color: #0256ff;
  font-weight: 400;
  text-align: center;
  margin-right: 22px;
  height: 32px;
  border-bottom: 4px solid #0256ff;
  cursor: pointer;
}
.changeLeft {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(0, -50%);
  display: none;
}
.changRight {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  display: none;
}
</style>
