<template>
  <div class="historyDialog">
    <div class="dialogBetWeen">
      <div class="upFileTitle">
        {{
          props.baseItem.resourcetype == 0
            ? t("base.base83")
            : t("base.base347")
        }}
      </div>
      <img
        :src="icon.closeFileView"
        alt=""
        class="closeFileViewImg"
        @click="closeHistory"
      />
    </div>
    <div class="historyDiv" ref="historyBody">
      <el-table
        :data="props.historyTableData"
        border
        :height="tableHistory"
        v-loading="props.historyLoading"
      >
        <!-- 文档名称 -->
        <el-table-column
          prop="fileName"
          :label="t('base.base84')"
          show-overflow-tooltip
          v-if="props.baseItem.resourcetype == 0"
        >
          <template #default="{ row }">
            <div class="tableFileNameDiv">
              <img :src="getBaseFileIcon(row)" alt="" class="fileIcon" />
              <div class="tableText tableMore">{{ row.fileName }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- 文档大小 -->
        <el-table-column
          prop="fileSize"
          :label="t('base.base85')"
          align="center"
          v-if="props.baseItem.resourcetype == 0"
        >
          <template #default="{ row }">
            <div class="tableText">
              {{ formatFileSize(Number(row.fileSize)) }}
            </div>
          </template>
        </el-table-column>
        <!-- 同步数量 -->
        <el-table-column
          prop="fileSize"
          :label="t('base.base342')"
          align="center"
          v-if="props.baseItem.resourcetype == 1"
        >
          <template #default="{ row }">
            <div class="tableText">
              {{
                row.numRecords != null || row.numRecords != ""
                  ? row.numRecords
                  : "-"
              }}
            </div>
          </template>
        </el-table-column>
        <!-- 上传时间 -->
        <el-table-column
          prop="finishTime"
          :label="
            props.baseItem.resourcetype == 0
              ? t('base.base86')
              : t('base.base348')
          "
          ><template #default="{ row }">
            <div class="tableText">
              {{
                props.baseItem.resourcetype == 0
                  ? getTime(row.finishTime)
                  : getTime(row.createdAt)
              }}
            </div>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column
          prop="finishStatus"
          :label="
            props.baseItem.resourcetype == 0
              ? t('base.base87')
              : t('base.base349')
          "
          align="center"
        >
          <template #default="{ row }">
            <div
              :class="row.finishStatus == 1 ? 'hisSuccess' : 'hisError'"
              v-if="props.baseItem.resourcetype == 0"
            >
              {{ row.finishStatus == 1 ? t("base.base88") : t("base.base89") }}
            </div>
            <div :class="row.status == 1 ? 'hisSuccess' : 'hisError'" v-else>
              {{
                row.status == 1
                  ? t("base.base343")
                  : t("base.base344") + row.failNum + t("base.base345")
              }}
            </div>
          </template>
        </el-table-column>
        <!-- 清除 -->
        <el-table-column
          prop="address"
          :label="t('base.base36')"
          align="center"
        >
          <template #default="{ row }">
            <el-button
              class="tableClearBtn"
              @click="
                clearItemHistory(
                  props.baseItem.resourcetype == 0 ? row.taskId : row.id
                )
              "
              >{{ t("base.base90") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="dialogEnd">
      <el-button class="dialogSaveBtn" @click="clearItemHistory('all')">{{
        t("base.base91")
      }}</el-button>
    </div>
  </div>
  <div class="maskFile"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { getBaseFileIcon, formatFileSize } from "@/utils/file";
import { useI18n } from "vue-i18n";
import { getTime } from "@/utils/utils";
const { t } = useI18n();
import { nextTick, onMounted, ref } from "vue";
const historyBody = ref(null); //获取histable高度
const tableHistory = ref(null);
const props = defineProps(["historyTableData", "historyLoading", "baseItem"]);
const emit = defineEmits(["closeHistory", "clearItemHistory"]);
onMounted(() => {
  getTableHeight();
  window.addEventListener("resize", () => {
    getTableHeight();
  });
});
const getTableHeight = () => {
  nextTick(() => {
    if (historyBody.value == null) return;
    tableHistory.value = historyBody.value.offsetHeight;
  });
};
const closeHistory = () => {
  emit("closeHistory");
};
const clearItemHistory = (id) => {
  emit("clearItemHistory", id);
};
</script>

<style lang="scss" scoped>
.maskFile {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 200;
  opacity: 0.3;
}
.historyDialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 30px;
  width: 820px;
  height: 782px;
  background-color: #fefefe;
  border-radius: 30px;
}
.historyDiv {
  height: calc(100% - 106px);
  margin: 20px 0px;
}
.hisSuccess {
  color: #14ae5c;
  font-weight: 600;
  font-size: 18px;
}
.hisError {
  color: #f02d63;
  font-weight: 600;
  font-size: 18px;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
.tableFileNameDiv {
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dialogBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upFileTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.closeFileViewImg {
  width: 14px;
  cursor: pointer;
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
.tableClearBtn {
  height: 31px;
  background-color: #f7f8ff;
  border: 0px;
  border-radius: 8px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
}
.tableText {
  color: #3d3d3d;
  font-weight: 400;
  font-size: 18px;
}
.tableMore {
  width: calc(100% - 28px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dialogSaveBtn {
  height: 48px;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #fefefe;
  font-weight: 400;
  font-size: 18px;
}
.dialogEnd {
  display: flex;
  justify-content: flex-end;
}
</style>
