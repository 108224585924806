<template>
  <div class="itemDiv">
    <!-- 头类型 -->
    <div class="flexBetWeen">
      <div class="flexCenter">
        <div class="titleDiv" v-if="props.item.notificationType == 2">
          {{ t("header.header50") }}
        </div>
        <div class="titleDiv1" v-if="props.item.notificationType == 1">
          {{ t("header.header51") }}
        </div>
        <div class="titleDiv2" v-if="props.item.notificationType == 3">
          {{ t("header.header52") }}
        </div>
        <div class="titleDiv3" v-if="props.item.notificationType == 4">
          {{ t("header.header53") }}
        </div>
        <div :class="!props.item.isRead ? 'wd' : ''" class="timeText">
          {{ getTime(props.item.createdAt) }}
        </div>
      </div>
      <div class="flexCenter">
        <el-tooltip popper-class="toolTip" :content="t('header.header54')">
          <img
            :src="icon.clearMessage"
            alt=""
            class="clearMessage"
            @click="markItem"
          />
        </el-tooltip>
        <el-tooltip popper-class="toolTip" :content="t('header.header55')">
          <img
            :src="icon.headerYd"
            alt=""
            class="clearMessage left12"
            @click="deleteNotice"
          />
        </el-tooltip>
      </div>
    </div>
    <!-- 内容 -->
    <div class="flexCenter top12">
      <div
        class="messageTx"
        :style="'background-color:' + props.item.color"
        v-if="props.item.userName"
      >
        {{ props.item.userName.slice(0, 1) }}
      </div>
      <div class="messageName">{{ props.item.userName }}</div>
      <div class="messageTip">{{ props.item.content }}</div>
    </div>
    <el-tooltip
      popper-class="toolTip"
      :content="props.item.leftNotes"
      v-if="props.item.leftNotes"
    >
      <div class="messageTips">
        {{ props.item.leftNotes }}
      </div>
    </el-tooltip>
    <div class="bodyDiv">
      <div class="flexCenter">
        <div class="messageYz">
          <img :src="icon.headerYz" alt="" class="headerYz" />
        </div>
        <el-tooltip
          popper-class="toolTip"
          :content="props.item.requestVerifyData.title"
        >
          <div class="messageTitle">
            {{ props.item.requestVerifyData.title }}
          </div>
        </el-tooltip>
      </div>
      <div class="yhxx">
        <div class="flexCenter wid65">
          <div
            class="messageTx1"
            v-if="props.item.requestVerifyData.userName"
            :style="'background-color:' + props.item.requestVerifyData.color"
          >
            {{ props.item.requestVerifyData.userName.slice(0, 1) }}
          </div>
          <div class="messageQqr">
            {{ props.item.requestVerifyData.userName }}
          </div>
        </div>
        <div class="flexCenter" v-if="props.item.notificationType <= 2">
          <div
            class="flexCenter yzBtn"
            @click="goYz(props.item.requestVerifyData)"
          >
            <div>{{ t("header.header4") }}</div>
            <img :src="icon.bImg" class="bImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { getTime } from "@/utils/utils";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["item"]);
const emit = defineEmits(["goYz", "markItem", "deleteNotice"]);
const goYz = (item) => {
  emit("goYz", item);
};
const markItem = () => {
  emit("markItem", props.item);
};
const deleteNotice = () => {
  emit("deleteNotice", props.item);
};
</script>

<style lang="scss" scoped>
.clearMessage {
  width: 20px;
  cursor: pointer;
}
.itemDiv {
  padding: 12px 0px;
  border-bottom: 1px solid #edeef6;
}
.bodyDiv {
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 14px 18px;
}
.wid65 {
  width: calc(100% - 65px);
}
.yhxx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}
.bImg {
  width: 9px;
  margin-left: 6px;
}
.yzBtn {
  border-radius: 8px;
  font-size: 16px;
  color: #0256ff;
  padding: 3px 9px;
  font-weight: 400;
  cursor: pointer;
}
.yzBtn:hover {
  background-color: #eceeff;
}
.messageQqr {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.messageTx1 {
  background-color: #f02d63;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}
.messageTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  max-width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.headerYz {
  width: 15px;
}
.messageYz {
  background-color: #ffc300;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.messageTips {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 400;
  margin-left: 37px;
  margin-bottom: 13px;
  max-width: calc(100% - 37px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.top12 {
  margin-top: 12px;
  margin-bottom: 14px;
}
.messageTip {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
}
.messageName {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-right: 10px;
}
.messageTx {
  background-color: #f02d63;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  text-align: center;
  line-height: 27px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}
.left12 {
  margin-left: 12px;
}
.wd::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #11d7b2;
  right: -15px;
  top: 3px;
}
.timeText {
  font-size: 16px;
  color: #b4b4b4;
  font-weight: 400;
  position: relative;
}
.titleDiv {
  background-color: #dbdfff;
  font-size: 14px;
  border-radius: 8px;
  color: #0256ff;
  font-weight: 400;
  padding: 5px 12px;
  margin-right: 12px;
}
.titleDiv1 {
  background-color: #e3e4fd;
  font-size: 14px;
  border-radius: 8px;
  color: #767bfa;
  font-weight: 400;
  padding: 5px 12px;
  margin-right: 12px;
}
.titleDiv2 {
  background-color: #fbd4df;
  font-size: 14px;
  border-radius: 8px;
  color: #f02d63;
  font-weight: 400;
  padding: 5px 12px;
  margin-right: 12px;
}
.titleDiv3 {
  background-color: #cfeede;
  font-size: 14px;
  border-radius: 8px;
  color: #14ae5c;
  font-weight: 400;
  padding: 5px 12px;
  margin-right: 12px;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  align-items: center;
}
</style>
