export const icon = {
    baseAdd: require("@/assets/base/add.svg"),
    baseMore: require("@/assets/base/more.svg"),
    basePin: require("@/assets/base/pin.svg"),
    basePinYes: require("@/assets/base/pinYes.svg"),
    baseLogo: require("@/assets/base/baselogo.svg"),
    admin: require("@/assets/manage/admin.svg"),
    hotPng: require("@/assets/index/hot.svg"),
    iconPng: require("@/assets/header/icon.svg"),
    fileYear: require("@/assets/base/fileYear.svg"),
    basechangeSvg: require("@/assets/chat/basechange.svg"),
    topYes: require("@/assets/index/yes.svg"),
    closeFileBottom: require("@/assets/base/closeFileBottom.svg"),
    drapBottom: require("@/assets/base/drapBottom.svg"),
    closeFileView: require("@/assets/base/closeFileView.svg"),
    nImg: require("@/assets/index/n.svg"),
    oImg: require("@/assets/index/o.svg"),
    iImg: require("@/assets/index/i.svg"),
    hImg: require("@/assets/index/h.svg"),
    cImg: require("@/assets/index/c.svg"),
    sendPng: require("@/assets/index/send.svg"),
    sendYes: require("@/assets/chat/sendYes.svg"),
    baseInput: require("@/assets/chat/baseInput.svg"),
    inputBase: require("@/assets/input/inputBase.svg"),
    inputBot: require("@/assets/input/inputBot.svg"),
    inputImg: require("@/assets/input/inputImg.svg"),
    fbl: require("@/assets/input/fbl.svg"),
    cc: require("@/assets/input/cc.svg"),
    fg: require("@/assets/input/fg.svg"),
    imgIcon: require("@/assets/input/imgIcon.svg"),
    rightReson: require("@/assets/chat/rightReson.svg"),
    leftReson: require("@/assets/chat/leftReson.svg"),
    closePng: require("@/assets/index/close.svg"),
    hotPngHeader: require("@/assets/header/hot.svg"),
    talkCloseSvg: require("@/assets/chat/talkClose.svg"),
    topTime: require("@/assets/index/time.svg"),
    sdImg: require("@/assets/input/sd.svg"),
    inputClose: require("@/assets/input/inputClose.svg"),
    noFileImg: require("@/assets/base/noFile.svg"),
    spShare: require("@/assets/base/spShare.svg"),
    arrow: require("@/assets/base/arrow.svg"),
    baseTopTime: require("@/assets/base/topTime.svg"),
    topResh: require("@/assets/base/topResh.svg"),
    hMore: require("@/assets/base/hMore.svg"),
    topic2: require("@/assets/index/hot.svg"),
    detailsLogo: require("@/assets/base/detailsLogo.svg"),
    moreImg: require("@/assets/base/moreLook.svg"),
    jbImg: require("@/assets/look/jb.svg"),
    hszImg: require("@/assets/look/hsz.svg"),
    hfgdImg: require("@/assets/look/hfgd.svg"),
    xfscImg: require("@/assets/look/xfsc.svg"),
    cmmImg: require("@/assets/look/cmm.svg"),
    scNoSvg: require("@/assets/chat/noSc.svg"),
    upReson: require("@/assets/chat/upReson.svg"),
    downReson: require("@/assets/chat/downReson.svg"),
    nextAiPng: require("@/assets/header/nextAi.svg"),
    logSvg: require("@/assets/publish/log.svg"),
    detailSvg: require("@/assets/publish/detail.svg"),
    vxSvg: require("@/assets/publish/vx.svg"),
    qwSvg: require("@/assets/publish/qw.svg"),
    qwbgSvg: require("@/assets/publish/qwbg.svg"),
    wxbgSvg: require("@/assets/publish/wxbg.svg"),
    jtSvg: require("@/assets/publish/jt.svg"),
    addClose: require("@/assets/base/addClose.svg"),
    copySvg: require("@/assets/publish/copy.svg"),
    searchSvg: require("@/assets/chat/search.svg"),
    leftFill: require("@/assets/chat/leftFill.svg"),
    rightFill: require("@/assets/chat/rightFill.svg"),
    leftNoFill: require("@/assets/chat/leftNoFill.svg"),
    rightNoFill: require("@/assets/chat/rightNoFill.svg"),
    chatCopySvg: require("@/assets/chat/copy.svg"),
    relete: require("@/assets/chat/relete.svg"),
    cToa: require("@/assets/chat/cToa.svg"),
    gantan: require("@/assets/chat/gantan.png"),
    baseReson: require("@/assets/chat/baseReson.svg"),
    netReson: require("@/assets/chat/netReson.svg"),
    editSvg: require("@/assets/chat/edit.svg"),
    time: require("@/assets/reson/time.svg"),
    talkReson: require("@/assets/reson/talkReson.svg"),
    timeReson: require("@/assets/reson/time.svg"),
    number: require("@/assets/reson/number.svg"),
    look: require("@/assets/share/look.svg"),
    yc: require("@/assets/share/yc.svg"),
    jbImgIndex: require("@/assets/index/jb.svg"),
    logo: require("@/assets/logo.svg"),
    ctrl: require("@/assets/menu/Ctrl.svg"),
    topicPng: require("@/assets/menu/topic.svg"),
    qcImg: require("@/assets/menu/QC.svg"),
    k: require("@/assets/menu/K.svg"),
    Vector: require("@/assets/menu/leftSs.svg"),
    dgn: require("@/assets/menu/dgn.svg"),
    footerImg: require("@/assets/menu/fooder.svg"),
    ybp: require("@/assets/header/ybp.svg"),
    ld: require("@/assets/header/ld.svg"),
    botImg: require("@/assets/base/addBot.svg"),
    titleSpImg: require("@/assets/base/titleSp.svg"),
    sq: require("@/assets/header/sq.svg"),
    menuIconRight: require("@/assets/menu/menuIconRight.svg"),
    timePng: require("@/assets/header/time.svg"),
    headerYz: require("@/assets/header/headerYz.svg"),
    mbImg: require("@/assets/header/mb.svg"),
    mrImg: require("@/assets/header/mr.svg"),
    mcImg: require("@/assets/header/mc.svg"),
    bImg: require("@/assets/index/b.svg"),
    chinaImg: require("@/assets/header/china.svg"),
    chinaHkImg: require("@/assets/header/chinaHk.svg"),
    usaImg: require("@/assets/header/usa.svg"),
    goBackImg: require("@/assets/header/goBack.svg"),
    langTx: require("@/assets/header/langTx.svg"),
    langActive: require("@/assets/header/langActive.svg"),
    grzx: require("@/assets/header/grzx.svg"),
    xgmm: require("@/assets/header/czmm.svg"),
    tcdl: require("@/assets/header/tcdl.svg"),
    HeaderMember: require("@/assets/header/baseMember.png"),
    baseTopic: require("@/assets/header/baseTopic.png"),
    HeaderFile: require("@/assets/header/baseFile.png"),
    closeMM: require("@/assets/header/closeMM.svg"),
    noDataSvg: require("@/assets/nodata.svg"),
    yesPng: require("@/assets/index/yes.svg"),
    greenYes: require("@/assets/header/greenYes.svg"),
    item1: require("@/assets/base/item1.svg"),
    excelImg: require("@/assets/base/excel.svg"),
    ollma: require("@/assets/base/ollma.svg"),
    zhImg: require("@/assets/base/zh.svg"),
    addFile: require("@/assets/base/addFile.svg"),
    addFileAc: require("@/assets/base/addFileAc.svg"),
    spAc: require("@/assets/base/spAc.svg"),
    spNo: require("@/assets/base/spNo.svg"),
    baseBImg: require("@/assets/base/b.svg"),
    imgAi: require("@/assets/base/img.svg"),
    imgYes: require("@/assets/chat/imgYes.svg"),
    azure: require("@/assets/base/azure.svg"),
    aliyun: require("@/assets/base/aliyun.svg"),
    baidu: require("@/assets/base/baidu.svg"),
    upFiles: require("@/assets/base/upFiles.svg"),
    addFiles: require("@/assets/base/addFiles.svg"),
    baseTb: require("@/assets/base/baseTb.svg"),
    upLog: require("@/assets/base/upLog.svg"),
    fileIcon: require("@/assets/base/fileIcon.svg"),
    topic1: require("@/assets/base/topic1.svg"),
    topic2base: require("@/assets/base/topic2.svg"),
    topic3: require("@/assets/base/topic3.svg"),
    fileUpSvg: require("@/assets/base/fileUp.svg"),
    topic5: require("@/assets/base/topic5.svg"),
    ok: require("@/assets/upload/ok.svg"),
    down: require("@/assets/upload/down.svg"),
    up: require("@/assets/upload/up.svg"),
    closeSvg: require("@/assets/upload/close.svg"),
    noData: require("@/assets/nodata.svg"),
    spColor: require("@/assets/base/spColor.svg"),
    fileMore: require("@/assets/base/fileMore.svg"),
    zkb: require("@/assets/base/zkb.svg"),
    zkr: require("@/assets/base/zkr.svg"),
    fileFolder: require("@/assets/base/fileFolder.svg"),
    botWsSvg: require("@/assets/publish/botWs.svg"),
    addSvg: require("@/assets/publish/add.svg"),
    highSvg: require("@/assets/bot/high.svg"),
    botRight: require("@/assets/bot/botRight.svg"),
    botBottom: require("@/assets/bot/botBottom.svg"),
    rabit: require("@/assets/bot/rabit.svg"),
    helper: require("@/assets/bot/helper.svg"),
    brain: require("@/assets/bot/brain.svg"),
    vector: require("@/assets/bot/Vector.svg"),
    IE: require("@/assets/bot/IE.svg"),
    addTool: require("@/assets/bot/addTool.svg"),
    endTalk: require("@/assets/publish/endTalk.svg"),
    clearBtn1Svg: require("@/assets/chat/clearBtn1.svg"),
    chatSend: require("@/assets/chat/send.svg"),
    chatInputFile: require("@/assets/chat/chatInputFile.svg"),
    chatInputImg: require("@/assets/chat/chatInputImg.svg"),
    upSvg: require("@/assets/chat/up.svg"),
    lineSvg: require("@/assets/chat/line.svg"),
    qxdz: require("@/assets/chat/dzTip.svg"),
    qxdzSet: require("@/assets/chat/qxdz.svg"),
    closeHistory: require("@/assets/chat/closeHistory.svg"),
    baseMlSvg: require("@/assets/base/baseMl.svg"),
    sqLeftSvg: require("@/assets/chat/sqLeft.svg"),
    clearBtnSvg: require("@/assets/chat/clearBtn.svg"),
    seleteSvg: require("@/assets/chat/selete.svg"),
    rightGd: require("@/assets/chat/rightGd.svg"),
    scSvg: require("@/assets/chat/sc.svg"),
    setCap: require("@/assets/chat/setCap.svg"),
    setNoCap: require("@/assets/chat/setNoCap.svg"),
    moreSvg: require("@/assets/chat/more.svg"),
    sqRightSvg: require("@/assets/chat/sqRight.svg"),
    setSearch: require("@/assets/chat/setSearch.svg"),
    chatYesSvg: require("@/assets/chat/chatYes.svg"),
    setSq: require("@/assets/chat/setSq.svg"),
    history: require("@/assets/chat/history.svg"),
    cd: require("@/assets/chat/cd.svg"),
    dz: require("@/assets/chat/dz.svg"),
    ydz: require("@/assets/chat/ydz.svg"),
    ydc: require("@/assets/chat/ydc.svg"),
    qxsc: require("@/assets/chat/qxsc.svg"),
    crossSvug: require("@/assets/chat/cross.svg"),
    chatMenu: require('@/assets/menu/chat.svg'),
    lookAllMenu: require('@/assets/header/lookAll.svg'),
    shareMenu: require("@/assets/menu/share.svg"),
    shareMenuAc: require("@/assets/header/sac.svg"),
    jImg: require("@/assets/index/j.svg"),
    kImg: require("@/assets/index/k.svg"),
    eImg: require("@/assets/index/e.svg"),
    dImg: require("@/assets/index/d.svg"),
    leftTip: require("@/assets/index/leftTip.svg"),
    botImgIndex: require("@/assets/index/bot.svg"),
    rightTipImg: require("@/assets/index/rightTip.svg"),
    AzureAI: require("@/assets/manage/AzureAI.svg"),
    aliAI: require("@/assets/manage/aliAI.svg"),
    baiduAI: require("@/assets/manage/baiduAI.svg"),
    rightImg: require("@/assets/index/rightImg.svg"),
    leftImg: require("@/assets/index/leftImg.svg"),
    ollmaMan: require("@/assets/manage/otherYt.svg"),
    channelSvg: require("@/assets/channel.svg"),
    login: require("@/assets/login/logo.svg"),
    slogo: require("@/assets/login/slogo.svg"),
    sso: require("@/assets/login/sso.svg"),
    bgLogin: require("@/assets/login/bgLogin.svg"),
    qwImg: require("@/assets/look/qw.svg"),
    rmImg: require("@/assets/look/rm.svg"),
    scImg: require("@/assets/look/sc.svg"),
    moreImgLook: require("@/assets/look/more.svg"),
    optionsImg: require("@/assets/look/options.svg"),
    goBackImgLook: require("@/assets/look/goBack.svg"),
    groupItem: require("@/assets/manage/groupItem.svg"),
    logoAzure: require("@/assets/manage/logoAzure.svg"),
    logoBaidu: require("@/assets/manage/logoBaidu.svg"),
    logoAli: require("@/assets/manage/logoAli.svg"),
    addResoure: require("@/assets/manage/addResoure.svg"),
    bill: require("@/assets/manage/bill.svg"),
    samlLogo: require("@/assets/manage/samlLogo.svg"),
    uploadMan: require('@/assets/manage/upload.svg'),
    downloadMan: require('@/assets/manage/download.svg'),
    qincheng: require('@/assets/manage/qincheng.svg'),
    dui: require('@/assets/manage/dui.svg'),
    cuo: require('@/assets/manage/cuo.svg'),
    user_yellow: require('@/assets/manage/user_yellow.svg'),
    table: require('@/assets/manage/table.svg'),
    QCMan: require('@/assets/manage/QC.svg'),
    cardTime: require('@/assets/manage/cardTime.svg'),
    cardUser: require('@/assets/manage/cardUser.svg'),
    logo_N: require('@/assets/manage/logo_N.svg'),
    manAzure: require('@/assets/manage/Azure.svg'),
    manAli: require('@/assets/manage/ali.svg'),
    manBaidu: require('@/assets/manage/baidu.svg'),
    addGroup: require('@/assets/manage/addGroup.svg'),
    delete: require('@/assets/manage/delete.svg'),
    editImg: require('@/assets/manage/editImg.svg'),
    SAML: require('@/assets/manage/SAML.svg'),
    sp: require('@/assets/publish/sp.svg'),
    hover2: require('@/assets/manage/hover2.svg'),
    screen: require('@/assets/manage/screen.svg'),
    loop: require('@/assets/manage/loop.svg'),
    adminMan: require('@/assets/manage/admin.svg'),
    spIcon: require('@/assets/publish/spIcon.svg'),
    noQImg: require('@/assets/share/noQ.svg'),
    baseImg: require('@/assets/share/base.svg'),
    chImg: require('@/assets/share/ch.svg'),
    netImg: require('@/assets/share/net.svg'),
    editImgShare: require('@/assets/share/edit.svg'),
    editAcImg: require('@/assets/share/editAc.svg'),
    hdzcImg: require('@/assets/share/hdzc.svg'),
    yjgxImg: require('@/assets/share/yjgx.svg'),
    addFileImg: require('@/assets/share/addFile.svg'),
    anchorImg: require('@/assets/share/anchorImg.svg'),
    exportImg: require('@/assets/share/exportImg.svg'),
    fbyzImg: require('@/assets/share/fbyz.svg'),
    shareYz: require('@/assets/share/shareYz.svg'),
    resh: require('@/assets/index/resh.svg'),
    verified: require('@/assets/share/verified.svg'),
    hotShare: require('@/assets/share/hot.svg'),
    collect: require('@/assets/share/collect.svg'),
    fvyzImg: require('@/assets/share/fbyzView.svg'),
    fhbj: require('@/assets/share/fhbj.svg'),
    echarImg: require('@/assets/statistics/echar.svg'),
    tokenError: require('@/assets/statistics/tokenError.svg'),
    downImg: require('@/assets/statistics/down.svg'),
    upImg: require('@/assets/statistics/up.svg'),
    indexMenu: require('@/assets/menu/index.svg'),
    baseMenu: require('@/assets/menu/base.svg'),
    baseMenuAc: require('@/assets/header/baseAc.svg'),
    botMenu: require('@/assets/menu/bot.svg'),
    botMenuAc: require('@/assets/header/botAc.svg'),
    statisticsMenu: require('@/assets/menu/statistics.svg'),
    tokenAc: require('@/assets/header/tokenAc.svg'),
    manageMenu: require('@/assets/menu/manage.svg'),
    mangeMenuAc: require('@/assets/header/mangeAc.svg'),
    word: require('@/assets/upload/word.svg'),
    excel: require('@/assets/upload/excel.svg'),
    html: require('@/assets/upload/html.svg'),
    pdf: require('@/assets/upload/pdf.svg'),
    ppt: require('@/assets/upload/ppt.svg'),
    txt: require('@/assets/upload/txt.svg'),
    notype: require('@/assets/upload/notype.svg'),
    wlSvg: require("@/assets/chat/wl.svg"),
    bexcel: require("@/assets/upload/bexcel.svg"),
    bhtml: require("@/assets/upload/bhtml.svg"),
    bpdf: require("@/assets/upload/bpdf.svg"),
    btext: require("@/assets/upload/btext.svg"),
    bword: require("@/assets/upload/bword.svg"),
    bppt: require("@/assets/upload/bppt.svg"),
    rexcel: require("@/assets/upload/rexcel.svg"),
    rhtml: require("@/assets/upload/rhtml.svg"),
    rpdf: require("@/assets/upload/rpdf.svg"),
    rtext: require("@/assets/upload/rtxt.svg"),
    rword: require("@/assets/upload/rword.svg"),
    rppt: require("@/assets/upload/rppt.svg"),
    wordType: require("@/assets/base/wordType.svg"),
    excelType: require("@/assets/base/excelType.svg"),
    basePx: require("@/assets/base/basePx.svg"),
    znzs: require("@/assets/bot/znzs.svg"),
    znyy: require("@/assets/bot/znyy.svg"),
    cj: require("@/assets/bot/cj.svg"),
    tsc: require("@/assets/bot/tsc.svg"),
    znzsAc: require("@/assets/bot/znzsAc.svg"),
    znyyAc: require("@/assets/bot/znyyAc.svg"),
    cjAc: require("@/assets/bot/cjAc.svg"),
    tscAc: require("@/assets/bot/tscAc.svg"),
    botDhq: require("@/assets/bot/botDhq.svg"),
    modelOptions: require("@/assets/chat/modelOptions.svg"),
    gptAzure: require("@/assets/gpt/gptAzure.svg"),
    stepLine: require("@/assets/bot/stepLine.svg"),
    plugUpload: require("@/assets/bot/plugUpload.svg"),
    plugAdd: require("@/assets/bot/plugAdd.svg"),
    botOption: require("@/assets/bot/botOption.svg"),
    yjwsIcon: require("@/assets/bot/yjwsIcon.svg"),
    plugDelete: require("@/assets/bot/plugDelete.svg"),
    manOptions: require("@/assets/gpt/manOptions.svg"),
    gptDialogTab: require("@/assets/gpt/gptDialogTab.svg"),
    mac: require("@/assets/gpt/mac.svg"),
    stopMac: require("@/assets/gpt/stopMac.svg"),
    base1: require("@/assets/gpt/base1.svg"),
    base2: require("@/assets/gpt/base2.svg"),
    base3: require("@/assets/gpt/base3.svg"),
    base4: require("@/assets/gpt/base4.svg"),
    base5: require("@/assets/gpt/base5.svg"),
    plugBs: require("@/assets/bot/plugBs.svg"),
    plugOption: require("@/assets/bot/plugOption.svg"),
    baseSql: require("@/assets/base/baseSql.svg"),
    sqlCx: require("@/assets/base/sqlCx.svg"),
    baseAzure: require("@/assets/gpt/baseAzure.svg"),
    baseAli: require("@/assets/gpt/baseAli.svg"),
    baseBaidu: require("@/assets/gpt/baseBaidu.svg"),
    baseOllama: require("@/assets/gpt/baseOllama.svg"),

    qianwenGpt: require("@/assets/gpt/qianwenGpt.svg"),
    baiduGpt: require("@/assets/gpt/baiduGpt.svg"),
    azureGpt: require("@/assets/gpt/azureGpt.svg"),
    deepseekGtp: require("@/assets/gpt/deepseekGtp.svg"),

    aliNoGpt: require("@/assets/gpt/aliNoGpt.svg"),
    azureNoGpt: require("@/assets/gpt/azureNoGpt.svg"),
    baiduNoGpt: require("@/assets/gpt/baiduNoGpt.svg"),
    ollamaNoGpt: require("@/assets/gpt/ollamaNoGpt.svg"),

    aliBlob: require("@/assets/gpt/aliBlob.svg"),
    azureBlob: require("@/assets/gpt/azureBlob.svg"),
    azureWord: require("@/assets/gpt/azureWord.svg"),
    azureSpeech: require("@/assets/gpt/azureSpeech.svg"),
    azureTranslator: require("@/assets/gpt/azureTranslator.svg"),
    azureBing: require("@/assets/gpt/azureBing.svg"),

    excelModel: require("@/assets/base/excelModel.svg"),
    fileDesc: require("@/assets/base/fileDesc.svg"),
    baseSpLogo: require("@/assets/base/baseSpLogo.svg"),
    clearMessage: require("@/assets/header/clearMessage.svg"),
    headerYd: require("@/assets/header/headerYd.svg"),
    headerNext: require("@/assets/header/headerNext.svg"),
    CN: require("@/assets/gpt/CN.svg"),
    AR: require("@/assets/gpt/AR.svg"),
    EN: require("@/assets/gpt/EN.svg"),
    DE: require("@/assets/gpt/DE.svg"),
    ES: require("@/assets/gpt/ES.svg"),
    FR: require("@/assets/gpt/FR.svg"),
    IT: require("@/assets/gpt/IT.svg"),
    JA: require("@/assets/gpt/JA.svg"),
    KO: require("@/assets/gpt/KO.svg"),
    PT: require("@/assets/gpt/PT.svg"),
    RU: require("@/assets/gpt/RU.svg"),
    TC: require("@/assets/gpt/TC.svg"),
}