<template>
  <div class="chatContent">
    <div :class="lookDialog ? 'fileIndex' : 'chatIndex'">
      <!-- gpt切换 -->
      <modelListView
        v-if="routeType == 'chat' && modelType"
        class="modelListView"
        type="right"
        :model="model"
        :modelList="modelList"
        :copyModelList="copyModelList"
        :macType="macType"
        :tagActive="tagActive"
        :llmNav="llmNav"
        :llmConfigurationDict="llmConfigurationDict"
        @changeModel="changeModel"
        @sendForm="sendForm"
      ></modelListView>
      <!-- 聊天 -->
      <div
        :class="{
          width10101:
            (rightType && !historyDialog && !lookDialog) ||
            (!rightType && historyDialog && !lookDialog), //只展示右侧一个清空
          width1010: !rightType && !lookDialog, //没有展示
          width10102: rightType && historyDialog && !lookDialog, //俩同时
          width10103: !rightType && !historyDialog && lookDialog,
        }"
        ref="leftDiv"
      >
        <div
          class="talkDiv"
          :style="'height:' + qaHeight + 'px'"
          ref="containerRef"
        >
          <!-- 默认 问候语-->
          <div
            class="messageView"
            :class="rightType || historyDialog || lookDialog ? 'top50' : ''"
          >
            {{
              topicItem.sessionType == 1
                ? t("base.base333")
                : templateCall
                ? templateCall
                : t("base.base186")
            }}
          </div>
          <!-- 提问灵感 -->
          <botQuestion :ideas="idea" @saveMessage="saveMessage"></botQuestion>
          <div v-for="(item, index) in chatList" :key="index">
            <!-- qa -->
            <qaIndex
              type="chat"
              :copyType="true"
              :item="item"
              :id="'talk' + item.conversationId"
              :ifThink="isValid == 0 ? true : false"
              @openFileView="openFileView"
              @openTalk="openTalk"
              @openResultDialog="openResultDialog"
            >
            </qaIndex>
            <!-- 操作类 -->
            <setVue
              ref="setRef"
              v-if="item.conversationId"
              @openHistory="openHistory"
              @scrollBottom="scrollBottom"
              :routeType="routeType"
              :rightType="rightType"
              :item="item"
              :model="model"
              :lookDialog="lookDialog"
              :historyDialog="historyDialog"
              :chatList="chatList"
              :llmConfigurationDict="llmConfigurationDict"
              @getTalk="deleteItem"
              @changeRequestverify="changeRequestverify"
            ></setVue>
            <!-- 编辑弹窗 -->
            <editQuesstionView
              :item="item"
              v-if="item.editType"
              @closeEdit="closeEdit"
            ></editQuesstionView>
            <!-- 点踩提醒 -->
            <downUpView
              v-if="item.tipDialog"
              :item="item"
              @closeDownUp="closeDownUp"
            ></downUpView>
            <!-- 提示语句 -->
            <!-- <div
              class="tipStart"
              v-if="
                index == chatList.length - 1 &&
                chatList[chatList.length - 1].conversationId &&
                routeType == 'chat'
              "
            >
              <tipView
                class="tipDiv"
                v-for="item in messageList"
                :key="item"
                @click="saveMessage(item)"
                >{{ item }}</tipView
              >
            </div> -->
          </div>
        </div>
        <div ref="inputBottomDiv">
          <inputIndex
            v-if="routeType == 'chat'"
            ref="inputRef"
            :rightType="rightType"
            :lookDialog="lookDialog"
            :historyDialog="historyDialog"
            :chatList="chatList"
            :model="model"
            @changeMacType="changeMacType"
            @inpuBaseId="inpuBaseId"
            @sendMessage="sendMessage"
            @getDivHeight="getDivHeight"
            @openFileView="openFileView"
            @openBaseFile="openBaseFile"
            @clearChatList="clearChatList"
            @changeTag="changeTag"
            @closeTag="closeTag"
            @clearTalk="clearTalk"
          ></inputIndex>
          <!-- 拖拽框 -->
          <div
            v-if="dragStartType"
            :class="rightType && historyDialog ? 'start143' : 'start67'"
            class="dragStartType"
            :draggable="true"
            @dragover.prevent="dragover"
            @drop="dropTop"
          >
            <img :src="icon.drapBottom" alt="" class="drapBottom" />
            <div class="drapBottomText">
              {{ t("base.base69") }}
            </div>
          </div>
        </div>
      </div>
      <!-- 历史记录 -->
      <transition name="expand-transition">
        <historyView
          v-if="historyDialog"
          :rightType="rightType"
          :historyDialog="historyDialog"
          :historyList="historyList"
          @closeHistoryClick="closeHistoryClick"
          @changeItemPage="changeItemPage"
        ></historyView>
      </transition>
      <!-- 右侧展开 -->
      <div
        v-if="!rightType && !historyDialog && !lookDialog"
        @click="rightType = !rightType"
        class="rightIcon"
      >
        <img :src="icon.sqLeftSvg" alt="" class="wid18" />
      </div>
      <!-- 展开div -->
      <transition name="expand-transition">
        <rightView
          v-if="rightType"
          :chatType="chatType"
          :deleteLoading="deleteLoading"
          :chatList="chatList"
          :capDialog="capDialog"
          :requestverifyLoading="requestverifyLoading"
          :containerRef="containerRef"
          :topicItem="topicItem"
          :seleteUserInfo="seleteUserInfo"
          :pageId="pageId"
          :routeType="routeType"
          @handleClick="handleClick"
          @deleteSession="deleteSession"
          @likeFn="likeFn"
          @openCap="openCap"
          @closeRight="closeRight"
          @closeCap="closeCap"
          @saveCap="saveCap"
          @loadList="talkLoad"
        ></rightView>
      </transition>
    </div>
    <!-- 文件预览 -->
    <fileView
      v-if="lookDialog && lookType == 'file'"
      type="chat"
      class="fileViewDiv"
      :fileItem="fileItem"
      :chunkDialog="true"
      :chunkList="chunkList"
      :isValed="chatType == 'base' ? true : false"
      :qaType="qaType"
      @closeFileView="closeFileView"
      @getFileList="getTalk"
    ></fileView>
    <!-- 对话预览 -->
    <topicView
      v-if="lookDialog && lookType == 'talk'"
      :sessionInfo="sessionInfo"
      :talkList="talkList"
      @closeTalk="closeTalk"
      @openTalk="openTalk"
      @talkOpenFile="talkOpenFile"
      @openResultDialog="openResultDialog"
    ></topicView>
    <!-- sql预览 -->
    <excelView
      v-if="lookDialog && lookType == 'sql'"
      class="sqlViewDiv"
      :type="chatType == 'base' && topicItem.isValid == 0 ? 0 : 1"
      :fileItem="fileItem"
      @closeExcel="closeExcel"
      @getFileList="getTalk"
    ></excelView>
    <!-- sql查询结果 -->
    <tipView
      v-if="resultType"
      :title="t('gpt.index62')"
      :tip="resultMessage"
      :ok="t('bot.bot49')"
      :maskType="false"
      :markDown="false"
      @cancel="closeResultType"
      @ok="closeResultType"
    ></tipView>
    <!-- 微模型选择文件 -->
    <baseFileView
      :saveList="saveList"
      :type="false"
      :baseId="baseId"
      v-if="baseFileDialog"
      @cancel="baseFileDialog = false"
      @ok="saveBase"
    ></baseFileView>
    <!-- 知识库情况下打开 -->
    <div
      class="baseMlDiv"
      @click.stop="openBaseFileDialog"
      v-if="chatType == 'base' && routeType == 'chat'"
    >
      <img class="baseMlSvg" :src="icon.baseMlSvg" alt="" />
    </div>
    <!-- 文件列表 -->
    <baseDialogFile
      ref="baseDialogFileRef"
      :leftFileLoading="leftFileLoading"
      :baseList="baseList"
      :rightList="rightList"
      :knowledge_type="topicItem.knowledge_type"
      @getTopic="getTopic"
      @changeTopic="changeTopic"
      @loadList="loadList"
      @changeNav="changeNav"
      @openFileView="openFileView"
      @openTalk="openTalk"
      @dragStart="dragStart"
      @nodeDragEnd="nodeDragEnd"
      @topicDragStart="topicDragStart"
      @dragover="dragover"
      v-if="baseDialogType"
    ></baseDialogFile>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import tipView from "@/components/tipDialog/index.vue";
import modelListView from "@/components/chat/modelList.vue";
import inputIndex from "./input.vue";
import excelView from "@/components/fileView/excelIndex.vue";
import setVue from "./set.vue";
// import tipView from "@/components/tip/index.vue";
import qaIndex from "@/components/qa/index.vue";
import fileView from "@/components/fileView/index.vue";
import baseDialogFile from "@/components/chat/baseDialogFile.vue";
import topicView from "@/components/chat/topicView.vue";
import rightView from "./right.vue";
import historyView from "./history.vue";
import downUpView from "./downUp.vue";
import editQuesstionView from "./editQuesstion.vue";
import { encryptApi, decryptApi } from "@/utils/crypto";
import baseFileView from "@/components/chat/baseFile.vue";
import { ref, watch, onMounted, onUpdated, nextTick, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { message } from "ant-design-vue";
import api from "@/api";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();
const model = ref(""); //gpt值
const modelList = ref([]); //模型列表
const userId = ref(store.state.userId);
const capDialog = ref(false); //验证弹窗状态
const chatType = ref(store.state.chatType);
const lookDialog = ref(false); //预览状态
const fileItem = ref({}); //文件预览item
const historyDialog = ref(false); //历史弹窗
const historyList = ref({}); //历史记录
// 右侧
const containerRef = ref(null); //ref值计算用
const chatList = ref([]); //历史聊天记录内容
const rightType = ref(false); //右侧展示值 如果大于1条就展开右侧 没有就关闭
const topicItem = ref(store.state.topicItem); //点击详情很重要
const leftDiv = ref(null);
const inputBottomDiv = ref(null);
const qaHeight = ref(null);
const seleteUserInfo = ref([]); //验证人
const requestverifyLoading = ref(false); //loading验证
const deleteLoading = ref(false); //loading归档
const messageList = ref([]);
const inputRef = ref(null);
const baseFileDialog = ref(false);
const baseId = ref(null);
const saveList = ref([]);
const setRef = ref(null);
const chunkList = ref([]);
const routeType = ref("");
const templateCall = ref("");
const idea = ref([]);
const talkList = ref([]);
const lookType = ref("file"); //根据file 和talk判断要展示什么
const sessionInfo = ref();
const qaType = ref(true);
const baseDialogType = ref(false); //判断是否打开知识库文件弹窗
const topicPageId = ref(1); //话题分页
const rightList = ref([]); //话题列表
const leftFileLoading = ref(false); //树loading
const baseList = ref([]); //知识库文件列表
const dragStartType = ref(false); //开始移动
const baseDialogFileRef = ref(null);
const isValid = ref(null);
const chatParams = ref(store.state.chatParams);
onMounted(() => {
  routeType.value = chatParams.value.type ? chatParams.value.type : "chat";
  isValid.value = chatParams.value.isValid ? chatParams.value.isValid : 0;
  if (topicItem.value.sessionType == 1) {
    store.commit("SET_CHATTYPE", "base");
    chatType.value = "base";
  } else {
    store.commit("SET_CHATTYPE", "chat");
    chatType.value = "chat";
  }
  if (Object.keys(store.state.postObj).length == 0) {
    pageId.value = 1;
    getTalk();
    getDefauleModel();
    getModel();
  } else {
    model.value = store.state.postObj.model;
    llmNav.value = store.state.postObj.llmNav;
    llmConfigurationDict.value = store.state.postObj.llmConfigurationDict;
    getCall();
    if (store.state.postObj.imageFlag == 1) {
      getImgModel();
    } else {
      getModel();
    }
  }
  window.addEventListener("resize", () => {
    getDivHeight();
  });
  // getMessage();
  getDivHeight();
  document.addEventListener("click", handleOutsideClick);
});
onUpdated(() => {
  scrollBottom();
});
// 离开页面
onBeforeRouteLeave((to, from, next) => {
  window.removeEventListener("resize", () => {});
  store.commit("SET_TALK", {});
  next();
});
watch(
  () => inputBottomDiv.value,
  (newValue, oldValue) => {
    getDivHeight();
  }
);
watch(
  () => store.state.chatParams,
  (newValue, oldValue) => {
    chatParams.value = store.state.chatParams;
    routeType.value = chatParams.value.type ? chatParams.value.type : "chat";
    isValid.value = chatParams.value.isValid ? chatParams.value.isValid : 0;
  }
);
// 监听切换 然后调用接口
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
    lookDialog.value = false;
    historyDialog.value = false;
    idea.value = [];
    routeType.value = route.query.type ? route.query.type : "chat";
    isValid.value = route.query.isValid ? route.query.isValid : 0;
    if (topicItem.value.sessionType == 1) {
      store.commit("SET_CHATTYPE", "base");
      chatType.value = "base";
    } else {
      store.commit("SET_CHATTYPE", "chat");
      chatType.value = "chat";
    }
    if (Object.keys(store.state.postObj).length == 0) {
      pageId.value = 1;
      llmNav.value = 0;
      getTalk();
      getDefauleModel();
      getModel();
    } else {
      model.value = store.state.postObj.model;
      llmNav.value = store.state.postObj.llmNav;
      llmConfigurationDict.value = store.state.postObj.llmConfigurationDict;
      getCall();
      if (store.state.postObj.imageFlag == 1) {
        getImgModel();
      } else {
        getModel();
      }
    }
    // getMessage();
    getDivHeight();
  }
);
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => rightType.value,
  (newValue, oldValue) => {
    getDivHeight();
  }
);
watch(
  () => historyDialog.value,
  (newValue, oldValue) => {
    getDivHeight();
  }
);
watch(
  () => lookDialog.value,
  (newValue, oldValue) => {
    getDivHeight();
  }
);
const resultMessage = ref("");
const resultType = ref(false);
const openResultDialog = (message) => {
  resultType.value = true;
  resultMessage.value = message;
};
// 关闭查询结果
const closeResultType = () => {
  resultType.value = false;
};
const llmConfigurationDict = ref({});
const getDefaulLlm = () => {
  if (tagActive.value == 2) return;
  api.gpt
    .llmGet({
      userId: userId.value,
      modelType: model.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        llmConfigurationDict.value = res.data.accurate;
      }
    });
};
const llmNav = ref(0);
const sendForm = (form, nav) => {
  llmNav.value = nav;
  llmConfigurationDict.value = form;
};
const macType = ref(false);
const changeMacType = (type) => {
  macType.value = type;
};
// 切换model
const changeModel = (e) => {
  model.value = e;
};
// 关闭点踩
const closeDownUp = (item) => {
  item.tipDialog = false;
};
// 关闭对话
const closeTalk = () => {
  lookDialog.value = false;
  getDivHeight();
};
onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick);
  window.removeEventListener("resize", () => {});
});
// 监听点击事件，点击页面其他地方时关闭弹出框
const handleOutsideClick = (event) => {
  if (baseDialogType.value && !event.target.closest(".baseDialog")) {
    baseDialogType.value = false;
  }
};
// 关闭查询结果
const closeExcel = () => {
  lookDialog.value = false;
  lookType.value = "";
};
const topicDragStart = (event, item) => {
  dragStartType.value = true;
  event.dataTransfer.setData("text/plain", JSON.stringify(item));
};
// 移动开始
const dragStart = (item, event) => {
  dragStartType.value = true;
  event.dataTransfer.setData("text/plain", JSON.stringify(item.data));
};
// div移动
const dropTop = (event) => {
  const itemData = event.dataTransfer.getData("text/plain");
  const item = JSON.parse(itemData || "{}");
  let fileList = [];
  let folderList = [];
  let attachmentSessionId = null;
  let arr = [item];
  arr.forEach((item) => {
    if (item.fileName) {
      fileList.push(item.id);
    } else if (item.folderName) {
      folderList.push(item.id);
    } else if (item.sessionName) {
      attachmentSessionId = item.sessionId;
    }
  });
  api.chat
    .attachmentAdd({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
      file_ids: fileList,
      folder_ids: folderList,
      attachmentSessionId: attachmentSessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        inputRef.value.getBaseFileIcon(topicItem.value.sessionId);
        dragStartType.value = false;
        baseDialogFileRef.value.oneList();
      }
    });
};
const dragover = (event) => {
  event.preventDefault();
};
// 拖动结束
const nodeDragEnd = () => {
  dragStartType.value = false;
  openBaseFileDialog();
};
// 更多话题
const loadList = (type) => {
  topicPageId.value++;
  getTopic(type);
};
// 切换nav
const changeNav = (index, type) => {
  topicPageId.value = 1;
  if (index == 0) {
    openBaseFileDialog();
  } else {
    getTopic(type);
  }
};
// 切换话题
const changeTopic = (type) => {
  topicPageId.value = 1;
  getTopic(type);
};
// 获取话题广场
const getTopic = (type, name) => {
  if (name) {
    topicPageId.value = 1;
  }
  leftFileLoading.value = true;
  api.base[type ? "verifyQuery" : "hotQuery"](
    {
      userId: userId.value,
      libraryId:
        chatType.value == "base"
          ? topicItem.value.knowledge_library_id
          : baseId.value,
      pageId: topicPageId.value,
      perPage: 10,
      sessionNamePattern: name,
    },
    chatType.value == "base"
      ? topicItem.value.knowledge_library_id
      : baseId.value
  )
    .then((res) => {
      leftFileLoading.value = false;
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.sessionType = 1;
        });
        if (topicPageId.value == 1) {
          rightList.value = res.data;
        } else {
          rightList.value = rightList.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      leftFileLoading.value = false;
    });
};
// 打开知识库文件
const openBaseFileDialog = () => {
  leftFileLoading.value = true;
  api.base
    .queryFileList({
      userId: userId.value,
      libraryId:
        chatType.value == "base"
          ? topicItem.value.knowledge_library_id
          : baseId.value,
    })
    .then((res) => {
      leftFileLoading.value = false;
      if (res.returnCode == 200) {
        baseList.value = res.data[0].children;
        baseDialogType.value = true;
      }
    })
    .catch((err) => {
      leftFileLoading.value = false;
    });
};
// 打开话题
const openTalk = (item) => {
  if (item.readable == 0) {
    message.warning(t("base.base321"));
    return;
  }
  api.share
    .baseDetailQuery({
      userId: userId.value,
      sessionId: item.sessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length != 0) {
          res.data.conversationInfo.forEach((items) => {
            items.chatActive = 1;
            items.resonType = false;
            items.resonList = [];
            items.tipDialog = false;
            items.editType = false;
            items.loading = false;
          });
        }
        talkList.value = res.data.conversationInfo;
        sessionInfo.value = res.data.sessionInfo;
        historyDialog.value = false;
        rightType.value = false;
        lookDialog.value = true;
        lookType.value = "talk";
      }
    });
};
// 关闭右侧
const closeRight = () => {
  rightType.value = !rightType.value;
};
// 关闭历史
const closeHistoryClick = () => {
  historyDialog.value = false;
};
// 打开请求验证 关闭其他
const changeRequestverify = () => {
  rightType.value = false;
  historyDialog.value = false;
  lookDialog.value = false;
};
const tagActive = ref(null);
const changeTag = (index) => {
  model.value = null;
  tagActive.value = index;
  if (index == 2) {
    getImgModel();
  } else {
    getModel();
  }
};
// 获取图像imglist
const modelType = ref(true);
const getImgModel = () => {
  copyModelList.value = [];
  modelType.value = false;
  modelList.value = [];
  api.chat
    .imgModel({
      userId: userId.value,
    })
    .then((res) => {
      modelType.value = true;
      if (res.returnCode == 200) {
        copyModelList.value = JSON.parse(JSON.stringify(res.data));
        modelList.value = groupedData(res.data);
        let type = copyModelList.value.some(
          (item) => item.model == model.value
        );
        if (!type) {
          model.value =
            copyModelList.value.length == 0 ? "" : copyModelList.value[0].model;
        }
      }
    })
    .catch((err) => {
      modelType.value = true;
    });
};
const closeTag = () => {
  model.value = null;
  getModel();
};
// 修改接口
const groupedData = (data) => {
  const groups = data.reduce((result, item) => {
    if (!result[item.platform_type]) {
      result[item.platform_type] = {
        name: item.platform_type,
        models: [],
      };
    }
    result[item.platform_type].models.push(item);
    return result;
  }, {});
  return Object.values(groups);
};
// 获取模型列表
const copyModelList = ref([]);
// 获取模型列表
const getModel = () => {
  modelType.value = false;
  modelList.value = [];
  copyModelList.value = [];
  let knowledgeId =
    chatType.value == "base"
      ? topicItem.value.knowledge_library_id
      : baseId.value;
  api.chat
    .chatModel({
      userId: userId.value,
      knowledgeId: knowledgeId,
      topicId: knowledgeId ? null : topicItem.value.sessionId,
    })
    .then((res) => {
      modelType.value = true;
      if (res.returnCode == 200) {
        copyModelList.value = JSON.parse(JSON.stringify(res.data));
        modelList.value = groupedData(res.data);
        let type = copyModelList.value.some(
          (item) => item.model == model.value
        );
        if (!type) {
          getDefauleModel();
        }
      }
    })
    .catch((err) => {
      modelType.value = true;
    });
};
// 结束上下文
const clearTalk = (name) => {
  if (name == "chat") {
    api.chat
      .chatClear({
        userId: userId.value,
        topicId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("login.login50"));
        }
      });
  } else {
    api.chat
      .baseClear({
        userId: userId.value,
        sessionId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("login.login50"));
        }
      });
  }
};
// 当前页面新对话 进行清除
const clearChatList = () => {
  chatList.value = [];
};
// 关闭文字编辑
const closeEdit = (item) => {
  item.editType = false;
};
// 保存对话
const saveMessage = (item) => {
  inputRef.value.saveMessage(item);
};
// 获取10选3
const getMessage = () => {
  api.chat.randomMessage().then((res) => {
    if (res.returnCode == 200) {
      messageList.value = res.data;
    }
  });
};
// 归档
const deleteSession = (e) => {
  if (e == 1) {
    deleteLoading.value = true;
    api.chat
      .sessionDelete({
        userId: userId.value,
        sessionInfo: [
          {
            sessionId: topicItem.value.sessionId,
            typeId: chatType.value == "chat" ? 1 : 0,
          },
        ],
        operationType: 0,
      })
      .then((res) => {
        deleteLoading.value = false;
        if (res.returnCode == 200) {
          message.success(t("base.base247"));
          store.commit("SET_MANGER", "base.base248");
          store.commit("SET_MENUACTIVE", {
            name: "lookAll",
            key: "/lookAll",
            type: 1,
            iconType: false,
            active: icon.lookAllMenu,
          });
          router.push({
            name: "lookAll",
            query: {
              type: 1,
            },
          });
        }
      })
      .catch((err) => {
        deleteLoading.value = false;
      });
  } else if (e == 3) {
    chatLikeTalk(0);
  } else if (e == 4) {
    chatLikeTalk(1);
  }
};
// 收藏区别调用
const likeFn = (id) => {
  if (chatType.value == "chat") {
    chatLikeTalk(id);
  } else {
    likeTalk(id);
  }
};
// chat收藏
const chatLikeTalk = (id) => {
  api.chat
    .chatCollect({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
      operationType: id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        topicItem.value.isCollected = id == 0 ? 1 : 0;
        message.success(id == 0 ? t("base.base220") : t("base.base15"));
      }
    });
};
// 话题收藏
const likeTalk = (id) => {
  api.chat
    .baseCollect({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
      operationType: id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        topicItem.value.isCollected = id == 0 ? 1 : 0;
        message.success(id == 0 ? t("base.base220") : t("base.base15"));
      }
    });
};
// 获取高度
const getDivHeight = () => {
  nextTick(() => {
    if (leftDiv.value && inputBottomDiv.value) {
      qaHeight.value =
        leftDiv.value.offsetHeight - inputBottomDiv.value.offsetHeight;
      if (inputRef.value) {
        inputRef.value.changeDivWidth();
      }
    }
  });
};
// 加减页数
const changeItemPage = (type) => {
  if (type && historyList.value.chatActive < historyList.value.answers.length) {
    historyList.value.chatActive++;
  }
  if (!type && historyList.value.chatActive > 1) {
    historyList.value.chatActive--;
  }
};
const getLoading = () => {
  return chatList.value.some((item) => item.loading === true);
};
// 发送消息
const sendMessage = (obj) => {
  if (getLoading()) {
    message.warning(t("base.base310"));
    return;
  }
  if (!obj.message) {
    message.warning(t("share.input1"));
    return;
  }
  if (obj.model != null) {
    model.value = obj.model;
  } else {
    if (obj.imageFlag == 0) {
      obj.model = model.value;
    } else {
      obj.pictureModelType = obj.pictureModelType
        ? obj.pictureModelType
        : model.value;
      model.value = obj.pictureModelType;
    }
  }
  if (!model.value && obj.pictureModelType == null) {
    message.warning(t("base.base249"));
    return;
  }
  obj.isKnowledgeOn =
    topicItem.value.isKnowledgeOn == 1 || tagActive.value == 0 ? true : false;
  obj.llmConfigurationDict = llmConfigurationDict.value;
  //加密时间
  let time = Math.round(new Date() / 1000);
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(
      JSON.stringify({
        ...obj,
        time: time,
      })
    ),
  };
  let chatObj = {
    question: obj.message,
    chatActive: 1,
    loading: false,
    answers: [
      {
        answerContent: "",
        thinkContent: "", //思考文本
        thinkTime: null, //思考时间
        model: obj.model,
        pluginInfo: [],
      },
    ],
    model: obj.model,
  };
  // changeRequestverify()
  chatList.value.push(chatObj);
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (model.value == "GPT-o1-preview") {
    scrollBottom();
    const currentChatItem = chatList.value[chatList.value.length - 1];
    currentChatItem.loading = true;
    store.commit("SET_TALK", {});
    pageId.value = 1;
    api.chat[chatType.value == "chat" ? "o1Chat" : "baseUpRegenerate"](
      obj
    ).then((res) => {
      currentChatItem.loading = false;
      if (res.returnCode == 200) {
        nextTick(() => {
          getMessage();
          getTalk();
        });
      } else if (res.returnCode == 423 || res.returnCode == 412) {
        router.push("/");
      } else {
        currentChatItem.answers[currentChatItem.chatActive - 1].answerContent =
          res.returnMessage;
        scrollBottom();
      }
    });
  } else {
    if (chatType.value == "chat") {
      sendFn(postObj, lang, "/openai/chat/v4/memory/chat");
    } else {
      sendFn(postObj, lang, "/openai/chat/v4/knowledge/chat");
    }
  }
};
const sendFn = async (postObj, lang, url) => {
  try {
    store.commit("SET_TALK", {});
    pageId.value = 1;
    const currentChatItem = chatList.value[chatList.value.length - 1];
    currentChatItem.loading = true;
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if ([500, 504].includes(response.status)) {
      const errorMap = {
        500: "base.base182",
        504: "base.base183",
      };
      message.warning(t(errorMap[response.status]));
      currentChatItem.answers[currentChatItem.chatActive - 1].answerContent = t(
        errorMap[response.status]
      );
      currentChatItem.loading = false;
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    let think = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      // 解析插件
      const plugRegex = /<swagger>\s*<start>(.*?)<end>\s*<\/swagger>/g;
      let plugMatch;
      while ((plugMatch = plugRegex.exec(rawData)) !== null) {
        currentChatItem.answers[currentChatItem.chatActive - 1].pluginInfo.push(
          JSON.parse(decryptApi(plugMatch[1]))
        );
      }
      // 解析 <think> 标签内的内容
      const thinkRegex = /<think>\s*<start>(.*?)<end>\s*<\/think>/g;
      let thinkMatch;
      let thinkArr = [];
      while ((thinkMatch = thinkRegex.exec(rawData)) !== null) {
        thinkArr.push(thinkMatch[1]);
      }
      // 解析 <text> 标签内的内容
      const textRegex = /<text>\s*<start>(.*?)<end>\s*<\/text>/g;
      let textMatch;
      let textArr = [];
      while ((textMatch = textRegex.exec(rawData)) !== null) {
        textArr.push(textMatch[1]);
      }
      const thinkData = thinkArr.map((data) => decryptApi(data));
      const textData = textArr.map((data) => decryptApi(data));
      think += thinkData.map((item) => item).join("");
      data += textData.map((item) => item).join("");
      currentChatItem.answers[currentChatItem.chatActive - 1].thinkContent =
        think;
      currentChatItem.answers[currentChatItem.chatActive - 1].answerContent =
        data;
      scrollBottom();
      if (done) {
        currentChatItem.loading = false;
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          nextTick(() => {
            getMessage();
            getTalk();
          });
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          currentChatItem.answers[
            currentChatItem.chatActive - 1
          ].answerContent = t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/");
          } else {
            currentChatItem.answers[
              currentChatItem.chatActive - 1
            ].answerContent = newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    const currentChatItem = chatList.value[chatList.value.length - 1];
    currentChatItem.loading = false;
    console.error("Request failed", err);
  }
};

const inpuBaseId = (id, type) => {
  llmNav.value = 0;
  baseId.value = id;
  if (type) {
    if (tagActive.value == 2) {
      getImgModel();
    } else {
      getModel();
    }
  }
};
const openBaseFile = (arr) => {
  if (
    (chatType.value == "chat" && baseId.value == null) ||
    (chatType.value == "base" && topicItem.value.knowledge_library_id == null)
  ) {
    message.warning(t("login.login52"));
    return;
  }
  saveList.value = arr;
  baseFileDialog.value = true;
};
// 保存筛选的文件
const saveBase = (arr) => {
  let fileList = [];
  let folderList = [];
  let attachmentSessionId = null;
  arr.forEach((item) => {
    if (item.fileName && item.fileType != 1 && item.fileType != 2) {
      fileList.push(item.id);
    } else if (item.folderName || item.fileType == 1) {
      folderList.push(item.id);
    } else if (item.folderName || item.fileType == 2) {
      attachmentSessionId = item.id;
    }
  });
  api.chat
    .attachmentAdd({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
      file_ids: fileList,
      folder_ids: folderList,
      attachmentSessionId: attachmentSessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        inputRef.value.getBaseFileIcon(topicItem.value.sessionId);
        baseFileDialog.value = false;
      }
    });
};
// 获取call
const getCall = () => {
  if (topicItem.value == null) return;
  if (chatType.value == "chat") {
    api.share
      .chatDetailQuery({
        userId: userId.value,
        topicId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          templateCall.value = res.templateCall;
          idea.value = res.idea;
        }
      });
  }
};
// 归档后分页为1
const deleteItem = () => {
  pageId.value = 1;
  getTalk();
};
// 加载分页
const talkLoad = () => {
  if (getLoading()) {
    message.warning(t("base.base310"));
    return;
  }
  pageId.value++;
  getTalk();
};
// 获取对话内容
const pageId = ref(1);
const perPage = ref(20);
const getTalk = () => {
  if (topicItem.value == null) return;
  if (chatType.value == "base") {
    let conversationIds = [];
    if (topicItem.value.conversations) {
      conversationIds = topicItem.value.conversations.map((item) => {
        return item.conversationId;
      });
    }
    api.share
      .baseDetailQuery({
        userId: userId.value,
        sessionId: topicItem.value.sessionId,
        conversationIds: conversationIds ? conversationIds : [],
        pageId: pageId.value,
        perPage: perPage.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.conversationInfo.length != 0) {
            res.data.conversationInfo.forEach((item) => {
              item.chatActive = 1;
              item.resonType = false;
              item.resonList = [];
              item.tipDialog = false;
              item.editType = false;
              item.loading = false;
              if (item.answers.length != 0) {
                item.answers.forEach((i) => {
                  i.langType = false;
                  i.langText = "";
                });
              }
            });
          }
          if (pageId.value == 1) {
            chatList.value = res.data.conversationInfo;
          } else {
            chatList.value = res.data.conversationInfo.concat(chatList.value);
          }
          if (!lookDialog.value) {
            rightType.value = chatList.value.length > 1 ? true : false;
          }
          scrollBottom();
        }
      });
  } else {
    api.share
      .chatDetailQuery({
        userId: userId.value,
        topicId: topicItem.value.sessionId,
        pageId: pageId.value,
        perPage: perPage.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          res.data.forEach((item) => {
            item.chatActive = 1;
            item.resonType = false;
            item.resonList = [];
            item.tipDialog = false;
            item.editType = false;
            item.loading = false;
            if (item.answers.length != 0) {
              item.answers.forEach((i) => {
                i.langType = false;
                i.langText = "";
              });
            }
          });
          if (pageId.value == 1) {
            chatList.value = res.data;
          } else {
            chatList.value = res.data.concat(chatList.value);
          }
          templateCall.value = res.templateCall;
          idea.value = res.idea;
          if (!lookDialog.value) {
            rightType.value = chatList.value.length > 1 ? true : false;
          }
        }
      });
  }
};
// 获取默认model
const getDefauleModel = () => {
  api.chat.modelGet({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      let type = copyModelList.value.some((item) => item.model == res.data);
      if (type) {
        model.value = res.data;
        getDefaulLlm();
      } else {
        model.value = "";
      }
    }
  });
};
// 打开历史
const openHistory = (data) => {
  historyList.value = data;
  historyDialog.value = true;
};
const scrollBottom = () => {
  if (pageId.value != 1) return;
  nextTick(() => {
    if (containerRef.value) {
      containerRef.value.scrollTop = containerRef.value.scrollHeight;
    }
  });
};
const handleClick = (e) => {
  e.preventDefault();
};
// 打开请求验证
const openCap = () => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: topicItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        seleteUserInfo.value = res.data.userInfo.filter(
          (item) => item.type !== 2 && item.ownerName
        );
        capDialog.value = true;
      }
    });
};
// 关闭验证
const closeCap = () => {
  capDialog.value = false;
};
// 保存验证
const saveCap = (treeData, peopleSel, leftNotes) => {
  if (treeData.length > 6) {
    message.warning(t("base.base250"));
    return;
  }
  let verifyNums = peopleSel
    .map((ownerId) => {
      const item = seleteUserInfo.value.find(
        (item) => item.ownerId === ownerId
      );
      return item ? { ownerId: item.ownerId, ownerType: item.ownerType } : null;
    })
    .filter((item) => item !== null);
  requestverifyLoading.value = true;
  api.share
    .postRequestverify({
      userId: userId.value,
      conversationIds: treeData,
      verifyNums: verifyNums,
      sessionName: topicItem.value.sessionName,
      sessionId: topicItem.value.sessionId,
      leftNotes: leftNotes,
      onlyQA: false,
    })
    .then((res) => {
      requestverifyLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base214"));
        capDialog.value = false;
      }
    });
};

// qa打开文件预览
const talkOpenFile = (item, list) => {
  qaType.value = true;
  if (item.fileType == 2) {
    openTalk({ sessionId: item.id });
    return;
  }
  fileItem.value = item;
  chunkList.value = list;
  historyDialog.value = false;
  rightType.value = false;
  if (setRef.value) {
    for (let i = 0; i < setRef.value.length; i++) {
      setRef.value[i].setCloseCap();
    }
  }
  if (item.type == 3 || item.knowledge_type == 3) {
    lookType.value = "sql";
  } else {
    lookType.value = "file";
  }
  lookDialog.value = true;
};
// 打开文件预览
const openFileView = (item, list) => {
  qaType.value = false;
  if (item.fileType == 2) {
    openTalk({ sessionId: item.id });
    return;
  }
  fileItem.value = item;
  chunkList.value = list;
  historyDialog.value = false;
  rightType.value = false;
  if (setRef.value) {
    for (let i = 0; i < setRef.value.length; i++) {
      setRef.value[i].setCloseCap();
    }
  }
  if (item.type == 3 || item.knowledge_type == 3) {
    lookType.value = "sql";
  } else {
    lookType.value = "file";
  }
  lookDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
</script>

<style lang="less" scoped>
.chatContent {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.talkDiv {
  overflow: auto;
  padding: 0px 10px;
}

.chatIndex {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  position: relative;
}

.fileIndex {
  width: 467px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  position: relative;
  margin-right: 20px;
}

.marginLeft10 {
  margin-left: 10px;
}

.tipStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tipDiv {
  margin-bottom: 12px;
}

.width1010 {
  min-width: 1010px;
  width: calc(100% - 510px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 60px);
}

.width10101 {
  min-width: 1010px;
  width: calc(100% - 491px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
}

.width10102 {
  min-width: 632px;
  width: calc(100% - 888px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
}

.width10103 {
  width: calc(100% - 60px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
}

.rightIcon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.rightIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.expand-transition-enter-active,
.expand-transition-leave-active {
  transition: all 0.2s ease;
}

.expand-transition-enter-from,
.expand-transition-leave-to {
  transform: translateX(10%);
  opacity: 0;
}

.expand-transition-enter-to,
.expand-transition-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.treeSelTip {
  color: #8a8a8a;
  font-weight: 500;
  font-size: 17px;
  margin-right: 10px;
}

.treeText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
}

.fileViewDiv {
  width: calc(100% - 547px);
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}
.sqlViewDiv {
  width: calc(100% - 547px);
  height: 100%;
  border-radius: 30px;
  overflow: auto;
  background-color: #fefefe;
}
.clearText {
  color: #0256ff;
}
.messageView {
  margin-bottom: 52px;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.top50 {
  margin-top: 50px;
}
.top50::after {
  content: "";
  height: 50px;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  top: -1px;
  z-index: 10;
}

.ideaDiv {
  background-color: #ffffff;
  border: 2px solid #edeef6;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin-bottom: 10px;
}
:deep(.el-button.is-disabled, .el-button.is-disabled:hover) {
  background-color: none;
  color: none;
}
.dropText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding-left: 10px;
}
:deep(.el-tooltip__trigger) {
  font-size: 16px;
  font-weight: 400;
}

.baseMlSvg {
  width: 20px;
}
.baseMlDiv {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #ffffff;
  z-index: 10;
}
.start143 {
  height: 143px;
}
.start67 {
  height: 67px;
}
.dragStartType {
  position: absolute;
  width: calc(100% - 30px);
  bottom: 1px;
  background-color: #edeef6;
  border-radius: 16px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}
.drapBottom {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}
.drapBottomText {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; /* 强制换行 */
}
.modelListView {
  z-index: 8;
  position: absolute;
  left: 30px;
  top: 30px;
}
</style>
