import { createApp } from 'vue'
import "./assets/icons";
// 引入自定义主题样式
import './styles/index.scss'
// 系统全局变量
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './utils/rem'
import i18n from './utils/i18n'
import SvgIcon from './components/SvgIcon/index.vue';
import permissionPlugin from './plugins/permission';
import AvatarGroup from './components/avatarGroup/index.vue'
import addUser from './components/addUser/index.vue'
import resoureGroup from './components/resoureGroup/index.vue'
import addGroup from './components/addGroup/index.vue'
import AvatarGroupTwo from './components/avatarGroupTwo/index.vue'
import avatarGroupName from './components/avatarGroupName/index.vue'
import botQuestion from './components/botQuestion/index.vue'
import AvatarName from './components/avatarName/index.vue'
import baseName from './components/baseName/index.vue'
import Pagination from './components/pagination/index.vue'
import markdownChat from './components/message/index.vue'
import Export from './components/export/index.vue'
import loading from './components/loading/index.vue'
import fileSizePlugin from './plugins/formatFileSize';
import loadSel from './utils/loadSel';
import { debounce } from './utils/utils';
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 全局组件
app.component('AvatarGroup', AvatarGroup)
app.component('addUser', addUser)
app.component('addGroup', addGroup)
app.component('markdownChat', markdownChat)
app.component('resoureGroup', resoureGroup)
app.component('AvatarGroupTwo', AvatarGroupTwo)
app.component('avatarGroupName', avatarGroupName)
app.component('AvatarName', AvatarName)
app.component('botQuestion', botQuestion)
app.component('baseName', baseName)
app.component('Pagination', Pagination)
app.component('loading', loading)
app.component('Export', Export)
app.component('svg-icon', SvgIcon)
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
            registration.unregister().then(() => {
                console.log('Service Worker 已成功注销');
            }).catch((error) => {
                console.error('Service Worker 注销失败:', error);
            });
        }
    });
}
if (
    process.env.VUE_APP_TYPE === "Formal"
) {
    console.log = function () { };
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback: any) {
        callback = debounce(callback, 200);
        super(callback);
    }
}
app.directive('selectLoadMore', loadSel);
// 全局方法
app.use(fileSizePlugin);
app.use(permissionPlugin)
app.use(ElementPlus).use(store).use(i18n).use(router).mount('#app')
