import axios from "../server";
export default {

    // 我的收藏
    getMyCollectionApi(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/user-collected-conversation/query`,
            method: "POST",
            data,
        });
    },
    // 我可验证
    getVerifyApi(data: any) {
        return axios({
            url: `/custom-library/canverify`,
            method: "POST",
            data,
        });
    },
    // 查看当前验证
    getVerifyStatusApi(data: any) {
        return axios({
            url: `/custom-library/verify/view`,
            method: "POST",
            data,
        });
    },
    // 我发起的验证
    getInitiateVerifyApi(data: any) {
        return axios({
            url: `/custom-library/verify/mine/list`,
            method: "POST",
            data,
        });
    },
    // 一键更新
    getUpdateApi(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/answer`,
            method: "POST",
            data,
        });
    },
    // 权威话题查看
    getVerifyQuertApi(data: any) {
        return axios({
            url: `/custom-library/verify/all/query`,
            method: "POST",
            data,
        });
    },
    // 知识库话题详情
    baseDetailQuery(data: any) {
        return axios({
            url: `knowledge/session/session/query`,
            method: "POST",
            data,
        });
    },
    // 对话所有历史
    chatDetailQuery(data: any) {
        return axios({
            url: `chat/session/session/query`,
            method: "POST",
            data,
        });
    },
    // 发起验证
    postRequestverify(data: any) {
        return axios({
            url: `custom-library/requestverify`,
            method: "POST",
            data,
        });
    },
    // 否认
    recordReject(data: any) {
        return axios({
            url: `custom-library/verify/record/reject`,
            method: "POST",
            data,
        });
    },
    // 保存验证
    recordSave(data: any) {
        return axios({
            url: `custom-library/verify/record/save`,
            method: "POST",
            data,
        });
    },
    // 发布
    verifyPublish(data: any) {
        return axios({
            url: `custom-library/verify/publish`,
            method: "POST",
            data,
        });
    },
    // 获取右侧
    recordAll(data: any) {
        return axios({
            url: `custom-library/verify/record/list/all`,
            method: "POST",
            data,
        });
    },
    continueVerify(data: any) {
        return axios({
            url: `custom-library/verify/continue`,
            method: "POST",
            data,
        });
    },
    conflictVerify(data: any) {
        return axios({
            url: `custom-library/verify/conflict`,
            method: "POST",
            data,
        });
    },
    verifyStatus(data: any) {
        return axios({
            url: `custom-library/verify/status`,
            method: "POST",
            data,
        });
    },
    
    
}