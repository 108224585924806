<script setup>
import { InboxOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import { message, UploadDragger } from "ant-design-vue";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps({
  showExport: Boolean,
});
const show = ref(props.showExport);
watch(
  () => props.showExport,
  (val) => {
    show.value = val;
  }
);
const emit = defineEmits(["handleCloneExport"]);
// 关闭弹窗
const handleCancel = () => {
  show.value = !show.value;
  emit("handleCloneExport");
};
// 下载模板
const fileDownloadExport = () => {
  const imageUrl = "/openai/user-management/example-file/download";
  const a = document.createElement("a");
  a.href = imageUrl;
  a.download = "downloaded-image.jpg";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  show.value = !show.value;
  emit("handleCloneExport");
};
// 上传参数
const failes = ref([]);
const fileList = ref([]);
const failType = ref(false);
const form = ref({
  pageId: 1,
});
// 移除文件
const removeUpload = () => {
  fileList.value = [];
};
const customRequest = () => {};
// 上传钩子
const beforeUpload = (file) => {
  fileList.value = [file];
  let fileName = file.name
    .substring(file.name.lastIndexOf(".") + 1)
    .replace(" ", "");
  if (fileName !== "xlsx") {
    message.warning(t("manage.userForm.user91"));
    fileList.value = [];
    return false;
  }
};
// 上传按钮
const handleChange = async () => {
  if (fileList.value.length == 0) {
    message.error(t("manage.userForm.user92"));
  } else {
    await api.manage.testFile(fileList.value, {}).then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.userForm.user93"));
        fileList.value = [];
        form.value.pageId = 1;
        emit("handleCloneExport");
      } else if (res.returnCode == 202) {
        failes.value = res.data;
        failType.value = true;
        fileList.value = [];
        form.value.pageId = 1;
        show.value = !show.value;
      } else {
        show.value = !show.value;
        emit("handleCloneExport");
      }
    });
  }
  fileList.value = [];
};
// 关闭报错弹窗
const changeError = () => {
  failType.value = false;
  emit("handleCloneExport");
};
</script>

<template>
  <div>
    <!-- 批量导入/导出 -->
    <el-dialog
      :z-index="100"
      destroy-on-close
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ t("manage.userForm.user84") }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div class="comtent">
        <UploadDragger
          :customRequest="customRequest"
          name="file"
          :multiple="true"
          action="#"
          :before-upload="beforeUpload"
          :file-list="fileList"
          @remove="removeUpload"
          class="upFileDragger"
        >
          <div>
            <InboxOutlined class="upFileIcon" />
          </div>
          <div class="upFileTip">
            {{ t("manage.userForm.user94") }}
          </div>
          <div class="upFileTip">
            {{ t("manage.userForm.user95") }}
          </div>
        </UploadDragger>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div @click="fileDownloadExport" class="cancel">
            {{ t("manage.userForm.user96") }}
          </div>
          <div @click="handleChange" class="confirm">
            {{ t("manage.userForm.user97") }}
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="failType"
      destroy-on-close
      :z-index="101"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
      :title="t('manage.userForm.user75')"
    >
      <el-table :data="failes" style="width: 100%" :height="300">
        <el-table-column prop="name" :label="t('base.base126')" />
        <el-table-column prop="email" :label="t('header.header14')" />
        <el-table-column prop="error" :label="t('gpt.index77')" />
      </el-table>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="changeError" class="confirm">{{
            t("bot.bot49")
          }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.confirm {
  color: #fff;
  background-color: #3376ff;
  margin-left: 10px;
  padding: 8px 20px;
  border-radius: 16px;
  font-weight: 400;
  cursor: pointer;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.nameYc {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.ant-upload.ant-upload-drag) {
  height: 170px;
  margin: 20px;
  width: calc(100% - 40px);
  margin-top: 0px;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  padding: 30px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.upFileTip {
  font-size: 16px;
  font-weight: 400;
  color: #0256ff;
}

.upFileIcon {
  font-size: 48px;
  margin-bottom: 10px;
  color: #0256ff;
}

.comtent {
  margin-top: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
</style>
