<template>
  <div class="addContent">
    <div class="titleFlex">
      <div class="addTitle">
        {{ editType == "detail" ? t("base.base338") : t("base.base20") }}
      </div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeAdd" />
    </div>
    <!-- 头 -->
    <div class="topDiv">
      <div class="logoDiv">
        <img :src="getBaseImg" alt="" class="baseIconLogo" />
        <img :src="getImgType" alt="" class="imgType" />
      </div>
      <div class="nameDIv">
        <div class="flexCenter">
          <el-tooltip
            popper-class="toolTip"
            :content="props.editObj.knowledge_name"
          >
            <div class="forBaseItemTitle">
              {{ props.editObj.knowledge_name }}
            </div>
          </el-tooltip>
          <div class="modeDiv">
            {{
              props.editObj.knowledge_type != 3
                ? t("base.base351")
                : t("base.base360")
            }}
            {{ props.editObj.resourcetype == 1 ? " · SharePoint" : "" }}
          </div>
        </div>
        <div class="nameTip">
          {{ props.editObj.documentNum }}
          {{ t("base.base352") }}
          {{ props.editObj.conversationNum }}
          {{ t("base.base353") }}
          {{ props.editObj.userNum }}
          {{ t("base.base354") }}
        </div>
      </div>
    </div>
    <!-- 导航 -->
    <div class="custom-style">
      <el-segmented v-model="navActive" :options="navList" id="value">
        <template #default="scope">
          <div class="flex flex-col items-center gap-2 p-2">
            <div>{{ t(scope.item.label) }}</div>
          </div>
        </template>
      </el-segmented>
    </div>
    <div class="addBody">
      <!-- 第一步内容 -->
      <template v-if="navActive == 0">
        <template v-if="editType == 'edit'">
          <!-- 知识库名称 -->
          <div>
            <span class="addLabel nameLabel">{{ t("base.base21") }}</span>
          </div>
          <el-input
            class="itemInput marginBtomm20px"
            maxlength="20"
            show-word-limit
            :placeholder="t('base.base22')"
            v-model="form.name"
          ></el-input>
          <!-- 描述 -->
          <div class="addLabel">{{ t("base.base23") }}</div>
          <el-input
            v-model="form.desc"
            class="textInput marginBtomm20px"
            maxlength="100"
            show-word-limit
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 5 }"
            :placeholder="t('base.base24')"
          ></el-input>
          <!-- 头像 -->
          <div class="marginBtomm20px addLabel">{{ t("base.base28") }}</div>
          <div class="flexCenter marginBtomm20px">
            <el-tooltip popper-class="toolTip" :content="t('base.base302')">
              <uploadIndex type="img" @beforeAvatarUpload="beforeAvatarUpload">
                <div :class="form.image_path ? 'txCenter' : 'step2Img'">
                  <img
                    v-if="form.imageName == null || form.imageName == ''"
                    :src="icon.baseBImg"
                    alt=""
                    class="stepImgIcon"
                  />
                  <img
                    v-else
                    :src="'/openai/chat/v4/image/get/' + form.imageName"
                    alt=""
                    class="stepImgIcons"
                  />
                </div>
              </uploadIndex>
            </el-tooltip>
            <div class="step2RightImg">
              <div class="pa">
                <img
                  :src="icon.leftReson"
                  v-if="imgList.length > 2"
                  alt=""
                  class="leftResonIcon"
                  @click="changeLeftReson(resonOne)"
                />
                <div class="imgFlex" ref="resonOne">
                  <div class="getImgLoading" v-if="getImgLoading">
                    <span class="loader"></span>
                  </div>
                  <div
                    v-for="(item, index) in imgList"
                    :key="item"
                    class="itemImgDiv"
                  >
                    <img
                      @click="changeImg(item, index)"
                      class="itemImg"
                      :src="item.url"
                      alt=""
                    />
                    <img
                      :src="icon.imgYes"
                      alt=""
                      v-if="imgActive == index"
                      class="imgYes"
                    />
                  </div>
                </div>
                <img
                  :src="icon.rightReson"
                  v-if="imgList.length > 2"
                  alt=""
                  class="rightResonIcon"
                  @click="changeRightReson(resonOne)"
                />
              </div>
              <el-button
                class="stepImgAi"
                @click="addImg"
                :loading="imgLoading"
              >
                <div class="aiImgIcon">
                  <img :src="icon.imgAi" alt="" class="wenshengtuImg" />
                </div>
                <div class="aiImgTitle">{{ t("base.base29") }}</div>
              </el-button>
            </div>
          </div>
        </template>
        <!-- 描述 -->
        <template v-if="editType == 'detail'">
          <div class="jsText">{{ t("base.base23") }}</div>
          <el-tooltip
            :content="
              props.editObj.knowledge_desc
                ? props.editObj.knowledge_desc
                : t('base.base363')
            "
            popper-class="toolTip"
          >
            <div class="descText">
              {{
                props.editObj.knowledge_desc
                  ? props.editObj.knowledge_desc
                  : t("base.base363")
              }}
            </div>
          </el-tooltip>
        </template>
        <!--  -->
        <div class="jsText">{{ t("base.base25") }}</div>
        <div
          v-for="(item, index) in upTypeList"
          :key="index"
          :class="
            editType == 'detail' ? 'bottomFileActive' : 'bottomFileActiveEdit'
          "
          @click="changeFile(item)"
        >
          <div class="flexCenter">
            <img class="fileImg" :src="item.icon" alt="" />
            <div class="itemTextActive">
              {{ t(item.name) }}
            </div>
          </div>
          <el-tooltip :content="t(item.tip)" popper-class="toolTip">
            <div class="fileTipActive">
              {{ t(item.tip) }}
            </div>
          </el-tooltip>
        </div>
      </template>
      <!-- 第二步 -->
      <step2
        v-if="navActive == 1"
        :type="props.editObj.knowledge_type"
        :addForm="form"
        :disabled="editType == 'edit' ? false : true"
        @sendForm="sendForm"
      ></step2>
      <!-- 第三步 -->
      <template v-if="navActive == 2">
        <!-- 添加用户 -->
        <div class="marginBtomm20px flexBetweenAdd">
          <span class="addLabel">{{ t("base.base32") }} </span>
          <el-button
            class="addUserBtn"
            @click="addUser"
            v-if="editType == 'edit'"
            >{{ t("base.base33") }}</el-button
          >
        </div>
        <el-row class="rightTable">
          <el-col :span="editType != 'edit' ? 12 : 10" class="tableNmae">{{
            t("base.base34")
          }}</el-col>
          <el-col :span="editType != 'edit' ? 12 : 8" class="tableNmae">
            {{ t("base.base35") }}
            <el-tooltip
              popper-class="toolTip"
              raw-content
              :content="t('base.base288')"
            >
              <el-icon class="tipIcon"><Warning /></el-icon>
            </el-tooltip>
          </el-col>
          <el-col :span="6" v-if="editType == 'edit'">{{
            t("base.base36")
          }}</el-col>
        </el-row>
        <el-row
          class="tableItem"
          v-for="(item, index) in form.shareUserIds"
          :key="item.id"
        >
          <el-col
            :span="editType != 'edit' ? 12 : 10"
            class="colFlex"
            v-if="item.name"
          >
            <div class="userImg" :style="'background-color:' + item.color">
              {{ item.name?.slice(0, 1) }}
            </div>
            <span class="tableTitleNmae">{{ item.name }}</span>
          </el-col>
          <el-col :span="editType != 'edit' ? 12 : 10" class="colFlex" v-else>
            <span class="tableTitleNmae">-</span>
          </el-col>
          <el-col :span="editType != 'edit' ? 12 : 8" class="colFlex">
            <div class="tableRoleType" v-if="item.type == 0">
              <div class="tableLogo">{{ t("base.base37").slice(0, 1) }}</div>
              <div class="tableRole">{{ t("base.base38") }}</div>
            </div>
            <div class="tableRoleType1" v-if="item.type == 1">
              <div class="tableLogo1">{{ t("base.base39") }}</div>
              <div class="tableRole1">{{ t("base.base40") }}</div>
            </div>
            <div class="tableRole" v-if="item.type == 2">
              {{ t("base.base41") }}
            </div>
          </el-col>
          <el-col :span="6" class="colFlex" v-if="editType == 'edit'">
            <el-button
              class="deleUserBtn"
              @click="deleteUser(item, index)"
              v-if="item.type != 0"
            >
              {{ t("base.base42") }}
            </el-button>
            <el-dropdown
              @command="changeUser($event, item)"
              v-if="item.type != 0 && item.ownerType != 2"
            >
              <el-icon class="moreUser">
                <MoreFilled />
              </el-icon>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-if="item.type == 1" :command="2">
                    <span class="dropText">{{ t("base.base43") }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item :command="1" v-if="item.type == 2">
                    <span class="dropText">{{ t("base.base44") }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
        </el-row>
      </template>
    </div>
    <div class="addBottomBtn">
      <el-button
        class="closeBtn"
        @click="closeDetail"
        v-if="editType == 'detail'"
        >{{ t("base.base45") }}</el-button
      >
      <el-button
        class="closeBtn"
        @click="closeEdit"
        v-if="editType == 'edit'"
        >{{ t("base.base113") }}</el-button
      >
      <el-button
        class="nextBtn"
        @click="save"
        v-if="editObj.knowledge_role_id == 0 && editType == 'detail'"
      >
        {{ t("base.base5") }}
      </el-button>
      <el-button
        class="nextBtn"
        v-if="editObj.knowledge_role_id == 0 && editType == 'edit'"
        :loading="props.addLoading"
        @click="saveEdit"
      >
        {{ t("base.base47") }}
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
  <userView
    v-if="addDialog"
    type="base"
    :userList="userList"
    :userObj="userObj"
    @closeUser="addDialog = false"
    @saveUser="saveUser"
  ></userView>
  <spView
    v-if="spDialog"
    @closeSp="closeSp"
    @saveSp="saveSp"
    @changeId="changeId"
    :form="form"
    :type="1"
  ></spView>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import userView from "./user.vue";
import step2 from "./step2.vue";
import { message } from "ant-design-vue";
import uploadIndex from "@/components/upload/index.vue";
import spView from "./spView.vue";
import api from "@/api";
const { t } = useI18n();
const props = defineProps(["editObj", "addLoading"]);
const emit = defineEmits(["closeDetail", "saveEdit"]);
const store = useStore();
const navActive = ref(0);
const navList = ref([
  {
    label: "bot.base153",
    value: 0,
  },
  {
    label: "bot.base163",
    value: 1,
  },
  {
    label: "bot.base155",
    value: 2,
  },
]);
const upTypeList = ref([]);
const userId = ref(store.state.userId);
const form = ref({
  userId: store.state.userId,
  name: "", //微模型名称
  desc: "", //微模型描述
  type: 2, //微模型类型
  shareUserIds: [], //知识库成员
  imageName: "",
  site: {
    siteurl: "",
    fileIds: [],
    folderIds: [],
    ParentfolderIds: [],
  },
  resourcetype: 0, //知识来源
  segment: {
    type: 1, //1自动 2自定义
    segmentIdentifier: "", //分段标识符 自动传null 自定义给值必传 文档模式
    maximumSegmentLength: 0, //分段最大长度 自动传null 自定义给值必传 文档模式
    segmentOverlapRatio: 0, //分段重叠比例 自动传null 自定义给值必传 文档模式
    replaceType: false, //替换掉连续的空格、换行符和制表符 true开启 false关闭 自动传null 自定义给值必传 文档模式
    deleteType: false, //删除所有 URL 和电子邮件地址 true开启 false关闭 自动传null 自定义给值必传 文档模式
    maximumDescriptionLength: 0, //表格/文档最大描述长度 表格模式传  自动分段传null 自定义必传
    maximumReQueryCount: 0, //NL2SQL最大重查询次数 表格模式传  自动分段传null 自定义必传
  },
  //检索设置
  retrieval: {
    type: 3, //1向量检索 2全文检索 3混合检索
    semantic: 0.5, //语义0-1
    keywords: 0.5, //关键词 0-1
    topK: 10, //Top K
    score: 0.6, //Score 阈值0-1
    scoreType: true, //是否开启Score 阈值 true开启 false关闭
  },
  //平台数组
  resourcePlatform: [],
  promptId: -1,
});
const resonOne = ref(null);
onMounted(() => {
  if (props.editObj.knowledge_type == 2) {
    if (props.editObj.resourcetype == 0) {
      upTypeList.value = [
        {
          name: "base.base48",
          icon: icon.addFile,
          acIcon: icon.addFileAc,
          tip: "base.base49",
          value: "file",
        },
      ];
    } else {
      upTypeList.value = [
        {
          name: "base.base332",
          icon: icon.spNo,
          acIcon: icon.spAc,
          tip: "base.base325",
          value: "sp",
        },
      ];
    }
  } else {
    upTypeList.value = [
      {
        name: "base.base48",
        icon: icon.addFile,
        acIcon: icon.addFileAc,
        tip: "base.base362",
        value: "file",
      },
    ];
  }
  getForm();
});
// 切换
const spDialog = ref(false);
const changeFile = (item) => {
  if (item.value == "sp") {
    spDialog.value = true;
  }
};
// 子级id
const changeId = (arr) => {
  form.value.site.ParentfolderIds = arr;
};
// 关闭sp
const closeSp = () => {
  spDialog.value = false;
};
// 保存sp
const saveSp = (site) => {
  form.value.site = site;
  spDialog.value = false;
};
const getForm = () => {
  let obj = JSON.parse(JSON.stringify(props.editObj));
  form.value.name = obj.knowledge_name;
  form.value.desc = obj.knowledge_desc;
  form.value.type = obj.knowledge_type;
  form.value.imageName = obj.image_path;
  form.value.resourcetype = obj.resourcetype;
  form.value.site = obj.site;
  form.value.segment = obj.segment;
  form.value.retrieval = obj.retrieval;
  form.value.resourcePlatform = obj.resourcePlatform;
  form.value.promptId = obj.prompt_library_id;
  form.value.prompt_value = obj.prompt_value
  if (obj.userInfo.length != 0) {
    obj.userInfo.forEach((item) => {
      item.userId = item.ownerId;
      item.id = item.ownerType == 2 ? item.ownerId : Number(item.ownerId);
      item.type = item.type; //0 拥有者 1 创作者 2 普通用户
      item.userName = item.ownerName;
      item.name = item.ownerName;
      item.ownerType = item.ownerType; //0个人 1部门 2群组
      item.color = item.color;
    });
    form.value.shareUserIds = obj.userInfo;
  }
};
// 返回知识库icon
const getBaseImg = computed(() => {
  if (props.editObj.image_path == null || !props.editObj.image_path) {
    if (props.editObj.resourcetype == 1) {
      return icon.baseSpLogo;
    } else {
      return icon.baseLogo;
    }
  } else {
    return "/openai/chat/v4/image/get/" + props.editObj.image_path;
  }
});
// 根据类型返回icon
const getImgType = computed(() => {
  if (props.editObj.knowledge_type == 3) {
    return icon.excelType;
  } else {
    return icon.wordType;
  }
});
const imgLoading = ref(false);
const imgList = ref([]);
const imgActive = ref(null);
const getImgLoading = ref(false);
// 微模型上传头像
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      getImgLoading.value = true;
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          // 创建 Blob 对象
          const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
          // 生成 URL
          const imageUrl = URL.createObjectURL(blob);
          getImgLoading.value = false;
          form.value.imageName = res.data;
          console.log(res.data);
          imgList.value.push({
            id: res.data,
            url: imageUrl,
          });
          imgActive.value = imgList.value.length - 1;
        })
        .catch((error) => console.log(error));
    }
  });
}; // 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 选择微模型头像
const changeImg = (item, index) => {
  imgActive.value = index;
  form.value.imageName = item.id;
};
// ai生成图片
const addImg = () => {
  imgLoading.value = true;
  api.base
    .imageGenerate({
      userId: userId.value,
      message: form.value.name,
    })
    .then((res) => {
      imgLoading.value = false;
      if (res.returnCode == 200) {
        getImgLoading.value = true;
        fetch(`/openai/chat/v4/image/get/${res.data}`)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            // 创建 Blob 对象
            const blob = new Blob([data], { type: "image/png" }); // 根据实际返回的图片类型设置 MIME 类型
            // 生成 URL
            const imageUrl = URL.createObjectURL(blob);
            getImgLoading.value = false;
            imgList.value.push({
              id: res.data,
              url: imageUrl,
            });
            imgActive.value = imgList.value.length - 1;
            form.value.imageName = res.data;
          })
          .catch((error) => (getImgLoading.value = false));
      }
    })
    .catch((err) => {
      imgLoading.value = false;
    });
};
// 取消
const closeAdd = () => {
  emit("closeDetail");
};
const closeDetail = () => {
  emit("closeDetail");
};
// 移除成员
const deleteUser = (item, index) => {
  form.value.shareUserIds.splice(index, 1);
};
// 更改成员权限
const changeUser = (e, item) => {
  item.type = e;
};
//添加成员
const userList = ref([]);
const userObj = ref({});
const addDialog = ref(false);
// 确定保存
const saveUser = (arr) => {
  const index = arr.findIndex((user) => user.id === userId.value);
  if (index == -1) {
    arr.unshift({
      userId: userId.value,
      id: userId.value,
      type: 0, //0 拥有者 1 创作者 2 普通用户
      userName: store.state.userName,
      name: store.state.userName,
      ownerType: 0, //0个人 1部门 2群组
      color: store.state.color,
    });
  }
  // 去重
  const seenIds = new Set();
  form.value.shareUserIds = arr.filter((item) => {
    const id = item.id;
    if (!seenIds.has(id)) {
      seenIds.add(id);
      return true;
    }
    return false;
  });
  addDialog.value = false;
};
const addUser = () => {
  // 判断部门 群组  人
  // 使用reduce方法将ownerType为0，1，2的对象分别筛选出来
  const result = form.value.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    if (item.ownerType == 0) {
      item.userId = Number(item.id);
    }
    if (item.ownerType == 1) {
      item.departmentId = Number(item.id);
    }
    if (item.ownerType == 2) {
      item.groupId = item.id;
    }
    acc[ownerType].push(item);
    return acc;
  }, {});

  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  userObj.value = userList.value.userList.filter((item) => item.type == 0);
  addDialog.value = true;
};
// 第二步返回的form
const sendForm = (obj) => {
  let newObj = JSON.parse(JSON.stringify(obj));
  form.value = { ...form.value, ...newObj };
};
// 保存
const editType = ref("detail");
const save = () => {
  editType.value = "edit";
};
const getNextDisabled = () => {
  if (!form.value.name) {
    message.warning(t("base.base22"));
    return false;
  }

  // 检查 segment 部分
  if (form.value.segment.type == 2) {
    if (form.value.type == 2) {
      if (!form.value.segment.segmentIdentifier) {
        message.warning(t("login.login59") + t("gpt.index19"));
        return false;
      }
      if (!form.value.segment.maximumSegmentLength) {
        message.warning(t("login.login59") + t("gpt.index20"));
        return false;
      }
      if (!form.value.segment.segmentOverlapRatio) {
        message.warning(t("login.login59") + t("gpt.index21"));
        return false;
      }
    } else {
      if (!form.value.segment.maximumDescriptionLength) {
        message.warning(t("login.login59") + t("gpt.index25"));
        return false;
      }
      if (!form.value.segment.maximumReQueryCount) {
        message.warning(t("login.login59") + t("gpt.index26"));
        return false;
      }
    }
  }

  // 检查 retrieval 部分
  const retrieval = form.value.retrieval;
  if (retrieval.type == 1 || retrieval.type == 2) {
    if (!retrieval.topK) {
      message.warning(t("login.login59") + "Top K");
      return false;
    }
    if (retrieval.scoreType && !retrieval.score) {
      message.warning(t("login.login59") + t("gpt.index32"));
      return false;
    }
  } else if (retrieval.type == 3) {
    if (!retrieval.topK) {
      message.warning(t("login.login59") + "Top K");
      return false;
    }
    if (!retrieval.semantic) {
      message.warning(t("login.login59") + t("gpt.index30"));
      return false;
    }
    if (!retrieval.keywords) {
      message.warning(t("login.login59") + t("gpt.index31"));
      return false;
    }
    if (retrieval.scoreType && !retrieval.score) {
      message.warning(t("login.login59") + t("gpt.index32"));
      return false;
    }
  }

  // 检查 resourcePlatform 部分
  if (form.value.resourcePlatform.length == 0) {
    message.warning(t("base.base52"));
    return false;
  } else {
    for (let item of form.value.resourcePlatform) {
      if (!item.embedding) {
        message.warning(t("base.base268") + item.value + t("gpt.index34"));
        return false;
      }
      if (!item.ocr) {
        message.warning(t("base.base268") + item.value + t("gpt.index35"));
        return false;
      }
      if (!item.model) {
        message.warning(t("base.base268") + item.value + t("gpt.index36"));
        return false;
      }
    }
  }

  // 如果所有校验通过，返回 true
  return true;
};

// 修改 saveEdit 函数
const saveEdit = () => {
  if (getNextDisabled()) {
    emit("saveEdit", form.value);
  }
};
// 放弃保存
const closeEdit = () => {
  getForm();
  editType.value = "detail";
  navActive.value = 0;
};
</script>

<style lang="less" scoped>
.addClose {
  width: 14px;
  cursor: pointer;
}
.addBody {
  max-height: 400px;
  overflow: auto;
  padding: 20px 0px;
}
.fileTipActive {
  font-size: 16px;
  color: #898989;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.itemTextActive {
  font-size: 18px;
  color: #696969;
  font-weight: 400;
}
.fileImg {
  width: 23px;
  margin-right: 6px;
}
.descText {
  background-color: #f6f6f6;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 18px;
  color: #696969;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 17px;
}
.jsText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-bottom: 12px;
}
.topDiv {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 24px;
}
.nameDIv {
  width: calc(100% - 58px);
}
.flexCenter {
  display: flex;
  align-items: center;
}
.nameTip {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
}
.modeDiv {
  background-color: #edeef6;
  border-radius: 4px;
  padding: 1px 7px;
  font-size: 13px;
  font-weight: 500;
  color: #8a8a8a;
  margin-left: 7px;
}
.forBaseItemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 700;
  max-width: calc(100% - 200px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.imgType {
  position: absolute;
  right: -7px;
  bottom: -7px;
  width: 24px;
}
.logoDiv {
  border-radius: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
}
.baseIconLogo {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 110;
  opacity: 0.3;
}
.addContent {
  position: fixed;
  width: 540px;
  max-height: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 111;
  padding: 30px;
  border-radius: 30px;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.addBottomBtn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}
.closeBtn {
  height: 48px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
  padding: 12px 20px;
}
.nextBtn {
  height: 48px;
  border-radius: 16px;
  color: #fefefe;
  background-color: #3376ff;
  border: 0px;
  font-size: 18px;
  font-weight: 400;
  padding: 12px 20px;
  margin-left: 12px !important;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #fefefe;
  --el-border-radius-base: 10px;
  padding: 3px;
  :deep(.el-segmented__item:not(.is-disabled):not(.is-selected):hover) {
    background-color: #e4e4e4;
    border-radius: 8px;
  }
  :deep(.el-segmented__item) {
    padding: 5px 22px;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 500;
  }
  :deep(.is-selected) {
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 500;
  }
}
.bottomFileActive {
  width: calc(49.5% - 48px);
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #f6f6f6;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  padding: 14px 20px;
  border-radius: 18px;
  cursor: default;
}
.bottomFileActiveEdit {
  width: calc(49.5% - 48px);
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 14px 20px;
  border-radius: 18px;
  cursor: pointer;
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border: 2px solid #3376ff;
}
.rightTable {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #8a8a8a;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 12px 12px 0px 0px;
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border: 1px solid #d9d9d9;
  border-top: 0px;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-12) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.colFlex {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.tableIcon {
  margin-right: 15px;
  width: 24px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableRole2 {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableLogo1 {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #ffc300;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.tableRole1 {
  color: #ffc300;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.tableRoleType1 {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #feecb2;
  border-radius: 8px;
  justify-content: center;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  margin-right: 6px;
}
.tableNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  padding-left: 10px;
}
.tipIcon {
  margin-left: 10px;
  font-size: 16px;
}
.nameLabel::before {
  position: absolute;
  content: "*";
  color: #f02d63;
  right: -15px;
}
.addLabel {
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  position: relative;
}
.itemInput {
  height: 48px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
}
.marginBtomm20px {
  margin-bottom: 12px;
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}

:deep(.el-input__inner::placeholder) {
  font-weight: 400;
  font-size: 18px;
}
:deep(.el-input__wrapper) {
  border-radius: 16px;
  border: 1px solid #edeef6;
  box-shadow: 0px 0px 0px;
}
.textInput {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
}
:deep(.el-textarea__inner) {
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  border: 1px solid #edeef6;
  border-radius: 16px;
  box-shadow: 0px 0px 0px;
  min-height: 48px !important;
  padding: 10px;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 30px;
}
:deep(.el-textarea .el-input__count) {
  font-weight: 400;
  font-size: 18px;
}
.flexBetweenAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addUserBtn {
  height: 36px;
  border-radius: 12px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  border: 0px;
  padding: 6px 20px;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
}
.moreUser {
  margin-left: 10px;
  font-size: 20px;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
.txCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c4ddfc;
  overflow: hidden;
}
.stepImgIcon {
  width: 60px;
}
.stepImgIcons {
  width: 100%;
  height: 100%;
}
.step2RightImg {
  background-color: #f7f8ff;
  height: 60px;
  border-radius: 18px;
  margin-left: 10px;
  width: calc(100% - 130px);
  padding: 12px 18px;
  display: flex;
  align-items: center;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.imgFlex {
  display: flex;
  overflow: hidden;
  max-width: 230px;
}
.getImgLoading {
  width: 59px;
  margin-right: 10px;
  height: 59px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.itemImgDiv {
  position: relative;
}
.itemImg {
  height: 59px;
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}
.imgYes {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 16px;
}
.stepImgAi {
  border-radius: 12px;
  height: 49px;
  border: 0px;
  background-color: #eceeff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  cursor: pointer;
}
.aiImgIcon {
  width: 36px;
  height: 36px;
  background-color: #dbdfff;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.aiImgTitle {
  font-size: 18px;
  color: #0256ff;
  font-weight: 500;
}
.wenshengtuImg {
  width: 21px;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.moseNo {
  cursor: not-allowed;
}
</style>
