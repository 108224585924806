<script setup>
import { icon } from "@/utils/icon";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
const { t } = useI18n();
const router = useRouter();
const showIndex = ref(null);
// 打开智能工具
const openTool = (index) => {
  showIndex.value = index;
  if (showIndex.value === 0) {
    router.push("/bot/components/word");
  } else if (showIndex.value === 1) {
    router.push("/bot/components/audio");
  } else if (showIndex.value === 2) {
    router.push("/bot/components/image");
  } else if (showIndex.value === 3) {
    router.push("/bot/components/bill");
  }
  // else if (showIndex.value === 4) {
  //     router.push('/bot/components/excel')
  // }
};
const toolList = ref([
  {
    name: "bot.bot67",
    introduce: "bot.bot68",
    tag: ["bot.bot69", "bot.bot70"],
  },
  {
    name: "bot.bot71",
    introduce: "bot.bot72",
    tag: ["bot.bot73", "bot.bot74"],
  },
  {
    name: "bot.bot75",
    introduce: "bot.bot76",
    tag: ["bot.bot77", "bot.bot78"],
  },
  {
    name: "bot.bot79",
    introduce: "bot.bot80",
    tag: ["bot.bot81", "bot.bot82"],
  },
  // {
  //     name: '自动填写问答',
  //     introduce: '上传Excel表格，空白处自动查询答案并填写',
  //     tag: [
  //         '上传Excel',
  //         '自动填写答案'
  //     ]
  // }
]);
</script>
<template>
  <div
    class="itemWidth"
    @click="openTool(index)"
    v-for="(item, index) in toolList"
    :key="index"
  >
    <div style="display: flex" class="tit">
      <div class="addDiv">
        <img :src="icon.addTool" alt="" />
      </div>
      <div class="baseItemTitle">{{ t(item.name) }}</div>
    </div>
    <div class="tagBox">
      <div class="tagItem" v-for="(tag, tagIndex) in item.tag" :key="tagIndex">
        {{ t(tag) }}
      </div>
    </div>
    <div class="addTip">
      {{ t(item.introduce) }}
    </div>
    <div class="addBtn" @click="openTool(index)">
      {{ t("bot.bot83") }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.itemWidth {
  position: relative;
  border-radius: 20px;
  background-color: #f7f8ff;
  margin-bottom: 20px;
  padding: 24px;
  width: 19.7%;
  height: 162px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;

  .addBtn {
    position: absolute;
    bottom: 30px;
    right: 20px;
    color: #8a8a8a;
    font-size: 16px;
    cursor: pointer;
    display: none;
  }
}

.itemWidth:hover {
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #eceeff;

  .addBtn {
    display: block;
    color: #0256ff;
  }
}

.tit {
  margin-bottom: 16px;
}

.tagBox {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-bottom: 8px;

  .tagItem {
    border-radius: 16px;
    height: 26px;
    line-height: 26px;
    padding: 0px 12px;
    color: #fff;
    font-size: 14px;
    background-color: #3376ff;
    margin-right: 12px;
    margin-bottom: 5px;
  }
}

.addDiv {
  border-radius: 8px;
  width: 28px;
  height: 28px;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.baseItemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 700;
  width: calc(100% - 80px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.addTip {
  font-size: 17px;
  color: #8a8a8a;
  font-weight: 400;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 18px;
}
</style>
