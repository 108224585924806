<template>
  <div class="fileItemDiv">
    <div class="fileBetween">
      <div class="flexCenter treeDiv">
        <img :src="getBaseFileIcon(props.data)" alt="" class="fileIcon" />

        <el-tooltip
          popper-class="toolTip"
          :content="props.data.fileName || props.data.folderName"
        >
          <div
            :class="{
              fileTitle:
                props.data.expireFlag == 1 && props.data.importantFlag == 1,
              fileTitle1:
                props.data.expireFlag == 1 || props.data.importantFlag == 1,
              fileTitle2:
                props.data.expireFlag != 1 && props.data.importantFlag != 1,
            }"
          >
            {{ props.data.fileName || props.data.folderName }}
          </div>
        </el-tooltip>
        <el-tooltip
          popper-class="toolTip"
          :content="t('base.base291')"
          v-if="props.data.importantFlag == 1"
        >
          <img :src="icon.fileYear" alt="" class="fileYear" />
        </el-tooltip>
      </div>
      <el-dropdown
        @command="changeFileMore($event, props.data)"
        v-if="props.data.folderName && props.baseItem.knowledge_role_id == 0"
      >
        <div class="fileMoreImgDiv">
          <img :src="icon.fileMore" alt="" class="fileMoreImg" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :command="5">
              <span class="dropText redColor">{{ t("base.base68") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown
        @command="changeFileMore($event, props.data)"
        v-if="props.baseItem.knowledge_role_id != 2 && props.data.fileName"
      >
        <div class="fileMoreImgDiv">
          <img :src="icon.fileMore" alt="" class="fileMoreImg" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              :command="1"
              v-if="props.data.fileName && props.baseItem.resourcetype == 0"
            >
              <span class="dropText blueColor">{{ t("base.base62") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              :command="3"
              v-if="props.data.fileName && props.data.importantFlag == 1"
            >
              <span class="dropText blueColor">{{ t("base.base65") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              :command="7"
              v-if="
                props.data.fileName &&
                (props.data.importantFlag == 0 ||
                  props.data.importantFlag == null)
              "
            >
              <span class="dropText blueColor">{{ t("base.base66") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              :command="4"
              v-if="
                props.data.fileName && props.baseItem.knowledge_role_id == 0
              "
            >
              <span class="dropText redColor">{{ t("base.base67") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { getBaseFileIcon } from "@/utils/file";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["data", "baseItem"]);
const emit = defineEmits(["changeFileMore"]);
const changeFileMore = (e, item) => {
  emit("changeFileMore", e, item);
};
</script>

<style lang="scss" scoped>
.fileItemDiv {
  height: 26px;
  cursor: pointer;
  width: calc(100% - 24px);
}
.fileBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  height: 100%;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.treeDiv {
  width: calc(100% - 52px);
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
.fileTitle {
  color: #3d3d3d;
  font-size: 22px;
  font-weight: 400;
  max-width: calc(100% - 64px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTitle1 {
  color: #3d3d3d;
  font-size: 22px;
  font-weight: 400;
  max-width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTitle2 {
  color: #3d3d3d;
  font-size: 22px;
  font-weight: 400;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
.redColor {
  color: #f02d63;
}
.fileYear {
  width: 18px;
  margin-left: 10px;
}
.fileMoreImgDiv {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fileMoreImgDiv:hover {
  background-color: #dbdfff;
}
.fileMoreImg {
  width: 4px;
}
</style>
