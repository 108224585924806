<template>
  <div class="addContent">
    <div class="titleFlex">
      <div class="addTitle">{{ t("base.base366") }}</div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeUser" />
    </div>
    <div class="bodyCenter">
      <div class="left">
        <div class="nacFelx">
          <div
            :class="activeName == index ? 'ac navItem' : 'navItem'"
            v-for="(item, index) in navList"
            :key="index"
            @click="changeNav(index)"
          >
            {{ t(item) }}
          </div>
        </div>
        <div class="posr">
          <el-input
            class="searchInput"
            v-model="departmentName"
            :placeholder="
              activeName == 0 ? t('gpt.index78') : t('base.base125')
            "
            @input="searchInput"
          ></el-input>
          <div class="searchDiv" v-if="searchDivType">
            <div class="searchTitle" v-if="searchDepartment.length != 0">
              {{ t("bot.bot102") }}
            </div>
            <div
              class="itemDiv"
              v-for="(item, index) in searchDepartment"
              :key="index"
              @click.stop="openDepartMent(item, 'dep')"
            >
              <div class="searchTx" :style="'background-color:' + item.color">
                {{ item.departmentName.slice(0, 1) }}
              </div>
              <el-tooltip popper-class="toolTip" :content="item.departmentName">
                <div class="searchName">{{ item.departmentName }}</div>
              </el-tooltip>
            </div>
            <div class="searchTitle" v-if="searchUserList.length != 0">
              {{ t("manage.userForm.user1") }}
            </div>
            <div
              class="itemDiv"
              v-for="(item, index) in searchUserList"
              :key="index"
              @click.stop="openDepartMent(item, 'user')"
            >
              <div class="searchTx" :style="'background-color:' + item.color">
                {{ item.userName.slice(0, 1) }}
              </div>
              <el-tooltip popper-class="toolTip" :content="item.userName">
                <div class="searchName">{{ item.userName }}</div>
              </el-tooltip>
            </div>
            <el-empty
              v-if="searchUserList.length == 0 && searchDepartment.length == 0"
            />
          </div>
        </div>
        <div class="treeDiv" v-loading="leftLoading">
          <el-tree
            ref="treeRef"
            v-if="activeName == 0"
            :data="departmentList"
            :props="departmentProps"
            node-key="departmentId"
            show-checkbox
            :default-expand-all="true"
            :expand-on-click-node="false"
            :check-strictly="true"
            :highlight-current="true"
            @check="handleCheckChange"
            @node-click="handleNodeClick"
          >
            <template #default="{ node, data }">
              <div :id="'tree' + data.departmentId" class="itemTree">
                <el-tooltip popper-class="toolTip" :content="node.label">
                  <span>{{ node.label }}</span>
                </el-tooltip>
              </div>
            </template>
          </el-tree>
          <el-tree
            v-else
            ref="treeRef"
            :data="departmentList"
            :props="userGroupProps"
            node-key="groupId"
            show-checkbox
            :default-expand-all="true"
            :expand-on-click-node="false"
            :check-strictly="true"
            :highlight-current="true"
            @check="handleCheckChange"
            @node-click="handleNodeClick"
          >
          </el-tree>
        </div>
      </div>
      <div class="right" v-if="activeName == 0">
        <el-table
          :data="userTableData"
          ref="multipleTableRef"
          row-key="userId"
          style="height: 100%"
          v-loading="rightLoading"
          @select="changeSel"
          @select-all="changeSelAll"
          class="tableClass"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :selectable="selectable"
          />
          <el-table-column prop="userName" :label="t('base.base126')" />
          <el-table-column prop="roleName" :label="t('base.base2')" />
        </el-table>
      </div>
    </div>
    <div class="dialog-footer">
      <div @click="closeUser" class="cancel">{{ t("base.base113") }}</div>
      <div @click="saveUser" class="confirm">{{ t("base.base212") }}</div>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import api from "@/api";
import { nextTick, onMounted, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const departmentList = ref([]); //部门列表
const activeName = ref(0); //nav
const navList = ref(["bot.base129", "header.header33"]);
const leftDepartmentId = ref(null); //左侧选择的id 部门和群组公用
const userTableData = ref([]); //右侧table
const rightLoading = ref(false); //loading
const leftLoading = ref(false); //loading
const rightList = ref([]); //右侧选择
const departmentLeftList = ref([]); //部门左侧选择
const multipleTableRef = ref(); //ref
const groupLeftList = ref([]); //部门
const leftIds = ref([]);
const rightIds = ref([]);
const treeRef = ref(null);
const departmentProps = ref({
  label: "departmentName",
  children: "children",
  value: "departmentId",
});
const userGroupProps = ref({
  label: "groupName",
  value: "groupId",
});
const departmentName = ref(""); //搜索名称
const emit = defineEmits(["closeUser", "saveUser"]);
const props = defineProps(["userList", "userObj", "type"]);
onMounted(() => {
  rightList.value = props.userList.userList; //人
  departmentLeftList.value = props.userList.departmentList; //部门
  leftIds.value = props.userList.departmentList.map((item) => item.id);
  groupLeftList.value = props.userList.gropuList; //群组
  rightIds.value = props.userList.gropuList.map((item) => item.id);
  getDepartment();
  document.addEventListener("click", handleOutsideClick);
});
onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick);
});
const selectable = (row) => {
  return row.userId != props.userObj[0].id;
};
// 保存user
const saveUser = () => {
  rightList.value.forEach((item) => {
    if (item.userId == props.userObj[0].id) {
      item.type = 0;
      item.id = Number(item.id);
    }
  });
  let arr = rightList.value.concat(
    departmentLeftList.value,
    groupLeftList.value
  );
  emit("saveUser", arr);
};
const changeNav = (index) => {
  activeName.value = index;
  departmentName.value = "";
  searchDivType.value = false;
  userName.value = null;
  if (activeName.value == 0) {
    getDepartment();
  } else {
    getGroupList();
  }
};
// 搜索
const searchInput = () => {
  if (activeName.value == 0) {
    searchUser();
  } else {
    getGroupList();
  }
};
// 选择树 //单个
const handleCheckChange = (item) => {
  if (activeName.value == 0) {
    leftIds.value = treeRef.value.getCheckedKeys(false);
    const index = departmentLeftList.value.findIndex(
      (items) => items.departmentId === item.departmentId
    );
    if (index !== -1) {
      departmentLeftList.value.splice(index, 1);
    } else {
      departmentLeftList.value.push({
        ...item,
        id: item.departmentId,
        name: item.departmentName,
        type: props.type == "base" ? 2 : 1,
        ownerType: 1,
        color: item.color,
      });
    }
  } else {
    rightIds.value = treeRef.value.getCheckedKeys(false);
    const index = groupLeftList.value.findIndex(
      (items) => items.groupId === item.groupId
    );
    if (index !== -1) {
      groupLeftList.value.splice(index, 1);
    } else {
      groupLeftList.value.push({
        ...item,
        id: item.groupId,
        name: item.groupName,
        type: props.type == "base" ? 2 : 1,
        ownerType: 2,
        color: item.color,
      });
    }
  }
};
// 树选择
const handleNodeClick = (e) => {
  if (activeName.value == 0) {
    leftDepartmentId.value = e.departmentId;
    getUserTable();
    nextTick(() => {
      treeRef.value.setCurrentKey(e.departmentId);
    });
  } else {
    leftDepartmentId.value = e.groupId;
    let arr = departmentList.value.filter(
      (item) => item.groupId == leftDepartmentId.value
    );
  }
};
// 获取左侧列表
const getDepartment = () => {
  leftLoading.value = true;
  api.manage
    .getDepartmentList({
      userId: userId.value,
      departmentName: departmentName.value,
    })
    .then((res) => {
      leftLoading.value = false;
      if (res.returnCode == 200) {
        departmentList.value = res.data;
        leftDepartmentId.value = res.data[0].departmentId;
        getUserTable();
        nextTick(() => {
          treeRef.value.setCheckedKeys(leftIds.value, false);
        });
      }
    })
    .catch((err) => {
      leftLoading.value = false;
    });
};
// 左侧群组
const getGroupList = () => {
  leftLoading.value = true;
  api.manage
    .queryGroupList({
      userId: userId.value,
      search: departmentName.value,
      pageId: 0,
      perPage: 0,
    })
    .then((res) => {
      leftLoading.value = false;
      if (res.returnCode == 200) {
        if (res.data.length != 0) {
          departmentList.value = res.data;
          nextTick(() => {
            treeRef.value.setCheckedKeys(rightIds.value, false);
          });
        } else {
          leftDepartmentId.value = null;
          departmentList.value = [];
        }
      }
    })
    .catch((err) => {
      leftLoading.value = false;
    });
};
// 监听点击事件，点击页面其他地方时关闭弹出框
const handleOutsideClick = (event) => {
  if (searchDivType.value && !event.target.closest(".searchDiv")) {
    searchDivType.value = false;
  }
};
// 搜索用
const userName = ref(null);
const openDepartMent = (item, type) => {
  userName.value = null;
  if (type == "user") {
    userName.value = item.userName;
  } else {
    const departmentIdDom = document.getElementById("tree" + item.departmentId);
    departmentIdDom.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  let obj = {
    departmentId: item.departmentId,
  };
  handleNodeClick(obj);
  searchDivType.value = false;
  departmentName.value = "";
};
const searchDepartment = ref([]);
const searchUserList = ref([]);
const searchDivType = ref(false);
const searchUser = () => {
  api.manage
    .managementUserSearch({
      userId: userId.value,
      search: departmentName.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.length == 0) {
          searchDivType.value = false;
        } else {
          searchDepartment.value = res.data.departments;
          searchUserList.value = res.data.users;
          searchDivType.value = true;
        }
      }
    });
};
// 获取右侧table
const getUserTable = () => {
  rightLoading.value = true;
  api.manage
    .getUserList({
      userId: userId.value,
      departmentId: leftDepartmentId.value,
      pageId: 0,
      perPage: 10,
      userNamePattern: userName.value,
    })
    .then((res) => {
      rightLoading.value = false;
      userName.value = null;
      if (res.returnCode == 200) {
        userTableData.value = res.data;
        toggleSelection();
      }
    })
    .catch((err) => {
      rightLoading.value = false;
    });
};
// 右侧回显选择
const toggleSelection = () => {
  nextTick(() => {
    multipleTableRef.value.clearSelection();
    rightList.value.forEach((row) => {
      userTableData.value.forEach((item) => {
        if (item.userId == row.userId || item.userId == row.id) {
          multipleTableRef.value.toggleRowSelection(item, true);
        }
      });
    });
  });
};
// 右侧全选
const changeSelAll = (list) => {
  //   有数据则添加
  if (list.length == userTableData.value.length) {
    list.forEach((item) => {
      rightList.value.push({
        ...item,
        id: Number(item.userId),
        name: item.userName,
        type: props.type == "base" ? 2 : 1,
        ownerType: 0,
        color: item.color,
      });
    });
    // 去重
    const uniqueMap = new Map(rightList.value.map((item) => [item.id, item]));
    rightList.value = Array.from(uniqueMap.values());
  } else {
    // 拿table列表数据
    userTableData.value.forEach((item) => {
      const index = rightList.value.findIndex(
        (user) => user.userId === item.userId
      );
      if (index !== -1) {
        rightList.value.splice(index, 1);
      }
    });
  }
};
// 右侧选择人 value数组
const changeSel = (value, row) => {
  const index = rightList.value.findIndex(
    (user) => Number(user.userId) === Number(row.userId)
  );
  if (index !== -1) {
    rightList.value.splice(index, 1);
    nextTick(() => {
      multipleTableRef.value.toggleRowSelection(row, false);
    });
  } else {
    rightList.value.push({
      ...row,
      id: Number(row.userId),
      name: row.userName,
      type: props.type == "base" ? 2 : 1,
      ownerType: 0,
      color: row.color,
    });
  }
};
// 关闭添加用户
const closeUser = () => {
  emit("closeUser");
};
</script>

<style lang="scss" scoped>
.itemDiv {
  display: flex;
  align-items: center;
  height: 41px;
  cursor: pointer;
}
.posr {
  position: relative;
}
.searchDiv {
  position: absolute;
  top: 50px;
  max-height: 312px;
  width: calc(100% - 24px);
  z-index: 10;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 12px;
  overflow: auto;
}
.searchTitle {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
  margin-bottom: 5px;
}
.searchTx {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  text-align: center;
  line-height: 31px;
  margin-right: 4px;
  font-size: 18px;
  color: #fefefe;
  font-weight: 600;
}
.searchName {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  max-width: calc(100% - 35px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 120;
  opacity: 0.3;
}

.addContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 121;
  padding: 30px;
  border-radius: 30px;
}

.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}

.addClose {
  width: 14px;
  cursor: pointer;
}

.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.left {
  width: 226px;
  border: 1px solid #edeef6;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #edeef6;
  padding: 12px;
}

.right {
  margin-left: 10px;
  width: 440px;
}

.bodyCenter {
  display: flex;
  height: 388px;
}

.treeDiv {
  height: calc(100% - 108px);
  overflow: auto;
  margin-top: 10px;
}

:deep(.el-tree-node__content) {
  height: 50px !important;
  border-radius: 16px;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  height: 100%;
  display: flex;
  align-items: center;
}
.itemTree {
  height: 100%;
  display: flex;
  align-items: center;
}
:deep(.el-tree-node__content:hover) {
  background-color: #f7f8ff !important;
  color: #0256ff;
}

:deep(.el-tree-node:focus > .el-tree-node__content) {
  background-color: #f7f8ff !important;
  color: #0256ff;
}
:deep(
    .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content
  ) {
  background-color: #f7f8ff !important;
  color: #0256ff;
}
.searchInput {
  font-size: 18px;
  font-weight: 400;
  :deep(.el-input__wrapper) {
    border: 1px solid #edeef6;
    box-shadow: 0px 0px 0px;
    height: 44px;
    border-radius: 12px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;
  margin-top: 24px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    font-weight: 400;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}

.nacFelx {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 0px 3px;
}

:deep(.el-tree-node) {
  width: 225px;
  display: table;
}
:deep(.el-table th.el-table__cell) {
  background-color: #f6f6f6;
  font-size: 18px;
  color: #8a8a8a;
  height: 60px;
}
.tableClass {
  border-radius: 12px;
  border: 1px solid #d9d9d9;
}
.navItem {
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  color: #8a8a8a;
  width: calc(50% - 6px);
  cursor: pointer;
  text-align: center;
}
.ac {
  box-shadow: 0px 0px 4px 0px #d4d4d4;
  color: #3d3d3d;
  background-color: #fefefe;
}
</style>
