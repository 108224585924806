<template>
  <div class="dialog">
    <div class="dialogBetWeen">
      <div class="dialogTitle">{{ t("base.base74") }}</div>
      <img
        @click="closeAddFolder"
        :src="icon.closeFileView"
        alt=""
        class="closeFileViewImg"
      />
    </div>
    <div class="dialogTip">
      {{ t("base.base75") }} <span style="color: #f02d63">*</span>
    </div>
    <el-input
      maxlength="100"
      :placeholder="t('base.base76')"
      v-model="floderName"
      show-word-limit
      class="dialogInput"
    ></el-input>
    <div class="dialogEnd">
      <el-button @click="closeAddFolder" class="dialogCancelBtn">{{
        t("base.base45")
      }}</el-button>
      <el-button
        @click="saveFloader"
        class="dialogSaveBtn"
        :loading="props.folderLoading"
      >
        {{ t("base.base46") }}
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emit = defineEmits(["closeAddFolder", "saveFloader"]);
const props = defineProps(["folderLoading"]);
const floderName = ref("");
const closeAddFolder = () => {
  emit("closeAddFolder");
};
const saveFloader = () => {
  emit("saveFloader", floderName.value);
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  width: 540px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
  box-shadow: 0px 0px 15px 0px #dcdcdc;
}
.dialogBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
  margin-bottom: 24px;
}
.closeFileViewImg {
  width: 14px;
  cursor: pointer;
}
.dialogTip {
  font-size: 18px;
  font-weight: 500;
  color: #3d3d3d;
  margin-bottom: 17px;
}

.dialogInput {
  margin-bottom: 24px;
  :deep(.el-input__wrapper) {
    border-radius: 16px;
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px;
    height: 48px;
    font-size: 18px;
    font-weight: 400;
  }
}
.dialogEnd {
  display: flex;
  justify-content: flex-end;
}
.dialogCancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #f02d63;
  font-weight: 400;
  font-size: 18px;
}
.dialogSaveBtn {
  height: 48px;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #fefefe;
  font-weight: 400;
  font-size: 18px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 200;
  opacity: 0.3;
}
</style>
