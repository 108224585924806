export default {
    CN: {
        bot1: '智能助手',
        bot3: '搜索智能助手',
        bot4: '新增成功',
        bot5: '修改成功',
        bot6: '置顶成功',
        bot7: '取消成功',
        bot8: '删除成功',
        bot9: '新建智能助手',
        bot10: '新建一个空白的AI智能助手；智能助手能够根据你的设定和介绍执行特定指令,帮助你自动执行任务。',
        bot11: '置顶智能助手',
        bot12: '取消置顶',
        bot13: '删除智能助手',
        bot14: '编排智能助手',
        bot15: 'AI微模型',
        bot16: '图像生成',
        bot17: '联网检索',
        bot18: '一键完善成功',
        bot19: '名称不能为空',
        bot20: '设定不能为空',
        bot21: '权限成员不能为空',
        bot22: '添加成功',
        bot23: '用户删除成功',
        bot24: '智能助手名称不能为空',
        bot25: '新建智能助手',
        bot26: '修改智能助手',
        bot27: '智能助手类型',
        bot28: '名称',
        bot29: '请输入智能助手名称',
        bot30: '设定',
        bot31: '一键完善',
        bot32: '输入智能助手主题、内容或作用的简要设定',
        bot33: '技能',
        bot34: '图标',
        bot35: '权限设置',
        bot36: '身份',
        bot37: '普通用户',
        bot38: '拥有者',
        bot39: '操作',
        bot40: '移除',
        bot41: '添加成员',
        bot42: '介绍',
        bot43: '简短介绍您的智能助手',
        bot44: '开场白',
        bot45: '智能助手的开场白',
        bot46: '提问灵感',
        bot47: '添加',
        bot48: '取消',
        bot49: '确定',
        bot50: '请输入部门名称',
        bot51: '请输入群组名称',
        bot52: '用户名',
        bot53: '角色',
        bot54: '群组名',
        bot55: '返回',
        bot56: '历史记录',
        bot57: "点击上传/拖拽本地音频文件到这里",
        bot58: '大小:音频≤50M,时长0.5小时内',
        bot59: '格式:.WAV,.MP3,.M4A,.OPUS/OGG,FLAC,WMA,AAC,ALAW in WAV container,MULAW in WAV container,AMR,WebM,SPEEX',
        bot60: '正在提取文字...',
        bot61: '音频内容',
        bot62: '不支持该文件类型上传。',
        bot63: '点击上传/拖拽PDF到这里',
        bot64: '正在解析中...',
        bot65: "识别结果",
        bot66: "导出为文档",
        bot67: '文档翻译',
        bot68: '跨越语言障碍,让信息自由流通',
        bot69: '多语种翻译',
        bot70: '多种格式支持',
        bot71: '智能音频',
        bot72: '快速识别音/视频,一键转换文字,精准支持多种语言',
        bot73: '语音识别',
        bot74: '多语种支持',
        bot75: '图片转文档',
        bot76: '单张图片上传,快速识别出结果',
        bot77: '图片识别',
        bot78: '文档导出',
        bot79: 'PDF文字提取',
        bot80: '支持PDF识别,快速识别出结果',
        bot81: 'PDF识别',
        bot82: 'PDF文字提取',
        bot83: '立即体验',
        bot84: '点击上传/拖拽图片到这里',
        bot85: '正在解析图片中...',
        bot86: '图片',
        bot87: '图片识别',
        bot88: 'AI润色预览',
        bot89: '不支持该文件类型上传。',
        bot90: '上传文件不能超过20MB',
        bot91: '文件名称过长,上传失败！',
        bot92: '点击或拖拽添加',
        bot93: '请选择目标语言',
        bot94: '开始翻译',
        bot95: '文档翻译中',
        bot96: '若离开页面,翻译不会取消,可到翻译历史中查看',
        bot97: '文档翻译成功',
        bot98: '文档下载',
        bot99: '在线预览',
        bot100: '上传新文档',
        bot101: '点击上传智能助手头像',
        bot102: '部门',
        bot104: '高级',
        bot105: '智能助手详情',
        bot106: 'AI智能助手详情',
        bot107: '点击「提交」按钮后,你的智能助手编排会提交到NextAI Platform,你可以在完成提交后进行发布',
        bot108: '在发布之前,请先将你的智能助手编排提交到NextAI Plaform',
        bot109: '编排',
        bot110: '设定和逻辑介绍',
        bot111: 'AI一键完善',
        bot112: '高级设置',
        bot113: '点击「调试」按钮后,你可以在右侧「调试预览」模块测试你的智能助手编排',
        bot114: '调试',
        bot115: '调试预览',
        bot116: '提交成功',
        bot117: '正在思考',
        bot118: '已完成思考',
        bot119: '思考时间',
        bot120: '几秒',
        bot121: '秒',
        bot122: '分钟',
        bot123: '智能应用',
        bot124: '插件',
        bot125: '提示词',
        base126: '新建插件',
        base127: '新建一个自定义插件；大模型可以驱使AI插件根据你设定的Schema执行外部功能,获取和操作外部信息。',
        base128: '搜索插件',
        base129: '部门与用户',
        base130: '测试',
        base131: '鉴权方法',
        base132: '无',
        base133: '输入参数',
        base134: '参数',
        base135: '值',
        base136: '测试',
        base137: '测试输出',
        base138: '鉴权类型',
        base139: '鉴权头部前缀',
        base140: '键',
        base141: '输入键名',
        base142: '输入值',
        base143: '编辑插件',
        base144: '输入插件名称',
        base145: '从URL中导入',
        base146: '空白模板',
        base147: '可用工具',
        base148: '描述',
        base149: '方法',
        base150: '路径',
        base151: '成员',
        base152: '第一步',
        base153: '基本信息',
        base154: '第二步',
        base155: '权限配置',
        base156: '信息和编排',
        base157: '使⽤插件⼯具可以为智能助⼿扩展更多能⼒',
        base158: '引⽤知识库作为智能助⼿的上下⽂知识',
        base159: '知识库',
        base160: '插件',
        base161: '删除插件',
        base162: '第三步',
        base163: '知识设置',
        base164: '添加插件',
        base165: '添加',
        base166: '已添加',
        base167: '基础信息',
        base168: '插件详情',
        base169: '引用 ·',
        base170: '插件编辑',
        base171: '请选择鉴权头部前缀',
        base172: '导入',
        base173: 'AI智能助手编辑',
    },
    HK: {
        bot1: '智能助手',
        bot3: '搜索智能助手',
        bot4: '新增成功',
        bot5: '修改成功',
        bot6: '置頂成功',
        bot7: '取消成功',
        bot8: '刪除成功',
        bot9: '新建智能體',
        bot10: '新建一個空白的AI智能助手；智能助手能夠根據你的設定和介紹執行特定指令,説明你自動執行任務。',
        bot11: '置頂智能體',
        bot12: '取消置頂',
        bot13: '刪除智能體',
        bot14: '編排智能助手',
        bot15: 'AI知識庫 ',
        bot16: '圖像生成',
        bot17: '聯網檢索',
        bot18: '一鍵完善成功',
        bot19: '名稱不能為空',
        bot20: '設定不能為空',
        bot21: '許可權成員不能為空',
        bot22: '添加成功',
        bot23: '用戶刪除成功',
        bot24: '智能助手名稱不能為空',
        bot25: '新建智能體',
        bot26: '修改智能體',
        bot27: '智能體類型',
        bot28: '名稱',
        bot29: '請輸入智能助手名稱',
        bot30: '設定',
        bot31: '一鍵完善',
        bot32: '輸入智能助手主題、內容或作用的簡要設定',
        bot33: '技能',
        bot34: '圖示',
        bot35: '許可權設置',
        bot36: '身份',
        bot37: '普通用戶',
        bot38: '擁有者',
        bot39: '操作',
        bot40: '移除',
        bot41: '添加成員',
        bot42: '介紹',
        bot43: '簡短介紹您的智能助手',
        bot44: '開場白',
        bot45: '智能體的開場白',
        bot46: '提問靈感',
        bot47: '添加',
        bot48: '取消',
        bot49: '確定',
        bot50: '請輸入部門名稱',
        bot51: '請輸入群組名稱',
        bot52: '用戶名',
        bot53: '部門',
        bot54: '群組名',
        bot55: '返回',
        bot56: '歷史記錄',
        bot57: "點擊上傳/拖拽本地音訊檔到這裡",
        bot58: '大小:音訊≤50M,時長0.5小時內',
        bot59: '格式:.WAV,.MP3,.M4A,.OPUS/OGG,FLAC,WMA,AAC,ALAW in WAV container,MULAW in WAV container,AMR,WebM,SPEEX',
        bot60: '正在提取文字...',
        bot61: '音訊內容',
        bot62: '不支持該檔案類型上傳。',
        bot63: '點擊上傳/拖拽PDF到這裡',
        bot64: '正在解析中...',
        bot65: "識別結果",
        bot66: "匯出為文檔",
        bot67: '文檔翻譯',
        bot68: '跨越語言障礙,讓資訊自由流通',
        bot69: '多語種翻譯',
        bot70: '多種格式支持',
        bot71: '智能音訊',
        bot72: '快速識別音/視頻,一鍵轉換文字,精准支援多種語言',
        bot73: '語音辨識',
        bot74: '多語種支持',
        bot75: '圖片轉文檔',
        bot76: '單張圖片上傳,快速識別出結果',
        bot77: '圖片識別',
        bot78: '文檔匯出',
        bot79: 'PDF文字提取',
        bot80: '支持PDF識別,快速識別出結果',
        bot81: 'PDF識別',
        bot82: 'PDF文字提取',
        bot83: '立即體驗',
        bot84: '點擊上傳/拖拽圖片到這裡',
        bot85: '正在解析圖片中...',
        bot86: '圖片',
        bot87: '圖片識別',
        bot88: 'AI潤色預覽',
        bot89: '不支持該檔案類型上傳。',
        bot90: '上傳檔不能超過20MB',
        bot91: '檔案名稱過長,上傳失敗！',
        bot92: '點擊或拖拽添加',
        bot93: '請選擇目的語言',
        bot94: '開始翻譯',
        bot95: '文檔翻譯中',
        bot96: '若離開頁面,翻譯不會取消,可到翻譯歷史中查看',
        bot97: '文檔翻譯成功',
        bot98: '文檔下載',
        bot99: '線上預覽',
        bot100: '上傳新文檔',
        bot101: '點擊上傳智能體頭像',
        bot102: '部門',
        bot104: '高級',
        bot105: '智能助手詳情',
        bot106: 'AI智能助手詳情',
        bot107: '點擊「提交」按鈕后,你的智能助手編排會提交到NextAI Platform,你可以在完成提交後進行發佈',
        bot108: '在發佈之前,請先將你的智能助手編排提交到NextAI Plaform',
        bot109: '編排',
        bot110: '設定和邏輯介紹',
        bot111: 'AI一鍵完善',
        bot112: "高級設置",
        bot113: '點擊「調試」按鈕后,你可以在右側「調試預覽」模塊測試你的智能助手編排',
        bot114: '調試',
        bot115: '調試預覽',
        bot116: '提交成功',
        bot117: '正在思考',
        bot118: '已完成思考',
        bot119: '思考時間',
        bot120: '幾秒',
        bot121: "秒",
        bot122: "分鐘",
        bot123: '智能應用',
        bot124: "插件",
        bot125: "提示詞",
        base126: '新建插件',
        base127: '新建一個自定義插件; 大模型可以驅使AI插件根據你設定的Schema執行外部功能,獲取和操作外部資訊。 ',
        base128: '搜索插件',
        base129: '部門與使用者',
        base130: '測試',
        base131: '鑒權方法',
        base132: "無",
        base133: '輸入參數',
        base134: "參數",
        base135: '值',
        base136: '測試測試',
        base137: "測試輸出",
        base138: "鑒權類型",
        base139: "鑒權頭部前綴",
        base140: "鍵",
        base141: '輸入鍵名',
        base142: "輸入值",
        base143: '編輯插件',
        base144: '輸入插件名稱',
        base145: '從URL中導入',
        base146: '空白範本',
        base147: '可用工具',
        base148: '描述',
        base149: '方法',
        base150: "路徑",
        base151: '成員',
        base152: '第一步',
        base153: '基本資訊',
        base154: '第二步',
        base155: '權限配置',
        base156: '信息和編排',
        base157: '使用插件工具可以為智能助手擴展更多能力',
        base158: '引用知識庫作為智能助手的上下文知識',
        base159: '知識庫',
        base160: '插件',
        base161: '刪除插件',
        base162: '第三步',
        base163: '知識設置',
        base164: '添加插件',
        base165: '添加',
        base166: '已添加',
        base167: '基礎資訊',
        base168: '插件詳情',
        base169: '引用 ·',
        base170: '插件編輯',
        base171: '請選擇鑒權頭部前綴',
        base172: '導入',
        base173: 'AI智慧助手編輯',
    },
    US: {
        bot1: 'AI Assistant',
        bot3: 'Search Assistant',
        bot4: 'Added Successfully',
        bot5: 'Modified Successfully',
        bot6: 'Pinned Successfully',
        bot7: 'Unpinned Successfully',
        bot8: 'Deleted Successfully',
        bot9: 'Create Smart Assistant',
        bot10: 'Create a blank Smart Assistant; the agent can execute specific instructions based on your settings and description, helping you automate tasks.',
        bot11: 'Pin Smart Assistant',
        bot12: 'Unpin Smart Assistant',
        bot13: 'Delete Smart Assistant',
        bot14: 'Orchestrate agents',
        bot15: 'AI Knowledge Base',
        bot16: 'Image Generation',
        bot17: 'Web Search',
        bot18: 'One-Click Enhancement Successful',
        bot19: 'Name cannot be empty',
        bot20: 'The setting cannot be empty',
        bot21: 'Permission members cannot be empty',
        bot22: 'Smart Assistant Modified Successfully',
        bot23: 'User Deleted Successfully',
        bot24: 'Smart Assistant name cannot be empty',
        bot25: 'Create Smart Assistant',
        bot26: 'Edit Smart Assistant',
        bot27: 'Agent Type',
        bot28: 'Name',
        bot29: 'Please enter the AI assistant name',
        bot30: 'Setting',
        bot31: 'One-Click Enhancement',
        bot32: 'Enter a brief setting of the Smart Assistant’s theme, content, or purpose',
        bot33: 'Skills',
        bot34: 'Icon',
        bot35: 'Permission Settings',
        bot36: 'Role',
        bot37: 'Member',
        bot38: 'Owner',
        bot39: 'Actions',
        bot40: 'Remove',
        bot41: 'Add Member',
        bot42: 'Description',
        bot43: 'Briefly introduce your Smart Assistant',
        bot44: 'Greeting',
        bot45: 'Smart Assistant greeting',
        bot46: 'Prompt Inspiration',
        bot47: 'Add',
        bot48: 'Cancel',
        bot49: 'Confirm',
        bot50: 'Please enter the department name',
        bot51: 'Please enter the group name',
        bot52: 'Username',
        bot53: 'Department',
        bot54: 'Group Name',
        bot55: 'Back',
        bot56: 'History',
        bot57: 'Click to upload or drag and drop local audio files here',
        bot58: 'Size: Audio ≤ 50MB, duration within 0.5 hours',
        bot59: 'Formats: .WAV, .MP3, .M4A, .OPUS/OGG, FLAC, WMA, AAC, ALAW in WAV container, MULAW in WAV container, AMR, WebM, SPEEX',
        bot60: 'Extracting text...',
        bot61: 'Audio Content',
        bot62: 'This file type is not supported for upload.',
        bot63: 'Click to upload or drag and drop a PDF here',
        bot64: 'Parsing...',
        bot65: 'Recognition Results',
        bot66: 'Export as Document',
        bot67: 'Document Translation',
        bot68: 'Break language barriers and enable seamless information flow',
        bot69: 'Multilingual Translation',
        bot70: 'Supports Multiple Formats',
        bot71: 'Intelligent Audio',
        bot72: 'Quickly transcribe audio/video into text with precise multilingual support',
        bot73: 'Speech Recognition',
        bot74: 'Multilingual Support',
        bot75: 'Image to Document',
        bot76: 'Upload a single image for fast recognition results',
        bot77: 'Image Recognition',
        bot78: 'Document Export',
        bot79: 'PDF Text Extraction',
        bot80: 'Supports PDF recognition with fast results',
        bot81: 'PDF Recognition',
        bot82: 'PDF Text Extraction',
        bot83: 'Try Now',
        bot84: 'Click to upload or drag and drop an image here',
        bot85: 'Parsing image...',
        bot86: 'Image',
        bot87: 'Image Recognition',
        bot88: 'AI Polishing Preview',
        bot89: 'This file type is not supported for upload.',
        bot90: 'Uploaded file cannot exceed 20MB',
        bot91: 'File name too long, upload failed!',
        bot92: 'Click or drag to add',
        bot93: 'Please select the target language',
        bot94: 'Start Translation',
        bot95: 'Document Translating',
        bot96: 'If you leave the page, the translation will not be canceled. You can view it in the translation history.',
        bot97: 'Document Translated Successfully',
        bot98: 'Download Document',
        bot99: 'Online Preview',
        bot100: 'Upload New Document',
        bot101: "Click to upload intelligent entity avatar",
        bot102: "Department",
        bot104: 'Senior',
        bot105: 'Agent details',
        bot106: 'Details of the AI intelligent assistant',
        bot107: "After clicking the 'submit' button, your agent orchestration will be submitted to NextAI Platform, and you can publish it after completing the submission",
        bot108: 'Before publishing, please submit your agent orchestration to NextAI Plaform',
        bot109: 'orchestration',
        bot110: 'Introduction to settings and logic',
        bot111: 'AI one-click improvement',
        bot112: 'advanced settings',
        bot113: 'After clicking the "Debug" button, you can test your agent orchestration in the "Debug Preview" module on the right',
        bot114: 'debug',
        bot115: 'debug preview',
        bot116: 'Submitted successfully',
        bot117: 'thinking',
        bot118: 'Completed thinking',
        bot119: 'Think time',
        bot120: 'A few seconds',
        bot121: 'S',
        bot122: 'Minute',
        bot123: 'Smart application',
        bot124: 'Plugin',
        bot125: 'Prompt word',
        base126: 'New plugin',
        base127: 'Create a new custom plugin; The large model can drive the AI plug-in to perform external functions according to the schema you set, and obtain and manipulate external information. ',
        base128: 'Search for plugins',
        base129: 'Dept Usr',
        base130: 'Test',
        base131: 'Authentication method',
        base132: 'None',
        base133: 'input parameter',
        base134: 'parameter',
        base135: 'value',
        base136: 'test',
        base137: 'Test Output',
        base138: 'Authentication type',
        base139: 'Authentication header prefix',
        base140: 'key',
        base141: 'Enter a key name',
        base142: 'Input value',
        base143: 'Edit plugin',
        base144: 'Enter a name for the plug-in',
        base145: 'Import from URL',
        base146: 'blank template',
        base147: 'tools available',
        base148: 'description',
        base149: 'method',
        base150: 'path',
        base151: 'Member',
        base152: 'Step 1',
        base153: 'Basic Info',
        base154: 'Step 2',
        base155: 'Auth Config',
        base156: 'Inf. & Orch.',
        base157: 'Use the plug-in tool to extend more capabilities for the smart assistant',
        base158: 'Contextual knowledge referencing knowledge base as a bot',
        base159: 'knowledge base',
        base160: 'plugin',
        base161: 'Delete plugin',
        ibase162: 'Step 3',
        base163: 'K.S.',
        base164: 'Add plugin',
        base165: 'Add',
        base166: 'Added',
        base167: 'Basic information',
        base168: 'plugin details',
        base169: 'Cite. ·',
        base170: 'plugin editing',
        base171: 'Select an authentication header prefix',
        base172: 'Import',
        base173: 'AI intelligent assistant editing',
    },
};
