<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import { Search } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { message, TreeSelect } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const emit = defineEmits(["handleClose", "addSubmit", "handleSubmit"]);
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
  showEdit: Boolean,
  roleInfo: Object,
  formType: String,
});
const loading = ref(false);
const show = ref(props.showEdit);
// 作用域
const showTd = ref(false);
const roleScopes = ref([]);
watch(
  () => roleScopes.value,
  (newVal) => {
    showTd.value = roleScopes.value.includes(1);
    if (!showTd.value) {
      newList3.value = [];
    }
  }
);
// 权限管理树形列表
const tdList = ref([]);
const zsList = ref([]);
const bsList = ref([]);
const newList = ref([]);
const newList2 = ref([]);
const newList3 = ref([]);
const newArrList = ref([]); //用户选中数据
watch(
  () => props.showEdit,
  (val) => {
    show.value = val;
  }
);
onMounted(() => {
  toggleSelection();
  if (props.formType == "edit") {
    console.log(props.roleInfo, "=adddddddddddddddd");
    newArrList.value = props.roleInfo.userInfoList;
    if (props.roleInfo.roles) {
      let arr = props.roleInfo.roles.split(",");
      newList.value = arr.map((item) => Number(item));
      newList2.value = arr.map((item) => Number(item));
      newList3.value = arr.map((item) => Number(item));
    }
    if (props.roleInfo.roleScopes?.length !== 0) {
      roleScopes.value = props.roleInfo.roleScopes?.map(
        (item) => item.departmentId
      );
      console.log(roleScopes.value, "====");
    } else {
      roleScopes.value = [];
    }
  }
  getTableList();
  getDepartment();
  getQxList();
});
// 左侧部门菜单
const defaultProps = ref({
  children: "children",
  label: "departmentName",
  value: "departmentId",
});
const listForm = ref({
  userId: userId.value,
  departmentName: "",
});

const tableId = ref(null); //部门ID
watch(
  () => tableId.value,
  (newVal) => {
    userTable.value.departmentId = newVal;
    getUserTable();
  }
);
const data = ref([]);
const getTableList = () => {
  loading.value = true;
  api.manage.getGroupList(listForm.value).then((res) => {
    if (res.returnCode == 200) {
      data.value = res.data;
      loading.value = false;
      getUserTable();
      tableId.value = res.data[0]?.departmentId; //默认选中第一个部门ID
    }
  });
};
// 部门树形列表点击
const multipleTableRef = ref(null);
const multipleSelection = ref([]);

const handleNodeClick = (data) => {
  tableId.value = data.departmentId;
};
const user = ref(null);
watch(
  () => user.value,
  (newVal) => {
    userTable.value.userNamePattern = newVal;
    getUserTable();
  }
);
// 请求表格数据
const userTable = ref({
  userId: userId.value,
  userNamePattern: "",
  departmentId: tableId.value,
  activate: 1,
  ifDirectly: true,
});
const tableMember = ref([]);
const getUserTable = () => {
  if (userTable.value.departmentId) {
    api.manage.getUserList(userTable.value).then((res) => {
      if (res.returnCode == 200) {
        tableMember.value = res.data;
        toggleSelection();
      }
    });
  }
};
// 右侧选择人 value数组
const changeSel = (value, row) => {
  const index = newArrList.value.findIndex(
    (user) => user.userId === row.userId
  );
  if (index !== -1) {
    newArrList.value.splice(index, 1);
    nextTick(() => {
      multipleTableRef.value.toggleRowSelection(row, false);
    });
  } else {
    newArrList.value.push({
      ...row,
      id: row.userId,
      name: row.userName,
      type: 2,
      ownerType: 0,
    });
  }
};
const handleSelectionChange = (list) => {
  //   有数据则添加
  if (list.length != 0) {
    list.forEach((item) => {
      newArrList.value.push({
        ...item,
        id: item.userId,
        name: item.userName,
        type: 2,
        ownerType: 0,
      });
    });
  } else {
    // 拿table列表数据
    tableMember.value.forEach((item) => {
      const index = newArrList.value.findIndex(
        (user) => user.userId === item.userId
      );
      if (index !== -1) {
        newArrList.value.splice(index, 1);
      }
    });
  }
};
const toggleSelection = () => {
  nextTick(() => {
    newArrList.value.forEach((row) => {
      tableMember.value.forEach((item) => {
        if (item.userId === row.userId) {
          multipleTableRef.value?.toggleRowSelection(item, true);
        }
      });
    });
  });
};
// 部门下拉
const departmentList = ref([
  {
    parentId: null,
    departmentName: "",
    departmentId: null,
    children: [],
  },
]);
const getDepartment = () => {
  api.log.getDepartmentName({ userId: userId.value, pageId: 0 }).then((res) => {
    if (res.returnCode === 200) {
      departmentList.value = res.data;
    }
  });
};
const defaultProps2 = {
  children: "children",
  label: "all_name",
  id: "id",
};
const deleteById = (array, idsToDelete) => {
  // 递归函数来删除具有指定id的对象
  function removeItem(items) {
    return items.reduce((acc, item) => {
      // 检查当前项是否需要被删除
      if (!idsToDelete.includes(item.id)) {
        // 如果有children，递归检查
        if (item.children && item.children.length > 0) {
          item.children = removeItem(item.children); // 递归调用
        }
        acc.push(item); // 将不需要删除的项添加到结果数组
      }
      return acc;
    }, []);
  }

  // 从根节点开始递归
  return removeItem(array);
};
//获取权限树
const getQxList = () => {
  api.manage
    .getRoleTreeApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        let arr = JSON.parse(JSON.stringify(res.data));
        res.data.forEach((item) => {
          if (item.name == "PTGL") {
            tdList.value = [item];
          }
        });
        const idsToDelete = [10034, 10047];
        tdList.value = deleteById(tdList.value, idsToDelete);
        console.log(tdList.value);
        arr[0].children.forEach((item) => {
          if (item.name == "ZSGC") {
            zsList.value = [item];
          } else if (item.name == "BSGL") {
            bsList.value = [item];
          }
        });
      }
    });
};
const treeCheckChange = (e, a, t) => {
  newList.value = a.checkedKeys;
  console.log(a.checkedKeys);
};
const treeCheckChange2 = (e, a, t) => {
  newList2.value = a.checkedKeys;
  console.log(a.checkedKeys);
};
const treeCheckChange3 = (e, a, t) => {
  newList3.value = a.checkedKeys;
  console.log(a.checkedKeys);
};
const rules = ref({
  roleName: [
    {
      required: true,
      message: t("manage.roleForm.role30"),
    },
  ],
  description: [
    {
      required: true,
      message: t("manage.roleForm.role31"),
    },
  ],
  // roleScopes: [{
  //     // required: true,
  //     message: '请配置作用域',
  // }]
});
// 拒绝
const handleClose = () => {
  formRef.value.resetFields();
  show.value = !show.value;
  emit("handleClose");
};
const addSum = ref(false);
// watch(
//   () => props.roleInfo.roles,
//   (val) => {
//     val.forEach((item) => {
//       if (item !== 10025) {
//         addSum.value = true;
//       }
//     });
//   }
// );
const formRef = ref(null);
const filterIds = (menus, arr) => {
  // 定义一个递归函数来检查ID是否存在于菜单结构中
  function containsId(menu, id) {
    if (menu.id === id) {
      return true;
    }
    if (menu.children) {
      for (const child of menu.children) {
        if (containsId(child, id)) {
          return true;
        }
      }
    }
    return false;
  }

  // 过滤arr中的ID，只保留存在于menus结构中的ID
  return arr.filter((id) => containsId(menus[0], id)); // 假设menus数组的第一个元素是根菜单
};
//修改
const handleConfirm = async (formRef) => {
  const userIdLis = newArrList.value.map((item) => item.userId);
  props.roleInfo.userId = userId.value;
  newList2.value = filterIds(zsList.value, newList2.value);
  newList3.value = filterIds(bsList.value, newList3.value);
  newList.value = filterIds(tdList.value, newList.value);
  props.roleInfo.roles = [
    ...newList.value,
    ...newList2.value,
    ...newList3.value,
  ];
  props.roleInfo.roleScopes = roleScopes.value;
  props.roleInfo.userIdList = userIdLis;
  await formRef.validate((valid) => {
    if (valid) {
      api.manage.roleEditApi(props.roleInfo).then((res) => {
        if (res.returnCode === 200) {
          message.success(t("manage.roleForm.role32"));
          show.value = !show.value;
          emit("handleSubmit");
        }
      });
    }
  });
};
// 新增
const addSave = async (formRef) => {
  const userIdLis = newArrList.value.map((item) => item.userId);
  props.roleInfo.userId = userId.value;
  props.roleInfo.roles = [
    ...newList.value,
    ...newList2.value,
    ...newList3.value,
  ];
  props.roleInfo.roleScopes = roleScopes.value;
  props.roleInfo.userIdList = userIdLis;
  await formRef.validate((valid) => {
    if (valid) {
      api.manage.roleAddApi(props.roleInfo).then((res) => {
        if (res.returnCode === 200) {
          message.success(t("manage.roleForm.role33"));
          show.value = !show.value;
          emit("addSubmit");
        }
      });
    }
  });
};
const selectable = (row) => {
  return row.roleId != 4;
};
</script>

<template>
  <div>
    <!-- 角色编辑 -->
    <el-dialog
      :z-index="100"
      destroy-on-close
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>
            {{
              formType == "add"
                ? t("manage.roleForm.role34")
                : t("manage.roleForm.role35")
            }}
          </div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleClose"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <el-divider />
      <div class="flexDiv">
        <el-form
          ref="formRef"
          :model="roleInfo"
          :rules="rules"
          label-position="top"
        >
          <el-form-item prop="roleName" :label="t('manage.roleForm.role34')">
            <el-input
              class="comtent"
              v-model="roleInfo.roleName"
              :placeholder="t('manage.roleForm.role30')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="t('manage.roleForm.role37')">
            <el-input
              class="comtent"
              v-model="roleInfo.description"
              :placeholder="t('manage.roleForm.role38')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="userIdList" :label="t('manage.roleForm.role39')">
            <el-container>
              <div class="container" v-loading="loading">
                <!-- 用户 -->
                <el-input
                  v-model="user"
                  size="large"
                  class="searchInput"
                  :placeholder="t('manage.roleForm.role40')"
                >
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <search />
                    </el-icon>
                  </template>
                </el-input>
                <el-aside class="side">
                  <el-tree
                    ref="treeRef"
                    :data="data"
                    node-key="departmentId"
                    :props="defaultProps"
                    :default-expand-all="true"
                    :expand-on-click-node="false"
                    @node-click="handleNodeClick"
                    highlight-current
                    :current-node-key="tableId"
                  >
                    <template #default="{ data }">
                      <el-tooltip :content="data.departmentName">
                        <div class="admin-text wid100">
                          {{ data.departmentName }}
                        </div>
                      </el-tooltip>
                      <!-- <span class="custom-tree-node">
                                                <span>{{ data.departmentName }}</span>
                                            </span> -->
                    </template>
                  </el-tree>
                </el-aside>
              </div>
              <el-main class="userMain">
                <el-table
                  :data="tableMember"
                  @select-all="handleSelectionChange"
                  @select="changeSel"
                  class="tableHeight"
                  ref="multipleTableRef"
                >
                  <el-table-column type="selection" :selectable="selectable" />
                  <el-table-column
                    prop="userName"
                    :label="$t('manage.roleForm.role1')"
                  />
                  <el-table-column
                    prop="departmentName"
                    :label="$t('manage.roleForm.role41')"
                  >
                    <template #default="scope">
                      <el-tooltip
                        popper-class="toolTip"
                        :content="scope.row.departmentName"
                      >
                        <div class="wid100">{{ scope.row.departmentName }}</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </el-main>
            </el-container>
          </el-form-item>
          <el-form-item prop="roleScopes" :label="$t('manage.roleForm.role42')">
            <!-- <TreeSelect
              v-model:value="roleScopes"
              style="width: 100%"
              :placeholder="$t('manage.roleForm.role43')"
              :field-names="{
                children: 'children',
                label: 'departmentName',
                value: 'departmentId',
              }"
              :tree-data="departmentList"
              tree-checkable
              allow-clear
              :show-checked-strategy="SHOW_PARENT"
              tree-node-filter-prop="departmentName"
            >
            </TreeSelect> -->
            <el-tree-select
              multiple
              :render-after-expand="false"
              show-checkbox
              :data="departmentList"
              v-model="roleScopes"
              :placeholder="$t('manage.roleForm.role43')"
              :props="defaultProps"
              node-key="departmentId"
              check-strictly
            >
              <template #default="{ data }">
                <div>
                  {{ data.departmentName }}
                </div>
              </template>
            </el-tree-select>
          </el-form-item>
          <el-form-item prop="roles" :label="$t('manage.roleForm.role44')">
            <div class="treeList">
              <div class="aside_one">
                <el-tree
                  :check-strictly="true"
                  :data="tdList"
                  show-checkbox
                  node-key="id"
                  @check="treeCheckChange"
                  :default-expanded-keys="[10000]"
                  :default-checked-keys="newList"
                  :props="defaultProps2"
                />
              </div>
              <div>
                <div :class="showTd ? 'aside_two2' : 'aside_two3'">
                  <el-tree
                    :check-strictly="true"
                    :data="zsList"
                    show-checkbox
                    node-key="id"
                    :default-expanded-keys="[10034]"
                    :default-checked-keys="newList2"
                    :props="defaultProps2"
                    @check="treeCheckChange2"
                  />
                </div>
                <div class="aside_two" v-if="showTd">
                  <el-tree
                    :check-strictly="true"
                    :data="bsList"
                    show-checkbox
                    node-key="id"
                    :default-expanded-keys="[10047]"
                    :default-checked-keys="newList3"
                    :props="defaultProps2"
                    @check="treeCheckChange3"
                  />
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer" v-if="props.formType == 'add'">
          <div @click="handleClose(formRef)" class="cancel">
            {{ $t("manage.roleForm.role7") }}
          </div>
          <div @click="addSave(formRef)" class="confirm">
            {{ $t("manage.roleForm.role6") }}
          </div>
        </div>
        <div class="dialog-footer" v-if="props.formType == 'edit'">
          <div @click="handleClose(formRef)" class="cancel">
            {{ $t("manage.roleForm.role7") }}
          </div>
          <div @click="handleConfirm(formRef)" class="confirm">
            {{ $t("manage.roleForm.role6") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-tree-node) {
  width: 225px;
  display: table;
}

:deep(.el-dialog) {
  --el-dialog-width: 756px;
  border-radius: 30px;
  padding: 30px;
  max-height: 872px;
}

:deep(.el-tree) {
  background-color: #f7f8ff;
  color: #3d3d3d;
}

:deep(.el-tree-node__content) {
  height: 34px;
  border-radius: 16px;
  font-size: 15px;
  color: #3d3d3d;
  font-weight: 400;
}

:deep(.el-tree-node__content:hover) {
  background-color: #eceeff;
  color: #0256ff;
}

:deep(.el-tree-node:focus > .el-tree-node__content) {
  background-color: #eceeff;
  color: #0256ff;
}

:deep(.el-input__wrapper) {
  border-radius: 16px;
  width: 130px !important;
  margin-bottom: 10px;
  margin-right: 10px;
}

:deep(.el-select__wrapper) {
  min-height: 30px !important;
  border-radius: 16px !important;
  width: 670px !important;
}

:deep(.custom-tree-node) {
  font-size: 16px;
}

:deep(.el-form-item__label) {
  margin-top: 10px;
}

:deep(.el-tag.is-closable) {
  height: 22px !important;
  line-height: 22px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  background-color: #eceeff !important;
  color: #3d3d3d !important;
}

:deep(.el-tag .el-tag__close:hover) {
  background-color: #3d3d3d !important;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.close-icon {
  font-size: 20px;
}

.flexDiv {
  height: 660px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
}

.form-name {
  font-size: 18px;
  color: #3d3d3d;

  .el-tag {
    display: flex;
    padding: 2px 33px 2px 2px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    font-size: 18px;
    margin-top: 12px;

    .tag-img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .tit {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .comtent {
    margin-bottom: 12px;
  }

  .select {
    margin-bottom: 12px;
  }
}

.container {
  position: relative;

  .side {
    max-width: 200px;
    height: 240px;
    overflow: auto;
    border-radius: 16px;
    background-color: #f7f8ff;
    padding: 0px 2px;
    position: relative;
    margin-right: 20px;
  }
}

.tableHeight {
  height: 300px;
}

.comtent {
  .aside {
    width: 200px;
    height: 200px;
    background-color: #f7f8ff;
    margin-right: 6px;
    border-radius: 16px;
    padding: 8px;

    .baseInput {
      width: 180px;
      height: 36px;
      height: 36px;
      font-size: 14px;
      color: #8a8a8a;
      font-weight: 400;
    }

    .tree {
      margin-top: 10px;
      height: 314px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .rightMain {
    width: 470px;
    height: 200px;
    border-radius: 0 0 16px 16px;

    .table {
      height: 200px;
    }
  }
}

// 配置权限范围
.treeList {
  display: flex;

  .aside_one {
    width: 325px;
    height: 380px;
    background-color: #f7f8ff;
    border-radius: 16px;
    padding: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 20px;
  }

  .aside_two {
    width: 325px;
    height: 180px;
    background-color: #f7f8ff;
    border-radius: 16px;
    padding: 8px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .aside_two2 {
    width: 325px;
    height: 180px;
    background-color: #f7f8ff;
    border-radius: 16px;
    padding: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20px;
  }

  .aside_two3 {
    width: 325px;
    height: 380px;
    background-color: #f7f8ff;
    border-radius: 16px;
    padding: 8px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
</style>
