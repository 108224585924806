<template>
  <div class="lookContent">
    <div class="deleteDiv" @click="openDelete" v-if="!deleteType">
      <img :src="icon.hszImg" alt="" class="deleteImg" />
      {{ t("base.base248") }}
    </div>
    <div class="gobackDiv" @click="closeDelete" v-if="deleteType">
      <img :src="icon.goBackImgLook" alt="" class="deleteImg" />
      {{ t("base.base251") }}
    </div>
    <div class="lookAllMargin">
      <div class="lookTopDiv">
        <el-input
          :placeholder="t('base.base130')"
          prefix-icon="Search"
          class="inputSearch"
          v-model="sessionNamePattern"
          @input="searchList"
        />
        <template v-if="!deleteType">
          <el-button
            @click="getTopicList(item.value)"
            :class="navActive == item.value ? 'itemBtnAc' : 'itemBtn'"
            v-for="item in navList"
            :key="item"
          >
            <img :src="item.url" class="qw" alt="" />
            {{ t(item.name) }}
          </el-button>
          <!-- <el-button class="itemBtn"><img :src="icon.moreImgLook" alt="" /></el-button> -->
          <!-- <div class="optionsDiv">
            <img :src="icon.optionsImg" alt="" class="optionsImg" />
          </div> -->
        </template>
      </div>
      <div v-loading="topicLoading" class="headerDiv">
        <div class="lookView" @scroll="scrollLoad" v-if="topicList.length != 0">
          <lookView
            type="look"
            :list="topicList"
            :deleteType="deleteType"
            :like="navActive == 2 ? true : false"
            :draggable="false"
            @goShare="goShare"
            @goLookView="goLookView"
            @reshList="searchList"
          ></lookView>
        </div>
        <img
          v-if="topicList.length == 0 && !topicLoading"
          :src="icon.noData"
          alt=""
          class="nodata"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import lookView from "@/components/look/index.vue";
import api from "@/api";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const store = useStore();
const sessionNamePattern = ref("");
const topicList = ref([]);
const deleteType = ref(false);
const userId = ref(store.state.userId);
const topicLoading = ref(false);
const navList = ref([
  // {
  //   name: "base.base98",
  //   url: icon.qwImg,
  //   value: 0,
  // },
  // {
  //   name: "base.base99",
  //   url: icon.rmImg,
  //   value: 1,
  // },
  {
    name: "base.base135",
    url: icon.scImg,
    value: 2,
  },
]);
// 列表参数
const listFomr = ref({
  userId: store.state.userId,
  sessionNamePattern: "",
  pageId: 1,
  perPage: 10,
});
const navActive = ref(null);
onMounted(() => {
  if (route.query.type == 1) {
    openDelete();
  } else {
    getTopic();
  }
});
watch(
  () => route.query,
  (newValue, oldValue) => {
    if (route.query.type == 1) {
      openDelete();
    }
  }
);
// 下拉加载
const scrollLoad = (e) => {
  const scrollHeight = e.target.scrollHeight;
  const scrollTop = e.target.scrollTop;
  const clientHeight = e.target.clientHeight;
  if (scrollTop + clientHeight >= scrollHeight - 1) {
    load();
  }
};
const load = () => {
  listFomr.value.pageId++;
  if (navActive.value == null) {
    getTopic();
  } else {
    getListFn(navActive.value);
  }
};
const searchGetList = (index) => {
  if (index == null) {
    getTopic();
  } else {
    getListFn(index);
  }
};
const getTopicList = (index) => {
  sessionNamePattern.value = "";
  topicList.value = []
  listFomr.value = {
    userId: store.state.userId,
    sessionNamePattern: "",
    pageId: 1,
    perPage: 10,
  };
  if (navActive.value == index) {
    navActive.value = null;
    getTopic();
    return;
  }
  navActive.value = index;
  getListFn(index);
};
const getListFn = (index) => {
  if (index == 0) {
    // 权威
    getQw();
  }
  if (index == 1) {
    // 热门
    getRm();
  }
  if (index == 2) {
    // 收藏
    getSc();
  }
};
const getQw = () => {
  topicLoading.value = true;
  api.share
    .getVerifyQuertApi(listFomr.value)
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        if (listFomr.value.pageId == 1) {
          topicList.value = res.data;
        } else {
          topicList.value = topicList.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
const getRm = () => {
  topicLoading.value = true;
  api.base
    .hotQuery(listFomr.value)
    .then((res) => {
      topicLoading.value = false;
      if (listFomr.value.pageId == 1) {
        topicList.value = res.data;
      } else {
        topicList.value = topicList.value.concat(res.data);
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
const getSc = () => {
  topicLoading.value = true;
  api.base
    .likeAllQuery(listFomr.value)
    .then((res) => {
      topicLoading.value = false;
      if (listFomr.value.pageId == 1) {
        topicList.value = res.data;
      } else {
        topicList.value = topicList.value.concat(res.data);
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
// 防止分页
const searchList = () => {
  listFomr.value.pageId = 1;
  if (navActive.value == null) {
    sessionNamePattern.value == listFomr.value.sessionNamePattern;
    getTopic();
  } else {
    listFomr.value.sessionNamePattern = sessionNamePattern.value;
    getListFn(navActive.value);
  }
};
// 获取左侧最近话题
const getTopic = () => {
  topicLoading.value = true;
  api.header
    .chatAllQuery({
      userId: userId.value,
      namePattern: sessionNamePattern.value,
      operationType: !deleteType.value ? 0 : 1,
      pageId: listFomr.value.pageId,
      perPage: 10,
    })
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        if (listFomr.value.pageId == 1) {
          topicList.value = res.data;
        } else {
          topicList.value = topicList.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
// 打开回收站
const openDelete = () => {
  listFomr.value.pageId = 1;
  sessionNamePattern.value = "";
  listFomr.value.sessionNamePattern = "";
  navActive.value = null;
  store.commit("SET_MANGER", "base.base248");
  deleteType.value = true;
  getTopic();
};
// 关闭回收站
const closeDelete = () => {
  listFomr.value.pageId = 1;
  sessionNamePattern.value = "";
  listFomr.value.sessionNamePattern = "";
  store.commit("SET_MANGER", "");
  deleteType.value = false;
  getTopic();
};
// 去验证
const goShare = (item) => {
  store.commit("SET_YZ", item);
  router.push("/share/details");
};
// 去对话详情
const goLookView = (item) => {
  api.chat
    .chatDescQuery({
      userId: userId.value,
      sessionId: item.sessionId,
      sessionType: item.sessionType,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.data.sessionType == 1 || navActive.value == 0) {
          store.commit("SET_CHATTYPE", "base");
        } else {
          store.commit("SET_CHATTYPE", "chat");
        }
        store.commit("SET_TOPIC", res.data);
        // 判断权威
        if (res.data.isValid == 1) {
          router.push("/chat");
          store.commit("SET_CHATPARMAS", {
            type: res.data.isOwner == 1 ? "looks" : "look",
            isValid: res.data.isValid,
          });
        } else {
          router.push("/chat");
          store.commit("SET_CHATPARMAS", {
            type: res.data.isOwner == 1 ? "chat" : "look",
            isValid: res.data.isValid,
          });
        }
      }
    });
};
</script>

<style lang="scss" scoped>
.lookContent {
  padding: 30px;
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 20px 0px #dbdfff78;
  position: relative;
}
.lookTopDiv {
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 20px;
}
.inputSearch {
  width: 236px;
  margin-right: 9px;
  :deep(.el-input__wrapper) {
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px 0px #ffffff;
    border-radius: 16px;
    height: 46px;
    font-size: 18px;
    font-weight: 400;
  }
}
.itemBtn {
  height: 52px;
  color: #8a8a8a;
  border-radius: 16px;
  border: 2px solid #edeef6;
  padding: 12px 15px;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemBtnAc {
  height: 52px;
  color: #8a8a8a;
  border-radius: 16px;
  border: 2px solid #edeef6;
  background-color: #eceeff;
  padding: 12px 15px;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qw {
  width: 26px;
  margin-right: 6px;
}
.otherImg {
  width: 24px;
  margin-right: 6px;
}
.moreImg {
  width: 33px;
}
.lookView {
  height: 100%;
  overflow: auto;
  position: relative;
}
.optionsDiv {
  background-color: #edeef6;
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  cursor: pointer;
}
.optionsImg {
  width: 25px;
}
.lookAllMargin {
  width: 1010px;
  height: 100%;
  margin: 0 auto;
}
.deleteDiv {
  height: 34px;
  border-radius: 12px;
  border: 1px solid #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 5px 12px;
}
.deleteImg {
  width: 25px;
  margin-right: 3px;
}
.gobackDiv {
  height: 34px;
  border-radius: 12px;
  border: 1px solid #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 5px 12px;
}
.nodata {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.headerDiv {
  height: calc(100% - 80px);
  position: relative;
}
</style>
