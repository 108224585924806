<template>
  <div class="topRightDiv" v-if="ptList.length != 0">
    <div class="rightDivItem">
      <div class="topTitle">
        <div class="rightTitle">{{ t("menu.index21") }}</div>
        <!-- 悬浮 -->
        <el-popover
          placement="bottom"
          popper-class="formDIv"
          v-if="chatTokenList.length != 0"
        >
          <template #reference>
            <div class="formDiv">
              <span>{{ t("menu.index11") }}</span>
              <img :src="icon.eImg" alt="" class="resonImg" />
              <img :src="icon.bImg" alt="" class="resonImgActive" />
            </div>
          </template>
          <el-table
            :data="chatTokenList[0].value[chatTokenList[0].active].limit"
          >
            <el-table-column :label="t('menu.index11')">
              <template #default="scope">
                <div class="tableCenter">
                  <div class="tableText">{{ scope.row.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="number" :label="t('menu.index30')">
              <template #default="scope">
                <span class="tableText"
                  >{{ t("menu.index32") }}{{ scope.row.number
                  }}{{ t("menu.index24") }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-popover>
      </div>
      <el-row class="rowHeight">
        <el-col :span="12" class="leftCol" v-if="ptList.length != 0">
          <div class="topTip">{{ t("menu.index22") }}</div>
          <el-dropdown @command="changeSearch" class="dropDiv">
            <span class="flexCenter">
              <img :src="ptList[platformType].url" alt="" class="selIcon" />
              <span class="topName">{{ t(ptList[platformType].label) }}</span>
              <el-icon class="el-icon--right rightIcon">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(item, index) in ptList"
                  :key="index"
                  :command="index"
                >
                  <div class="flexCenter">
                    <img :src="item.url" alt="" class="selIcon" />
                    <span class="topName">{{ t(item.label) }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
        <el-col :span="12" class="leftCol" v-if="chatTokenList.length != 0">
          <!-- 上 -->
          <div class="topRightFlex">
            <div class="processDiv">
              <el-progress
                type="circle"
                :percentage="
                  chatTokenList[0].value[chatTokenList[0].active].percentage
                "
                :color="
                  getColor(
                    chatTokenList[0].value[chatTokenList[0].active].percentage,
                    chatTokenList[0].value[chatTokenList[0].active].numberType
                  )
                "
                :stroke-width="20"
              >
                <template></template>
              </el-progress>
            </div>
            <el-tooltip
              popper-class="toolTip"
              :content="
                t('menu.index23') +
                chatTokenList[0].value[chatTokenList[0].active].remainder +
                t('menu.index24')
              "
            >
              <div class="left78">
                <div class="processTip">{{ t("menu.index23") }}</div>
                <div
                  class="processTip flexCenter"
                  :style="
                    'color:' +
                    getColor(
                      chatTokenList[0].value[chatTokenList[0].active]
                        .percentage,
                      chatTokenList[0].value[chatTokenList[0].active].numberType
                    )
                  "
                >
                  <div class="processNumber leftNumber">
                    {{
                      getNumber(
                        chatTokenList[0].value[chatTokenList[0].active]
                          .remainder
                      )
                    }}
                  </div>
                  <div>{{ t("menu.index24") }}</div>
                </div>
              </div>
            </el-tooltip>
          </div>
          <!-- 下 -->
          <div>
            <el-select v-model="chatTokenList[0].active" class="chatSelete">
              <el-option
                v-for="(item, index) in chatTokenList[0].value"
                :key="item.modelType"
                :label="item.modelType"
                :value="index"
              >
                <div class="flexCenter">
                  <img :src="returnModel(item.icon)" alt="" class="gptAzure" />
                  <span class="selText">{{ item.modelType }}</span>
                </div>
              </el-option>
              <template #prefix>
                <img
                  :src="
                    returnModel(
                      chatTokenList[0].value[chatTokenList[0].active].icon
                    )
                  "
                  alt=""
                  class="gptAzure"
                />
              </template>
            </el-select>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="rightDivItem">
      <div class="rowBottomHeight row" ref="resonOne">
        <div class="col" v-if="imgTokenList.length != 0">
          <div class="end30">
            <el-popover placement="bottom" popper-class="formDIv">
              <template #reference>
                <div class="formDiv">
                  <span>{{ t("menu.index11") }}</span>
                  <img :src="icon.eImg" alt="" class="resonImg" />
                  <img :src="icon.bImg" alt="" class="resonImgActive" />
                </div>
              </template>
              <el-table
                :data="imgTokenList[0].value[imgTokenList[0].active].limit"
              >
                <el-table-column :label="t('menu.index11')">
                  <template #default="scope">
                    <div class="tableCenter">
                      <div class="tableText">{{ scope.row.name }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column property="number" :label="t('menu.index30')">
                  <template #default="scope">
                    <span class="tableText"
                      >{{ t("menu.index32") }}{{ scope.row.number
                      }}{{ t("menu.index26") }}</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-popover>
          </div>
          <div class="bottomEnd">
            <!-- 上 -->
            <div class="topRightFlex">
              <div class="processDiv">
                <el-progress
                  type="circle"
                  :percentage="
                    imgTokenList[0].value[imgTokenList[0].active].percentage
                  "
                  :color="
                    getColor(
                      imgTokenList[0].value[imgTokenList[0].active].percentage,
                      imgTokenList[0].value[imgTokenList[0].active].numberType
                    )
                  "
                  :stroke-width="20"
                >
                  <template> </template>
                </el-progress>
              </div>
              <el-tooltip
                popper-class="toolTip"
                :content="
                  t('menu.index25') +
                  imgTokenList[0].value[imgTokenList[0].active].remainder +
                  t('menu.index26')
                "
              >
                <div class="left78">
                  <div class="processTip">{{ t("menu.index25") }}</div>
                  <div
                    class="processTip flexCenter"
                    :style="
                      'color:' +
                      getColor(
                        imgTokenList[0].value[imgTokenList[0].active]
                          .percentage,
                        imgTokenList[0].value[imgTokenList[0].active].numberType
                      )
                    "
                  >
                    <div class="processNumber leftNumber">
                      {{
                        getNumber(
                          imgTokenList[0].value[imgTokenList[0].active]
                            .remainder
                        )
                      }}
                    </div>
                    <div>{{ t("menu.index26") }}</div>
                  </div>
                </div>
              </el-tooltip>
            </div>
            <!-- 下 -->
            <div>
              <el-select v-model="imgTokenList[0].active" class="chatSelete">
                <el-option
                  v-for="(item, index) in imgTokenList[0].value"
                  :key="item.modelType"
                  :label="item.modelType"
                  :value="index"
                >
                  <div class="flexCenter">
                    <img
                      :src="returnModel(item.icon)"
                      alt=""
                      class="gptAzure"
                    />
                    <span class="selText">{{ item.modelType }}</span>
                  </div>
                </el-option>
                <template #prefix>
                  <img
                    :src="
                      returnModel(
                        imgTokenList[0].value[imgTokenList[0].active].icon
                      )
                    "
                    alt=""
                    class="gptAzure"
                  />
                </template>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col" v-if="embeddingTokenList.length != 0">
          <div class="end30">
            <el-popover placement="bottom" popper-class="formDIv">
              <template #reference>
                <div class="formDiv">
                  <span>{{ t("menu.index11") }}</span>
                  <img :src="icon.eImg" alt="" class="resonImg" />
                  <img :src="icon.bImg" alt="" class="resonImgActive" />
                </div>
              </template>
              <el-table
                :data="
                  embeddingTokenList[0].value[embeddingTokenList[0].active]
                    .limit
                "
              >
                <el-table-column :label="t('menu.index11')">
                  <template #default="scope">
                    <div class="tableCenter">
                      <div class="tableText">{{ scope.row.name }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column property="number" :label="t('menu.index30')">
                  <template #default="scope">
                    <span class="tableText"
                      >{{ t("menu.index32") }}{{ scope.row.number
                      }}{{ t("menu.index28") }}</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-popover>
          </div>
          <div class="bottomEnd">
            <!-- 上 -->
            <div class="topRightFlex">
              <div class="processDiv">
                <el-progress
                  type="circle"
                  :percentage="
                    embeddingTokenList[0].value[embeddingTokenList[0].active]
                      .percentage
                  "
                  :color="
                    getColor(
                      embeddingTokenList[0].value[embeddingTokenList[0].active]
                        .percentage,
                      embeddingTokenList[0].value[embeddingTokenList[0].active]
                        .numberType
                    )
                  "
                  :stroke-width="20"
                >
                  <template> </template>
                </el-progress>
              </div>
              <el-tooltip
                popper-class="toolTip"
                :content="
                  t('menu.index27') +
                  embeddingTokenList[0].value[embeddingTokenList[0].active]
                    .remainder +
                  t('menu.index28')
                "
              >
                <div class="left78">
                  <div class="processTip">{{ t("menu.index27") }}</div>
                  <div
                    class="processTip flexCenter"
                    :style="
                      'color:' +
                      getColor(
                        embeddingTokenList[0].value[
                          embeddingTokenList[0].active
                        ].percentage,
                        embeddingTokenList[0].value[
                          embeddingTokenList[0].active
                        ].numberType
                      )
                    "
                  >
                    <div class="processNumber leftNumber">
                      {{
                        getNumber(
                          embeddingTokenList[0].value[
                            embeddingTokenList[0].active
                          ].remainder
                        )
                      }}
                    </div>
                    <div>{{ t("menu.index28") }}</div>
                  </div>
                </div>
              </el-tooltip>
            </div>
            <!-- 下 -->
            <div>
              <el-select
                v-model="embeddingTokenList[0].active"
                class="chatSelete"
              >
                <el-option
                  v-for="(item, index) in embeddingTokenList[0].value"
                  :key="item.modelType"
                  :label="item.modelType"
                  :value="index"
                >
                  <div class="flexCenter">
                    <img
                      :src="returnModel(item.icon)"
                      alt=""
                      class="gptAzure"
                    />
                    <span class="selText">{{ item.modelType }}</span>
                  </div>
                </el-option>
                <template #prefix>
                  <img
                    :src="
                      returnModel(
                        embeddingTokenList[0].value[
                          embeddingTokenList[0].active
                        ].icon
                      )
                    "
                    alt=""
                    class="gptAzure"
                  />
                </template>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col" v-if="ocrTokenList.length != 0">
          <div class="end30">
            <el-popover placement="bottom" popper-class="formDIv">
              <template #reference>
                <div class="formDiv">
                  <span>{{ t("menu.index11") }}</span>
                  <img :src="icon.eImg" alt="" class="resonImg" />
                  <img :src="icon.bImg" alt="" class="resonImgActive" />
                </div>
              </template>
              <el-table
                :data="ocrTokenList[0].value[ocrTokenList[0].active].limit"
              >
                <el-table-column :label="t('menu.index11')">
                  <template #default="scope">
                    <div class="tableCenter">
                      <div class="tableText">{{ scope.row.name }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column property="number" :label="t('menu.index30')">
                  <template #default="scope">
                    <span class="tableText"
                      >{{ t("menu.index32") }}{{ scope.row.number
                      }}{{ t("menu.index28") }}</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-popover>
          </div>
          <div class="bottomEnd">
            <!-- 上 -->
            <div class="topRightFlex">
              <div class="processDiv">
                <el-progress
                  type="circle"
                  :percentage="
                    ocrTokenList[0].value[ocrTokenList[0].active].percentage
                  "
                  :color="
                    getColor(
                      ocrTokenList[0].value[ocrTokenList[0].active].percentage,
                      ocrTokenList[0].value[ocrTokenList[0].active].numberType
                    )
                  "
                  :stroke-width="20"
                >
                  <template> </template>
                </el-progress>
              </div>
              <el-tooltip
                popper-class="toolTip"
                :content="
                  t('menu.index29') +
                  ocrTokenList[0].value[ocrTokenList[0].active].remainder +
                  t('menu.index31')
                "
              >
                <div class="left78">
                  <div class="processTip">{{ t("menu.index29") }}</div>
                  <div
                    class="processTip flexCenter"
                    :style="
                      'color:' +
                      getColor(
                        ocrTokenList[0].value[ocrTokenList[0].active]
                          .percentage,
                        ocrTokenList[0].value[ocrTokenList[0].active].numberType
                      )
                    "
                  >
                    <div class="processNumber leftNumber">
                      {{
                        getNumber(
                          ocrTokenList[0].value[ocrTokenList[0].active]
                            .remainder
                        )
                      }}
                    </div>
                    <div>{{ t("menu.index31") }}</div>
                  </div>
                </div>
              </el-tooltip>
            </div>
            <!-- 下 -->
            <div>
              <el-select v-model="ocrTokenList[0].active" class="chatSelete">
                <el-option
                  v-for="(item, index) in ocrTokenList[0].value"
                  :key="item.modelType"
                  :label="item.modelType"
                  :value="index"
                >
                  <div class="flexCenter">
                    <img
                      :src="returnModel(item.icon)"
                      alt=""
                      class="gptAzure"
                    />
                    <span class="selText">{{ item.modelType }}</span>
                  </div>
                </el-option>
                <template #prefix>
                  <img
                    :src="
                      returnModel(
                        ocrTokenList[0].value[ocrTokenList[0].active].icon
                      )
                    "
                    alt=""
                    class="gptAzure"
                  />
                </template>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div
        class="leftAbs"
        @click="changeLeftReson(resonOne)"
        v-if="
          imgTokenList.length != 0 &&
          embeddingTokenList.length != 0 &&
          ocrTokenList.length != 0
        "
      >
        <img :src="icon.leftImg" class="leftImg" alt="" />
      </div>
      <div
        class="rightAbs"
        @click="changeRightReson(resonOne)"
        v-if="
          imgTokenList.length != 0 &&
          embeddingTokenList.length != 0 &&
          ocrTokenList.length != 0
        "
      >
        <img :src="icon.rightImg" class="leftImg" alt="" />
      </div>
    </div>
  </div>
  <div class="nodata" v-else>
    <el-empty :description="t('login.login64')" />
  </div>
</template>

<script setup>
import { returnModel } from "@/utils/gpt";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { useI18n } from "vue-i18n";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const platformType = ref(0);
const chatTokenList = ref([]);
const embeddingTokenList = ref([]);
const imgTokenList = ref([]);
const ocrTokenList = ref([]);
const resonOne = ref(null);
const ptList = ref([
  {
    value: "Azure",
    label: "statistics.name25",
    url: icon.baseAzure,
  },
  {
    value: "Baidu",
    label: "statistics.name26",
    url: icon.baseBaidu,
  },
  {
    value: "Ali",
    label: "statistics.name27",
    url: icon.baseAli,
  },
  {
    value: "Local",
    label: "statistics.name38",
    url: icon.baseOllama,
  },
]);
onMounted(() => {
  getModel();
});
// 数字进行单位展示
const getNumber = (value) => {
  let language = store.state.lang;
  if (value === undefined) {
    return 0;
  }
  let num;
  let unit;
  if (language != "US") {
    if (Math.abs(value) >= 100000000) {
      num = Math.trunc(value / 100000000);
      unit = language == "CN" ? "亿" : "億";
      if (value % 100000000 !== 0) {
        unit += "+";
      }
    } else if (Math.abs(value) >= 10000) {
      num = Math.trunc(value / 10000);
      unit = language == "CN" ? "万" : "萬";
      if (value % 10000 !== 0) {
        unit += "+";
      }
    } else {
      num = value;
      unit = "";
    }
  } else {
    if (Math.abs(value) >= 1000000000) {
      num = Math.trunc(value / 1000000000);
      unit = "B";
      if (value % 100000000 !== 0) {
        unit += "+";
      }
    } else if (Math.abs(value) >= 1000000) {
      num = Math.trunc(value / 1000000);
      unit = "M";
      if (value % 1000000 !== 0) {
        unit += "+";
      }
    } else if (Math.abs(value) >= 1000) {
      num = Math.trunc(value / 1000);
      unit = "K";
      if (value % 1000 !== 0) {
        unit += "+";
      }
    } else {
      num = value;
      unit = "";
    }
  }
  return num + unit;
};
// 获取已有资源
const getModel = () => {
  api.chat
    .indexDictGet({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        // 过滤掉ptList中存在于后端返回数据的对象
        ptList.value = ptList.value.filter((item) =>
          res.data.includes(item.value)
        );
        if (ptList.value.length != 0) {
          getToken();
        }
      }
    });
};
// 切换资源
const changeSearch = (e) => {
  platformType.value = e;
  chatTokenList.value = [];
  embeddingTokenList.value = [];
  imgTokenList.value = [];
  getToken();
};
// 动态获取color
const getColor = (number, type) => {
  // 正常
  if (type == 0) {
    if (number > 20) {
      // 绿色
      return "#14AE5C";
    } else if (number > 10) {
      // 黄色
      return "#FFC300";
    } else if (number > 0) {
      // 红色
      return "#F02D63";
    }
  }
  if (type == 1 || type == 2) {
    return "#8A8A8A";
  }
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 2);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 获取token
const getToken = () => {
  api.home
    .getIndexTokens({
      userId: userId.value,
      platformType: ptList.value[platformType.value].value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.active = 0;
          if (item.value.length != 0) {
            item.value.forEach((i) => {
              let maxNumber = 0;
              i.percentage = 0;
              i.numberType = 0; //0 正常  1等于 2 超出
              i.remainder = 0;
              // 获取最大token
              if (i.limit.length != 0) {
                maxNumber = i.limit.reduce(
                  (max, obj) => (obj.number > max ? obj.number : max),
                  i.limit[0].number
                );
              }
              // 没有资源
              if (maxNumber == 0) {
                i.percentage = 100;
                i.numberType = 2;
                i.remainder = 0;
              } else {
                // 正常除
                let a = i.used / maxNumber;
                // 超出直接返回超出值
                if (a > 1) {
                  i.numberType = 2;
                  if (Number.isInteger(a)) {
                    i.percentage = 100;
                  } else {
                    if (a < 2) {
                      const [integerPart, b] = a.toString().split(".");
                      let c = parseFloat("0." + b);
                      i.percentage = c * 100;
                    } else {
                      i.percentage = 100;
                    }
                  }
                } else {
                  let d = 100 - a * 100;
                  if (d == 0) {
                    i.percentage = 100;
                    i.numberType = 1;
                  } else {
                    i.percentage = d;
                    i.numberType = 0;
                  }
                }
                i.remainder = maxNumber - i.used;
              }
            });
          }
        });
        chatTokenList.value = res.data.filter(
          (item) => item.functionType == "文本生成"
        );
        embeddingTokenList.value = res.data.filter(
          (item) => item.functionType == "向量化"
        );
        imgTokenList.value = res.data.filter(
          (item) => item.functionType == "文生图"
        );
        ocrTokenList.value = res.data.filter(
          (item) => item.functionType == "光学扫描"
        );
      }
    });
};
</script>

<style lang="less" scoped>
.topRightDiv {
  min-width: 475px;
  width: 30%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nodata {
  background-color: #fefefe;
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0px 0px 16px 0 #dbdfff82;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 475px;
  width: 30%;
}
.rightDivItem {
  width: calc(100% - 40px);
  background-color: #fefefe;
  height: calc(50% - 45px);
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0px 0px 16px 0 #dbdfff82;
  position: relative;
}
.rightDivItem:hover {
  .leftAbs {
    display: block;
  }
  .rightAbs {
    display: block;
  }
}
.formDiv {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  padding: 2px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .resonImg {
    display: inline-block;
    margin-left: 5px;
    width: 11px;
  }
  .resonImgActive {
    display: none;
    margin-left: 5px;
    width: 11px;
  }
}
.formDiv:hover {
  border-radius: 12px;
  background-color: #dbdfff;
  color: #0256ff;
  .resonImg {
    display: none;
    margin-left: 5px;
    width: 11px;
  }
  .resonImgActive {
    display: inline-block;
    margin-left: 5px;
    width: 11px;
  }
}
.rightTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 600;
}
.topTitle {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topTip {
  color: #8a8a8a;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 12px;
}
.selIcon {
  width: 25px;
  margin-right: 6px;
}
.topName {
  font-size: 22px;
  color: #0087ff;
  font-weight: 400;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.dropDiv {
  height: 48px;
  background-color: #f7f8ff;
  padding: 0px 12px;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 0px #dbdfff;
}
.rightIcon {
  font-size: 20px;
  color: #3d3d3d;
}
.rowHeight {
  height: calc(100% - 30px);
}
.leftCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.processDiv {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: #f7f8ff;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.processTip {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.processNumber {
  font-size: 20px;
  font-weight: 700;
}
.topRightFlex {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 68px;
  width: 100%;
}
:deep(.el-progress-circle) {
  width: 55px !important;
  height: 55px !important;
}
.chatSelete {
  width: 155px;
}
.selText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
}
:deep(.el-select__wrapper) {
  height: 36px;
  color: #3d3d3d;
  font-size: 16px;
  font-weight: 400;
  border-radius: 9px;
  border: 1px solid #edeef6;
  box-shadow: 0px 0px 0px;
}
:deep(.el-select__caret) {
  font-size: 16px;
  color: #8a8a8a;
}
.rowBottomHeight {
  height: 100%;
}
.end30 {
  display: flex;
  justify-content: flex-end;
  height: 30px;
}
.bottomEnd {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: calc(100% - 30px);
  width: 100%;
}
.leftImg {
  width: 12px;
  cursor: pointer;
}
.leftAbs {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(0, -50%);
  display: none;
}
.rightAbs {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0, -50%);
  display: none;
}
.leftNumber {
  max-width: calc(100% - 45px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 5px;
}
.left78 {
  width: calc(100% - 78px);
}
.row {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
.col {
  display: block;
  max-width: 50%;
  flex: 0 0 50%;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
.tableText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableCenter {
  display: flex;
  align-items: center;
}
.gptAzure {
  width: 20px;
  margin-right: 10px;
}
</style>
