<template>
  <div class="widhei100" v-loading="loading">
    <VueOfficeExcel
      :options="options"
      :src="props.excelUrl"
      @rendered="renderedHandler"
    ></VueOfficeExcel>
  </div>
</template>

<script setup>
//引入VueOfficeExcel组件
import VueOfficeExcel from "@vue-office/excel";
//引入相关样式
import "@vue-office/excel/lib/index.css";
import { ref } from "vue";
const props = defineProps(["excelUrl"]);
const emit = defineEmits(["changeExcelBodyData"]);
const options = {
  xls: false, //预览xlsx文件设为false；预览xls文件设为true
  minColLength: 0, // excel最少渲染多少列，如果想实现xlsx文件内容有几列，就渲染几列，可以将此值设置为0.
  minRowLength: 0, // excel最少渲染多少行，如果想实现根据xlsx实际函数渲染，可以将此值设置为0.
  widthOffset: 10, //如果渲染出来的结果感觉单元格宽度不够，可以在默认渲染的列表宽度上再加 Npx宽
  heightOffset: 10, //在默认渲染的列表高度上再加 Npx高
  beforeTransformData: (workbookData) => {
    return workbookData;
  },
  transformData: (workbookData) => {
    return workbookData;
  },
};
const loading = ref(true);
const renderedHandler = () => {
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.widhei100 {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
