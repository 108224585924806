<template>
  <!-- 开场白 -->
  <div class="messageView call">
    {{
      props.botItem.templateCall
        ? props.botItem.templateCall
        : props.templateCall
        ? props.templateCall
        : t("base.base186")
    }}
  </div>
  <!-- 灵感 -->
  <div>
    <div v-for="(idea, index) in props.botItem.suggest" :key="index">
      <div class="ideaName" @click="saveMessage(idea.value || idea)">
        {{ idea.value || idea }}
      </div>
    </div>
  </div>
  <template v-for="item in props.chatList" :key="item">
    <!-- 问题 -->
    <div class="qDiv">
      <div class="qMessage">{{ item.question }}</div>
    </div>
    <!-- 粘贴 缺少循环 -->
    <div class="flexCenterEnd">
      <el-tooltip popper-class="toolTip" :content="t('base.base194')">
        <div class="lineIcon" @click="copyFn(item.question)">
          <img :src="icon.chatCopySvg" alt="" class="wid36" />
        </div>
      </el-tooltip>
    </div>
    <div
      v-if="!item.resonType && item.answers[item.chatActive - 1].answerContent"
      class="resonDiv"
      @click="getReson(item)"
    >
      <img :src="icon.searchSvg" alt="" class="wid19" />
      <div class="resonText">{{ t("base.base276") }}</div>
    </div>
    <chatFile
      v-if="item.resonType"
      type="bot"
      :item="item"
      :resonDialog="resonDialog"
      @openFileView="openFileView"
      @openTalk="openTalk"
      @openReson="openReson"
      @openResultDialog="openResultDialog"
    ></chatFile>
    <!-- 插件 -->
    <plugView
      type="bot"
      :pluginInfo="item.answers[item.chatActive - 1].pluginInfo"
      v-if="
        item.answers[item.chatActive - 1].pluginInfo &&
        item.answers[item.chatActive - 1].pluginInfo.length != 0
      "
    ></plugView>
    <!-- deepseek思考 -->
    <thinkMessageView
      v-if="
        (item.answers[item.chatActive - 1].model == 12 ||
          item.answers[item.chatActive - 1].model == 'DeepSeek-R1') &&
        props.ifThink
      "
      :message="item.answers[item.chatActive - 1].thinkContent"
      :chatMessage="item.answers[item.chatActive - 1].answerContent"
      :time="item.answers[item.chatActive - 1].thinkTime"
    ></thinkMessageView>
    <!-- 回答 -->
    <messageView
      v-if="item.answers[item.chatActive - 1].answerContent"
      :message="item.answers[item.chatActive - 1].answerContent"
      :class="item.answers[item.chatActive - 1].langType ? '' : 'messageView'"
    ></messageView>
    <span
      class="loader messageView"
      v-if="
        !item.answers[item.chatActive - 1].answerContent &&
        item.answers[item.chatActive - 1].model != 12 &&
        item.answers[item.chatActive - 1].model != 'DeepSeek-R1'
      "
    ></span>
    <!-- 翻译 -->
    <el-divider v-if="item.answers[item.chatActive - 1].langType" class="hr" />
    <span
      class="loader messageView"
      v-if="
        item.answers[item.chatActive - 1].langType &&
        !item.answers[item.chatActive - 1].langText
      "
    ></span>
    <messageView
      v-if="item.answers[item.chatActive - 1].langText"
      :message="item.answers[item.chatActive - 1].langText"
      class="messageView"
    ></messageView>
    <div class="bottomFlex" v-if="item.conversationId">
      <div class="fillCenter" v-if="item.answers.length > 1">
        <el-button
          link
          @click="changeItemPage(false, item)"
          :disabled="getLoading()"
        >
          <img
            :src="item.chatActive != 1 ? icon.leftFill : icon.leftNoFill"
            alt=""
            class="fillImg"
          />
        </el-button>
        <span
          :class="item.chatActive == 1 ? 'fillColor' : ''"
          class="fillText"
          >{{ item.chatActive }}</span
        >
        <span
          class="fillText"
          :class="item.chatActive == item.answers.length ? 'fillColor' : ''"
          >/{{ item.answers.length }}</span
        >
        <el-button
          link
          @click="changeItemPage(true, item)"
          :disabled="getLoading()"
        >
          <img
            :src="
              item.chatActive == item.answers.length
                ? icon.rightNoFill
                : icon.rightFill
            "
            alt=""
            class="fillImg"
          />
        </el-button>
      </div>
      <!-- 回答拷贝 -->
      <el-tooltip
        popper-class="toolTip"
        :content="t('base.base194')"
        placement="top"
      >
        <el-button
          class="iconMargin"
          @click="copyFn(item.answers[item.chatActive - 1].answerContent)"
        >
          <img :src="icon.copySvg" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <!-- 重新生成 -->
      <el-tooltip
        popper-class="toolTip"
        :content="t('base.base195')"
        placement="top"
      >
        <el-button class="iconMargin" @click="releteTalk(item)">
          <img :src="icon.relete" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <!-- 翻译 -->
      <el-dropdown placement="top" @command="changeLang($event, item)">
        <el-button
          :class="
            !item.answers[item.chatActive - 1].langType
              ? 'iconMargin'
              : 'tocBtn'
          "
          @click="btnLang(item)"
          :disabled="getLoading()"
        >
          <img :src="icon.cToa" alt="" class="wid36" />
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="items in langList"
              :key="items.value"
              :command="items.value"
              :disabled="getLoading()"
            >
              <div class="dropItem">
                <img :src="items.icon" alt="" class="itemIcon" />
                {{ t(items.label) }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- 详细信息 -->
      <el-tooltip popper-class="toolTip" placement="top">
        <template #content>
          {{ t("base.base200") }}<br />
          {{ t("login.login33") }}:{{ descObj.model }}<br />
          {{ t("login.login35") }}: {{ descObj.answerTokens }}<br />
          {{ t("login.login37") }}:{{ descObj.questionTokens }}<br />
          {{ t("login.login36") }}: {{ descObj.realQuestionTokens }}<br />
          {{ t("login.login38") }}: {{ descObj.internetCount }}<br />
          {{ t("login.login39") }}: {{ getTime(descObj.createdTime) }}<br />
        </template>
        <el-button class="iconMargin" @mouseover="getDesc(item)">
          <img :src="icon.gantan" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
    </div>
  </template>
</template>

<script setup>
import { icon } from "@/utils/icon";
import messageView from "@/components/message/index.vue";
import thinkMessageView from "@/components/message/thinkMessage.vue";
import chatFile from "./chatFile.vue";
import plugView from "./plug.vue";
import { getTime, throttle } from "@/utils/utils";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import api from "@/api";
import { useStore } from "vuex";
const store = useStore();
const { t } = useI18n();
import clipboard3 from "vue-clipboard3";
const { toClipboard } = clipboard3();
const langList = ref([
  {
    label: "gpt.index79",
    value: "EN",
    icon: icon.EN,
  },
  {
    label: "gpt.index80",
    value: "CN",
    icon: icon.CN,
  },
  {
    label: "gpt.index81",
    value: "TC",
    icon: icon.TC,
  },
  {
    label: "gpt.index82",
    value: "JA",
    icon: icon.JA,
  },
  {
    label: "gpt.index83",
    value: "KO",
    icon: icon.KO,
  },
  {
    label: "gpt.index84",
    value: "RU",
    icon: icon.RU,
  },
  {
    label: "gpt.index85",
    value: "ES",
    icon: icon.ES,
  },
  {
    label: "gpt.index86",
    value: "FR",
    icon: icon.FR,
  },
  {
    label: "gpt.index87",
    value: "IT",
    icon: icon.IT,
  },
  {
    label: "gpt.index88",
    value: "PT",
    icon: icon.PT,
  },
  {
    label: "gpt.index89",
    value: "AR",
    icon: icon.AR,
  },
  {
    label: "gpt.index90",
    value: "DE",
    icon: icon.DE,
  },
]);
const props = defineProps([
  "botItem",
  "chatList",
  "debugtemplateId",
  "templateCall",
  "ifThink",
]);
const emit = defineEmits([
  "saveMessage",
  "releteTalk",
  "openFileView",
  "openTalk",
  "openResultDialog",
]);
// 打开查询结果
const openResultDialog = (message) => {
  emit("openResultDialog", message);
};
const descObj = ref({});
const getLoading = () => {
  return props.chatList.some((item) => item.loading === true);
};
const userId = ref(store.state.userId);
const resonDialog = ref(false); //来源展示框
// 获取来源数据
const getReson = (item) => {
  if (item.loading) {
    message.warning(t("login.login65"));
    return;
  }
  api.base
    .chatHistory({
      userId: userId.value,
      conversationId: item.conversationId,
      topicId: props.debugtemplateId,
      indebug: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        item.resonList = res.data;
        item.resonType = true;
        if (resonDialog.value) {
          resonDialog.value = false;
        }
      }
    });
};
const openReson = () => {
  resonDialog.value = true;
};
// 打开文件预览
const openFileView = (item, items) => {
  emit("openFileView", item, items);
};
// 打开会话
const openTalk = (item) => {
  emit("openTalk", item);
};
// 加减页数
const changeItemPage = (type, item) => {
  item.resonType = false;
  if (type && item.chatActive < item.answers.length) {
    item.chatActive++;
  }
  if (!type && item.chatActive > 1) {
    item.chatActive--;
  }
};
const saveMessage = (message) => {
  emit("saveMessage", message);
};
// 重新生成
const releteTalk = (item) => {
  emit("releteTalk", item);
};
// 直接点击翻译
const btnLang = (item) => {
  if (item.answers[item.chatActive - 1].langType) {
    item.answers[item.chatActive - 1].langType = false;
    item.answers[item.chatActive - 1].langText = "";
    return;
  }
  qaLang.value = "EN";
  item.answers[item.chatActive - 1].langType = true;
  itemQa(item);
};
// 切换语言
const qaLang = ref(null);
const changeLang = (e, item) => {
  qaLang.value = e;
  item.answers[item.chatActive - 1].langType = true;
  itemQa(item);
};
const itemQa = (item) => {
  item.loading = true;
  item.answers[item.chatActive - 1].langText = "";
  api.chat
    .chatTranslate({
      userId: userId.value,
      conversationId: item.conversationId,
      answerId: item.answers[item.chatActive - 1].answerId,
      indebug: true,
      language: qaLang.value,
    })
    .then((res) => {
      item.loading = false;
      if (res.returnCode == 200) {
        message.success(t("base.base218"));
        item.answers[item.chatActive - 1].langType = true;
        item.answers[item.chatActive - 1].langText = res.data;
      } else {
        item.answers[item.chatActive - 1].langType = false;
        item.answers[item.chatActive - 1].langText = "";
      }
    })
    .catch((err) => {
      item.loading = false;
    });
};
// 获取信息
// 获取详情
const getDesc = throttle((item) => {
  api.chat
    .chatDesc({
      answerId: item.answers[item.chatActive - 1].answerId,
      conversationId: item.conversationId,
      userId: userId.value,
      indebug: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        descObj.value = res.data;
      }
    });
}, 1000);
// 复制
const copyFn = async (item) => {
  await toClipboard(item); // str 为需要复制的文字
  message.success(t("base.base216"));
};
</script>

<style lang="scss" scoped>
.messageView {
  margin-bottom: 48px;
}
.call {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.ideaName {
  padding: 8px 12px;
  border: #edeef6 2px solid;
  background-color: #fefefe;
  border-radius: 12px;
  color: #3d3d3d;
  font-size: 18px;
  margin-bottom: 12px;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
}

.qMessage {
  display: flex;
  background-color: #fefefe;
  border-radius: 16px;
  padding: 12px 26px;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.qDiv {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.lineIcon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.wid36 {
  width: 36px;
}
.flexCenterEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.iconMargin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 0px;
  background-color: transparent;
  margin-left: 0px !important;
}
.tocBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 0px;
  margin-left: 0px !important;
  background-color: #edeef6;
  border-radius: 12px;
}
.iconMargin:hover {
  background-color: #edeef6;
  border-radius: 12px;
}
.bottomFlex {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.loader {
  width: 28px;
  height: 28px;
  border: 4px solid #f6f6f6;
  border-bottom-color: #0256ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillImg {
  width: 24px;
}
.fillText {
  font-size: 18px;
  font-weight: 600;
  color: #8a8a8a;
}
.fillColor {
  color: #d9d9d9;
}
.resonDiv {
  display: flex;
  background-color: #feffff;
  border-radius: 30px;
  height: 48px;
  padding: 0px 15px;
  align-items: center;
  margin-bottom: 24px;
  max-width: 240px;
  cursor: pointer;
}
.wid19 {
  width: 19px;
}
.resonText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 25px;
}
.hr {
  margin: 14px 0px;
}
.itemIcon {
  width: 18px;
  margin-right: 7px;
}
.dropItem {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
}
</style>
