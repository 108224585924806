<template>
  <div :class="containerClass">
    <div class="historyTopDiv">
      <div>{{ t("base.base199") }}</div>
      <div @click="closeHistoryClick" class="closeDiv">
        <img :src="icon.closeHistory" class="historyCloseIcon" alt="" />
      </div>
    </div>
    <div class="hisToryItem">
      <!-- 提问 -->
      <div class="hisBetween">
        <div class="hisCenter">
          <div class="txImg" :style="'background-color:' + currentAnswer.color">
            {{ currentAnswer.userName.slice(0, 1) }}
          </div>
          <div class="userNmae">
            {{ currentAnswer.userName }} {{ t("base.base232") }}
          </div>
        </div>
        <div class="historyTime">
          {{ getTime(props.historyList.questionCreateTime) }}
        </div>
      </div>
      <!-- 问题 -->
      <div class="hisQ hisCenter">
        {{ props.historyList.question }}
      </div>
      <!-- 回答 -->
      <div class="hisBetween">
        <div class="hisCenter">
          <div class="txImg" style="background-color: #0256ff">N</div>
          <div class="userNmae">{{ t("base.base233") }}</div>
        </div>
        <div class="historyTime">
          {{ getTime(props.historyList.answerCreatTime) }}
        </div>
      </div>
      <div class="hisQ">
        <div v-if="props.historyList.answers.length > 1" class="fillCenter">
          <img
            :src="leftArrowSrc"
            alt=""
            class="fillImg"
            @click="changeItemPage(false)"
          />
          <span :class="fillColorClass">{{
            props.historyList.chatActive
          }}</span>
          <span class="fillText">/{{ props.historyList.answers.length }}</span>
          <img
            :src="rightArrowSrc"
            alt=""
            class="fillImg"
            @click="changeItemPage(true)"
          />
        </div>
        <div
          :class="
            props.historyList.answers.length > 1
              ? 'text92 historyItemText'
              : 'historyItemText'
          "
        >
          <messageView :message="currentAnswer.answerContent" :type="true" />
        </div>
      </div>
      <!-- 文件来源 -->
      <div v-if="currentAnswer.answerResource.fileResource.length">
        <div class="userNmae marginHis10">
          {{ t("base.base234") }}
        </div>
        <div
          v-for="(item, index) in currentAnswer.answerResource.fileResource"
          :key="index"
          class="hisItemText"
        >
          <div class="dcflex">
            <span class="marginRightHistory">[{{ index + 1 }}]</span>
            <span class="marginRightHistory">{{ item.fileName }}</span>
          </div>
        </div>
      </div>
      <!-- 会话 -->
      <div v-if="currentAnswer.answerResource.sessionResource.length">
        <div class="userNmae marginHis10">
          {{ t("base.base311") }}
        </div>
        <div
          v-for="(item, index) in currentAnswer.answerResource.sessionResource"
          :key="index"
          class="hisItemText"
        >
          <div class="dcflex">
            <span class="marginRightHistory">[{{ index + 1 }}]</span>
            <span class="marginRightHistory">{{ item.sessionName }}</span>
          </div>
        </div>
      </div>
      <!-- 网络 -->
      <div v-if="currentAnswer.answerResource.internetResource.length">
        <div class="userNmae marginHis10">
          {{ t("base.base235") }}
        </div>
        <div
          v-for="(item, index) in currentAnswer.answerResource.internetResource"
          :key="index"
          class="hisItemText"
        >
          <div class="dcflex">
            <span class="marginRightHistory">[{{ index + 1 }}]</span>
            <span class="marginRightHistory">{{ item.internetUrl }}</span>
          </div>
        </div>
      </div>
      <!-- 插件 -->
      <div
        v-if="
          currentAnswer.answerResource.PluginList &&
          currentAnswer.answerResource.PluginList.length
        "
      >
        <div class="userNmae marginHis10">
          {{ t("gpt.index56")
          }}{{ currentAnswer.answerResource.PluginList.length
          }}{{ t("gpt.index57") }}
        </div>
        <div
          v-for="(item, index) in currentAnswer.answerResource.PluginList"
          :key="index"
          class="hisItemText"
        >
          <div class="dcflex">
            <span class="marginRightHistory">[{{ index + 1 }}]</span>
            <span class="marginRightHistory">{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { getTime } from "@/utils/utils";
import messageView from "@/components/message/index.vue";
import { computed } from "vue";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const props = defineProps(["rightType", "historyDialog", "historyList"]);
const emit = defineEmits(["closeHistoryClick", "changeItemPage"]);

// 当前回答
const currentAnswer = computed(
  () => props.historyList.answers[props.historyList.chatActive - 1]
);

// 容器样式
const containerClass = computed(() => ({
  historyDialog: props.rightType && props.historyDialog,
  hisOne: !props.rightType && props.historyDialog,
}));

// 左右箭头图片
const leftArrowSrc = computed(() =>
  props.historyList.chatActive !== 1 ? icon.leftFill : icon.leftNoFill
);
const rightArrowSrc = computed(() =>
  props.historyList.chatActive !== props.historyList.answers.length
    ? icon.rightFill
    : icon.rightNoFill
);

// 当前页码颜色
const fillColorClass = computed(() =>
  props.historyList.chatActive === 1 ? "fillColor" : ""
);

// 关闭对话框
const closeHistoryClick = () => {
  emit("closeHistoryClick");
};

// 切换页面
const changeItemPage = (type) => {
  emit("changeItemPage", type);
};
</script>

<style lang="scss" scoped>
.historyTopDiv {
  font-size: 20px;
  height: 29px;
  color: #3d3d3d;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}

.historyDialog,
.hisOne {
  position: absolute;
  top: 30px;
  width: 353px;
  height: calc(100% - 86px);
  background-color: #f7f8ff;
  border-radius: 18px;
  padding: 13px 24px;
}

.historyDialog {
  right: 444px;
}

.hisOne {
  right: 30px;
}

.closeDiv {
  cursor: pointer;
}

.historyCloseIcon {
  width: 14px;
  cursor: pointer;
}

.hisToryItem {
  height: calc(100% - 60px);
  margin-top: 15px;
  overflow: auto;
}

.hisBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hisCenter {
  display: flex;
  align-items: center;
}

.txImg {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
}

.userNmae {
  color: #3d3d3d;
  font-weight: 500;
  font-size: 18px;
}

.historyTime {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 16px;
}

.hisQ {
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  margin-top: 5px;
  margin-bottom: 15px;
  position: relative;
}

.hisItemText {
  font-size: 17px;
  color: #3d3d3d;
  font-weight: 400;
  margin-top: 2px;
}

.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
}
.text92 {
  text-indent: 85px;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}

.fillText {
  font-size: 18px;
  font-weight: 600;
  color: #8a8a8a;
}

.fillColor {
  color: #d9d9d9;
}

.historyItemText {
  color: #000;
  font-weight: 400;
  font-size: 18px;
}

.marginHis10 {
  margin: 10px 0px;
}

.dcflex {
  word-wrap: break-word;
}

.marginRightHistory {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
