<template>
  <div>
    <!-- 文件 -->
    <div
      v-if="inputFileList.length != 0"
      class="pa"
      :class="{
        wid50: !clearType,
        wid160: clearType,
      }"
    >
      <img
        :src="icon.leftReson"
        alt=""
        class="leftResonIcon"
        v-if="inputFileList.length >= 3"
        @click="changeLeftReson(fileListRef)"
      />
      <div ref="fileListRef" class="listFlex">
        <div
          class="fileItem"
          v-for="(item, index) in inputFileList"
          :key="index"
          @click="openFile(item)"
        >
          <template v-if="!ifImg(item)">
            <img :src="getFileIcon(item)" alt="" class="fileImg" />
            <div class="fileRightDiv">
              <el-tooltip
                popper-class="toolTip"
                :content="item.name || item.fileName"
              >
                <div class="fileNmae">{{ item.name || item.fileName }}</div>
              </el-tooltip>
              <el-tooltip
                popper-class="toolTip"
                :content="
                  getFileType(item) + ' ' + formatFileSize(item.fileSize)
                "
              >
                <div class="fileSize">
                  {{ getFileType(item) }} {{ formatFileSize(item.fileSize) }}
                </div>
              </el-tooltip>
            </div>
            <img
              :src="icon.closePng"
              @click.stop="deleteFile(item)"
              alt=""
              class="closeIcon"
            />
          </template>
          <template v-if="ifImg(item)">
            <el-popover placement="top" popper-class="fileImgPopover">
              <template #reference>
                <img :src="getImgUrl(item)" alt="" class="fileImg" />
              </template>
              <img :src="getImgUrl(item)" alt="" class="hoverImg" />
            </el-popover>
            <div class="fileRightDiv">
              <el-tooltip popper-class="toolTip" :content="item.name">
                <div class="fileNmae">{{ item.name }}</div>
              </el-tooltip>
              <el-tooltip
                popper-class="toolTip"
                :content="
                  getFileType(item) + ' ' + formatFileSize(item.fileSize)
                "
              >
                <div class="fileSize">
                  {{ getFileType(item) }} {{ formatFileSize(item.fileSize) }}
                </div>
              </el-tooltip>
            </div>
            <img
              :src="icon.closePng"
              alt=""
              class="closeIcon"
              @click.stop="deleteFile(item)"
            />
          </template>
          <div class="absDiv" v-if="item.isFinish == 0">
            <span class="loader"></span>
            <div class="loadingText">{{ t("login.login42") }}</div>
          </div>
        </div>
      </div>
      <img
        :src="icon.rightReson"
        alt=""
        class="rightResonIcon"
        v-if="inputFileList.length >= 3"
        @click="changeRightReson(fileListRef)"
      />
    </div>
    <!-- 结束上下文 -->
    <div
      :class="getEndClass"
      @mouseenter="clearType = true"
      @mouseleave="clearType = false"
      @click="clearTalk"
    >
      <img :src="icon.endTalk" v-if="!clearType" class="clearBtnSvg" alt="" />
      <div link v-else class="blueClear">
        <img :src="icon.clearBtn1Svg" alt="" class="clearBtnSvg1" />
        {{ t("login.login51") }}
      </div>
    </div>
    <div class="inputDivHistory">
      <el-input
        v-model="question"
        :placeholder="t('base.base318')"
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 3 }"
        id="chatMessage"
        :disabled="props.debugtemplateId == null"
        @input="changeInput"
        @keydown="messageSendListen"
        @paste="onPasteUpload($event)"
      >
      </el-input>
      <!-- 下方选项 -->
      <div class="flexEnd">
        <!-- 对话 -->
        <div class="flexCenter">
          <uploadIndex type="file" @beforeAvatarUpload="beforeAvatarUpload">
            <img :src="icon.chatInputFile" class="chatInputFile" alt="" />
          </uploadIndex>
          <uploadIndex
            type="img"
            @beforeAvatarUpload="beforeAvatarUpload"
            v-if="props.model == 'GPT-4o-mini' || props.model == 'GPT-4o'"
          >
            <img :src="icon.chatInputImg" class="chatInputFile" alt="" />
          </uploadIndex>
          <template v-if="checkConditions()">
            <el-switch v-model="isInternetOn" />
            <div class="inputText">
              {{ t("base.base226") }}
            </div>
          </template>
        </div>
        <img :src="icon.lineSvg" alt="" class="zwf" />
        <el-tooltip
          popper-class="toolTip"
          :content="t('gpt.index16')"
          v-if="getMacType"
        >
          <img :src="icon.mac" alt="" class="mac" @click="startMac" />
        </el-tooltip>
        <el-tooltip
          popper-class="toolTip"
          :content="t('gpt.index17')"
          v-if="macType"
        >
          <div class="stopMacDiv" @click="stopMac">
            <img :src="icon.stopMac" alt="" class="stopMac" />
          </div>
        </el-tooltip>
        <img
          :src="icon.sendPng"
          v-if="question.length == 0"
          alt=""
          class="sendImg"
        />
        <img :src="icon.sendYes" v-else class="sendImg" @click="sendMessage" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { throttle } from "@/utils/utils";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
const speechsdk = require("microsoft-cognitiveservices-speech-sdk");
import { icon } from "@/utils/icon";
import uploadIndex from "@/components/upload/index.vue";
import { getFileIcon, formatFileSize } from "@/utils/file";
import { onBeforeRouteLeave } from "vue-router";
import api from "@/api";
import { ref, nextTick, computed, onMounted, onUnmounted, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { useStore } from "vuex";
import { message } from "ant-design-vue";
const store = useStore();
const props = defineProps([
  "model",
  "debugtemplateId",
  "botItem",
  "knowledgeType",
  "isKnowledgeOn",
  "isInternetOn",
]);
const emit = defineEmits([
  "getDivHeight",
  "sendMessage",
  "openFileView",
  "clearTalk",
  "changeMacType",
]);
const isInternetOn = ref(false); //联网搜索
const userId = ref(store.state.userId);
const knowledgeId = ref(null); //选择tag的知识库id
const question = ref(""); //input框
const timer = ref(null);
const fileListRef = ref(null);
const clearType = ref(false);
const chatType = ref(store.state.chatType);
const strategy = ref(0); //默认算法
const inputFileList = ref([]); //文件列表
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
onMounted(() => {
  getMac();
  getTask();
});
onBeforeRouteLeave((to, from, next) => {
  clearInterval(processTime.value);
  processTime.value = null;
  macType.value = false;
  question.value = "";
  if (speechRecognizer.value) {
    speechRecognizer.value.stopContinuousRecognitionAsync();
    speechRecognizer.value = null;
  }
  next();
});
onUnmounted(() => {
  clearInterval(processTime.value);
  processTime.value = null;
});
const checkConditions = () => {
  // 检查是否联网
  if (props.isInternetOn) {
    // 如果联网，检查知识功能的状态
    if (props.isKnowledgeOn) {
      // 如果知识功能开启，检查知识类型是否不等于3
      return props.knowledgeType !== 3;
    } else {
      // 如果知识功能未开启，直接返回true
      return true;
    }
  } else {
    // 如果未联网，直接返回false
    return false;
  }
};
// 获取是否可以用mac
const getMacType = computed(() => {
  if (props.model) {
    if (!macType.value && props.model.includes("GPT") && speechKey.value) {
      return true;
    } else {
      return false;
    }
  }
});
// 判断权限
const speechKey = ref("");
const speechRegion = ref("");
const getMac = () => {
  api.chat.getVoice({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      if (res.data.length == 0) return;
      const azureRecord = res.data.find(
        (item) => item.platform_type === "Azure"
      );
      speechKey.value = azureRecord.api_key;
      speechRegion.value = azureRecord.region;
    }
  });
};
// 开始语音
const macType = ref(false);
const speechRecognizer = ref(null); //azure实例
watch(
  () => macType.value,
  (newValue, oldValue) => {
    emit("changeMacType", macType.value);
  }
);
const startMac = throttle(() => {
  macAzure();
}, 1000);
const stopMac = () => {
  macType.value = false;
  if (speechRecognizer.value) {
    speechRecognizer.value.stopContinuousRecognitionAsync();
    speechRecognizer.value = null;
  }
};
// azure录入
const macAzure = async () => {
  speechRecognizer.value = null;
  // 获取token
  const azureToken = await api.chat.getAzureToken({
    userId: userId.value,
    speechKey: speechKey.value,
    speechRegion: speechRegion.value,
  });
  const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
    azureToken.data,
    speechRegion.value
  );
  speechConfig.speechRecognitionLanguage = "zh-CN";
  const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
  speechRecognizer.value = new speechsdk.SpeechRecognizer(
    speechConfig,
    audioConfig
  );
  macType.value = true;
  speechRecognizer.value.startContinuousRecognitionAsync();
  speechRecognizer.value.recognized = (s, e) => {
    if (e.result.reason == ResultReason.RecognizedSpeech) {
      question.value += e.result.text;
      changeInput();
    }
    if (e.result.reason == ResultReason.NoMatch) {
      if (speechRecognizer.value) {
        speechRecognizer.value.stopContinuousRecognitionAsync();
        speechRecognizer.value = null;
      }
      macType.value = false;
    }
  };
};
//清楚上下文
const clearTalk = () => {
  emit("clearTalk");
};
const getEndClass = computed(() => {
  const clearTypeIsTrue = clearType.value;
  const inputFileListIsEmpty = inputFileList.value.length === 0;
  return clearTypeIsTrue
    ? inputFileListIsEmpty
      ? "clearBtnDiv5"
      : "clearBtnDiv6"
    : inputFileListIsEmpty
    ? "clearBtnDiv"
    : "clearBtnDiv2";
});
// file转图片
const getImgUrl = (file) => {
  if (file.fileContentPath) {
    return file.fileContentPath;
  } else {
    return URL.createObjectURL(file);
  }
};
// 删除文件
const deleteFile = (item) => {
  api.chat
    .chatFileDelete({
      userId: userId.value,
      fileId: item.fileId || item.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base16"));
        getChatFile();
      }
    });
};
// 获取文件类型
const getFileType = (item) => {
  if (item.fileType && item.fileType == 2) {
    return t("login.login40");
  }
  let name = item.fileName || item.name;
  let fileType = name.substring(name.lastIndexOf(".") + 1);
  fileType = fileType.toLowerCase().replace(" ", "");
  return fileType;
};
// 判断是不是图片
const ifImg = (file) => {
  if (file.name) {
    const type = file.name.substring(file.name.lastIndexOf(".") + 1);
    const fileType = type.toLowerCase().replace(" ", "");
    if (fileType == "png") {
      return true;
    } else if (fileType == "jpg") {
      return true;
    } else if (fileType == "gif") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
// 预览文件
const openFile = (item) => {
  if (item.isFinish == 0) {
    message.warning(t("base.base308"));
    return;
  }
  if (
    item.fileType == "png" ||
    item.fileType == "jpg" ||
    item.fileType == "gif" ||
    item.fileType == 1
  )
    return;
  emit("openFileView", item);
};
const saveMessage = (message) => {
  let postObj = {
    model: props.model,
    message: message,
    topicId: props.debugtemplateId,
    knowledgeId: knowledgeId.value,
    userId: userId.value,
    isInternetOn: checkConditions() ? isInternetOn.value : false,
    regenerateFlag: 0,
    conversationId: null,
    internetResource: null,
    strategy: strategy.value,
    imageFlag: 0,
    indebug: true,
  };
  emit("sendMessage", postObj);
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 调用父组件-input高度
const changeInput = () => {
  setTimeout(() => {
    emit("getDivHeight");
  }, 100);
};
// 换行
const messageSendListen = (event) => {
  if (event.keyCode === 13) {
    if (!event.altKey) {
      event.preventDefault();
      sendMessage(); // 发送文本
    } else {
      let textarea = document.getElementById("chatMessage");
      let start = textarea.selectionStart;
      let end = textarea.selectionEnd;
      let before = textarea.value.substring(0, start);
      let after = textarea.value.substring(end);
      question.value = before + "\n" + after;
      changeInput();
      nextTick(() => {
        textarea.scrollTop = textarea.scrollHeight;
      });
    }
  }
};
// 粘贴文件
const onPasteUpload = (event) => {
  let items = event.clipboardData && event.clipboardData.items;
  let file = null;
  let fileList = [];
  const fileTypeList = [
    "txt",
    "pptx",
    "pdf",
    "docx",
    "xlsx",
    "html",
    "jpg",
    "png",
    "gif",
  ];
  if (items && items.length) {
    for (let key in items) {
      if (items[key].kind == "file") {
        file = items[key].getAsFile();
        file.fileType = file.name
          .substring(file.name.lastIndexOf(".") + 1)
          .toLowerCase()
          .replace(" ", "");
        if (!fileTypeList.includes(file.fileType)) {
          message.warning(file.name + t("base.base283"));
          return;
        }
        // 大小限制
        if (file.size > 20971520) {
          message.warning(file.name + t("base.base281"));
          return;
        }
        // 名称超长
        let fileNameLen = String(file.name);
        if (fileNameLen.length > 100) {
          message.warning(file.name + t("base.base282"));
          return;
        }
        fileList.push(file);
        clearTimeout(timer.value);
        timer.value = setTimeout(() => {
          beforeAvatarUpload(fileList);
        }, 100);
      }
    }
  }
};
// 上传
const beforeAvatarUpload = (fileList) => {
  // 不等于4o 不让用图片
  if (props.model != "GPT-4o" && props.model != "GPT-4o-mini") {
    const nonImageFiles = fileList.filter(
      (item) => !["jpg", "png", "gif"].includes(item.fileType)
    );
    fileList = nonImageFiles;
  }
  if (fileList.length == 0 || chatType.value == "base") return;
  let inFileList = JSON.parse(JSON.stringify(inputFileList.value));
  inFileList = inFileList.concat(fileList);
  let file = inFileList.filter(
    (item) => !["jpg", "png", "gif"].includes(item.fileType)
  );
  let imgNumberList = inFileList.filter((item) =>
    ["jpg", "png", "gif"].includes(item.fileType)
  );
  if (file.length > 3) {
    return message.warning(t("base.base300"));
  }
  if (imgNumberList.length > 3) {
    return message.warning(t("base.base301"));
  }
  //   上传轮询
  api.chat
    .chatUpFile(fileList, {
      topicId: props.debugtemplateId,
      indebug: true,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (res.failedFileList.length != 0) {
          res.failedFileList.forEach((item) => {
            message.warning(item[0] + item[1]);
          });
          getChatFile();
        } else {
          getChatFile();
        }
        getTask();
      }
    });
};
const processTime = ref(null);
const getTask = () => {
  if (props.debugtemplateId) {
    api.chat
      .getProcess({
        userId: userId.value,
        topicId: props.debugtemplateId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length == 0) {
            getChatFile();
            clearInterval(processTime.value);
            processTime.value = null;
          } else {
            clearInterval(processTime.value);
            processTime.value = null;
            getChatFile();
            if (window.location.pathname == "/bot/detail") {
              processTime.value = setInterval(() => {
                setTimeout(() => {
                  getTask();
                }, 0);
              }, 3000);
            }
          }
        }
      });
  }
};
// 回显文件
const getChatFile = () => {
  if (props.debugtemplateId) {
    api.chat
      .chatFileQuery({
        userId: userId.value,
        topicId: props.debugtemplateId,
        indebug: true,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          inputFileList.value = res.data;
          emit("getDivHeight");
        }
      });
  }
};
// 发送消息
const sendMessage = () => {
  if (macType.value) {
    message.warning(t("gpt.index76"));
    return;
  }
  let postObj = {
    model: props.model,
    message: question.value,
    topicId: props.debugtemplateId,
    knowledgeId: knowledgeId.value,
    userId: userId.value,
    isInternetOn: checkConditions() ? isInternetOn.value : false,
    regenerateFlag: 0,
    conversationId: null,
    internetResource: null,
    strategy: strategy.value,
    imageFlag: 0,
    pictureModelType: null,
    indebug: true,
  };
  emit("sendMessage", postObj);
  question.value = "";
};
defineExpose({
  saveMessage,
  getChatFile,
});
</script>

<style lang="scss" scoped>
.inputDivHistory {
  min-height: 84px;
  border: 2px solid #d9d9d9;
  border-radius: 18px;
  padding: 15px;
  position: relative;
  background-color: #feffff;
}
.tjDiv {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
}
:deep(.el-textarea) {
  height: 100%;
  .el-textarea__inner {
    border: 0px;
    resize: none;
    outline: none;
    width: 100%;
    overflow: auto;
    font-size: 18px;
    font-weight: 400;
    min-height: 43px !important;
    box-shadow: 0px 0px 0px 0px #ffffff;
    border-radius: 0;
    padding: 0px;
  }
  .el-textarea__inner:disabled {
    background-color: #fff;
  }
  .el-textarea__inner::placeholder {
    color: #d9d9d9;
    font-size: 18px;
    font-weight: 400;
  }
}
.flexEnd {
  margin-top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}
.chatInputFile {
  width: 20px;
  margin-right: 14px;
}
.inputText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-left: 6px;
}
.zwf {
  margin: 0px 12px;
  width: 5px;
  height: 25px;
}
.sendImg {
  width: 30px;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.listFlex {
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.fileItem {
  width: 188px;
  height: 52px;
  background-color: #feffff;
  border-radius: 20px;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
}
.fileImg {
  width: 40px;
}
.fileNmae {
  font-size: 18px;
  font-weight: 500;
  color: #3d3d3d;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileSize {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.closeIcon {
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
  width: 15px;
  z-index: 20;
}
.hoverImg {
  max-width: 300px;
  max-height: 300px;
}
.fileRightDiv {
  margin-left: 13px;
  width: calc(100% - 41px);
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.loader {
  width: 28px;
  height: 28px;
  border: 4px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.absDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8a8a8a;
  opacity: 0.9;
  border-radius: 20px;
  flex-direction: column;
  z-index: 10;
}
.loadingText {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}
:deep(.el-tooltip__trigger) {
  font-size: 16px;
  font-weight: 400;
}
.clearBtnSvg {
  width: 24px;
  cursor: pointer;
}
.blueClear {
  background-color: #3376ff;
  border-radius: 16px;
  padding: 12px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.clearBtnSvg1 {
  width: 24px;
  margin-right: 6px;
}
.clearBtnDiv5 {
  position: absolute;
  top: -55px;
  right: 10px;
}
.clearBtnDiv6 {
  position: absolute;
  top: 15px;
  right: 10px;
}
.wid50 {
  width: calc(100% - 50px);
}
.wid160 {
  width: calc(100% - 160px);
}
.clearBtnDiv {
  position: absolute;
  top: -35px;
  right: 10px;
}
.clearBtnDiv2 {
  position: absolute;
  top: 30px;
  right: 10px;
}
:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  border-radius: 7px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 7px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #fefefe;
  left: 2px;
  border-radius: 4px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
  border-radius: 4px;
}
.mac {
  width: 22px;
  cursor: pointer;
  margin-right: 15px;
}
.stopMac {
  width: 22px;
}
.stopMacDiv {
  background-color: #edeef6;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 11px;
}
</style>
