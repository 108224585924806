<template>
  <!-- 思考中 -->
  <div class="loadingDiv">
    <span class="loader" v-if="props.time == null && !props.chatMessage"></span>
    <div class="loadingText" v-if="props.time == null && !props.chatMessage">
      {{ t("bot.bot117") }}
    </div>
    <div
      class="loadingText"
      v-if="props.time == null && props.chatMessage && props.message"
    >
      {{ t("bot.bot118") }}
    </div>
    <div class="loadingText" v-if="props.time != null">
      {{ t("bot.bot119") }}
    </div>
    <div class="loadingText" v-if="props.time != null">{{ getTime }}</div>
    <img
      :src="!textType ? icon.downReson : icon.upReson"
      alt=""
      class="reSonUpFileImg"
      @click="textType = !textType"
      v-if="props.message"
    />
  </div>
  <el-collapse-transition>
    <div class="thinkDiv" v-show="textType && props.message">
      <div v-html="renderMdText(props.message)" class="mdTextBox"></div>
    </div>
  </el-collapse-transition>
</template>

<script setup>
import { icon } from "@/utils/icon";
import MarkdownIt from "markdown-it";
import MarkdownItHighlightjs from "markdown-it-highlightjs";
import "highlight.js/styles/a11y-dark.css";
import "katex/dist/katex.min.css";
import markdownItKatexGpt from "./index";
import { nextTick, onMounted, ref, computed } from "vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["message", "type", "time", "chatMessage"]);
const markdownRender = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
})
  .use(MarkdownItHighlightjs)
  .use(markdownItKatexGpt);
const textType = ref(true);
onMounted(() => {
  nextTick(() => {
    const codeBlocks = document.querySelectorAll("pre code");
    codeBlocks.forEach((block) => {
      const preElement = block;
      const parentElement = preElement.parentNode;
      const firstDiv = parentElement.querySelector("div");
      if (!firstDiv) {
        var childDiv2 = document.createElement("div");
        childDiv2.textContent = "copy code";
        childDiv2.setAttribute("class", "copyText");
        parentElement.insertBefore(childDiv2, preElement);
        // 给div添加点击事件
        childDiv2.onclick = function () {
          const textArea = document.createElement("textarea");
          textArea.value = preElement.innerText;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand("copy");
          document.body.removeChild(textArea);
          message.success(t("base.base216"));
        };
      }
    });
  });
});
const renderMdText = (text) => {
  if (text == null) return;
  text = text.replace(/\align\*/g, "aligned");
  return markdownRender.render(text);
};
const getTime = computed(() => {
  if (props.time <= 10) {
    return t("bot.bot120");
  }
  if (props.time > 10 && props.time < 60) {
    return Math.trunc(props.time) + t("bot.bot121");
  }
  if (props.time >= 60) {
    return Math.trunc(props.time / 60) + t("bot.bot122");
  }

  return;
});
</script>

<style lang="less" scoped>
.mdTextBox {
  font-size: 18px;
  color: #808080;
  font-weight: 400;
  max-width: 100%;
  overflow: auto;
  user-select: text;
  padding: 6px 15px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
:deep(p) {
  margin-top: 0px;
  margin-bottom: 5px;
}

:deep(code) {
  border-radius: 3px;
  max-width: 100%;
  overflow: auto;
  padding-top: 35px !important;
}

:deep(table) {
  border-collapse: collapse;
  width: 100%;
}

:deep(table) {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

:deep(th) {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
}
:deep(td) {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
:deep(pre) {
  position: relative;
}

:deep(.copyText) {
  cursor: pointer;
  position: absolute;
  color: #ffffff;
  font-weight: 400;
  right: 5px;
  font-size: 14px;
}

:deep(.dellImg) {
  width: 100%;
}
.thinkDiv {
  border-left: 2px solid #e8e8e8;
  margin-bottom: 24px;
}
.loader {
  width: 20px;
  height: 20px;
  border: 4px solid #f6f6f6;
  border-bottom-color: #0256ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.reSonUpFileImg {
  width: 12px;
  cursor: pointer;
}
.loadingText {
  color: #b4b4b4;
  font-size: 18px;
  font-weight: 600;
  margin-left: 8px;
  margin-right: 8px;
}
.loadingDiv {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
</style>
