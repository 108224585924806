<template>
  <div class="fileItem" @click.stop="goUrl">
    <div class="leftDiv">
      <el-tooltip
        popper-class="toolTip"
        :content="props.htmlItem.internetName || getUrl()"
      >
        <div class="fontTitle">
          {{ props.htmlItem.internetName || getUrl() }}
        </div>
      </el-tooltip>
      <img
        :src="props.htmlItem.internetImage"
        v-if="props.htmlItem.internetImage"
        alt=""
        class="img56"
      />
    </div>
    <div class="flexBetween">
      <div class="fontName">{{ getUrl() }}</div>
    </div>
    <div class="flexBtoomCenter">
      <div class="lookDiv bottom8" @click.stop="goUrl">
        <img class="lookImg" :src="icon.look" alt="" />
      </div>
      <div class="lookDiv" @click.stop="deleteHtml" v-if="props.deleType">
        <img class="lookImg" :src="icon.yc" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
const props = defineProps(["htmlItem", "deleType"]);
const emit = defineEmits(["deleteHtml", "goUrl"]);
const getUrl = () => {
  var url = new URL(props.htmlItem.internetUrl);
  return url.origin;
};
const goUrl = () => {
  window.open(props.htmlItem.internetUrl, "_blank");
};
const deleteHtml = () => {
  emit("deleteHtml");
};
</script>

<style lang="scss" scoped>
.fileItem {
  background-color: #fefefe;
  border-radius: 20px;
  padding: 12px 43px 12px 23px;
  height: 90px;
  width: 284px;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 5px 0px #dbdfff7d;
}
.zwf {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d9d9d9;
  font-size: 13px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  line-height: 18px;
}
.fontName {
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 400;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-bottom: 12px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; /* 强制换行 */
}
.img56 {
  width: 56px;
  margin-left: 9px;
}
.leftDiv {
  display: flex;
  min-width: 220px;
}
.flexBtoomCenter {
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.lookDiv {
  background-color: #edeef6;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}
.bottom8 {
  margin-bottom: 8px;
}
.lookImg {
  width: 12px;
}
</style>
