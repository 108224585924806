<template>
  <div>
    <!-- 分段设置 -->
    <div class="itemTitle">{{ t("gpt.index18") }}</div>
    <div>
      <div
        v-for="item in topList"
        :key="item.value"
        :class="form.segment.type == item.value ? 'borderAc' : 'borderNo'"
      >
        <div
          @click="changeTop(item.value)"
          :class="{
            itemDivAc: form.segment.type == item.value,
            itemDiv: form.segment.type != item.value,
            mouseNo: props.disabled,
          }"
        >
          <div class="iconItem">
            <img :src="item.icon" alt="" class="itemIcon" />
          </div>
          <div class="wid67">
            <div class="forTitle">{{ t(item.name) }}</div>
            <el-tooltip popper-class="toolTip" :content="t(item.tip)">
              <div class="forTip">{{ t(item.tip) }}</div>
            </el-tooltip>
          </div>
        </div>
        <!-- 自定义分段 -->
        <!-- 文档模式 -->
        <template
          v-if="form.segment.type == 2 && item.value == 2 && props.type == 2"
        >
          <el-row class="zdyTopTitleDiv">
            <el-col :span="8" class="zdyText">{{ t("gpt.index19") }}</el-col>
            <el-col :span="8" class="zdyText">{{ t("gpt.index20") }}</el-col>
            <el-col :span="8" class="zdyText">{{ t("gpt.index21") }}</el-col>
          </el-row>
          <el-row class="topBottomInput">
            <el-col :span="8" class="numberDiv">
              <el-input
                class="numberInput"
                v-model="form.segment.segmentIdentifier"
                :disabled="props.disabled"
              ></el-input>
            </el-col>
            <el-col :span="8" class="numberDiv">
              <el-input-number
                class="numberClass"
                v-model="form.segment.maximumSegmentLength"
                :min="1"
                :max="2000"
                :disabled="props.disabled"
                controls-position="right"
              >
                <template #suffix>
                  <span>tokens</span>
                </template>
              </el-input-number>
            </el-col>
            <el-col :span="8" class="numberDiv">
              <el-input-number
                class="numberClass"
                v-model="form.segment.segmentOverlapRatio"
                :min="1"
                :max="100"
                :disabled="props.disabled"
                controls-position="right"
              >
                <template #suffix>
                  <span>%</span>
                </template>
              </el-input-number>
            </el-col>
          </el-row>
          <div class="zdyTopTitleDiv">
            <div class="zdyText1">{{ t("gpt.index22") }}</div>
            <div class="checkoutDiv">
              <el-checkbox
                v-model="form.segment.replaceType"
                :disabled="props.disabled"
                class="marginRight6"
              />{{ t("gpt.index23") }}
            </div>
            <div class="checkoutDiv">
              <el-checkbox
                v-model="form.segment.deleteType"
                :disabled="props.disabled"
                class="marginRight6"
              />{{ t("gpt.index24") }}
            </div>
          </div>
        </template>
        <!-- 表格模式 -->
        <template
          v-if="form.segment.type == 0 && item.value == 0 && props.type == 3"
        >
          <el-row class="zdyTopTitleDiv">
            <el-col :span="12" class="zdyText">{{ t("gpt.index25") }}</el-col>
            <el-col :span="12" class="zdyText">{{ t("gpt.index26") }}</el-col>
          </el-row>
          <el-row class="topBottomInput">
            <el-col :span="12" class="numberDiv">
              <el-input-number
                class="numberClass"
                :min="1"
                :max="2000"
                :disabled="props.disabled"
                controls-position="right"
                v-model="form.segment.maximumDescriptionLength"
              >
                <template #suffix>
                  <span>tokens</span>
                </template>
              </el-input-number>
            </el-col>
            <el-col :span="12" class="numberDiv">
              <el-input-number
                class="numberClass"
                :min="1"
                :max="10"
                :disabled="props.disabled"
                controls-position="right"
                v-model="form.segment.maximumReQueryCount"
              >
                <template #suffix>
                  <span>{{ t("gpt.index27") }}</span>
                </template>
              </el-input-number>
            </el-col>
          </el-row>
        </template>
      </div>
    </div>
    <!-- 检索设置 -->
    <div class="itemTitle">{{ t("gpt.index28") }}</div>
    <div>
      <div
        v-for="item in bottomList"
        :key="item.value"
        :class="form.retrieval.type == item.value ? 'borderAc' : 'borderNo'"
      >
        <div
          :class="{
            itemDivAc: form.retrieval.type == item.value,
            itemDiv: form.retrieval.type != item.value,
            mouseNo: props.disabled,
          }"
          @click="changeBottom(item.value)"
        >
          <div class="iconItem">
            <img :src="item.icon" alt="" class="itemIcon" />
          </div>
          <div class="wid67">
            <div class="forTitle">{{ t(item.name) }}</div>
            <el-tooltip popper-class="toolTip" :content="t(item.tip)">
              <div class="forTip">{{ t(item.tip) }}</div>
            </el-tooltip>
          </div>
        </div>
        <!-- 设置 -->
        <template v-if="form.retrieval.type == item.value">
          <div class="bottomDiv">
            <template v-if="item.value == 3">
              <div class="zdyText">{{ t("gpt.index29") }}</div>
              <div class="processDiv">
                <el-slider
                  :step="0.1"
                  :max="1"
                  :min="0"
                  :disabled="props.disabled"
                  v-model="form.retrieval.semantic"
                  @change="changeRetrieval"
                />
                <div class="flexBetween">
                  <div class="leftText">
                    {{ t("gpt.index30") }} {{ form.retrieval.semantic }}
                  </div>
                  <div class="rightText">
                    {{ (1 - form.retrieval.semantic).toFixed(1) }}
                    {{ t("gpt.index31") }}
                  </div>
                </div>
              </div>
            </template>

            <el-row class="bottomDivProcess">
              <el-col :span="12" class="zdyText" v-if="item.value != 2"
                >Top K</el-col
              >
              <el-col
                :span="12"
                :class="form.retrieval.scoreType ? 'zdyText' : 'zdyText1'"
              >
                <el-switch
                  class="switchDiv"
                  :disabled="props.disabled"
                  v-model="form.retrieval.scoreType"
                />
                {{ t("gpt.index32") }}
              </el-col>
            </el-row>
            <el-row class="bottomProcess">
              <el-col :span="12" class="numberDiv" v-if="item.value != 2">
                <el-input-number
                  class="numberClass"
                  :min="0"
                  :max="99999999"
                  :disabled="props.disabled"
                  v-model="form.retrieval.topK"
                  controls-position="right"
                />
              </el-col>
              <el-col :span="12" class="numberDiv">
                <el-input-number
                  class="numberClass"
                  :min="0"
                  :max="1"
                  :step="0.1"
                  :disabled="props.disabled"
                  v-model="form.retrieval.score"
                  controls-position="right"
                />
              </el-col>
            </el-row>
          </div>
        </template>
      </div>
    </div>
    <!-- 平台管理 -->
    <div class="itemTitle">
      {{ t("gpt.index33") }}
      <el-tooltip popper-class="toolTip" :content="t('base.base361')">
        <el-icon class="ptIconTip"><Warning /></el-icon>
      </el-tooltip>
    </div>
    <div>
      <div
        v-for="item in ptList"
        :key="item.value"
        :class="getPt(item) ? 'borderAc' : 'borderNo'"
      >
        <div
          :class="{
            itemDivAc: getPt(item),
            itemDiv: !getPt(item),
            mouseNo: props.disabled,
          }"
          @click="clickPt(item)"
        >
          <div class="ptDiv">
            <img :src="item.icon" alt="" class="ptIconItem" />
          </div>
          <div class="forTitle">{{ t(item.name) }}</div>
        </div>
        <template v-if="getPt(item)">
          <div class="bottomDiv">
            <div class="zdyText">{{ t("gpt.index34") }}</div>
            <el-select
              v-model="item.embedding"
              class="ptSelect"
              :disabled="props.disabled"
              @change="changePt"
            >
              <el-option
                v-for="items in item.embeddingOptions"
                :key="items"
                :label="items"
                :value="items"
              >
                <span class="dropText">{{ items }}</span>
              </el-option>
            </el-select>
            <div class="zdyText">{{ t("gpt.index35") }}</div>
            <el-select
              v-model="item.ocr"
              class="ptSelect"
              :disabled="props.disabled"
              @change="changePt"
            >
              <el-option
                v-for="items in item.ocrOptions"
                :key="items"
                :label="items"
                :value="items"
              >
                <span class="dropText">{{ items }}</span>
              </el-option>
            </el-select>
            <div class="zdyText">
              {{ t("gpt.index36") }}
              <el-tooltip popper-class="toolTip" :content="t('gpt.index37')">
                <span class="modelClass">{{ t("gpt.index37") }}</span>
              </el-tooltip>
            </div>
            <el-select
              v-model="item.model"
              class="ptSelect"
              :disabled="props.disabled"
              @change="changePt"
            >
              <el-option
                v-for="items in item.modelOptions"
                :key="items"
                :label="items"
                :value="items"
              >
                <span class="dropText">{{ items }}</span>
              </el-option>
            </el-select>
          </div>
        </template>
      </div>
    </div>
    <!-- 提示词 -->
    <div class="itemTitle">
      {{ t("base.base30") }}
      <el-tooltip popper-class="toolTip" :content="t('base.base305')">
        <el-icon class="ptIconTip"><Warning /></el-icon>
      </el-tooltip>
    </div>
    <el-select
      class="elSelete"
      :placeholder="t('base.base31')"
      v-model="form.promptId"
      filterable
      remote
      reserve-keyword
      remote-show-suffix
      :remote-method="remoteMethod"
      :disabled="props.disabled"
      v-if="!props.disabled"
    >
      <el-option
        v-for="item in botList"
        :key="item.templateId"
        :label="item.templateName"
        :value="item.templateId"
      >
        <span class="dropText">{{ item.templateName }}</span>
      </el-option>
    </el-select>
    <div v-else class="noSel">
      {{ props.addForm.prompt_value.promptName }}
    </div>
  </div>
</template>

<script setup>
import api from "@/api";
import { icon } from "@/utils/icon";
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const form = ref({
  //分段设置
  segment: {
    type: 1, //1自动 2自定义
    segmentIdentifier: "", //分段标识符 自动传null 自定义给值必传 文档模式
    maximumSegmentLength: 0, //分段最大长度 自动传null 自定义给值必传 文档模式
    segmentOverlapRatio: 0, //分段重叠比例 自动传null 自定义给值必传 文档模式
    replaceType: false, //替换掉连续的空格、换行符和制表符 true开启 false关闭 自动传null 自定义给值必传 文档模式
    deleteType: false, //删除所有 URL 和电子邮件地址 true开启 false关闭 自动传null 自定义给值必传 文档模式
    maximumDescriptionLength: 0, //表格/文档最大描述长度 表格模式传  自动分段传null 自定义必传
    maximumReQueryCount: 0, //NL2SQL最大重查询次数 表格模式传  自动分段传null 自定义必传
  },
  //检索设置
  retrieval: {
    type: 3, //1向量检索 2全文检索 3混合检索
    semantic: 0.5, //语义0-1
    keywords: 0.5, //关键词 0-1
    topK: 10, //Top K
    score: 0.6, //Score 阈值0-1
    scoreType: true, //是否开启Score 阈值 true开启 false关闭
  },
  //平台数组
  resourcePlatform: [],
  promptId: -1,
});
const props = defineProps(["type", "addForm", "disabled"]);
const emit = defineEmits(["sendForm"]);
const userId = ref(store.state.userId);
const botList = ref([]);
const excelList = ref([
  {
    name: "gpt.index38",
    tip: "gpt.index39",
    icon: icon.base1,
    value: 1,
  },
  {
    name: "gpt.index40",
    tip: "gpt.index41",
    icon: icon.base2,
    value: 0,
  },
]);
const topList = ref([
  {
    name: "gpt.index42",
    tip: "gpt.index43",
    icon: icon.base1,
    value: 1,
  },
  {
    name: "gpt.index40",
    tip: "gpt.index44",
    icon: icon.base2,
    value: 2,
  },
]);
const bottomList = ref([
  {
    name: "gpt.index45",
    tip: "gpt.index46",
    icon: icon.base3,
    value: 1,
  },
  {
    name: "gpt.index47",
    tip: "gpt.index48",
    icon: icon.base4,
    value: 2,
  },
  {
    name: "gpt.index49",
    tip: "gpt.index50",
    icon: icon.base5,
    value: 3,
  },
]);
const ptList = ref([
  {
    name: "gpt.index51",
    icon: icon.baseAzure,
    value: "Azure",
    embedding: "",
    ocr: "",
    model: "",
    embeddingOptions: [],
    ocrOptions: [],
    modelOptions: [],
  },
  {
    name: "gpt.index52",
    icon: icon.baseAli,
    value: "Ali",
    embedding: "",
    ocr: "",
    model: "",
    embeddingOptions: [],
    ocrOptions: [],
    modelOptions: [],
  },
  {
    name: "gpt.index53",
    icon: icon.baseBaidu,
    value: "Baidu",
    embedding: "",
    ocr: "",
    model: "",
    embeddingOptions: [],
    ocrOptions: [],
    modelOptions: [],
  },
  {
    name: "gpt.index54",
    icon: icon.baseOllama,
    value: "Local",
    embedding: "",
    ocr: "",
    model: "",
    embeddingOptions: [],
    ocrOptions: [],
    modelOptions: [],
  },
]);
const templateName = ref("");
onMounted(() => {
  getForm();
});
watch(
  () => form.value,
  (newValue, oldValue) => {
    emit("sendForm", form.value);
  },
  { deep: true }
);
const getForm = () => {
  getBotList();
  getPtList();
  form.value = props.addForm;
  // 判断模式切换对应的分段
  if (props.type == 2) {
    form.value.segment.maximumDescriptionLength = 0;
    form.value.segment.maximumReQueryCount = 0;
  } else {
    topList.value = excelList.value;
    form.value.segment.segmentIdentifier = "";
    form.value.segment.maximumSegmentLength = 0;
    form.value.segment.segmentOverlapRatio = 0;
    form.value.segment.replaceType = false;
    form.value.segment.deleteType = false;
  }
  if (props.addForm.type == 3) {
    topList.value = excelList.value;
    if (props.addForm.segment.type == 2) {
      props.addForm.segment.type = 0;
    }
  } else {
    if (props.addForm.segment.type == 0) {
      props.addForm.segment.type = 2;
    }
  }
  if (props.addForm) {
    form.value.segment = props.addForm.segment;
    form.value.segment.segmentIdentifier =
      form.value.segment.segmentIdentifier.replace(/\n/g, "\\n");
    form.value.retrieval = props.addForm.retrieval;
    form.value.resourcePlatform = props.addForm.resourcePlatform;
  }
  getPtDetail();
};
// 平台内容切换
const changePt = (e) => {
  // 遍历数组2
  ptList.value.forEach((item2) => {
    // 在数组1中查找value相同的对象
    const item1 = form.value.resourcePlatform.find(
      (item1) => item1.value === item2.value
    );
    if (item1) {
      // 如果找到匹配的对象，更新数组1中的对象
      item1.model = item2.model;
      item1.embedding = item2.embedding;
      item1.ocr = item2.ocr;
    }
  });
};
// 回显平台
const getPtDetail = () => {
  // 根据平台传入对应的值
  // 遍历后端数组，更新前端数组
  form.value.resourcePlatform.forEach(async (backendItem) => {
    const frontendItem = ptList.value.find(
      (item) => item.value === backendItem.value
    );
    if (frontendItem) {
      try {
        frontendItem.embeddingOptions = await getOptions(
          backendItem,
          "embedding"
        );
        frontendItem.modelOptions = await getOptions(
          backendItem,
          "text_generator"
        );
        frontendItem.ocrOptions = await getOptions(backendItem, "ocr");
        frontendItem.embedding = backendItem.embedding;
        frontendItem.ocr = backendItem.ocr;
        frontendItem.model = backendItem.model;
      } catch (error) {
        console.error("Error in clickPt:", error);
      }
    }
  });
};
// 切换语义 关键词
const changeRetrieval = () => {
  form.value.retrieval.keywords = 1 - form.value.retrieval.semantic;
};
// 获取可用资源平台
const getPtList = () => {
  api.base
    .platformVerify({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        ptList.value = ptList.value.filter((item) =>
          res.data.includes(item.value)
        );
      }
    });
};
// 搜索
const remoteMethod = (name) => {
  templateName.value = name;
  getBotList();
};
// 获取智能体列表
const getBotList = () => {
  api.base
    .getBaseBot({
      userId: userId.value,
      templateName: templateName.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        botList.value = res.data;
        let result = botList.value.findIndex(
          (item) => item.templateId == form.value.promptId
        );
        if (result == -1) {
          form.value.promptId = null;
        } else {
          form.value.promptId = props.addForm.promptId;
        }
      }
    });
};
// 获取平台embedding
const getOptions = (item, type) => {
  return new Promise((resolve) => {
    api.gpt
      .modelSelGet({
        userId: userId.value,
        resourceType: item.value,
        modelType: type,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          resolve(res.data); // 将结果传递给调用者
        } else {
          resolve([]); // 如果返回码不是 200，返回空数组
        }
      })
      .catch((err) => {
        console.error("Error in getOptions:", err);
        resolve([]); // 如果发生错误，返回空数组
      });
  });
};
// 选择的平台
const clickPt = async (item) => {
  if (props.disabled) return;
  // 查找数组中是否存在该id
  const index = form.value.resourcePlatform.findIndex(
    (items) => items.value === item.value
  );
  if (index !== -1) {
    // 如果存在，删除该id
    form.value.resourcePlatform.splice(index, 1);
  } else {
    try {
      item.embeddingOptions = await getOptions(item, "embedding");
      item.modelOptions = await getOptions(item, "text_generator");
      item.ocrOptions = await getOptions(item, "ocr");
      form.value.resourcePlatform.push(item);
    } catch (error) {
      console.error("Error in clickPt:", error);
    }
  }
};
const getPt = (item) => {
  // 查找数组中是否存在该id
  const index = form.value.resourcePlatform.findIndex(
    (items) => items.value === item.value
  );
  if (index !== -1) {
    return true;
  } else {
    return false;
  }
};
// 切换分段
const changeTop = (value) => {
  if (props.disabled) return;
  form.value.segment = {
    type: 1, //0自动 1自定义
    segmentIdentifier: "", //分段标识符 自动传null 自定义给值必传 文档模式
    maximumSegmentLength: 0, //分段最大长度 自动传null 自定义给值必传 文档模式
    segmentOverlapRatio: 0, //分段重叠比例 自动传null 自定义给值必传 文档模式
    replaceType: false, //替换掉连续的空格、换行符和制表符 true开启 false关闭 自动传null 自定义给值必传 文档模式
    deleteType: false, //删除所有 URL 和电子邮件地址 true开启 false关闭 自动传null 自定义给值必传 文档模式
    maximumDescriptionLength: 0, //表格/文档最大描述长度 表格模式传  自动分段传null 自定义必传
    maximumReQueryCount: 0, //NL2SQL最大重查询次数 表格模式传  自动分段传null 自定义必传
  };
  form.value.segment.type = value;
};
// 切换检索
const changeBottom = (value) => {
  if (props.disabled) return;
  form.value.retrieval = {
    type: 3, //0向量检索 1全文检索 2混合检索
    semantic: 0.5, //语义0-1
    keywords: 0.5, //关键词 0-1
    topK: 10, //Top K
    score: 0.6, //Score 阈值0-1
    scoreType: true, //是否开启Score 阈值 true开启 false关闭
  };
  form.value.retrieval.type = value;
};
</script>

<style lang="scss" scoped>
.wid67 {
  width: calc(100% - 67px);
}
.itemIcon {
  width: 32px;
}
.itemTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}
.borderNo {
  border: 2px solid #edeef6;

  border-radius: 16px;
  box-shadow: 0px 2px 3px 0px #edeef6;
  margin-bottom: 12px;
}
.borderAc {
  border: 2px solid #3376ff;

  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px #dbdfff;
  margin-bottom: 12px;
}
.itemDiv {
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  padding: 14px;
  align-items: center;
  background-color: #f7f8ff;
}
.itemDivAc {
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  padding: 14px;
  align-items: center;
  background-color: #eceeff;
}
.iconItem {
  width: 55px;
  height: 55px;
  border: 1px solid #edeef6;
  background-color: #fefefe;
  border-radius: 12px;
  box-shadow: 0px 4px 6px 0px #dbdfff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.forTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.forTip {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.zdyText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.zdyText1 {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.checkoutDiv {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.marginRight6 {
  margin-right: 6px;
}
.zdyTopTitleDiv {
  padding: 0px 14px;
  margin-top: 18px;
  margin-bottom: 6px;
}
.bottomDivProcess {
  margin-top: 18px;
  margin-bottom: 6px;
}
.topBottomInput {
  padding: 0px 14px;
  margin-bottom: 18px;
}
.bottomProcess {
  margin-bottom: 18px;
}
.numberClass {
  width: 100%;
  :deep(.el-input__wrapper) {
    height: 40px;
    border-radius: 8px;
    background-color: #f6f6f6;
    border: 0px;
    box-shadow: 0px 0px 0px;
  }
  :deep(.el-input-number__decrease) {
    border: 0px;
  }
  :deep(.el-input-number__increase) {
    border: 0px;
  }
}
.numberDiv {
  padding-right: 6px;
}
.numberInput {
  :deep(.el-input__wrapper) {
    height: 40px;
    background-color: #f6f6f6;
    border-radius: 8px;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
  }
}
.bottomDiv {
  padding: 0px 10px;
  margin: 18px 0px;
}
.processDiv {
  background-color: #fefefe;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  box-shadow: 1px 2px 8px 0px rgba(204, 206, 229, 0.25);
  padding: 10px;
  margin-top: 6px;
  :deep(.el-slider) {
    --el-slider-main-bg-color: #11d7b2 !important;
    --el-slider-runway-bg-color: #38aee5 !important;
  }
  :deep(.el-slider__button) {
    width: 16px;
    height: 16px;
  }
  :deep(.el-slider__runway.is-disabled .el-slider__bar) {
    background-color: #11d7b2 !important;
  }
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftText {
  font-size: 14px;
  color: #11d7b2;
  font-weight: 600;
}
.rightText {
  font-size: 14px;
  color: #38aee5;
  font-weight: 600;
}
.switchDiv {
  margin-right: 6px;
}
:deep(.el-switch__core) {
  width: 38px;
  height: 22px;
  border: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  border-radius: 7px;
}
:deep(.el-switch.is-checked .el-switch__core) {
  width: 38px;
  height: 22px;
  border: 0px;
  background-color: #3376ff;
  border-radius: 7px;
}
:deep(.el-switch__core .el-switch__action) {
  background-color: #fefefe;
  left: 2px;
  border-radius: 4px;
}
:deep(.el-switch.is-checked .el-switch__core .el-switch__action) {
  background-color: #ffffff;
  left: calc(100% - 18px);
  border-radius: 4px;
}
.ptIconTip {
  width: 18px;
  margin-left: 6px;
  color: #8a8a8a;
}
.ptIconItem {
  width: 25px;
}
.ptDiv {
  width: 40px;
  height: 40px;
  background-color: #fefefe;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  box-shadow: 0px 4px 6px 0px #dbdfff;
}
.ptSelect {
  margin-top: 6px;
  margin-bottom: 12px;
  :deep(.el-select__wrapper) {
    height: 40px;
    border-radius: 8px;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
    background-color: #f6f6f6;
    font-size: 16px;
    font-weight: 400;
  }
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
.elSelete {
  :deep(.el-select__wrapper) {
    font-size: 16px;
    height: 46px;
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    color: #3d3d3d;
    box-shadow: 0px 0px 0px 0px;
  }
}
.noSel {
  font-size: 16px;
  height: 46px;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  color: #3d3d3d;
  box-shadow: 0px 0px 0px 0px;
  background-color: #f6f6f6;
  line-height: 46px;
  padding-left: 10px;
}
.modelClass {
  font-size: 12px;
  font-weight: 400;
  color: #8a8a8a;
  margin-left: 10px;
  position: relative;
}
.zdyText::before {
  content: "*";
  color: #f02d63;
  position: relative;
  font-size: 18px;
  margin-right: 5px;
}
.mouseNo {
  cursor: not-allowed;
}
</style>
