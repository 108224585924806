<template>
  <!-- 步骤条 -->
  <div class="stepCenter">
    <template v-for="(item, index) in props.list" :key="item.value">
      <div :class="props.step == item.value ? 'stepAc' : 'step'">
        {{ props.step == item.value ? item.name : item.value }}
      </div>
      <div :class="props.step == item.value ? 'stepAcTip' : 'stepTip'">
        {{ item.tip }}
      </div>
      <img
        v-if="index != props.list.length - 1"
        :src="icon.stepLine"
        alt=""
        class="stepLine"
      />
    </template>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
const props = defineProps(["list", "step"]);
</script>

<style lang="scss" scoped>
.stepCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepAc {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-color: #3376ff;
  border-radius: 30px;
  text-align: center;
  padding: 3px 16px;
}
.stepAcTip {
  font-size: 18px;
  color: #0256ff;
  font-weight: 600;
  margin-left: 12px;
}
.stepLine {
  width: 24px;
  margin: 0px 15px;
}
.step {
  border: 1px solid #d9d9d9;
  width: 34px;
  height: 29px;
  border-radius: 30px;
  text-align: center;
  line-height: 29px;
  font-size: 18px;
  color: #d9d9d9;
  font-weight: 500;
}
.stepTip {
  font-size: 18px;
  color: #d9d9d9;
  font-weight: 500;
  margin-left: 12px;
}
</style>
