<template>
  <div>
    <!-- q -->
    <div class="qDiv">
      <div :class="props.type == 'chat' ? 'itemQuestionChat' : 'itemQuestion'">
        {{ props.item.question }}
      </div>
      <div class="flexCenterEnd" v-if="props.copyType">
        <!-- 复制 -->
        <el-tooltip popper-class="toolTip" :content="t('base.base194')">
          <div class="lineIcon" @click="copyFn(props.item)">
            <img :src="icon.chatCopySvg" alt="" class="wid36" />
          </div>
        </el-tooltip>
        <!-- 编辑问题 -->
        <!-- <el-tooltip
            popper-class="toolTip"
            content="编辑"
            v-if="routeType == 'chat'"
          >
            <div class="lineIcon" @click="openEdit(props.item)">
              <img :src="icon.editSvg" alt="" class="wid36" />
            </div>
          </el-tooltip> -->
      </div>
    </div>
    <!-- 左侧 -->
    <div
      v-if="
        !props.item.resonType &&
        props.item.answers[props.item.chatActive - 1].answerContent
      "
      class="resonDiv"
      @click="getReson"
    >
      <img :src="icon.searchSvg" alt="" class="wid19" />
      <div class="resonText">{{ t("base.base276") }}</div>
    </div>
    <!-- 文件 -->
    <chatFile
      v-if="props.item.resonType"
      type="chat"
      :item="props.item"
      :resonDialog="resonDialog"
      @openFileView="openFileView"
      @openTalk="openTalk"
      @openReson="openReson"
      @openResultDialog="openResultDialog"
    ></chatFile>
    <!-- 插件 -->
    <plugView
      type="chat"
      :pluginInfo="props.item.answers[props.item.chatActive - 1].pluginInfo"
      v-if="
        props.item.answers[props.item.chatActive - 1].pluginInfo &&
        props.item.answers[props.item.chatActive - 1].pluginInfo.length != 0
      "
    ></plugView>
    <!-- deepseek思考 -->
    <thinkMessageView
      v-if="
        (props.item.answers[props.item.chatActive - 1].model == 12 ||
          props.item.answers[props.item.chatActive - 1].model ==
            'DeepSeek-R1') &&
        props.ifThink
      "
      :message="props.item.answers[props.item.chatActive - 1].thinkContent"
      :chatMessage="props.item.answers[props.item.chatActive - 1].answerContent"
      :time="props.item.answers[props.item.chatActive - 1].thinkTime"
    ></thinkMessageView>
    <!-- 回答 -->
    <messageView
      v-if="props.item.answers[props.item.chatActive - 1].answerContent"
      :message="props.item.answers[props.item.chatActive - 1].answerContent"
      :class="
        props.item.answers[props.item.chatActive - 1].langType
          ? ''
          : 'messageView'
      "
    ></messageView>
    <span
      class="loader messageView"
      v-if="
        !props.item.answers[props.item.chatActive - 1].answerContent &&
        props.item.answers[props.item.chatActive - 1].model != 12 &&
        props.item.answers[props.item.chatActive - 1].model != 'DeepSeek-R1'
      "
    ></span>
    <!-- 翻译 -->
    <el-divider
      v-if="props.item.answers[props.item.chatActive - 1].langType"
      class="hr"
    />
    <span
      class="loader messageView"
      v-if="
        props.item.answers[props.item.chatActive - 1].langType &&
        !props.item.answers[props.item.chatActive - 1].langText
      "
    ></span>
    <messageView
      v-if="props.item.answers[props.item.chatActive - 1].langText"
      :message="props.item.answers[props.item.chatActive - 1].langText"
      class="messageView"
    ></messageView>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import messageView from "@/components/message/index.vue";
import thinkMessageView from "@/components/message/thinkMessage.vue";
import chatFile from "./chatFile.vue";
import plugView from "./plug.vue";
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
const { t } = useI18n();
import clipboard3 from "vue-clipboard3";
const { toClipboard } = clipboard3();
const route = useRoute();
const store = useStore();
const props = defineProps(["item", "type", "copyType", "ifThink"]);
const emit = defineEmits(["openFileView", "openTalk", "openResultDialog"]);
const userId = ref(store.state.userId);
const topicItem = ref(store.state.topicItem);
const baseItem = ref(store.state.baseItem);
const yzItem = ref(store.state.yzItem);
const botItem = ref(store.state.botItem);
const chatType = ref(store.state.chatType);
const routeType = ref("menu"); //判断菜单 chat base 验证
const resonDialog = ref(false); //来源展示框
const fileObj = ref({});
// 监听切换 然后调用接口
watch(
  () => route.meta.type,
  (newValue, oldValue) => {
    routeType.value = route.meta.type;
    changeItem();
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
    changeItem();
  }
);
watch(
  () => store.state.yzItem,
  (newValue, oldValue) => {
    yzItem.value = newValue;
    changeItem();
  }
);
watch(
  () => store.state.baseItem,
  (newValue, oldValue) => {
    baseItem.value = store.state.baseItem;
    changeItem();
  }
);
watch(
  () => store.state.botItem,
  (newValue, oldValue) => {
    botItem.value = store.state.botItem;
    changeItem();
  }
);
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
    changeItem();
  }
);
watch(
  () => route.path,
  (newValue, oldValue) => {
    routeType.value = route.meta.type;
  }
);
onMounted(() => {
  routeType.value = route.meta.type;
  changeItem();
});
// 打开查询结果
const openResultDialog = (message) => {
  emit("openResultDialog", message);
};
// 根据场景切换不同的item
const changeItem = () => {
  if (routeType.value == "chat") {
    fileObj.value = topicItem.value;
  } else if (routeType.value == "yanzheng") {
    fileObj.value = yzItem.value;
  } else if (routeType.value == "base") {
    fileObj.value = baseItem.value;
  } else if (routeType.value == "bot") {
    fileObj.value = botItem.value;
  }
};
// 复制
const copyFn = async (item) => {
  await toClipboard(item.question); // str 为需要复制的文字
  message.success(t("base.base216"));
};
// 打开文字编辑
const openEdit = (item) => {
  item.editType = true;
};
// 打开会话
const openTalk = (item) => {
  emit("openTalk", item);
};
// 获取来源数据
const getReson = () => {
  console.log(fileObj.value);
  if (props.item.loading == true) {
    message.warning(t("login.login65"));
    return;
  }
  if (chatType.value == "chat") {
    api.base
      .chatHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        topicId: fileObj.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          props.item.resonList = res.data;
          props.item.resonType = true;
          if (resonDialog.value) {
            resonDialog.value = false;
          }
        }
      });
  } else {
    api.base
      .baseHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        libraryId:
          fileObj.value.knowledge_library_id ||
          fileObj.value.libraryId ||
          fileObj.value.knowledge.knowledge_library_id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          props.item.resonList = res.data;
          props.item.resonType = true;
          if ((resonDialog.value = true)) {
            resonDialog.value = false;
          }
        }
      });
  }
};
// 打开来源
const openReson = () => {
  resonDialog.value = true;
};
// 打开文件预览
const openFileView = (item) => {
  emit(
    "openFileView",
    item,
    props.item.resonList.answers[props.item.chatActive - 1].answerResource
      .chunkResource
  );
};
</script>

<style lang="scss" scoped>
.resonText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 25px;
}
.itemQuestion {
  display: flex;
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 12px 26px;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  margin-bottom: 52px;
}
.itemQuestionChat {
  display: flex;
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 12px 26px;
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  margin-bottom: 80px;
  -webkit-box-orient: vertical;
  word-break: break-all; /* 强制换行 */
}
.qDiv {
  display: flex;
  justify-content: flex-end;
  margin-left: 110px;
  position: relative;
}
.messageView {
  margin-bottom: 52px;
}
.loader {
  width: 28px;
  height: 28px;
  border: 4px solid #f6f6f6;
  border-bottom-color: #0256ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.resonDiv {
  display: flex;
  background-color: #f7f8ff;
  border-radius: 30px;
  height: 48px;
  padding: 0px 15px;
  align-items: center;
  margin-bottom: 24px;
  max-width: 240px;
  cursor: pointer;
}
.wid19 {
  width: 19px;
}
.flexCenterEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 0;
}
.lineIcon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}
.wid36 {
  width: 36px;
}
.hr {
  margin: 14px 0px;
}
</style>
