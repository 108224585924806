export default {
    CN: {
        index1: '生成多样性',
        index2: '输入/输出设置',
        index3: '精准',
        index4: '平衡',
        index5: '创意',
        index6: '自定义',
        index7: '文本',
        index8: 'Markdown',
        index9: '温度',
        index10: 'Top P',
        index11: '存在惩罚',
        index12: '频率惩罚',
        index13: '上下文轮数',
        index14: '最大标记',
        index15: '输出格式',
        index16: '语音输入',
        index17: '停止语音输入',
        index18: '分段设置',
        index19: '分段标识符',
        index20: '分段最大长度',
        index21: '分段重叠比例',
        index22: '文本预处理规则',
        index23: '替换掉连续的空格、换行符和制表符',
        index24: '删除所有 URL 和电子邮件地址',
        index25: '表格/文档最大描述长度',
        index26: 'NL2SQL最大重查询次数',
        index27: '次',
        index28: '检索设置',
        index29: '权重比例设置',
        index30: '语义',
        index31: '关键词',
        index32: 'Score 阈值',
        index33: '资源平台',
        index34: 'Embedding 模型',
        index35: '文档识别资源',
        index36: '大语言模型',
        index37: 'NextAI平台优先使用该大模型解析文档复杂结构',
        index38: '自动分段和查询',
        index39: '自动分段和配置查询',
        index40: '自定义',
        index41: '自定义分段和查询规则',
        index42: '自动分段清洗',
        index43: '自动分段与预处理规则',
        index44: '自定义分段规则、分段长度及预处理规则',
        index45: '向量检索',
        index46: '为查询问题生成嵌入向量并查询与其向量参数表示最相近的文本分段',
        index47: '全文检索',
        index48: '索引文档中的词汇,根据用户查询问题返回包含相同词汇的文本分段',
        index49: '混合检索',
        index50: '使用全文检索和向量检索多路召回,通过重排序选择匹配最佳结果',
        index51: 'Azure 云资源',
        index52: '阿里云资源',
        index53: '百度智能云资源',
        index54: 'Ollama 本地资源',
        index55: 'NL2SQL查询语句',
        index56: '运行',
        index57: '个插件',
        index58: '插件',
        index59: '工具',
        index60: '请求体',
        index61: '响应体',
        index62: '查询结果',
        index63: '参数配置',
        index64: '恢复默认',
        index65: '配置名称',
        index66: '配置',
        index67: '表格',
        index68: '记录',
        index69: '查看文件描述',
        index70: '设为权威文件',
        index71: '查询结果',
        index72: '记录',
        index73: '表格描述',
        index74: '文件描述',
        index75: '未检测到任何发言。',
        index76: '正在语音识别！',
        index77: '错误原因',
        index78: '请输入部门名称或用户名',
        index79: '英⽂',
        index80: '简体中文',
        index81: '繁体中文',
        index82: '⽇⽂',
        index83: '韩⽂',
        index84: '俄⽂',
        index85: '⻄班⽛⽂',
        index86: '法⽂',
        index87: '意⼤利⽂',
        index88: '葡萄⽛⽂',
        index89: '阿拉伯⽂',
        index90: '德⽂',
        Azure: 'Azure云平台',
        Baidu: '百度智能云',
        Ali: '阿里云平台',
        Local: 'Ollama',
        index91:'当前文件或文件夹无法被拖动至自身位置。',
    },
    HK: {
        index1: '生成多樣性',
        index2: "輸入/輸出設置",
        index3: "精準",
        index4: "平衡",
        index5: '創意',
        index6: "自定義",
        index7: "文本",
        index8: 'Markdown',
        index9: "溫度",
        index10: 'Top P',
        index11: "存在懲罰",
        index12: "頻率懲罰",
        index13: "上下文輪數",
        index14: "最大標記",
        index15: "輸出格式",
        index16: '語音輸入',
        index17: '停止語音輸入',
        index18: "分段設置",
        index19: "分段標識符",
        index20: "分段最大長度",
        index21: "分段重疊比例",
        index22: '文本預處理規則',
        index23: "替換掉連續的空格、換行符和製表元",
        index24: '刪除所有 URL 和電子郵件位址',
        index25: '表格/文檔最大描述長度',
        index26: 'NL2SQL最大重查詢次數',
        index27: "次",
        index28: "檢索設置",
        index29: '權重比例設置',
        index30: '語義',
        index31: '關鍵詞',
        index32: 'Score 閾值',
        index33: '資源平臺',
        index34: 'Embedding 模型',
        index35: '文檔識別資源',
        index36: "大語言模型",
        index37: 'NextAI平台優先使用該大模型解析文檔複雜結構',
        index38: '自動分段和查詢',
        index39: '自動分段和配置查詢',
        index40: '自定義',
        index41: '自定義分段和查詢規則',
        index42: '自動分段清洗',
        index43: '自動分段與預處理規則',
        index44: '自定義分段規則、分段長度及預處理規則',
        index45: "向量檢索",
        index46: '為查詢問題生成嵌入向量並查詢與其向量參數表示最相近的文本分段',
        index47: "全文檢索",
        index48: '索引文檔中的詞彙,根據使用者查詢問題返回包含相同詞彙的文本分段',
        index49: "混合檢索",
        index50: '使用全文檢索和向量檢索多路召回,通過重排序選擇匹配最佳結果',
        index51: 'Azure 雲資源',
        index52: "阿裡雲資源",
        index53: "百度智能雲資源",
        index54: 'Ollama 本地資源',
        index55: 'NL2SQL查詢語句',
        index56: "運行",
        index57: '個外掛程式',
        index58: "外掛程式",
        index59: '工具',
        index60: "請求體",
        index61: '回應體',
        index62: "查詢結果",
        index63: "參數配置",
        index64: "恢復默認",
        index65: '配置名稱',
        index66: '配置',
        index67: "表格",
        index68: '記錄',
        index69: "查看文件描述",
        index70: '設為權威文件',
        index71: "查詢結果",
        index72: "記錄",
        index73: '表格描述',
        index74: '文件描述',
        index75: '未檢測到任何發言。',
        index76: '正在語音辨識！',
        index77: '錯誤原因',
        index78: '請輸入部門名稱或使用者名',
        index79: '英文',
        index80: '簡體中文',
        index81: '繁體中文',
        index82: '日文',
        index83: '韓文',
        index84: '俄文',
        index85: '⻄班牙文',
        index86: '法文',
        index87: '義大利文',
        index88: '葡萄牙文',
        index89: '阿拉伯文',
        index90: '德文',
        Azure: 'Azure雲平台',
        Baidu: '百度智能雲',
        Ali: '阿里雲平台',
        Local: 'Ollama',
        index91:'當前檔或資料夾無法被拖動至自身位置。',
    },
    US: {
        index1: 'Generate diversity',
        index2: 'Input/Output Settings',
        index3: 'Accurate',
        index4: 'Balance',
        index5: 'Creativity',
        index6: 'Customize',
        index7: 'Text',
        index8: 'Markdown',
        index9: 'Temperature',
        index10: 'Top P',
        index11: 'Penalty exists',
        index12: 'Frequency penalty',
        index13: 'Contextual rounds',
        index14: 'Maximum mark',
        index15: 'Output Format',
        index16: 'Voice input',
        index17: 'Stop voice typing',
        index18: 'Segment Settings',
        index19: 'Segment identifier',
        index20: 'Maximum length of segments',
        index21: 'Segment overlap ratio',
        index22: 'Text preprocessing rules',
        index23: 'Replace sequential spaces, line breaks, and tabs',
        index24: 'Remove all URLs and email addresses',
        index25: 'Maximum description length of table/document',
        index26: 'Maximum number of requeries in NL2SQL',
        index27: 'Times',
        index28: 'Search settings',
        index29: 'Weight Ratio Setting',
        index30: 'Semantics',
        index31: 'keyword',
        index32: 'Score threshold',
        index33: 'Resource Platform',
        index34: 'Embedding model',
        index35: 'Document Identification Resources',
        index36: 'Large language model',
        index37: 'NextAI platform preferentially uses this large model to parse complex structures of documents',
        index38: 'Automatic segmentation and query',
        index39: 'Automatic segmentation and configuration queries',
        index40: 'Custom',
        index41: 'Custom segmentation and query rules',
        index42: 'Automatic sectional cleaning',
        index43: 'Automatic segmentation and preprocessing rules',
        index44: 'Custom segmentation rules, segment lengths, and preprocessing rules',
        index45: 'Vector search',
        index46: 'Generate an embedding vector for a query problem and query for the text segment that most closely matches its vector parameter representation',
        index47: 'Full text search',
        index48: "Index vocabulary in a document, return text segments containing the same vocabulary based on the user's query question",
        index49: 'Mixed search',
        index50: 'Multiplex recall using full-text and vector retrieval to select the best matching result by reordering',
        index51: 'Azure Cloud Resources',
        index52: 'Alibaba Cloud resources',
        index53: 'Baidu Intelligent Cloud Resources',
        index54: 'Ollama Local Resources',
        index55: 'NL2SQL query statement',
        index56: 'Run',
        index57: 'Plugins',
        index58: 'Plugin',
        index59: 'Tools',
        index60: 'Request body',
        index61: 'Response body',
        index62: 'Query result',
        index63: 'Parameter configuration',
        index64: 'Restore default',
        index65: 'configuration name',
        index66: 'Configuration',
        index67: 'Table',
        index68: 'Record',
        index69: 'View file description',
        index70: 'Make authority',
        index71: 'query result',
        index72: 'Record',
        index73: 'Table description',
        index74: 'File description',
        index75: 'No statements were detected.',
        index76: 'Speech recognition is ongoing!',
        index77: 'Reason for the error',
        index78: 'Please enter the department name or username',
        index79: 'English',
        index80: 'Chinese Simplified',
        index81: 'Chinese Traditional',
        index82: 'Japanese',
        index83: 'Korean',
        index84: 'Russian',
        index85: 'Siban Yawen',
        index86: 'French',
        index87: 'Italian',
        index88: 'Portuguese',
        index89: 'Arabic',
        index90: 'German',
        Azure: 'Azure Cloud Platform',
        Baidu: 'Baidu Intelligent Cloud',
        Ali: 'Alibaba Cloud Platform',
        Local: 'Ollama',
        index91:'The current file or folder cannot be dragged to its own location.',
    },
};
