<template>
  <div class="dialogView">
    <div class="flexBetween">
      <div class="dialogTitle">{{ t("base.base109") }}</div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeDialog" />
    </div>
    <!-- 拥有者 -->
    <div v-if="baseItem.knowledge_role_id == 0">
      <div class="dialogTip">
        {{ t("base.base110") }}
      </div>
      <div class="flexBetween bottom17">
        <span class="addLabel nameLabel">{{ t("base.base26") }}</span>
        <span class="ptTip">{{ t("base.base112") }}</span>
      </div>
      <div class="divMax">
        <div
          v-for="item in ptList"
          :key="item.value"
          :class="getPt(item) ? 'borderAc' : 'borderNo'"
        >
          <div
            :class="{
              itemDivAc: getPt(item),
              itemDiv: !getPt(item),
              mouseNo: ptDisabled(item.value),
            }"
            @click="clickPt(item)"
          >
            <div class="ptDiv">
              <img :src="item.icon" alt="" class="ptIconItem" />
            </div>
            <div class="forTitle">{{ t(item.name) }}</div>
          </div>
          <template v-if="getPt(item)">
            <div class="bottomDiv">
              <div class="zdyText">{{ t("gpt.index34") }}</div>
              <el-select
                v-model="item.embedding"
                class="ptSelect"
                :disabled="ptDisabled(item.value)"
                @change="changePt"
              >
                <el-option
                  v-for="items in item.embeddingOptions"
                  :key="items"
                  :label="items"
                  :value="items"
                >
                  <span class="dropText">{{ items }}</span>
                </el-option>
              </el-select>
              <div class="zdyText">{{ t("gpt.index35") }}</div>
              <el-select
                v-model="item.ocr"
                class="ptSelect"
                :disabled="ptDisabled(item.value)"
                @change="changePt"
              >
                <el-option
                  v-for="items in item.ocrOptions"
                  :key="items"
                  :label="items"
                  :value="items"
                >
                  <span class="dropText">{{ items }}</span>
                </el-option>
              </el-select>
              <div class="zdyText">
                {{ t("gpt.index36") }}
                <el-tooltip popper-class="toolTip" :content="t('gpt.index37')">
                  <span class="modelClass">{{ t("gpt.index37") }}</span>
                </el-tooltip>
              </div>
              <el-select
                v-model="item.model"
                class="ptSelect"
                :disabled="false"
                @change="changePt"
              >
                <el-option
                  v-for="items in item.modelOptions"
                  :key="items"
                  :label="items"
                  :value="items"
                >
                  <span class="dropText">{{ items }}</span>
                </el-option>
              </el-select>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 普通 -->
    <div v-if="baseItem.knowledge_role_id != 0">
      <div class="dialogTip">{{ t("base.base111") }}</div>
    </div>
    <div class="btnDiv" v-if="baseItem.knowledge_role_id == 0">
      <!-- 放弃 -->
      <el-button class="cancelBtn" @click="closeDialog">{{
        t("base.base113")
      }}</el-button>
      <!-- 重新分析 -->
      <el-button class="sureBtn" @click="changeBtn" :disabled="disabledBtn()">{{
        t("base.base116")
      }}</el-button>
      <!-- 转移权限 -->
      <el-button
        class="sureBtn"
        @click="openPeople"
        :disabled="disabledBtn()"
        >{{ t("base.base117") }}</el-button
      >
    </div>
    <div class="btnDiv" v-if="baseItem.knowledge_role_id != 0">
      <!-- 放弃 -->
      <el-button class="cancelBtn" @click="closeDialog">{{
        t("base.base113")
      }}</el-button>
      <!-- 退出 -->
      <el-button class="cancelBtn" @click="outBase">
        {{ t("base.base114") }}
      </el-button>
      <!-- 转为普通成员 -->
      <el-button class="sureBtn" @click="toPt">
        {{ t("base.base115") }}
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
  <div v-if="dialogTableVisible" class="dialogView1">
    <div>
      <div class="flexBetween">
        <div class="dialogTitle">{{ t("base.base117") }}</div>
        <img
          :src="icon.addClose"
          alt=""
          class="addClose"
          @click="dialogTableVisible = false"
        />
      </div>
    </div>
    <el-table :data="userList" :height="300" border v-loading="userLoading">
      <el-table-column :label="t('base.base126')" align="center">
        <template #default="scope">
          <span class="classTableItem">{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template #default="scope">
          <el-button class="sureBtn" @click="toUser(scope.row.userId)">
            {{ t("base.base313") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="mask1" v-if="dialogTableVisible"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api";
import { message } from "ant-design-vue";
const { t } = useI18n();
const store = useStore();
const emit = defineEmits(["closeTipDialog", "getList"]);
const baseItem = ref(store.state.baseItem);
const userId = ref(store.state.userId);
const userLoading = ref(false);
const userList = ref([]);
const dialogTableVisible = ref(false);
const ptActive = ref([]);
const ptList = ref([
  {
    name: "gpt.index51",
    icon: icon.baseAzure,
    value: "Azure",
    embedding: "",
    ocr: "",
    model: "",
    embeddingOptions: [],
    ocrOptions: [],
    modelOptions: [],
  },
  {
    name: "gpt.index52",
    icon: icon.baseAli,
    value: "Ali",
    embedding: "",
    ocr: "",
    model: "",
    embeddingOptions: [],
    ocrOptions: [],
    modelOptions: [],
  },
  {
    name: "gpt.index53",
    icon: icon.baseBaidu,
    value: "Baidu",
    embedding: "",
    ocr: "",
    model: "",
    embeddingOptions: [],
    ocrOptions: [],
    modelOptions: [],
  },
  {
    name: "gpt.index54",
    icon: icon.baseOllama,
    value: "Local",
    embedding: "",
    ocr: "",
    model: "",
    embeddingOptions: [],
    ocrOptions: [],
    modelOptions: [],
  },
]);
onMounted(() => {
  ptActive.value = baseItem.value.resourcePlatform;
  console.log(ptActive.value, "我选择的平台");
  getPtDetail();
});
// 获取禁用的
const ptDisabled = (id) => {
  // 选择的情况下 让点
  if (ptActive.value.findIndex((item) => item.value == id) != -1) {
    return false;
  }
  const embeddingTypeKey = {
    Azure: "azureEmbedding",
    Ali: "aliEmbedding",
    Baidu: "baiduEmbedding",
    Local: "localEmbedding",
  };
  if (!embeddingTypeKey[id]) {
    return true;
  }
  const ids = baseItem.value.current_user_embedding_type.filter((item) =>
    [embeddingTypeKey[id]].includes(item)
  );
  return ids.length === 0;
};
// 回显平台
const getPtDetail = () => {
  // 根据平台传入对应的值
  // 遍历后端数组，更新前端数组
  ptActive.value.forEach(async (backendItem) => {
    const frontendItem = ptList.value.find(
      (item) => item.value === backendItem.value
    );
    if (frontendItem) {
      try {
        frontendItem.embeddingOptions = await getOptions(
          backendItem,
          "embedding"
        );
        frontendItem.modelOptions = await getOptions(
          backendItem,
          "text_generator"
        );
        frontendItem.ocrOptions = await getOptions(backendItem, "ocr");
        frontendItem.embedding = backendItem.embedding;
        frontendItem.ocr = backendItem.ocr;
        frontendItem.model = backendItem.model;
      } catch (error) {
        console.error("Error in clickPt:", error);
      }
    }
  });
};
const getPt = (item) => {
  // 查找数组中是否存在该id
  const index = baseItem.value.resourcePlatform.findIndex(
    (items) => items.value === item.value
  );
  if (index !== -1) {
    return true;
  } else {
    return false;
  }
};
// 获取平台embedding
const getOptions = (item, type) => {
  return new Promise((resolve) => {
    api.gpt
      .modelSelGet({
        userId: userId.value,
        resourceType: item.value,
        modelType: type,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          resolve(res.data); // 将结果传递给调用者
        } else {
          resolve([]); // 如果返回码不是 200，返回空数组
        }
      })
      .catch((err) => {
        console.error("Error in getOptions:", err);
        resolve([]); // 如果发生错误，返回空数组
      });
  });
};
// 选择的平台
const clickPt = async (item) => {
  if (ptDisabled(item.value)) return;
  // 查找数组中是否存在该id
  const index = baseItem.value.resourcePlatform.findIndex(
    (items) => items.value === item.value
  );
  if (index !== -1) {
    // 如果存在，删除该id
    ptActive.value.splice(index, 1);
  } else {
    try {
      item.embeddingOptions = await getOptions(item, "embedding");
      item.modelOptions = await getOptions(item, "text_generator");
      item.ocrOptions = await getOptions(item, "ocr");
      ptActive.value.push(item);
    } catch (error) {
      console.error("Error in clickPt:", error);
    }
  }
};
// 平台内容切换
const changePt = (e) => {
  // 遍历数组2
  ptList.value.forEach((item2) => {
    // 在数组1中查找value相同的对象
    const item1 = ptActive.value.find((item1) => item1.value === item2.value);
    if (item1) {
      // 如果找到匹配的对象，更新数组1中的对象
      item1.model = item2.model;
      item1.embedding = item2.embedding;
      item1.ocr = item2.ocr;
    }
  });
};
// 关闭 放弃
const closeDialog = () => {
  emit("closeTipDialog");
};
// 禁用重新分析  权限转义
const disabledBtn = () => {
  if (ptActive.value.length == 0) {
    return true;
  } else {
    for (let item of ptActive.value) {
      if (!item.embedding || !item.ocr || !item.model) {
        return true;
      }
    }
  }

  return false;
};
// 重新分析
const changeBtn = () => {
  api.base
    .embeddingChange({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
      resourcePlatform: ptActive.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base314"));
        emit("getList");
      }
    });
};
// 推出知识库
const outBase = () => {
  // 退出微模型
  api.base
    .libraryExit(
      {
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base17"));
        emit("getList");
      }
    });
};
// 转普通
const toPt = () => {
  api.base
    .grTransfer({
      userId: userId.value,
      ownerId: userId.value,
      ownerType: 0,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base315"));
        emit("getList");
      }
    });
};
// 打开转让
const openPeople = () => {
  dialogTableVisible.value = true;
  getUser();
};
// 获取用户
const getUser = () => {
  userLoading.value = true;
  api.base
    .canUser({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      userLoading.value = false;
      if (res.returnCode == 200) {
        userList.value = res.data;
      }
    })
    .catch((err) => {
      userLoading.value = false;
    });
};
// 转让
const toUser = (id) => {
  api.base
    .baseTransfer({
      userId: userId.value,
      newOwnerId: id,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base316"));
        dialogTableVisible.value = false;
        closeDialog();
      }
    });
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 120;
  opacity: 0.3;
}
.dialogView {
  position: fixed;
  width: 540px;
  border-radius: 30px;
  background-color: #ffffff;
  z-index: 200;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mask1 {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 200;
  opacity: 0.3;
}
.dialogView1 {
  position: fixed;
  width: 540px;
  border-radius: 30px;
  background-color: #ffffff;
  z-index: 201;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.dialogTitle {
  font-size: 20px;
  font-weight: 500;
  color: #3d3d3d;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTip {
  margin: 24px 0px;
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  line-height: 28px;
}
.addLabel {
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  position: relative;
}
.nameLabel::before {
  position: absolute;
  content: "*";
  color: #f02d63;
  right: -15px;
}
.ptTip {
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 500;
}
.bottom17 {
  margin-bottom: 17px;
}
.cancelBtn {
  border: 2px solid #edeef6;
  height: 48px;
  border-radius: 16px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
  padding: 12px 20px;
  margin-left: 12px !important;
}
.sureBtn {
  border: 2px solid #3376ff;
  background-color: #3376ff;
  height: 48px;
  border-radius: 16px;
  font-size: 18px;
  color: #fefefe;
  font-weight: 400;
  padding: 12px 20px;
  margin-left: 12px !important;
}
.btnDiv {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
:deep(.el-table--border th.el-table__cell) {
  background-color: #f7f8ff;
  font-size: 18px;
  color: #8a8a8a;
}
.classTableItem {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 400;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.borderNo {
  border: 2px solid #edeef6;

  border-radius: 16px;
  box-shadow: 0px 2px 3px 0px #edeef6;
  margin-bottom: 12px;
}
.borderAc {
  border: 2px solid #3376ff;

  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px #dbdfff;
  margin-bottom: 12px;
}
.ptDiv {
  width: 40px;
  height: 40px;
  background-color: #fefefe;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  box-shadow: 0px 4px 6px 0px #dbdfff;
}
.ptIconItem {
  width: 25px;
}
.forTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.bottomDiv {
  padding: 0px 10px;
  margin: 18px 0px;
}
.zdyText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 500;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
.ptSelect {
  margin-top: 6px;
  margin-bottom: 12px;
  :deep(.el-select__wrapper) {
    height: 40px;
    border-radius: 8px;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
    background-color: #f6f6f6;
    font-size: 16px;
    font-weight: 400;
  }
}
.divMax {
  max-height: 500px;
  overflow: auto;
}
.itemDivAc {
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  padding: 14px;
  align-items: center;
  background-color: #eceeff;
}
.itemDiv {
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  padding: 14px;
  align-items: center;
  background-color: #f7f8ff;
}
.mouseNo {
  cursor: not-allowed;
}
.modelClass {
  font-size: 12px;
  font-weight: 400;
  color: #8a8a8a;
  margin-left: 10px;
  position: relative;
}
.modelClass::before {
  content: "*";
  color: #f02d63;
  position: absolute;
  font-size: 18px;
  left: -10px;
}
.zdyText::before {
  content: "*";
  color: #f02d63;
  position: relative;
  font-size: 18px;
  margin-right: 5px;
}
</style>
