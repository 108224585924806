<script setup>
import { ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { getTime } from "@/utils/utils";
import { formatTime1 } from "@/utils/qincheng";
import { icon } from "@/utils/icon";
const store = useStore();
const userId = ref(store.state.userId);
const { t } = useI18n();
const deleteType = ref(false);
const loading = ref(false);
onMounted(() => {
  getList();
});
const status = ref("userNamePattern");
const search = ref([
  {
    value: "userNamePattern",
    label: t("manage.userForm.userName"),
  },
  {
    value: "sessionNamePattern",
    label: t("manage.topicForm.topic1"),
  },
]);
// 打开回收站
const openDelete = () => {
  deleteType.value = true;
  getList2();
};
// 关闭回收站
const closeDelete = () => {
  getList();
  deleteType.value = false;
};
// 用户表格
const tableMember = ref([]);
const totalItems = ref(0);
// 分页
const pageSize = ref(20);
const currentPage = ref(1);
// 每页显示条数
const updatePageSize = (newSize) => {
  pageSize.value = newSize;
};
// 更新当前页码
const updateCurrentPage = (newPage) => {
  currentPage.value = newPage;
};
watch(
  () => pageSize.value,
  (newVal) => {
    formList.value.perPage = newVal;
    getList();
  }
);
watch(
  () => currentPage.value,
  (newVal) => {
    formList.value.pageId = newVal;
    getList();
  }
);
const valueInput = ref("");
const formList = ref({
  userId: userId.value,
  pageId: currentPage.value,
  perPage: pageSize.value,
  operationType: 0,
  userNamePattern: "",
  sessionNamePattern: "",
  startDate: "",
  endDate: "",
});
const timeValue = ref("");
const changeTimeValue = (newVal) => {
  formList.value.startDate = formatTime1(newVal[0]);
  formList.value.endDate = formatTime1(newVal[1]);
  getList();
};
// 清除时间选择器事件
const clearRefresh = () => {
  valueInput.value = "";
  formList.value.userNamePattern = "";
  formList.value.sessionNamePattern = "";
  timeValue.value = "";
  formList.value.startDate = "";
  formList.value.endDate = "";
  getList();
};
watch(
  () => valueInput.value,
  (newVal) => {
    if (status.value == "userNamePattern") {
      formList.value.userNamePattern = newVal;
      formList.value.sessionNamePattern = "";
    } else if (status.value == "sessionNamePattern") {
      formList.value.userNamePattern = "";
      formList.value.sessionNamePattern = newVal;
    }
    getList();
  }
);
watch(
  () => status.value,
  (newVal) => {
    if (newVal == "userNamePattern") {
      getList();
    } else if (newVal == "sessionNamePattern") {
      getList();
    }
  }
);
// 表单查询
const getList = () => {
  loading.value = true;
  api.manage.getTopicListApi(formList.value).then((res) => {
    if (res.returnCode == 200) {
      tableMember.value = res.data;
      totalItems.value = res.totalNum;
      loading.value = false;
    }
  });
};
watch(
  () => formList.userNamePattern,
  (newVal) => {
    formList.userNamePattern = newVal;
    getList();
  }
);
// 多选
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  console.log(val);
  multipleSelection.value = val;
};
// 多选
const multipleSelection2 = ref([]);
const handleSelectionChange2 = (val) => {
  console.log(val);
  multipleSelection2.value = val;
};
const returnForm = ref({
  userId: userId.value,
  operationType: 0,
  sessionInfo: [],
});
const listFor = ref({
  sessionId: "",
  typeId: null,
});
// 归档
const returnBtn = (row) => {
  console.log(row);
  listFor.value.sessionId = row.sessionId;
  listFor.value.typeId = row.typeId;
  returnForm.value.sessionInfo = []
  returnForm.value.sessionInfo.push(listFor.value);
  api.manage.getTopicArchiveApi(returnForm.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.topicForm.topic2"));
      getList();
    }
  });
};
const returnForm2 = ref({
  userId: userId.value,
  operationType: 1,
  sessionInfo: [],
});
// 删除
const deleteBtn = (row) => {
  listFor.value.sessionId = row.sessionId;
  listFor.value.typeId = row.typeId;
  returnForm2.value.sessionInfo = []
  returnForm2.value.inTrash = deleteType.value ? 1 : 0;
  returnForm2.value.sessionInfo.push(listFor.value);
  api.manage.getTopicArchiveApi(returnForm2.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.topicForm.topic3"));
      getList();
      getList2();
    }
  });
};
// 批量归档
const allReturn = () => {
  if (multipleSelection.value.length == 0) {
    message.error(t("manage.topicForm.topic4"));
    return;
  }
  returnForm.value.sessionInfo = [];
  multipleSelection.value.forEach((item) => {
    listFor.value = {
      sessionId: item.sessionId,
      typeId: item.typeId,
    };
    returnForm.value.sessionInfo.push(listFor.value);
  });
  api.manage.getTopicArchiveApi(returnForm.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.topicForm.topic2"));
      getList();
    }
  });
};
// 批量删除
const allDelete = (num) => {
  if (num == 1) {
    if (multipleSelection.value.length == 0) {
      message.error(t("manage.topicForm.topic5"));
      return;
    }
    returnForm2.value.sessionInfo = [];
    multipleSelection.value.forEach((item) => {
      listFor.value = {
        sessionId: item.sessionId,
        typeId: item.typeId,
      };
      returnForm2.value.sessionInfo.push(listFor.value);
    });
  } else if (num == 2) {
    if (multipleSelection2.value.length == 0) {
      message.error(t("manage.topicForm.topic5"));
      return;
    }
    returnForm2.value.sessionInfo = [];
    multipleSelection2.value.forEach((item) => {
      listFor.value.sessionId = item.sessionId;
      listFor.value.typeId = item.typeId;
      returnForm2.value.sessionInfo.push(listFor.value);
    });
  }
  returnForm2.value.inTrash = deleteType.value ? 1 : 0;
  api.manage.getTopicArchiveApi(returnForm2.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.topicForm.topic3"));
      getList();
      getList2();
    }
  });
};
// =====回收站=====
// 分页
const status2 = ref("userNamePattern");
const currentPage2 = ref(1);
const pageSize2 = ref(20);
const totalItems2 = ref(null);
const tableMember2 = ref([]);
const updatePageSize2 = (val) => {
  pageSize2.value = val;
  getList2();
};
const updateCurrentPage2 = (val) => {
  currentPage2.value = val;
  getList2();
};
watch(
  () => pageSize2.value,
  (newVal) => {
    formList2.value.perPage = newVal;
    getList2();
  }
);
watch(
  () => currentPage2.value,
  (newVal) => {
    formList2.value.pageId = newVal;
    getList2();
  }
);
const valueInput2 = ref("");
watch(
  () => valueInput2.value,
  (newVal) => {
    if (status2.value == "userNamePattern") {
      formList2.value.userNamePattern = newVal;
      formList2.value.sessionNamePattern = "";
    } else if (status2.value == "sessionNamePattern") {
      formList2.value.userNamePattern = "";
      formList2.value.sessionNamePattern = newVal;
    }
    getList2();
  }
);
watch(
  () => status2.value,
  (newVal) => {
    if (newVal == "userNamePattern") {
      getList2();
    } else if (newVal == "sessionNamePattern") {
      getList2();
    }
  }
);
// 查询
const formList2 = ref({
  userId: userId.value,
  pageId: currentPage2.value,
  perPage: pageSize2.value,
  operationType: 1,
  userNamePattern: "",
  sessionNamePattern: "",
  startDate: "",
  endDate: "",
});
const timeValue2 = ref("");
// 清除时间选择器
const changeTimeValue2 = (newVal) => {
  formList2.value.startDate = formatTime1(newVal[0]);
  formList2.value.endDate = formatTime1(newVal[1]);
  getList2();
};
// 重置查询信息
const clearRefresh2 = () => {
  valueInput2.value = "";
  formList2.value.userNamePattern = "";
  formList2.value.sessionNamePattern = "";
  timeValue2.value = "";
  formList2.value.startDate = "";
  formList2.value.endDate = "";
  getList2();
};
const getList2 = () => {
  api.manage.getTopicListApi(formList2.value).then((res) => {
    if (res.returnCode == 200) {
      tableMember2.value = res.data;
      totalItems2.value = res.totalNum;
    }
  });
};
watch(
  () => formList2.userNamePattern,
  (newVal) => {
    formList2.userNamePattern = newVal;
    getList2();
  }
);

const returnForm3 = ref({
  userId: userId.value,
  operationType: 2,
  sessionInfo: [],
});
// 恢复
const restoreBtn = (row) => {
  returnForm3.value.sessionInfo = [];
  listFor.value.sessionId = row.sessionId;
  listFor.value.typeId = row.typeId;
  returnForm3.value.sessionInfo.push(listFor.value);
  api.manage.getTopicArchiveApi(returnForm3.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.topicForm.topic6"));
      getList2();
    }
  });
};
// 批量恢复
const allRestore = () => {
  if (multipleSelection2.value.length == 0) {
    message.error(t("manage.topicForm.topic7"));
    return;
  }
  returnForm3.value.sessionInfo = [];
  multipleSelection2.value.forEach((item) => {
    listFor.value = {
      sessionId: item.sessionId,
      typeId: item.typeId,
    };
    returnForm3.value.sessionInfo.push(listFor.value);
  });
  api.manage.getTopicArchiveApi(returnForm3.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.topicForm.topic6"));
      getList2();
    }
  });
};
</script>

<template>
  <div class="main" v-loading="loading">
    <!-- 列表展示 -->
    <div v-if="!deleteType" class="hei100">
      <div class="menuBox">
        <div style="display: flex">
          <!-- 用户 -->
          <div class="User">
            <div class="searchDiv">
              <el-select v-model="status">
                <el-option
                  v-for="(item, index) in search"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                  <span class="dropText">{{ item.label }}</span>
                </el-option>
              </el-select>
              <el-input
                v-model="valueInput"
                :placeholder="$t('manage.topicForm.topic8')"
              ></el-input>
            </div>
          </div>
          <!-- 起止时间 -->
          <div class="User">
            <div class="searchDiv">
              <div class="time">{{ t("manage.topicForm.topic9") }}</div>
              <el-date-picker
                v-model="timeValue"
                type="daterange"
                :range-separator="$t('manage.topicForm.topic10')"
                :start-placeholder="$t('manage.topicForm.topic11')"
                :end-placeholder="$t('manage.topicForm.topic12')"
                :clearable="false"
                @change="changeTimeValue"
              />
            </div>
          </div>
          <div
            class="User1"
            style="border: #dcdfe6 1px solid"
            @click="clearRefresh"
          >
            <el-icon class="icon">
              <Refresh />
            </el-icon>
          </div>
        </div>
        <!-- 功能区 -->
        <div style="display: flex">
          <el-button
            class="toolBox"
            @click="allReturn"
            :disabled="$hasPermission('10037') == false"
          >
            {{ t("manage.topicForm.topic13") }}
          </el-button>
          <el-button
            class="toolBox"
            @click="allDelete(1)"
            :disabled="$hasPermission('10038') == false"
          >
            {{ t("manage.topicForm.topic14") }}
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableMember"
        @selection-change="handleSelectionChange"
        class="tableHeight"
      >
        <el-table-column type="selection" />
        <el-table-column
          prop="userName"
          :label="$t('manage.topicForm.topic15')"
        />
        <el-table-column
          prop="sessionName"
          :label="$t('manage.topicForm.topic16')"
        >
          <template #default="scope">
            <el-tooltip popper-class="toolTip" :content="scope.row.sessionName">
              <div class="wid100">{{ scope.row.sessionName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="conversationNum"
          :label="$t('manage.topicForm.topic17')"
          width="80"
        />
        <el-table-column prop="type" :label="$t('manage.topicForm.topic18')" />
        <el-table-column
          prop="createTime"
          :label="$t('manage.topicForm.topic19')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="getTime(scope.row.createTime)"
            >
              <div class="wid100">{{ getTime(scope.row.createTime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="$t('manage.topicForm.topic20')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="getTime(scope.row.lastUpdateTime)"
            >
              <div class="wid100">{{ getTime(scope.row.lastUpdateTime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('manage.topicForm.topic21')"
          class="operation"
        >
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="returnBtn(scope.row)"
              :disabled="$hasPermission('10037') == false"
            >
              {{ t("manage.topicForm.topic22") }}
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              @click="deleteBtn(scope.row)"
              :disabled="$hasPermission('10038') == false"
            >
              {{ t("manage.topicForm.topic23") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="deleteDiv" @click="openDelete">
          <img :src="icon.hszImg" alt="" class="deleteImg" />
          {{ t("manage.topicForm.topic24") }}
        </div>
        <div>
          <Pagination
            :background="true"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :total="totalItems"
            @size-change="updatePageSize"
            @current-change="updateCurrentPage"
          />
        </div>
      </div>
    </div>
    <!-- 归档列表 -->
    <div v-if="deleteType" class="hei100">
      <div class="menuBox">
        <div style="display: flex">
          <!-- 用户 -->
          <div class="User">
            <div class="searchDiv">
              <el-select v-model="status">
                <el-option
                  v-for="(item, index) in search"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                  <span class="dropText">{{ item.label }}</span>
                </el-option>
              </el-select>
              <el-input
                v-model="valueInput2"
                :placeholder="$t('manage.topicForm.topic8')"
              ></el-input>
            </div>
          </div>
          <!-- 起止时间 -->
          <div class="User">
            <div class="searchDiv">
              <div class="time">{{ t("manage.topicForm.topic9") }}</div>
              <el-date-picker
                v-model="timeValue2"
                type="daterange"
                :range-separator="$t('manage.topicForm.topic10')"
                :start-placeholder="$t('manage.topicForm.topic11')"
                :end-placeholder="$t('manage.topicForm.topic12')"
                clearable="false"
                @change="changeTimeValue2"
              />
            </div>
          </div>
          <!-- 重置 -->
          <div
            class="User1"
            style="border: #dcdfe6 1px solid"
            @click="clearRefresh2"
          >
            <el-icon class="icon">
              <Refresh />
            </el-icon>
          </div>
        </div>
        <!-- 功能区 -->
        <div style="display: flex">
          <el-button
            class="toolBox"
            :disabled="$hasPermission('10037') == false"
            @click="allRestore"
          >
            {{ t("manage.topicForm.topic25") }}
          </el-button>
          <el-button
            class="toolBox"
            @click="allDelete(2)"
            :disabled="$hasPermission('10038') == false"
          >
            {{ t("manage.topicForm.topic14") }}
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableMember2"
        @selection-change="handleSelectionChange2"
        class="tableHeight"
      >
        <el-table-column type="selection" />
        <el-table-column
          prop="userName"
          :label="t('manage.topicForm.topic15')"
        />
        <el-table-column
          prop="sessionName"
          :label="t('manage.topicForm.topic16')"
        >
          <template #default="scope">
            <el-tooltip popper-class="toolTip" :content="scope.row.sessionName">
              <div class="wid100">{{ scope.row.sessionName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="conversationNum"
          :label="t('manage.topicForm.topic17')"
          width="80"
        />
        <el-table-column prop="type" :label="t('manage.topicForm.topic18')" />
        <el-table-column
          prop="createTime"
          :label="t('manage.topicForm.topic19')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="getTime(scope.row.createTime)"
            >
              <div class="wid100">{{ getTime(scope.row.createTime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="t('manage.topicForm.topic20')"
        >
          <template #default="scope">
            <el-tooltip
              popper-class="toolTip"
              :content="getTime(scope.row.lastUpdateTime)"
            >
              <div class="wid100">{{ getTime(scope.row.lastUpdateTime) }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="t('manage.topicForm.topic21')"
          class="operation"
        >
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              @click="restoreBtn(scope.row)"
              :disabled="$hasPermission('10037') == false"
            >
              {{ t("manage.topicForm.topic26") }}
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              @click="deleteBtn(scope.row)"
              :disabled="$hasPermission('10038') == false"
            >
              {{ t("manage.topicForm.topic23") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="gobackDiv" @click="closeDelete">
          <img :src="icon.goBackImgLook" alt="" class="deleteImg" />
          {{ t("manage.topicForm.topic27") }}
        </div>
        <div>
          <Pagination
            :currentPage="currentPage2"
            :pageSize="pageSize2"
            :total="totalItems2"
            @size-change="updatePageSize2"
            @current-change="updateCurrentPage2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.menuBox {
  :deep(.el-input__wrapper) {
    border-radius: 0 16px 16px 0;
    border-right: none !important;
  }

  :deep(.el-input) {
    --el-input-height: 42px !important;
  }
}

.searchDiv {
  :deep(.el-select) {
    width: 80px;
  }

  :deep(.el-select__wrapper) {
    width: 80px;
    padding: 0 6px;
    height: 42px !important;
    border-radius: 16px 0 0 16px !important;
    background-color: #f7f8ff;
  }
}

:deep(.el-input) {
  --el-input-height: 42px !important;
}

:deep(.el-date-editor.el-input__wrapper) {
  height: 42px !important;
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

.main {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

.time {
  padding: 0 10px;
  background-color: #f7f8ff;
  border-radius: 16px 0 0 16px;
  height: 42px;
  color: #a8abb2;
  font-size: 14px;
  line-height: 42px;
  border: 1px solid #dcdfe6;
  border-right: none;
}

.footer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;

  .deleteDiv {
    height: 34px;
    border-radius: 12px;
    border: 1px solid #f7f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f02d63;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 10px;

    .deleteImg {
      width: 25px;
      margin-right: 3px;
    }
  }

  .gobackDiv {
    height: 34px;
    border-radius: 12px;
    border: 1px solid #f7f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8a8a8a;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 10px;

    .deleteImg {
      width: 25px;
      margin-right: 3px;
    }
  }
}

.menuBox {
  position: relative;
  display: flex;
  justify-content: space-between;

  .User {
    height: 42px;
    line-height: 42px;
    border-radius: 16px;
    color: #3d3d3d;
    font-size: 17px;
    margin-right: 10px;

    .searchDiv {
      display: flex;
      color: #3d3d3d;

      .searchSpan {
        color: #3d3d3d;
        font-size: 16px;
        display: flex;

        .text {
          min-width: 34px;
          white-space: nowrap;
        }
      }

      .searchIcon {
        margin-left: 3px;
      }
    }
  }

  .date {
    height: 42px;
    line-height: 42px;
    border: 2px solid #f7f8ff;
    border-radius: 16px;
    font-size: 18px;
    color: #8a8a8a;
    display: flex;
    font-size: 17px;
    text-align: center;
    padding: 0 0 0 10px;
    margin-right: 16px;

    div:first-child {
      margin-right: 20px;
      color: #8a8a8a;
    }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .searchDiv {
      margin-left: 10px;
      color: #3d3d3d;

      .searchSpan {
        color: #3d3d3d;
        font-size: 16px;
        width: 100%;
        display: flex;
        margin-top: 10px;
      }

      .searchIcon {
        margin-left: 10px;
      }
    }
  }
}

.User1 {
  height: 42px;
  border-radius: 16px;
  color: #3d3d3d;
  font-size: 17px;
  margin-right: 10px;
  padding: 0 10px;
  cursor: pointer;

  .icon {
    font-size: 22px;
    margin-top: 8px;
  }
}

.User1:hover {
  background: #f7f8ff;
}

.toolBox {
  border: #f7f8ff solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  height: 42px;
  line-height: 40px;
  color: #f02d63;
  margin-right: 10px;
  cursor: pointer;
}

.tableHeight {
  margin-top: 20px;
  height: calc(100% - 110px);
}
.hei100 {
  height: 100%;
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  padding-left: 10px;
}
</style>
