<template>
  <div class="baseContent">
    <!-- 搜索 -->
    <div class="flexCenter searchDiv">
      <el-input
        class="baseInput"
        :placeholder="t('base.base1')"
        v-model="listFomr.knowledgePattern"
        @input="searchInput"
        prefix-icon="Search"
      />
      <!-- 角色 -->
      <div class="roleDiv">
        <div class="roleTitle">{{ t("base.base2") }}</div>
        <el-dropdown @command="changeNav">
          <span class="dropText">
            {{ t(navList[listFomr.roles].name) }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in navList"
                :key="item.value"
                :command="item.value"
              >
                <span class="dropText">{{ t(item.name) }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <!-- 模式 -->
      <div class="roleDiv">
        <div class="roleTitle">{{ t("base.base350") }}</div>
        <el-dropdown @command="changeType">
          <span class="dropText">
            {{ t(baseModelList[listFomr.knowledge_type - 1].name) }}
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in baseModelList"
                :key="item.value"
                :command="item.value"
              >
                <span class="dropText">{{ t(item.name) }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <!-- 筛选 -->
      <div class="sortDiv">
        <el-dropdown @command="changeShort">
          <span class="el-dropdown-link">
            <img class="basePx" :src="icon.basePx" alt="" />
            <span class="font18">{{
              t(basePxList[listFomr.sort_option].name)
            }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in basePxList"
                :command="item.value"
                :key="item.value"
              >
                <span class="dropText">{{ t(item.name) }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <!-- 内容 -->
    <div class="flexDiv">
      <el-row
        :gutter="20"
        v-infinite-scroll="loadList"
        :infinite-scroll-distance="5"
        :infinite-scroll-immediate="false"
      >
        <!-- 新增 -->
        <el-col :span="6">
          <div class="itemWidthAdd" @click="openAdd">
            <div class="flexCenter itemTile">
              <div class="addDiv">
                <img :src="icon.baseAdd" alt="" class="addImg" />
              </div>
              <div class="baseItemTitle">{{ t("base.base3") }}</div>
            </div>
            <div class="addTip3">
              {{ t("base.base4") }}
            </div>
          </div>
        </el-col>
        <!-- 列表 -->
        <el-col :span="6" v-for="item in list" :key="item.knowledge_library_id">
          <div class="itemWidth" @click="goBaseDetails(item)">
            <div class="flexCenter itemTile">
              <div class="flexCenter titleNoMax">
                <div class="logoDiv">
                  <img :src="getBaseImg(item)" alt="" class="baseIconLogo" />
                  <img :src="getImgType(item)" alt="" class="imgType" />
                </div>
                <div class="nameDIv">
                  <div
                    :class="
                      lang == 'US' || item.resourcetype == 1
                        ? 'flexStart'
                        : 'flexCenter'
                    "
                  >
                    <el-tooltip popper-class="toolTip" :content="item.name">
                      <div
                        :class="
                          lang == 'US' || item.resourcetype == 1
                            ? 'forBaseItemTitleUs'
                            : 'forBaseItemTitleCn'
                        "
                      >
                        {{ item.name }}
                      </div>
                    </el-tooltip>
                    <div
                      :class="
                        lang == 'US' || item.resourcetype == 1
                          ? 'modeDiv'
                          : 'modeDiv marginLeft7'
                      "
                    >
                      {{
                        item.knowledge_type != 3
                          ? t("base.base351")
                          : t("base.base360")
                      }}
                      {{ item.resourcetype == 1 ? " · SharePoint" : "" }}
                    </div>
                  </div>
                  <div class="nameTip">
                    {{ item.documentNum }}
                    {{ t("base.base352") }}
                    {{ item.conversationNum }}
                    {{ t("base.base353") }}
                    {{ item.userNum }}
                    {{ t("base.base354") }}
                  </div>
                </div>
              </div>
              <div
                :class="item.isPin == 0 ? 'pinDiv' : 'pinYesDiv'"
                @click.stop="changeMore(item.isPin == 0 ? 1 : 2, item)"
              >
                <img :src="getPin(item)" alt="" class="pinImg" />
              </div>
            </div>
            <div class="addTip">
              {{ item.desc }}
            </div>
            <div class="bottomFlexBetween">
              <div class="flexCenter wid46">
                <div
                  class="baseTx"
                  :style="'background-color:' + item.owner.color"
                >
                  {{
                    item.owner.userName ? item.owner.userName.slice(0, 1) : ""
                  }}
                </div>
                <el-tooltip
                  popper-class="toolTip"
                  :content="item.owner.userName"
                >
                  <div class="baseName">
                    {{ item.owner.userName ? item.owner.userName : "" }}
                  </div>
                </el-tooltip>
              </div>
              <el-dropdown @command="changeMore($event, item)" trigger="click">
                <div class="moreDiv" @click.stop="">
                  <img :src="icon.baseMore" alt="" class="moreIcon" />
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :command="5">
                      <span class="colorBlue">{{ t("login.login46") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item :command="1" v-if="item.isPin != 1">
                      <span class="colorBlue">{{ t("base.base6") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item :command="2" v-if="item.isPin === 1">
                      <span class="colorBlue">{{ t("base.base7") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="3"
                      v-if="item.knowledge_role_id == 0"
                    >
                      <span class="colorRed">{{ t("base.base8") }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item
                      :command="4"
                      v-if="item.knowledge_role_id != 0"
                    >
                      <span class="colorRed">{{ t("base.base9") }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="flexDiv1" v-if="listLoading" v-loading="listLoading"></div>
    <!-- 新增 -->
    <addView
      v-if="addDialog"
      :addLoading="addLoading"
      @closeAdd="closeAdd"
      @saveAdd="saveAdd"
    ></addView>
    <!-- 详情 -->
    <indexD
      v-if="detailType"
      :editObj="editObj"
      :addLoading="addLoading"
      @closeDetail="detailType = false"
      @saveEdit="saveEdit"
    ></indexD>
    <dialogView
      v-if="tipDialog"
      @closeTipDialog="closeTipDialog"
      @getList="getList"
    ></dialogView>
    <!-- sp授权 -->
    <spView v-if="spDialog" @closeSp="closeSp" :type="2"></spView>
  </div>
</template>

<script setup>
import spView from "./spView.vue";
import addView from "./add.vue";
import dialogView from "./dialog.vue";
import indexD from "./indexD.vue";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { icon } from "@/utils/icon";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
onMounted(() => {
  getList();
});
const navList = ref([
  {
    name: "base.base10",
    value: 0,
  },
  {
    name: "base.base38",
    value: 1,
  },
  {
    name: "base.base40",
    value: 2,
  },
  {
    name: "base.base41",
    value: 3,
  },
]);
const baseModelList = ref([
  {
    name: "base.base10",
    value: 1,
  },
  {
    name: "base.base351",
    value: 2,
  },
  {
    name: "base.base355",
    value: 3,
  },
]);
const basePxList = ref([
  {
    name: "base.base356",
    value: 0,
  },
  {
    name: "base.base357",
    value: 1,
  },
  {
    name: "base.base358",
    value: 2,
  },
  {
    name: "base.base359",
    value: 3,
  },
]);
const userId = ref(store.state.userId);
const addLoading = ref(false);
const editObj = ref({}); //编辑接口
const listLoading = ref(false);
const detailType = ref(false);
// 列表参数
const listFomr = ref({
  userId: store.state.userId,
  knowledgePattern: "",
  pageId: 1,
  perPage: 15,
  roles: 0,
  knowledge_type: 1,
  sort_option: 0,
});
const tipDialog = ref(false);
const lang = ref(store.state.lang);
watch(
  () => store.state.lang,
  (newValue, oldValue) => {
    lang.value = store.state.lang;
  }
);
// 根据类型返回icon
const getImgType = (item) => {
  if (item.knowledge_type == 3) {
    return icon.excelType;
  } else {
    return icon.wordType;
  }
};
// 返回知识库icon
const getBaseImg = (item) => {
  if (item.image_path == null || !item.image_path) {
    if (item.resourcetype == 1) {
      return icon.baseSpLogo;
    } else {
      return icon.baseLogo;
    }
  } else {
    return "/openai/chat/v4/image/get/" + item.image_path;
  }
};
// 判断是否置顶
const getPin = (item) => {
  if (item.isPin == 0) {
    return icon.basePin;
  } else {
    return icon.basePinYes;
  }
};
// 选择角色
const changeNav = (e) => {
  listFomr.value.roles = e;
  listFomr.value.pageId = 1;
  getList();
};
// 选择模式
const changeType = (e) => {
  listFomr.value.knowledge_type = e;
  listFomr.value.pageId = 1;
  getList();
};
// 选择排序
const changeShort = (e) => {
  listFomr.value.sort_option = e;
  listFomr.value.pageId = 1;
  getList();
};
// 关闭提示弹窗
const closeTipDialog = () => {
  tipDialog.value = false;
  getList();
};
const list = ref([]);
const searchInput = () => {
  listFomr.value.pageId = 1;
  getList();
};
const loadList = () => {
  listFomr.value.pageId++;
  getList();
};
// 获取微模型列表
const getList = () => {
  tipDialog.value = false;
  let obj = JSON.parse(JSON.stringify(listFomr.value));
  if (obj.roles == 0) {
    obj.roles = null;
  } else {
    obj.roles = obj.roles - 1;
  }
  if (obj.knowledge_type == 1) {
    obj.knowledge_type = null;
  }
  listLoading.value = true;
  api.base
    .customLibraryList(obj)
    .then((res) => {
      listLoading.value = false;
      if (res.returnCode == 200) {
        if (listFomr.value.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      listLoading.value = false;
    });
};
// 更多操作
const changeMore = (key, item) => {
  if (key == 1) {
    // 置顶
    api.base
      .basePin({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base14"));
          searchInput();
        }
      });
  } else if (key == 2) {
    // 取消置顶
    api.base
      .basePinCancel({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base15"));
          searchInput();
        }
      });
  } else if (key == 3) {
    // 删除微模型
    api.base
      .libraryDelete(
        {
          userId: userId.value,
        },
        item.id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base247"));
          getList();
        }
      });
  } else if (key == 4) {
    // 退出微模型
    api.base
      .libraryExit(
        {
          userId: userId.value,
        },
        item.id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base17"));
          getList();
        }
      });
  } else if (key == 5) {
    // 编辑
    api.base
      .customLibraryDesc({
        userId: userId.value,
        libraryId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          res.data.segment.deleteType =
            res.data.segment.deleteType == 0 ? false : true;
          res.data.segment.replaceType =
            res.data.segment.replaceType == 0 ? false : true;
          res.data.segment.maximumDescriptionLength = Number(
            res.data.segment.maximumDescriptionLength
          );
          editObj.value = res.data;
          detailType.value = true;
        }
      });
  }
};
// 双击进入详情
const goBaseDetails = (item) => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: item.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.seleteUserInfo = res.data.userInfo.filter(
          (item) => item.ownerType == 0 && item.ownerName
        );
        store.commit("SET_BASEITEM", res.data);
        store.commit("SET_CHATTYPE", "base");
        const checkEmbeddingType = (embeddingOn, embeddingType) => {
          const embedding = res.data.current_user_embedding_type.filter(
            (item) => [embeddingType].includes(item)
          );
          return embeddingOn === 1 && embedding.length !== 1;
        };
        const shouldShowTipDialog =
          checkEmbeddingType(res.data.aliEmbeddingOn, "aliEmbedding") ||
          checkEmbeddingType(res.data.azureEmbeddingOn, "azureEmbedding") ||
          checkEmbeddingType(res.data.baiduEmbeddingOn, "baiduEmbedding") ||
          checkEmbeddingType(res.data.localEmbeddingOn, "localEmbedding");
        // 普通成员直接进
        if (res.data.knowledge_role_id == 2) {
          store.commit("SET_BASEDETAILSROUTE", {
            navActive: 0,
            topicActive: 0,
          });
          router.push("/base/details");
          return;
        }
        // 创作者 个人 弹窗
        if (shouldShowTipDialog && res.data.knowledge_role_id == 1) {
          // 判断个人还是部门
          let type = res.data.creator_info.some((item) => item.ownerType == 0);
          if (type) {
            tipDialog.value = true;
            return;
          } else {
            message.warning(t("login.login63"));
            return;
          }
        }
        // 拥有者
        if (shouldShowTipDialog && res.data.knowledge_role_id == 0) {
          tipDialog.value = true;
          return;
        }
        if (!shouldShowTipDialog) {
          router.push("/base/details");
          store.commit("SET_BASEDETAILSROUTE", {
            navActive: 0,
            topicActive: 0,
          });
          return;
        }
      }
    });
};
const addDialog = ref(false);
// 关闭新增
const closeAdd = () => {
  addDialog.value = false;
};
// 关闭授权
const closeSp = () => {
  spDialog.value = false;
};
// 保存新增
const spDialog = ref(false);
const saveAdd = (form) => {
  addLoading.value = true;
  api.base
    .customLibraryAdd(form)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base18"));
        getList();
        addDialog.value = false;
      } else if (res.returnCode == 499) {
        spDialog.value = true;
      }
    })
    .catch((err) => {
      addLoading.value = false;
    });
};
// 编辑
const saveEdit = (form) => {
  addLoading.value = true;
  let newForm = {
    userId: form.userId,
    libraryName: form.name,
    desc: form.desc,
    promptId: form.promptId,
    libraryId: editObj.value.knowledge_library_id,
    imageName: form.imageName,
    resourcePlatform: form.resourcePlatform,
    site: form.site,
    ...form,
  };
  api.base
    .baseUpdata(newForm)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base307"));
        listFomr.value.pageId = 1;
        getList();
        detailType.value = false;
      } else if (returnCode == 499) {
        spDialog.value = true;
      }
    })
    .catch((err) => {
      addLoading.value = false;
    });
};
const openAdd = () => {
  addDialog.value = true;
};
</script>

<style lang="less" scoped>
.baseContent {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: relative;
}
.roleDiv {
  border-radius: 12px;
  display: flex;
  align-items: center;
  height: 28px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-left: 12px;
  background-color: #f6f6f6;
  :deep(.el-dropdown) {
    max-width: calc(100% - 45px);
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 400;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
  }
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-weight: 400;
  width: 45px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.baseInput {
  width: 236px;
  height: 52px;
  height: 52px;
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  :deep(.el-input__wrapper) {
    height: 46px;
    border-radius: 12px !important;
    background-color: #f6f6f6;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
  }
}
.baseItemTitle {
  font-size: 20px;
  color: #0256ff;
  font-weight: 700;
}
.forBaseItemTitleUs {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 700;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.forBaseItemTitleCn {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 700;
  max-width: calc(100% - 75px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.addDiv {
  background-color: #dbdfff;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.logoDiv {
  border-radius: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
}
.addImg {
  width: 15px;
  height: 15px;
}
.baseIconLogo {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.searchDiv {
  margin-bottom: 22px;
  position: relative;
}
.itemWidth {
  border-radius: 16px;
  background-color: #f7f8ff;
  margin-bottom: 20px;
  padding: 24px;
  cursor: pointer;
  height: 162px;
  box-shadow: 0px 2px 8px 0px rgba(204, 206, 229, 0.5);
  border: 1px solid #dbdfff;
}
.itemWidthAdd {
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 24px;
  cursor: pointer;
  height: 160px;
  border: 1px solid #d9d9d9;
}
.itemWidth:hover {
  background-color: #f1f2ff;
  box-shadow: 0px 8px 16px 0px #cccee5;
  border: 1px solid #a3acec;
  .pinDiv {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #fefefe;
  }
  .pinYesDiv {
    border-radius: 8px;
  }
  .moreDiv {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.pinDiv {
  display: none;
}
.pinYesDiv {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.moreDiv {
  display: none;
}
.itemMargin {
  margin-left: 25px;
  margin-right: 25px;
}
.addTip {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  /* 限制行数 */
  -webkit-box-orient: vertical;
  word-break: break-all; /* 强制换行 */
  margin-bottom: 16px;
}
.addTip3 {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  height: 75px;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 18px;
  word-break: break-all; /* 强制换行 */
}
.flexDiv {
  height: calc(100% - 84px);
  padding: 5px 0px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  :deep(.el-row) {
    margin: 0px !important;
    width: 100% !important;
  }
}
.flexDiv1 {
  position: absolute !important;
  width: calc(100% - 60px);
  bottom: 30px;
  height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.itemTile {
  height: 50px;
  margin-bottom: 16px;
  position: relative;
}
.bottomFlexBetween {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pinImg {
  width: 16px;
  height: 16px;
}
.titleNoMax {
  width: calc(100% - 31px);
}
.moreIcon {
  width: 18px;
}
.px {
  width: 13px;
}
.colorBlue {
  color: #0256ff;
  font-size: 16px;
  font-weight: 400;
}
.colorRed {
  color: #f02d63;
  font-size: 16px;
  font-weight: 400;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  padding-left: 10px;
}
.modeDiv {
  background-color: #edeef6;
  border-radius: 4px;
  padding: 1px 7px;
  font-size: 13px;
  font-weight: 500;
  color: #8a8a8a;
}
.marginLeft7 {
  margin-left: 7px;
}
.nameTip {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
}
.nameDIv {
  width: calc(100% - 58px);
}
.baseTx {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #0256ff;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
  margin-right: 6px;
}
.baseName {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
  max-width: calc(100% - 30px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid46 {
  width: calc(100% - 46px);
}
.imgType {
  position: absolute;
  right: -7px;
  bottom: -7px;
  width: 24px;
}
.basePx {
  width: 16px;
  margin-right: 6px;
}
.sortDiv {
  background-color: #f6f6f6;
  border-radius: 8px;
  margin-left: 12px;
  font-size: 18px;
  color: #989898;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  :deep(.el-dropdown-link) {
    display: flex;
    align-items: center;
  }
}
.font18 {
  font-size: 18px;
}
.flexStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
