<template>
  <div class="baseInner">
    <!-- tab切换 -->
    <div class="tabDiv">
      <!-- tab切换 -->
      <div class="flexCenter">
        <div
          v-for="(item, index) in navList"
          :key="index"
          :class="{
            navItem: navActive != index,
            navItemActive: navActive == index,
          }"
          @click="changeNav(index)"
        >
          {{ t(item) }}
        </div>
      </div>
      <div class="flexCenter">
        <!-- 搜索 -->
        <div class="topicSearch" v-if="navActive == 1">
          <el-input
            :placeholder="
              t('base.base54') + baseItem.knowledge_name + t('base.base55')
            "
            v-model="sessionNamePattern"
            prefix-icon="Search"
            class="topicInput"
            @input="searchList"
          />
          <div class="roleDiv">
            <div class="roleTitle">{{ t("base.base71") }}</div>
            <el-dropdown @command="changeTime">
              <span class="dropText">
                {{ t(timeList[timeValue].label) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in timeList"
                    :key="item.value"
                    :command="item.value"
                  >
                    <span class="dropText">{{ t(item.label) }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="roleDiv">
            <div class="roleTitle">{{ t("base.base73") }}</div>
            <el-dropdown @command="searchTopicList">
              <span class="dropText">
                {{
                  sessionUserNamePattern == 0
                    ? t("base.base10")
                    : sessionUserNamePattern
                }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu class="maxMenu">
                  <el-dropdown-item :command="0">
                    <span class="dropText">{{ t("base.base10") }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-for="(item, index) in baseItem.seleteUserInfo"
                    :key="index"
                    :command="item.ownerName"
                  >
                    <span class="dropText">{{ item.ownerName }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <!-- 发布 -->
        <publishView
          @openDetail="openDetail"
          type="base"
          :strategyType="baseItem.knowledge_type"
        ></publishView>
      </div>
    </div>
    <div class="flex">
      <!-- 左侧 -->
      <div :class="navActive == 0 ? 'leftInner' : 'leftInner1'">
        <div ref="leftDiv" class="leftDiv">
          <!-- tab切换 -->
          <div ref="topNav" style="position: relative">
            <!-- 搜索 -->
            <el-input
              class="searchInput"
              prefix-icon="Search"
              v-if="navActive == 0"
              v-model="searchFileName"
              @input="searchInput"
              :placeholder="
                t('base.base54') + baseItem.knowledge_name + t('base.base55')
              "
            ></el-input>
            <!-- 上传 新增文件夹 历史 -->
            <div
              class="upDiv"
              v-if="navActive == 0 && baseItem.knowledge_role_id != 2"
            >
              <!-- 上传 新增 -->
              <template v-if="baseItem.resourcetype == 0">
                <el-dropdown @command="upFile">
                  <div class="upFileBtn">
                    <img :src="icon.upFiles" alt="" class="upFiles" />
                    <div>{{ t("base.base56") }}</div>
                    <img :src="icon.fileIcon" alt="" class="wid11" />
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <uploadIndex
                        :type="baseItem.knowledge_type == 3 ? 'excel' : 'base'"
                        @beforeAvatarUpload="beforeAvatarUpload"
                      >
                        <el-dropdown-item :command="1">
                          <span class="dropText">{{ t("base.base57") }}</span>
                        </el-dropdown-item>
                      </uploadIndex>
                      <upFloderIndex
                        :type="baseItem.knowledge_type == 3 ? 'excel' : 'base'"
                        @upFloder="upFloder"
                      >
                        <el-dropdown-item :command="2">
                          <span class="dropText">{{ t("base.base58") }}</span>
                        </el-dropdown-item>
                      </upFloderIndex>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <div class="addFiles" @click="openCreateFloader">
                  <img :src="icon.addFiles" alt="" class="width30" />
                  <div>{{ t("base.base59") }}</div>
                </div>
              </template>
              <!-- 开始同步 -->
              <el-tooltip
                v-if="
                  baseItem.resourcetype == 1 &&
                  !nowSyncType &&
                  baseItem.knowledge_role_id == 0
                "
                popper-class="toolTip"
                :content="t('base.base339')"
              >
                <el-button class="tbDiv" @click="goSync" :loading="syncLoading">
                  <img :src="icon.baseTb" alt="" class="baseTb" />
                  <div class="syncText">{{ t("base.base339") }}</div>
                </el-button>
              </el-tooltip>
              <!-- 同步中 -->
              <el-tooltip
                popper-class="toolTip"
                :content="t('base.base341')"
                v-if="
                  baseItem.resourcetype == 1 &&
                  nowSyncType &&
                  baseItem.knowledge_role_id == 0
                "
              >
                <div class="nowSyncTypeDiv">
                  <el-icon class="is-loading marginRight6">
                    <Loading />
                  </el-icon>
                  <div class="syncLoadingText">{{ t("base.base341") }}</div>
                  <div class="syncProgress">
                    <el-progress :percentage="syncProgress" color="#11D7B2" />
                  </div>
                </div>
              </el-tooltip>
              <!-- 历史记录打开 -->
              <div class="addFiles" @click="openHistory">
                <img :src="icon.upLog" alt="" class="width30" />
                <div>{{ t("base.base60") }}</div>
              </div>
            </div>
            <!-- 根目录 -->
            <div
              class="fileUpDiv"
              :draggable="true"
              v-if="fileUpType"
              @dragover.prevent="dragover"
              @drop="dropTop"
            >
              <div class="fileUpInnerDiv">
                <img :src="icon.fileUpSvg" class="fileUpSvg" alt="" />
                <span>{{ t("base.base322") }}</span>
              </div>
            </div>
          </div>
          <div
            :style="'height:' + fileListHeight + 'px'"
            class="fileListDiv"
            v-if="navActive == 0 && fileList.length != 0"
          >
            <!-- 文件列表 -->
            <div
              :style="'height:' + fileListHeight + 'px'"
              class="fileListDiv"
              v-loading="leftFileLoading"
              @click="rightType = false"
              @contextmenu.prevent.stop="openRight($event, {})"
            >
              <el-tree
                ref="treeRef"
                class="VueDraggable"
                :data="fileList"
                node-key="id"
                draggable
                :highlight-current="true"
                :filter-node-method="treeFilter"
                @keydown.prevent="copy($event)"
                @node-click="handleNodeClick"
                @node-drag-start="dragStart"
                @node-drop="dropEnd"
                @node-contextmenu="openRight"
                @node-drag-end="nodeDragEnd"
              >
                <template #default="{ data }">
                  <treeFileView
                    :data="data"
                    :baseItem="baseItem"
                    @changeFileMore="changeFileMore"
                  >
                  </treeFileView>
                </template>
              </el-tree>
            </div>
          </div>
          <!-- 无数据 -->
          <div
            :style="'height:' + fileListHeight + 'px'"
            class="fileListDiv nodataCenter"
            v-if="navActive == 0 && fileList.length == 0"
          >
            <el-empty />
          </div>
          <!-- 话题广场列表 -->
          <div v-if="navActive == 1" :style="'height:' + fileListHeight + 'px'">
            <div
              v-for="(item, index) in topicList"
              :key="index"
              :class="{
                topMenuActive: topicActive == index,
              }"
              class="topMenu"
              @click="changeTopic(index)"
            >
              <img :src="item.url" alt="" class="wid24 marginRight13" />
              <div>{{ t(item.name) }}</div>
            </div>
          </div>
          <!-- 针对微模型提问 -->
          <div ref="topBtn">
            <bottomFileView
              :bottomFileList="bottomFileList"
              @dragover="dragover"
              @bottomDrop="bottomDrop"
              @deleteBottomFile="deleteBottomFile"
              @goChat="goChat"
            >
            </bottomFileView>
          </div>
        </div>
      </div>
      <!-- 话题广场 -->
      <div class="rightTopic" v-if="navActive == 1">
        <!-- 话题 -->
        <div class="baseItemDiv" v-loading="topicLoading">
          <!-- 换指定的数据即可 -->
          <div
            class="VueDraggable overAuto"
            @scroll="scrollLoad"
            v-if="rightList.length != 0"
          >
            <lookView
              :type="'base'"
              :list="rightList"
              :deleteType="false"
              :like="topicActive == 2 ? true : false"
              :draggable="baseItem.knowledge_type == 3 ? false : true"
              @goLookView="goLookView"
              @reshList="reshList"
              @topicDragStart="topicDragStart"
            ></lookView>
          </div>
          <div class="noDataDiv" v-if="rightList.length == 0">
            <img :src="icon.noData" class="noData" alt="" />
          </div>
        </div>
      </div>
      <!-- 预览文件 -->
      <fileView
        ref="fileViewRef"
        class="rightInner"
        :fileItem="fileItem"
        :chunkResource="[]"
        :chunkDialog="chunkDialog"
        :qaType="false"
        :isValed="true"
        type="base"
        @closeFileView="closeFileView"
        @downFile="downFile"
        @deleteFile="deleteFile"
        @getFileList="getFileList"
        v-if="
          navActive == 0 && fileType == 'file' && baseItem.knowledge_type == 2
        "
      >
      </fileView>
      <!-- 无文件展示 -->
      <div class="noDataFile" v-if="navActive == 0 && fileType == 'floder'">
        <img :src="icon.noFileImg" alt="" class="noFileImg" />
      </div>
      <!-- excel模式预览 -->
      <excelView
        :type="0"
        class="rightInner"
        @closeExcel="closeFileView"
        @getFileList="getFileList"
        :fileItem="fileItem"
        v-if="
          navActive == 0 && baseItem.knowledge_type == 3 && fileType == 'file'
        "
      ></excelView>
    </div>
    <!-- 新建文件夹 -->
    <addFolderView
      v-if="floderDialog"
      :folderLoading="folderLoading"
      @closeAddFolder="closeAddFolder"
      @saveFloader="saveFloader"
    >
    </addFolderView>
    <!-- 上传文件 -->
    <upFileDivView
      v-if="upFileDialog"
      :upTypeActive="upTypeActive"
      :upFileList="upFileList"
      :upFloderName="upFloderName"
      :errorFileList="errorFileList"
      @saveDialog="saveDialog"
      @closeUpDialog="closeUpDialog"
    ></upFileDivView>
    <!-- 历史记录 -->
    <upFileHistoryView
      v-if="historyDialog"
      :historyTableData="historyTableData"
      :historyLoading="historyLoading"
      :baseItem="baseItem"
      @closeHistory="closeHistory"
      @clearItemHistory="clearItemHistory"
    ></upFileHistoryView>
    <!-- 上传进度条 -->
    <processView
      v-if="processDialog"
      :cancelType="cancelType"
      :processList="processList"
      @postFile="postFile"
      @closeProcess="closeProcess"
    ></processView>
    <!-- 过期 -->
    <timeView
      v-if="timeDialog"
      :loading="timeLoading"
      @closeTime="closeTime"
      @saveTime="saveTime"
    ></timeView>
    <!-- 针对话题弹窗 -->
    <newChat
      v-if="chatDialog"
      type="base"
      @closeChat="closeChat"
      :bottomFileList="bottomFileList"
    ></newChat>
    <!-- 右键菜单 -->
    <div
      v-if="rightType"
      class="menuDiv"
      :style="'left:' + leftPos + 'px;top:' + topPos + 'px'"
    >
      <div
        class="menuItem"
        @click="copyClose"
        v-if="copyType && Object.keys(moveItem).length == 0"
      >
        {{ t("base.base303") }}
      </div>
      <div
        class="menuItem"
        @click="rightCopy"
        v-if="Object.keys(moveItem).length != 0"
      >
        {{ t("base.base304") }}
      </div>
    </div>
    <!-- 详情 -->
    <indexD
      v-if="detailType"
      :editObj="editObj"
      :addLoading="addLoading"
      @closeDetail="detailType = false"
      @saveEdit="saveEdit"
    ></indexD>
    <spView v-if="spDialog" @closeSp="closeSp" :type="2"></spView>
  </div>
</template>

<script setup>
import uploadIndex from "@/components/upload/index.vue";
import upFloderIndex from "@/components/upFloder/index.vue";
import fileView from "@/components/fileView/index.vue";
import lookView from "@/components/look/index.vue";
import excelView from "@/components/fileView/excelIndex.vue";
import api from "@/api/index";
import indexD from "./indexD.vue";
import spView from "./spView.vue";
import addFolderView from "./addFolder.vue";
import upFileDivView from "./upFileDiv.vue";
import upFileHistoryView from "./upFileHistory.vue";
import processView from "./process.vue";
import treeFileView from "./treeFile.vue";
import bottomFileView from "./bottomFile.vue";
import { ref, onMounted, nextTick, onUnmounted } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import timeView from "./time.vue";
import { icon } from "@/utils/icon";
import newChat from "@/components/newChat/index.vue";
import publishView from "@/components/publish/index.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const baseItem = ref(store.state.baseItem);
const fileList = ref([]);
const fileItem = ref({});
const navList = ref(["base.base295", "base.base296"]);
const topicActive = ref(0); //话题广场选择
const rootId = ref(null); //root文件夹目录
const sessionNamePattern = ref("");
const topicList = ref([
  {
    name: "base.base98",
    url: icon.topic1,
  },
  {
    name: "base.base99",
    url: icon.topic2base,
  },
  {
    name: "base.base100",
    url: icon.topic3,
  },
  {
    name: "base.base101",
    url: icon.topic5,
  },
]);
const navActive = ref(0); //nav菜单选项
const userId = ref(store.state.userId); //userId
const chunkDialog = ref(false); //文件预览是否展示
const floderDialog = ref(false); //新建文件夹
const fileActive = ref(null); //选择的那个文件
const topNav = ref(null); //ref计算使用
const topBtn = ref(null); //ref计算使用
const leftDiv = ref(null);
const fileListHeight = ref(null);
const bottomFileList = ref([]); //底部拖动
const upFileList = ref([]); //要上传的文件列表
const upFileDialog = ref(false); //上传框状态
const upTypeActive = ref(null); //判断上传文件还是文件夹
const errorFileList = ref([]); //不符合条件的文件
const upFloderName = ref(""); //上传文件夹的名称
const timmer = ref(null); //轮询使用
const historyDialog = ref(false); //历史上传dialog
const historyTableData = ref([]); //历史数据
const historyLoading = ref(false); //历史记录loading
const processDialog = ref(false); //上传进度
const searchLoading = ref(false); //搜索loading
const searchFileList = ref([]); //搜索数组
const leftFileLoading = ref(false); //左侧列表loading
const folderLoading = ref(false); //创建文件夹loading
const processList = ref([]); //进度
const pauseType = ref(false); //暂停
const cancelType = ref(false); //取消
const timeDialog = ref(false); //过期弹窗
const timeLoading = ref(false); //过期loading
const timeObj = ref({});
const rightList = ref([]);
const topicLoading = ref(false);
const editObj = ref({}); //编辑信息存储
const addLoading = ref(false); //编辑保存
const chatDialog = ref(false); //针对微模型对话
const moveItem = ref({});
const moveType = ref(false);
const rightType = ref(false); //右键弹出展示
const leftPos = ref(); //位置
const topPos = ref(); //位置
const copyItem = ref({}); //右键内容
const copyType = ref(false); //是否展示复制
const topicPageId = ref(1);
const fileViewRef = ref();
const sessionUserNamePattern = ref("");
const startDate = ref("");
const endDate = ref("");
const timeValue = ref(0);
const detailType = ref(false);
const searchFileName = ref("");
const treeRef = ref(null);
const fileUpType = ref(false); //是否展示移动最上方
const topType = ref(false);
const timeList = ref([
  {
    label: "base.base10",
    value: 0,
  },
  {
    label: "base.base172",
    value: 1,
  },
  {
    label: "base.base173",
    value: 2,
  },
  {
    label: "base.base174",
    value: 3,
  },
  {
    label: "base.base175",
    value: 4,
  },
]);
const baseDetailsRoute = ref(store.state.baseDetailsRoute);
onMounted(() => {
  store.commit("SET_CHATTYPE", "base");
  if (baseDetailsRoute.value.navActive == 1) {
    // changeNav(baseDetailsRoute.value.navActive);
    topicPageId.value = 1;
    navActive.value = baseDetailsRoute.value.navActive;
    startDate.value = "";
    endDate.value = "";
    chunkDialog.value = false;
    sessionNamePattern.value = "";
    rightList.value = [];
    changeTopic(baseDetailsRoute.value.topicActive);
  } else {
    changeNav(baseDetailsRoute.value.navActive);
  }
  if (baseItem.value.resourcetype == 1) {
    pollingSyncFn();
    getSyncProcess();
  } else {
    pollingFn();
    task();
  }
  window.addEventListener("resize", () => {
    getFileHeight();
  });
  getFileHeight();
});
// 离开结束所有任务
onBeforeRouteLeave((to, from, next) => {
  clearInterval(syncTime.value);
  syncTime.value = null;
  clearInterval(timmer.value);
  timmer.value = null;
  // 解绑resize
  window.removeEventListener("resize", () => {});
  next();
});
onUnmounted(() => {
  clearInterval(syncTime.value);
  syncTime.value = null;
  clearInterval(timmer.value);
  timmer.value = null;
  // 解绑resize
  window.removeEventListener("resize", () => {});
});
// 同步
const syncLoading = ref(false);
// 开始同步
const goSync = () => {
  syncLoading.value = true;
  api.base
    .asyncBase({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      syncLoading.value = false;
      if (res.returnCode == 200) {
        fileActive.value = null;
        fileType.value = "floder";
        fileItem.value = {};
        chunkDialog.value = false;
        getSyncProcess();
      }
    })
    .catch((err) => {
      syncLoading.value = false;
    });
};
// 获取进度
const nowSyncType = ref(false);
const syncProgress = ref(10);
const syncTime = ref(null);
const spDialog = ref(false);
const getSyncProcess = () => {
  api.base
    .getProcess({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        getFileList();
        if (res.data.progress != null) {
          nowSyncType.value = true;
          syncProgress.value = res.data.progress;
          pollingSyncFn();
        } else {
          nowSyncType.value = false;
          clearInterval(syncTime.value);
          syncTime.value = null;
        }
      }
    })
    .catch((err) => {
      clearInterval(syncTime.value);
      syncTime.value = null;
    });
};
// 轮询
const pollingSyncFn = () => {
  clearInterval(syncTime.value);
  syncTime.value = null;
  if (window.location.pathname == "/base/details") {
    syncTime.value = setInterval(() => {
      setTimeout(() => {
        getSyncProcess();
      }, 0);
    }, 3000);
  }
};
// 关闭sp
const closeSp = () => {
  spDialog.value = false;
};
// ctrl x
const keyItem = ref({});
const copy = (event) => {
  // 剪切
  if (event.keyCode == 88 && (event.ctrlKey || event.metaKey)) {
    message.success(t("base.base312"));
    keyItem.value = JSON.parse(JSON.stringify(fileItem.value));
  }
  // 粘贴
  if (event.keyCode == 86 && (event.ctrlKey || event.metaKey)) {
    let type = keyItem.value.folderName ? true : false;
    moveListPublic(
      [keyItem.value.id],
      fileActive.value ? fileActive.value : rootId.value,
      true,
      type
    );
  }
};
// 筛选tree
const treeFilter = (value, data) => {
  if (!value) return true;
  if (data.fileName) {
    return data.fileName.includes(value);
  } else {
    return data.folderName.includes(value);
  }
};
const searchInput = () => {
  treeRef.value.filter(searchFileName.value);
};
// 拖动开始
const dragStart = (item, event) => {
  fileUpType.value = true;
  event.dataTransfer.setData("text/plain", JSON.stringify(item.data));
};
// 移动未完成
const nodeDragEnd = () => {
  fileUpType.value = false;
};
// 移动到最顶层
const dropTop = (event) => {
  topType.value = true;
  const itemData = event.dataTransfer.getData("text/plain");
  const item = JSON.parse(itemData || "{}");
  let type = item.folderName ? true : false;
  moveListPublic([item.id], rootId.value, false, type);
};
// 拖动结束进行粘贴
const dropEnd = (a, b, c, d) => {
  if (topType.value) return;
  let type = a.data.folderName ? true : false;
  if (b.data.fileName) {
    moveListPublic([a.data.id], b.data.folder, false, type);
  } else {
    moveListPublic([a.data.id], b.data.id, false, type);
  }
};
const topicDragStart = (event, item) => {
  event.dataTransfer.setData("text/plain", JSON.stringify(item));
};
const moveListPublic = (ids, id, type, folderType) => {
  if (ids[0] == id) {
    message.warning(t("base.base365"));
    return;
  }
  if (ids[0] != undefined) {
    api.base
      .companyFileMove(
        {
          userId: userId.value,
          fileIds: !folderType ? ids : null,
          folderId: id ? id : rootId.value,
          childfolderId: folderType ? ids : null,
        },
        baseItem.value.knowledge_library_id
      )
      .then((res) => {
        getFileList();
        if (res.returnCode == 200) {
          fileUpType.value = false;
          topType.value = false;
          moveType.value = false;
          moveType.value = false;
          rightType.value = false;
          copyItem.value = {};
          moveItem.value = {};
          keyItem.value = {};
          fileActive.value = null;
          if (type) {
            message.success(t("base.base102"));
          }
        }
      });
  }
};
const openRight = (event, item) => {
  leftPos.value = event.pageX;
  topPos.value = event.pageY;
  rightType.value = true;
  if (Object.keys(item).length != 0) {
    copyType.value = true;
    copyItem.value = item;
    fileActive.value = item.id;
  } else {
    copyType.value = false;
    // 只展示粘贴
  }
};
// 剪切
const copyClose = () => {
  copyType.value = false;
  moveItem.value = copyItem.value;
  rightType.value = false;
};
// 粘贴
const rightCopy = () => {
  let type = moveItem.value.folderName ? true : false;
  moveListPublic(
    [moveItem.value.id],
    fileActive.value ? fileActive.value : rootId.value,
    true,
    type
  );
};
// 去对话历史记录
const goLookView = (item) => {
  api.chat
    .chatDescQuery({
      userId: userId.value,
      sessionId: item.sessionId,
      sessionType: item.sessionType,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        store.commit("SET_CHATTYPE", "base");
        store.commit("SET_TOPIC", res.data);
        if (res.data.isValid == 1) {
          router.push("/chat");
          store.commit("SET_CHATPARMAS", {
            type: res.data.isOwner == 1 ? "looks" : "look",
            isValid: res.data.isValid,
          });
        } else {
          router.push("/chat");
          store.commit("SET_CHATPARMAS", {
            type: res.data.isOwner == 1 ? "chat" : "look",
            isValid: res.data.isValid,
          });
        }
      }
    });
};
// 编辑保存
const saveEdit = (form) => {
  addLoading.value = true;
  let newForm = {
    userId: form.userId,
    libraryName: form.name,
    desc: form.desc,
    promptId: form.promptId,
    libraryId: editObj.value.knowledge_library_id,
    imageName: form.imageName,
    resourcePlatform: form.resourcePlatform,
    site: form.site,
    ...form,
  };
  api.base
    .baseUpdata(newForm)
    .then((res) => {
      addLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base103"));
        fileActive.value = null;
        fileType.value = "floder";
        fileItem.value = {};
        chunkDialog.value = false;
        detailType.value = false;
        updataEdit();
      } else if (res.returnCode == 499) {
        spDialog.value = true;
      }
    })
    .catch((err) => {
      addLoading.value = false;
    });
};
// 详情更新
const updataEdit = () => {
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: editObj.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.seleteUserInfo = res.data.userInfo.filter(
          (item) => item.ownerType == 0 && item.ownerName
        );
        store.commit("SET_BASEITEM", res.data);
      }
    });
};
// 打开编辑
const openDetail = () => {
  // 编辑
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.segment.deleteType =
          res.data.segment.deleteType == 0 ? false : true;
        res.data.segment.replaceType =
          res.data.segment.replaceType == 0 ? false : true;
        res.data.segment.maximumDescriptionLength = Number(
          res.data.segment.maximumDescriptionLength
        );
        editObj.value = res.data;
        detailType.value = true;
      }
    });
};
// 清除所有历史
const clearItemHistory = (id) => {
  historyLoading.value = true;
  api.base
    .clearHistory(
      {
        userId: userId.value,
        taskId: id,
        resourcetype: baseItem.value.resourcetype,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        openHistory();
        message.success(t("base.base104"));
      }
    });
};
// 关闭历史
const closeHistory = () => {
  historyDialog.value = false;
};
// 打开历史
const openHistory = () => {
  historyLoading.value = true;
  api.base
    .getHistoryFiles(
      {
        endTime: null,
        filenamePattern: "",
        pageId: 1,
        perPage: 10000,
        startTime: null,
        userId: userId.value,
        resourcetype: baseItem.value.resourcetype,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        historyLoading.value = false;
        historyDialog.value = true;
        historyTableData.value = res.data;
      }
    });
};
// 删除底部
const deleteBottomFile = (index) => {
  bottomFileList.value.splice(index, 1);
  getFileHeight();
};
// 下载文件
const downFile = (item) => {
  api.base
    .wordView(
      {
        fileId: item.id,
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      const imageUrl = res.data.url;
      const link = document.createElement("a");
      link.href = imageUrl;
      link.setAttribute("download", "文件");
      link.click();
    });
};
// 删除文件
const deleteFile = () => {
  deleteFileFn(fileItem.value);
};
const dragover = (event) => {
  event.preventDefault();
};
// 移动到底部
const bottomDrop = (event) => {
  topType.value = true;
  fileUpType.value = false;
  const itemData = event.dataTransfer.getData("text/plain");
  const item = JSON.parse(itemData || "{}");
  bottomFileList.value.push(item);
  onAdd();
  getFileList();
};
const onAdd = () => {
  setBottomFileList();
  setTimeout(() => {
    getFileHeight();
  }, 100);
};
// 去重底部
const setBottomFileList = () => {
  let arr = JSON.parse(JSON.stringify(bottomFileList.value));
  // 筛选出包含id属性的元素
  let idArr = arr.filter((item) => item.id !== undefined);
  idArr = Array.from(new Map(idArr.map((item) => [item.id, item])).values());
  // 筛选出包含sessionId属性的元素
  let sessionIdArr = arr.filter((item) => item.sessionId !== undefined);
  sessionIdArr = Array.from(
    new Map(sessionIdArr.map((item) => [item.sessionId, item])).values()
  );
  bottomFileList.value = idArr.concat(sessionIdArr);
};
// 动态计算高度
const getFileHeight = () => {
  nextTick(() => {
    if (leftDiv.value && topNav.value && topBtn.value) {
      fileListHeight.value =
        leftDiv.value.offsetHeight -
        (topNav.value.offsetHeight + topBtn.value.offsetHeight);
    }
  });
};
// 跳转chat
const goChat = () => {
  let fileList = [];
  let folderList = [];
  let topicList = [];
  bottomFileList.value.forEach((item) => {
    if (item.fileName) {
      fileList.push(item.id);
    } else if (item.folderName) {
      folderList.push(item.id);
    } else if (item.sessionName) {
      topicList.push(item.sessionId);
    }
  });
  store.commit("SET_BASEDRAP", { fileList, folderList, topicList });
  store.commit("SET_CHATTYPE", "base");
  chatDialog.value = true;
};
const closeChat = () => {
  chatDialog.value = false;
};
// 创建文件夹
const openCreateFloader = () => {
  floderDialog.value = true;
};
// 切换话题
const changeTopic = (index) => {
  store.commit("SET_BASEDETAILSROUTE", {
    navActive: navActive.value,
    topicActive: index,
  });
  sessionUserNamePattern.value = "";
  timeValue.value = 0;
  topicActive.value = index;
  startDate.value = "";
  endDate.value = "";
  topicPageId.value = 1;
  rightList.value = [];
  sessionNamePattern.value = "";
  getTopic();
};
// 获取文件
const getFileList = () => {
  leftFileLoading.value = true;
  api.base
    .queryFileList({
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
    })
    .then((res) => {
      leftFileLoading.value = false;
      if (res.returnCode == 200) {
        rootId.value = res.data[0].id;
        fileList.value = res.data[0].children;
        topType.value = false;
      }
    })
    .catch((err) => {
      leftFileLoading.value = false;
    });
};
// 远程搜索
const selectSearch = (value) => {
  searchLoading.value = true;
  api.base
    .queryFolderFileCompanyApi(
      {
        userId: userId.value,
        folderNamePattern: value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      searchLoading.value = false;
      if (res.returnCode == 200) {
        if (res.data.filesData.length != 0) {
          searchFileList.value = res.data.filesData;
        }
      }
    })
    .catch((err) => {
      searchLoading.value = false;
    });
};
// 关闭新增文件夹
const closeAddFolder = () => {
  floderDialog.value = false;
};
// 打开预览
const fileType = ref("floder");
const handleNodeClick = (data) => {
  fileActive.value = data.id;
  fileItem.value = data;
  // 进入文件夹
  if (data.folderName) {
    fileType.value = "floder";
    chunkDialog.value = false;
  } else {
    fileType.value = "file";
    chunkDialog.value = true;
  }
};
// 确定设置过期时间
const saveTime = (time) => {
  timeLoading.value = true;
  api.base
    .importantSet({
      userId: userId.value,
      fileId: timeObj.value.id,
      expireTime: time ? time : null,
      libraryId: baseItem.value.knowledge_library_id,
      operateType: "add",
    })
    .then((res) => {
      timeLoading.value = false;
      if (res.returnCode == 200) {
        timeDialog.value = false;
        message.success(t("base.base105"));
        getFileList();
        fileViewRef.value.getFileDesc();
      }
    })
    .catch((err) => {
      timeLoading.value = false;
    });
};
const closeTime = () => {
  timeDialog.value = false;
};
// 操作文件
const changeFileMore = (e, item) => {
  // 下载
  if (e == 1) {
    downFile(item);
  }
  // 取消验证
  if (e == 3) {
    api.base
      .importantSet({
        userId: userId.value,
        fileId: item.id,
        expireTime: null,
        libraryId: baseItem.value.knowledge_library_id,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base15"));
          getFileList();
          fileViewRef.value.getFileDesc();
        }
      });
  }
  // 删除文件夹
  if (e == 5) {
    deleteFolderFn(item);
  }
  // 删除文件
  if (e == 4) {
    deleteFileFn(item);
  }
  // 验证
  if (e == 7) {
    timeObj.value = item;
    timeDialog.value = true;
  }
};
// 删除文件
const deleteFileFn = (item) => {
  api.base
    .deleteFile(
      {
        idArray: [item.id],
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        if (fileItem.value && item.id == fileItem.value.id) {
          fileActive.value = null;
          fileType.value = "floder";
          fileItem.value = {};
          chunkDialog.value = false;
        }
        message.success(t("base.base16"));
        getFileList();
      }
    });
};
// 删除文件夹
const deleteFolderFn = (item) => {
  api.base
    .deleteFolder(
      {
        folderId: item.id,
        userId: userId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        fileActive.value = null;
        fileActive.value = null;
        fileType.value = "floder";
        fileItem.value = {};
        chunkDialog.value = false;
        message.success(t("base.base16"));
        getFileList();
      }
    });
};
const searchTopicList = (e) => {
  if (e == 0) {
    sessionUserNamePattern.value = "";
  } else {
    sessionUserNamePattern.value = e;
  }
  topicPageId.value = 1;
  rightList.value = [];
  getTopic();
};
const reshList = () => {
  topicPageId.value = 1;
  rightList.value = [];
  getTopic();
};
// nav
const changeNav = (index) => {
  store.commit("SET_BASEDETAILSROUTE", {
    navActive: index,
    topicActive: topicActive.value,
  });
  navActive.value = index;
  topicPageId.value = 1;
  if (index == 1) {
    startDate.value = "";
    endDate.value = "";
    chunkDialog.value = false;
    sessionNamePattern.value = "";
    rightList.value = [];
    getTopic();
  } else {
    fileActive.value = null;
    fileType.value = "floder";
    fileItem.value = {};
    getFileList();
  }
  getFileHeight();
};
// 下拉加载
const scrollLoad = (e) => {
  const scrollHeight = e.target.scrollHeight;
  const scrollTop = e.target.scrollTop;
  const clientHeight = e.target.clientHeight;
  if (scrollTop + clientHeight >= scrollHeight - 1) {
    load();
  }
};
const load = () => {
  topicPageId.value++;
  getTopic();
};
const searchList = () => {
  topicPageId.value = 1;
  getTopic();
};
// 获取日期
const getDateFromToday = (type) => {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth(); // 月份是从0开始的，所以这里得到的是0-11

  switch (type) {
    case 1: // 一个月前
      month -= 1;
      break;
    case 2: // 一个季度前
      month -= 3;
      break;
    case 3: // 半年前
      month -= 6;
      break;
    case 4: // 一年前
      year -= 1;
      break;
    default:
      return today; // 如果输入不是1-4之间的数字，返回今天
  }

  // 如果月份小于0，调整年份和月份
  if (month < 0) {
    year = year - 1;
    month = 12 + month; // 计算新的月份
  }

  // 创建新的日期对象
  const date = new Date(year, month);

  // 返回格式化的日期字符串
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};
const changeTime = (e) => {
  timeValue.value = e;
  if (e == 0) {
    startDate.value = "";
    endDate.value = "";
    topicPageId.value = 1;
    rightList.value = [];
    getTopic();
    return;
  }
  startDate.value = getDateFromToday(e);
  // 创建一个新的Date对象，它将被初始化为当前的日期和时间
  const now = new Date();
  // 获取年份
  const year = now.getFullYear();
  // 获取月份，月份是从0开始的，所以需要加1
  const month = now.getMonth() + 1;
  // 获取日
  const day = now.getDate();
  endDate.value = year + "-" + month + "-" + day;
  topicPageId.value = 1;
  rightList.value = [];
  getTopic();
};
// 获取话题广场
const getTopic = () => {
  let arr = ["verifyQuery", "hotQuery", "likeQuery", "allQuery"];
  topicLoading.value = true;
  api.base[arr[topicActive.value]](
    {
      userId: userId.value,
      libraryId: baseItem.value.knowledge_library_id,
      pageId: topicPageId.value,
      perPage: 10,
      sessionNamePattern: sessionNamePattern.value,
      sessionUserNamePattern: sessionUserNamePattern.value,
      startDate: startDate.value,
      endDate: endDate.value,
    },
    baseItem.value.knowledge_library_id
  )
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          item.sessionType = 1;
        });
        if (topicPageId.value == 1) {
          rightList.value = res.data;
        } else {
          rightList.value = rightList.value.concat(res.data);
        }
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
// 上传选择
const upFile = (index) => {
  upTypeActive.value = index;
};
// 关闭上传文件选项
const closeUpDialog = () => {
  upFileList.value = [];
  errorFileList.value = [];
  upFloderName.value = "";
  upFileDialog.value = false;
};
// 保存上传
const saveDialog = () => {
  if (upTypeActive.value == 1) {
    let id =
      fileType.value == "file"
        ? rootId.value
        : fileActive.value
        ? fileActive.value
        : rootId.value;
    upFileFn(upFileList.value, id);
  } else {
    // 新建文件夹
    api.base
      .createFolderCompanyApi(
        {
          userId: userId.value,
          folderName: upFloderName.value,
          parentId:
            fileType.value == "file"
              ? rootId.value
              : fileActive.value
              ? fileActive.value
              : rootId.value,
        },
        baseItem.value.knowledge_library_id
      )
      .then((res) => {
        if (res.returnCode == 200) {
          handleNodeClick({ folderName: upFloderName.value, id: res.data });
          upFileFn(upFileList.value, res.data);
        }
      });
  }
};
// 上传文件
const beforeAvatarUpload = (fileList, err) => {
  upFileList.value = fileList;
  errorFileList.value = err;
  upFileDialog.value = true;
};
// 上传文件fuction
const upFileFn = (fileList, id) => {
  upFileDialog.value = false;
  api.base
    .uploadFileCompanyApi(
      fileList,
      { folderId: id },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        pollingFn();
        task();
        if (res.failedFileList.length != 0) {
          const textFile = res.failedFileList.toString();
          message.warning(textFile + t("base.base89"));
        }
      }
    })
    .catch((err) => {
      message.warning(t("base.base89"));
    });
};
// 轮询
const pollingFn = () => {
  clearInterval(timmer.value);
  timmer.value = null;
  if (window.location.pathname == "/base/details") {
    timmer.value = setInterval(() => {
      setTimeout(() => {
        task();
      }, 0);
    }, 3000);
  }
};
// 轮询
const task = () => {
  api.base
    .taskView({ userId: userId.value }, baseItem.value.knowledge_library_id)
    .then((res) => {
      if (res.returnCode == 200) {
        //刷新当前文件夹
        getFileList();
        if (res.data.length == 0) {
          clearInterval(timmer.value);
          timmer.value = null;
          processList.value = [];
        } else {
          processDialog.value = true;
          processList.value = res.data;
          // 判断是否有可暂停的
          let pauseList = processList.value.filter(
            (item) => item.waitableFlag == "1"
          );
          // 有暂停的
          if (pauseList.length != 0) {
            pauseType.value = false;
          } else {
            // 没有 不可点击
            pauseType.value = true;
          }
          // 判断是否有取消的
          let cancelList = processList.value.filter(
            (item) =>
              item.processStatusFlag == "ready" ||
              item.processStatusFlag == "suspend"
          );
          if (cancelList.length != 0) {
            cancelType.value = false;
          } else {
            cancelType.value = true;
          }
        }
      }
    });
};
const closeProcess = () => {
  processDialog.value = false;
};
// 判断取消 暂停 开始
const postFile = (item, type) => {
  if (processList.value.length == 0) {
    message.warning(t("base.base107"));
    return;
  }
  if (type == "pause") {
    fileTypeChange(item, "uploadSuspend");
  } else if (type == "start") {
    fileTypeChange(item, "uploadContinue");
  } else if (type == "delete") {
    fileTypeChange(item, "uploadDelete");
  } else if (type == "allPause") {
    fileTypeChange({}, "uploadAllSuspend");
  } else if (type == "allStart") {
    fileTypeChange({}, "uploadAllStart");
  } else if (type == "allDelete") {
    fileTypeChange({}, "uploadAllDelete");
  }
};
// 公共类
const fileTypeChange = (item, name) => {
  api.base[name](
    { userId: userId.value, taskId: item ? item.taskId : "" },
    baseItem.value.knowledge_library_id
  ).then((res) => {
    if (res.returnCode == 200) {
      clearInterval(timmer.value);
      timmer.value = null;
      pollingFn();
      task();
    }
  });
};
// 保存文件夹名称
const saveFloader = (name) => {
  if (name == "") {
    message.warning(t("base.base76"));
    return;
  }
  folderLoading.value = true;
  api.base
    .createFolderCompanyApi(
      {
        userId: userId.value,
        folderName: name,
        parentId:
          fileType.value == "file"
            ? rootId.value
            : fileActive.value
            ? fileActive.value
            : rootId.value,
      },
      baseItem.value.knowledge_library_id
    )
    .then((res) => {
      folderLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base108"));
        floderDialog.value = false;
        getFileList();
      }
    })
    .catch((err) => {
      folderLoading.value = false;
    });
};
// 上传文件夹
const upFloder = (fileList, name, errorList) => {
  upFileList.value = fileList;
  errorFileList.value = errorList;
  upFloderName.value = name;
  upFileDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  fileActive.value = null;
  fileType.value = "floder";
  fileItem.value = {};
};
</script>

<style lang="scss" scoped>
.baseInner {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: relative;
  background-color: #fefefe;
  border-radius: 30px;
  padding: 30px;
}
.flex {
  display: flex;
  height: calc(100% - 76px);
}
.leftInner {
  width: 402px;
  height: 100%;
}
.leftInner1 {
  width: 270px;
  height: calc(100% - 30px);
  padding-bottom: 30px;
}
.rightTopic {
  width: calc(100% - 418px);
  height: calc(100% - 60px);
  margin-left: 30px;
  padding: 30px 59px;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 5px #edeef6;
}
.noDataFile {
  width: calc(100% - 432px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 5px #edeef6;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.navItem {
  font-size: 20px;
  color: #8a8a8a;
  font-weight: 400;
  text-align: center;
  margin-right: 22px;
  border-bottom: 4px solid #d9d9d9;
  cursor: pointer;
}
.navItemActive {
  font-size: 20px;
  color: #0256ff;
  font-weight: 400;
  text-align: center;
  margin-right: 22px;
  border-bottom: 4px solid #0256ff;
  cursor: pointer;
}
.searchInput {
  :deep(.el-input__wrapper) {
    height: 48px;
    border: 1px solid #edeef6;
    margin-bottom: 15px;
    font-size: 18px;
    color: #8a8a8a;
    font-weight: 400;
    border-radius: 16px;
  }
}
.topicInput {
  width: 390px;
  :deep(.el-input__wrapper) {
    height: 44px;
    border: 1px solid #edeef6;
    font-size: 18px;
    color: #8a8a8a;
    font-weight: 400;
    border-radius: 16px;
  }
}
.topicSearch {
  display: flex;
  align-items: center;
  height: 60px;
  margin-right: 128px;
}
.upFileBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 8px 13px;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #edeef6;
  color: #0256ff;
  cursor: pointer;
}
.addFiles {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 400;
  color: #8a8a8a;
  padding: 8px 20px;
  border: 2px solid #edeef6;
  cursor: pointer;
}
.upDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 49px;
  padding-bottom: 15px;
}
.width30 {
  margin-right: 6px;
  width: 24px;
}
.upFiles {
  width: 18px;
  margin-right: 9px;
}
.wid11 {
  width: 24px;
  margin-left: 6px;
}

.fileListDiv {
  overflow: auto;
  position: relative;
}

.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}

.wid24 {
  width: 24px;
}
.rightInner {
  width: calc(100% - 432px);
  height: 100%;
  margin-left: 30px;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 4px 12px 5px #edeef6;
}
.topMenu {
  height: 32px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  padding: 17px 88px 15px 49px;
}
.topMenuActive {
  background-color: #f7f8ff;
  color: #0256ff;
}
.marginRight13 {
  margin-right: 13px;
}
.roleDiv {
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-left: 14px;
  :deep(.el-dropdown) {
    max-width: calc(100% - 70px);
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 400;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
  }
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-weight: 400;
  width: 70px;
}
.baseItemDiv {
  height: 100%;
  position: relative;
}
.nodataCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noFileImg {
  width: 360px;
}
.leftDiv {
  width: 100%;
  height: 100%;
  position: relative;
}

.VueDraggable {
  width: 100%;
  height: 100%;
}
.overAuto {
  overflow: auto;
}

.dialogCancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #f02d63;
  font-weight: 400;
  font-size: 18px;
}
.dialogSaveBtn {
  height: 48px;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
  padding: 16px 20px;
  color: #fefefe;
  font-weight: 400;
  font-size: 18px;
}
.dialogEnd {
  display: flex;
  justify-content: flex-end;
}
.szText {
  color: #0256ff;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
}
.noData {
  width: 300px;
}
.noDataDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuDiv {
  position: fixed;
  width: 80px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px #a6a6a6;
}
.menuItem {
  text-align: center;
  padding: 5px;
  font-size: 16px;
  font-weight: 400;
  caret: #3d3d3d;
  cursor: pointer;
}
.menuItem:hover {
  background-color: rgba(196, 205, 234);
  cursor: pointer;
}
.maxMenu {
  max-height: 265px;
  overflow: auto;
}
.radioText {
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  :deep(.el-radio__input.is-checked .el-radio__inner) {
    background-color: transparent;
    border: 1px solid #3376ff;
  }
  :deep(.el-radio__input.is-checked .el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3376ff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: "";
  }
  :deep(.el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #8a8a8a;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: "";
  }
  :deep(.el-radio__inner) {
    border: 1px solid #8a8a8a;
    width: 18px;
    height: 18px;
  }
  :deep(el-radio__label) {
    font-size: 18px;
    font-weight: 400;
    color: #3d3d3d;
  }
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}
:deep(.el-tree-node__content) {
  height: 45px;
  border-radius: 6px;
}

.blueColor {
  color: #0256ff;
}
:deep(.el-loading-mask) {
  z-index: 300 !important;
}
.tabDiv {
  height: 52px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
}
.fileUpSvg {
  width: 20px;
  margin-right: 6px;
}
.fileUpDiv {
  height: 52px;
  border-radius: 8px;
  background-color: #eceeffc7;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: calc(100% - 12px);
  bottom: 10px;
}
.fileUpInnerDiv {
  border: 1px solid #a3acec;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #767bfa;
  font-weight: 500;
}
.baseTb {
  width: 20px;
  margin-right: 8px;
}
.tbDiv {
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  width: calc(100% - 130px);
  height: 44px;
  border-radius: 16px;
  font-size: 18px;
  color: #0256ff;
  font-weight: 400;
  justify-content: center;
  margin-right: 12px;
}
.nowSyncTypeDiv {
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  width: calc(100% - 130px);
  height: 40px;
  border-radius: 16px;
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 400;
  justify-content: center;
  margin-right: 12px;
}
.syncProgress {
  width: 50%;
  margin-left: 5px;
}
:deep(.el-progress__text) {
  font-size: 16px !important;
  font-weight: 500;
}
.marginRight6 {
  margin-right: 6px;
}
:deep(.el-progress-bar__outer) {
  height: 9px !important;
}
.syncText {
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.syncLoadingText {
  max-width: 100px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
