<template>
  <div
    :class="
      props.tagActive != null && props.tagList.length != 0
        ? 'flexEnd'
        : 'flexBetween'
    "
  >
    <div class="flexCenter" v-if="props.tagActive == null">
      <div
        v-for="(item, index) in props.tagList"
        :key="index"
        class="flexCenter"
      >
        <div
          :class="index != 1 ? 'marginright15 tagItemDiv' : ' tagItemDiv'"
          @click="handleSearch(item)"
        >
          <img class="tagItemImg" :src="item.img" alt="" />
          <span class="tagItemTitle">{{ t(item.label) }}</span>
        </div>
        <img :src="icon.lineSvg" alt="" class="zwf" v-if="index == 1" />
      </div>
    </div>
    <modelListVue
      type="bottom"
      :model="model"
      :modelList="props.modelList"
      :copyModelList="props.copyModelList"
      :macType="macType"
      :tagActive="props.tagActive"
      :llmNav="props.llmNav"
      :llmConfigurationDict="props.llmConfigurationDict"
      @changeModel="changeModel"
      @sendForm="sendForm"
    ></modelListVue>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import modelListVue from "./modelList.vue";
import { message } from "ant-design-vue";
const { t } = useI18n();
const model = ref(null);
const props = defineProps([
  "tagActive",
  "tagList",
  "modelList",
  "model",
  "macType",
  "sendForm",
  "llmConfigurationDict",
  "llmNav",
  "copyModelList",
]);
const emit = defineEmits(["changeModel", "handleSearch", "sendForm"]);
onMounted(() => {
  model.value = props.model;
});
watch(
  () => props.model,
  (newValue, oldValue) => {
    model.value = props.model;
  }
);
const handleSearch = (e) => {
  if (props.macType) {
    message.warning(t("gpt.index76"));
    return;
  }
  emit("handleSearch", e);
};
const changeModel = (e) => {
  emit("changeModel", e);
};
const sendForm = (form, nav) => {
  emit("sendForm", form, nav);
};
</script>

<style lang="scss" scoped>
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  height: 48px;
  padding: 0px 12px;
}
.flexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
  height: 48px;
  padding: 0px 12px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.tagItemDiv {
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  padding: 7px 11px;
  display: flex;
  align-items: center;
  height: 30px;
  cursor: pointer;
}
.marginright15 {
  margin-right: 15px;
}
.tagItemImg {
  margin-right: 7px;
  width: 20px;
}
.tagItemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.zwf {
  margin: 0px 12px;
  width: 5px;
  height: 25px;
}
</style>
