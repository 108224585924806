import login from './http/login'
import header from './http/header'
import base from './http/base'
import share from './http/share'
import manage from './http/manage'
import chat from './http/chat'
import home from './http/home'
import log from './http/log'
import bot from './http/bot'
import publish from './http/publish'
import gpt from './http/gpt'
export default {
    login, header, base, share, manage, chat, home, log, bot, publish, gpt
}
