<script setup>
import { ref, reactive, onMounted, watch, onUnmounted } from "vue";
import addDialog from "./components/addBot.vue";
import botTool from "./components/botTool.vue";
import plugAdd from "./components/plugAdd.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { icon } from "@/utils/icon";
import detailBot from "./components/detailBot.vue";
import { useRouter } from "vue-router";
import detailToolView from "./components/detailTool.vue";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const userId = ref(store.state.userId);
const listLoading = ref(false);
const detailType = ref(false);
const botIsOwner = ref(0);
onMounted(() => {
  getList();
});
// 列表查询
const navActive = ref(0);
const navList = ref([
  {
    name: "bot.bot1",
    url: icon.znzs,
    acUrl: icon.znzsAc,
  },
  {
    name: "bot.bot123",
    url: icon.znyy,
    acUrl: icon.znyyAc,
  },
  {
    name: "bot.bot124",
    url: icon.cj,
    acUrl: icon.cjAc,
  },
  // {
  //   name: "bot.bot125",
  //   url: icon.tsc,
  //   acUrl: icon.tscAc,
  // },
]);
const roleList = ref([
  {
    name: "base.base10",
    value: 0,
  },
  {
    name: "base.base38",
    value: 1,
  },
  {
    name: "base.base41",
    value: 2,
  },
]);
const basePxList = ref([
  {
    name: "base.base356",
    value: 0,
  },
  {
    name: "base.base357",
    value: 1,
  },
  {
    name: "base.base358",
    value: 2,
  },
  {
    name: "base.base359",
    value: 3,
  },
]);
const toolLoading = ref(false);
// 保存插件
const saveTool = (form) => {
  toolLoading.value = true;
  form.userId = userId.value;
  api.bot
    .toolUpdate(form)
    .then((res) => {
      toolLoading.value = false;
      if (res.returnCode == 200) {
        toolType.value = false;
        message.success(t("base.base103"));
        changeNav(2);
      }
    })
    .catch((err) => {
      toolLoading.value = false;
    });
};
// 新增插件
const plugAddFn = (form) => {
  toolLoading.value = true;
  form.userId = userId.value;
  api.bot
    .toolAdd(form)
    .then((res) => {
      toolLoading.value = false;
      if (res.returnCode == 200) {
        plugType.value = false;
        message.success(t("bot.bot4"));
        changeNav(2);
      }
    })
    .catch((err) => {
      toolLoading.value = false;
    });
};
const changeNav = (index) => {
  navActive.value = index;
  listFomr.value.pageId = 1;
  listFomr.value.templatePattern = "";
  listFomr.value.agentNamePattern = "";
  listFomr.value.orderBy = 0;
  listFomr.value.roles = 0;
  if (index != 1) {
    getList();
  }
};
const changeRole = (index) => {
  listFomr.value.roles = index;
  listFomr.value.pageId = 1;
  list.value = [];
  getList();
};
// 选择排序
const changeShort = (e) => {
  listFomr.value.orderBy = e;
  listFomr.value.pageId = 1;
  list.value = [];
  getList();
};
// 分页
const currentPage = ref(1);
const pageSize = ref(30);
// 列表参数
const listFomr = ref({
  userId: userId.value,
  templatePattern: "",
  agentNamePattern: "",
  pageId: currentPage.value,
  perPage: pageSize.value,
  roles: 0,
  orderBy: 0,
});
watch(
  () => listFomr.value.templatePattern,
  (newVal) => {
    listLoading.value = true;
    if (navActive.value == 0) {
      listFomr.value.templatePattern = newVal;
    } else {
      listFomr.value.agentNamePattern = newVal;
    }
    listFomr.value.pageId = 1;
    list.value = [];
    getList();
  }
);
// 根据item返回头像
const getBotImg = (item) => {
  if (item.image_path == null || !item.image_path) {
    if (navActive.value == 0) {
      return icon.rabit;
    } else if (navActive.value == 2) {
      return icon.plugUpload;
    }
  } else {
    return "/openai/chat/v4/image/get/" + item.image_path;
  }
};
// 判断是否置顶
const getPin = (item) => {
  if (item.isPin == 0) {
    return icon.basePin;
  } else {
    return icon.basePinYes;
  }
};
const list = ref([]);
// 获取微模型列表
const getList = () => {
  listLoading.value = true;
  let obj = JSON.parse(JSON.stringify(listFomr.value));
  if (obj.roles == 0) {
    obj.roles = null;
  } else {
    obj.roles = obj.roles - 1;
  }
  api.bot[navActive.value == 0 ? "templateQuery" : "toolQuery"](obj)
    .then((res) => {
      if (res.returnCode == 200) {
        if (obj.pageId == 1) {
          list.value = res.data;
        } else {
          list.value = list.value.concat(res.data);
        }
        listLoading.value = false;
      }
    })
    .catch((err) => {
      listLoading.value = false;
    });
};
const loadList = () => {
  listFomr.value.pageId++;
  getList();
};
// 新建
const botInfo = ref({});
const showDialog = ref(false);
const plugType = ref(false);
// 关闭新增插件
const closeAdd = () => {
  plugType.value = false;
};
// 新建智能体 插件
const openAdd = () => {
  if (navActive.value == 0) {
    showDialog.value = true;
    botInfo.value = {
      shareUserIds: [
        {
          userId: userId.value,
          id: userId.value,
          type: 0, //0 拥有者 1 创作者 2 普通用户
          userName: store.state.userName,
          name: store.state.userName,
          ownerType: 0, //0个人 1部门 2群组
          color: store.state.color,
        },
      ],
      suggest: [],
      imageName: "",
      AgentIds: [],
      isAgentOn: false,
    };
  } else {
    plugType.value = true;
  }
};

// 确定新增
const submitBtn = (form) => {
  form.userId = userId.value;
  showDialog.value = false;
  api.bot.templateAdd(form).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("bot.bot4"));
      api.bot
        .templateDetail({
          userId: userId.value,
          templateId: res.data.templateId,
        })
        .then((ress) => {
          if (ress.returnCode == 200) {
            botInfo.value = ress.data;
            botInfo.value.templateId = res.data.templateId;
            botInfo.value.suggest = botInfo.value.suggestQuestion;
            store.commit("SET_BOT", botInfo.value);
            router.push({
              name: "botDetail",
            });
          }
        });
    }
  });
};
// 智能体修改
const templateId = ref(null);
const goBaseDetails = (item) => {
  if (navActive.value == 0) {
    api.chat
      .addChat({
        userId: userId.value,
        topicName: item.templateName,
        templateId: item.templateId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          api.chat
            .chatDescQuery({
              userId: userId.value,
              sessionId: res.data.table_id,
              sessionType: 2,
            })
            .then((ress) => {
              if (ress.returnCode == 200) {
                store.commit("SET_CHATTYPE", "chat");
                store.commit("SET_TOPIC", ress.data);
                router.push("/chat");
                store.commit("SET_CHATPARMAS", {
                  type: "chat",
                  isValid: 0,
                });
              }
            });
        }
      });
  }
};
// 关闭弹窗
const closeBtn = () => {
  showDialog.value = false;
  getList();
};
// 修改确定
const editBtn = (form) => {
  api.bot.templateEdit(form).then((res) => {
    if (res.returnCode == 200) {
      detailType.value = false;
      message.success(t("bot.bot5"));
      changeNav(0);
    }
  });
};
const toolObj = ref({});
const toolType = ref(false);
// 关闭插件详情
const closeTool = () => {
  toolType.value = false;
};
// 更多操作
const changeMore = (key, item) => {
  if (key == 1) {
    // 置顶
    api.bot[navActive.value == 0 ? "templateTop" : "toolPin"]({
      userId: userId.value,
      templateId: item.templateId,
      agentId: item.agentId,
      isFavourite: 1,
    }).then((res) => {
      if (res.returnCode == 200) {
        message.success(t("bot.bot6"));
        listFomr.value.pageId = 1;
        getList();
      }
    });
  } else if (key == 2) {
    // 取消置顶
    api.bot[navActive.value == 0 ? "templateTop" : "toolPin"]({
      userId: userId.value,
      templateId: item.templateId,
      agentId: item.agentId,
      isFavourite: 0,
    }).then((res) => {
      if (res.returnCode == 200) {
        message.success(t("bot.bot7"));
        listFomr.value.pageId = 1;
        getList();
      }
    });
  } else if (key == 3) {
    // 删除智能体
    api.bot
      .templateDel({
        userId: userId.value,
        templateId: item.templateId,
      })
      .then((res) => {
        message.success(t("bot.bot8"));
        getList();
      });
  } else if (key == 4) {
    // 编排
    templateId.value = item.templateId;
    api.bot
      .templateDetail({
        userId: userId.value,
        templateId: item.templateId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          botInfo.value = res.data;
          botInfo.value.templateId = templateId.value;
          botInfo.value.suggest = botInfo.value.suggestQuestion;
          store.commit("SET_BOT", botInfo.value);
          router.push({
            name: "botDetail",
          });
        }
      });
  } else if (key == 5) {
    //智能体详情
    templateId.value = item.templateId;
    api.bot
      .templateDetail({
        userId: userId.value,
        templateId: item.templateId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          botIsOwner.value = item.isOwner;
          botInfo.value = res.data;
          botInfo.value.templateId = templateId.value;
          detailType.value = true;
        }
      });
  } else if (key == 6) {
    // 插件删除
    api.bot
      .toolDelete({
        userId: userId.value,
        agentId: item.agentId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("bot.bot8"));
          getList();
        }
      });
  } else if (key == 7) {
    // 插件详情
    api.bot
      .toolDesc({
        userId: userId.value,
        agentId: item.agentId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          toolObj.value = res.data;
          toolType.value = true;
        }
      });
  } else if (key == 8) {
    // 插件编辑
    api.bot
      .toolDesc({
        userId: userId.value,
        agentId: item.agentId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          toolObj.value = res.data;
          plugType.value = true;
        }
      });
  }
};
// 关闭详情
const closeDetail = () => {
  detailType.value = false;
};
</script>

<template>
  <div class="container">
    <!-- 搜索框 -->
    <div class="topNav">
      <div class="flexCenter">
        <div
          v-for="(item, index) in navList"
          @click="changeNav(index)"
          :key="item"
          :class="navActive == index ? 'navItemAc' : 'navItem'"
        >
          <img
            :src="navActive == index ? item.acUrl : item.url"
            alt=""
            class="navItemImg"
          />
          <div>{{ t(item.name) }}</div>
        </div>
      </div>
      <div class="flexCenter" v-if="navActive != 1">
        <el-input
          class="baseInput"
          :placeholder="navActive == 0 ? t('bot.bot3') : t('bot.base128')"
          v-model="listFomr.templatePattern"
          prefix-icon="Search"
        />
        <!-- 角色 -->
        <div class="roleDiv">
          <div class="roleTitle">{{ t("base.base2") }}</div>
          <el-dropdown @command="changeRole">
            <span class="dropText">
              {{ t(roleList[listFomr.roles].name) }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in roleList"
                  :key="item.value"
                  :command="item.value"
                >
                  <span class="dropText">{{ t(item.name) }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <!-- 筛选 -->
        <div class="sortDiv">
          <el-dropdown @command="changeShort">
            <span class="el-dropdown-link">
              <img class="basePx" :src="icon.basePx" alt="" />
              <span class="font18">{{
                t(basePxList[listFomr.orderBy].name)
              }}</span>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in basePxList"
                  :command="item.value"
                  :key="item.value"
                >
                  <span class="dropText">{{ t(item.name) }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <!-- <img class="botDhq" :src="icon.botDhq" alt="" /> -->
      </div>
    </div>
    <!-- 内容 -->
    <div
      class="box"
      v-infinite-scroll="loadList"
      :infinite-scroll-distance="5"
      :infinite-scroll-immediate="false"
    >
      <!-- 新建智能体 插件 -->
      <div
        class="itemWidthAdd"
        @click="openAdd"
        v-if="navActive == 0 || navActive == 2"
      >
        <div class="flexCenter itemTile">
          <div class="addDiv">
            <img :src="icon.baseAdd" alt="" class="addImg" />
          </div>
          <div class="baseItemTitle">
            {{ navActive == 0 ? t("bot.bot9") : t("bot.base126") }}
          </div>
        </div>
        <div class="addTip other">
          {{ navActive == 0 ? t("bot.bot10") : t("bot.base127") }}
        </div>
      </div>
      <!-- 展示智能工具 -->
      <botTool v-if="navActive == 1"></botTool>
      <!--展示智能助手-->
      <template v-if="navActive != 1">
        <div
          class="itemWidth"
          v-for="(item, index) in list"
          :key="index"
          @click="goBaseDetails(item)"
        >
          <div class="flexCenter itemTile">
            <div class="flexCenter titleMax">
              <div class="logoDiv">
                <img :src="getBotImg(item)" alt="" class="baseIconLogo" />
              </div>
              <div class="nameBigDiv">
                <el-tooltip
                  popper-class="toolTip"
                  :content="item.templateName || item.agentName"
                >
                  <div class="forBaseItemTitle">
                    {{ item.templateName || item.agentName }}
                  </div>
                </el-tooltip>
                <div class="nameTip" v-if="navActive == 0">
                  {{ item.sessionNum }}
                  {{ t("base.base353") }}
                  {{ item.userResults }}
                  {{ t("base.base354") }}
                </div>
                <div class="nameTip" v-if="navActive == 2">
                  {{ item.sessionNum }}
                  {{ t("bot.base169") }}
                  {{ item.userNum }}
                  {{ t("base.base354") }}
                </div>
              </div>
            </div>
            <div
              :class="item.isPin == 0 ? 'pinDiv' : 'pinYesDiv'"
              @click.stop="changeMore(item.isPin == 0 ? 1 : 2, item)"
            >
              <img :src="getPin(item)" alt="" class="pinImg" />
            </div>
          </div>
          <div class="addTip">
            {{ item.templateDesc || item.agentDescription }}
          </div>
          <!-- 功能区 -->
          <div class="bottomFlexBetween">
            <div class="flexCenter wid46">
              <div
                class="baseTx"
                :style="'background-color:' + item.owner.color"
              >
                {{ item.owner.userName ? item.owner.userName.slice(0, 1) : "" }}
              </div>
              <el-tooltip popper-class="toolTip" :content="item.owner.userName">
                <div class="baseName">
                  {{ item.owner.userName ? item.owner.userName : "" }}
                </div>
              </el-tooltip>
            </div>
            <!-- 功能区 -->
            <el-dropdown @command="changeMore($event, item)" trigger="click">
              <div class="moreDiv" @click.stop="">
                <img :src="icon.baseMore" alt="" class="moreIcon" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- 编排 -->
                  <el-dropdown-item
                    :command="4"
                    v-if="item.isOwner == 1 && navActive == 0"
                  >
                    <span class="dropText colorBlue">{{ t("bot.bot14") }}</span>
                  </el-dropdown-item>
                  <!-- 详情 -->
                  <el-dropdown-item :command="5" v-if="navActive == 0">
                    <span class="dropText colorBlue">
                      {{ t("bot.bot105") }}
                    </span>
                  </el-dropdown-item>
                  <!-- 插件详情 -->
                  <el-dropdown-item :command="7" v-if="navActive == 2">
                    <span class="dropText colorBlue">
                      {{ t("bot.base168") }}
                    </span>
                  </el-dropdown-item>
                  <!-- 置顶 -->
                  <el-dropdown-item :command="1" v-if="item.isPin === 0">
                    <span class="dropText colorBlue">{{
                      t("base.base6")
                    }}</span>
                  </el-dropdown-item>
                  <!-- 取消置顶 -->
                  <el-dropdown-item :command="2" v-if="item.isPin === 1">
                    <span class="dropText colorRed">{{ t("bot.bot12") }}</span>
                  </el-dropdown-item>
                  <!-- 删除 -->
                  <el-dropdown-item
                    :command="3"
                    v-if="item.isOwner == 1 && navActive == 0"
                  >
                    <span class="dropText colorRed">{{ t("bot.bot13") }}</span>
                  </el-dropdown-item>
                  <!-- 删除插件 -->
                  <el-dropdown-item
                    :command="6"
                    v-if="item.isOwner == 1 && navActive == 2"
                  >
                    <span class="dropText colorRed">{{
                      t("bot.base161")
                    }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </template>
    </div>
    <div class="flexDiv1" v-if="listLoading" v-loading="listLoading"></div>
  </div>
  <!-- 新建智能体弹窗 -->
  <addDialog
    v-if="showDialog"
    :botInfo="botInfo"
    :formType="formType"
    @closeBtn="closeBtn"
    @submitBtn="submitBtn"
  ></addDialog>
  <!-- 详情 -->
  <detailBot
    v-if="detailType"
    :botInfo="botInfo"
    :botIsOwner="botIsOwner"
    @closeDetail="closeDetail"
    @editBtn="editBtn"
  ></detailBot>
  <!-- 插件 -->
  <plugAdd
    v-if="plugType"
    @closeAdd="closeAdd"
    @saveTool="saveTool"
    :toolObj="toolObj"
    :toolLoading="toolLoading"
    @plugAddFn="plugAddFn"
  ></plugAdd>
  <!-- 插件详情 -->
  <detailToolView
    v-if="toolType"
    :toolObj="toolObj"
    @closeTool="closeTool"
    @saveTool="saveTool"
  ></detailToolView>
</template>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:deep(.el-loading-spinner) {
  width: 100%;
}
:deep(.el-button.is-round) {
  border-radius: 12px;
}

:deep(.el-loading-mask) {
  z-index: 3 !important;
}

.flexDiv1 {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.container {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px;
  width: 100%;
  height: 100%;
  position: relative;
  .baseInput {
    width: 236px;
    height: 48px;
    font-size: 17px;
    color: #8a8a8a;
    font-weight: 400;
    :deep(.el-input__wrapper) {
      border-radius: 12px;
      background-color: #f6f6f6;
      border: 0px;
      box-shadow: 0px 0px 0px 0px;
    }
  }
}
.sort {
  padding: 12px !important;

  img {
    width: 25px;
    height: 25px;
  }
}

.box {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-top: 22px;
  overflow: auto;
}
.itemWidthAdd {
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 24px;
  cursor: pointer;
  width: 23%;
  height: 204px;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  margin-left: 10px;
  margin-right: 10px;
}
.itemWidth {
  border-radius: 16px;
  background-color: #f7f8ff;
  margin-bottom: 20px;
  padding: 24px;
  cursor: pointer;
  width: 23%;
  height: 204px;
  box-shadow: 0px 2px 8px 0px rgba(204, 206, 229, 0.5);
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #dbdfff;
}
.pinYesDiv {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemWidth:hover {
  background-color: #f1f2ff;
  box-shadow: 0px 8px 16px 0px #cccee5;
  border: 1px solid #a3acec;
  .pinDiv {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #fefefe;
  }

  .pinYesDiv {
    border-radius: 8px;
  }

  .moreDiv {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flexCenter {
  display: flex;
  align-items: center;
}

.itemTile {
  height: 28px;
  margin-bottom: 24px;
  position: relative;
}

.addDiv {
  background-color: #dbdfff;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.addImg {
  width: 15px;
  height: 15px;
}

.baseItemTitle {
  font-size: 20px;
  color: #0256ff;
  font-weight: 700;
}

.addTip {
  font-size: 18px;
  color: #8a8a8a;
  text-align: justify;
  font-weight: 400;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  word-break: break-all; /* 强制换行 */
  -webkit-box-orient: vertical;
}

.other {
  -webkit-line-clamp: 3; /* 限制行数 */
  word-break: break-all; /* 强制换行 */
  -webkit-box-orient: vertical;
  height: 75px;
}

.titleMax {
  width: 100%;
}

.logoDiv {
  border-radius: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.baseIconLogo {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.forBaseItemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 700;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pinDiv {
  display: none;
}

.pinImg {
  width: 16px;
  height: 16px;
}

.bottomFlexBetween {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.baseIcon {
  width: 25px;
}

.moreDiv {
  display: none;
}

.moreIcon {
  width: 18px;
}

.dropText {
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
}
.colorBlue {
  color: #0256ff;
}
.colorRed {
  color: #f02d63;
}
.nameTip {
  font-size: 14px;
  color: #8a8a8a;
  font-weight: 400;
}
.nameBigDiv {
  width: calc(100% - 100px);
}
.baseName {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 500;
  max-width: calc(100% - 30px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid46 {
  width: calc(100% - 46px);
}
.baseTx {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #0256ff;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
  margin-right: 6px;
}
.navItemImg {
  width: 20px;
  margin-right: 5px;
}
.navItem {
  display: flex;
  align-items: center;
  padding: 11px 16px;
  font-size: 18px;
  color: #898989;
  font-weight: 500;
  border-radius: 16px;
  margin-right: 12px;
  cursor: pointer;
}
.navItemAc {
  display: flex;
  align-items: center;
  padding: 11px 16px;
  font-size: 18px;
  color: #3376ff;
  font-weight: 500;
  border-radius: 16px;
  margin-right: 12px;
  cursor: pointer;
  background-color: #fefefe;
  box-shadow: 1px 2px 8px 0px rgba(204, 206, 229, 0.5);
}
.navItem:hover {
  background-color: #f6f6f6;
}
.topNav {
  height: 48px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.roleDiv {
  border-radius: 12px;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-left: 12px;
  background-color: #f6f6f6;
  :deep(.el-dropdown) {
    max-width: calc(100% - 45px);
    font-size: 18px;
    color: #3d3d3d;
    font-weight: 400;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
  }
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-weight: 400;
  width: 45px;
}
.sortDiv {
  background-color: #f6f6f6;
  border-radius: 8px;
  margin-left: 12px;
  font-size: 18px;
  color: #989898;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  :deep(.el-dropdown-link) {
    display: flex;
    align-items: center;
  }
}
.basePx {
  width: 16px;
  margin-right: 6px;
}
.botDhq {
  width: 24px;
  margin-left: 20px;
  cursor: pointer;
}
.font18 {
  font-size: 18px;
}
</style>
