<template>
  <div class="resonFlex">
    <!-- 来源 -->
    <div
      :class="props.type == 'chat' ? 'reson1' : 'reson'"
      v-if="!props.resonDialog"
      @click="openReson(props.item)"
    >
      <img :src="icon.searchSvg" alt="" class="wid19" />
      <div class="resonText">
        {{ t("base.base277") }}{{ getNumber(props.item)
        }}{{ t("base.base278") }}
      </div>
    </div>
    <div
      v-if="
        props.resonDialog &&
        (getFileNumber(props.item) != 0 || getNetNumber(props.item) != 0)
      "
      class="resonDialog"
      @click="closeReson"
    >
      <div class="reSonUpFile bottom12" v-if="getFileNumber(props.item) != 0">
        <div class="reSonCenter">
          <img class="reSonIcon" :src="icon.baseReson" alt="" />
          <div class="reSonTitle">
            {{ t("base.base277") }}{{ getFileNumber(props.item)
            }}{{ t("base.base280") }}
          </div>
        </div>
        <img
          class="reSonUpFileImg"
          :src="icon.upReson"
          alt=""
          v-if="resonOneType"
          @click="resonOneType = !resonOneType"
        />
        <img
          class="reSonUpFileImg"
          :src="icon.downReson"
          alt=""
          v-else
          @click="resonOneType = !resonOneType"
        />
      </div>
      <!-- 文件内容 -->
      <el-collapse-transition>
        <div class="pa" v-show="resonOneType">
          <img
            :src="icon.leftReson"
            alt=""
            class="leftResonIcon"
            v-if="getFileNumber(props.item) >= 3"
            @click="changeLeftReson(resonOne)"
          />
          <div class="flexItemReson" ref="resonOne">
            <fileReSon
              v-for="items in props.item.resonList.answers[
                props.item.chatActive - 1
              ].answerResource.fileResource"
              :key="items.fileId"
              :fileItem="items"
              class="resonMargin"
              @click="openFileView(props.item, items)"
            ></fileReSon>
            <talkReSon
              class="resonMargin"
              v-for="items in props.item.resonList.answers[
                props.item.chatActive - 1
              ].answerResource.sessionResource"
              :key="items.sessionId"
              :talkItem="items"
              @click="openTalk(items)"
            ></talkReSon>
          </div>
          <img
            :src="icon.rightReson"
            alt=""
            class="rightResonIcon"
            v-if="getFileNumber(props.item) >= 3"
            @click="changeRightReson(resonOne)"
          />
        </div>
      </el-collapse-transition>

      <!-- 网页 -->
      <div class="reSonUpFile" v-if="getNetNumber(props.item) != 0">
        <div class="reSonCenter">
          <img class="reSonIcon" :src="icon.netReson" alt="" />
          <div class="reSonTitle">
            {{ t("base.base277") }}{{ getNetNumber(props.item)
            }}{{ t("base.base279") }}
          </div>
        </div>
        <img
          class="reSonUpFileImg"
          :src="icon.upReson"
          alt=""
          v-if="resonTwoType"
          @click="resonTwoType = !resonTwoType"
        />
        <img
          class="reSonUpFileImg"
          :src="icon.downReson"
          alt=""
          v-else
          @click="resonTwoType = !resonTwoType"
        />
      </div>
      <el-collapse-transition>
        <div class="pa" v-show="resonTwoType">
          <img
            :src="icon.leftReson"
            alt=""
            class="leftResonIcon"
            v-if="
              props.item.resonList.answers[props.item.chatActive - 1]
                .answerResource.internetResource.length >= 3
            "
            @click="changeLeftReson(resonTwo)"
          />
          <div class="flexItemReson" ref="resonTwo">
            <htmlReSon
              v-for="items in props.item.resonList.answers[
                props.item.chatActive - 1
              ].answerResource.internetResource"
              :key="items"
              :htmlItem="items"
              class="resonMargin"
            ></htmlReSon>
          </div>
          <img
            :src="icon.rightReson"
            alt=""
            class="rightResonIcon"
            v-if="
              props.item.resonList.answers[props.item.chatActive - 1]
                .answerResource.internetResource.length >= 3
            "
            @click="changeRightReson(resonTwo)"
          />
        </div>
      </el-collapse-transition>
      <!-- sql -->
      <div
        class="reSonUpFile"
        v-if="
          props.item.resonList.answers[props.item.chatActive - 1].answerResource
            .sqlResults.length != 0
        "
      >
        <div class="reSonCenter">
          <img class="reSonIcon" :src="icon.baseSql" alt="" />
          <div class="reSonTitle">{{ t("gpt.index55") }}</div>
        </div>
        <img
          class="reSonUpFileImg"
          :src="icon.upReson"
          alt=""
          v-if="resonTreeType"
          @click="resonTreeType = !resonTreeType"
        />
        <img
          class="reSonUpFileImg"
          :src="icon.downReson"
          alt=""
          v-else
          @click="resonTreeType = !resonTreeType"
        />
      </div>
      <el-collapse-transition>
        <div
          v-show="
            resonTreeType &&
            props.item.resonList.answers[props.item.chatActive - 1]
              .answerResource.sqlResults &&
            props.item.resonList.answers[props.item.chatActive - 1]
              .answerResource.sqlResults.length != 0
          "
          class="sqlDiv"
        >
          <div
            v-for="(item, index) in props.item.resonList.answers[
              props.item.chatActive - 1
            ].answerResource.sqlResults"
            :key="item"
            :class="
              index !=
              props.item.resonList.answers[props.item.chatActive - 1]
                .answerResource.sqlResults.length -
                1
                ? 'bottom20'
                : ''
            "
          >
            <div v-html="getSql(item.sqlSentence)" class="sqlText"></div>
            <div class="flexEnd">
              <div class="sqlCxDiv" @click="openResultDialog(item.sqlResult)">
                <img :src="icon.sqlCx" class="sqlCx" alt="" />
                {{ t("gpt.index62") }}
              </div>
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>
<script setup>
import { icon } from "@/utils/icon";
import fileReSon from "@/components/reson/file.vue";
import htmlReSon from "@/components/reson/html.vue";
import talkReSon from "@/components/reson/talk.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { ref } from "vue";
const resonOneType = ref(true);
const resonTwoType = ref(true);
const resonOne = ref(null);
const resonTwo = ref(null);
const resonTreeType = ref(true);
// 打开查询结果
const openResultDialog = (message) => {
  emit("openResultDialog", message);
};
// 将\n换成换行标签
const getSql = (sql) => {
  return sql.replace(/\n/g, "<br>");
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 打开来源
const openReson = (item) => {
  if (getNumber(item) == 0) return;
  emit("openReson");
};
// 关闭来源
const closeReson = () => {
  // resonDialog.value = false;
};
const props = defineProps(["item", "resonDialog", "type"]);
const emit = defineEmits([
  "openFileView",
  "openTalk",
  "openReson",
  "openResultDialog",
]);
const getNumber = (item) => {
  if (item.resonList.answers.length == 0) return 0;
  let number =
    item.resonList.answers[item.chatActive - 1].answerResource.fileResource
      .length +
    item.resonList.answers[item.chatActive - 1].answerResource.internetResource
      .length +
    item.resonList.answers[item.chatActive - 1].answerResource.sessionResource
      .length;
  return number;
};
const getNetNumber = (item) => {
  if (item.resonList.answers.length == 0) return 0;
  let number =
    item.resonList.answers[item.chatActive - 1].answerResource.internetResource
      .length;
  return number;
};

const getFileNumber = (item) => {
  if (item.resonList.answers.length == 0) return 0;
  let number =
    item.resonList.answers[item.chatActive - 1].answerResource.fileResource
      .length +
    item.resonList.answers[item.chatActive - 1].answerResource.sessionResource
      .length;
  return number;
};
// 打开文件预览
const openFileView = (item, items) => {
  emit(
    "openFileView",
    items,
    item.resonList.answers[item.chatActive - 1].answerResource.chunkResource
  );
};
// 打开会话
const openTalk = (item) => {
  emit("openTalk", item);
};
</script>

<style lang="scss" scoped>
.resonFlex {
  display: flex;
  cursor: pointer;
  margin-bottom: 24px;
}
.reson {
  display: flex;
  border-radius: 30px;
  background-color: #feffff;
  padding: 12px 15px;
  align-items: center;
  position: relative;
}
.reson1 {
  display: flex;
  border-radius: 30px;
  background-color: #f7f8ff;
  padding: 12px 15px;
  align-items: center;
  position: relative;
}
.resonText {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 25px;
}
.resonImg {
  display: flex;
  align-items: center;
  position: relative;
}
.wlImg {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 50%;
  width: 30px;
}
.resonDialog {
  width: calc(100% - 30px);
  background-color: #f7f8ff;
  border-radius: 30px;
  padding: 17px 15px;
  position: relative;
}
.reSonUpFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom12 {
  margin-bottom: 12px;
}
.reSonCenter {
  display: flex;
  align-items: center;
}
.reSonIcon {
  width: 25px;
  margin-right: 10px;
}
.reSonTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
}
.reSonUpFileImg {
  width: 24px;
  cursor: pointer;
}
.pa {
  position: relative;
  margin-left: 32px;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.flexItemReson {
  display: flex;
  margin: 0px 0px 12px 0px;
  align-content: flex-start;
  justify-content: flex-start;
  overflow: auto; /* 使div可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
  -ms-overflow-style: none;
  position: relative;
  margin-top: 10px;
}
.resonMargin {
  margin-right: 12px;
}
.qDiv {
  display: flex;
  justify-content: flex-end;
  margin-left: 110px;
  position: relative;
}

.wid19 {
  width: 19px;
}
.sqlDiv {
  background-color: #fefefe;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(219, 223, 255, 0.5);
  padding: 12px 20px;
  margin-top: 10px;
  cursor: default;
  position: relative;
  margin-left: 32px;
}
.sqlCx {
  width: 12px;
  margin-right: 12px;
}
.sqlText {
  color: #898989;
  font-size: 16px;
  font-weight: 400;
  max-width: calc(100% - 130px);
  overflow: auto;
}
.sqlCxDiv {
  background-color: #fefefe;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  box-shadow: 1px 2px 8px 0px rgba(204, 206, 229, 0.25);
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 400;
  padding: 8px 16px;
  cursor: pointer;
}
.flexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom20 {
  margin-bottom: 20px;
}
</style>
