<template>
  <div class="fileItem">
    <img class="icon" :src="getResonFileImg(props.fileItem)" alt="" />
    <div class="flexDiv">
      <div class="flexCenter">
        <el-tooltip popper-class="toolTip" :content="props.fileItem.fileName">
          <div class="fileName">{{ props.fileItem.fileName }}</div>
        </el-tooltip>
        <img
          class="wid20"
          :src="icon.time"
          alt=""
          v-if="props.fileItem.importantFlag == 1"
        />
      </div>
      <div class="flexBetween">
        <div class="fileTip">
          {{ props.fileItem.fileType }}
          {{ formatFileSize(props.fileItem.fileSize) }}
        </div>
        <!-- <div class="zwf">1</div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { formatFileSize, getResonFileImg } from "@/utils/file";
const props = defineProps(["fileItem"]);
</script>

<style lang="scss" scoped>
.fileItem {
  display: flex;
  align-items: center;
  background-color: #fefefe;
  border-radius: 20px;
  padding: 11px 23px 10px 25px;
  height: 57px;
  width: 212px;
  box-shadow: 0px 0px 5px 0px #dbdfff7d;
}
.icon {
  width: 31px;
  margin-right: 16px;
}
.fileName {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 600;
  width: calc(100% - 31px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid20 {
  width: 20px;
  margin: 0px 0px 0px 11px;
}
.fileTip {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 400;
}
.zwf {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d9d9d9;
  font-size: 13px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  line-height: 18px;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 165px;
}
.flexCenter {
  display: flex;
  align-items: center;
  width: 165px;
}
.flexDiv {
  height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
