<template>
  <div class="addContent">
    <div class="flexBetween">
      <div class="flexCenter">
        <img :src="icon.fileDesc" class="fileDesc" alt="" />
        <div class="descTitle">
          {{ props.type == "file" ? t("gpt.index74") : t("gpt.index73") }}
        </div>
      </div>
      <img
        :src="icon.closeFileView"
        alt=""
        class="closeFileView"
        @click="closeDesc"
      />
    </div>
    <div class="bottomDiv">
      <div class="flexCenter bottom23">
        <img :src="icon.excelModel" alt="" class="excelModel marginRight6" />
        <div class="excelName">{{ props.excelDesc.knowledge_name }}</div>
        <div class="excelName margin6">/</div>
        <div class="excelName">{{ props.excelDesc.fileName }}</div>
      </div>
      <div class="bottomText">
        {{ props.fileDesc }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["type", "excelDesc", "fileDesc"]);
const emit = defineEmits(["closeDesc"]);
const closeDesc = () => {
  emit("closeDesc");
};
</script>

<style lang="scss" scoped>
.bottomText {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: 500;
}
.bottom23 {
  margin-bottom: 23px;
}
.marginRight6 {
  margin-right: 6px;
}
.excelModel {
  width: 24px;
}
.margin6 {
  margin: 0px 6px;
}
.excelName {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 400;
}
.bottomDiv {
  max-height: 213px;
  overflow: auto;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.descTitle {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.addContent {
  position: absolute;
  width: 556px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 111;
  padding: 22px;
  border-radius: 28px;
  box-shadow: 1px 2px 8px 0px #edeef6;
}
.fileDesc {
  width: 28px;
  margin-right: 6px;
}
.closeFileView {
  width: 14px;
  cursor: pointer;
}
</style>
