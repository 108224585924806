<template>
  <div class="plugAdd" :class="step == 2 ? '' : 'height760'">
    <!-- 标题 -->
    <div class="titleFlex">
      <div class="addTitle">
        {{ t("bot.base126") }}
      </div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeAdd" />
    </div>
    <!-- 步骤 -->
    <stepView :list="stepList" :step="step"></stepView>
    <div class="topDiv" v-if="step == 1">
      <!-- 名称 -->
      <div class="flex">
        <div class="inputDiv">
          <div class="name">{{ t("bot.bot28") }}</div>
          <el-input
            class="plugInput"
            :placeholder="t('bot.base144')"
            v-model="form.agentName"
            show-word-limit
            maxlength="100"
          ></el-input>
        </div>
        <uploadIndex @beforeAvatarUpload="beforeAvatarUpload" type="img">
          <img :src="getImg" alt="" class="plugUpload" />
        </uploadIndex>
      </div>
      <!-- Schema * -->
      <div class="flexBetween">
        <div class="name">Schema</div>
        <div class="flexCenter">
          <div class="pos">
            <div class="urlBtn" @click.stop="openUrl">
              <img class="plugAddImg" :src="icon.plugAdd" alt="" />
              <div class="urlAdd">{{ t("bot.base145") }}</div>
            </div>
            <div class="urlDiv" v-show="urlType" @click.stop="">
              <el-input
                placeholder="https://"
                class="urlInputModel"
                v-model="url"
              ></el-input>
              <el-button
                :loading="urlLoading"
                class="importBtn"
                @click.stop="importUrl"
                >{{ t("bot.base172") }}</el-button
              >
            </div>
          </div>
          <div class="urlAdd urlBtn" @click="setTemplate">
            {{ t("bot.base146") }}
          </div>
        </div>
      </div>
      <textarea
        class="urlInput"
        @change="changeSchema"
        v-model="form.swaggerDescription"
      ></textarea>
      <!-- 可用工具 -->
      <template v-if="tableData.length != 0">
        <div class="names">{{ t("bot.base147") }}</div>
        <el-table :data="tableData" class="toolTable">
          <el-table-column prop="functionName" :label="t('bot.bot28')" />
          <el-table-column prop="description" :label="t('bot.base148')" />
          <el-table-column prop="methods" :label="t('bot.base149')" />
          <el-table-column prop="path" :label="t('bot.base150')" />
          <el-table-column :label="t('bot.bot39')">
            <template #default="scope">
              <div class="testBtn" @click="openTest(scope.row)">
                {{ t("bot.base136") }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 鉴权方法 -->
      <div class="names">{{ t("bot.base131") }}</div>
      <div class="optionDiv">
        <div class="optionText">
          {{ form.authorizationHas == 0 ? t("bot.base132") : "API Key" }}
        </div>
        <img :src="icon.botOption" class="botOption" alt="" @click="openAt" />
      </div>
    </div>
    <!-- 成员 -->
    <div v-if="step == 2">
      <div class="userFlex">
        <div class="names">{{ t("bot.base151") }}</div>
        <div class="addUser" @click="openUser">{{ t("bot.bot41") }}</div>
      </div>
      <el-row class="rightTable">
        <el-col :span="10" class="tableNmae">{{ t("base.base34") }}</el-col>
        <el-col :span="8" class="tableNmae">
          {{ t("base.base35") }}
          <!-- <el-tooltip popper-class="toolTip" raw-content content="暂无内容">
            <el-icon class="tipIcon"><Warning /></el-icon>
          </el-tooltip> -->
        </el-col>
        <el-col :span="6">{{ t("base.base36") }}</el-col>
      </el-row>
      <div class="maxHeight">
        <el-row
          class="tableItem"
          :class="index == form.shareUserIds.length - 1 ? 'tableBottom' : ''"
          v-for="(item, index) in form.shareUserIds"
          :key="item.id"
        >
          <el-col :span="10" class="flexCenter padding10" v-if="item.name">
            <div class="userImg" :style="'background-color:' + item.color">
              {{ item.name?.slice(0, 1) }}
            </div>
            <span class="tableTitleNmae">{{ item.name }}</span>
          </el-col>
          <el-col :span="10" class="flexCenter padding10" v-else>
            <span class="tableTitleNmae">-</span>
          </el-col>
          <el-col :span="8" class="padding10">
            <div class="tableRoleType" v-if="item.type == 0">
              <div class="tableLogo">{{ t("base.base37").slice(0, 1) }}</div>
              <div class="tableRole">{{ t("base.base38") }}</div>
            </div>
            <div class="tableRole" v-if="item.type == 1">
              {{ t("base.base41") }}
            </div>
          </el-col>
          <el-col :span="6" class="flexCenter">
            <el-button
              class="deleUserBtn"
              @click="deleteUser(item, index)"
              v-if="item.type != 0"
            >
              {{ t("base.base42") }}
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 按钮 -->
    <div class="fooderDiv">
      <el-button class="cannel" @click="closeAdd">{{
        t("bot.bot48")
      }}</el-button>
      <el-button class="saveBtn" v-if="step == 2" @click="changeStep(1)">{{
        t("base.base337")
      }}</el-button>
      <el-button
        class="saveBtn"
        v-if="step == 1"
        :disabled="nextStepDisabled"
        @click="changeStep(2)"
        >{{ t("login.login11") }}</el-button
      >
      <el-button
        class="saveBtn"
        :disabled="saveDisabled"
        :loading="props.toolLoading"
        v-if="step == 2"
        @click="plugAddFn"
        >{{ t("base.base46") }}</el-button
      >
    </div>
  </div>
  <div class="mask"></div>
  <userView
    v-if="addDialog"
    type="bot"
    :userList="userList"
    :userObj="userObj"
    @closeUser="addDialog = false"
    @saveUser="saveUser"
  ></userView>
  <!-- 测试弹窗 -->
  <testView
    v-if="testType"
    @closeTest="closeTest"
    :form="form"
    :testObj="testObj"
  ></testView>
  <!-- 鉴权 -->
  <atView
    v-if="atType"
    @closeAt="closeAt"
    @saveAt="saveAt"
    :form="form"
  ></atView>
</template>

<script setup>
import { icon } from "@/utils/icon";
import uploadIndex from "@/components/upload/index.vue";
import testView from "./testView.vue";
import atView from "./atView.vue";
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import userView from "@/views/base/user.vue";
import stepView from "@/components/step/index.vue";
const props = defineProps(["toolObj", "toolLoading"]);
const emit = defineEmits(["closeAdd", "plugAddFn", "saveTool"]);
import { useStore } from "vuex";
import api from "@/api";
const nullTemplate = `{
      "openapi": "3.1.0",
      "info": {
        "title": "Untitled",
        "description": "Your OpenAPI specification",
        "version": "v1.0.0"
      },
      "servers": [
        {
          "url": ""
        }
      ],
      "paths": {},
      "components": {
        "schemas": {}
      }
    }`;
const store = useStore();
const form = ref({
  userId: store.state.userId,
  shareUserIds: [],
  agentName: "",
  pictureId: "",
  swaggerDescription: "",
  authorizationHas: 0,
  authorizationName: "Authorization",
  authorizationValue: null,
  authorizationType: null,
});
const userId = ref(store.state.userId);
const tableData = ref([]);
const stepList = ref([
  {
    name: t("bot.base152"),
    value: 1,
    tip: t("bot.base153"),
  },
  {
    name: t("bot.base154"),
    value: 2,
    tip: t("bot.base155"),
  },
]);
// 生命周期
onMounted(() => {
  document.addEventListener("click", handleOutsideClick);
  // 新增设置本人为主创
  form.value.shareUserIds.push({
    userId: userId.value,
    id: userId.value,
    type: 0, //0 拥有者 1 创作者 2 普通用户
    userName: store.state.userName,
    name: store.state.userName,
    ownerType: 0, //0个人 1部门 2群组
    color: store.state.color,
  });
});
// 保存健全
const saveAt = (obj) => {
  form.value = { ...form.value, ...obj };
  atType.value = false;
};
// 监听点击事件，点击页面其他地方时关闭弹出框
const handleOutsideClick = (event) => {
  if (urlType.value && !event.target.closest(".urlDiv")) {
    urlType.value = false;
  }
};
onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick);
});
// 打开url导入
const urlType = ref(false);
const openUrl = () => {
  urlType.value = !urlType.value;
};
// 导入url
const url = ref("");
const urlLoading = ref(false);
const importUrl = () => {
  tableData.value = [];
  urlLoading.value = true;
  api.bot
    .urlDown({
      userId: userId.value,
      url: url.value,
    })
    .then((res) => {
      urlLoading.value = false;
      if (res.returnCode == 200) {
        form.value.swaggerDescription = res.data;
        urlType.value = false;
      }
    });
};
// 插入空白模板
const setTemplate = () => {
  tableData.value = [];
  form.value.swaggerDescription = nullTemplate;
};
// 获取头像
const getImg = computed(() => {
  if (form.value.pictureId) {
    return "/openai/chat/v4/image/get/" + form.value.pictureId;
  } else {
    return icon.plugUpload;
  }
});
// 下一步判断
const nextStepDisabled = computed(() => {
  if (
    form.value.agentName &&
    form.value.swaggerDescription &&
    tableData.value.length != 0
  ) {
    return false;
  } else {
    return true;
  }
});
// 保存
const saveDisabled = computed(() => {
  if (form.value.shareUserIds.length != 0) {
    return false;
  } else {
    return false;
  }
});
// 保存
const plugAddFn = () => {
  let newForm = JSON.parse(JSON.stringify(form.value));
  newForm.shareUserIds = newForm.shareUserIds.map((item) => {
    return {
      id: item.id || item.ownerId,
      name: item.name,
      type: item.type,
      ownerType: item.ownerType,
      color: item.color,
    };
  });
  emit("plugAddFn", newForm);
};
// scema
const changeSchema = () => {
  tableData.value = [];
  api.bot
    .toolSwaggerParse({
      userId: userId.value,
      swaggerDescription: form.value.swaggerDescription,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        tableData.value = res.data;
      }
    });
};
// 打开鉴权
const atType = ref(false);
const openAt = () => {
  atType.value = true;
};
// 关闭鉴权
const closeAt = () => {
  atType.value = false;
};
// 打开测试
const testType = ref(false);
const testObj = ref({
  functionName: "",
  swaggerDescription: "",
  parameters: [],
  userId: userId.value,
});
const openTest = (row) => {
  testObj.value.functionName = row.functionName;
  testObj.value.swaggerDescription = form.value.swaggerDescription;
  testObj.value.parameters = row.functionParameter;
  testType.value = true;
};
// 关闭测试
const closeTest = () => {
  testType.value = false;
};
// 微模型上传头像
const beforeAvatarUpload = (list) => {
  api.base.imgUpLoad(list, "").then((res) => {
    if (res.returnCode == 200) {
      fetch(`/openai/chat/v4/image/get/${res.data}`)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          form.value.pictureId = res.data;
        })
        .catch((error) => console.log(error));
    }
  });
};
// 保存用户添加
// 确定保存
const saveUser = (arr) => {
  const index = arr.findIndex((user) => user.id === userId.value);
  if (index == -1) {
    arr.unshift({
      userId: userId.value,
      id: userId.value,
      type: 0, //0 拥有者 1 创作者 2 普通用户
      userName: store.state.userName,
      name: store.state.userName,
      ownerType: 0, //0个人 1部门 2群组
      color: store.state.color,
    });
  }
  // 去重
  const seenIds = new Set();
  form.value.shareUserIds = arr.filter((item) => {
    const id = item.id;
    if (!seenIds.has(id)) {
      seenIds.add(id);
      return true;
    }
    return false;
  });
  addDialog.value = false;
};
// 打开添加成员
const addDialog = ref(false);
const userList = ref([]);
const userObj = ref({});
const openUser = () => {
  // 判断部门 群组  人
  // 使用reduce方法将ownerType为0，1，2的对象分别筛选出来
  const result = form.value.shareUserIds.reduce((acc, item) => {
    const { ownerType } = item;
    if (!acc[ownerType]) {
      acc[ownerType] = [];
    }
    if (item.ownerType == 0) {
      item.userId = Number(item.id);
    }
    if (item.ownerType == 1) {
      item.departmentId = Number(item.id);
    }
    if (item.ownerType == 2) {
      item.groupId = item.id;
    }
    acc[ownerType].push(item);
    return acc;
  }, {});

  // 将结果转换为数组
  userList.value.userList = result[0] || [];
  userList.value.departmentList = result[1] || [];
  userList.value.gropuList = result[2] || [];
  userObj.value = userList.value.userList.filter((item) => item.type == 0);
  addDialog.value = true;
};
// 切换步骤
const step = ref(1);
const changeStep = (index) => {
  step.value = index;
};
// 关闭
const closeAdd = () => {
  emit("closeAdd");
};
// 移除成员
const deleteUser = (item, index) => {
  form.value.shareUserIds.splice(index, 1);
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.plugAdd {
  width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 111;
  padding: 30px;
  border-radius: 30px;
}
.height760 {
  height: 760px;
}
.maxHeight {
  max-height: 500px;
  overflow: auto;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 110;
  opacity: 0.3;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.plugUpload {
  width: 90px;
  margin-left: 18px;
  border-radius: 16px;
}
.flex {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
}
.name {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.names {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}
.plugInput {
  :deep(.el-input__wrapper) {
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 0px;
    height: 44px;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 17px;
  }
}
.name::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  margin-left: 5px;
}
.inputDiv {
  width: calc(100% - 118px);
}
.urlAdd {
  font-size: 16px;
  color: #696969;
  font-weight: 500;
}
.plugAddImg {
  width: 12px;
  margin-right: 6px;
}
.urlBtn {
  display: flex;
  border: 1px solid #edeef6;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 4px 0px #edeef6;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 9px;
  position: relative;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.urlInput {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-top: 17px;
  padding: 11px 20px;
  font-size: 16px;
  font-weight: 400;
  height: 150px;
  resize: none;
  outline: none;
  overflow: auto;
  margin-bottom: 24px;
}
.toolTable {
  width: 100%;
  border-radius: 12px;
  margin-top: 17px;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  :deep(.el-table thead) {
    background-color: #f6f6f6;
  }
}
.testBtn {
  border: 1px solid #d9d9d9;
  box-shadow: 1px 1px 4px 0px rgba(212, 212, 212, 0.25);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 10px;
  display: inline-block;
  cursor: pointer;
}
.botOption {
  width: 24px;
  cursor: pointer;
}
.optionDiv {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin-top: 17px;
}
.optionText {
  width: calc(100% - 24px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.cannel {
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  padding: 11px 20px;
  color: #f02d63;
  font-size: 18px;
  font-weight: 400;
  height: 48px;
}
.saveBtn {
  border: 1px solid #3376ff;
  border-radius: 16px;
  padding: 11px 20px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  height: 48px;
  background-color: #3376ff;
}
.fooderDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}
.topDiv {
  height: calc(100% - 151px);
  overflow: auto;
}
.addUser {
  border-radius: 8px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 16px;
  cursor: pointer;
}
.userFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 13px;
}
.rightTable {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #8a8a8a;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 12px 12px 0px 0px;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  padding-left: 10px;
}
.tipIcon {
  margin-left: 10px;
  font-size: 16px;
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border: 1px solid #d9d9d9;
  border-top: 0px;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableBottom {
  border-radius: 0px 0px 12px 12px;
}
.marginBtomm20px {
  margin-bottom: 12px;
}
.padding10 {
  padding-left: 10px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-weight: 400;
  margin-left: 10px;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-weight: 400;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  margin-right: 6px;
}
.urlDiv {
  position: absolute;
  background-color: #fefefe;
  border: 1px solid #edeef6;
  border-radius: 16px;
  width: 360px;
  height: 59px;
  bottom: -65px;
  right: 0;
  box-shadow: 0px 2px 4px 0px #edeef6;
  padding: 12px;
  display: flex;
  align-items: center;
}
.pos {
  position: relative;
}
.importBtn {
  background-color: #3376ff;
  border: 0px;
  box-shadow: 0px 0px 0px 0px;
  border-radius: 8px;
  color: #fefefe;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 12px;
  margin-left: 6px;
}
.urlInputModel {
  :deep(.el-input__wrapper) {
    background-color: #f6f6f6;
    border: 0px;
    box-shadow: 0px 0px 0px 0px;
    border-radius: 8px;
    height: 17px;
    font-size: 14px;
    font-weight: 400;
    padding: 9px 12px;
  }
}
</style>
