<script setup>
import { icon } from "@/utils/icon";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emit = defineEmits(["handleCancel", "handleAdd"]);
const props = defineProps({
  showDetail: Boolean,
  detailInfo: Object,
});
const show = ref(props.showDetail);
watch(
  () => props.showDetail,
  (newVal) => {
    show.value = newVal;
  }
);
const form = ref(props.detailInfo);
watch(
  () => props.detailInfo,
  (newVal) => {
    form.value = newVal;
  }
);
const title = ref(t("manage.userForm.user62"));
// 拒绝
const handleCancel = () => {
  show.value = !show.value;
  emit("handleCancel");
};
//同意
const handleConfirm = () => {
  show.value = !show.value;
  form.value.groupIds = form.value.groups.map((item) => item.groupId);
  emit("handleAdd", form.value);
};
</script>

<template>
  <div>
    <!-- 用户详情 -->
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ title }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div class="content">
        <el-divider />
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex; width: 100%">
            <div class="avatar" :style="'background-color:' + form.color">
              {{ form.userName.slice(0, 1) }}
            </div>
            <div class="name">{{ form.userName }}</div>
            <!-- 启用 -->
            <div class="tag" v-if="form.isActive == true">
              <div class="tag-icon">
                <img :src="icon.dui" alt="" />
              </div>
              <div class="tag-text">{{ t("manage.userForm.user52") }}</div>
            </div>
            <!-- 禁用 -->
            <div class="tag_cuo" v-else>
              <div class="tag-icon">
                <img :src="icon.cuo" alt="" />
              </div>
              <div class="tag-text">{{ t("manage.userForm.user53") }}</div>
            </div>
          </div>
        </div>
        <el-form :model="form" class="formDetail">
          <el-form-item prop="name">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.userName") }}</p>
              <p class="comtent">{{ form.userName }}</p>
            </div>
          </el-form-item>
          <el-form-item prop="email">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.email") }}</p>
              <p class="comtent">{{ form.email }}</p>
            </div>
          </el-form-item>
          <el-form-item prop="phone">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.phone") }}</p>
              <p class="comtent" v-if="form.phone == ''">-</p>
              <p class="comtent" v-else>{{ form.phone }}</p>
            </div>
          </el-form-item>
          <el-form-item prop="address">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.user2") }}</p>
              <div class="el-tag">
                <div class="tag-img">
                  <img :src="icon.qincheng" alt="" />
                </div>
                <div>{{ form.departmentName }}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="address">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.user60") }}</p>
              <div v-if="form.groups.length == 0">
                <div>-</div>
              </div>
              <div
                class="el-tag"
                v-else
                v-for="(item, index) in form.groups"
                :key="index"
              >
                <div class="tag-img">
                  <img :src="icon.user_yellow" alt="" />
                </div>
                <div>{{ item.groupName }}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="roleName">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.user61") }}</p>
              <p class="comtent">{{ form.roleName }}</p>
            </div>
          </el-form-item>
          <el-form-item prop="roleName">
            <div class="form-name">
              <p class="tit">{{ t("manage.userForm.sex") }}</p>
              <p class="comtent">
                {{
                  form.sex == null
                    ? "暂无信息"
                    : form.sex == 1
                    ? t("manage.userForm.user55")
                    : t("manage.userForm.user54")
                }}
              </p>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleCancel" class="cancel">
            {{ t("manage.userForm.user24") }}
          </el-button>
          <el-button
            :disabled="$hasPermission('10007') == false"
            @click="handleConfirm"
            class="confirm"
            >{{ t("manage.userForm.user63") }}</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  // height: 800px;
  padding: 30px;
}

:deep(.el-divider) {
  margin: 1px 0 10px !important;
  width: calc(100%) !important;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}

.close-icon {
  font-size: 20px;
}

.formDetail {
  margin-top: 20px;
}

.content {
  font-size: 16px;
  color: #8a8a8a;

  .name {
    color: #3d3d3d;
    font-size: 22px;
    margin: auto 8px auto 12px;
    max-width: calc(100% - 150px);
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 6px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    font-size: 34px;
    font-weight: 600;
  }

  .tag {
    display: flex;
    justify-content: space-between;
    background-color: #b8e6cd;
    color: #14ae5c;
    padding: 0px 4px;
    height: 26px;
    line-height: 26px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 16px;
      height: 16px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .tag_cuo {
    display: flex;
    justify-content: space-between;
    background-color: #fbd4df;
    color: #f02d63;
    padding: 0px 4px;
    height: 26px;
    line-height: 26px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 16px;
      height: 16px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .reset {
    color: #0256ff;
    margin: auto 10px;
  }
}

.form {
  margin-top: 10px;
}

.form-name {
  font-size: 18px;
  color: #3d3d3d;

  .el-tag {
    display: flex;
    justify-content: left;
    min-width: 100px;
    padding: 2px 18px 2px 2px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 8px;

    .tag-img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .tit {
    margin-bottom: 2px;
    font-weight: 600;
  }

  .comtent {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
  }
}
</style>
