<template>
  <div class="releaseDiv" @click.stop="">
    <div class="flexBetween">
      <div class="dialogTitle">{{ props.title }}</div>
      <img class="closeImg" :src="icon.closeFileView" alt="" @click="cancel" />
    </div>
    <div class="dialogTip" v-if="!props.markDown">
      {{ props.tip }}
    </div>
    <div class="dialogTip" v-else>
      <messageView :message="props.tip"></messageView>
    </div>
    <div class="flexEnd">
      <el-button class="cancelBtn" @click="cancel" v-if="props.cancel">{{
        props.cancel
      }}</el-button>
      <el-button class="okBtn" @click="ok">{{ props.ok }}</el-button>
    </div>
  </div>
  <div class="mask" v-if="props.maskType"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import messageView from "@/components/message/index.vue";
const props = defineProps([
  "title",
  "tip",
  "cancel",
  "ok",
  "maskType",
  "markDown",
]);
const emit = defineEmits(["cancel", "ok"]);
const cancel = () => {
  emit("cancel");
};
const ok = () => {
  emit("ok");
};
</script>

<style lang="scss" scoped>
.releaseDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  z-index: 1000;
  padding: 30px;
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  z-index: 999;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-weight: 500;
}
.closeImg {
  width: 15px;
  cursor: pointer;
}
.dialogTip {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 500;
  margin: 30px 0px;
  max-height: 400px;
  overflow: auto;
}
.cancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  color: #f02d63;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 400;
}
.okBtn {
  height: 48px;
  background-color: #3376ff;
  border-radius: 16px;
  border: 2px solid #3376ff;
  color: #fefefe;
  font-weight: 400;
  font-size: 20px;
}
</style>
