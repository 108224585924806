<script setup>
import detailView from "../components/groupDetail.vue";
import addView from "../components/groupAdd.vue";
import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { ElMessageBox } from "element-plus";
import { icon } from "@/utils/icon";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const loading = ref(true);
const loading2 = ref(false);
onMounted(() => {
  listForm.value.pageId = 1;
  getGroupList();
});
// 群组查询
const listData = ref({
  userId: userId.value,
  search: "",
});
watch(
  () => listData.value.search,
  (newVal) => {
    listData.value.search = newVal;
    listForm.value.pageId = 1;
    getGroupList();
  }
);
// 左侧树形
const groupNameNew = ref("");
const groupList = ref([]);
const number = ref(0);
const getGroupList = () => {
  api.manage.queryGroupList(listData.value).then((res) => {
    if (res.returnCode == 200) {
      groupList.value = res.data;
      groupNameNew.value = res.data[0]?.groupName;
      listForm.value.groupId = res.data[0]?.groupId;
      getGroupData();
      loading.value = false;
    }
  });
};
// 左侧点击
const currentNodeId = ref(0);
const handleNodeClick = (data) => {
  listForm.value.pageId = 1;
  currentNodeId.value = data.groupId;
  groupNameNew.value = data.groupName;
};
watch(
  () => currentNodeId.value,
  (newVal) => {
    listForm.value.groupId = newVal;
    getGroupData();
  }
);
// 分页 --table
const totalItems = ref(0);
const tableMember = ref([]);
const listForm = ref({
  pageId: 1,
  perPage: 10,
  userId: userId.value,
  groupId: currentNodeId.value,
});
// 部门组列表
const defaultProps = ref({
  label: "label",
});
// 请求右侧table
const getGroupData = () => {
  loading2.value = true;
  api.manage.getGroupTableApi(listForm.value).then((res) => {
    if (res.returnCode == 200) {
      totalItems.value = res.totalUserNum;
      tableMember.value = res.data.users;
      number.value = res.totalUserNum;
      loading2.value = false;
    }
  });
};

// 部门组收缩
const isAsideVisible = ref(true);
const toggleAside = () => {
  isAsideVisible.value = !isAsideVisible.value;
};

// 每页显示条数
const updatePageSize = (newSize) => {
  listForm.value.perPage = newSize;
  getGroupData();
};
// 更新当前页码
const updateCurrentPage = (newPage) => {
  listForm.value.pageId = newPage;
  getGroupData();
};

// 单个移除
const handleClick = (data) => {
  api.manage
    .deleteGroupApi(
      {
        userIdList: [data.userId],
        userId: userId.value,
      },
      listForm.value.groupId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.groupForm.group1"));
        listForm.value.pageId = 1;
        getGroupData();
      }
    });
};
// 多选 -- 批量移除
const ids = ref([]);
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
  ids.value = val.map((item) => item.userId);
};
const removeGroup = () => {
  if (multipleSelection.value.length == 0) {
    message.error(t("manage.groupForm.group2"));
  } else {
    console.log(ids.value);
    ElMessageBox.confirm(
      t("manage.groupForm.group3"),
      t("manage.userForm.user75"),
      {
        confirmButtonText: t("manage.userForm.user38"),
        cancelButtonText: t("manage.userForm.user37"),
        type: "warning",
      }
    )
      .then(() => {
        api.manage
          .deleteGroupApi(
            {
              userIdList: ids.value,
              userId: userId.value,
            },
            listForm.value.groupId
          )
          .then((res) => {
            if (res.returnCode == 200) {
              message.success(t("manage.groupForm.group1"));
              listForm.value.pageId = 1;
              getGroupData();
            }
          });
      })
      .catch(() => {
        message.info(t("manage.groupForm.group4"));
      });
  }
};
// 打开群组详情
const showDetail = ref(false);
const groupInfo = ref({});
const groupDetail = ref({});
const changeMore = (key, item) => {
  if (key === 1) {
    api.manage
      .getGroupInfoApi({
        groupId: item.groupId,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          groupDetail.value = res.data;
        }
      });
    showDetail.value = true;
  } else if (key === 2) {
    api.manage
      .getGroupTableApi({
        groupId: item.groupId,
        userId: userId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          groupInfo.value = res.data;
        }
      });
    showEdit.value = true;
    formType.value = "edit";
  } else if (key === 3) {
    // 删除群组
    ElMessageBox.confirm(
      t("manage.groupForm.group5"),
      t("manage.userForm.user75"),
      {
        confirmButtonText: t("manage.userForm.user38"),
        cancelButtonText: t("manage.userForm.user37"),
        type: "warning",
      }
    ).then(() => {
      api.manage
        .deleteGroupAllApi(
          {
            userId: userId.value,
          },
          item.groupId
        )
        .then((res) => {
          if (res.returnCode == 200) {
            message.success(t("manage.groupForm.group6"));
            getGroupList();
          }
        });
    });
  }
};
// 群组详情
const formType = ref("add");
const showEdit = ref(false);
const handleCancel = () => {
  showDetail.value = false;
};
const handleUpload = () => {
  showDetail.value = false;
  showEdit.value = true;
  formType.value = "edit";
  groupInfo.value = groupDetail.value;
};
// 编辑群组接口
const handleClose = () => {
  showEdit.value = false;
  // getGroupList();
};
const addGroup = () => {
  showEdit.value = true;
  formType.value = "add";
  groupInfo.value = {};
};
// 增加组用户
const userIdList = ref([]);
const ids2 = ref([]);
const showAddDialog = ref(false);
const addUserNum = () => {
  showAddDialog.value = true;
};
// 确定修改用户
const saveUser = (arr) => {
  ids2.value = arr.map((item) => item.userId);
  showAddDialog.value = false;
  api.manage
    .addGroupUserApi(
      {
        userId: userId.value,
        userIdList: ids2.value,
      },
      listForm.value.groupId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.groupForm.group7"));
        listForm.value.pageId = 1;
        getGroupData();
      }
    });
};
// 确认新增
const handleSave = (data) => {
  api.manage.addGroupApi(data).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.userForm.user78"));
      showEdit.value = false;
      getGroupList();
    } else {
      showEdit.value = false;
    }
  });
};
</script>

<template>
  <div class="main1">
    <el-container v-loading="loading">
      <div class="container">
        <!-- 群组搜索 -->
        <el-input
          v-model="listData.search"
          size="large"
          class="searchInput"
          :placeholder="$t('manage.groupForm.group8')"
          clearable
          v-if="isAsideVisible"
        >
          <template #prefix>
            <el-icon class="el-input__icon">
              <search />
            </el-icon>
          </template>
        </el-input>
        <el-aside class="side" v-if="isAsideVisible">
          <div class="treeDiv">
            <el-tree
              ref="treeRef"
              :data="groupList"
              node-key="currentNodeId"
              :props="defaultProps"
              default-expand-all
              highlight-current
              :current-node-key="currentNodeId"
            >
              <template #default="{ data }">
                <span class="custom-tree-node">
                  <div class="admin-name" @click="handleNodeClick(data)">
                    <img :src="icon.hover2" alt="" class="admin" />
                    <el-tooltip :content="data.groupName">
                      <span class="admin-text">{{ data.groupName }}</span>
                    </el-tooltip>
                  </div>
                  <span>
                    <el-dropdown
                      @command="changeMore($event, data)"
                      trigger="click"
                    >
                      <div class="moreIcon">
                        <img :src="icon.baseMore" alt="" />
                      </div>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            :command="1"
                            :disabled="$hasPermission('10013') == false"
                          >
                            <span class="dropText">{{
                              t("manage.groupForm.group9")
                            }}</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="2"
                            :disabled="$hasPermission('10015') == false"
                          >
                            <span class="dropText">{{
                              t("manage.groupForm.group10")
                            }}</span>
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="3"
                            :disabled="$hasPermission('10016') == false"
                          >
                            <span class="dropText">{{
                              t("manage.groupForm.group11")
                            }}</span>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </span>
                </span>
              </template>
            </el-tree>
          </div>
          <!-- 新建群组按钮-->
          <el-button
            class="addGroup"
            @click="addGroup"
            :disabled="$hasPermission('10014') == false"
          >
            <el-icon class="plus">
              <Plus />
            </el-icon>
            <div>{{ $t("manage.groupForm.group12") }}</div>
          </el-button>
        </el-aside>
        <div @click="toggleAside" class="toggleAside">
          <el-icon class="tagIcon">
            <component :is="isAsideVisible ? 'DArrowLeft' : 'DArrowRight'" />
          </el-icon>
        </div>
      </div>
      <el-main class="userMain">
        <div class="marin">
          <div class="dashboard">
            <div class="companyName">{{ groupNameNew }}</div>
          </div>
          <div class="search">
            <div>
              <div class="toolLeft">
                {{ $t("manage.groupForm.group13") }}
                <span class="num">{{ number }}</span>
              </div>
            </div>
            <!-- 功能区 -->
            <div style="display: flex">
              <el-button
                class="toolBox"
                style="color: #f02d63"
                @click="removeGroup"
                :disabled="$hasPermission('10016') == false"
              >
                {{ $t("manage.groupForm.group14") }}
              </el-button>
              <el-button
                class="toolBox addUser"
                @click="addUserNum"
                :disabled="$hasPermission('10014') == false"
                v-if="listForm.groupId"
              >
                {{ $t("manage.groupForm.group15") }}
              </el-button>
            </div>
          </div>
          <el-table
            v-loading="loading2"
            :data="tableMember"
            @selection-change="handleSelectionChange"
            class="tableHeight"
          >
            <el-table-column type="selection" />
            <el-table-column
              prop="userName"
              :label="$t('manage.userForm.userName')"
            />
            <el-table-column
              prop="email"
              :label="$t('manage.userForm.email')"
            />
            <el-table-column
              prop="departmentName"
              :label="$t('manage.userForm.departmentName')"
            />
            <el-table-column
              :label="$t('manage.userForm.operation')"
              class="operation"
            >
              <template #default="scope">
                <el-button
                  :disabled="$hasPermission('10016') == false"
                  link
                  type="primary"
                  class="remove"
                  @click="handleClick(scope.row)"
                >
                  {{ $t("manage.groupForm.group25") }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <Pagination
          :background="true"
          class="pagination"
          :currentPage="listForm.pageId"
          :pageSize="listForm.perPage"
          :total="totalItems"
          @size-change="updatePageSize"
          @current-change="updateCurrentPage"
        />
        <!-- 群组详情 -->
        <detailView
          :show="showDetail"
          :groupDetail="groupDetail"
          @handleCancel="handleCancel"
          @handleUpload="handleUpload"
        >
        </detailView>
        <!-- 群组编辑 -->
        <addView
          :show="showEdit"
          :formType="formType"
          :groupInfo="groupInfo"
          @handleClose="handleClose"
          @handleSave="handleSave"
        ></addView>
      </el-main>
    </el-container>
  </div>
  <!-- 增加组用户 -->
  <addUser
    v-if="showAddDialog"
    :show="showAddDialog"
    @closeUser="showAddDialog = false"
    @saveUser="saveUser"
    :userList="userIdList"
  >
  </addUser>
</template>

<style lang="scss" scoped>
@use "./group.scss" as group;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.side {
  :deep(.el-tree) {
    background-color: #f7f8ff;
    color: #3d3d3d;
  }

  :deep(.el-tree-node__content) {
    height: 50px;
    border-radius: 16px !important;

    &:hover {
      background-color: #eceeff;
    }
  }

  :deep(.custom-tree-node) {
    font-size: 16px;
  }
}

:deep(.el-table th.el-table__cell) {
  background-color: #f7f8ff;
}

:deep(.el-input__wrapper) {
  border-radius: 16px;
  margin-left: 6px;
  margin-right: 6px;
}

:deep(.el-container) {
  height: 100% !important;
}

:deep(.custom-tree-node) {
  font-size: 16px;
}

:deep(.el-checkbox) {
  height: 30px;
}

.searchItem {
  :deep(.el-select__wrapper) {
    border-radius: 16px !important;
    height: 44px;
    width: 160px !important;
    font-size: 16px !important;
  }
}
.marin {
  height: calc(100% - 65px);
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  padding-left: 10px;
}
.treeDiv {
  height: calc(100% - 70px);
  overflow: auto;
}
</style>
