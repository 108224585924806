<template>
  <!-- 文件 -->
  <div class="listFlex">
    <img
      :src="icon.leftReson"
      alt=""
      class="leftResonIcon"
      v-if="props.fileList.length > 3"
      @click="changeLeftReson(fileListRef)"
    />
    <div ref="fileListRef" class="itemFlex">
      <div
        class="fileItem"
        v-for="(item, index) in props.fileList"
        :key="index"
      >
        <template v-if="!ifImg(item)">
          <img :src="getFileIcon(item)" alt="" class="fileImg" />
          <div class="fileRightDiv">
            <el-tooltip
              popper-class="toolTip"
              :content="
                item.name ||
                item.fileName ||
                item.folderName ||
                item.sessionName
              "
            >
              <div class="fileNmae">
                {{
                  item.name ||
                  item.fileName ||
                  item.folderName ||
                  item.sessionName
                }}
              </div>
            </el-tooltip>
            <el-tooltip
              popper-class="toolTip"
              :content="
                getFileType(item) + formatFileSize(item.fileSize || item.size)
              "
            >
              <div class="fileSize">
                {{ getFileType(item) }}
                {{ formatFileSize(item.fileSize || item.size) }}
              </div>
            </el-tooltip>
          </div>
          <img
            :src="icon.closePng"
            @click.stop="deleteFile(index)"
            alt=""
            class="closeIcon"
          />
        </template>
        <template v-if="ifImg(item)">
          <el-popover placement="top" popper-class="fileImgPopover">
            <template #reference>
              <img :src="getImgUrl(item)" alt="" class="fileImg" />
            </template>
            <img :src="getImgUrl(item)" alt="" class="hoverImg" />
          </el-popover>
          <div class="fileRightDiv">
            <el-tooltip popper-class="toolTip" :content="item.name">
              <div class="fileNmae">
                {{ item.name }}
              </div>
            </el-tooltip>
            <el-tooltip
              popper-class="toolTip"
              :content="
                getFileType(item) + formatFileSize(item.fileSize || item.size)
              "
            >
              <div class="fileSize">
                {{ getFileType(item) }}
                {{ formatFileSize(item.fileSize || item.size) }}
              </div>
            </el-tooltip>
          </div>
          <img
            :src="icon.closePng"
            alt=""
            class="closeIcon"
            @click.stop="deleteFile(index)"
          />
        </template>
      </div>
    </div>
    <img
      :src="icon.rightReson"
      alt=""
      class="rightResonIcon"
      v-if="props.fileList.length > 3"
      @click="changeRightReson(fileListRef)"
    />
  </div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import { getFileIcon, formatFileSize } from "@/utils/file";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { ref } from "vue";
const fileListRef = ref(null);
const props = defineProps(["fileList", "type"]);
const emit = defineEmits(["deleteFile"]);
// 判断是不是图片
const ifImg = (file) => {
  if (props.type == "chat" && file.name) {
    const type = file.name.substring(file.name.lastIndexOf(".") + 1);
    const fileType = type.toLowerCase().replace(" ", "");
    if (fileType == "png") {
      return true;
    } else if (fileType == "jpg") {
      return true;
    } else if (fileType == "gif") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// file转图片
const getImgUrl = (file) => {
  return URL.createObjectURL(file);
};
// 获取文件类型
const getFileType = (item) => {
  if (item.folderName) {
    return t("base.base264");
  }
  if (item.sessionName) {
    return t("base.base309");
  }
  let name = item.fileName || item.name;
  let fileType = name.substring(name.lastIndexOf(".") + 1);
  fileType = fileType.toLowerCase().replace(" ", "");
  return fileType;
};
//   删除文件
const deleteFile = (index) => {
  emit("deleteFile", index);
};
</script>

<style lang="scss" scoped>
.listFlex {
  margin-bottom: 5px;
  position: relative;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(25px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
    cursor: pointer;
  }
}
.itemFlex {
  display: flex;
  overflow: hidden;
}
.fileItem {
  width: 188px;
  min-width: 188px;
  height: 52px;
  background-color: #f7f8ff;
  border-radius: 20px;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  position: relative;
  display: flex;
  align-items: center;
}
.fileImg {
  width: 40px;
}
.fileRightDiv {
  margin-left: 13px;
  width: calc(100% - 41px);
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.fileNmae {
  font-size: 18px;
  font-weight: 500;
  color: #3d3d3d;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileSize {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 400;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.closeIcon {
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
  width: 15px;
}
.hoverImg {
  width: 100%;
}
</style>
