<template>
  <div class="chatContent">
    <!-- tag 以及gpt切换 -->
    <indexTagView
      :tagActive="tagActive"
      :model="model"
      :modelList="modelList"
      :copyModelList="copyModelList"
      :tagList="tagList"
      :macType="macType"
      :llmNav="llmNav"
      :llmConfigurationDict="llmConfigurationDict"
      type="bottom"
      @changeModel="changeModel"
      @handleSearch="handleSearch"
      @sendForm="sendForm"
    ></indexTagView>
    <!-- 选择后 -->
    <topTitle
      v-if="tagActive != null && tagList.length != 0"
      :tagActive="tagActive"
      :tagList="tagList"
      :wsLoading="wsLoading"
      :macType="macType"
      @changeBaseId="changeBaseId"
      @changeTemplateId="changeTemplateId"
      @closeTag="closeTag"
      @wsMessage="wsMessage"
      ref="topTitleRef"
    ></topTitle>
    <!-- 输入框 -->
    <div
      :class="tagActive == null ? 'chatInput' : 'chatInputActive'"
      class="pa"
    >
      <!-- 文件 -->
      <indexFileView
        v-if="fileList.length != 0"
        :fileList="fileList"
        :type="props.type"
        @deleteFile="deleteFile"
      ></indexFileView>
      <!-- 提示 -->
      <div class="tipFlex" v-if="fileList.length != 0">
        <tipCom
          v-for="item in messageList"
          :key="item"
          @click="saveMessage(item)"
        >
          <el-tooltip popper-class="toolTip" effect="dark" :content="item">
            {{ item }}
          </el-tooltip>
        </tipCom>
      </div>
      <!-- 输入框 -->
      <div
        :class="{
          inputDivHeight: tagActive == null,
          inputDivHeight100: tagActive != null,
        }"
        v-if="fileList.length == 0"
      >
        <el-mention
          v-model="question"
          type="textarea"
          :options="macType ? [] : tagList"
          :prefix="['@', '/']"
          :placeholder="
            tagList.length != 0 ? t('base.base261') : t('base.base318')
          "
          :autosize="{ minRows: 1, maxRows: 3 }"
          @select="handleSearch"
          @keydown="messageSendListen"
          @paste="onPasteUpload($event)"
          id="message"
        >
          <template #label="{ item }">
            <div class="tjDiv">
              <img class="tagItemImg" :src="item.img" alt="" />
              <div>{{ t(item.label) }}</div>
            </div>
          </template>
        </el-mention>
      </div>
      <div v-if="fileList.length != 0">
        <el-mention
          v-model="question"
          type="textarea"
          :options="macType ? [] : tagList"
          :prefix="['@', '/']"
          :placeholder="
            tagList.length != 0 ? t('base.base261') : t('base.base318')
          "
          :class="tagActive == null ? 'noTag' : 'acTag'"
          :autosize="
            tagActive != null
              ? { minRows: 1, maxRows: 1 }
              : { minRows: 2, maxRows: 2 }
          "
          @select="handleSearch"
          @keydown="messageSendListen"
          @paste="onPasteUpload($event)"
          id="message"
        >
          <template #label="{ item }">
            <div class="tjDiv">
              <img class="tagItemImg" :src="item.img" alt="" />
              <div>{{ t(item.label) }}</div>
            </div>
          </template>
        </el-mention>
      </div>
      <!-- 底部 -->
      <div class="inputBetween">
        <!-- 左侧正常上传 -->
        <div class="flexCenter bottomLeftDiv">
          <!-- 微模型选择文件 -->
          <img
            :src="icon.baseInput"
            alt=""
            class="baseInput"
            @click="openBaseFile"
            v-if="tagActive == 0 || props.type == 'base'"
          />
          <!-- 图片选择 -->
          <imgSel
            v-if="tagActive == 2"
            :model="model"
            @changebl="changebl"
            @changeqxd="changeqxd"
            @changeStyle="changeStyle"
          ></imgSel>
          <!-- 上传文件 -->
          <uploadIndex
            type="file"
            @beforeAvatarUpload="beforeAvatarUpload"
            v-if="(tagActive == null || tagActive == 1) && props.type != 'base'"
          >
            <img :src="icon.hImg" alt="" class="fileIcon" />
          </uploadIndex>
          <!-- 上传图片 -->
          <uploadIndex
            type="img"
            @beforeAvatarUpload="beforeAvatarUpload"
            v-if="
              (tagActive == null || tagActive == 1) &&
              props.type != 'base' &&
              (model == 'GPT-4o-mini' || model == 'GPT-4o')
            "
          >
            <img :src="icon.cImg" alt="" class="marginLeft10 fileIcon" />
          </uploadIndex>
          <!-- 联网搜索 -->
          <template v-if="ifIsInternetOn">
            <el-switch
              class="marginLeft10"
              v-model="isInternetOn"
              v-if="tagActive == null || tagActive == 0 || tagActive == 1"
            />
            <span
              class="marginLeft10 netWorkTitle"
              v-if="tagActive == null || tagActive == 0 || tagActive == 1"
              >{{ t("base.base226") }}</span
            >
          </template>
        </div>
        <!-- 右侧发送 以及深度 -->
        <div class="flexCenter sdDiv">
          <el-tooltip
            popper-class="toolTip"
            :content="t('gpt.index16')"
            v-if="getMacType"
          >
            <img :src="icon.mac" alt="" class="mac" @click="startMac" />
          </el-tooltip>
          <el-tooltip
            popper-class="toolTip"
            :content="t('gpt.index17')"
            v-if="macType"
          >
            <div class="stopMacDiv" @click="stopMac">
              <img :src="icon.stopMac" alt="" class="stopMac" />
            </div>
          </el-tooltip>
          <div
            class="flexCenter bottomItem"
            v-if="tagActive == 0 && knowledge_type != 3"
          >
            <div
              v-for="item in options"
              :key="item"
              @click="strategy = item.value"
              :class="{
                activeItem: strategy === item.value,
                activeItemHover: strategy != item.value,
              }"
              class="allCenter textNo width50"
            >
              <el-tooltip popper-class="toolTip" :content="t(item.tip)">
                <span class="noSpan">{{ t(item.label) }}</span>
              </el-tooltip>
            </div>
          </div>
          <div class="iconZwf" v-if="tagActive == 0 && knowledge_type != 3">
            |
          </div>
          <img
            :src="icon.sendPng"
            alt=""
            class="sendImg"
            v-if="question.length == 0"
          />
          <img
            :src="icon.sendYes"
            alt=""
            class="sendImg"
            v-else
            @click="send"
          />
        </div>
      </div>
    </div>
    <!-- 微模型选择文件 -->
    <baseFileView
      :saveList="fileList"
      :baseId="baseId"
      :type="true"
      v-if="baseFileDialog"
      @cancel="baseFileDialog = false"
      @ok="saveBase"
    ></baseFileView>
  </div>
</template>

<script setup>
import { throttle } from "@/utils/utils";
import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
const speechsdk = require("microsoft-cognitiveservices-speech-sdk");
import { icon } from "@/utils/icon";
import tipCom from "@/components/tip/index.vue";
import uploadIndex from "@/components/upload/index.vue";
import topTitle from "./topTitle.vue";
import indexTagView from "./indexTag.vue";
import indexFileView from "./indexFile.vue";
import { ref, onMounted, watch, nextTick, computed, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import baseFileView from "./baseFile.vue";
import imgSel from "./imgSel.vue";
import api from "@/api";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const emit = defineEmits(["changeFile", "closeChat", "handleSearch"]);
const props = defineProps(["bottomFileList", "type"]);
const options = [
  {
    label: "base.base202",
    tip: "login.login73",
    value: 0,
  },
  {
    label: "base.base191",
    tip: "login.login74",
    value: 1,
  },
  {
    label: "base.base192",
    tip: "login.login75",
    value: 2,
  },
]; //深度
const userId = ref(store.state.userId);
const model = ref(""); //选择的模型
const modelList = ref([]); //模型列表
const isInternetOn = ref(false); //联网搜索
const question = ref(""); //要发送的消息
const strategy = ref(0); //选择的深度值
const tagActive = ref(null); //默认选择值
const baseId = ref(null);
const templateId = ref(null);
const baseItem = ref(store.state.baseItem);
const baseDrapList = ref(store.state.baseDrapList);
const baseFileDialog = ref(false);
const messageList = ref([]);
const templateName = ref(null);
const libraryName = ref("");
const wsLoading = ref(false);
const imageSize = ref("");
const imageQuality = ref("");
const imageStyle = ref("");
const tagList = ref([
  {
    label: "base.base262",
    img: icon.nImg,
    icon: icon.inputBase,
    value: 0,
  },
  {
    label: "base.base263",
    img: icon.oImg,
    icon: icon.inputBot,
    value: 1,
  },
  {
    label: "base.base29",
    img: icon.iImg,
    icon: icon.inputImg,
    value: 2,
  },
]); //@的值
const fileList = ref([]); //文件列表
const timer = ref(null);
onMounted(() => {
  getMac();
  getModel();
  if (props.bottomFileList.length != 0) {
    fileList.value = props.bottomFileList;
  }
  if (props.type == "base") {
    tagList.value = [];
  } else {
    tagList.value = [
      {
        label: "base.base262",
        img: icon.nImg,
        icon: icon.inputBase,
        value: 0,
      },
      {
        label: "base.base263",
        img: icon.oImg,
        icon: icon.inputBot,
        value: 1,
      },
      {
        label: "base.base29",
        img: icon.iImg,
        icon: icon.inputImg,
        value: 2,
      },
    ];
  }
  getDefauleModel();
});
watch(
  () => fileList.value,
  (newValue, oldValue) => {
    // 过滤非图片文件和图片文件
    const nonImageFiles = fileList.value.filter(
      (item) => !["jpg", "png", "gif"].includes(item.fileType)
    );
    const imageFiles = fileList.value.filter((item) =>
      ["jpg", "png", "gif"].includes(item.fileType)
    );
    // 根据文件类型设置消息列表
    if (nonImageFiles.length > 0 && imageFiles.length === 0) {
      messageList.value = [
        "文档的主要内容是什么？",
        "理解并整理这篇文档，并输出结构清晰的总结",
      ];
    } else if (nonImageFiles.length === 0 && imageFiles.length > 0) {
      messageList.value = ["图片的主要内容是什么？", "帮我提取图片中的文字"];
    } else {
      messageList.value = [];
    }
  }
);
watch(
  () => store.state.baseItem,
  (newValue, oldValue) => {
    baseItem.value = newValue;
  }
);
watch(
  () => store.state.baseDrapList,
  (newValue, oldValue) => {
    baseDrapList.value = newValue;
  }
);
const llmConfigurationDict = ref({});
const getDefaulLlm = () => {
  if (tagActive.value == 2) return;
  api.gpt
    .llmGet({
      userId: userId.value,
      modelType: model.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        llmConfigurationDict.value = res.data.accurate;
      }
    });
};
const llmNav = ref(0);
const sendForm = (form, nav) => {
  llmNav.value = nav;
  llmConfigurationDict.value = form;
};
// 获取是否可以用mac
const getMacType = computed(() => {
  if (model.value) {
    if (!macType.value && model.value.includes("GPT") && speechKey.value) {
      return true;
    } else {
      return false;
    }
  }
});
// 判断权限
const speechKey = ref("");
const speechRegion = ref("");
const getMac = () => {
  api.chat.getVoice({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      if (res.data.length == 0) return;
      const azureRecord = res.data.find(
        (item) => item.platform_type === "Azure"
      );
      speechKey.value = azureRecord.api_key;
      speechRegion.value = azureRecord.region;
    }
  });
};
onBeforeMount(() => {
  question.value = "";
  macType.value = false;
  if (speechRecognizer.value) {
    speechRecognizer.value.stopContinuousRecognitionAsync();
    speechRecognizer.value = null;
  }
});
// 开始语音
const macType = ref(false);
const speechRecognizer = ref(null); //azure实例
const startMac = throttle(() => {
  macAzure();
}, 1000);
const stopMac = () => {
  macType.value = false;
  if (speechRecognizer.value) {
    speechRecognizer.value.stopContinuousRecognitionAsync();
    speechRecognizer.value = null;
  }
};
// azure录入
const macAzure = async () => {
  speechRecognizer.value = null;
  // 获取token
  const azureToken = await api.chat.getAzureToken({
    userId: userId.value,
    speechKey: speechKey.value,
    speechRegion: speechRegion.value,
  });
  const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
    azureToken.data,
    speechRegion.value
  );
  speechConfig.speechRecognitionLanguage = "zh-CN";
  const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
  speechRecognizer.value = new speechsdk.SpeechRecognizer(
    speechConfig,
    audioConfig
  );
  macType.value = true;
  speechRecognizer.value.startContinuousRecognitionAsync();
  speechRecognizer.value.recognized = (s, e) => {
    if (e.result.reason == ResultReason.RecognizedSpeech) {
      question.value += e.result.text;
    }
    if (e.result.reason == ResultReason.NoMatch) {
      if (speechRecognizer.value) {
        speechRecognizer.value.stopContinuousRecognitionAsync();
        speechRecognizer.value = null;
      }
      macType.value = false;
    }
  };
};
// 切换模型
const changeModel = (e) => {
  model.value = e;
  // 不等于4o 不让用图片
  if (e != "GPT-4o" && e != "GPT-4o-mini") {
    const nonImageFiles = fileList.value.filter(
      (item) => !["jpg", "png", "gif"].includes(item.fileType)
    );
    fileList.value = nonImageFiles;
  }
};
const changeStyle = (e) => {
  imageStyle.value = e;
};
const changeqxd = (e) => {
  imageQuality.value = e;
};
const changebl = (e) => {
  imageSize.value = e;
};
// 获取图像imglist
const getImgModel = () => {
  copyModelList.value = [];
  api.chat
    .imgModel({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        copyModelList.value = JSON.parse(JSON.stringify(res.data));
        modelList.value = groupedData(res.data);
        let type = copyModelList.value.some(
          (item) => item.model == model.value
        );
        if (!type) {
          model.value =
            copyModelList.value.length == 0 ? "" : copyModelList.value[0].model;
        }
      }
    });
};
// 修改接口
const groupedData = (data) => {
  const groups = data.reduce((result, item) => {
    if (!result[item.platform_type]) {
      result[item.platform_type] = {
        name: item.platform_type,
        models: [],
      };
    }
    result[item.platform_type].models.push(item);
    return result;
  }, {});
  return Object.values(groups);
};
// 获取模型列表
const copyModelList = ref([]);
const getModel = () => {
  copyModelList.value = [];
  api.chat
    .chatModel({
      userId: userId.value,
      knowledgeId: baseId.value ? baseId.value : null,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        copyModelList.value = JSON.parse(JSON.stringify(res.data));
        modelList.value = groupedData(res.data);
        getDefauleModel();
      }
    });
};
// 粘贴文件
const onPasteUpload = (event) => {
  if (
    props.type == "chat" &&
    (tagActive.value == null || tagActive.value == 1)
  ) {
    let items = event.clipboardData && event.clipboardData.items;
    let file = null;
    let fileList = [];
    const fileTypeList = [
      "txt",
      "pptx",
      "pdf",
      "docx",
      "xlsx",
      "html",
      "jpg",
      "png",
      "gif",
    ];
    if (items && items.length) {
      for (let key in items) {
        if (items[key].kind == "file") {
          file = items[key].getAsFile();
          file.fileType = file.name
            .substring(file.name.lastIndexOf(".") + 1)
            .toLowerCase()
            .replace(" ", "");
          if (!fileTypeList.includes(file.fileType)) {
            message.warning(file.name + t("base.base283"));
            return;
          }
          // 大小限制
          if (file.size > 20971520) {
            message.warning(file.name + t("base.base281"));
            return;
          }
          // 名称超长
          let fileNameLen = String(file.name);
          if (fileNameLen.length > 100) {
            message.warning(file.name + t("base.base282"));
            return;
          }
          fileList.push(file);
          clearTimeout(timer.value);
          timer.value = setTimeout(() => {
            beforeAvatarUpload(fileList);
          }, 100);
        }
      }
    }
  }
};
// 一键完善
const wsMessage = () => {
  wsLoading.value = true;
  api.chat
    .imgOptimize({
      userId: userId.value,
      message: question.value,
    })
    .then((res) => {
      wsLoading.value = false;
      if (res.returnCode == 200) {
        question.value = res.data;
      }
    })
    .then((res) => {
      wsLoading.value = false;
    });
};
// 点击文字直接发送
const saveMessage = (question) => {
  if (tagActive.value != 0 && props.type != "base") {
    let file = fileList.value.filter(
      (item) => !["jpg", "png", "gif"].includes(item.fileType)
    );
    let imgNumberList = fileList.value.filter((item) =>
      ["jpg", "png", "gif"].includes(item.fileType)
    );
    if (file.length > 3) {
      return message.warning(t("base.base300"));
    }
    if (imgNumberList.length > 3) {
      return message.warning(t("base.base301"));
    }
  }
  if (!model.value) {
    message.warning(t("base.base249"));
    return;
  }
  if (!question) {
    message.warning(t("base.base265"));
    return;
  }
  let libraryId;
  if (tagActive.value == 0) {
    libraryId = baseId.value;
  } else {
    libraryId = baseItem.value ? baseItem.value.knowledge_library_id : "";
    libraryName.value = baseItem.value ? baseItem.value.knowledge_name : "";
  }
  if (tagActive.value == 0 || props.type == "base") {
    api.base
      .addBase({
        userId: userId.value,
        sessionName: question.slice(0, 250),
        libraryId: libraryId,
        fileIds: baseDrapList.value.fileList ? baseDrapList.value.fileList : [],
        folderIds: baseDrapList.value.folderList
          ? baseDrapList.value.folderList
          : [],
        sessionId: baseDrapList.value.topicList
          ? baseDrapList.value.topicList
          : "",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          api.chat
            .chatDescQuery({
              userId: userId.value,
              sessionId: res.data,
              sessionType: 1,
            })
            .then((ress) => {
              if (ress.returnCode == 200) {
                let obj = {
                  userId: userId.value,
                  model: model.value,
                  message: question,
                  libraryId: libraryId,
                  isInternetOn: isInternetOn.value,
                  sessionId: res.data,
                  strategy: strategy.value,
                  regenerateFlag: 0,
                  internetSource: null,
                  conversationId: null,
                  inValid: false,
                  imageFlag: tagActive.value == 2 ? 1 : 0,
                  llmConfigurationDict: llmConfigurationDict.value,
                  llmNav: llmNav.value,
                };
                store.commit("SET_BASEDRAP", {});
                store.commit("SET_CHATTYPE", "base");
                store.commit("SET_TOPIC", ress.data);
                store.commit("SET_FILELIST", []);
                store.commit("SET_TALK", obj);
                router.push("/chat");
                store.commit("SET_CHATPARMAS", {
                  type: "chat",
                  isValid: 0,
                });
                emit("closeChat");
              }
            });
        }
      });
  } else {
    // 没有文件就新增
    api.chat
      .addChat({
        userId: userId.value,
        topicName: question.slice(0, 250),
        templateId: templateId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          api.chat
            .chatDescQuery({
              userId: userId.value,
              sessionId: res.data.table_id,
              sessionType: 2,
            })
            .then((ress) => {
              if (ress.returnCode == 200) {
                // 新增对话 不算base chat
                store.commit("SET_CHATTYPE", "chat");
                let obj = {
                  model: model.value,
                  message: question,
                  topicId: res.data.table_id,
                  knowledgeId: baseId.value,
                  userId: userId.value,
                  isInternetOn: isInternetOn.value,
                  regenerateFlag: 0,
                  conversationId: null,
                  internetResource: null,
                  strategy: strategy.value,
                  imageFlag: tagActive.value == 2 ? 1 : 0,
                  llmConfigurationDict: llmConfigurationDict.value,
                  llmNav: llmNav.value,
                };
                store.commit("SET_TALK", obj);
                store.commit("SET_FILELIST", fileList.value);
                store.commit("SET_TOPIC", ress.data);
                router.push("/chat");
                store.commit("SET_CHATPARMAS", {
                  type: "chat",
                  isValid: 0,
                });
                emit("closeChat");
              }
            });
        }
      });
  }
};
defineExpose({
  saveMessage,
});
// enter发送
// 发送消息
const messageSendListen = (event) => {
  if (event.keyCode === 13) {
    if (!event.altKey) {
      event.preventDefault();
      send(); // 发送文本
    } else {
      let textarea = document.getElementById("message");
      let start = textarea.selectionStart;
      let end = textarea.selectionEnd;
      let before = textarea.value.substring(0, start);
      let after = textarea.value.substring(end);
      question.value = before + "\n" + after;
      nextTick(() => {
        textarea.scrollTop = textarea.scrollHeight;
      });
    }
  }
};

// 保存微模型文件列表
const saveBase = (arr) => {
  let fileLists = [];
  let folderList = [];
  let topicList = [];
  arr.forEach((item) => {
    if (item.fileName) {
      fileLists.push(item.id);
    } else if (item.folderName) {
      folderList.push(item.id);
    } else if (item.sessionName) {
      topicList.push(item.sessionId);
    }
  });
  baseDrapList.value = { fileList: fileLists, folderList, topicList };
  fileList.value = arr;
  baseFileDialog.value = false;
  emit("changeFile", fileList.value);
};
// 打开微模型文件列表
const openBaseFile = () => {
  if (!baseId.value && tagActive.value == 0) {
    message.warning(t("login.login52"));
    return;
  }
  baseFileDialog.value = true;
};
// 关闭tag
const closeTag = () => {
  llmNav.value = 0;
  tagActive.value = null;
  baseId.value = "";
  templateId.value = "";
  fileList.value = [];
  knowledge_type.value = null;
  ifIsInternetOn.value = true;
  if (topTitleRef.value) {
    topTitleRef.value.changeTag();
  }
  getModel();
  emit("changeFile", fileList.value);
  emit("handleSearch", null);
};
// 获取默认model
const getDefauleModel = () => {
  api.chat.modelGet({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      let type = copyModelList.value.some((item) => item.model == res.data);
      if (type) {
        model.value = res.data;
        getDefaulLlm();
      } else {
        model.value = "";
      }
    }
  });
};
// 删除文件
const deleteFile = (index) => {
  fileList.value.splice(index, 1);
  let fileLists = [];
  let folderList = [];
  let topicList = [];
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileLists.push(item.id);
    } else if (item.folderName) {
      folderList.push(item.id);
    } else if (item.sessionName) {
      topicList.push(item.sessionId);
    }
  });
  baseDrapList.value = { fileList: fileLists, folderList, topicList };
};
const topTitleRef = ref(null);
const handleSearch = (e) => {
  question.value = "";
  // if (e.value == tagActive.value) return;
  knowledge_type.value = null;
  llmNav.value = 0;
  baseId.value = "";
  templateId.value = "";
  fileList.value = [];
  if (tagActive.value != e.value) {
    ifIsInternetOn.value = true;
  }
  isInternetOn.value = false;
  tagActive.value = e.value;
  if (topTitleRef.value) {
    topTitleRef.value.changeTag();
  }
  if (e.value == 2) {
    getImgModel();
  } else {
    getModel();
  }
  emit("handleSearch", e.value);
  emit("changeFile", fileList.value);
};
// 上传
const beforeAvatarUpload = (list) => {
  fileList.value = [...fileList.value, ...list];
  // 不等于4o 不让用图片
  if (model.value != "GPT-4o" && model.value != "GPT-4o-mini") {
    const nonImageFiles = fileList.value.filter(
      (item) => !["jpg", "png", "gif"].includes(item.fileType)
    );
    fileList.value = nonImageFiles;
  }
  emit("changeFile", fileList.value);
};
// 微模型选择
const knowledge_type = ref(null);
const changeBaseId = (id, list) => {
  knowledge_type.value = list[0].knowledge_type;
  if (knowledge_type.value == 3) {
    isInternetOn.value = false;
  }
  llmNav.value = 0;
  baseId.value = id;
  libraryName.value = list[0].name;
  fileList.value = [];
  if (list[0].knowledge_type == 3) {
    ifIsInternetOn.value = false;
  } else {
    ifIsInternetOn.value = true;
  }
  getModel();
};
// 智能体选择
const ifIsInternetOn = ref(true);
const changeTemplateId = (id, list) => {
  llmNav.value = 0;
  templateId.value = id;
  templateName.value = list[0].templateName;
  api.bot
    .templateDetail({
      userId: userId.value,
      templateId: templateId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        ifIsInternetOn.value = res.data.isInternetOn == 1 ? true : false;
      }
    });
};
// 发送
const send = async () => {
  if (macType.value) {
    message.warning(t("gpt.index76"));
    return;
  }
  if (tagActive.value != 0 && props.type != "base") {
    let file = fileList.value.filter(
      (item) => !["jpg", "png", "gif"].includes(item.fileType)
    );
    let imgNumberList = fileList.value.filter((item) =>
      ["jpg", "png", "gif"].includes(item.fileType)
    );
    if (file.length > 3) {
      return message.warning(t("base.base300"));
    }
    if (imgNumberList.length > 3) {
      return message.warning(t("base.base301"));
    }
  }
  if (!model.value) {
    message.warning(t("base.base249"));
    return;
  }
  if (!question.value) {
    message.warning(t("base.base265"));
    return;
  }
  let libraryId;
  if (tagActive.value == 0) {
    libraryId = baseId.value;
  } else {
    libraryId = baseItem.value ? baseItem.value.knowledge_library_id : "";
    libraryName.value = baseItem.value ? baseItem.value.knowledge_name : "";
  }
  if (tagActive.value == 0 || props.type == "base") {
    api.base
      .addBase({
        userId: userId.value,
        sessionName: question.value.slice(0, 250),
        libraryId: libraryId,
        fileIds: baseDrapList.value.fileList ? baseDrapList.value.fileList : [],
        folderIds: baseDrapList.value.folderList
          ? baseDrapList.value.folderList
          : [],
        sessionId: baseDrapList.value.topicList
          ? baseDrapList.value.topicList
          : "",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          api.chat
            .chatDescQuery({
              userId: userId.value,
              sessionId: res.data,
              sessionType: 1,
            })
            .then((ress) => {
              if (ress.returnCode == 200) {
                let obj = {
                  userId: userId.value,
                  model: model.value,
                  message: question.value,
                  libraryId: libraryId,
                  isInternetOn: isInternetOn.value,
                  sessionId: res.data,
                  strategy: strategy.value,
                  regenerateFlag: 0,
                  internetSource: null,
                  conversationId: null,
                  inValid: false,
                  imageFlag: tagActive.value == 2 ? 1 : 0,
                  llmConfigurationDict: llmConfigurationDict.value,
                  llmNav: llmNav.value,
                };
                store.commit("SET_BASEDRAP", {});
                store.commit("SET_CHATTYPE", "base");
                store.commit("SET_TOPIC", ress.data);
                store.commit("SET_FILELIST", []);
                store.commit("SET_TALK", obj);
                router.push("/chat");
                store.commit("SET_CHATPARMAS", {
                  type: "chat",
                  isValid: 0,
                });
                emit("closeChat");
              }
            });
        }
      });
  } else {
    // 没有文件就新增
    api.chat
      .addChat({
        userId: userId.value,
        topicName: question.value.slice(0, 250),
        templateId: templateId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          api.chat
            .chatDescQuery({
              userId: userId.value,
              sessionId: res.data.table_id,
              sessionType: 2,
            })
            .then((ress) => {
              if (ress.returnCode == 200) {
                // 新增对话 不算base chat
                store.commit("SET_CHATTYPE", "chat");
                let obj = {
                  model: tagActive.value == 2 ? null : model.value,
                  message: question.value,
                  topicId: res.data.table_id,
                  knowledgeId: baseId.value,
                  userId: userId.value,
                  isInternetOn: isInternetOn.value,
                  regenerateFlag: 0,
                  conversationId: null,
                  internetResource: null,
                  strategy: strategy.value,
                  imageFlag: tagActive.value == 2 ? 1 : 0,
                  imageSize: imageSize.value,
                  pictureModelType: tagActive.value == 2 ? model.value : null,
                  imageQuality: imageQuality.value,
                  imageStyle: imageStyle.value,
                  llmConfigurationDict: llmConfigurationDict.value,
                  llmNav: llmNav.value,
                };
                store.commit("SET_TOPIC", ress.data);
                store.commit("SET_TALK", obj);
                store.commit("SET_FILELIST", fileList.value);
                router.push("/chat");
                store.commit("SET_CHATPARMAS", {
                  type: "chat",
                  isValid: 0,
                });
                emit("closeChat");
              }
            });
        }
      });
  }
};
</script>

<style lang="scss" scoped>
@use "./index.scss" as index;
</style>
