<template>
  <div class="spView">
    <div class="titleFlex">
      <div class="addTitle">{{ t("base.base327") }}</div>
      <img :src="icon.addClose" alt="" class="addClose" @click="closeSp" />
    </div>
    <div class="content">
      <div class="tip">
        {{ t("base.base328") }}
      </div>
      <template v-if="step == 1">
        <!-- 第一步 -->
        <div class="stepTop">
          <div class="stepOne">{{ t("base.base138") }}</div>
          <div class="stepTitle"></div>
        </div>
        <div class="stepTip">
          {{ t("base.base329") }}
        </div>
        <div class="spDiv" @click="openSp">
          <img class="spColor" :src="icon.spColor" alt="" />
          <div class="spText">SharePoint</div>
        </div>
        <!-- 第二部 -->
        <template v-if="props.type == 1">
          <div class="stepTop">
            <div class="stepOne">{{ t("base.base145") }}</div>
            <div class="stepTitle">{{ t("base.base334") }}</div>
          </div>
          <div class="stepTip bottom30">{{ t("base.base335") }}</div>
          <el-input
            :placeholder="t('base.base336')"
            v-model="siteurl"
          ></el-input>
        </template>
      </template>
      <template v-if="step == 2">
        <div class="stepTop">
          <div class="stepOne">{{ t("base.base152") }}</div>
          <div class="stepTitle">{{ t("base.base330") }}</div>
        </div>
        <div class="stepTip">{{ t("base.base331") }}</div>
        <el-tree-select
          ref="tree"
          v-model="treeValue"
          :data="treeData"
          :load="loadNode"
          :default-expanded-keys="defaultKeys"
          collapse-tags
          :max-collapse-tags="3"
          lazy
          node-key="id"
          multiple
          show-checkbox
          check-strictly
          check-on-click-node
          :props="propsTree"
          @remove-tag="removeTag"
          @check="treeCheck"
        >
          <template #default="{ data }">
            <el-tooltip popper-class="toolTip" :content="data.name">
              <div link class="dropText">
                {{ data.name }}
              </div>
            </el-tooltip>
          </template>
        </el-tree-select>
      </template>
      <!-- 第三 -->
    </div>
    <div class="dialog-footer">
      <template v-if="step == 2">
        <div @click="goStep" class="cancel">{{ t("base.base337") }}</div>
        <div @click="saveSp" class="confirm">{{ t("base.base47") }}</div>
      </template>
      <template v-if="step == 1">
        <el-button
          @click="getSp"
          :loading="stepLoading"
          class="confirm"
          v-if="props.type == 1"
        >
          {{ t("base.base317") }}
        </el-button>
        <el-button @click="closeSp" class="cancel" v-if="props.type == 2">{{
          t("base.base340")
        }}</el-button>
      </template>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import { icon } from "@/utils/icon";
import api from "@/api";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { ref, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
const store = useStore();
const userId = ref(store.state.userId);
const treeValue = ref([]);
const step = ref(1);
const treeSel = ref([]);
const props = defineProps(["type", "form"]);
const propsTree = {
  children: "children",
  label: "name",
  id: "id",
  isLeaf: "leaf",
};
const treeData = ref();
const defaultKeys = ref([]);
const copyTreeValue = ref([]);
const copyDefaultKeys = ref([]);
const emit = defineEmits(["closeSp", "saveSp", "changeId"]);
onMounted(() => {
  if (props.type != 2) {
    siteurl.value = props.form.site.siteurl;
    if (
      props.form.site.fileIds.length != 0 ||
      props.form.site.folderIds.length != 0
    ) {
      treeValue.value = props.form.site.fileIds.concat(
        props.form.site.folderIds
      );
      defaultKeys.value = props.form.site.ParentfolderIds;
      // 防止切换一致站点
      copyTreeValue.value = JSON.parse(JSON.stringify(treeValue.value));
      copyDefaultKeys.value = JSON.parse(JSON.stringify(defaultKeys.value));
    }
  }
});
// 移除
const tree = ref(null);
const removeTag = (id) => {
  treeSel.value = treeSel.value.filter((item) => item.id !== id);
  treeValue.value = treeValue.value.filter((item) => item !== id);
};
// 选择文件 文件夹
const treeCheck = (a, b) => {
  treeSel.value = b.checkedNodes;
};
// 上一步
const goStep = () => {
  step.value = 1;
};
// 获取子集
const loadNode = (node, resolve) => {
  if (node.level === 0) {
    return resolve(treeData.value);
  } else {
    defaultKeys.value.push(node.data.id);
    defaultKeys.value = Array.from(
      new Set(defaultKeys.value.map((item) => item))
    );
    emit("changeId", defaultKeys.value);
    api.base
      .setSiteList({
        userId: userId.value,
        siteurl: siteurl.value,
        folder_id: node.data.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          res.data.forEach((item) => {
            if (item.file) {
              item.leaf = true;
            }
          });
          nextTick(() => {
            tree.value.setCheckedNodes(treeValue.value);
            tree.value.setCheckedKeys(treeValue.value);
          });
          resolve(res.data);
        } else {
          goStep();
        }
      });
  }
};
const siteurl = ref("");
// 验证
const stepLoading = ref(false);
const getSp = () => {
  stepLoading.value = true;
  api.base
    .setSiteList({
      userId: userId.value,
      siteurl: siteurl.value,
    })
    .then((res) => {
      stepLoading.value = false;
      if (res.returnCode == 200) {
        if (siteurl.value != props.form.site.siteurl) {
          treeValue.value = [];
          defaultKeys.value = [];
          treeSel.value = [];
          emit("changeId", []);
        } else {
          treeValue.value = copyTreeValue.value;
          defaultKeys.value = copyDefaultKeys.value;
          emit("changeId", defaultKeys.value);
          // 判断是文件 文件夹 然后调用树点击方法
          let fileIds = JSON.parse(JSON.stringify(props.form.site.fileIds));
          let folderIds = JSON.parse(JSON.stringify(props.form.site.folderIds));
          let newFileIds = fileIds.map((id, index) => ({
            id: id,
            file: index + 1, // index 是从 0 开始的，所以加 1
          }));
          let newFolderIds = folderIds.map((id, index) => ({
            id: id,
            folder: index + 1, // index 是从 0 开始的，所以加 1
          }));
          let checkedNodes = newFileIds.concat(newFolderIds);
          treeCheck({}, { checkedNodes: checkedNodes });
        }
        res.data.forEach((item) => {
          if (item.file) {
            item.leaf = true;
          }
        });
        treeData.value = res.data;
        step.value = 2;
      }
    });
};
// 打开授权
const openSp = () => {
  api.base.getSpUrl({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      console.log(res.data);
      const width = 700; // 弹窗宽度
      const height = 500; // 弹窗高度
      // 计算弹窗位置，使其居中
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
      window.open(
        res.data.auth_url,
        "_blank",
        `width=${width},height=${height},left=${left},top=${top}`
      );
    }
  });
};
const closeSp = () => {
  emit("closeSp");
};
const saveSp = () => {
  let site = {
    siteurl: siteurl.value,
    fileIds: [],
    folderIds: [],
    ParentfolderIds: defaultKeys.value,
  };
  treeSel.value.forEach((item) => {
    if (item.file) {
      site.fileIds.push(item.id);
    } else if (item.folder) {
      site.folderIds.push(item.id);
    }
  });
  emit("saveSp", site);
};
</script>

<style lang="scss" scoped>
.spView {
  position: fixed;
  width: 540px;
  max-height: 613px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 121;
  background-color: #fefefe;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 30px;
  padding: 30px;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 120;
  opacity: 0.3;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}

.addClose {
  width: 14px;
  cursor: pointer;
}

.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
}
.tip {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: 500;
}
.stepOne {
  background-color: #767bfa;
  border-radius: 30px;
  padding: 3px 21px;
  font-size: 18px;
  color: #feffff;
  font-weight: 600;
  text-align: center;
  margin-right: 12px;
}
.stepTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 600;
}
.stepTip {
  font-size: 16px;
  color: #8a8a8a;
  font-weight: 600;
  margin-top: 6px;
}
.stepTop {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.spColor {
  width: 26px;
  margin-right: 3px;
}
.spDiv {
  background-color: #f7f8ff;
  display: flex;
  align-items: center;
  border-radius: 18px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  width: 225px;
  height: 56px;
  justify-content: center;
  margin-top: 30px;
  font-size: 18px;
  color: #0256ff;
  font-weight: 600;
  cursor: pointer;
}
.spText {
  position: relative;
}
// .spText::after {
//   content: "";
//   position: absolute;
//   width: 8px;
//   height: 8px;
//   border-radius: 50%;
//   background-color: #11d7b2;
//   right: -18px;
//   top: 0;
// }
:deep(.el-select__wrapper) {
  min-height: 48px;
  border-radius: 16px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
}
.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;
  margin-top: 24px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    font-weight: 400;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}
.content {
  max-height: calc(100% - 119px);
  overflow: auto;
}
.bottom30 {
  margin-bottom: 30px;
}
:deep(.el-input__wrapper) {
  border-radius: 16px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
}
:deep(.el-input__inner::placeholder) {
  font-weight: 400;
  font-size: 18px;
}
.dropText {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d !important;
  padding-left: 10px;
  max-width: 400px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
